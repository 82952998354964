import io from "socket.io-client"
import { SET_SOCKET } from "../actions/actionTypes";

const ENDPOINT = process.env.REACT_APP_URL_API
const inicialState = io(ENDPOINT, { transports: ["websocket"], forceNew: true, reconnectionDelay:2500,  });

export default function socket(state = inicialState, action) {
    switch (action.type) {
        case SET_SOCKET:
            state.close();
            return io(ENDPOINT, { transports: ["websocket"], forceNew: true, reconnectionDelay:2500, });
        default:
            return state;
    }
} 

