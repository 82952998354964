import { ContainerEmptyMessage } from "./style";
import ImageChat from "../../assets/images/empty-chat.png";
import ImageReport from "../../assets/images/empty-report.png";
interface EmptyMessageProps {
  type: "message" | "report";
}

export function EmptyMessage({ type }: EmptyMessageProps) {
  const Image = type === "message" ? ImageChat : ImageReport;
  const message =
    type === "message"
      ? "Selecione as conversas para visualizar as mensagens"
      : `Sintetize os seus dados e crie relatórios para você e seus grupos`;
  return (
    <ContainerEmptyMessage>
      <img src={Image} />
      <p className="message">{message}</p>
    </ContainerEmptyMessage>
  );
}
