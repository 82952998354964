import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { ReportFunctions } from "../../../util/report/reportFunctions";

import {
  Content,
  ContentTable,
  ContentText,
  DynamicContent,
  Margins,
  Size,
  TDocumentDefinitions,
  TableCell,
} from "pdfmake/interfaces";

import { ReportStylesInterface } from "../../../types/project";
import { defaultImage } from "./assets/defaultImage";

type reportProps = {
  download?: boolean;
  header: TableCell[];
  data: TableCell[][];
  widths?: Size[];
  stylesPDF: ReportStylesInterface;
  setSource: (any) => void;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

function widthTable(tam: number) {
  let cells: Size[] = [];
  for (let i = 0; i < tam; i++) {
    cells.push("auto");
  }

  return cells;
}

async function reportPDF({
  download = false,
  header,
  data,
  widths,
  setSource,
  setLoading,
  stylesPDF,
}: reportProps) {
  const {
    title,
    orientation,
    lineHeight,
    styleBorder,
    page,
    margins,
    background,
    colorBackground,
    headerContent,
    colorBorder,
    sortBody,
    body,
    visibleTable,
    visibleTitle,
    footer,
    marginTable,
    footerContent,
    alignmentTable,
  } = stylesPDF;
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const details: ContentTable | ContentText | Content =
    data.length > 0
      ? {
          columns: [
            {
              width:
                alignmentTable == "center" || alignmentTable == "right"
                  ? "*"
                  : "auto",
              text: "",
            },

            {
              width: alignmentTable == "justify" ? "*" : "auto",
              table: {
                headerRows: 1,
                widths: widths ? widths : widthTable(header.length),
                heights: (row) => (row != 0 ? lineHeight : "auto"),
                body: [header, ...data],
                dontBreakRows: false,
              },

              layout: {
                ...ReportFunctions.getStyleBorderTable(
                  styleBorder,
                  colorBorder
                ),
                ...ReportFunctions.getBackgroundTable(
                  background,
                  colorBackground
                ),

                hLineStyle: function (i, node) {
                  if (i === 0 || i === node!.table.body.length) {
                    return null;
                  }
                  return null;
                },
                vLineStyle: function (i, node) {
                  if (i === 0 || i === node!.table!.widths!.length) {
                    return null;
                  }
                  return null;
                },
                // paddingLeft: function(i, node) { return 4; },
                // paddingRight: function(i, node) { return 4; },
                // paddingTop: function(i, node) { return 2; },
                // paddingBottom: function(i, node) { return 2; },
                // fillColor: function (i, node) { return null; }
              },
            },
            {
              width:
                alignmentTable == "center" || alignmentTable == "left"
                  ? "*"
                  : "auto",
              text: "",
            },
          ],
        }
      : {
          text: `Nenhum dado retornado na busca`,
          alignment: "left",
          fontSize: 10,
          margin: [0, 10, 0, 0],
        };

  const Rodape: DynamicContent = (currentPage, pageCount, pageSize) => {
    const test4 = deepCopyFunction(footerContent);

    const contentPagination = {
      ...footer.pagination,
      text: currentPage + " / " + pageCount,
    };
    const contentDateCreated = {
      ...footer.dateCreated,
      text: `${new Date().toLocaleString()}`,
    };
    test4[contentPagination.position] = [
      contentPagination,
      ...test4[contentPagination.position],
    ];
    test4[contentDateCreated.position] = [
      ...test4[contentDateCreated.position],
      contentDateCreated,
    ];
    return [
      {
        width: pageSize.width,
        marginLeft: margins[0],
        marginRight: margins[2],
        columns: [
          {
            width: (pageSize.width - margins[0] - margins[2]) / 3,
            stack: test4[0],
          },
          {
            width: (pageSize.width - margins[0] - margins[2]) / 3,
            stack: test4[1],
          },
          {
            width: (pageSize.width - margins[0] - margins[2]) / 3,
            stack: test4[2],
          },
        ],
      },
    ];
  };
  const calcMargin = (): Margins => {
    const marginCalc = ReportFunctions.findMarginHeader(stylesPDF);
    const marginHeader =
      marginCalc > stylesPDF.margins[1] ? marginCalc : stylesPDF.margins[1];
    const marginCalcFooter = ReportFunctions.findMarginFooter(stylesPDF);
    const marginFooter =
      marginCalcFooter > stylesPDF.margins[3]
        ? marginCalc
        : stylesPDF.margins[3];

    return [
      stylesPDF.margins[0],
      marginHeader,
      stylesPDF.margins[2],
      marginFooter,
    ];
  };
  const deepCopyFunction = (inObject) => {
    let outObject, value, key;

    if (typeof inObject !== "object" || inObject === null) {
      // retorna o valor de entrada se inObject não é objeto
      return inObject;
    }

    // Cria um objeto ou array para armazenar os valores
    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
      value = inObject[key];

      // Cópia recursiva (profunda) para objetos aninhados
      outObject[key] = deepCopyFunction(value);
    }

    return outObject;
  };

  const HeaderFunction: DynamicContent = (currentPage, pageCount, pageSize) => {
    const test4 = deepCopyFunction(headerContent);
    return {
      width: pageSize.width,
      marginLeft: margins[0],
      marginRight: margins[2],
      columns: [
        {
          width: (pageSize.width - margins[0] - margins[2]) / 6,
          stack: test4[0],
        },
        {
          width: ((pageSize.width - margins[0] - margins[2]) / 6) * 4,
          stack: test4[1],
        },
        {
          width: (pageSize.width - margins[0] - margins[2]) / 6,
          stack: test4[2],
        },
      ],
    };
  };

  const getBody = (): Content => {
    const bodyContent: Content[] = [];
    if (sortBody !== undefined) {
      sortBody.forEach((id) => {
        if (id == "title") {
          if (visibleTitle) {
            bodyContent.push(title);
          }
        } else if (id == "table") {
          if (visibleTable) {
            bodyContent.push(details);
          }
        } else {
          const item = body.find((item) => item.id == id);
          if (item) {
            bodyContent.push(item);
          }
        }
      });
    } else {
      if (visibleTitle) {
        bodyContent.push(title);
      }
      if (visibleTable) {
        bodyContent.push(details);
      }
      body.forEach((item) => bodyContent.push(item));
    }

    return bodyContent;
  };

  const docDefinitions: TDocumentDefinitions = {
    pageSize: page,
    info: {
      title: `${title.text.toString()}`,
    },
    pageMargins: calcMargin(),
    header: HeaderFunction,
    content: getBody(),
    footer: Rodape,
    pageOrientation: orientation,
    images: defaultImage
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinitions);

  if (download) {
    pdfDocGenerator.download(() => {
      setLoading && setLoading(false);
    });
  } else {
    pdfDocGenerator.getDataUrl((dataUrl) => {
      console.log(dataUrl);
      setSource(dataUrl);
      setLoading && setLoading(false);
    });
  }
}

export default reportPDF;
