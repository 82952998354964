import React from "react";
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { SocketContext } from "../../services/socket";

import styled, { keyframes } from "styled-components";
import { HiPhone } from '../../assets/icons/';

const CallNotification = (props) => {

    const { call, callAccepted, setCall } = React.useContext(SocketContext)
    const history = useHistory();


    const callNotificationRoot = document.getElementById("call-notification-root");

    const isCalling = () => {
        return call?.isReceivingCall && !callAccepted;
    }

    const acceptCall = () => {
        document.title = process.env.REACT_APP_NAME || '';
        history.push({
            pathname: '/call'
        })
    }
    const rejectCall = () => {
        setCall(null)
        document.title = process.env.REACT_APP_NAME || '';
    }

    return isCalling() ?
        ReactDOM.createPortal(
            (
                <Container>
                    <Content>
                        <AsideLine />
                        <HiPhone className="icon" size={38} color="#508CE7" />

                        <CallInfo>
                            <h1 className="title">{call?.name || "Sérgio Augusto"}</h1>
                            <p className="text">Está lhe chamando...</p>
                        </CallInfo>

                        <Options>
                            <button className="accept" onClick={acceptCall}>
                                Aceitar
                            </button>
                            <button className="reject" onClick={rejectCall}>
                                Rejeitar
                            </button>
                        </Options>
                    </Content>
                </Container>
            )
            , callNotificationRoot ? callNotificationRoot : new Element()
        )
        : null;

}

export default CallNotification;


const PushAnimation = keyframes`
    0%{
        top: -164px;
    }
    100%{
        top: 40px;
    }
`;


const Container = styled.div`
    position: absolute;
    width: 614px;
    height: 114px;
    right: 85px;
    top: 40px;
    cursor: default;
    animation: ${PushAnimation} 0.35s ease-in-out;
    z-index: 1000;

    @media only screen and (max-width: 700px) {
        right: 4rem;
        width: auto;
    }
`;

const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: start;
    align-items: center;
    border-radius: 22px;
    padding: 0 22px;
    background-color: #fff;
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    & .icon{
        margin: 0 20px;
    }

`;

const AsideLine = styled.span`
    width: 7px;
    height: 70px;

    background: #508CE7;
    border-radius: 7px;
`;

const CallInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 120px;
    
  

    & .title{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        text-align: left;
        margin: 0;
        margin-bottom: 4px;
        color: #508CE7;
    }

    & .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 21px;
        margin: 0;
        color: #575757;
    }

    @media only screen and (max-width: 700px) {
       margin-right: 40px;
    }

    @media only screen and (max-width: 500px) {
       margin-right: 0;
    }
`;

const Options = styled.div`

    @media only screen and (max-width: 700px) {
       max-width: 170px;
    }
    & button{
        font-family: 'Poppins Semi-bold';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
    }

    & .accept{
        color: #097F3F;
    }

    & .reject{
        color: #A61627;
    }
`;