import { Container, Main } from "./style";
import { FiHelpCircle } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";

const Barra = (props) => {
  const { senha } = props; 
  const [status, setStatus] = useState('barra_fraca')
  const [mensagemSenha, setMensagemSenha] = useState('Sua senha está muito fácil. Pode ser melhor!')
  const [textoSenha, setTextoSenha] = useState('FRACA')
  const [colorStatus, setColorStatus] = useState('vermelho')
  const [contain, setContain] = useState(" ")

  useEffect(() => {
    setContain(' ')
    const valor = senha.toString()
    const senhaForte = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#%()])[0-9a-zA-Z$*&@#%()]{8,}$/)

    if (senhaForte.test(valor)) {
      setStatus('barra_forte')
      setColorStatus('verde')
      setMensagemSenha('Sua senha está forte. Ótimo trabalho!')
      setTextoSenha('FORTE')
    } else if (valor.length > 5) {
      setStatus('barra_media')
      setColorStatus('amarelo')
      setMensagemSenha('Sua senha está boa, mas ainda pode ser melhor!')
      setTextoSenha('MÉDIA')
    } else if (valor.length <= 5 && valor.length > 0) {
      setStatus('barra_fraca')
      setColorStatus('vermelho')
      setMensagemSenha('Sua senha está muito fácil. Pode ser melhor!')
      setTextoSenha('FRACA')
    } else {
      setContain('some')
    }

  }, [senha]);

  return (
    <Main>
      <Container className={contain}>
        <div className="header">
          <div className="title">
            Segurança da senha
            <div
              className="center"
              data-tip="Use ao menos 8 caracteres. Alterne entre letras (maiúsculas e minúsculas), números e caracteres especiais."
            >
              <ReactTooltip
                className="test"
                textColor="#fff"
                border={true}
                borderColor="#BDBDBD"
                backgroundColor="#000"
                type="info"
                place="right"
                effect="solid"
              />
              <FiHelpCircle className="icon"></FiHelpCircle>
            </div>
          </div>
          <p className={"palavra_status " + colorStatus}>{textoSenha}</p>
        </div>

        <div className="barra">
          <div className={status}></div>
        </div>

        <p className="footer">
          {mensagemSenha}
        </p>
      </Container>
    </Main>
  );
};

export default Barra;