import moment from "moment";
import { useContext, useEffect, useState } from "react";
import AudioAnalyser from "react-audio-analyser";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeContext } from "styled-components";
import { AiFillAudio, BsFillPauseFill, FiTrash, HiCheck, IoMdSend } from "../../../../../../assets/icons";
import { setMessage } from "../../../../../../store/actions/messages/messageAction";
import { TypeMessage } from "../../../../../../types/project";
import { Btn } from "../style";
import Waveform, { BtnVoice } from "../wave";
import { Container, PreviewAudio } from "./style";

const RECORDER_TYPE = {
    pause: 'paused',
    start: 'recording',
    finish: 'inactive',
    abort: 'abort'
}

var startTimestamp = moment().startOf("day");

const AudioRecorderFunc = (props) => {
    const { setMessage, setRecorder } = props
    const { pause, start, finish, abort } = RECORDER_TYPE;
    const [status, setStatus] = useState("");
    const [timer, setTimer] = useState("00:00");
    const [previewAudio, setPreviewAudio] = useState<any>(null);
    const [hover, setHover] = useState(false);
    const theme = useContext(ThemeContext);


    useEffect(() => {
        setStatus(start)
        startTimestamp = moment().startOf("day");
    }, [])

    const controlAudio = status => {
        setStatus(status);
    };

    const toggleRecording = () => {
        status === start
            ? controlAudio(pause)
            : controlAudio(start)
    };

    const audioProps = {
        audioType: "audio/mp3",
        className: "record-audio",
        backgroundColor: theme.chat.message.backgroundMyI,
        strokeColor: "#756D71",
        status,
        height: 28,
        width: 180,
        timeslice: 1100,
        stopCallback: e => {
            if (previewAudio == abort) {
                setRecorder(false);
            } else {
                setPreviewAudio({
                    fileURL: URL.createObjectURL(e),
                    fileType: TypeMessage.VOICE,
                    file: e,
                    blobUrl: URL.createObjectURL(e)
                });
            }
        },
        onRecordCallback: e => {
            startTimestamp.add(1, 'second');
            setTimer(startTimestamp.format('mm:ss'));
        },
        errorCallback: err => {
        },
    };

    const cancel = () => {
        if (status !== finish) {
            setPreviewAudio(abort)
            setStatus(finish);
        } else {
            setRecorder(false);
        }
    }

    const handleMouse = () => {
        setHover(!hover);
    }

    return (
        <Container>
            <Btn onClick={() => cancel()}>
                <FiTrash className="close" size={28} />
            </Btn>
            <PreviewAudio>
                {!previewAudio?.blobUrl ?
                    <>
                        <BtnVoice className={status == pause ? '' : 'rec'} onClick={() => { toggleRecording() }} onMouseEnter={() => handleMouse()} onMouseLeave={() => handleMouse()} >
                            {hover && status !== pause ?
                                <BsFillPauseFill />
                                :
                                <AiFillAudio />
                            }
                        </BtnVoice>
                        <AudioAnalyser  {...audioProps}/>
                        <div id="timer">
                            {timer}
                        </div>
                    </>
                    :
                    <Waveform url={previewAudio.blobUrl} none />
                }
            </PreviewAudio>
            {!previewAudio?.blobUrl ?
                <Btn onClick={() => { controlAudio(finish) }}>
                    <HiCheck className="finish" />
                </Btn>
                :
                <Btn>
                    <IoMdSend className="play" size={28} onClick={() => {
                        setMessage(previewAudio);
                        setRecorder(false);
                    }} />
                </Btn>
            }
        </Container >
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setMessage
}, dispatch);

export default connect(null, mapDispatchToProps)(AudioRecorderFunc)