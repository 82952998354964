import styled from "styled-components";
import { mobile } from "../../../components/NavBar/style";
interface StyledProps {
  gridRow?: string;
  column2?: boolean;
  column1?: boolean;
  schedule?: boolean;
  $overflow?: boolean;
  border?: boolean;
}
const responsive2b = `
@media only screen and (max-width: 1817px) {
  overflow: auto;
}

@media only screen and (max-width: 1217px) {
  width: calc(100% - 3rem);
  padding: 1.5rem 1.5rem;
}
`;

const responsive2 = `
@media only screen and (max-width: 1817px) {
  grid-template:
      "conta conta informacoes informacoes" auto
      "clima clima evento evento" 1fr
      ". aniversario aniversario ." auto
      / 1fr 1fr 1fr 1fr;
  height: auto;
}

@media only screen and (max-width: 1318px) {
  grid-template:
  "informacoes" auto
    "conta" auto
    "clima" 1fr
    "evento" 1fr
    "aniversario" auto
    / 1fr;
  height: auto;
}`;

const responsive1 = `
    grid-template:
    "informacoes" auto
    "conta" auto
    "clima" 1fr
    "evento" 1fr
    "aniversario" auto
    / 1fr;
    height: auto;`;
export const ContainerDashBoard = styled.div<StyledProps>`
  width: 100%;
  border-left: ${({ theme, border }) =>
    border ? `1px solid ${theme.bordes.lightGrey}` : `inherit`};
  height: 100%;
  display: flex;
  background-color: ${(props) => props.theme.backgrounds.default};
  flex-direction: column;
  /* padding: 0px 2.5rem 1.5rem 2.5rem; */
  /* ${({ column1 }) => (column1 ? `initial` : `960px`)}; */
  overflow: auto;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    max-height: initial;
  }
  /* @media only screen and (max-width: 700px) {
    height: 100%; 
  } */
  @media only screen and (max-width: ${mobile}) {
    max-width: initial;
    height: 100%;
    overflow: auto;
  }

  ${(props) => props.column2 && responsive2b};

  .image_birthday {
    height: 55px;
    width: 55px;
    padding: 4px 0px;
    border-radius: 50%;
  }

  ${({ schedule }) =>
    schedule &&
    `@media only screen and (max-width: 1510px) {
      display: none;
  }`};
`;

export const ContainerSmall = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: informacoes;

  .div_bottom {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }
`;

export const ContainerContent = styled.div<StyledProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  margin-bottom: 1rem;
  grid-template-rows: repeat(
    auto,
    minmax(120px, auto)
  ); /* Três linhas com tamanho mínimo de 100px */
  gap: 1rem;
  padding: 0 1.5rem;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  align-self: center;
  box-sizing: border-box;

  > * {
    min-height: 200px;
  }
  @media only screen and (max-width: 1450px) {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }

  @media only screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    > * {
      min-width: 200px;
    }
  }
`;

export const ContainerTitle = styled.div<StyledProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(4rem + 12px + 12px);
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 5;
  background-color: ${(props) => props.theme.backgrounds.white};
  border-bottom: 1px solid ${({ theme }) => theme.bordes.lightGrey};

  box-sizing: border-box;
  padding: 1.5rem 2.5rem 4px 2.5rem;
  padding: 12px 2rem;

  @media only screen and (max-width: 900px) {
    padding: 1.5rem 1.5rem 4px 1.5rem;
  }
  @media only screen and (max-width: ${mobile}) {
    padding-top: 1rem;
  }

  ${(props) => props.column1 && ` margin-top: 0;`};
  @media only screen and (max-width: 900px) {
    margin: 0px;
  }

  .container_notification {
    position: relative;
    padding: 6px;
  }

  .number_notifications {
    background-color: red;
    padding: 2px 6px;
    position: absolute;
    top: -8px;
    right: -4px;
    margin: 0px;
    color: white;
    border-radius: 90px;
  }

  .button {
    display: flex;
    #profile_dash {
      display: flex;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      align-self: center;
      object-fit: cover;
      ${(props) => props.column1 && ` display: flex;`};
    }
    p {
      margin-top: 0px !important;
      padding-left: 1rem;
    }
    :hover {
      cursor: pointer;
    }
  }

  .name {
    margin-bottom: 0px;
    font-size: 32px;
    ${(props) => props.column1 && ` display: none;`};
    font-family: "inter-bold", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  .nameLogo {
    margin-bottom: 0;
    font-size: 32px;
    display: none;
    ${(props) => props.column1 && ` display: flex;`};
    font-family: "inter-bold", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    @media only screen and (max-width: 900px) {
      display: flex;
    }
  }

  .label {
    margin-top: 8px;
    font-size: 16px;
    font-family: "inter-semi-bold", sans-serif;
    color: ${(props) => props.theme.default.darkerI};
  }

  #icon_notification {
    height: 30px;
    width: 30px;
    color: ${(props) => props.theme.texts.purpleDark};
    align-self: center;

    :hover {
      cursor: pointer;
    }
  }
  .iconDrop {
    font-size: 25px;
    color: ${(props) => props.theme.begin.color.button.secondary};
    margin-right: 8px;
  }

  #btn-option {
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
    #iconDrop {
      font-size: 25px;
      color: ${(props) => props.theme.begin.color.button.secondary};
    }
  }
  .button-notification {
    padding: 6px;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ContainerBirthday = styled.div<StyledProps>`
  flex: 1;
  grid-column: span 1;
  grid-row: span 2;
  width: 100%;
  display: flex;
  height: 20rem;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  background: ${(props) => props.theme.backgrounds.primary};
  border: 1px solid ${({ theme }) => theme.bordes.card};
  border-radius: 16px;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${({ theme }) => theme.boxShadow.card};

  .month {
    margin: 0px;
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.5rem;
  }

  .scroll {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 2rem;
  }

  .divItens {
    flex: 1;
  }

  ${(props) => props.column1 && `max-height: 350px;`};

  @media only screen and (max-width: 1500px) {
    max-height: 350px;
  }
`;

export const NoNotifications = styled.li`
  :hover {
    background-color: inherit !important;
  }
  display: flex !important;
  justify-content: center;
  margin: 0px 16px;
`;

export const ContainerAccountInfor = styled.div`
  grid-column: span 1;
  grid-row: span 2;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  padding: 1.5rem;
  box-sizing: border-box;

  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
  display: flex;
  border-radius: 16px;
  color: ${({ theme }) => theme.texts.darkerGreyII};
  justify-content: flex-start;
  background: ${(props) => props.theme.backgrounds.primary};

  border: 1px solid ${({ theme }) => theme.bordes.card};
  box-shadow: ${({ theme }) => theme.boxShadow.card};

  color: ${({ theme }) => theme.texts.contentCards};

  .p_pacote {
    font-family: "Inter", sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    margin: 0px;
    margin-bottom: 0.75rem;
  }

  .title {
    font-family: "Inter", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.texts.titleCards};
  }
  @media only screen and (max-width: 700px) {
    min-height: 310px;
  }
  @media only screen and (max-width: ${mobile}) {
    .title {
      font-size: 1.6rem;
    }
  }

  .margin {
    margin-top: 1rem !important;
  }

  .empresa {
    display: flex;
    width: calc(100% - 4rem);
    margin: 0 2rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
      position: relative;
      top: -5px;
      align-self: flex-end;
    }
  }

  .subtitle {
    font-family: "Poppins medium";
    padding-bottom: 0.5rem;
    font-size: 20px;

    align-items: center;
    text-align: center;
    font-size: large;
    margin-bottom: 0px;
  }
  .texto {
    margin-top: 0px;
    text-align: center;
    font-size: xx-large;
  }
`;

export const ContainerEvents = styled.div<StyledProps>`
  grid-column: span 1;
  grid-row: span 2;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
  background: ${(props) => props.theme.backgrounds.primary};
  border: 1px solid ${({ theme }) => theme.bordes.card};
  min-height: 200px;
  border-radius: 16px;
  padding: 0px;

  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: ${({ theme }) => theme.boxShadow.card};

  .teste {
    box-sizing: border-box;
    height: 100%;
    padding-top: 1.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .div-itens {
    > * {
      padding: 0 2rem;
    }
    padding-top: 1rem;
    display: flex;
    overflow: auto;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }
  ${(props) => props.column1 && `max-height: 350px;`};

  @media only screen and (max-width: 1500px) {
    max-height: 350px;
  }
`;

export const ContainerAdvertising = styled.div`
  grid-area: ad;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  display: flex;
  max-height: 370px;

  border-radius: 16px;
  background-color: #43c778;
  img {
    object-fit: cover;
    border-radius: 16px;
  }
  @media only screen and (max-width: 700px) {
    min-height: 250px;
  }
  @media only screen and (max-width: 1500px) {
    max-height: 350px;
  }
`;

export const ContainerModalAll = styled.div`
  display: block;
  max-height: 430px;
  overflow-y: auto;
`;

export const NoContent = styled.p`
  margin: 0;
  color: #848383;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
export const More = styled.div`
  color: #47a845;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0px;

  font-family: "Poppins", sans-serif;
  font-weight: 500;
  z-index: 2;

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.hover.lightGreyIII};
  }
`;

export const ProfileMenu = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  border-bottom: ${(props) =>
    props.theme.title == "light" && "1px solid rgba(255, 255, 255, 0.2)"};
  color: ${(props) => props.theme.texts.darkerGreyII};
  overflow-y: ${({ $overflow }) =>
    $overflow ? `visible` : `auto !important;`};
  overflow-x: ${({ $overflow }) => ($overflow ? `initial` : `hidden`)};
  max-height: 75vh !important;

  .center {
    text-align: left;
    color: ${(props) => props.theme.texts.purpleDark};

    padding-left: 1rem;
  }
  .title_noti {
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey} !important ;
    padding-bottom: 8px;
    margin-bottom: 0px;
  }

  #profile {
    margin-top: 12px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    align-self: center;
    object-fit: cover;
  }

  .text {
    width: 100%;
    align-self: center;
    margin-left: 12px;
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};

    #name {
      margin: 0;
      font-size: 20px;
    }

    #nickname {
      margin: 0;
      font-size: 14px;
      font-weight: lighter;
    }
  }

  li {
    border-radius: 0px !important;
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey} !important  ;
  }
`;
