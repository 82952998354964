import { Fragment, useRef } from "react";
import { RiShieldUserLine } from "../../../../../../assets/icons";
import ItemContainer from "../../../Components/ItemContainer";
import Access from "../../Access";
import { Button, HorizontalRow, PasswordPerfil } from "../../style";

const ModalAcess = () => {

    const modalAccess = useRef<any>();

    return (
        <Fragment>
            <ItemContainer title="Acesso">
                <Button onClick={() => modalAccess.current?.open()}>
                    <HorizontalRow>
                        <RiShieldUserLine size={22} />
                        <PasswordPerfil>
                            Acessos Autorizados
                            <div className="line">
                                Defina como acessar a plataforma
                            </div>
                        </PasswordPerfil>
                    </HorizontalRow>
                </Button>
            </ItemContainer>
            <Access modalAcess={modalAccess} />
        </Fragment>
    );
}

export default ModalAcess;