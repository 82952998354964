import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconHome = ({ color = "#666666", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_5707_608)">
        <path
          d="M12.2007 0.504154C11.8582 0.180394 11.4049 0 10.9336 0C10.4623 0 10.0089 0.180394 9.66648 0.504154L0.732193 8.94967C0.178871 9.47164 -0.132832 10.2002 -0.132832 10.9601V21.2334C-0.132832 21.9671 0.158648 22.6708 0.677486 23.1897C1.19633 23.7085 1.90002 24 2.63377 24H6.32258C6.68605 24 7.04596 23.9284 7.38174 23.7892C7.71752 23.6501 8.02259 23.4461 8.27951 23.189C8.53644 22.9319 8.74019 22.6267 8.87912 22.2908C9.01804 21.955 9.08942 21.595 9.08918 21.2316V16.6205C9.08918 16.376 9.18634 16.1414 9.35929 15.9684C9.53223 15.7955 9.7668 15.6983 10.0114 15.6983H11.8558C12.1004 15.6983 12.3349 15.7955 12.5079 15.9684C12.6808 16.1414 12.778 16.376 12.778 16.6205V21.2316C12.778 21.9653 13.0695 22.669 13.5883 23.1878C14.1071 23.7067 14.8108 23.9982 15.5446 23.9982H19.2334C19.9671 23.9982 20.6708 23.7067 21.1897 23.1878C21.7085 22.669 22 21.9653 22 21.2316V10.9582C22 10.1983 21.6865 9.46979 21.1331 8.94783L12.2007 0.500465V0.504154ZM10.9336 1.84319L19.8679 10.2906C19.9586 10.3765 20.0308 10.4801 20.0803 10.5948C20.1298 10.7096 20.1554 10.8332 20.1556 10.9582V21.2316C20.1556 21.4761 20.0584 21.7107 19.8855 21.8836C19.7125 22.0566 19.478 22.1538 19.2334 22.1538H15.5446C15.3 22.1538 15.0654 22.0566 14.8925 21.8836C14.7195 21.7107 14.6224 21.4761 14.6224 21.2316V16.6205C14.6224 15.8868 14.3309 15.1831 13.8121 14.6643C13.2932 14.1454 12.5895 13.8539 11.8558 13.8539H10.0114C9.27763 13.8539 8.57394 14.1454 8.0551 14.6643C7.53626 15.1831 7.24478 15.8868 7.24478 16.6205V21.2316C7.24478 21.4761 7.14762 21.7107 6.97467 21.8836C6.80173 22.0566 6.56716 22.1538 6.32258 22.1538H2.63377C2.38919 22.1538 2.15462 22.0566 1.98168 21.8836C1.80873 21.7107 1.71157 21.4761 1.71157 21.2316V10.9582C1.71153 10.8329 1.73703 10.709 1.7865 10.5938C1.83597 10.4787 1.90837 10.3749 1.9993 10.2887L10.9336 1.84319Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5707_608">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconHome;
