import styled from "styled-components";

export const ContainerBlocked = styled.div`
  display: grid;
  grid-template-rows: 50px 250px;
`;

export const HeaderBlock = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  justify-items: center;
  align-items: center;

  h5 {
    justify-self: start;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  overflow: auto;

  #bold{
    font-weight: bold;
  }
  p {
    font-size: 16px;
    color: ${props => props.theme.texts.darkerGreyII};
    text-align: center;
    margin: 0.5rem 0rem;
  }
  a{
    color: ${(props) => props.theme.link};
  }
`;
