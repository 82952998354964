import styled from "styled-components";
import ImageDark from "../../assets/images/padronagemDark.svg";
import Image from "../../assets/images/padronagem.svg";

export const ContainerEmptyMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${({ theme }) => theme.title === "dark" ? ImageDark : Image});
  
  flex-direction: column;
  gap: 1rem;

  svg {
    fill: red;
  }
  img {
    max-height: 75%;
    max-width: 100%;
    object-fit: cover;
  }

  .message {
    margin: 0px;
    color: ${({theme}) => theme.texts.contentCards};
    text-align: center;
    font-family: "inter-medium", sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
  }
`;
