import { ContainerNoticesEmpty } from "./style";
import BackImage from "../../../../../../assets/images/novidades.png";

export function CardNotices() {
  return (
    <ContainerNoticesEmpty>
      <img src={BackImage} className="back-image" />

      <div className="content">
        <header>Personalize os conteúdos que você quer ver!</header>
        <main>
          Receba novidades personalizadas e de seu interesse na plataforma.
        </main>
      </div>
      <button>Personalizar conteúdos</button>
    </ContainerNoticesEmpty>
  );
}
