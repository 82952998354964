import React from "react";
import { SVGComponent } from "../../types/project";

export interface SVGComponentBase extends SVGComponent {
  children?: React.ReactNode;
  title?: string;
}
const Icon = ({ color, size, children, title, ...rest }: SVGComponentBase) => {
  return (
    <svg
      width={size}
      height={size}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      {children}
    </svg>
  );
};

export default Icon;
