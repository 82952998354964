import React, { forwardRef, useImperativeHandle } from "react";
import ReactDOM from 'react-dom';
import { ModalBox, ModalWrapper, ModalBackdrop, Header } from './style';
import { IoIosArrowBack } from '../../../assets/icons/index'

const Modal = forwardRef((props: any, ref) => {
    const { title, subTitle, none } = props;
    const [display, setDisplay] = React.useState(false);

    useImperativeHandle(ref, () => {
        return {
            open: () => open(),
            close: () => close()
        }
    });

    const open = () => {
        setDisplay(true)
    };

    const close = () => {
        setDisplay(false);
    };

    const modalRoot = document.getElementById("modal-root");

    return display ? ReactDOM.createPortal(
        !none ?
            title && subTitle ?
                <ModalWrapper>
                    <ModalBackdrop onClick={close} />
                    <ModalBox>
                        <div></div>
                        <Header>
                            <div id="top">
                                <IoIosArrowBack id="back" onClick={close} />
                                <div id="data">
                                    <h3 id="title">{title}</h3>
                                    <legend id="subTitle">{subTitle}</legend>
                                </div>
                            </div>
                        </Header>
                        {props.children}
                    </ModalBox>
                </ModalWrapper>
                :
                <ModalWrapper>
                    <ModalBackdrop onClick={close} />
                    <ModalBox>
                        {props.children}
                    </ModalBox>
                </ModalWrapper>
            :
            <ModalWrapper>
                <ModalBackdrop onClick={close} />
                {props.children}
            </ModalWrapper>

        , modalRoot ? modalRoot : new Element()) : null

});


export default Modal;



