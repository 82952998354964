import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import { AxiosError, AxiosResponse } from "axios";
import { useToasts } from "react-toast-notifications";
import { ToastModel } from "../../../../../util/error";
import { Queries } from "../../../../../types/queries";
import {
  StorageClusterService,
  UpdateColorStorageClusterPayload,
  UpdateNameStorageClusterPayload,
} from "../../../../../services/requests/storage-cluster";

interface UseFolderUpdateName {
  mutate: UseMutateFunction<AxiosResponse<any>, AxiosError<any>, UpdateColorStorageClusterPayload, unknown>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export const UseFolderUpdateColor = (): UseFolderUpdateName => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();


  const { mutate, isLoading, isSuccess, isError,  } = useMutation(
    (data: UpdateColorStorageClusterPayload) => StorageClusterService.patchColor(data),

    {
      onSuccess: ({ status }: AxiosResponse, data) => {
        if (status == 200) {
          queryClient.setQueryData(
            [Queries.REPOSITORY_FOLDERS, data.folder.StorageCluster_id??""],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.map((item) => {
                  if (item.id === data.storageClusterId) {
                    return { ...item, color: data.color };
                  }
                  return item;
                });
              }
              return [];
            }
          );
        } else {
          ToastModel(addToast, "Erro de conexão!", "Error", "error");
        }
      },
      onError: (err: AxiosError) => {
        ToastModel(addToast, "Erro de conexão!", err.message, "error");
      },
    }
  );
  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};
