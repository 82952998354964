import React, { useEffect, useState } from "react";
import { SocketContext } from "../../services/socket";
import { CallMyVideo, CallVideo, CallVideoGrid } from './style';

const CallVideos = ({ }) => {
  const { myVideo, userVideo, callAccepted } = React.useContext(SocketContext)

  const [classNameUser, setClassNameUser] = useState(" userNotebook")
  const [classNameMy, setClassNameMy] = useState(" myNotebook")

  useEffect(() => {
    setTimeout(() => {
      if (userVideo.current) {
        if (userVideo.current.videoHeight > userVideo.current.videoWidth) {
          setClassNameUser("userNotebook")
        } else {
          setClassNameUser("userNotebook")
        }
      }

      if (myVideo.current) {
        if (myVideo.current.videoHeight && myVideo.current.videoWidth) {
          if (myVideo.current.videoHeight > myVideo.current.videoWidth) {
            setClassNameMy("myMobile")
          } else {
            setClassNameMy("myNotebook")
          }
        }
      }
    }, 1000);
  }, [callAccepted])


  return (
    <CallVideoGrid>
      <CallMyVideo className={classNameMy} playsInline muted ref={myVideo} autoPlay />
      <CallVideo className={classNameUser} playsInline ref={userVideo} autoPlay />
    </CallVideoGrid>
  )
}

export default CallVideos