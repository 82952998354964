import styled from "styled-components";

export const ContentImage = styled.div`
  display: flex;
  position: relative;
  align-self: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 16px;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  color: white;

  #profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    color: white;
    border: 1px solid ${(props) => props.theme.default.dark};
  }

  #row {
    display: flex;
    margin-top: 2px;
  }
`;

export const ButtonFile = styled.div`
  display: flex;
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 10px;
  background-color: #fff;
  color: ${(props) => props.theme.purple.dark};
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.purple.dark};
  cursor: pointer;
`;
