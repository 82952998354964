import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Modal } from "../../../../../../components";
import { ReportStylesInterface } from "../../../../../../types/project";
import { HeaderTable } from "../../../../../../util/report/reportTypes";
import AddBodyModal from "../../Modais/AddBodyModal";
import { OptionSelected } from "../../style";
import BodyItemOption from "./BodyItemOption";
import SectionOption, { ModalProps } from "./SectionOption";
import { ReportContext } from "../../../../../../contexts/ReportContext";
interface VisualBlockProps {
  header: HeaderTable;
  setHeader: Dispatch<SetStateAction<HeaderTable | undefined>>;
}

const BodyOptions = ({ header, setHeader }: VisualBlockProps) => {
    const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
  const idsBody = stylesPDF.body.map((item) => item.id);
  //Array de items
  const [items, setItems] = useState(["title", "table"].concat(idsBody));
  console.log(items);

  //id do item que usuário que mover
  const [activeId, setActiveId] = useState(null);

  //Elemento que usuário que mover
  const activeElem = activeId
    ? stylesPDF.body.find((elem) => elem.id == activeId)
    : undefined;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const lenghtBody = stylesPDF.body.length + 2;
    const lenghtBodyDefault = stylesPDF.body.length;
    if (lenghtBody == items.length) return;
    else if (lenghtBody > items.length) {
      items.push(stylesPDF.body[lenghtBodyDefault - 1].id);
      setItems(items);
    } else if (lenghtBody < items.length) {
      const newArray = [
        "table",
        "title",
        ...stylesPDF.body.map((item) => item.id),
      ];
      const diferença = items.filter((a) => !newArray.includes(a));
      diferença.forEach((item) => {
        const index = items.indexOf(item);
        if (index > -1) {
          items.splice(index, 1);
        }
      });
      setItems(items);
    }
  }, [stylesPDF]);

  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        console.log(items);

        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        // const temp = stylesPDF.body[oldIndex - 1].position;
        // stylesPDF.body[oldIndex - 1].position = stylesPDF.body[newIndex - 1].position;
        // stylesPDF.body[newIndex - 1].position = temp;
        // setStylesPDF({ ...stylesPDF });
        // setUpdate((oldUpdate) => !oldUpdate);
        const result = arrayMove(items, oldIndex, newIndex);
        console.log(result);
        handleStylesPDF({ ...stylesPDF, sortBody: result });
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  }
  const addItemRef = useRef<any>();

  const modalProps: ModalProps = {
    modal: (
      <Modal mobile={true} ref={addItemRef}>
        <AddBodyModal modal={addItemRef} />
      </Modal>
    ),
    modalItem: addItemRef,
  };
  return (
    <SectionOption modal={modalProps} title="Corpo da Página">
      <OptionSelected>
        <DndContext
          onDragStart={handleDragStart}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items}>
            {items.map((item) => {
              console.log(item);
              if (item == "title" || item == "table") {
                return (
                  <BodyItemOption
                    id={item}
                    header={header}
                    setHeader={setHeader}
                    key={item}
                    value={undefined}
                  />
                );
              }
              const itemStyle = stylesPDF.body.find(
                (object) => object.id == item
              );
              if (itemStyle)
                return (
                  <BodyItemOption
                    id={itemStyle.id}
                    header={header}
                    setHeader={setHeader}
                    key={item}
                    value={itemStyle}
                  />
                );
            })}
          </SortableContext>
          <DragOverlay>
            {activeId &&
              (activeId == "table" || activeId == "title" ? (
                <BodyItemOption
                  background={true}
                  id={activeId}
                  header={header}
                  setHeader={setHeader}
                  key={activeId}
                  value={undefined}
                />
              ) : activeElem ? (
                <BodyItemOption
                  id={activeId}
                  header={header}
                  setHeader={setHeader}
                  background={true}
                  value={activeElem}
                />
              ) : null)}
          </DragOverlay>
        </DndContext>
      </OptionSelected>
    </SectionOption>
  );
};

export default BodyOptions;
