import { AiOutlineClear } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { clearAllFiltersRepository, removeTypeFilterRepository } from "../../../../../store/actions/storageClusterAction";
import { TypeConstRedux } from "../../../../../types/redux";
import { getTypeNameMedia, pluralWords } from "../../../../../util/verification";
import { UseFilesRepository } from "../../hooks/Files/useFilesRepository";
import { UseFoldersRepository } from "../../hooks/Folders/useFoldersRepository";
import { ContainerToolbar } from "./style";

const TypeItem = ({ type, dispatch }) => {
    const onDelete = () => dispatch(removeTypeFilterRepository(type));
    return <div className="item-type">
        <p className="item-text">{getTypeNameMedia(type)}</p>
        <IoIosClose onClick={onDelete} className="item-close" />
    </div>
}
const FilterToolbar = () => {
    const { types } = useSelector((store: TypeConstRedux) => store.repository.filters);
    const hasFilter = types.length > 0;
    const dispatch = useDispatch();
    const { files } = UseFilesRepository();
    const { folders } = UseFoldersRepository();
    const resultsFound = files.length + folders.length;
    const clearAll = () => dispatch(clearAllFiltersRepository());
    return hasFilter ?
        <div className="flex-column">
            <p style={{ margin: "0" }}>
                <strong> {resultsFound}</strong> {`  ${pluralWords("resultado", resultsFound)} ${pluralWords("encontrado", resultsFound)}`}
            </p>
            <ContainerToolbar>
                <div className="card flex-row container-type">
                    <p>Tipos: </p>
                    {types.map((item) => <TypeItem dispatch={dispatch} key={item} type={item} />)}

                </div>

                <button className="button-clear" onClick={clearAll}>Limpar <AiOutlineClear /></button>
            </ContainerToolbar>
        </div> : null;
}

export default FilterToolbar;