import styled from "styled-components";

interface styledProps  {
  height?: any | "auto",
  width?: any | "auto"
}

export const Container = styled.div<styledProps>`
  position: relative;
  height: ${props => props.height};
  width: ${props => props.width};
`;
