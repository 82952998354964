import { Fragment, useState } from "react";
import Toggle from "../../../../../components/Toggle";
import { HorizontalRow, VerticalRowItems } from "../../style";

function dump() {
    return;
}

const ToogleSilence = (props) => {
    const { onEnable = dump, onDisable = dump, title, lastLine, value } = props
    const [toogle, setToogle] = useState(value ? value : false);
    const [disabled, setDisabled] = useState(false);

    const changeToogle = () => {
        const newToogleValue = !toogle;

        setToogle(newToogleValue);

        if (newToogleValue) {
            onEnable()
        } else {
            onDisable()
        }
    }
    return (
        <Fragment>
            <HorizontalRow noHover={true} justify="space-between" lastLine={lastLine}>
                <VerticalRowItems noHover={true} isSingle>
                    {title}
                    <div className="line">{toogle ? 'Ativado' : 'Desativado'}</div>
                </VerticalRowItems>
                <Toggle hamburguer={toogle} value={toogle} onChange={() => changeToogle()} disabled={disabled} />
            </HorizontalRow>
        </Fragment>
    )
}

export default ToogleSilence