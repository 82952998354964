import styled from "styled-components";

export const MainLoad = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  color: ${(props) => props.theme.texts.purpleDark};
  background-color: ${props => props.theme.backgrounds.white};

  #loading {
    margin: 6px 0px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(4, min-content) 1fr;
  row-gap: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: ${props => props.theme.backgrounds.purpleLightI};

 .btn-content{
   display: flex;
   flex-direction: column;
   justify-content: end;
   row-gap: 12px;
 }
  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background-color: ${props => props.theme.backgrounds.white};
  }

  h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: "inter-medium", sans-serif;
  }

  #name {
    color: ${(props) => props.theme.texts.darkerGrey};
    font-size: 19px;
  }

  #nick {
    color: ${(props) => props.theme.texts.darkerGrey};
    font-size: 14px;
  }

  #img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
    padding-inline: 0.5rem;
  }

  .voterData {
    grid-template-columns: auto auto;

    .title-item{
      grid-area: title-item;
    }

    .number {
      grid-area: number;
    }

    .zone {
      grid-area: zone;
      margin-left:1rem ;
    }

    .section {
      grid-area: section;
    }

    grid-template-areas:
      "title-item title-item"
      "number number"
      "section zone";
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  background: ${props => props.theme.backgrounds.white};
  margin: 0;
  padding: 1rem;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;

  h3 {
    margin: 0px;
    font-size: ${(props) => props.theme.fonts.text_lg};
    color: ${(props) => props.theme.texts.purpleDark};
  }

  #icon {
    margin-right: 0.5rem;
    cursor: pointer;
    color: ${(props) => props.theme.texts.purpleDark};
  }
`;

interface StyledProps {
  data?: any;
}

export const Row = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 45px;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid ${(props) => props.theme.bordes.lightGreyI};
  color: ${(props) => props.theme.texts.darkerGreyI};

  .end {
    justify-self: end;
    color: ${(props) => props.theme.texts.darkerGrey};
  }
`;

export const HorizontalMidia = styled.div`
  display: flex;
  max-width: 350px;
`;

export const Button = styled.button`
  display: grid;
  grid-template-columns: 25px 1fr;
  width: 100%;
  padding: 1rem;
  font-size: 14px;
  background-color: ${(props) => props.theme.backgrounds.white};
  color: ${(props) => (props.color ? props.theme.hover.green : props.theme.hover.red)};
  text-align: left;
  transition: all 300ms ease-in;
  align-self: end;

  #icon {
    font-size: 18px;
  }

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;

export const Item = styled.div<StyledProps>`
  display: grid;
  background-color: ${props => props.theme.backgrounds.white};
  grid-template-rows: auto;
  font-family: "inter-medium", sans-serif;
  padding: 1rem;

  .title-item{
    color: ${(props) => props.theme.texts.purpleDarkTitle};
    margin: 0;
    font-size: 16px;
    font-weight: normal;
  }
`;

export const NoInfo = styled.div<StyledProps>`
  display: ${(props) => (props.data ? "none" : "block")};
  text-align: center;
  padding: 0.5rem;
  font-size: 14px;
  color: ${(props) => props.theme.default.darker};
  font-family: "inter", sans-serif;
`;

export const MidiaContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 60px;
  background-color: ${(props) => props.theme.purple.dark};
  color: #fff;
  font-size: 28px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  
  .img-midia {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    object-fit: cover;
  }

  .blur {
    filter: blur(1px);
  }
`;
