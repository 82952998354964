import { CallOption, CallOptionsList } from './style';


import { useContext } from 'react';
import {
    BsCameraVideo, BsCameraVideoOff, FiMic,
    FiMicOff, FiPhoneOff
} from '../../assets/icons';
import { LogoNoturno } from '../../assets/images';
import { SocketContext } from '../../services/socket';

const CallOptions = ({ onCloseCall, muted, setMuted, cameraOff, setCameraOff }) => {
    const { mutedAudio, mutedVideo } = useContext(SocketContext);

    return (
        <CallOptionsList>
            <img className='icon_title hidden' src={LogoNoturno} />
            <CallOption
                onClick={() => { mutedAudio(muted); setMuted(!muted) }}>
                {muted ? <FiMicOff className='icon' color="#383E44" /> : <FiMic className='icon'  color="#383E44" />}
            </CallOption>
            <CallOption
                onClick={() => { mutedVideo(cameraOff),setCameraOff(!cameraOff) }}>
                {cameraOff ? <BsCameraVideoOff className='icon' color="#383E44" strokeWidth={0.3} /> : <BsCameraVideo className='icon'  color="#383E44" strokeWidth={0.3} />}
                {/*  <FiCamera size={32} color="#383E44" /> */}
            </CallOption>
            <CallOption
                className='noMargin'
                color="#CC3939"
                onClick={onCloseCall}
            >
                <FiPhoneOff className='icon' color="#441010" />
            </CallOption>
        </CallOptionsList>
    );
};

export default CallOptions;