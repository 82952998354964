import { AxiosResponse } from "axios";
import { RefObject, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { InputControlled } from "../../../../../components/Input";
import { ModalRef } from "../../../../../components/Modal";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import {
  StorageClusterService,
} from "../../../../../services/requests/storage-cluster";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import { Container } from "./styles";

interface ColumnsProps {
  modal: RefObject<ModalRef>;
}

const ModalNewCluster = ({ modal }: ColumnsProps) => {
  const [nameCluster, setNameCluster] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const folder = useSelector(
    (state: TypeConstRedux) => state.repository.folder
  );
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation(
    (dado) => StorageClusterService.post(dado),
    {
      onSuccess: ({ status, data }: AxiosResponse) => {
        if (status == 201) {
          queryClient.setQueryData(
            [Queries.REPOSITORY_FOLDERS, data.StorageCluster_id ?? ""],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return [...prevData, { ...data, usersSharing: [] }];
              }
              return [data];
            }
          );
          modal.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  useEffect(() => {
    if (modal.current?.open) {
      inputRef.current?.focus();
    }
  }, [modal]);
  function saveChange() {
    let data: any = { name: nameCluster };

    if (folder.length > 0) {
      data = { ...data, storageClusterId: folder[folder.length - 1].id };
    }

    mutate(data);
  }

  const handleName = (e) => {
    if(e.target.value.length > 100) return;
    setNameCluster(e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      saveChange();
    }
  };

  return (
    <ModalContainer
      loadRequisition={isLoading}
      padding={false}
      $minHeight={false}
      height="150px"
      onButtonRight={saveChange}
      buttonConfirmation={true}
      title="Nova pasta"
      onClick={() => modal.current?.close()}
      buttonBack={true}
    >
      <Container onClick={(e) => e.stopPropagation}>
        <InputControlled
          onKeyPress={handleKeyPress}
          ref={inputRef}
          value={nameCluster}
          onChange={handleName}
          placeholder="Nome"
          maxLength={100}
        />
      </Container>
    </ModalContainer>
  );
};

export default ModalNewCluster;
