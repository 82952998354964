import styled from "styled-components";
import { Conversation } from "../../Conversations/Folder/ItemFolder/style";


export const Header = styled.div`
  display: grid;
  grid-template-columns: 70% auto auto;
  grid-gap: 5px;
  align-items: center;

  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.highlights.default};
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;

    #icon {
      color: white;
      font-size: 20px;
    }
  }
`;


export const ListConversation = styled(Conversation)`
  margin-top:1rem;
  padding: 0;
  background-color: ${props => props.theme.backgrounds.white};

  .sz-16{
    font-size: 16px !important;
  }
  
`;