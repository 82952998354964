import { createGlobalStyle } from "styled-components";
import InterBold from "./assets/fonts/InterWoff/Inter-Bold.woff";
import InterLight from "./assets/fonts/InterWoff/Inter-Light-BETA.woff";
import InterMedium from "./assets/fonts/InterWoff/Inter-Medium.woff";
import Inter from "./assets/fonts/InterWoff/Inter-Regular.woff";
import InterSemiBold from "./assets/fonts/InterWoff/Inter-SemiBold.woff";
import Montserrat from "./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf";
import PoppinsBold from "./assets/fonts/Poppins/Poppins-Bold.ttf";
import PoppinsLight from "./assets/fonts/Poppins/Poppins-Light.ttf";
import PoppinsMedium from "./assets/fonts/Poppins/Poppins-Medium.ttf";
import PoppinsRegular from "./assets/fonts/Poppins/Poppins-Regular.ttf";
import PoppinsSemiBold from "./assets/fonts/Poppins/Poppins-SemiBold.ttf";
import RobotoLight from "./assets/fonts/Roboto/Roboto-Light.ttf";
import Roboto from "./assets/fonts/Roboto/Roboto-Regular.ttf";
import "rc-dropdown/assets/index.css";

export const GlobalStyle = createGlobalStyle`

  @font-face {  
    font-family: 'inter';  
    font-display: swap; /* Ler o próximo ponto */ 
    unicode-range: U+000-5FF; /* Fazer apenas o download dos glifos latinos */ 
    src: local('inter'), url(${Inter}) format('woff2');
  }

  @font-face {
    font-family: 'Inter-light';
    font-display: swap; /* Ler o próximo ponto */ 
    unicode-range: U+000-5FF; /* Fazer apenas o download dos glifos latinos */ 
    src: local('Inter-light'), url(${InterLight}) format('woff2');

  }

 
  @font-face {
    font-family: 'inter-medium';
    font-display: swap; /* Ler o próximo ponto */ 
    unicode-range: U+000-5FF; /* Fazer apenas o download dos glifos latinos */ 
    src: local('inter-medium'), url(${InterMedium}) format('woff2');

  }

  @font-face {
    font-family: 'inter-semi-bold';
    font-display: swap; /* Ler o próximo ponto */ 
    unicode-range: U+000-5FF; /* Fazer apenas o download dos glifos latinos */ 
    src: local('inter-semi-bold'), url(${InterSemiBold}) format('woff2');

  }

  @font-face {
    font-family: 'inter-bold';
    font-display: swap; /* Ler o próximo ponto */ 
    unicode-range: U+000-5FF; /* Fazer apenas o download dos glifos latinos */ 
    src: local('inter-bold'), url(${InterBold})  format('woff2');
  }

  @font-face {
    font-family: 'monteserrat';
    src:  url(${Montserrat}) format('truetype');
  }

  @font-face {
    font-family: 'roboto';
    src:  url(${Roboto}) format('truetype');
  }

  @font-face {
    font-family: 'roboto-light';
    src:  url(${RobotoLight}) format('truetype');
  }

@font-face {
  font-family: 'Poppins light';
  src: url(${PoppinsLight}) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: url(${PoppinsRegular}) format('truetype');
}

@font-face {
  font-family: 'Poppins medium';
  src: url(${PoppinsMedium}) format('truetype');
}

@font-face {
  font-family: 'Poppins semi-bold';
  src: url(${PoppinsSemiBold}) format('truetype');
}

@font-face {
  font-family: 'Poppins bold';
  src: url(${PoppinsBold}) format('truetype');
}

  .icon-arrow-root{
    transition: transform 0.4s linear;
    cursor: pointer;

  }
  .down-root {
    transform: rotate(0deg);
  }

  .up-root {
    transform: rotate(-90deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  .flex-row{
    display: flex;
    flex-direction: row;
  }

  .flex-column{
    display: flex;
    flex-direction: column;
  }

  .flex-center{
    justify-content: center;
    align-items: center;
  }


  .rc-dropdown-close{
    z-index: -1;
  }
  .rc-dropdown-open{ 
    z-index: 9999;
  }
  .in-production{
    opacity: 0.2;
    pointer-events:none;
  }
  html, body,#root {
    -webkit-font-smoothing: antialiased;
    -mos-osx-font-soothing: grayscale;
    text-rendering: optimizeLegibility;
 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /*pode ser transparent também*/
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: ${(props) => props.theme.backgrounds.white};
        font-family: 'inter', sans-serif;

        scrollbar-color:${(props) => props.theme.highlights.default};
        scrollbar-width: thin;
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing: antialiased;
        
    }
    .background-button-animation {
    border-radius: 50%;
    height: fit-content;
    transition: background-color 0.5s ease;
    :hover {
      /* Estilos quando o mouse passa por cima do ícone */
      background-color: ${(props) => props.theme.hover.buttonHover};
      :active {
       background-color: ${(props) => props.theme.hover.buttonActive};
     }
      :not(:active) {
        background-color: ${(props) => props.theme.hover.buttonHover};
      }
    }
   
  }
    .pd-1{
      padding: 1rem 1rem;
    }

    button{
      font-family: 'inter', sans-serif;
    }

    .card{
      border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      transition: background 0.5s ease-in-out;
    }
    .card-hover{
      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.hover.lightGrey};
      }
    }
    .vertical-gradient{
      background: linear-gradient(to bottom left, ${(props) =>
        props.theme.backgrounds.buttonModalGroup1} , ${(props) =>
        props.theme.backgrounds.buttonModalGroup2});
    }

    .horizontal-gradient{
      background: linear-gradient(to left, ${(props) =>
        props.theme.backgrounds.buttonModalGroup1} , ${(props) =>
        props.theme.backgrounds.buttonModalGroup2}) !important;
    }

    .horizontal-gradient-login{
      background: linear-gradient(to left, ${(props) =>
        props.theme.backgrounds.buttonModalGroup1Login} , ${(props) =>
        props.theme.backgrounds.buttonModalGroup2Login}) !important;
    }
    .text-center{
      text-align: center;
    }

    &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px #D5D5D5;
          border: none;
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: white;
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${(props) => props.theme.backgrounds.darkI};
        }

    button{
      border: none;
      cursor: pointer;
      background: transparent;
      &:focus {
        outline:none;
      }
      transition: all 200ms ease-in 0s;
    }
    
`;
