import { useEffect, useRef } from "react";
import { AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { IoExitOutline } from "react-icons/io5";
import { TbLock } from "react-icons/tb";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  IoIosArrowBack,
  IoNotificationsOutline,
  RiContactsLine,
} from "../../../assets/icons";
import { NavBar } from "../../../components";
import AddContact from "../../../components/AddContact";
import ModalExit from "../../../components/Modal/ModalExit";
import { logout } from "../../../services/auth";
import { clearReducers } from "../../../store/actions/cleanAction";
import DashboardS from "../Dashboard/DashS";
import Contacts from "./Contacts";
import SetImage from "./GeneralSettings/Wallpaper/SetImage";
import GeneralSettings from "./GeneralSettings/index";
import { NotificationsAndSounds } from "./Notification";
import Privacy from "./Privacy";
import User from "./User";
import { MainSettings } from "./style";
import { TypeConstRedux } from "../../../types/redux";
import { OptionConfig } from "../../../types/project";
import { setOptionConfig } from "../../../store/actions/configAction";

const Settings = (props) => {
  const { push } = useHistory();
  const modalRef = useRef<any>(null);
  const actualConfig = useSelector(
    (state: TypeConstRedux) => state.config.option
  );
  const dispatch = useDispatch();

  function CurrentConfig() {
    switch (actualConfig) {
      case OptionConfig.MY_PERFIL:
        return <User />;
      case OptionConfig.CONTACTS:
        return <Contacts />;
      case OptionConfig.NOTIFICATION:
        return <NotificationsAndSounds />;
      case OptionConfig.PRIVACY:
        return <Privacy />;
      case OptionConfig.THEME:
        return <GeneralSettings />;
      default:
        return null;
    }
  }

  const routes = [
    {
      text: "Meu perfil",
      Icon: AiOutlineUser,
      url: "/settings/abare",
      component: User,
    },
    { url: "/settings/contacts/user/:id", component: User, exact: true },
    { url: "/settings/contacts/add", component: AddContact, exact: true },
    {
      text: "Contatos",
      Icon: RiContactsLine,
      url: "/settings/contacts",
      component: Contacts,
    },
    {
      text: "Temas e Convite",
      Icon: AiOutlineSetting,
      url: "/settings/general",
      component: GeneralSettings,
      exact: true,
    },
    { url: "/settings/general/wallpaper", component: SetImage },
    {
      text: "Privacidade",
      Icon: TbLock,
      url: "/settings/privacy",
      component: Privacy,
    },
    {
      text: "Notificações",
      Icon: IoNotificationsOutline,
      url: "/settings/notifications",
      component: NotificationsAndSounds,
    },
  ];

  // useEffect(() => {
  //   console.log("Teste oprtio");
  //   const handlePopstate = (event: PopStateEvent) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     console.log("hndufhahfoihnafihfif7jidoifjo");
  //     // Verifique se o modal está aberto antes de fechá-lo
  //     if (actualConfig) {
  //       console.log("FDPGDO")
  //       dispatch(setOptionConfig(null));
  //       window.history.replaceState(null, "", window.location.href);

  //     }
  //   };

  //   // Adicione um ouvinte de eventos para o botão de voltar do navegador
  //   window.addEventListener("popstate", handlePopstate);

  //   // Remova o ouvinte de eventos quando o componente for desmontado

  // }, [actualConfig]);
  return (
    <MainSettings onClick={(e) => e.stopPropagation()}>
      <CurrentConfig />
    </MainSettings>
  );
};

export default Settings;
