import { Size, TableCell } from "pdfmake/interfaces";

import {
  EnumBackgroundTable,
  EnumBorderTable,
  EnumOrdering,
  EnumPositionHorizontal,
  ReportEnum,
  ReportOptionsInterface,
  ReportStylesInterface,
  ReportTypeFilter,
  TypeDataTable,
} from "../../types/project";
import { getBioSex } from "../verification";
import { HeaderTable } from "./reportTypes";

export interface reportType {
  type?: ReportEnum;
}

const getTitle = (type: ReportEnum) => {
  switch (type) {
    case ReportEnum.EVENT_PARTICULAR:
      return "Relatório de eventos particular";
    case ReportEnum.EVENT_GROUP:
      return "Relatório de eventos";
    case ReportEnum.CONTACTS_BIRTHDAYS:
      return "Relatório de aniversariantes";
    case ReportEnum.CONTACTS_ALL:
      return "Relatório de contatos";
    case ReportEnum.GROUP_ALL:
      return "Relatório geral de grupos";
    case ReportEnum.GROUP_MEMBERS:
      return "Relatório de membros de grupo";
    case ReportEnum.EVENT_CONFIRMATIONS:
      return "Relatório de confirmações";
    default:
      return "";
  }
};

const getOptions = (type: ReportEnum): ReportOptionsInterface => {
  switch (type) {
    case ReportEnum.EVENT_PARTICULAR:
      return {
        ...defaultOptionsPDF,
        filters: [],
        filtersOperations: [],
        requiredId: false,
        requiredPeriod: true,
        message: "Selecione o período para a pesquisa",
        ordering: {
          column: "dateStart",
          type: EnumOrdering.CRESCENT,
        },
      };
    case ReportEnum.EVENT_GROUP:
      return {
        ...defaultOptionsPDF,

        filters: [{ key: ReportTypeFilter.GROUP_ID, values: [] }],
        requiredId: false,
        filtersOperations: [],
        requiredPeriod: true,
        ordering: {
          column: "dateStart",
          type: EnumOrdering.CRESCENT,
        },
        message: "Selecione os grupo e o período para a pesquisa",
      };
    case ReportEnum.CONTACTS_BIRTHDAYS:
      return {
        ...defaultOptionsPDF,
        filters: [],
        requiredId: false,
        filtersOperations: [],
        ordering: {
          column: "name",
          type: EnumOrdering.CRESCENT,
        },
        requiredPeriod: false,
        message: undefined,
      };

    case ReportEnum.CONTACTS_ALL:
      return {
        ...defaultOptionsPDF,
        filters: [],
        filtersOperations: [],
        ordering: {
          column: "name",
          type: EnumOrdering.CRESCENT,
        },
        requiredId: false,
        requiredPeriod: false,
        message: undefined,
      };
    case ReportEnum.GROUP_ALL:
      return {
        ...defaultOptionsPDF,
        filters: [],
        filtersOperations: [],
        ordering: {
          column: "group",
          type: EnumOrdering.CRESCENT,
        },
        requiredId: true,
        requiredPeriod: false,
        message: undefined,
      };
    case ReportEnum.GROUP_MEMBERS:
      return {
        ...defaultOptionsPDF,
        filters: [],
        filtersOperations: [],
        ordering: {
          column: "name",
          type: EnumOrdering.CRESCENT,
        },
        requiredPeriod: false,
        requiredId: true,
        message: "Selecione um grupo para a pesquisa",
      };
    case ReportEnum.EVENT_CONFIRMATIONS:
      return {
        ...defaultOptionsPDF,
        filters: [],
        filtersOperations: [],
        requiredPeriod: true,
        requiredId: true,
        ordering: {
          column: "name",
          type: EnumOrdering.CRESCENT,
        },
        message: undefined,
      };
    default:
      return defaultOptionsPDF;
  }
};

const getWidthsTable = (header: HeaderTable): Size[] => {
  const widths = new Array();
  const columns = Object.keys(header.itens);
  columns.forEach((column) => {
    if (header.itens[column].checked) {
      widths.push(header.itens[column].width);
    }
  });

  return widths;
};

const getHeaderTable = (header: HeaderTable): TableCell[] => {
  const columns = Object.keys(header.itens);
  const finished = new Array();
  columns.forEach((column) => {
    if (header.itens[column].checked) {
      finished.push({
        ...header.itens[column],
        text: header.itens[column].label,
      });
    }
  });
  return finished;
};

const getDataTable = (data: any[], header: HeaderTable): TableCell[][] => {
  const rows = new Array();
  for (let d of data) {
    const row = new Array<TableCell>();
    for (var column in header.itens) {
      if (header.itens[column].checked) {
        if (d[column] !== undefined) {
          let dataPush = { ...header.itens[column] };
          switch (header.itens[column].type) {
            case TypeDataTable.DATA:
              dataPush = {
                ...dataPush,
                text: new Date(d[column]).toLocaleString(),
              };
              break;
            case TypeDataTable.DATA_BIRTHDAY:
              dataPush = {
                ...dataPush,
                text: new Date(d[column]).toLocaleDateString(),
              };
              break;
            case TypeDataTable.BOOLEAN:
              const textPush = d[column] ? "Sim" : "Não";
              dataPush = {
                ...dataPush,
                text: textPush,
              };
              break;
            case TypeDataTable.PRESENCE:
              dataPush = {
                ...dataPush,
                text: d[column] === 1 ? "Confirmada" : "Não Confirmada",
              };
              break;
            case TypeDataTable.SPECIAL_BIO_SEXY:
              dataPush = {
                ...dataPush,
                text: getBioSex(d[column]),
              };
              break;
            default:
              dataPush = {
                ...dataPush,
                text: d[column],
                fontSize: 11,
              };
              break;
          }
          row.push(dataPush);
        } else {
          row.push("");
        }
      }
    }
    rows.push(row);
  }
  return rows;
};

const findMarginHeader = (style: ReportStylesInterface): number => {
  let big = style.margins[0];
  let line = 0;
  style.headerContent[0].map((item) => {
    let height = 0;
    height += item.margin[1];
    height += item.margin[3];

    if (item.text) {
      height += item.fontSize * 1.5;
    } else {
      height += item.fit[1];
    }
    line += height;
  });

  if (line > big) big = line;
  line = 0;

  style.headerContent[1].map((item) => {
    let height = 0;
    height += item.margin[1];
    height += item.margin[3];

    if (item.text) {
      height += item.fontSize * 1.5;
    } else {
      height += item.fit[1];
    }
    line += height;
  });

  if (line > big) big = line;
  line = 0;

  style.headerContent[2].map((item) => {
    let height = 0;
    height += item.margin[1];
    height += item.margin[3];

    if (item.text) {
      height += item.fontSize * 1.5;
    } else {
      height += item.fit[1];
    }
    line += height;
  });

  if (line > big) big = line;

  return big;
};

const findMarginFooter = (style: ReportStylesInterface): number => {
  let big = style.margins[2];
  let line = 0;
  const { dateCreated, pagination } = style.footer;
  const heightDate =
    dateCreated.margin!![1] +
    dateCreated.margin!![3] +
    dateCreated.fontSize!! * 1.5;
  const heightPagination =
    pagination.margin!![1] +
    pagination.margin!![3] +
    pagination.fontSize!! * 1.5;

  if (style.footer.dateCreated.position == style.footer.pagination.position) {
    if (big > heightDate + heightPagination) {
      return big;
    } else {
      return heightDate + heightPagination;
    }
  } else {
    if (big > heightDate && big > heightPagination) {
      return big;
    }
    if (heightDate > heightPagination) {
      return heightDate;
    }
    return heightPagination;
  }
};

const getStyleBorderTable = (type: EnumBorderTable, color: string): Object => {
  switch (type) {
    case EnumBorderTable.NO_BORDER:
      return {
        hLineWidth: function (i, node) {
          return 0;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
      };
    case EnumBorderTable.ONLY_HEADER:
      return {
        hLineWidth: function (i, node) {
          return i === 1 ? 2 : 0;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
        hLineColor: function (i, node) {
          return color;
        },
        vLineColor: function (i, node) {
          return color;
        },
      };
    case EnumBorderTable.ONLY_HORIZONTAL:
      return {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length
            ? 0
            : i === 1
            ? 2
            : 0.5;
        },
        vLineWidth: function (i, node) {
          return 0;
        },
        hLineColor: function (i, node) {
          return color;
        },
        vLineColor: function (i, node) {
          return color;
        },
      };
    default:
      return {
        hLineWidth: function (i, node) {
          return 1;
        },
        vLineWidth: function (i, node) {
          return 1;
        },
        hLineColor: function (i, node) {
          return color;
        },
        vLineColor: function (i, node) {
          return color;
        },
      };
  }
};

const getBackgroundTable = (
  type: EnumBackgroundTable,
  color: string
): Object => {
  switch (type) {
    case EnumBackgroundTable.NO_BACKGROUND:
      return {
        fillColor: function (rowIndex, node, columnIndex) {
          return null;
        },
      };
    case EnumBackgroundTable.ONLY_HEADER:
      return {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex === 0 ? color : null;
        },
      };
    case EnumBackgroundTable.ZEBRA:
      return {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex % 2 === 0 ? color : null;
        },
      };
    default:
      return {
        fillColor: function (rowIndex, node, columnIndex) {
          return null;
        },
      };
  }
};

const defaultStylePDF: ReportStylesInterface = {
  lineHeight: "auto",
  visibleTable: true,
  visibleTitle: true,
  orientation: "portrait",
  alignmentTable: "justify",
  marginTable: [0, 0, 0, 0],
  sortBody: undefined,
  title: {
    text: "",
    alignment: "center",
    fontSize: 18,
    color: "#000",
    bold: true,
    italics: false,
    margin: [0, 4, 4, 4],
  },
  page: "A4",
  margins: [25, 40, 25, 40],
  signature: true,
  styleBorder: EnumBorderTable.DEFAULT,
  background: EnumBackgroundTable.ONLY_HEADER,
  colorBorder: "#000",
  colorBackground: "#ccc",
  headerContent: [
    [
      {
        image: "chapeu",
        alignment: "left",
        fontSize: 14,
        color: "#000",
        bold: false,
        italics: false,
        margin: [0, 12, 0, 4],
        fit: [60, 60],
      },
    ],
    [
      {
        text: "Nheengatu Tech Solutions",
        alignment: "center",
        fontSize: 14,
        color: "#000",
        bold: true,
        italics: true,
        margin: [0, 24, 0, 8],
      },
    ],
    [
      {
        image: "Logo",
        alignment: "right",
        fontSize: 14,
        color: "#000",
        bold: false,
        italics: false,
        margin: [0, 8, 0, 4],
        fit: [50, 50],
      },
    ],
  ],
  body: [],
  footerContent: [[], [], []],
  footer: {
    pagination: {
      text: "",
      alignment: "center",
      fontSize: 9,
      color: "#000",
      position: EnumPositionHorizontal.CENTER,
      margin: [0, 10, 0, 0],
    },
    dateCreated: {
      text: `${new Date().toLocaleString()}`,
      alignment: "left",
      fontSize: 9,
      color: "#000",
      position: EnumPositionHorizontal.LEFT,

      margin: [0, 10, 0, 0],
    },
  },
  // { text: "hahahaha", margin: [0, 0, 0, 0], position:  1}, { text: "hohohoho", margin: [0, 0, 0, 0] , position:0}
};

const milissegundos_por_dia = 1000 * 60 * 60 * 24;
const dateActual = new Date();
export const defaultDatePeriod: [Date, Date] = [
  new Date(dateActual.getTime() - 5 * milissegundos_por_dia),
  new Date(dateActual.getTime() + 5 * milissegundos_por_dia),
];

const defaultOptionsPDF: ReportOptionsInterface = {
  requiredPeriod: true,
  key: null,
  filterUnion: false,
  filtersOperations: [],
  filters: [],
  requiredId: false,
  datePeriod: defaultDatePeriod,
  message: undefined,
  generalSearch: "",
  ordering: {
    column: undefined,
    type: EnumOrdering.CRESCENT,
  },
};

export const ReportFunctions = {
  getDataTable,
  getTitle,
  getHeaderTable,
  getWidthsTable,
  getOptions,
  getStyleBorderTable,
  getBackgroundTable,
  findMarginHeader,
  findMarginFooter,
  defaultStylePDF,
  defaultOptionsPDF,
  defaultDatePeriod,
};
