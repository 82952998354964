import { Container } from "./style";

interface EmptyContentProps {
  message: string;
  onAction?: () => void;
  messageButton?: string;
  padding?: boolean;
}
export function EmptyContent({
  message,
  onAction,
  messageButton,
  padding = true,
}: EmptyContentProps) {
  const isButtonVisible = onAction && messageButton;
  return (
    <Container $padding={padding}>
      <span className="message">{message}</span>
      {isButtonVisible ? (
        <button onClick={onAction}>{messageButton}</button>
      ) : null}
    </Container>
  );
}
