import styled from "styled-components";
import {
  colapsedSchedule,
  notebook,
  tablet,
} from "../../../../components/NavBar/style";

interface styledProps {
  rightContent?: any;
}

export const AsideContent = styled.aside<styledProps>`
  display: flex;
  /* transition: width 0.5s ease; */

  flex-direction: column;
  display: ${({ rightContent }) => (rightContent ? "block" : "none")};
  width: ${({ rightContent }) => (rightContent ? "350px" : "0px")};
  background: rgba(112, 5, 64, 0.05);
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  ${({ rightContent }) =>
    rightContent
      ? `overflow: hidden;
  overflow-y: auto;`
      : `  overflow: visible;
  `}
  overflow: hidden;

  /* min-width: 400px; */

  @media only screen and (max-width: ${tablet}) {
    min-width: 100%;
  }
`;

export const ChatContainer = styled.div<styledProps>`
  display: flex;
  box-sizing: border-box;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  overflow: hidden;
  background-color: ${({ theme }) => theme.backgrounds.default};
  border-radius: 14px;

  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  @media only screen and (max-width: ${tablet}) {
    border-radius: 14px;
  }
  #noContent {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.backgrounds.white};
    flex: 1;
    display: flex !important;
    border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
    border-radius: 14px;
    box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  }
  .informations-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #main-chat {
    margin-right: ${({ rightContent, theme }) =>
      rightContent ? theme.general.column_gap : "0px"};
    border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
    background-color: ${({ theme }) => theme.backgrounds.white};
    border-radius: 14px;
    box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 55px 1fr auto;
    overflow: hidden;
    flex: 1;
  }

  @media only screen and (max-width: ${notebook}) {
    grid-template-columns: 1fr min-content;
    ${AsideContent} {
      display: ${(props) => !props.rightContent && "none"};
    }
  }

  @media only screen and (max-width: ${tablet}) {
    #main-chat {
      display: ${(props) => props.rightContent && "none"};
    }
  }
`;
