import { useRef, useState } from "react";
import { AiFillCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { PromoteAdd } from "..";
import { BsPersonPlus, IoIosArrowBack } from '../../../../../../assets/icons';
import { Colors, Input, Modal } from "../../../../../../components";
import { postGroupRole } from "../../../../../../services/requests/group-role";
import { updateGroupForAllUsers } from "../../../../../../store/actions/messages/chatAction";
import { TProfileGroup } from "../../../../../../types/project";
import { TypeConstRedux } from "../../../../../../types/redux";
import { getClientId } from "../../../../../../util/verification";
import { Back, Container, Edit, Participants, Row } from './style';

type TProps = {
    groupInfo: TProfileGroup,
    updateGroupForAllUsers: (group: TProfileGroup) => void;
}

const AddRole = (props: TProps) => {
    const { groupInfo, updateGroupForAllUsers } = props;
    const modalAddParticipants = useRef<any>();
    const [participants, setParticipants] = useState<any>([])
    const { addToast } = useToasts();
    const { goBack } = useHistory();

    const remove = (id) => {
        setParticipants(participants.filter(item => item.id != id))
    }

    const createRole = (event) => {
        event.preventDefault()
        const { role, color } = event.target.elements
        if (!(role.value.trim() == "")) {
            var ids: Array<any> = [];
            participants.map((user) => {
                ids.push(user.id)

            })
            postGroupRole(groupInfo.id, { name: role.value, active: 1, color: color.value, userIds: ids }).then((res) => {
                if (res.status == 200) {
                    const message = res.data.message.pt
                    addToast({
                        title: message,
                        description: `o grupo '${role.value}' foi adicionado com sucesso.`

                    },
                        { appearance: 'success' });


                    groupInfo.roles = [...groupInfo.roles, { users: participants, role: res.data.result[0] }]
                    updateGroupForAllUsers(groupInfo)
                    goBack()
                }
            })
        } else {
            addToast({
                title: 'Preencha todos os campos',
                description: "Nome do cargo nao pode ser vazio!"
            },
                { appearance: 'warning' });
        }
    }
    return (
        <Container>
            <form onSubmit={createRole}>
                <Back>
                    <IoIosArrowBack size={22} id="icon-back" onClick={() => goBack()} />
                    <h3>Cadastrar função</h3>
                    <button type="submit" >
                        <AiFillCheckCircle size={28}  id="check" />
                    </button>
                </Back>
                <Edit>
                    <Row>
                        <label id="label-role" htmlFor="role">Nome do cargo</label>
                        <Input name="role" required maxLength={40} type="text" placeholder="Digite o nome aqui..." autoComplete="off" />
                    </Row>
                    <div id="hr" />
                    <Row className="pd-b-0">
                        <label>Selecione uma cor</label>
                        <Colors />
                    </Row>
                    <div id="hr" />
                    <Row className="pd-b-0">
                        <label>Membros</label>
                    </Row>
                    <Participants onClick={() => modalAddParticipants.current.open()}>
                        <div className="add">
                            <BsPersonPlus id="icon" />
                        </div>
                        <div className="add-name">Promover Membros</div>
                    </Participants>
                    {participants != null && participants.map(({ name, photoUrl, id }, i) => (
                        <Participants key={i}>
                            <img alt="Perfil" src={photoUrl} />
                            <div className="name">{name}</div>
                            <AiOutlineCloseCircle className="remove" onClick={() => remove(id)} />
                        </Participants>
                    ))}
                </Edit>
                <Modal ref={modalAddParticipants}>
                    <PromoteAdd groupInfo={groupInfo} modal={modalAddParticipants} participants={participants} setParticipants={setParticipants} roleId={null} updateMembersGroup={null} />
                </Modal>
            </form>
        </Container>
    );
}

const mapStateToProps = (state: TypeConstRedux) => {
    return {
        groupInfo: state.chat.globalMessages[getClientId(state.chat.personal)].client,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateGroupForAllUsers
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(AddRole);