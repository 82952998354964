import { memo, useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { RiShareForwardFill } from "react-icons/ri";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "../../../../../../components";
import ModalDropMessage from "../../../../../../components/Modal/ModalDropMessage";
import { dropMessage } from "../../../../../../store/actions/messages/messageAction";
import { TypeDeleteMessage } from "../../../../../../types/project";
import { TypeConstRedux } from "../../../../../../types/redux";
import { getClientId, personalTemp } from "../../../../../../util/verification";
import Forward from "../../../ListConversation/Forward";
import { Container } from "./style";

const SelectMessages = (props) => {
    const { setSelectMessages, test, setTest, managerChat, clientId, dropMessage, abare } = props;
    const messages = managerChat[clientId].messages.filter((message) => test[message.msg_id]);
    const { ME, ALL } = TypeDeleteMessage;
    const modalRef = useRef<any>(null);
    const modalForward = useRef<any>(null);

    const [all, setAll] = useState<boolean>(true);
    const qtdeMessages = messages?.length;
    const onlyMessage = qtdeMessages === 1;

    const resetMessages = () => {
        setTest((oldTest) => {
            for (const property in test) {
                test[property] = false;
            }
            return oldTest;
        })
    }

    const openModalDrop = () => {
        if (qtdeMessages > 0) {
            setAll(messages.reduce((acc, message) => {
                if (!(message.sendBy == abare.id))
                    return false
                return acc
            }, true))
            modalRef.current.open();
        }
    }

    const dropMessages = (dropAll: boolean) => {
        if (qtdeMessages > 0) {
            const type = dropAll ? ALL : ME;
            messages.map((msg) => dropMessage(msg, type));
            modalRef.current.close();
            setSelectMessages(false);
            resetMessages();
        }
    }

    const handleForwardMessages = () => {
        if (qtdeMessages > 0) {
            modalForward.current.open()
        }
    }

    return (
        <Container >
            <div className="container_close">
                <IoIosClose size={28} className="icon" onClick={() => { setSelectMessages(false), resetMessages() }} />
                {qtdeMessages} {`${onlyMessage ? `Mensagem` : `Mensagens`}`}
            </div>
            <div className="container_close">
                <RiShareForwardFill onClick={() => handleForwardMessages()} size={22} className="icon margin" />
                <FaTrash onClick={() => openModalDrop()} size={22} className="icon" />
            </div>
            <ModalDropMessage onlyMessage={onlyMessage} all={all} title={`Excluir ${onlyMessage ? `Mensagem` : `Mensagens`}`} action={dropMessages} modalRef={modalRef} />
            <Modal mobile={true} ref={modalForward}>
                <Forward {...props} resetMessages={resetMessages} modalAdd={modalForward} groupType={false} messages={messages} />
            </Modal>
        </Container >
    );
}

const mapStateToProps = (state: TypeConstRedux) => ({
    abare: state.abare.abare,
    managerChat: state.chat.globalMessages,
    clientId: getClientId(state.chat.personal) || personalTemp(state.chat.personal, state.abare.abare),
})
const mapDispatchToProps = dispatch => bindActionCreators({
    dropMessage,
}, dispatch)

export default memo(connect(mapStateToProps, mapDispatchToProps)(SelectMessages));



