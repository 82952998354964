import styled from "styled-components";

export const Container  = styled.div`
    width: 100%;
    height: 48px;

    background-color: #BC718E;
    border-radius: 10px 10px 0 0;
    margin-top: 16px;

    > ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        align-items: center;

        margin: 13px 0 0;
        padding: 0;
        color: #fff;

        > li {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            cursor: pointer;

            .bottom-line {
                height: 4px;
                width: 90px;
                margin-left: 0px !important;
                background: #484848;
            }
        }
    }
`;