import styled from "styled-components";

export const ContainerIcons = styled.div`
  width: 100%;
  display: grid;
  flex: 1;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(5, 1fr);
  height: 100%;
  place-items: center;
  margin: 1rem 0px;
  #iconCopy {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out;
    svg {
      color: white;
    }
  }

  .iconLabel {
    :hover {
      cursor: pointer !important;
      #iconCopy {
        transform: scale(1.2);
      }
    }
    :active {
      #iconCopy  {
        transform: scale(1.1) !important;
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  svg {
    transition: transform 0.2s ease-in-out;
    border-radius: 50%;
  }

  button {
    :hover {
      svg {
        transform: scale(1.2);
      }
    }
    :active {
      svg {
        transform: scale(1.1) !important;
        background-color: red;
      }
    }
  }
`;

export const Exit = styled.div`
  text-align: center;

  #icon {
    color: ${(props) => props.theme.texts.darkerGreyI};
  }

  #message {
    margin: 0px 0px 16px 0px;
    color: ${(props) => props.theme.texts.darkerGreyI};
    font-weight: normal;
  }

  .action {
    padding: 18px 0px 16px 0px;

    button {
      padding: 10px 16px;
      border-radius: 6px;
      border: solid 1px;
      transition: all 200ms ease-in 0s;
    }
  }
`;
