import { FiLink } from "react-icons/fi";
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import { useToasts } from "react-toast-notifications";
import Modal from "..";
import ModalContainer from "../ModalContainer";
import { ContainerIcons } from "./style";
interface ShareProps {
    modalRef: any;
    url: string;
    title: string;
}

interface IconProps {
    Icon: React.ReactNode;
    label: string;
}
const IconLabel = ({ Icon, label }: IconProps): JSX.Element => <div className="iconLabel">
    {Icon}
    <p>{label}</p>
</div>

const ModalShare = ({ modalRef, title, url }: ShareProps) => {
    const { addToast } = useToasts();

    const copyLink = () => {
        navigator.clipboard.writeText(url);
        addToast({
            title: "Link copiado!",
        },
            { appearance: 'success' });
    }

    return <Modal ref={modalRef} mobile={true}>
        <ModalContainer $minHeight={false} padding={false} title="Compartilhar" onClick={() => modalRef.current.close()} buttonBack={true}>
            <ContainerIcons>
                <div className="iconLabel" onClick={copyLink}>
                    <div id="iconCopy">
                        <FiLink size={30} />

                    </div>
                    <p>Copiar Link</p>
                </div>
                <WhatsappShareButton title={title} url={url} children={<IconLabel Icon={<WhatsappIcon />} label="Whatsapp" />} />
                <TelegramShareButton title={title} url={url} children={<IconLabel Icon={<TelegramIcon />} label="Telegram" />} />
                <TwitterShareButton title={title} url={url} children={<IconLabel Icon={<TwitterIcon />} label="Twitter" />} />
                <FacebookShareButton title={title} url={url} children={<IconLabel Icon={<FacebookIcon />} label="Facebook" />} />
                <LinkedinShareButton title={title} url={url} children={<IconLabel Icon={<LinkedinIcon />} label="Linkedin" />} />
                <LineShareButton title={title} url={url} children={<IconLabel Icon={<LineIcon />} label="Line" />} />
                <RedditShareButton title={title} url={url} children={<IconLabel Icon={<RedditIcon />} label="Reddit" />} />
            </ContainerIcons>
        </ModalContainer>
    </Modal>
};


export default ModalShare;
