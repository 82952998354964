import { Fragment, useState } from "react";
import { Input } from "../../../../../../components/Input";
import ItemContainer from "../../../Components/ItemContainer";
import { desformatVoterData, voterNumberFormat } from "../../../../../../util/verification";
import { nInscriçãoMask, onlyNumbers, sectionMask, zoneMask } from "../../../../../../util/mask";
import { Row } from "../../style";

const ElectoralData = (props) => {
    const { user, editInfos } = props;
    const [electoralInfor, setElectoralInforState] = useState(false);
    const [inputNumberI, setInputNumberI] = useState(user.voterNumber ? voterNumberFormat(user.voterNumber) : "");
    const [inputZone, setInputZone] = useState(user.voterZone ? `${user.voterZone}` : "");
    const [inputSection, setInputSection] = useState(user.voterSection ? `${user.voterSection}` : "");
    const [load, setLoad] = useState(false);

    const updateVoterData = e => {
        e.preventDefault()
        const { nVoter, nZone, nSection } = e.target.elements;
        const voterReplace = desformatVoterData(nVoter);

        const data = {
            voterNumber: voterReplace ? voterReplace : null,
            voterZone: nZone.value ? nZone.value : null,
            voterSection: nSection.value ? nSection.value : null,
        }

        if (user.voterNumber != data.voterNumber || user.voterZone != data.voterZone || user.voterSection != data.voterSection) {
            const functionSucess = () => {
                user.voterNumber = data.voterNumber;
                user.voterZone = nZone.value;
                user.voterSection = nSection.value;
                setElectoralInforState(false);
                return user;
            }
            editInfos(data, functionSucess, setLoad)
        } else {
            setElectoralInforState(false)
            setLoad(false)
        }
    }

    return (
        <Fragment>
            {electoralInfor ?
                <Fragment>
                    <form onSubmit={updateVoterData}>
                        <ItemContainer title="Dados eleitorais" onClick={() => setElectoralInforState(!electoralInfor)} load={false} isButton>
                            <Row>
                                Número de inscrição
                                <Input name="nVoter" value={inputNumberI} placeholder="Digite o número de inscrição" autoFocus title="9999 9999 9999"
                                    onInvalid={e => e.target.setCustomValidity('O número de inscrição deve conter 16 caracteres. Ex: 9999 9999 9999')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    onChange={(e) => setInputNumberI(nInscriçãoMask(e.target.value))} pattern="[0-9]{4} [0-9]{4} [0-9]{4}$" />
                            </Row>
                            <Row>
                                Zona
                                <Input name="nZone" value={inputZone} placeholder="Digite o número da sua zona" title="999"
                                    onInvalid={e => e.target.setCustomValidity('A zona deve conter 3 caracteres. Ex: 999')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    onChange={(e) => setInputZone(zoneMask(e.target.value))} pattern="[0-9]{3}" />
                            </Row>
                            <Row lastLine>
                                Seção
                                <Input name="nSection" value={inputSection} placeholder="Digite o número da sua seção" title="9999"
                                    onInvalid={e => e.target.setCustomValidity('A seção deve conter 4 caracteres. Ex: 9999')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    onChange={(e) => setInputSection(sectionMask(e.target.value))} pattern="[0-9]{4}$" />
                            </Row>
                        </ItemContainer>
                    </form >
                </Fragment>
                :
                <Fragment>
                    <ItemContainer title="Dados eleitorais" onClick={() => setElectoralInforState(!electoralInfor)} load isButton>
                        <Row>
                            Número de inscrição
                            <div className="inputs">
                                <input disabled defaultValue={user.voterNumber ? voterNumberFormat(user.voterNumber) : "Não informado"}></input>
                            </div>
                        </Row>
                        <Row>
                            Zona
                            <div className="inputs">
                                <input disabled defaultValue={user.voterZone ? user.voterZone : "Não informado"}></input>
                            </div>
                        </Row>
                        <Row lastLine>
                            Seção
                            <div className="inputs">
                                <input disabled defaultValue={user.voterSection ? user.voterSection : "Não informado"}></input>
                            </div>
                        </Row>
                    </ItemContainer>
                </Fragment>
            }
        </Fragment>
    );
}

export default ElectoralData;