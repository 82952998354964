import { api } from '../api';
import { getToken } from "../auth";

export const getPersonals = (id) => {
    return api({
        url: "/personal-chat/" + id,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {

                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const postPersonal = (id, userId) => {
    return api({
        url: "/personal-chat/" + id,
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: {
            userId
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {

                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const getInfoPersonal = (userId, userIdInfo, personalId) => {
    return api({
        url: `/personal-chat/${userId}/${userIdInfo}/${personalId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const updateReadMessage = (userId, personalId) => {
    return api({
        url: `/personal-chat/${userId}/${personalId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const deletePersonal = (userId, personalId) => {
    return api({
        url: `/personal-chat/${userId}/${personalId}`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};