import { } from 'react'
import { BiCheckDouble, BiTimeFive } from "../../../assets/icons"
import { TypeStatusMessage } from '../../../types/project'
import { getHourMessage } from '../../../util/verification'
import { Container } from './style'
const Status = ({ message, myMessage }) => {
    const StatusIcon = ({ status }) => {
        const { SEND, NOTSEND, CANCELED, NOTRECEIVED } = TypeStatusMessage
        switch (status) {
            case SEND:
                return (
                    <BiCheckDouble size={16} className="send" />
                );
            case CANCELED:
            case NOTSEND:
                return (
                    <BiTimeFive size={16} />
                );
            default:
                return (
                    <BiCheckDouble size={16} className="read" />
                );
        }
    }

    return (
        <Container>
            {getHourMessage(message.createdAt)}
            {myMessage && <div className="status-message">
                <StatusIcon status={message.status} />
            </div>
            }
        </Container>
    );
}

export default Status