import styled from "styled-components";

export const Container = styled.footer`
  width: 100vw;
  max-width: 100%;
  height: auto;
  background-color: ${props => props.theme.begin.background.footer};

  .content-footer {
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 38px;
    align-items: center;
    justify-content: center;
  }

  .content-footer-right {
    position: absolute;
    margin-right: 2rem;
    right: 0;
    align-items: flex-start !important;
  }

  .content-footer-left {
    position: absolute;
    margin-left: 2rem;
    left: 0;
  }

  .p-direitos {
    font-family: "inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }

  .p-termos {
    font-family: "roboto";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .p-termos:hover {
    text-decoration: underline;
  }

  .p-fale-conosco {
    font-family: "sansation";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .noMargin {
    margin: 0px;
  }

  .noMarginBottom {
    margin-bottom: 0 !important;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .start {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .center {
    justify-content: center;
    align-items: center;
  }

  .white {
    color: white;
  }

  .img-email {
    position: relative;
    top: 2px;
  }

  @media (max-width: 867px) {
    .content-footer-left {
      position: initial;
      margin: 0;
      margin-bottom: 1rem;
    }

    .content-footer-right {
      position: initial;
      margin: 0;
      margin-top: 1rem;
    }

    .p-fale-conosco {
      align-self: center;
    }
  }
`;
