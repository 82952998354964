import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 55px min-content min-content;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .pd-b-0 {
    padding-bottom: 0px;
  }

  .margin-button {
    padding: 0.5rem;
    text-align: center;
    background-color: red;
  }
`;

export const MainInput = styled.div`
  flex: 1;
  label {
    display: inline-block;
    margin-top: 0.7rem;
    font-size: 1rem;
    color: ${(props) => props.theme.texts.darkerGreyII};
  }

  div{
    width: calc(100% - 1rem);
  }
`;

export const Back = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 1rem 0.5rem 1rem 1rem;

  h3 {
    flex: 1;
    font-size: ${(props) => props.theme.fonts.text_lg};
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .check {
    color: ${(props) => props.theme.texts.purpleDarkTitle};
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s linear;
    transform: translate3d(100px, 0, 0);
  }

  .show {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }

  #icon-back {
    cursor: pointer;
    margin-right: 8px;
    color: ${(props) => props.theme.texts.purpleDark};
  }
`;

export const Edit = styled.div`
  padding: 1rem 1rem 2rem 1rem;
  background-color: ${(props) => props.theme.backgrounds.white};

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #row {
    display: flex;
    margin-top: 2px;
  }
`;

export const Default = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;

  #profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: white;
    border: 1px solid ${(props) => props.theme.default.dark};
  }
`;

export const BtnFile = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.purple.dark};
  color: ${(props) => props.theme.purple.dark};
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 50%;
`;

export const OutlineButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: none;
  padding: 5px 8px;
  border-radius: 10px;
  border: solid 1px ${(props) => props.theme.texts.purpleDarkTitle};
  transition: box-shadow 0.3s;
  margin-right: 5px;

  #icon {
    font-size: 20px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  .right {
    margin-right: 4px;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;
