import { Group, User } from "../assets/images/index";
import {
  TypeApplication,
  TypeMessage,
  TypePersonal,
  TypeRelationship,
} from "../types/project";
import { EnumTypeRepository } from "../types/redux";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 KB";
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getRandomColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const r = Math.floor(Math.abs(Math.sin(hash + 0)) * 256);
  const g = Math.floor(Math.abs(Math.sin(hash + 1)) * 256);
  const b = Math.floor(Math.abs(Math.sin(hash + 2)) * 256);

  return `rgb(${r}, ${g}, ${b})`;
}
export function ordenarAnivesarios(a, b) {
  const data2 = new Date(a.birthday);
  const data1 = new Date(b.birthday);
  data1.setHours(data1.getHours() + 10);
  data2.setHours(data2.getHours() + 10);

  data1.setFullYear(2000);
  data2.setFullYear(2000);

  return data1.getTime() > data2.getTime()
    ? -1
    : data1.getTime() < data2.getTime()
    ? 1
    : 0;
}
export const getImageEvent = (event, global) => {
  if (global && event) {
    const id = event.Group_id ? event.Group_id : event.User_id;
    if (global[id] && global[id].client) {
      return photoUrl(global[id].client);
    }
  }
  const defaultPhoto = { type: TypePersonal.PERSONAL };
  return photoUrl(defaultPhoto);
};

export const photoUrl = (client) => {
  const { GROUP } = TypePersonal;

  if (!client) {
    return "";
  }

  if (client.photoUrl) {
    return transformUrlImage(client.photoUrl);
  } else {
    switch (client.type) {
      case GROUP:
        return Group;
      default:
        return User;
    }
  }
};

export const nameFormat = (name) => {
  if (name) {
    const nameForm = name.split(" ");
    return nameForm.length == 1
      ? name
      : nameForm[0] + " " + nameForm[nameForm.length - 1];
  } else {
    return " ";
  }
};

export const dateFormat = (d) => {
  if (d) {
    var newDate = new Date(d),
      day = (newDate.getDate() + 1).toString(),
      dayF = day.length == 1 ? "0" + day : day,
      month = (newDate.getMonth() + 1).toString(),
      monthF = month.length == 1 ? "0" + month : month,
      yearF = newDate.getFullYear();

    return dayF + "/" + monthF + "/" + yearF;
  }
};

export const dateFormatUser = (d) => {
  if (d) {
    var newDate = new Date(d),
      day = (newDate.getDate() + 1).toString(),
      dayF = day.length == 1 ? "0" + day : day,
      month = (newDate.getMonth() + 1).toString(),
      monthF = month.length == 1 ? "0" + month : month,
      yearF = newDate.getFullYear();

    return yearF + "-" + monthF + "-" + dayF;
  }
};

export const bioSexVerification = (abare, state) => {
  let bioSexUser;
  if (abare == "M") bioSexUser = "M";
  if (abare == "F") bioSexUser = "F";
  if (state) {
    bioSexUser = state == "NULL" ? null : state;
  }
  return bioSexUser;
};

export const getFirstName = (name) => {
  if (name) {
    const nameForm = name.split(" ");
    return nameForm.length == 1 ? name : nameForm[0];
  } else {
    return "";
  }
};

export const getLastName = (name) => {
  if (name) {
    const nameForm = name.split(" ");
    return nameForm.length == 1 ? name : nameForm[nameForm.length - 1];
  } else {
    return "";
  }
};

export const userIdContact = (userId, personal) => {
  if (personal.User_id_0 == userId) {
    return personal.User_id_1;
  } else if (personal.User_id_1 == userId) {
    return personal.User_id_0;
  }
  return null;
};

export const phoneNumberFormat = (str) => {
  if (str) {
    let cleaned = ("" + str).replace(/\D/g, "");

    let match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return (
        "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
      );
    }

    return null;
  }
};

export const voterNumberFormat = (str) => {
  if (str) {
    let cleaned = ("" + str).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3];
    }
    return;
  }
};

export const cpfOrCnpjFormat = (str, isCpf) => {
  if (str) {
    if (isCpf) {
      return str.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        function (r, a, b, c, d) {
          return a + "." + b + "." + c + "-" + d;
        }
      );
    }
    return str.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      function (r, a, b, c, d, e) {
        return a + "." + b + "." + c + "/" + d + "-" + e;
      }
    );
  }
};

export const getBioSex = (sex) => {
  switch (sex) {
    case "0":
      return "Feminino";
    case "1":
      return "Masculino";
    default:
      return null;
  }
};

export const getHourMessage = (createdAt) => {
  var hourMessage = null;
  if (createdAt) {
    const date = new Date(createdAt);
    hourMessage = date.getHours() + ":" + formatDate(date.getMinutes());
  }
  return hourMessage;
};

export const getDateMessages = (createdAt) => {
  var newDate = null;
  if (createdAt) {
    const date = new Date(createdAt);
    newDate =
      formatDate(date.getDate()) +
      "/" +
      formatDate(date.getMonth() + 1) +
      "/" +
      date.getFullYear();
  }
  return newDate;
};

export const getDateEvent = (date) => {
  var newDate = new Date(date);

  return newDate;
};

export const formatDate = (date) => {
  const data = date < 10 ? "0" : "";
  return data + date;
};

// export const desformatCPF = (str) => str.replace(/[^0-9]/g, '')

export const someNumberInString = (data) => {
  if (data) {
    return data.replace(/[^0-9]/g, "");
  }
  return null;
};

export const desformatVoterData = (data) => {
  const voterNumber = data.value;
  const numberReplace = voterNumber.replace(/\s/g, "").replace(/_/g, "");

  return numberReplace;
};

export const getNameUserGroup = (group, id) => {
  const nameView = group.namesView[id] ? group.namesView[id] : "Você";
  return nameView;
};

export const isFile = (type) => {
  const str = type.split("/");
  return str[0] == "application";
};

export const getTypeMessage = (file) => {
  const str = file.type.split("/");
  const type = str[0] == "application" ? str[1] : str[0];
  const {
    IMAGE,
    VIDEO,
    AUDIO,
    PDF,
    DOC,
    DOCX,
    PPT,
    PPTX,
    XLS,
    XLSX,
    ZIP,
    VOICE,
  } = TypeApplication;
  switch (type) {
    case IMAGE:
      return TypeMessage.IMAGE;
    case VIDEO:
      return TypeMessage.VIDEO;
    case AUDIO:
      return TypeMessage.AUDIO;
    case PDF:
      return TypeMessage.PDF;
    case VOICE:
      return TypeMessage.VOICE;
    case DOC:
      return TypeMessage.DOC;
    case DOCX:
      return TypeMessage.DOCX;
    case PPT:
      return TypeMessage.PPT;
    case PPTX:
      return TypeMessage.PPTX;
    case XLS:
      return TypeMessage.XLS;
    case XLSX:
      return TypeMessage.XLSX;
    case ZIP:
      return TypeMessage.ZIP;
    default:
      return TypeMessage.UNDEFINED;
  }
};

export const getTypeNameMedia = (type) => {
  const {
    IMAGE,
    VIDEO,
    AUDIO,
    PDF,
    DOC,
    DOCX,
    PPT,
    PPTX,
    XLS,
    XLSX,
    ZIP,
    VOICE,
    FOLDER,
  } = TypeMessage;
  switch (type) {
    case IMAGE:
      return "imagem";
    case VIDEO:
      return "video";
    case AUDIO:
      return "audio";
    case PDF:
      return "pdf";
    case VOICE:
      return "mp3";
    case DOC:
      return "doc";
    case DOCX:
      return "docx";
    case PPT:
      return "ppt";
    case PPTX:
      return "pptx";
    case XLS:
      return "xls";
    case XLSX:
      return "xlsx";
    case ZIP:
      return "zip";
    case FOLDER:
      return "pasta";
    default:
      return "desconhecido";
  }
};
export const getTypeRelationship = (type) => {
  const { FAMILY, WORK, FRIEND, SCHOOL, SOCIETY } = TypeRelationship;
  switch (type) {
    case FAMILY:
      return "Família";
    case WORK:
      return "Trabalho";
    case FRIEND:
      return "Amigo(a)";
    case SCHOOL:
      return "Escola/Faculdade";
    case SOCIETY:
      return "Sociedade";
    default:
      return null;
  }
};

export const getTypeRepository = (type) => {
  const {
    CONVERSATION,
    MY_SPACE,
    REPORT,
    SCHEDULE,
    SHARE_WITH_FRIENDS,
    SHARE_WITH_ME,
  } = EnumTypeRepository;
  switch (type) {
    case CONVERSATION:
      return "Conversas";
    case MY_SPACE:
      return "Meu espaço";
    case REPORT:
      return "Relatório";
    case SCHEDULE:
      return "Agenda";
    case SHARE_WITH_FRIENDS:
      return "Compartilhados";
    case SHARE_WITH_ME:
      return "Compartilhados comigo";
    default:
      return "";
  }
};
export const createUrl = (file) => {
  return URL.createObjectURL(file);
};

export const transformUrlImage = (url) => {
  const parse = url.split("/");
  if (parse[0] == "gs:") {
    const key = parse[parse.length - 1];
    const bucket = `abchat`;
    return `https://${bucket}.storage.googleapis.com/${key}`;
  }
  return url;
};

export const isFilled = (str) => {
  return !(str.trim() == "");
};

export const showDate = (messages, index) => {
  return (
    index == 0 ||
    getDateMessages(messages[index - 1].createdAt) !=
      getDateMessages(messages[index].createdAt)
  );
};

//metodo para saber se pode apagar mensagem
export const isDeleteMessage = (createdAt) => {
  const minutesPermited = 240;
  const date1 = new Date(createdAt);
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1);
  const minutes = Math.ceil(diffTime / (1000 * 60));

  return minutes <= minutesPermited;
};

export const isEqualsArray = (array1, array2) => {
  return JSON.stringify(array1.sort()) === JSON.stringify(array2.sort());
};

export const personalTemp = (personal, abare) => {
  return abare.id + personal.id;
};

export const getClientId = (client) => {
  if (client) {
    const typeIds = ["Personal_id", "Group_id", "Guest_id"];
    const id = typeIds.find((id) => client[id]);
    return client[id];
  }
  return null;
};

export const arrayMove = (arr, fromIndex, toIndex, element) => {
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
};

export const generateId = () => {
  return Math.floor(Date.now() * Math.random()).toString(36);
};

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const arrayOfUsers = (array, userId) => {
  const newArray = array
    .filter((user) => {
      return user.id !== userId;
    })
    .map((finalResult) => {
      return finalResult.id;
    });
  return newArray;
};

export const keyClient = {
  [TypePersonal.PERSONAL]: "Personal_id",
  [TypePersonal.GROUP]: "Group_id",
  [TypePersonal.GUEST]: "Personal_id",
  [TypePersonal.SECRET]: "Secret_id",
};

export const setCheckFalse = (array) => {
  const newArray = array.map((item) => ({ ...item, checked: false }));
  return newArray;
};

export const getContactName = (contacts, userIdContact) => {
  const contact = contacts.find(
    (item) => item.User_id_contact == userIdContact
  );

  if (contact) {
    return contact.name;
  }
  return null;
};

export const TIME_CALLING = 15000;

export const pluralWords = (word, quantity) => {
  if (quantity <= 1) {
    return word;
  } else {
    // Regra geral: adicionar 's' ao final da palavra
    if (!word.endsWith("s")) {
      return word + "s";
    }

    // Regra para algumas exceções
    if (word.endsWith("r") || word.endsWith("z")) {
      return word + "es";
    }

    // Regra para palavras terminadas em 'ão'
    if (word.endsWith("ão")) {
      return word.slice(0, -2) + "ões";
    }

    return word;
  }
};
