import styled from "styled-components";

export const Card = styled.div`
  max-width: 425px;
  width: 95%;
  height: 100%;
  padding-inline: 1rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgrounds.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-inline: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.8rem;

  #title {
    color: ${({ theme }) => theme.begin.color.button.secondary};
    margin-bottom: 0;
  }

  h5{
    margin-top: 0;
    color: ${props => props.theme.default.darker};
    font-weight: normal;
  }

`;

export const Button = styled.button`
  padding: 0.7rem;
  padding-inline: 2.5rem;
  cursor: pointer;
  color: white;
  border-radius: 30px;
  background: linear-gradient(267.35deg, #920b42 0.4%, #6f0c5f 100%);
  margin-top: 1.5rem;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-content: center;
  justify-items: center;
  justify-content: space-between;
  place-items: center;
  overflow: hidden;
  min-height: 100%;

  @media only screen and (max-width: 480px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    ${Card} {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      border-radius: 0;

      h5 {
        margin-bottom: 5vh;
      }
      ${Button} {
        margin-top: 9vh;
      }
    }
  }
`;
