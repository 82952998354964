import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.theme.title == "light" ? "#700540" : "#0d283a"};
  padding: 0px 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;

  .back {
    display: none;
    margin-right: 0.5rem;
  }

  .content-profile {
    .profile {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      object-fit: cover;
    }
  }
  .buttons {
    display: flex;
  }

  .icon{
    margin-right: 12px;
  }
  .flex-row {
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;

    #name {
      margin-left: 6px;
      color: ${(props) => props.theme.texts.white};
      font-size: ${(props) => props.theme.fonts.text_lg};
      &:hover {
        color: ${(props) => props.theme.hover.orange};
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: auto 1fr 30px 30px;
    .back {
      display: block;
    }
  }
`;

export const Button = styled.button`
  color: ${({theme}) => theme.texts.white} !important;
  cursor: pointer;
  padding: 0;
  .icon {
    font-size: 22px;
  }
  .icon_drop{
    font-size: 22px;
  }
  &:hover {
    color: ${({theme}) => theme.hover.orange} !important;
  }
`;
