import { Line } from "rc-progress";
import { useContext } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { BlocoConta } from "./style";
import { ContainerAccountInfor } from "../../../style";

const statusBarra = (porcentagem) => {
  if (porcentagem <= 50) {
    return `rgba(35, 173, 92, 1)`;
  } else if (porcentagem <= 89.99) {
    return `rgba(255, 165, 0, 1)`;
  } else {
    return ` #d53737`;
  }
};

const CardAccountInfor = () => {
  const { packageInfo } = useSelector((state: any) => state.abare.abare);
  const dataAquisicao = new Date(packageInfo.startAt);
  const dataFim = new Date(packageInfo.finishAt);
  const tempoUso = new Date().getTime() - dataAquisicao.getTime();
  const usoArmazenamento = packageInfo.hdUsed * 1000000;
  const armazenamentoTotal = packageInfo.hdLimit * 1000000;
  const porcentagemArmazenamento =
    (packageInfo.hdUsed * 100) / packageInfo.hdLimit;
  const statusCircle = statusBarra(porcentagemArmazenamento);
  const porcentagemUso =
    (tempoUso * 100) / (dataFim.getTime() - dataAquisicao.getTime());
  const { texts } = useContext(ThemeContext);

  function formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return "0 GB";
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function formatMonths(time: number) {
    const i = time / (1000 * 60 * 60 * 24);
    if (i < 0) {
      return "0 dias";
    } else if (Math.floor(i) == 1) {
      return "1 dia";
    } else if (i < 30) {
      return Math.floor(i) + " dias";
    } else {
      if (Math.floor(time / (1000 * 60 * 60 * 24) / 30) == 1) {
        return "1 mês";
      }
      return Math.floor(time / (1000 * 60 * 60 * 24) / 30) + " meses";
    }
  }

  return (
    <ContainerAccountInfor>
      <div className="title">Informações da conta</div>

      <BlocoConta>
        <div>
          <div className="circularBar">
            <CircularProgressbarWithChildren
              value={porcentagemArmazenamento}
              strokeWidth={6}
              styles={buildStyles({
                pathColor: statusCircle,
                trailColor: "#d6d6d6",
              })}
            >
              <p className="p_porcentagem">
                {porcentagemArmazenamento.toFixed(2)}%
              </p>

              <p className="p_texto_armazenamento">Armazenamento</p>
            </CircularProgressbarWithChildren>
          </div>
          <div className="div_armazenamento">
            <p className="used">{formatBytes(usoArmazenamento)}</p>{" "}
            <p className="p_armazenamento_total">
              /{formatBytes(armazenamentoTotal)}
            </p>
          </div>
        </div>
        <div className="div_informacoes_conta">
          <div className="column-content">
            <p className="p_pacote ">Pacote {packageInfo.name}</p>

            <div className="center">
              <div className="column">
                <p className="p_title_information">Aquisição</p>
                <p className="p_information">
                  {dataAquisicao.toLocaleDateString()}
                </p>
              </div>
              <hr />
              <div className="column">
                <p className="p_title_information">Validade</p>
                <p className="p_information">
                  {dataFim.toLocaleDateString()}
                </p>
              </div>
            </div>

            <div className="line">
              <p className="p_title_information">Tempo de uso: </p>
              <p className="p_information ">&nbsp;{formatMonths(tempoUso)}</p>
            </div>
          </div>
          <button className="buy-storage">Comprar armazenamento</button>
        </div>
      </BlocoConta>
    </ContainerAccountInfor>
  );
};

export default CardAccountInfor;
