import styled from "styled-components";

export const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0px;
  overflow: hidden;
  overflow-y: auto;
  background: ${(props) => props.theme.backgrounds.lightGreyIV};
  background-size: 40%;

  .main2 {
    display: flex;
    flex-direction: column;
  }
`;

interface GridProps {
  selectMessage?: boolean;
  selected?: boolean;
}
export const Grid = styled.div<GridProps>`
  display: grid;
  padding: ${({ selectMessage }) =>
    selectMessage ? `0px 16px 2px 8px` : `0px 8px 2px 8px`};
  grid-template-columns: ${({ selectMessage }) =>
    selectMessage ? `30px auto` : `auto`};
  background-color: ${(props) =>
    (props.selected && props.selectMessage) ? props.theme.hover.default : `inherit`};
  margin-bottom: 2px;
  .hidden {
    display: ${({ selectMessage }) => (selectMessage ? `block` : `none`)};
  }

  :hover {
    background-color: ${(props) =>
      props.selectMessage ? props.theme.hover.default : `inherit`};
    cursor: ${(props) => (props.selectMessage ? `pointer` : `inherit`)};
  }

  input[type="checkbox"] {
    cursor: pointer;
  }
`;

interface StyledProps {
  isMy?: boolean;
  color?: string;
  fixed?: boolean;
}

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
`;

export const ContainerDate = styled.div<StyledProps>`
  margin: 0.6rem 0rem;
  align-self: center;
  position: ${(props) => (props.fixed ? "fixed" : "")};

  .message {
    font-size: 12px;
    background-color: ${(props) => props.theme.chat.message.backgroundData};
    border-radius: 8px;
    box-shadow: "0px 1px 4px rgba(0, 0, 0, 0.3)";
    padding: 0.6rem 1rem;
    font-size: 12px;

    p {
      margin: 0;
      padding: 0;
      color: ${(props) => props.theme.texts.darkerGreyII};
    }
  }
`;
