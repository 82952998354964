import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AddContact from "../../../../components/AddContact";
import { TypePersonal } from "../../../../types/project";
import { TypeConstRedux } from "../../../../types/redux";
import { ProfileGroup } from "../Group";
import Profile from './Profile';
import { Body, Footer, Header } from './pages';
import { AsideContent, ChatContainer } from './style';

const Decider = () => {
    const { personal } = useSelector((state: TypeConstRedux) => state.chat)
    return (!personal ||  personal.type == TypePersonal.PERSONAL) ? <Profile/> : <ProfileGroup/> 
}

const routes = [
    { path: "/dashboard", component: Decider },
    { path: "/personal", component: Decider },
    { path: "/group", component: Decider },
    { path: "/addContact", component: AddContact },
]

const Chat = (props) => {
    const { personal, location } = props;
    const { details } = useSelector((state: TypeConstRedux) => state.chat)
    const { pathname } = useLocation();
    const rightContent = pathname.split('/')[4] !== 'dashboard';
    const { path } = useRouteMatch();
    const [selectMessages, setSelectMessages] = useState<boolean>(false);
    const [selectedMessages, setSelectedMessages] = useState<any>();
    const [test, setTest] = useState<any>({});


    return (
        personal ?
            <ChatContainer rightContent={rightContent}>
                <div id="main-chat">
                    <Header setSelectMessages={setSelectMessages} />
                    <Body setTest={setTest} selectMessages={selectMessages} setSelectedMessages={setSelectedMessages} />
                    <Footer test={test} setTest={setTest} selectMessages={selectMessages} setSelectMessages={setSelectMessages} />
                </div>
                <AsideContent rightContent={rightContent}>
                    <Switch location={location}>
                        {routes.map(({ component: Component, ...route }, key) => (
                            <Route {...route} path={path + route.path} key={key} >
                                <Component gridRow column1={true} />
                            </Route>
                        ))}
                        <Redirect to={path + "/dashboard"} />
                    </Switch>
                </AsideContent>
            </ChatContainer>
            :
            // be careful when modifying. this path is being used to receive an invite link
            // ex: '..conversations/dashboard/?invite=XXXX'
            <Redirect to="/home/conversations/dashboard" />
    );
}

const mapStateToProps = (state) => ({
    personal: state.chat.personal,
})

export default connect(mapStateToProps, null)(Chat);