import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import {
  colapsedCalendar,
  colapsedSchedule,
  notebook,
  tablet,
} from "../../../../../components/NavBar/style";

export const Container = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: ${(props) => props.theme.backgrounds.white};
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  & > * {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #icon-menu {
    cursor: pointer;
    display: none;
    margin-right: 4px;
    @media only screen and (max-width: ${colapsedCalendar}) {
      display: block;
    }
  }

  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  /* justify-content: flex-start; */
  /* align-items: center; */
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
  height: 55px;
  color: ${({ theme }) => theme.texts.secondaryToolbar};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.backgrounds.secondaryToolbar};
  padding-left: 8px;
  padding-right: 8px;
  .center {
    align-items: center;
  }

  .container-options{
    cursor: pointer;
  }
  .option {
    color: ${({ theme }) => theme.texts.secondaryToolbar};
  }

  .icon {
    font-size: 20px;
    border-radius: 8px;
    padding: 4px;
    :hover {
      box-shadow: inset 0 0 0 150px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    margin-left: 10px;
    color: ${({ theme }) => theme.texts.secondaryToolbar};
  }

  span {
    font-weight: 400;
    font-size: 18px;
    color: ${({ theme }) => theme.texts.secondaryToolbar};
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const FileContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  overflow-y: auto;
  flex-grow: 1;
`;

export const SemiTitle = styled.div`
  font-weight: 500;
  text-transform: capitalize;
  color: #5f6368;
  p {
    margin: 0px;
  }
  .main {
    color: ${({ theme }) => theme.texts.black};
    font-size: 1.2rem;
    font-family: "inter-medium";
  }

  .sub {
    margin-top: 4px;
    font-size: 0.8rem;
  }
`;

export const GridContainer = styled.div`
  grid-gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  margin: 20px 0;
  grid-row-gap: 1rem;
`;

export const Margin = styled.div``;
interface styledProps {
  rightContent?: any;
}

export const StyledDateGrid = styled(DataGrid)`
  .MuiDataGrid-columnHeaders {
    background-color: red !important;
    border: solid 1px black;
  }
`;
export const ChatContainer = styled.div<styledProps>`
  display: grid;
  grid-template-columns: ${({ rightContent }) =>
    rightContent ? "1fr auto" : "1fr"};
  column-gap: ${({ theme }) => theme.general.column_gap};
  grid-template-rows: 1fr;
  overflow: hidden;

  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  @media only screen and (max-width: ${tablet}) {
    border-radius: 14px;
  }
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .item-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px !important;
    button {
      color: ${({ theme }) => theme.texts.black} !important;
    }
    svg {
      color: ${({ theme }) => theme.begin.color.button.secondary};
      margin-right: 4px;
    }
  }
  .item-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .item {
    :hover {
      box-shadow: inset 0 0 0 150px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      border-radius: 8px;
      padding: 3px 0px;
    }
  }

  .points {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    svg {
      padding: 8px 8px;
      font-size: 1.2rem;
      margin: 0 !important;
    }
    :hover {
      box-shadow: inset 0 0 0 150px rgba(0, 0, 0, 0.1);
    }
  }
  #noContent {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex !important;
    border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
    border-radius: 14px;
    box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  }
  .informations-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #main-chat {
    margin-right: ${({ rightContent }) => (rightContent ? "16px" : "0px")};
    border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
    border-radius: 14px;
    box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 55px 1fr auto;
    overflow: hidden;
    flex: 1;
  }

  @media only screen and (max-width: ${notebook}) {
    grid-template-columns: 1fr min-content;
  }

  @media only screen and (max-width: ${tablet}) {
    ${Container} {
      display: ${(props) => props.rightContent && "none"};
    }
  }
`;
