import ReactDOM from 'react-dom';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import App from './App';
import { ToastConfig } from './components';
import reportWebVitals from './reportWebVitals';
import configStore from './store/storeConfig';

const store = configStore();
const queryClient = new QueryClient();

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register("../firebase-messaging-sw.js")
//         .then(function (registration) {
//         })
//         .catch(function (err) {
//         });
// }

// function askNotificationPermission(handler) {

//     // checks to see if notification is actually supported
//     function checkNotificationPromise() {
//         try {
//             // checks if promise based notification is okay
//             Notification.requestPermission().then();
//         } catch (e) {
//             return false;
//         }
//         return true;
//     }

//     // Let's check if the browser supports notifications
//     if (!('Notification' in window)) {
//         console.log("This browser does not support notifications.");
//     } else {

//         if (checkNotificationPromise()) {
//             Notification.requestPermission()
//                 .then((permission) => {
//                     handler(permission);
//                 })

//         } else {
//             Notification.requestPermission(function (permission) {
//                 handler(permission);
//             });
//         }
//     }
// }

// askNotificationPermission((permission) => {
// });

ReactDOM.render(
    <Provider store={store}>
        <StrictMode>

            <ToastProvider autoDismissTimeout={3000} placement="top-center" autoDismiss components={{ Toast: ToastConfig }}>
                <QueryClientProvider client={queryClient}>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />

                </QueryClientProvider>
            </ToastProvider>
        </StrictMode>

    </Provider>,
    document.getElementById('root'),
);

reportWebVitals();
