import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { BsFillPauseFill, BsPlayFill } from "react-icons/bs";
import styled, { ThemeContext } from "styled-components";
import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = (ref, props) => ({
  container: ref,
  waveColor: "#756D71",
  progressColor: props.highlights.default,
  cursorColor: props.highlights.default,
  barWidth: 1,
  barRadius: 1,
  height: 28,
  barMinHeight: 2,
  autoCenter: true,
  hideScrollbar: true,
  mediaControls: true,
  normalize: true,
  partialRender: true,
  responsive: true,
});


export const BtnVoice = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.purple.dark};
  color: #FFF;
  padding: 0.4rem;
  font-size: 16px;
  border-radius: 50%;
  margin-right: 10px;
`;

const formatTime = (time) => {
  return moment().startOf('day').seconds(time).format('mm:ss')
};

const Waveform = (props) => {
  const { url, none } = props
  const waveformRef = useRef(null);
  const wavesurfer = useRef<any>(null);
  const [playing, setPlay] = useState(false);
  const [duration, setDurantion] = useState("0:00/0:00");
  const theme = useContext(ThemeContext);


  useEffect(() => {
    if (url) {
      const options = formWaveSurferOptions(waveformRef.current, theme);
      wavesurfer.current = WaveSurfer.create(options);
      const wave = wavesurfer.current;

      wave.load(url);

      wave.on("ready", function () {
        if (wave) {
          setDurantion(formatTime(wave.getDuration()))
        }
      });

      wave.on('audioprocess', function () {
        if (wave && wave.isPlaying()) {
          var totalTime = formatTime(wave.getDuration()),
            currentTime = formatTime(wave.getCurrentTime())

          setDurantion(currentTime)

        }
      });

      wave.on('finish', function () {
        setPlay(false)
      });

      return () => wave.destroy();
    }
  }, [url]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };


  return (
    none ?
      <>
        <BtnVoice onClick={() => handlePlayPause()}>{!playing ? <BsPlayFill className="play" /> : <BsFillPauseFill className="play" />}</BtnVoice>
        <div id="waveform-audio" ref={waveformRef} />
        <div id="timer">{duration}</div>
      </>
      :
      <div className="waveform-container">
        <BtnVoice onClick={() => handlePlayPause()}>{!playing ? <BsPlayFill className="play" /> : <BsFillPauseFill className="play" />}</BtnVoice>
        <div>
          <div id="waveform-audio" ref={waveformRef} />
          <div className="duration">{duration}</div>
        </div>
      </div>
  );
}

export default Waveform
