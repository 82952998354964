import styled from "styled-components";

export const Link = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.blue.darkI};
  text-decoration: none;
  font-size: 13px;
  transition: 0.1s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.blue.darker};
  }
`;

export const Container = styled.div`
  padding: 1.5rem;
  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.begin.color.button.secondary};
    margin: 0;
  }

  legend {
    color: ${(props) => props.theme.default.darker};
    margin: 0;
    font-size: 14px;
    margin-bottom: 1rem;
  }
`;

export const Button = styled.button`
  padding: 0.7rem;
  padding-inline: 2.5rem;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  border-radius: 30px;
  background: linear-gradient(267.35deg, #920b42 0.4%, #6f0c5f 100%);
  margin-top: 1rem;
`;
