import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { deletePersonal } from "../../../../../../services/requests/personal";
import { dropConversation } from "../../../../../../store/actions/messages/chatAction";
import { bindActionCreators } from "redux";
import { Modal } from "../../../../../../components";
import ModalDelete from "../../../../../../components/Modal/ModalDelete";

const Delete = (props) => {
    const { abare, personal, modalDelete, dropConversation } = props;
    const { addToast } = useToasts();
    const name = personal.name || "@" + personal.nickname

    const dropPersonal = () => {
        deletePersonal(abare.id, personal.Personal_id).then((res) => {
            if (res.status == 200) {
                addToast({
                    title: "Conversa deletada",
                    description: "Seu registro com " + name + " foi apagado."
                },
                    { appearance: 'success' });
                dropConversation(personal)
            }
        })
    }

    return (
        <Modal ref={modalDelete}>
            <ModalDelete modal={modalDelete} title='Excluir conversa' buttonAction='Excluir' action={dropPersonal}>
                <p>Você está prestes a excluir sua conversa com <b>{name}</b>. </p>
                <p>Ao confirmar, será excluida definitivamente </p>
            </ModalDelete>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
        personal: state.chat.personal,
    }
}


const mapDispatchToProps = (dispatch) => bindActionCreators({
    dropConversation
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Delete);