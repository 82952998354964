import styled from "styled-components";

interface ContainerProps {
  $height?: string;
  $minHeight?: boolean;
}
export const Container = styled.div<ContainerProps>`
  .teste {
    max-height: 35px;
    min-height: 35px;
    border: 1px solid #c6c6c6;
    border-radius: 16px;
    font-family: "Poppins light", sans-serif;
    font-size: 14px;
    padding: 0px 8px 0px 8px;
    width: 100%;
    box-sizing: border-box;

    &:hover {
      cursor: pointer !important;
    }
    &:focus {
      -webkit-text-fill-color: #666666;
      border: 1px solid;
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: #666666;
      opacity: 1;
      -webkit-text-fill-color: #666666;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #666666;
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #666666;
    }
  }
  .inputTime {
    max-height: 35px;
    min-height: 35px;
    border: 1px solid #c6c6c6;
    border-radius: 16px;
    font-family: "Poppins light", sans-serif;
    font-size: 14px;
    padding: 0px 8px 0px 8px;
    width: 45px;

    :hover {
      cursor: pointer !important;
    }

    &:focus {
      -webkit-text-fill-color: #666666;
      border: 1px solid;
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: #666666;
      opacity: 1;
      -webkit-text-fill-color: #666666;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #666666;
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #666666;
    }
  }
`;

interface StyledProps {
  color?: string;
  justifyC?: string;
  border?: string;
  background?: string;
  grown?: boolean;
  $required?: boolean;
}

export const Element = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  margin-right: 8px;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;

  padding-bottom: 4px;
  align-items: center;
  color: ${(props) => (props.color ? props.color : "#666666")};
  font-family: "Poppins light", sans-serif;
  font-size: 14px;
  white-space: nowrap;
  flex: 1;
  p {
    margin: 0px;
    padding: 0px;
  }
  .react-datepicker-wrapper {
    width: auto !important;
  }
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) =>
      theme.backgrounds.buttonSchedule} !important;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }) => theme.purple.ligther} !important;
  }
  .icon {
    margin-right: 8px;
    color: ${({ $required, theme }) => ($required ? theme.error : "inherit")};
  }
  .grown {
    flex-grow: 1;
  }

  .check {
    margin-left: 24px;
  }

  .toogle-status {
    margin-bottom: 0px;
    font-size: 1.1rem;
    line-height: 1;
  }

  .toogle-text {
    margin-top: 0px;
    font-size: 0.75rem;
  }

  .margin-right {
    margin-right: 8px;
  }
  @media only screen and (max-width: 906px) {
    border: none;
    padding-bottom: 0.5rem;
  }
`;
