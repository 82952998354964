import { IoIosArrowBack } from "../../../assets/icons";
import { Back, Container } from "./style";

interface ModalProps {
    title: string;
    onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
    children: React.ReactNode;
}
const ModalContent = ({title, onClick, children}: ModalProps) => {
    return (
        <Container>
            <div className="container">
                <Back onClick={onClick}>
                    <IoIosArrowBack id="icon" />
                    <b><h3>{title}</h3></b>
                </Back>
                <div className="item">
                    {children}
                </div>
            </div>
        </Container>
    );
}

export default ModalContent;