import { useContext, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ThemeContext } from "styled-components";
import { Modal } from "../../../components";
import ModalExit from "../../../components/Modal/ModalExit";
import { logout } from "../../../services/auth";
import { getEvents } from "../../../services/requests/event";
import {
  deleteNotification,
  getNotifications,
  readNotification,
} from "../../../services/requests/notification";
import { clearReducers } from "../../../store/actions/cleanAction";
import {
  setCommitmentDash,
  setNotificationsDash,
} from "../../../store/actions/dashboardAction";
import { toChangeTheme } from "../../../store/actions/themeAction";
import { TNotification } from "../../../types/project";
import AllNotifications from "./Notification/AllNotifications";
import Protocol from "./Protocol";
import { ContainerContent, ContainerDashBoard, ContainerSmall } from "./style";
import { ContainerSwitch } from "../style";
import CardEvent from "./components/Cards/CardEvent";
import CardAccountInfor from "./components/Cards/CardAccountInfor";
import CardBirthday from "./components/Cards/CardBirthday";
import CardWeather from "./components/Cards/CardWeather";
import { CardWelcome } from "./components/Cards/CardWelcome";
import { CardNotices } from "./components/Cards/CardNotices";
import { CardDailyMessages } from "./components/Cards/CardDailyMessages";
import { CardProgrammingEvents } from "./components/Cards/CardProgrammingEvents";

const Dashboard = (props) => {
  const {
    abare,
    column1,
    column2,
    schedule,
    setCommitmentDash,
    clearReducers,
    setNotificationsDash,
    notifications,
    border = false,
  } = props;
  const { title } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const modalRef = useRef<any>(null);
  const modalProtocol = useRef<any>(null);
  const modalAllNotifications = useRef<any>(null);

  const { push } = useHistory();

  const array: Array<number> = [];
  let number_notifications: number = 0;
  notifications?.map((notification) => {
    if (!notification.status) {
      number_notifications = number_notifications + 1;
    }
  });
  const actionLogout = () => {
    clearReducers();
    logout();
    push("/login");
  };

  const newAccount = () => {
    clearReducers();
    logout();
    push("/signin/email");
  };

  function sortNotificationByDate(a: TNotification, b: TNotification) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  function sortNotificationByState(a: TNotification, b: TNotification) {
    if (a.status == b.status) {
      return 0;
    }
    if (!a.status) {
      return -1;
    }
    return 1;
  }
  useEffect(() => {
    getEvents().then((res) => {
      if (res.status == 200) {
        setCommitmentDash(res.data.events);
      }
    });
    getNotifications().then((res) => {
      if (res.status === 200) {
        setNotificationsDash(
          res.data.sort(sortNotificationByDate).sort(sortNotificationByState)
        );
      }
    });
  }, []);

  const handleStatus = (notification) => {
    readNotification(notification.id).then((res) => {
      const index = notifications.indexOf(notification);
      let copy = [...notifications];
      copy[index].status = true;
      dispatch(setNotificationsDash(copy));
    });
  };

  const handleDelete = (notification) => {
    deleteNotification(notification.id).then((res) => {
      dispatch(
        setNotificationsDash(
          notifications.filter(
            (notificationL) => notificationL !== notification
          )
        )
      );
    });
  };

  const handleTheme = () => {
    dispatch(toChangeTheme());
  };

  return (
    <ContainerDashBoard border={border} column1={column1} schedule={schedule}>
      <ContainerContent column1={column1} column2={column2}>
        <CardWelcome />
        <CardNotices />

        <CardDailyMessages />
        <CardProgrammingEvents />
        <CardWeather />
        <CardEvent column1={column1} />
        <CardBirthday column1={column1} />

        <CardAccountInfor />
      </ContainerContent>
      <Modal ref={modalProtocol} mobile={true}>
        <Protocol modalRef={modalProtocol} />
        {/* <div style={{height:"100px", width: "100px", backgroundColor:"red"}}></div> */}
      </Modal>
      <Modal ref={modalAllNotifications} mobile={true}>
        <AllNotifications
          modalRef={modalAllNotifications}
          notifications={notifications}
          handleStatus={handleStatus}
          handleDelete={handleDelete}
        />
        {/* <div style={{height:"100px", width: "100px", backgroundColor:"red"}}></div> */}
      </Modal>
      <ModalExit
        title="Encerrar sessão"
        action={actionLogout}
        modalRef={modalRef}
      />
    </ContainerDashBoard>
  );
};

const mapStateToProps = (state) => ({
  abare: state.abare.abare,
  notifications: state.dashboard.notifications_dash,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCommitmentDash,
      clearReducers,
      setNotificationsDash,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
