import { connect } from "react-redux";
import { getClientId } from "../../../../../util/verification";
import { memo } from "react";
import ItemConversation from "./ItemConversation";
import { TypeConstRedux } from "../../../../../types/redux";

const List = (props) => {
    const { globalMessages, personals } = props;
    return (
        personals.map((personal, key) => {
            const  client= globalMessages[getClientId(personal)]?.client;
            if (client) {
                return (
                    <ItemConversation key={key} client={client} />
                )
            }
        })
    );
}

const mapStateToProps = (state: TypeConstRedux) => ({
    globalMessages: state.chat.globalMessages,
    folders: state.folder.list
})

export default connect(mapStateToProps)(memo(List));