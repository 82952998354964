import { useState } from "react";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { ReportOptionsInterface, ReportSelectFilter, ReportTypesValueFilter } from "../../../../../types/project";
import { ReportFilters } from "../../../../../util/report/reportFilters";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import FilterOptions from "../FormContent/FilterOptions";
import { ItemModal } from "../style";

interface ColumnsProps {
    modal: any;
    header: HeaderTable;
    optionsPDF: ReportOptionsInterface;
    setOptionsPDF: React.Dispatch<React.SetStateAction<ReportOptionsInterface>>;
}

const AddFilterModal = ({ modal, header, optionsPDF, setOptionsPDF }: ColumnsProps) => {

    const [value, setValue] = useState<ReportTypesValueFilter>(undefined);
    const columnsTable = Object.keys(header.itens);
    const [columnSelect, setColumnSelect] = useState(columnsTable[0]);
    const [filterSelected, setFilterSelected] = useState<ReportSelectFilter>(ReportFilters.valuesLabel(header.itens[columnSelect].type)[0]);

    function saveChange() {
        console.log({ key: columnSelect, type: filterSelected.value, value: value })
        optionsPDF.filtersOperations.push({ key: columnSelect, type: filterSelected.value, value: value })
        setOptionsPDF({ ...optionsPDF });
        modal.current.close();
    }

    return (
        <ModalContainer padding={false} onButtonRight={saveChange} buttonConfirmation={true} title="Adicionar filtro à tabela" onClick={() => modal.current.close()} buttonBack={true}>
            <ItemModal $noPadding={true} $borderBottom={true}>
                <div className="flex padding border" style={{ minWidth: "230px", flex: 1 }}>
                    <FilterOptions value={value} setValue={setValue} header={header} columnSelect={columnSelect} setColumnSelect={setColumnSelect} filterSelected={filterSelected} setFilterSelected={setFilterSelected} />
                </div>
            </ItemModal>
        </ModalContainer>
    );
}

export default AddFilterModal;