export const TOKEN_KEY = "@abarechat-token";
export const DOWNLOAD_DOCS = "download_docs";
export const DOWNLOAD_AUDIO = "download_audio";
export const DOWNLOAD_FOTOS = "download_fotos";
export const DOWNLOAD_VIDEOS = "download_videos";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getDownloadDocs = () => localStorage.getItem(DOWNLOAD_DOCS) ? true : false;
export const getDownloadAudio = () => localStorage.getItem(DOWNLOAD_AUDIO) ? true : false;
export const getDownloadFotos = () => localStorage.getItem(DOWNLOAD_FOTOS) ? true : false;
export const getDownloadVideos = () => localStorage.getItem(DOWNLOAD_VIDEOS) ? true : false;
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const downloads = props => {
  const { docs, audio, fotos, video } = props
  docs != undefined && localStorage.setItem(DOWNLOAD_DOCS, docs);
  audio != undefined && localStorage.setItem(DOWNLOAD_AUDIO, audio);
  fotos != undefined && localStorage.setItem(DOWNLOAD_FOTOS, fotos);
  video != undefined && localStorage.setItem(DOWNLOAD_VIDEOS, video);
}
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
