import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip';
import ModalContainer from "../../../../../../../components/Modal/ModalContainer";
import { ShimmerCard } from "../../../../../../../components/Shimmers/styles";
import { getAllUsersInGroup } from "../../../../../../../services/requests/user-group";
import { TypeConstRedux } from "../../../../../../../types/redux";
import { optionsMounthDate } from "../../../../../../../util/date";
import { getClientId, photoUrl } from "../../../../../../../util/verification";
import { Month } from "./style";
import Img from "../../../../../../../components/Img";

const AllBirthday = (props) => {
    const { modalAllBirthday, defaultContacts, group, abareId, clientId } = props;
    const [meses, setMeses] = useState<any | never>(null);
    const [contacts, setContacts] = useState(defaultContacts)
    useEffect(() => {
        if (contacts) {
            var isHappy = false
            const mesesA: any[] = [[], [], [], [], [], [], [], [], [], [], [], []]
            contacts.map((contact) => {
                if (contact.birthday) {
                    isHappy = true
                    mesesA[parseInt(contact.birthday.substring(5, 7)) - 1].push(contact)
                }
            })
            if (isHappy) {
                setMeses(mesesA)
            } else {
                setMeses([])
            }

        }
    }, [contacts])

    useEffect(() => {
        if (group) {
            getAllUsersInGroup(clientId).then((res) => {
                if (res.status == 200) {
                    setContacts(res.data)
                }
            })
        }
    }, [])

    return (
        <ModalContainer title="Aniversariantes" onClick={() => modalAllBirthday.current.close()} buttonBack={true}>
            {
                meses == null ? <ShimmerCard /> :
                    meses.length != 0 ? <>
                        {meses.map((mes, key) => {
                            return mes.length > 0 &&
                                <Month key={key}>
                                    <p>{optionsMounthDate[key + 1].label}</p>
                                    <div className="div-img">
                                        {mes.map((contact, key) => {
                                            const tooltip = `${contact.birthday.substring(8, 10)} de ${optionsMounthDate[parseInt(contact.birthday.substring(5, 7))].label} é o anivesário de ${contact.name}`
                                            return <div key={key} data-tip={tooltip}>
                                                <Img key={key} className="image_birthday spaceImage" id="profile" src={photoUrl(contact)} />
                                                <ReactTooltip textColor="#fff" border={true} borderColor="#BDBDBD" backgroundColor="#000" place="right" effect="solid" />
                                            </div>
                                        })}
                                    </div>
                                </Month>
                        })}</>
                        : <Month>
                            <p className="empty">Nenhum membro deixou público a sua data de aniversário :( </p>
                        </Month>
            }
        </ModalContainer>
    )
}

const mapStateToProps = (state: TypeConstRedux) => ({
    abareId: state.abare.abare!!.id,
    clientId: getClientId(state.chat.personal)
})


export default connect(mapStateToProps)(AllBirthday);