import { ChangeEvent, Fragment, useState } from "react";
import { Input, InputSelect } from "../../../../../../components/Input";
import { birthdayFormat, dateBirthday, defaultAbareYear, defaultDay, defaultMounth, defaultYear, optionsDayDate, optionsMounthDate, optionsYearDate, verificationYearDate } from "../../../../../../util/date";
import { cnpjMask, cpfMask } from "../../../../../../util/mask";
import { cpfOrCnpjFormat, someNumberInString } from "../../../../../../util/verification";
import ItemContainer from "../../../Components/ItemContainer";
import { VerticalRowItems } from "../../../style";
import { Row } from "../../style";

const PersonalInfor = (props) => {
  const { user, editInfos } = props;
  const [personalInfor, setPersonalInforState] = useState(false);
  const [cpfUser, setCpfUser] = useState(cpfOrCnpjFormat(user.cnpj ||user.cpf, !user.cnpj));
  const [day, setDay] = useState(() => defaultDay(user.birthday));
  const [mounth, setMounth] = useState(() => defaultMounth(user.birthday));
  const [year, setYear] = useState(() => defaultYear(user.birthday));
  const yearValue = defaultAbareYear(user.birthday);
  const [dateState, setDateState] = useState(false);
  const [load, setLoad] = useState(false);

  const [isCpf, setIsCpf] = useState<boolean>(!user.cnpj);
  const regexDoc: string = isCpf ? "[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$" : "[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}$";
  const titleDoc: string = isCpf ? "999.999.999-99" : "99.999.999/9999-99";

  const [opt1, setOpt1] = useState(user.bioSex == 'F');
  const [opt2, setOpt2] = useState(user.bioSex == 'M');
  const [opt3, setOpt3] = useState(!user.bioSex);

  const cpfOrCnpjUser = user.cnpj || user.cpf;
  const items = [
    { name: "radio_btn1", message: "Feminino", value: "F", marginBottom: true, checked: opt1, onchange: () => { setOpt1(!opt1); setOpt2(false); setOpt3(false) } },
    { name: "radio_btn2", message: "Masculino", value: "M", marginBottom: true, checked: opt2, onchange: () => { setOpt1(false); setOpt2(!opt2); setOpt3(false) } },
    { name: "radio_btn3", message: "Não informado", value: "N", marginBottom: false, checked: opt3, onchange: () => { setOpt1(false); setOpt2(false); setOpt3(!opt3); } }
  ]

  const updateInfoUser = e => {
    e.preventDefault()
    const { cpf, radio_btn1, radio_btn2, radio_btn3 } = e.target.elements;
    const cpfReplace = someNumberInString(cpf.value);
    let yearDef = verificationYearDate(year, yearValue, day, mounth)
    let birthday = dateBirthday(day, mounth, yearDef);
    let bioSexUser = radio_btn1.checked ? 'F' : radio_btn2.checked ? 'M' : null;

    const cpfOrCnpj = isCpf ? 'cpf' : 'cnpj';
    const data = {
      cpf: null,
      cnpj: null,
      birthday: birthday == '0000-00-00' ? null : birthday,
      bioSex: bioSexUser ? bioSexUser : null,
    }
    if (cpfReplace) {
      data[cpfOrCnpj] = cpfReplace;
    }


    if (user.cpf != data.cpf || user.birthday != data.birthday || user.bioSex != data.bioSex) {
      const functionSucess = () => {
        //inicialized with null to then assign cpf or cnpj
        user.cpf = null;
        user.cnpj = null;
        user[cpfOrCnpj] = cpfReplace;
        user.birthday = birthday;
        user.bioSex = bioSexUser;
        setPersonalInforState(false);
        setDateState(false)
        return user;
      }
      editInfos(data, functionSucess, setLoad)
    } else {
      setPersonalInforState(false)
      setLoad(false)
    }
  }

  const handleDoc = ({ target }: ChangeEvent<HTMLInputElement>) => { setCpfUser(isCpf ? cpfMask(target.value) : cnpjMask(target.value)) }

  return (
    <Fragment>
      {personalInfor ?
        <form onSubmit={updateInfoUser}>
          <ItemContainer title="Informações pessoais" onClick={() => setPersonalInforState(!personalInfor)} load={false} isButton>
            <Row>
              <label><input checked={isCpf} onChange={() => setIsCpf(true)} type="radio" name="CPF" />CPF</label>
              <label className="marginLeft"><input checked={!isCpf} onChange={() => setIsCpf(false)} type="radio" name="CNPJ" />CNPJ</label>
              <Input name="cpf" value={cpfUser} placeholder="Digite o número de inscrição" autoFocus title={titleDoc}
                onChange={handleDoc} pattern={regexDoc} />
            </Row>
            <Row>
              Data de nascimento
              <div id="input-date">
                <InputSelect id='day' name='day' className='date' style={false} placeholder='Dia' options={optionsDayDate} defaultValue={optionsDayDate[day]} onChange={(e) => setDay(e.value)}></InputSelect>
                <InputSelect id='mounth' name='mounth' className='date' style={false} placeholder='Mês' options={optionsMounthDate} defaultValue={optionsMounthDate[mounth]} onChange={(e) => setMounth(e.value)}></InputSelect>
                <InputSelect id='year' name='year' className='date' style={false} placeholder='Ano' options={optionsYearDate} defaultValue={optionsYearDate[defaultYear(user.birthday)]} onChange={(e) => setYear(e.value)}></InputSelect>
              </div>
              {dateState &&
                <div>
                  <p>*Verifique se os campos estão corretamente preenchidos.</p>
                </div>
              }
            </Row>
            <Row lastLine>
              Sexo
              <div className="line">
                {items.map((it, key) => (
                  <VerticalRowItems isSingle marginBottom={it.marginBottom} key={key}>
                    <input type="radio" name={it.name} checked={it.checked} onChange={it.onchange} />
                    <span>{it.message}</span>
                  </VerticalRowItems>
                ))}
              </div>
            </Row>
          </ItemContainer>
        </form>
        :
        <ItemContainer title="Informações pessoais" onClick={() => setPersonalInforState(!personalInfor)} load isButton>
          <Row>
            CPF/CNPJ
            <div className="inputs">
              <input disabled defaultValue={cpfOrCnpjUser ? cpfOrCnpjFormat(cpfOrCnpjUser, isCpf) : "Não informado"} pattern={regexDoc}></input>
            </div>
          </Row>
          <Row>
            Data de nascimento
            <div className="inputs">
              <input disabled defaultValue={user.birthday ? birthdayFormat(user.birthday) : "Não informado"}></input>
            </div>
          </Row>
          <Row lastLine>
            Sexo
            <div className="inputs">
              <input disabled defaultValue={user.bioSex ? (user.bioSex == 'F' ? 'Feminino' : 'Masculino') : "Não informado"}></input>
            </div>
          </Row>
        </ItemContainer>
      }
    </Fragment>
  );
}

export default PersonalInfor;