import { MdArrowRight } from "react-icons/md";
import { SidbarItemMain, SidebarItemArrow, SidebarItemStyle } from "./styles";

interface dataDTO {
  arrow?: boolean;
  icon?: any;
  label?: any;
  selected?: boolean;
  onAction: (e: React.MouseEvent<HTMLDivElement>) => void;
  onArrow?: (e: React.MouseEvent<HTMLDivElement>) => void;
  layer?: number;
  open?: boolean;
  active?: boolean;
}

function SidebarItem({
  active = true,
  arrow = false,
  icon,
  label,
  selected = false,
  onAction,
  layer = 0,
  onArrow = () => {},
  open = false,
}: dataDTO) {
  return (
    <SidebarItemStyle
      $active={active}
      $paddingLeft={`${layer * 10}`}
      onClick={onAction}
      $selected={selected}
    >
      <SidebarItemArrow onClick={onArrow}>
        {arrow && <MdArrowRight className={open ? "up" : "down"} />}
      </SidebarItemArrow>

      <SidbarItemMain>
        {icon}
        <p>{label}</p>
      </SidbarItemMain>
    </SidebarItemStyle>
  );
}

export default SidebarItem;
