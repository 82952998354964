import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Header } from "../../Report/ListReports/style";
import CardCategoryEvent from "../components/CardCategoryEvent";
import CardTypeEvent from "../components/CardTypeEvent";

import { L10n, loadCldr } from "@syncfusion/ej2-base";

import * as gregorian from "cldr-data/main/pt/ca-gregorian.json";
import * as numbers from "cldr-data/main/pt/numbers.json";
import * as timeZoneNames from "cldr-data/main/pt/timeZoneNames.json";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as weekData from "cldr-data/supplemental/weekData.json"; // To load the culture based first day of week
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setDateSelect } from "../../../../store/actions/scheduleAction";
import { TypeConstRedux } from "../../../../types/redux";
import CalendarDate from "../components/Calendar/CalendarDate";
import CardPersonalizedCategory from "../components/CardPersonalizedCategory";
import { Card } from "../components/style";
import { MiddleContainer } from "./style";
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);


L10n.load({
    pt: {
        calendar: {},
    },
});
interface ListOptions {
    overflow?: boolean;
    hidden?: boolean;
    modal?: boolean;
    setOpen?: Dispatch<SetStateAction<boolean>>;
}
const ListOptions = ({ overflow = true, hidden = true, modal = false, setOpen }: ListOptions) => {
    const [newFolder, setNewFolder] = useState<Boolean>(false);
    const calendar = useRef<CalendarDate>(null);
    const dispatch = useDispatch();
    const { dateSelect } = useSelector((state: TypeConstRedux) => state.schedule);

    return (

        <MiddleContainer className={`${hidden ? `hidden` : ` `} ${modal ? `border` : ``}`} overflow={false} onClick={(e) => e.stopPropagation()}>
            <Header>
                <div className="top">
                    <h3 className="title">Agendas</h3>
                    {(setOpen && modal) && <IoIosClose onClick={() => setOpen(false)} className="iconClose" />}
                </div>
            </Header>
            <div className="container-list">
                <CardTypeEvent />
                <CalendarDate
                    value={dateSelect}
                    change={(e) => {
                        dispatch(setDateSelect(new Date(e.value)));
                    }}
                    showTodayButton={false}
                    ref={calendar}
                    locale="pt"
                />
                <Card>
                    <p className="title">Categorias</p>
                    <CardCategoryEvent />
                    <CardPersonalizedCategory />

                </Card>
            </div>

        </MiddleContainer>

    );
}

export default ListOptions;