import { forwardRef, useImperativeHandle, useState } from "react";
import { createPortal } from "react-dom";
import { IoIosArrowBack } from "../../assets/icons";
import { Header, ModalBackdrop, ModalBox, ModalWrapper } from "./style";

const modalRoot: any = document.getElementById("modal-root");
export type ModalRef = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
};

export type ModalProps = {
  // outros props do componente
};
const Modal = forwardRef<ModalRef, any>((props: any, ref) => {
  const {
    $overflow,
    title,
    subTitle,
    none,
    children,
    openDefault = false,
    mobile = false,
    right = false,
  } = props;
  const [display, setDisplay] = useState(openDefault);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setDisplay(true),
      close: () => setDisplay(false),
      isOpen: display,
    }),
    [close, display]
  );

  const myStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    e.stopPropagation();
  return createPortal(
    display ? (
      !none ? (
        title && subTitle ? (
          <ModalWrapper onClick={myStopPropagation}>
            <ModalBackdrop onClick={() => setDisplay(false)} />
            <ModalBox $overflow={true} mobile={mobile}>
              <Header>
                <div id="top">
                  <IoIosArrowBack id="back" onClick={() => setDisplay(false)} />
                  <div id="data">
                    <h3 id="title">{title}</h3>
                    <legend id="subTitle">{subTitle}</legend>
                  </div>
                </div>
              </Header>
              {children}
            </ModalBox>
          </ModalWrapper>
        ) : (
          <ModalWrapper onClick={myStopPropagation}>
            <ModalBackdrop onClick={() => setDisplay(false)} />
            <ModalBox $overflow={true} mobile={mobile}>
              {children}
            </ModalBox>
          </ModalWrapper>
        )
      ) : (
        <ModalWrapper onClick={myStopPropagation}>
          <ModalBackdrop onClick={() => setDisplay(false)} />
          {children}
        </ModalWrapper>
      )
    ) : null,
    modalRoot
  );
});

export default Modal;
