import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  margin-top: 12px;
  border: 3px ${(props) => props.theme.texts.purpleDark} dashed;
  width: 100%;
  color: ${(props) => props.theme.texts.darkerGreyII};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;

  .btn {
    border: none;
    text-align: center;
    background-color: rgb(218, 216, 216);
    height: 50px;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    transition-duration: 0.6s;
  }
  .btn:hover {
    background-color: blue;
    color: aliceblue;
  }
`;
