import styled from "styled-components";

interface ButtonProps {
  mobile: boolean;
}
export const Container = styled.div<ButtonProps>`
  @media only screen and (min-width: 540px) {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }

  .icon {
    font-size: 20px;
  }
  .bottom-right {
    bottom: 30px;
    right: 12px;
  }
  .bottom-left {
    bottom: 30px;
    left: 12px;
  }
  .top-right {
    top: 30px;
    right: 12px;
  }
  .top-left {
    top: 30px;
    left: 12px;
  }

  .large {
    width: 50px;
    height: 50px;
    .icon {
      font-size: 30px;
    }
  }
`;

export const ButtonFloat = styled.button`
  position: absolute;
  min-width: auto;

  width: 35px;
  height: 35px;
  padding: 0px;
  border-radius: 50%;
  color: ${({ theme }) => theme.default.ligther};
  background-color: ${({ theme }) => theme.begin.color.button.secondary};
  opacity: 0.9;
  z-index: 10;
  transition: all ease-in-out 300ms;
  box-shadow: 0px 37px 20px -15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.highlights.default};
  }
`;
