import { api } from "../api";
import { getToken } from "../auth";

export const getSource = () => {
  return api({
    url: `/storage-media`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

export const getFolder = (id) => {
  console.log(id);
  return api({
    url: `/storage-media/list-file-in-folder`,
    method: "POST",
    data: { storageClusterId: id },
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

export type UpdateNameStorageFilePayload = {
  storageMediaId: string;
  name?: string;
  storageClusterId?: string;

};
const patchName = (data: UpdateNameStorageFilePayload) => {
  return api({
    url: `/storage-media/update-name-media`,
    method: "PATCH",
    data: data,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

const deleteMedia = (ids: string[]) => {
  return api({
    url: `/storage-media`,
    method: "DELETE",
    data: {
      storageMediaId: ids,
    },
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

const post = (data) => {
  return api({
    url: `/storage-media`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const StorageMediaService = {
  post,
  patchName,
  deleteMedia,
  getSource,
  getFolder,
};
