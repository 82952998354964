import { useContext, useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { getEvents, getEventsGroup } from "../../../services/requests/event";
import { getUsersInEvent } from "../../../services/requests/event-user";
import {
  getGroupsForFolders,
  getInfoGroup,
} from "../../../services/requests/group";
import {
  ReportEnum,
  ReportOptionsInterface,
  ReportTypeFilter,
  TContact,
} from "../../../types/project";
import { TypeConstRedux } from "../../../types/redux";
import { optionsMounthDate } from "../../../util/date";
import { ReportFilters } from "../../../util/report/reportFilters";
import { ReportFunctions } from "../../../util/report/reportFunctions";
import { ReportSort } from "../../../util/report/reportSort";
import { HeaderTable, columnsTable } from "../../../util/report/reportTypes";
import { ContainerSwitch } from "../style";
import FloatingButton from "./components/FloatingButtton";
import NoMessage from "./components/NoMessage";
import ViewPdf from "./components/ViewPDF";
import ListReports from "./ListReports";
import OptionReport from "./OptionReport";
import reportPDF from "./reportPDF";
import { ContentChat } from "./style";
import { ReportContext } from "../../../contexts/ReportContext";

const Report = () => {
  const { id }: any = useSelector<TypeConstRedux>((state) => state.abare.abare);
  const { stylesPDF, optionReportSelected, handleStylesPDF } =
    useContext(ReportContext);

  //Objeto com as opções de busca e filtro do relatório
  const [optionsPDF, setOptionsPDF] = useState<ReportOptionsInterface>(
    ReportFunctions.defaultOptionsPDF
  );
  //Url que aponta para o pdf gerado, se não houver nenhum relatório selecionado se torna undefined
  const [urlPdf, setUrlPdf] = useState<string | undefined>(undefined);

  //Estado para saber se aplicação precisa de alguma informação
  const [isWaiting, setIsWaiting] = useState<boolean>(true);
  //Estado para saber se aplicação esta buscando dados ou construindo pdf
  const [loading, setLoading] = useState<boolean>(false);
  //Estado para guardar dados da requisição
  const [data, setData] = useState<Object[] | undefined>(undefined);
  //Estado para guardar o header do pdf
  const [header, setHeader] = useState<HeaderTable | undefined>(undefined);

  const [openOptions, setOpenOptions] = useState(false);

  const [update, setUpdate] = useState<boolean>(false);

  const contacts: TContact[] = useSelector(
    (state: TypeConstRedux) => state.contacts.list
  );
  const display: boolean = optionReportSelected && header ? true : false;

  function filterData(
    res: any[],
    options: ReportOptionsInterface,
    header: HeaderTable
  ) {
    const search = options.filters.find(
      (option) => option.key == ReportTypeFilter.SEARCH
    );

    if (res.length > 0 && options.filters.length > 0) {
      options.filters.forEach((option) => {
        //Se não houver nenhum valor no filtro ou a opção selecionada for diferente de "all"
        //filtrar o valor da resposta,
        if (
          !(option.values[0] && option.values[0].value == "all") &&
          !(option.key === ReportTypeFilter.SEARCH)
        ) {
          res = res.filter((value) =>
            option.values.some((a) => a.value === value[option.key])
          );
        }
      });
      if (search && search.values.length > 0) {
        res = res.filter((value) =>
          Object.values(value).some((campo) => {
            if (typeof campo == "string") {
              return campo.search(search.values[0].label) !== -1;
            }
            if (typeof campo == "number") {
              return campo.toString().search(search.values[0].label) !== -1;
            }
            return false;
          })
        );
      }
    }

    if (res.length > 0 && options.filtersOperations.length > 0) {
      res = ReportFilters.filterData(
        res,
        options.filtersOperations,
        options.filterUnion
      );
    }
    res = ReportSort.sortData(res, options.ordering, header);

    return res;
  }

  const getData = async (type: ReportEnum, options: ReportOptionsInterface) => {
    let params = {};
    switch (type) {
      case ReportEnum.GROUP_MEMBERS:
        if (options.key) {
          return getInfoGroup(id, options.key).then(
            (res) => res.data.participants
          );
        }
        return;
      case ReportEnum.GROUP_ALL:
        if (options.key) {
          return getGroupsForFolders(options.key).then((res) => {
            let participants: any[] = [];
            res.data.forEach((group) => {
              participants = [
                ...participants,
                ...group.participants.map((participant) => {
                  return {
                    ...participant,
                    group: group.name,
                    Folder_group_id: group.id,
                  };
                }),
              ];
            });
            return participants;
          });
        }
        return;
      case ReportEnum.EVENT_PARTICULAR:
        if (options.datePeriod !== undefined) {
          params = {
            start: options.datePeriod[0],
            finish: options.datePeriod[1],
          };
        }
        return getEvents(params).then((res) => res.data.events);
      case ReportEnum.EVENT_GROUP:
        if (options.datePeriod !== undefined) {
          params = {
            start: options.datePeriod[0],
            finish: options.datePeriod[1],
          };
        }
        return getEventsGroup(params).then((res) => res.data);
      case ReportEnum.CONTACTS_ALL:
        return Promise.resolve(contacts);
      case ReportEnum.CONTACTS_BIRTHDAYS:
        return Promise.resolve(
          contacts
            .filter((contacts) => contacts.birthday)
            .map((contact) => {
              return {
                ...contact,
                anniversary:
                  optionsMounthDate[parseInt(contact.birthday!.substring(5, 7))]
                    .label,
              };
            })
        );
      case ReportEnum.EVENT_CONFIRMATIONS:
        if (options.key && typeof options.key !== "string") {
          return getUsersInEvent(options.key.id, options.key.Group_id).then(
            (res) => res.data.users
          );
        }
        break;
    }
  };

  //Função para dar apenas visibilidades para colunas que possuírem algum dado
  function autoChecked(res: any, header: HeaderTable): HeaderTable {
    const columns = Object.keys(header.itens);

    columns.map((column) => {
      if (column !== "signature" && header.itens[column].checked == undefined)
        if (res.filter((data) => data[column] != undefined).length > 0) {
          header.itens[column].checked = true;
        } else {
          header.itens[column].checked = false;
        }
    });
    return header;
  }

  //UseEffect para quando tipo de relatório mudar
  useEffect(() => {
    //Se ele escolheu um novo relatório realiza as configurações necessárias, senão reseta os estados
    if (optionReportSelected) {
      const options = ReportFunctions.getOptions(optionReportSelected);
      setOptionsPDF(options);
      handleStylesPDF({
        ...stylesPDF,
        title: {
          ...stylesPDF.title,
          text: ReportFunctions.getTitle(optionReportSelected),
        },
      });

      //Se o tipo de relatório não pedir ID, já realiza a busca, senão muda o estado para o de espera
      if (!options.requiredId) {
        setIsWaiting(false);
        setLoading(true);
        setHeader(columnsTable(optionReportSelected));

        getData(optionReportSelected, options)
          .then((res) => {
            setData(res);
            const newHeader = autoChecked(
              res,
              columnsTable(optionReportSelected)
            );
            setHeader(newHeader);
            reportPDF({
              stylesPDF: {
                ...stylesPDF,
                title: {
                  ...stylesPDF.title,
                  text: ReportFunctions.getTitle(optionReportSelected),
                },
              },
              setLoading,
              setSource: setUrlPdf,
              header: ReportFunctions.getHeaderTable(newHeader),
              data: ReportFunctions.getDataTable(
                filterData(res, options, newHeader),
                newHeader
              ),
              widths: ReportFunctions.getWidthsTable(newHeader),
            });
          })
          .finally(() => setLoading(false));
      } else {
        setIsWaiting(true);
        setLoading(false);
        const newHeader = columnsTable(optionReportSelected);
        setHeader(newHeader);
      }
    } else {
      setHeader(undefined);
      setIsWaiting(false);
      setLoading(false);
    }
  }, [optionReportSelected]);

  //UseEffect para quando alguma opção do relatório mudar
  useEffect(() => {
    const isNew =
      optionsPDF != ReportFunctions.getOptions(optionReportSelected!!);
    if (
      isNew &&
      optionReportSelected &&
      header &&
      (!optionsPDF.requiredId || optionsPDF.key)
    ) {
      setLoading(true);
      setIsWaiting(false);
      getData(optionReportSelected, optionsPDF)
        .then((res) => {
          setData(res);
          const newHeader = autoChecked(
            res,
            columnsTable(optionReportSelected)
          );
          setHeader(newHeader);
          reportPDF({
            stylesPDF: {
              ...stylesPDF,
              title: {
                ...stylesPDF.title,
                text: ReportFunctions.getTitle(optionReportSelected),
              },
            },
            setLoading,
            setSource: setUrlPdf,
            header: ReportFunctions.getHeaderTable(newHeader),
            data: ReportFunctions.getDataTable(
              filterData(res, optionsPDF, newHeader),
              newHeader
            ),
            widths: ReportFunctions.getWidthsTable(newHeader),
          });
        })
        .finally(() => setLoading(false));
    }
  }, [optionsPDF]);

  useEffect(() => {
    if (optionReportSelected && data && !loading) {
      setLoading(true);
      let newHeader: HeaderTable;
      if (!header) {
        newHeader = columnsTable(optionReportSelected);
        setHeader(newHeader);
      } else {
        newHeader = header;
      }
      reportPDF({
        stylesPDF: { ...stylesPDF },
        setLoading,
        setSource: setUrlPdf,
        header: ReportFunctions.getHeaderTable(newHeader),
        data: ReportFunctions.getDataTable(
          filterData(data, optionsPDF, newHeader),
          newHeader
        ),
        widths: ReportFunctions.getWidthsTable(newHeader),
      });
    }
  }, [update, stylesPDF, header]);

  const download = () => {
    if (header && optionReportSelected && data) {
      reportPDF({
        stylesPDF: { ...stylesPDF },
        download: true,
        setLoading,
        setSource: setUrlPdf,
        header: ReportFunctions.getHeaderTable(header),
        data: ReportFunctions.getDataTable(
          filterData(data, optionsPDF, header),
          header
        ),
        widths: ReportFunctions.getWidthsTable(header),
      });
    } else {
      //ESPERRE O CARREGAMENTO
    }
  };

  return (
    <ContainerSwitch>
      <ContentChat rightContent={optionReportSelected !== undefined}>
        <ListReports hidden={display} />
        <div
          id="iframeContainer"
          className={`${!display ? `hidden` : ` `} iframeContainer `}
        >
          <div id="mid_column">
            <ViewPdf
              downloadPdf={download}
              isLoading={loading}
              urlPdf={urlPdf}
            />
          </div>
          {display && header && (
            <div className={`options ${openOptions ? `ml2` : ``}`}>
              <OptionReport
                urlPdf={urlPdf}
                open={openOptions}
                isLoading={loading}
                isWaiting={isWaiting}
                optionsPDF={optionsPDF}
                setOptionsPDF={setOptionsPDF}
                data={data}
                download={download}
                setUpdate={setUpdate}
                header={header}
                setHeader={setHeader}
              />
            </div>
          )}
          <FloatingButton isOpen={openOptions} setIsOpen={setOpenOptions} />
        </div>
        {!display && <NoMessage />}
      </ContentChat>
    </ContainerSwitch>
  );
};

export default Report;
