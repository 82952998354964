import { AxiosResponse } from "axios";
import { useRef } from "react";
import { CgClose } from "react-icons/cg";
import { useMutation } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { AiOutlinePaperClip, BiTimeFive, BsCheckLg, BsPeople, CgUserList, FaTrash, FaUserLock, FiMoreVertical, FiUserCheck, FiUserMinus, FiUserX, MdModeEdit, MdOutlineLocationOn, VscCalendar } from "../../../../../assets/icons";
import { Dropdown, Load, Modal } from "../../../../../components";
import Card from "../../../../../components/Card";
import { ModalRef } from "../../../../../components/Modal";
import ModalEvent from "../../../../../components/Modal/ModalEvent";
import { deleteEvent } from "../../../../../services/requests/event";
import { pacthEventUser, postEventUser } from "../../../../../services/requests/event-user";
import { removeEventSchedule } from "../../../../../store/actions/scheduleAction";
import { TypeConstRedux } from "../../../../../types/redux";
import { birthdayFormat } from "../../../../../util/date";
import { ToastModel } from "../../../../../util/error";
import FilesEvent from "./Files";
import PermissionEvent from "./Permission";
import PresenceEvent from "./Presence";
import Status from "./Status";
import { ButtonElement, ContainerButton, Creator, Description, Details, ElementoInfo, Title } from "./style";

const Event = (props) => {

  const { filter, event, index, setEvents, setInvitations, abare, theme, weather } = props;
  const { title, description, Group_id, local, type, dateStart, dateEnd, confirmations, participation,
    status, userCreator, isCanDelete, isCanEdit, isCanPresence, confirmOpen, confirmNumber, category } = event
  const { addToast } = useToasts()
  const dispatch = useDispatch();

  const modalEdit = useRef<ModalRef>(null);
  const dateCreatedAt = new Date(event.dateCreatedAt)
  const dateEdition = event.dateEdition ? new Date(event.dateEdition) : null
  const isOver = new Date().getTime() > new Date(dateEnd).getTime()
  const isAdm = event?.User_id == abare.id
  const dateFormatStart = new Date(dateStart).toString()
  const dateFormatEnd = new Date(dateEnd).toString()
  const modalPresence = useRef<any>();
  const modalPermissions = useRef<any>();
  const modalFiles = useRef<any>()

  const fuso = event && new Date(dateStart)
  //const dateSystem = new Date()

  const globalMessages: any = useSelector<TypeConstRedux>((store) => store.chat.globalMessages);


  const mutateDelete = useMutation(() => deleteEvent(event.id), {
    onSuccess: ({ status }: AxiosResponse) => {
      if (status == 200) {
        dispatch(removeEventSchedule(event));
        ToastModel(addToast, "Evento excluído!", "Seu evento foi excluído com sucesso.", 'success');
      } else {
        ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
      }
    },
    onError: () => {
      ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
    }
  });

  function percentageConfirmations(confirmations: number) {
    if (Group_id && globalMessages[Group_id].client) {
      return `(${((100 * confirmations) / (Object.values(globalMessages[Group_id].client.namesView).length + 1)).toFixed(2)}%)`;
    }
  }

  const enableEditing = () => {
    modalEdit.current?.open();
  }

  const dropEvent = () => {
    if (!(mutateDelete.isLoading)) {
      mutateDelete.mutate();
    }
  }

  const createEventUser = (participation: boolean) => {
    postEventUser(event.id, participation).then((res) => {
      if (res.status == 200) {
        event.participation = participation ? 1 : 0
        setEvents(events => {
          let i = 0
          events.forEach((evemtL, index) => {
            if (evemtL.id == event.id) {
              i = index
            }
          })
          events[i] = event
          return [...events]
        })
        if (participation) {
          ToastModel(addToast, "Convite confirmado!", "Convite confirmado com sucesso.", 'success')
        } else {
          ToastModel(addToast, "Convite recusado!", "Convite recusado com sucesso.", 'success')
        }
      } else {
        ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error')
      }
    })
  }

  const patchEventUser = (participation: number) => {
    pacthEventUser(event.id, participation).then((res) => {
      if (res.status == 200) {
        setEvents(events => {
          event.participation = participation
          let i = 0
          events.forEach((evemtL, index) => {
            if (evemtL.id == event.id) {
              i = index
            }
          })
          events[i] = event
          return [...events]
        })
        if (participation) {
          ToastModel(addToast, "Convite confirmado!", "Convite confirmado com sucesso.", 'success')
        } else {
          ToastModel(addToast, "Compromisso cancelado!", "Seu compromisso foi cancelado com sucesso.", 'success')
        }
      } else {
        ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error')
      }
    })
  }



  const formatDate = () => {
    const ano = fuso.getFullYear();
    const mes = fuso.getMonth() + 1 <= 9 ? `0${fuso.getMonth() + 1}` : fuso.getMonth() + 1;
    const dia = fuso.getDate() <= 9 ? `0${fuso.getDate()}` : fuso.getDate();

    return birthdayFormat(`${ano}-${mes}-${dia}`);
  }
  return (
    <Card>
      <Modal ref={modalPresence}>
        <PresenceEvent modal={modalPresence} event={event} />
      </Modal>
      <Modal ref={modalPermissions}>
        <PermissionEvent modal={modalPermissions} event={event} />
      </Modal>
      <Modal ref={modalFiles} mobile={true}>
        <FilesEvent modal={modalFiles} event={event} />
      </Modal>
      {mutateDelete.isLoading ? <Load className="dropdown" primaryColor={'light'} secundaryColor={'dark'} /> : ((isAdm || !Group_id || isCanPresence || isCanEdit || isCanDelete) &&
        <div className="dropdown" >
          <Dropdown defaultClass="bottom-left">
            <FiMoreVertical id="menu" />
            <div className="widthLista">
              {
                isCanPresence && <li onClick={() => modalPresence.current.open()}>
                  {" "}
                  <CgUserList className="icon" size={18} />Presença
                </li>
              }
              {
                isAdm && Group_id && <li onClick={() => modalPermissions.current.open()}>
                  {" "}
                  <FaUserLock className="icon" size={18} />Permissões
                </li>
              }
              {
                (isCanEdit || !Group_id) && <li onClick={enableEditing}>
                  {" "}
                  <MdModeEdit className="icon" size={18} /> Editar
                </li>
              }
              {
                (isCanDelete || !Group_id) && <form>
                  <li onClick={dropEvent}>
                    {" "}
                    <FaTrash className="icon" size={14} /> Excluir
                  </li>
                </form>
              }
              {
                <form>
                  <li onClick={() => modalFiles.current.open()}>
                    {" "}
                    <AiOutlinePaperClip className="icon" size={18} /> Arquivos
                  </li>
                </form>
              }
              {event.participation === true && filter != 0 && !isOver && confirmOpen == '1' && <li onClick={() => (patchEventUser(1))}>
                {" "}
                <BsCheckLg className="icon" size={14} /> Participar
              </li>}
              {event.participation === false && filter != 0 && !isOver && confirmOpen == '1' && <li onClick={() => (patchEventUser(0))}>
                {" "}
                <CgClose className="icon" size={14} /> Cancelar
              </li>}
            </div>
          </Dropdown>
        </div>
      )}
      {Group_id && <Creator> Criado&nbsp;em&nbsp;<strong>{event.name_group}</strong> &nbsp;-&nbsp; <strong>{userCreator}</strong> &nbsp;-&nbsp; <strong>{dateCreatedAt.toLocaleDateString()}</strong>&nbsp;<strong>{new Date(new Date(dateCreatedAt.setHours(0)).setMinutes(0)).toLocaleTimeString().substring(0, 5).replace(':', 'h')}</strong>
        {dateEdition && <> &nbsp;- Editado:&nbsp;<strong>{dateEdition.toLocaleDateString()}</strong>&nbsp;<strong>{dateEdition.toLocaleTimeString().substring(0, 5).replace(':', 'h')}</strong></>}
      </Creator>}
      <Title>{title}</Title>

      <Description>
        {description}
      </Description>
      <Details>
        <ElementoInfo color={theme.title == 'light' ? "green" : "#1cb900"}>
          <VscCalendar />
          {formatDate()}
        </ElementoInfo>
        <ElementoInfo color={'#ff0018'}>
          <BiTimeFive />
          {dateFormatStart.substring(16, 21) + "h" + " - " + dateFormatEnd.substring(16, 21) + 'h'}
        </ElementoInfo>
        {confirmations &&
          <ElementoInfo color={theme.title == 'light' ? "grey" : "white"}>
            <BsPeople />
            {confirmations}
          </ElementoInfo>
        }
        {Group_id &&
          <ElementoInfo color={theme.title == 'light' ? "grey" : "white"}>
            <BsPeople />
            Confirmação: {confirmNumber} {percentageConfirmations(confirmNumber)}
          </ElementoInfo>
        }
        <Status status={status} />
      </Details>
      {local &&
        <Details data-tip data-for='maps'>
          <a data-tip data-for='maps' href={`https://www.google.com.br/maps/dir/${weather ? `${weather.coord.lat},${weather.coord.lon}` : ``}/${local && local.replace(" ", "+")}/@`} className="button_maps" target="_blank" rel="noopener noreferrer">

            <ElementoInfo color={theme.title == 'light' ? "#11729B" : "#4b9cff"} className="address" border="0">
              <MdOutlineLocationOn />
              {local}
            </ElementoInfo></a>

        </Details>
      }
      {Group_id &&
        <div className="confirmBtn">
          {participation === null && confirmOpen && !isOver ?
            <ContainerButton>
              <ButtonElement onClick={() => createEventUser(true)} background="#03BB85" color="white" border="#03BB85">
                <FiUserCheck size="20px" />
                Confirmar
              </ButtonElement>
              <ButtonElement onClick={() => createEventUser(false)}>
                <FiUserX size="20px" />
                Recusar
              </ButtonElement>
            </ContainerButton>
            : filter === 0 && !isOver && confirmOpen &&
            <ButtonElement onClick={() => patchEventUser(0)}>
              <FiUserMinus size="20px" />
              Cancelar compromisso
            </ButtonElement>
          }
        </div>
      }
      <ReactTooltip id='maps'>
        <span>Abrir maps até o evento</span>
      </ReactTooltip>
      <ModalEvent modal={modalEdit} event={event} />

    </Card >
  );
}

const mapStateToProps = (state) => {
  return {
    abare: state.abare.abare,
    theme: state.abare.theme,
    weather: state.dashboard.weather_dash,
  }
}

export default connect(mapStateToProps)(Event)