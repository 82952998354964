import { ContentImage } from "pdfmake/interfaces";
import { ChangeEvent, useRef } from "react";
import { BiAlignMiddle, BiCaretDownSquare, BiCaretLeftSquare, BiCaretRightSquare, BiCaretUpSquare, BiImageAdd } from "react-icons/bi";
import { BsImages } from "react-icons/bs";
import { TbArrowAutofitHeight, TbArrowAutofitWidth, TbBoxMargin } from "react-icons/tb";
import { InputSelect } from "../../../../../components";
import { ContainerInput } from "../../../../../components/Input/style";
import { ReportStylesInterface } from "../../../../../types/project";
import { ContainerImage } from "../Blocks/VisualOptions/style";
import { ItemModal } from "../style";

interface ColumnsProps {
    valueImage: ContentImage;
    setValueImage: React.Dispatch<React.SetStateAction<ContentImage>>;
    isEdit?: boolean;
}

const ImageOptions = ({ valueImage, setValueImage, isEdit = true }: ColumnsProps) => {
    const fontValues = [
        { value: "roboto", label: "Roboto" },];
    const alignmentValues = [
        { value: "left", label: "Esquerda" },
        { value: "right", label: "Direita" },
        { value: "center", label: "Centralizada" },
        { value: "justify", label: "Justificada" }];
    const inputRef = useRef<HTMLInputElement>(null);
    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const list: File[] = Array.from(e.target.files ? e.target.files : [])

        list && list.forEach(async (file: File) => {
            const url = URL.createObjectURL(file);
            convertImgToBase64URL(url, (dataUrl) => {
                console.log(dataUrl);
                console.log(valueImage)
                console.log({ ...valueImage, image: dataUrl, })

                setValueImage({ ...valueImage, image: dataUrl, link: url, });
            })
        });
    }

    function convertImgToBase64URL(url, callback) {
        var canvas = document.createElement<any>('CANVAS'),
            ctx = canvas.getContext('2d'),
            img = new Image;
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var dataURL;
            canvas!.height! = img.height;
            canvas!.width! = img.width;
            ctx.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL('image/png');
            callback(dataURL);
            canvas = null;
        };
        img.src = url;
    }

    return (
        <ItemModal>
            <div className="flex" style={{ minWidth: "400px", flex: "1" }}>
                {/* Texto */}
                <div className="icon">
                    <BiImageAdd title="Imagem" size="20px" />
                </div>
                {/* <DropZone message={"Adicione uma imagem em formato jpeg ou png"} maxFiles={1} image={true} multiple={false} disabled={false} onDrop={onDrop} /> */}
                {valueImage.link ? <ContainerImage>
                    <img src={valueImage.link} />
                    {isEdit && <div className="buttons">
                        <BsImages className="icon-color" onClick={() => { inputRef!.current!.click() }} size={18} />
                    </div>}

                </ContainerImage> : <input onChange={handleFileSelected} type="file" multiple={false} accept="image/*" />}
            </div>
            <input ref={inputRef} style={{ display: "none" }} onChange={handleFileSelected} type="file" multiple={false} accept="image/*" />
            <div className="flex" style={{ minWidth: "150px", flex: "1" }}>
                {/* Campo de alinhamento da coluna */}
                <div className="icon">
                    <BiAlignMiddle title="Alinhamento" size="20px" />
                </div>
                {isEdit ? <InputSelect
                    $flex={true}
                    value={alignmentValues.find((option) => (option.value === valueImage.alignment))}
                    onChange={(e) => {
                        valueImage.alignment = e.value
                        setValueImage({ ...valueImage, alignment: e.value })
                    }} placeholder="Alinhamento" options={alignmentValues} /> :
                    <p>{alignmentValues.find((option) => (option.value === valueImage.alignment))?.label}</p>


                }

            </div>
            <div className="flex" style={{ minWidth: "70px" }}>


                {/* Campo de Altura da imagem */}
                <div className="icon ">
                    <TbArrowAutofitHeight title="Altura da imagem" size="20px" />
                </div>

                {
                    isEdit ? <ContainerInput $width="50px">
                        <input
                            name='height_img'
                            value={valueImage!.fit![0]}
                            onChange={(e) => {
                                setValueImage({ ...valueImage, fit: [parseInt(e.target.value), valueImage!.fit![1]] });
                            }}
                            id='height_img'
                            type="number"
                        // placeholder="Digite o tamanho da fonte..."
                        />
                        pt
                    </ContainerInput> : <p>{valueImage!.fit![0]}</p>


                }

            </div>
            <div className="flex" style={{ minWidth: "70px" }}>

                {/* Campo de largura da imagem */}
                <div className="icon ">
                    <TbArrowAutofitWidth title="Largura da imagem" size="20px" />
                </div>
                {
                    isEdit ?
                        <ContainerInput $width="50px">
                            <input
                                name='width_img'
                                value={valueImage!.fit![1]}
                                onChange={(e) => {
                                    setValueImage({ ...valueImage, fit: [valueImage!.fit![0], parseInt(e.target.value)] });
                                }}
                                id='width_img'
                                type="number"
                            // placeholder="Digite o tamanho da fonte..."
                            />
                            pt
                        </ContainerInput>
                        : <p>{valueImage!.fit![1]}</p>

                }


            </div>

            {/* Campo de Margin da pagina */}
            <div className="flex" style={{ minWidth: "200px", flex: "1" }}>
                <div className="icon">
                    <TbBoxMargin title="Margem" size="20px" />
                </div>

                {isEdit ? <>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_left'
                            title="Margem esquerda"
                            value={valueImage.margin![0]}
                            onChange={(e) => { setValueImage({ ...valueImage, margin: [parseInt(e.target.value), valueImage.margin![1], valueImage.margin![2], valueImage.margin![3]] }) }}

                            id='margin_left'
                            type="number"
                            placeholder="Esquerda"
                        />
                        <BiCaretLeftSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_top'
                            title="Margem superior"

                            value={valueImage.margin![1]}
                            onChange={(e) => { setValueImage({ ...valueImage, margin: [valueImage.margin![0], parseInt(e.target.value), valueImage.margin![2], valueImage.margin![3]] }) }}
                            id='margin_top'
                            type="number"
                            placeholder="Topo"
                        />
                        <BiCaretUpSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_right'
                            title="Margem direita"

                            value={valueImage.margin![2]}
                            onChange={(e) => { setValueImage({ ...valueImage, margin: [valueImage.margin![0], valueImage.margin![1], parseInt(e.target.value), valueImage.margin![3]] }) }}
                            id='margin_right'
                            type="number"
                            placeholder="Direita"
                        />
                        <BiCaretRightSquare />
                    </ContainerInput>
                    <ContainerInput>
                        <input
                            title="Margem inferior"
                            name='margin_bottom'
                            value={valueImage.margin![3]}
                            onChange={(e) => { setValueImage({ ...valueImage, margin: [valueImage.margin![0], valueImage.margin![1], valueImage.margin![2], parseInt(e.target.value)] }) }}
                            id='margin_bottom'
                            type="number"
                            placeholder="Baixo"
                        />
                        <BiCaretDownSquare />

                    </ContainerInput>
                </>
                    : <p>{`[${valueImage.margin![0]},${valueImage.margin![1]},${valueImage.margin![2]},${valueImage.margin![3]}]`}</p>
                }

            </div>
        </ItemModal>
    );
}

export default ImageOptions;