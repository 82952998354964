import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { Container, TitleBar } from "./style";
import { useDispatch } from "react-redux";
import { setOptionConfig } from "../../../../../store/actions/configAction";
import { MouseEventHandler } from "react";

const SettingsContainer = (props) => {
  const { title, children, onClick, isIcon, menu } = props;
  const dispatch = useDispatch();
  function closeMenu(e: React.MouseEvent<SVGElement, MouseEvent>) {
    e.stopPropagation();
    dispatch(setOptionConfig(null));
  }

  return (
    <Container>
      <TitleBar onClick={onClick}>
        <h3>{title}</h3>
        <IoIosClose onClick={closeMenu} id="icon" />

        {menu && menu()}
      </TitleBar>
      <div className="barra">{children}</div>
    </Container>
  );
};

export default SettingsContainer;
