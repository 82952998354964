import { useCallback, useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { InputSelect, ItemFile, TextArea } from "../../../../components";
import FloatingButton from "../../../../components/Button/FloatingButton/FloatingButton";
import Dropzone from "../../../../components/DropZone";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import { getProtocolCategory, getProtocols, postProtocol } from "../../../../services/requests/protocol";
import { TypeImage } from "../../../../types/project";
import { ToastModel } from "../../../../util/error";
import { getTypeMessage } from "../../../../util/verification";
import ProtocolSend from "./ProtocolSend";
import { Container, ContainerContent, Item } from "./style";

interface ProtocolProps {
    description: string;
    number: string;
    tema: string;
    images: TypeImage[];
    modalRef: any;
}


const Protocol = ({ modalRef }) => {
    const [description, setDescription] = useState<string>('');
    const [sending, isSending] = useState<boolean>(false);
    const [images, setImages] = useState<TypeImage[]>([]);
    const [optionsAssuntos, setOptionsAssuntos] = useState<Object[]>([]);
    const [assuntoSelected, setAssuntoSelected] = useState<string | null>(null);
    const [protocolSend, setProtocolSend] = useState<ProtocolProps | null>(null);
    const { tokenBlock } = useParams<{ tokenBlock }>();
    const { addToast } = useToasts();
    const { goBack } = useHistory();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.map((file) => {
            const url = URL.createObjectURL(file);
            const type = getTypeMessage(file);

            setImages((oldImages) => [...oldImages, { defaultUrl: url, newUrl: url, file: file, type: type }]);
        });
    }, []);

    const removeItem = (position) => {
        images.splice(position, 1);
        setImages([...images]);
    };

    const create = (e) => {
        e.preventDefault();

        if (!sending) {
            isSending(true);
            if (assuntoSelected == null) {
                ToastModel(
                    addToast,
                    "Campo necessário.",
                    "Selecione o assunto do protocolo.",
                    "warning"
                );
                return
            };
            const dataForm = new FormData();
            images.map((image) => dataForm.append('file', image.file));
            dataForm.append('description', description);
            dataForm.append('protocolCategoryId', assuntoSelected);
            postProtocol(dataForm, tokenBlock).then((res) => {
                if (res.status == 201) {
                    setProtocolSend({
                        description: description,
                        tema: optionsAssuntos[assuntoSelected].label,
                        number: res.data.number,
                        images: images,
                        modalRef: modalRef,
                    })
                    ToastModel(
                        addToast,
                        "Protocolo criado.",
                        "Obrigado pela sua contribuição.",
                        "success"
                    );
                } else {
                    ToastModel(addToast, "Erro ao enviar protocolo", "Por favor tente novamente mais tarde", "error")

                }
            }).finally(() => {
                isSending(false);
            });
        }
    };

    useEffect(() => {
        if (optionsAssuntos.length <= 0) {
            getProtocolCategory(tokenBlock).then((res) => {
                const result = res.data.map((data) => ({ value: data.id, label: data.name }));
                setOptionsAssuntos(result);

            });
        };
        getProtocols(tokenBlock).then((res) => {
            console.log('lista de protocolos');
            console.log(res);
        });
    }, []);

    return (
        <>
            {protocolSend ?
                <ProtocolSend {...protocolSend} /> :
                <ModalContainer padding={false} title="Protocolo" onClick={() => modalRef ? modalRef.current.close() : goBack()} buttonBack={true}  >

                    <Container onSubmit={create}>
                        <FloatingButton position="bottom-left" onclick={() => modalRef ? modalRef.current.close() : goBack()} />
                        <FloatingButton mobile={false} type="submit" size="large" icon={sending ? "load" : "send"} position="bottom-right" />

                        <ContainerContent >
                            <InputSelect
                                onChange={(event) => { setAssuntoSelected(event.value) }}
                                label={"Qual o assunto?"}
                                options={[{ label: 'Assuntos', options: optionsAssuntos }]}
                                defaultValue={{ label: "Selecione o assunto" }} />
                            <TextArea required value={description}
                                onChange={event => setDescription(event.target.value)}
                                label="Descreva o problema" />

                            <Dropzone disabled={images.length <= 2 ? false : true} onDrop={onDrop} />
                            {images && images.map(({ newUrl, type, file }, i) => (
                                <Item key={i} >
                                    <div className="media">
                                        <ItemFile className="img-video" type={type} src={newUrl} />
                                    </div>
                                    <p>{file.name}</p>
                                    <IoIosClose onClick={(e) => (e.stopPropagation(), removeItem(i))} />
                                </Item>
                            ))}
                            {/* <button type="submit">
                            {load ? <Load width="13px" /> : `Enviar`}
                        </button> */}

                        </ContainerContent>
                    </Container>
                </ModalContainer>}
        </>

    )
};

export default Protocol;

