import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconSchedule = ({
  color = "#666666",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    > 
      <g clipPath="url(#clip0_5711_519)">
        <g clipPath="url(#clip1_5711_519)">
          <path
            d="M2.76923 2.59619H21.2308C22.346 2.59619 23.25 3.50023 23.25 4.61542V21.2308C23.25 22.346 22.346 23.25 21.2308 23.25H2.76923C1.65404 23.25 0.75 22.346 0.75 21.2308V4.61542C0.75 3.50023 1.65404 2.59619 2.76923 2.59619Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M14.3077 11.9999C15.0724 11.9999 15.6923 11.38 15.6923 10.6153C15.6923 9.85063 15.0724 9.23071 14.3077 9.23071C13.543 9.23071 12.9231 9.85063 12.9231 10.6153C12.9231 11.38 13.543 11.9999 14.3077 11.9999Z"
            fill={color}
          />
          <path
            d="M18.9231 11.9999C19.6878 11.9999 20.3077 11.38 20.3077 10.6153C20.3077 9.85063 19.6878 9.23071 18.9231 9.23071C18.1584 9.23071 17.5385 9.85063 17.5385 10.6153C17.5385 11.38 18.1584 11.9999 18.9231 11.9999Z"
            fill={color}
          />
          <path
            d="M14.3077 16.6154C15.0724 16.6154 15.6923 15.9955 15.6923 15.2308C15.6923 14.4661 15.0724 13.8462 14.3077 13.8462C13.543 13.8462 12.9231 14.4661 12.9231 15.2308C12.9231 15.9955 13.543 16.6154 14.3077 16.6154Z"
            fill={color}
          />
          <path
            d="M18.9231 16.6154C19.6878 16.6154 20.3077 15.9955 20.3077 15.2308C20.3077 14.4661 19.6878 13.8462 18.9231 13.8462C18.1584 13.8462 17.5385 14.4661 17.5385 15.2308C17.5385 15.9955 18.1584 16.6154 18.9231 16.6154Z"
            fill={color}
          />
          <path
            d="M5.07688 16.6154C5.84158 16.6154 6.46149 15.9955 6.46149 15.2308C6.46149 14.4661 5.84158 13.8462 5.07688 13.8462C4.31217 13.8462 3.69226 14.4661 3.69226 15.2308C3.69226 15.9955 4.31217 16.6154 5.07688 16.6154Z"
            fill={color}
          />
          <path
            d="M9.69235 16.6154C10.4571 16.6154 11.077 15.9955 11.077 15.2308C11.077 14.4661 10.4571 13.8462 9.69235 13.8462C8.92765 13.8462 8.30774 14.4661 8.30774 15.2308C8.30774 15.9955 8.92765 16.6154 9.69235 16.6154Z"
            fill={color}
          />
          <path
            d="M5.07688 21.2307C5.84158 21.2307 6.46149 20.6107 6.46149 19.846C6.46149 19.0813 5.84158 18.4614 5.07688 18.4614C4.31217 18.4614 3.69226 19.0813 3.69226 19.846C3.69226 20.6107 4.31217 21.2307 5.07688 21.2307Z"
            fill={color}
          />
          <path
            d="M9.69235 21.2307C10.4571 21.2307 11.077 20.6107 11.077 19.846C11.077 19.0813 10.4571 18.4614 9.69235 18.4614C8.92765 18.4614 8.30774 19.0813 8.30774 19.846C8.30774 20.6107 8.92765 21.2307 9.69235 21.2307Z"
            fill={color}
          />
          <path
            d="M14.3077 21.2307C15.0724 21.2307 15.6923 20.6107 15.6923 19.846C15.6923 19.0813 15.0724 18.4614 14.3077 18.4614C13.543 18.4614 12.9231 19.0813 12.9231 19.846C12.9231 20.6107 13.543 21.2307 14.3077 21.2307Z"
            fill={color}
          />
          <path
            d="M24 7.46143H0"
            stroke={color}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M5 1V2"
            stroke={color}
            strokeWidth="1.7"
            strokeLinecap="round"
          />
          <path
            d="M19 1V2"
            stroke={color}
            strokeWidth="1.7"
            strokeLinecap="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5711_519">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_5711_519">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconSchedule;
