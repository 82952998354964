import styled from "styled-components";
import { mobile } from "../../../src/components/NavBar/style";
import { ContainerInput } from "../Input/style";

export const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.default.darkerI};

  .send {
    color: #fff;
  }

  .close {
    font-size: 22px;
    color: #b82121;
  }

  .finish {
    font-size: 20px;
    color: #008b0e;
  }

  .play {
    font-size: 20px;
    color: ${(props) => props.theme.purple.dark};
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 101;
  background-color: #1d1a24;
  overflow: auto;
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  height: 65px;
  background-color: ${(props) => props.theme.darkBlue.darker};
  color: #fff;
  padding: 0 20px;
  z-index: 102;
  font-family: "inter-medium", sans-serif;

  @media only screen and (max-width: ${mobile}) {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
  }
  .sendFor {
    display: flex;
    align-items: center;
    .photoUrl {
      margin: 0 0.5rem;
      border-radius: 50%;
      height: 43px;
      width: 43px;
      object-fit: cover;
    }
  }

  .principal {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .name-file {
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 20px;
    justify-self: center;
    margin: 0;
    resize: horizontal;
  }

  .options {
    display: grid;
    grid-template-columns: 30px 30px;
    justify-content: center;
    align-items: center;
    transition: ease 0.2s;

    .icons {
      cursor: pointer;
      color: #fff;
      font-size: 17px;
      &:hover {
        color: ${(props) => props.theme.darkBlue.lightI};
      }
    }
  }

  .self-end {
    justify-self: end;
  }
  #close {
    color: #fff;
    font-size: 23px;
  }
`;

export const PicturePanel = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 55px auto 78px;
  min-height: 100vh;
  @media only screen and (max-width: ${mobile}) {
    height: 100vh;
    max-height: 100vh;
  }
  .centered {
    display: grid;
    justify-content: center;
    align-items: center;

    #recordVideo {
      max-height: 100vh;
      max-width: 100vh;
    }
  }

  .navFooter {
    display: grid;
    grid-template-columns: 60px;
    grid-template-rows: 78px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.darkBlue.darker};

    .back-mobile {
      display: none;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      font-size: 28px;
      transition: ease 0.2s;
    }

    .active {
      background-color: ${(props) => props.theme.darkBlue.ligther};
      color: ${(props) => props.theme.darkBlue.darkerI};

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 4px #5a4d77;
      }
    }

    .disabled {
      background-color: ${(props) => props.theme.darkBlue.darkerI};
      color: ${(props) => props.theme.darkBlue.ligther};
      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 4px #363241;
      }
    }
  }

  @media only screen and (max-width: ${mobile}) {
    .navFooter {
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: 50px;
      display: flex;
      background: none;

      .back-mobile {
        margin-right: 1rem;
        display: flex;
      }

      .camera {
        height: 65px;
        width: 65px;
      }
    }

    .back {
      display: none;
    }
  }
`;

export const PreviewPanel = styled.div`
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto; */

  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: auto;

  @media only screen and (max-width: ${mobile}) {
    position: relative;
  }

  .image-centered {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    overflow-x: auto;
    .img-video {
      max-height: 90%;
      max-width: 90%;
      justify-self: center;
      align-self: center;
    }
    @media only screen and (max-width: ${mobile}) {
      flex-grow: 1;

      .img-video {
        max-height: 100vh;
        max-width: 100%;
      }
      img {
        max-height: 100vh;
        height: auto;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .previewFooter {
    display: grid;
    grid-template-columns: 50px auto 50px;
    grid-template-rows: 78px;
    padding: 0px 4px;
    justify-items: center;
    align-items: center;
    color: #fff;
    z-index: 103;

    background-color: ${(props) => props.theme.darkBlue.darker};
    @media only screen and (max-width: ${mobile}) {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0px;
      background: none;
    }
  }
`;

export const Input = styled(ContainerInput)`
  width: 98%;
  background-color: #dedede;

  input {
    height: 35px;

    &:focus {
      -webkit-text-fill-color: ${(props) => props.theme.darkBlue.darker};
    }
  }
  #send {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.default.darkerI};
  }

  &:focus-within {
    color: ${(props) => props.theme.darkBlue.dark};
    -webkit-text-fill-color: ${(props) => props.theme.darkBlue.lightI};
    border: 1px solid ${(props) => props.theme.darkBlue.lightI};
  }
`;

export const CropPanel = styled(PreviewPanel)`
  .container {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;
  }

  .item {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: blue;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .content-crop {
    position: relative;
    flex: 1;
    .my-crop {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 100%;
      width: 100%;
      max-height: 80%;
    }
  }

  .navFooter {
    display: grid;
    grid-template-columns: 60px 60px;
    grid-template-rows: 78px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.darkBlue.darker};

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      font-size: 28px;
      transition: ease 0.2s;
    }

    .active {
      background-color: ${(props) => props.theme.darkBlue.ligther};
      color: ${(props) => props.theme.darkBlue.darkerI};

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 4px #5a4d77;
      }
    }

    .disabled {
      background-color: ${(props) => props.theme.darkBlue.darkerI};
      color: ${(props) => props.theme.darkBlue.ligther};
      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 0px 4px #363241;
      }
    }
  }
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  padding: 0px;
  border: 1px solid ${(props) => props.theme.darkBlue.ligther};
  color: #fff;
  background-color: ${(props) => props.theme.darkBlue.lightI};
  height: 75px;
  width: 75px;
  margin-top: 15px;
  font-size: 45px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .media {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
  }

  img,
  video {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
  }
`;

export const ScrollVertical = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  min-width: 125px;
  max-height: 100%;

  @media only screen and (max-width: ${mobile}) {
    position: absolute !important;
    left: 0px;
    top: initial;
    bottom: 50px;
  }

  .itens {
    padding: 0px 1.5rem;
    overflow: hidden;
    overflow-y: auto;
    .more {
      border: 2px solid ${(props) => props.theme.darkBlue.ligther};
      color: ${(props) => props.theme.darkBlue.ligther};
      min-height: 75px;
      min-width: 75px;
      margin-top: 15px;

      &:hover {
      }
    }
  }

  @media only screen and (max-width: 740px) {
    display: block;
    position: static;
    top: none;
    transform: none;
    max-width: none;
    max-height: none;
    padding: 1rem 1rem;

    .itens {
      display: flex;
      overflow: hidden;
      overflow-x: auto;
      padding: 10px 0px;

      ${Item} {
        margin-right: 20px;
      }
    }
  }
`;

export const MainItem = styled.div`
  display: flex;
  padding: 0px;
  border: 1px solid ${(props) => props.theme.darkBlue.ligther};
  color: #fff;
  background-color: ${(props) => props.theme.darkBlue.lightI};
  height: 50vh;
  width: 50vh;
  justify-self: center;
  align-self: center;
  font-size: 150px;
  align-items: center;
  justify-content: center;
`;

export const BtnRemove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: ${(props) => props.theme.darkBlue.lightI};
  border: 1px solid ${(props) => props.theme.darkBlue.ligther};
  color: ${(props) => props.theme.darkBlue.ligther};
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 50%;
`;
