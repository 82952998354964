import { useState } from "react";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { BiAlignMiddle, BiBold, BiCaretDownSquare, BiCaretLeftSquare, BiCaretRightSquare, BiCaretUpSquare, BiFontColor, BiFontFamily, BiFontSize, BiItalic } from "react-icons/bi";

import { MdSubtitles, MdViewColumn } from "react-icons/md";
import { TbBoxMargin } from "react-icons/tb";
import { InputSelect } from "../../../../../components";
import { Checkbox, InputColor } from "../../../../../components/Input";
import { ContainerInput } from "../../../../../components/Input/style";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import { ItemModal } from "../style";

interface ColumnsProps {
    modal: any;
    columnsTable: string[];
    header: HeaderTable;
    setHeader: React.Dispatch<React.SetStateAction<HeaderTable | undefined>>;

}

const ColumnsModal = ({ columnsTable, modal, header, setHeader }: ColumnsProps) => {
    const [myProps, setMyProps] = useState<HeaderTable>(header);
    const alignmentValues = [
        { value: "left", label: "Esquerda" },
        { value: "right", label: "Direita" },
        { value: "center", label: "Centralizada" },
        { value: "justify", label: "Justificada" }];
    const fontValues = [
        { value: "roboto", label: "Roboto" },];
    const columnValues = columnsTable.map((column) => { return { value: column, label: header.itens[column].label } });
    const [columnSelect, setColumnSelect] = useState(columnsTable[0]);

    function saveChange() {
        setHeader({ ...myProps });
        modal.current.close();
    }
    const item = myProps.itens[columnSelect]
    const customerWidth = (item.width != '*' && item.width != 'auto')
    const valueAlign = item.alignment ? alignmentValues.find((option) => (option.value === item.alignment)) : { value: "left", label: "Esquerdo" };

    return (
        <ModalContainer padding={false} onButtonRight={saveChange} buttonConfirmation={true} title="Configurações das colunas" onClick={() => modal.current.close()} buttonBack={true}>
            <ItemModal>
                <div className="flex" style={{ minWidth: "230px", flex: "1" }}>
                    <div className="icon margin-right">
                        <MdViewColumn title="Nome da coluna" size="20px" />
                    </div>
                    <InputSelect
                        $flex={true}
                        value={columnValues.find((option) => (option.value === columnSelect))}
                        onChange={(e) => { setColumnSelect(e.value) }}
                        placeholder="Selecione a coluna que deseja modificar" options={columnValues} />

                </div>

                {/* Campo de alinhamento da coluna */}
                <div className="flex" style={{ minWidth: "230px", flex: "1" }}>
                    <div className="icon margin-right">
                        <BiAlignMiddle title="Alinhamento" size="20px" />
                    </div>
                    <InputSelect
                        $flex={true}
                        value={valueAlign}
                        onChange={(e) => {
                            myProps.itens[columnSelect].alignment = e.value
                            setMyProps({ ...myProps })
                        }} placeholder="Selecione o estilo de borda" options={alignmentValues} />

                </div>

                {/* Campo de nome da coluna */}
                <div className="flex" style={{ width: "100%" }}>
                    <div className="icon margin-right">
                        <MdSubtitles title="Título da coluna" size="20px" />
                    </div>
                    <ContainerInput>
                        <input
                            name='title_report'
                            value={item.label}
                            onChange={(e) => {
                                myProps.itens[columnSelect].label = e.target.value
                                setMyProps({ ...myProps })
                            }}
                            id='title_report'
                            placeholder="Digite o título aqui ..."
                        />

                    </ContainerInput>


                </div>
                {/* Tamanho fonte */}

                <div className="flex col-1">
                    <div className="icon margin-right">
                        <BiFontSize title="Tamanho fonte" size="20px" />
                    </div>
                    <ContainerInput $width="60px">
                        <input
                            name='font_size'
                            value={item.fontSize?.toString()}
                            onChange={(e) => {
                                myProps.itens[columnSelect].fontSize = parseInt(e.target.value);
                                setMyProps({ ...myProps })
                            }}
                            id='font_size'
                            type="number"
                        // placeholder="Digite o tamanho da fonte..."
                        />
                        pt
                    </ContainerInput>
                </div>

                {/* Familia da Fonte*/}
                <div className="flex" style={{ flex: "1", minWidth: "135px" }}>

                    <div className="icon margin-right">
                        <BiFontFamily title="Familia da fonte" size="20px" />
                    </div>
                    <InputSelect
                        $flex={true}
                        value={fontValues[0]}
                        onChange={(e) => {

                            // myProps.itens[columnSelect].fontSize = parseInt(e.target.value);
                            // setMyProps({ ...myProps })
                        }}
                        placeholder="Familia da fonte" options={fontValues} />



                </div>
                {/* Campo para font em negrito */}
                <div className="flex">
                    {/* Cor fonte */}

                    <div className="icon margin-right">
                        <BiFontColor title="Cor da fonte" size="20px" />
                    </div>
                    <InputColor value={item.color!}
                        onChange={(color) => {
                            myProps.itens[columnSelect].color = color.hex
                            setMyProps({ ...myProps })
                        }}
                    />
                </div>
                {/* Campo para font em negrito */}
                <div className="flex">

                    <div className="icon ">
                        <BiBold title="Negrito" size="20px" />
                    </div>
                    <Checkbox
                        checked={item.bold ?? false}
                        name='bold_font'
                        onChange={() => {
                            myProps.itens[columnSelect].bold = !myProps.itens[columnSelect].bold
                            setMyProps({ ...myProps })
                        }}
                        id='bold_font'
                    />
                    {/* Campo para font em negrito */}

                    <div className="icon ">
                        <BiItalic title="Itálico" size="20px" />
                    </div>
                    <Checkbox
                        checked={item.italics ?? false}
                        name='italic_font'
                        onChange={() => {
                            myProps.itens[columnSelect].italics = !myProps.itens[columnSelect].italics
                            setMyProps({ ...myProps })
                        }}
                        id='italic_font'
                    />

                </div>

                {/* Campo de Margin da pagina */}
                <div className="flex col-8">

                    <div className="icon margin-right">
                        <TbBoxMargin title="Margem" size="20px" />
                    </div>

                    <ContainerInput $marginRight="8px">
                        <input
                            name=''
                            title="Margem esquerda"
                            value={item.margin![0]}
                            onChange={(e) => {
                                myProps.itens[columnSelect].margin = [parseInt(e.target.value), item.margin![1], item.margin![2], item.margin![3]]
                                setMyProps({ ...myProps })
                            }}

                            id=''
                            type="number"
                            placeholder="Esquerda"
                        />
                        <BiCaretLeftSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_top'
                            title="Margem superior"

                            value={item.margin![1]}
                            onChange={(e) => {
                                myProps.itens[columnSelect].margin = [item.margin![0], parseInt(e.target.value), item.margin![2], item.margin![3]]
                                setMyProps({ ...myProps })
                            }}
                            id='margin_top'
                            type="number"
                            placeholder="Topo"
                        />
                        <BiCaretUpSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_right'
                            title="Margem direita"

                            value={item.margin![2]}
                            onChange={(e) => {
                                myProps.itens[columnSelect].margin = [item.margin![0], item.margin![1], parseInt(e.target.value), item.margin![3]]
                                setMyProps({ ...myProps })
                            }} id='margin_right'
                            type="number"
                            placeholder="Direita"
                        />
                        <BiCaretRightSquare />
                    </ContainerInput>
                    <ContainerInput>
                        <input
                            title="Margem inferior"
                            name='margin_bottom'
                            value={item.margin![3]}
                            onChange={(e) => {
                                myProps.itens[columnSelect].margin = [item.margin![0], item.margin![1], item.margin![2], parseInt(e.target.value)]
                                setMyProps({ ...myProps })
                            }} id='margin_bottom'
                            type="number"
                            placeholder="Baixo"
                        />
                        <BiCaretDownSquare />
                    </ContainerInput>
                </div>
                <div className="flex col-8">
                    {/* Campo de largura da coluna */}
                    <div className="icon margin-right">
                        <AiOutlineColumnWidth title="Largura da coluna" size="20px" />
                    </div>
                    <label><input onChange={() => {
                        myProps.itens[columnSelect].width = 'auto';
                        setMyProps({ ...myProps });
                    }} checked={item.width == 'auto'} type="radio" />Justa</label>
                    <label><input onChange={() => {
                        myProps.itens[columnSelect].width = '*';
                        setMyProps({ ...myProps });
                    }} checked={item.width == '*'} type="radio" />Automática</label>

                    <label className="marginLeft" ><input onChange={() => {
                        myProps.itens[columnSelect].width = 50;
                        setMyProps({ ...myProps });
                    }} checked={customerWidth} type="radio" />Personalizada</label>
                    <ContainerInput $invisible={!customerWidth} $marginLeft="8px" $width="50px">
                        <input
                            name='title_report'
                            value={myProps.itens[columnSelect].width}
                            onChange={(e) => {
                                myProps.itens[columnSelect].width = parseInt(e.target.value);
                                setMyProps({ ...myProps });
                            }}
                            id='title_report'
                            type="number"
                        // placeholder="Digite a altura das linhas ..."
                        />
                        pt
                    </ContainerInput>
                </div >

            </ItemModal>
        </ModalContainer >
    );
}

export default ColumnsModal;