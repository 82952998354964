import styled from "styled-components";

interface StyledProps {
  active?: boolean;
}

export const Conversation = styled.label<StyledProps>`
  display: flex;
  align-items: center;
  padding-inline: 1rem;
  cursor: pointer; 
  background-color: ${(props) => props.active ? props.theme.backgrounds.conversaSelecionada : props.theme.backgrounds.folderTalk} ;
  
  &:hover {
    background-color: ${(props) => props.theme.backgrounds.lightGrey};
  }

  .profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  #col-message {
    padding: 1.2rem 0.7rem;
    width: 100%;
    #name {
      margin: 0;
      padding: 0;
      font-size:  ${(props) => props.theme.fonts.text_lg};
      color: ${(props) => props.theme.texts.darkerGreyI};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
    }

    #message {
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: ${(props) => props.theme.fonts.text_sm};
      color: ${(props) => props.theme.texts.darkGreyI};

      .fileIcon {
        display: grid;
        grid-template-columns: 20px auto;
        align-items: center;

        .fileName{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  #col-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #notification {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 13px;
      width: 20px;
      height: 20px;
      color: white;
      background-color: ${(props) => props.theme.highlights.default};
    }
    #time {
      font-size:  ${(props) => props.theme.fonts.text_xs};
      color: ${(props) => props.theme.texts.darkGreyI};
    }

    #time-notify {
      font-size:  ${(props) => props.theme.fonts.text_xs};
      color: ${(props) => props.theme.highlights.default};
    }
  }
`;
