import { api } from "../api";
import { getToken } from "../auth";

export const getCategorys = () => {
  return api({
    url: "/event-category/find-all",
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getActivesCategorys = () => {
  return api({
    url: "/event-category/find-actives",
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const postCreateCategorys = (data: { name: string }) => {
  return api({
    url: "/event-category",
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const patchUpdateCategorys = (data: {
  name: string;
  id: number;
  active: boolean;
}) => {
  return api({
    url: "/event-category",
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getHasCategoryInEvent = (categoryId: number) => {
  return api({
    url: "/event-category/find-in-any-event/" + categoryId,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const deleteCategory = (data: any) => {
  return api({
    url: "/event-category",
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};
