import { api } from "../api";
import { getToken } from "../auth";

export const postEvent = (data) => {
  return api({
    url: `/event/`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getEvents = (params) => {
  return api({
    url: `/event/all-events`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    params: params,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getEventsGroup = (params) => {
  return api({
    url: `/event/group`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    params: params,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getEventsGroupById = (groupId) => {
  return api({
    url: `/event/group-especific/${groupId}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const patchEvent = (eventid, data) => {
  return api({
    url: `/event/${eventid}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const deleteEvent = (id) => {
  return api({
    url: `/event/${id}`,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getFilesEvent = (id) => {
  return api({
    url: `/event-file/${id}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const postFileEvent = (data) => {
  return api({
    url: `/event-file`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const deleteFileEvent = (fileId) => {
  return api({
    url: `/event-file/${fileId}`,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};
