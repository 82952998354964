import styled from "styled-components";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import { colapsedSchedule } from "../../../../components/NavBar/style";

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;

  img {
    max-width: 600px;
    width: 100%;
    height: auto;
  }
  .alert {
    display: grid;
    grid-template-rows: auto auto;

    align-content: center;
    text-align: center;

    #title {
      font-size: 60px;
      color: ${(props) => props.theme.texts.purpleDark};
      font-family: "inter-medium";
      margin: 0;
    }

    #message {
      max-width: 600px;
      font-size: 25px;
      font-family: "InterLight";
      font-weight: normal;
    }
  }
`;

interface StyledProps {
  rightContent?: any;
  $overflow: boolean;
}

export const MiddleContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  box-sizing: border-box;

  background-color: ${(props) => props.theme.backgrounds.white};
  height: 100%;
  overflow: ${({ $overflow }) => ($overflow ? "auto" : "hidden")};
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  color: ${({ theme }) => theme.texts.black};

  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .scroll-div {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 8px 0px;
  }
  .iconClose {
    font-size: 22px;
    color: ${({ theme }) => theme.texts.black};
    cursor: pointer;
  }
  .container {
    overflow-y: auto;
    height: 100%;
  }
`;
