import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    right: 8px;
    bottom: 2px;
    font-size: 11px;
    color: ${props => props.theme.texts.darkGrey};

    .read{
        display: flex;
        align-items: flex-end;
        color: ${props => props.theme.chat.message.read};
    }

    .send{
        display: flex;
        align-items: flex-end;
    }

    .status-message{
        margin-left: 4px;
    }
`;