import styled from "styled-components";
import {
  colapsedSchedule,
  mobile,
  samllNotebook,
  tablet,
} from "../../../components/NavBar/style";
import { ContainerDashBoard } from "../Dashboard/style";
import { MiddleContainer } from "./ListReports/style";

interface StyledProps {
  colRight?: boolean;
  personal?: boolean;
  rightContent?: any;
}

export const ContentChat = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 100%;
  height: 100%;
  overflow: hidden;
  grid-gap: ${({ theme }) => theme.general.column_gap};
  border: none;

  @media only screen and (max-width: ${samllNotebook}) {
    grid-template-columns: ${(props) => props.personal && "min-content auto"};
    grid-template-columns: ${(props) => props.rightContent && "1fr"};
    ${MiddleContainer} {
      display: ${(props) => props.rightContent && "none"};
    }
  }
  @media only screen and (max-width: ${colapsedSchedule}) {
    grid-gap: 0rem;
    #icon-alert {
      height: 100px;
    }
  }

  .mr2 {
    margin-right: 8px;
  }

  .ml2 {
    margin-left: ${({ theme }) => theme.general.column_gap};
  }

  #mid_column {
    flex: 1;
    overflow: hidden;
    border-radius: 14px;
    display: flex;
  }

  .options {
    border-radius: 14px;
    overflow: hidden;
  }
  @media only screen and (max-width: ${colapsedSchedule}) {
    grid-gap: 0rem;
    #icon-alert {
      height: 100px;
    }
  }

  @media only screen and (max-width: ${samllNotebook}) {
    grid-template-columns: ${(props) => props.personal && "min-content auto"};
    grid-template-columns: ${(props) =>
      props.personal && props.rightContent && "1fr"};
    ${MiddleContainer} {
      display: ${(props) => props.personal && props.rightContent && "none"};
    }
  }

  @media only screen and (max-width: ${tablet}) {
    grid-template-columns: 1fr;
    ${ContainerDashBoard} {
      display: none;
    }
    ${MiddleContainer} {
      display: ${(props) => props.personal && props.rightContent && "none"};
    }
  }

  .hidden {
    display: none !important;
  }

  .iframeContainer {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgrounds.default};
    border-radius: 14px;
    iframe {
      /* border: none; */
      box-sizing: border-box;
    }
  }
  .floating-button__window {
    overflow: hidden;
    height: 100%;
  }
  .floating-button {
    display: block;
    @media only screen and (max-width: 1250px) and (min-width: ${mobile}) {
      display: block;
    }

    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  .floating-button__button {
    scale: 0.9;
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: none;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.default.ligther};
    background-color: ${({ theme }) => theme.begin.color.button.secondary};
    cursor: pointer;
    transition: transform 0.3s;
  }
  .floating-button__exit {
    position: absolute;
    bottom: 0px;
    left: 15px;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    border: none;
    font-size: 20px;
    color: ${({ theme }) => theme.default.ligther};
    background-color: ${({ theme }) => theme.begin.color.button.secondary};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
  }

  .floating-button__button:hover {
    transform: scale(1);
  }
`;

export const ContainerPDF = styled.div`
  height: 100%;
  width: 100%;
`;
