import styled from "styled-components";
import { mobile } from "../NavBar/style";

interface StyledProps {
  isMy?: boolean;
  color?: string;
  fixed?: boolean;
  nameUser?: boolean;
  sequence?: boolean;
}

export const ContainerMessage = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: auto auto;
  position: relative;
  max-width: 75%;


  .teste {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
  @media only screen and (max-width: ${mobile}) {
    max-width: 90%;
  }
  
  margin: ${(props) => (props.sequence ? "8px" : "3px")} 12px 0px 12px;
  padding: 0px 0px;
  align-self: ${(props) => (props.isMy ? "flex-end" : "flex-start")};
  place-self: ${(props) => (props.isMy ? "flex-end" : "flex-start")};
  animation: ${(props) => (props.isMy ? "slideUpAndRight" : "slideUpAndLeft")}
    0.167s cubic-bezier(0.4, 0, 0.2, 1);

  #alert {
    text-align: center;
    max-width: 200px;
    white-space: normal;
  }
  span {
    white-space: nowrap;
  }

  span:after {
    white-space: normal;
  }

  span:focus {
    outline: none;
  }

  /*== start of code for tooltips ==*/
  .tool {
    position: relative;
  }

  /*== common styles for both parts of tool tip ==*/
  .tool::before,
  .tool::after {
    right: 110%;
    opacity: 0;
    position: absolute;
    z-index: -100;
  }

  .tool:hover::before,
  .tool:focus::before,
  .tool:hover::after,
  .tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
  }

  /*== speech bubble ==*/
  .tool::after {
    white-space: nowrap;
    background: ${(props) => props.theme.backgrounds.darkI};
    border-radius: 0.25em;
    bottom: -25%;
    font-size: 13px;
    color: ${(props) => props.theme.texts.lightGrey};
    content: attr(data-tip);
    padding: 1em;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
    transform: scale(0.6) translateX(30%);
  }

  .tool:hover::after,
  .tool:focus::after {
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
  }

  /* @media (max-width: 760px) {
    .tool::after {
      font-size: 0.75em;
      margin-left: -5em;
      width: 10em;
    }
  } */
  /*== ================================= ==*/

  .alert {
    align-self: center;
    margin-right: 4px;
    color: red;
    cursor: pointer;
  }
  @-webkit-keyframes animation {
    0% {
      background-color: red;
    }
    50.0% {
      background-color: #ff6666;
    } /* your chosen 'mid' color */
    100.0% {
      background-color: red;
    }
  }

  @keyframes animation {
    0% {
      background-color: #fff;
    }
    50.0% {
      background-color: rgba(232, 214, 214, 1);
    }
    100.0% {
      background-color: rgba(204, 169, 169, 1);
    }
  }

  .animation {
    animation-name: animation;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }

  .container {
    background: ${(props) =>
      props.isMy
        ? props.theme.chat.message.backgroundMyII
        : props.theme.chat.message.backgroundOtherII};

    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

    .float-drop {
      position: absolute;
      top: 4px;
      right: 0;
    }

    #list-width {
      width: 150px;
    }
    .user-name {
      position: absolute;
      font-size: 12px;
      top: 6px;
      left: 8px;
      font-weight: bold;
      color: ${(props) => props.theme.texts.purpleDark};
      cursor: pointer;
      max-width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @keyframes slideUpAndRight {
      from {
        transform: translateX(100px) translateY(44px);
      }
      to {
        transform: none;
      }
    }

    @keyframes slideUpAndLeft {
      from {
        transform: translateX(-100px) translateY(44px);
      }
      to {
        transform: none;
      }
    }
  }
`;

const bubbleLeft = `
  bottom: -4px;
  left: -9px;
  transform: rotate(68deg);
`;

const bubbleRight = `
  bottom: -4px;
  right: -9px;
  transform: rotate(-68deg);
`;

export const Bubble = styled.div<StyledProps>`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 14px solid
    ${(props) =>
      props.isMy
        ? props.theme.chat.message.backgroundMyI
        : props.theme.chat.message.backgroundOtherII};
  position: absolute;
  ${(props) => (props.isMy ? bubbleRight : bubbleLeft)};
`;

export const MsgLayout = styled.div<StyledProps>`
  color: ${(props) =>
    props.isMy ? props.theme.texts.purpleDark : props.theme.texts.darkerGreyII};
  font-size: 14px;
  padding: 8px 60px 8px 8px;

  .messageContent {
    .text {
      padding-top: ${(props) => (props.nameUser ? "10px" : "0px")};
      word-break: break-word;
      white-space: pre-line;
    }
  }

  a:link,
  a:visited {
    color: ${(props) => props.theme.link};
  }
`;

export const LoadUpload = styled.div<StyledProps>`
  position: relative;
  cursor: pointer;
  .start-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(37, 37, 37, 0.4);
    color: ${(props) => props.theme.backgrounds.lightGreyI};
  }

  .cancel-icon {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    color: ${(props) =>
      props.color === "purple"
        ? props.theme.backgrounds.purpleDark
        : props.theme.backgrounds.lightGreyI};

    .icon {
      font-size: 28px;
    }
  }
`;

export const ButtonDownload = styled.button<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.purple.ligther};
  color: ${(props) => props.theme.texts.purpleDark};
  width: 38px;
  height: 38px;
  font-size: 20px;
  border-radius: 50%;
`;

interface AnswerProps {
  image?: boolean;
  group?: boolean;
}

export const ContainerAnswer = styled.div<AnswerProps>`
  margin: 8px 8px 0px 8px;
  position: relative;
  cursor: pointer;
  max-height: 100px;
  background: ${(props) => props.theme.backgrounds.answer};
  border-radius: 8px;
  border-left: 8px solid ${(props) => props.theme.texts.purpleDark};
  display: flex;
  flex-direction: column;
  padding: 8px;
  ${({ group }) => group && ` margin-top: 24px; `}

  p {
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20%;
    z-index: 0;
    border-top-right-radius: 8px;
    img {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .message {
    ${({ image }) => image && `margin-right: 20%; `}
    margin-bottom: 4px;
  }

  .title {
    ${({ image }) => image && `margin-right: 20%; `}
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    #icon {
      color: white;
      z-index: 3;
      :hover {
        cursor: pointer;
      }
    }
  }
`;
