import { BsFillEraserFill } from "react-icons/bs";
import { ReportFunctions } from "../../../../../util/report/reportFunctions";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import { ContainerElements, TitleContainer } from "../style";
import BodyOptions from "./VisualOptions/BodyOptions";
import FooterOptions from "./VisualOptions/FooterOptions";
import HeaderOptions from "./VisualOptions/HeaderOptions";
import PageOptions from "./VisualOptions/PageOptions";
import { ContainerOption } from "./VisualOptions/style";
import { ReportContext } from "../../../../../contexts/ReportContext";
import { useContext } from "react";

interface VisualBlockProps {
  header: HeaderTable;
  setHeader: React.Dispatch<React.SetStateAction<HeaderTable | undefined>>;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisualBlock = ({ header, setHeader, setUpdate }: VisualBlockProps) => {
  const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
  return (
    <ContainerElements>
      <TitleContainer>
        <h3>Personalização Visual</h3>
        <div className="buttons marginRight">
          <BsFillEraserFill
            title="Reiniciar formatação do PDF"
            className="icon-color"
            size={22}
            onClick={() =>
              handleStylesPDF({
                ...ReportFunctions.defaultStylePDF,
                title: stylesPDF.title,
              })
            }
          />
        </div>
      </TitleContainer>

      <PageOptions />
      <HeaderOptions setUpdate={setUpdate} />
      <BodyOptions header={header} setHeader={setHeader} />
      <FooterOptions />
      <ContainerOption></ContainerOption>
    </ContainerElements>
  );
};

export default VisualBlock;
