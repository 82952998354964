import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiOutlineHome, AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { BsChatDots, BsSearch } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  IoIosArrowBack,
  IoIosArrowUp,
  IoIosPaper,
  IoMdOptions,
} from "react-icons/io";
import {
  IoCalendarOutline,
  IoExitOutline,
  IoFileTrayFull,
  IoNotificationsOutline,
} from "react-icons/io5";
import {
  MdDarkMode,
  MdGroupAdd,
  MdLightMode,
  MdPayments,
} from "react-icons/md";
import { RiContactsLine } from "react-icons/ri";
import { TbLock } from "react-icons/tb";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import { ThemeContext, useTheme } from "styled-components";
import { LogoMin, LogoNoturno } from "../../assets/images";
import { Dropdown, Modal, NavBar } from "../../components";
import Img from "../../components/Img";
import { InputToolbar } from "../../components/Input";
import ModalExit from "../../components/Modal/ModalExit";
import { ProfileMenu } from "../../components/NavBar/style";
import { logout } from "../../services/auth";
import {
  deleteNotification,
  getNotifications,
  readNotification,
} from "../../services/requests/notification";
import { setConfig, setNavBarState } from "../../store/actions/abareAction";
import { clearReducers } from "../../store/actions/cleanAction";
import { setNotificationsDash } from "../../store/actions/dashboardAction";
import { toChangeTheme } from "../../store/actions/themeAction";
import { OptionConfig, TNotification } from "../../types/project";
import { TypeConstRedux } from "../../types/redux";
import { nameFormat, photoUrl } from "../../util/verification";
import Search from "./Conversations/ListConversation/Search";
import Notification from "./Dashboard/Notification";
import AllNotifications from "./Dashboard/Notification/AllNotifications";
import { More, NoNotifications } from "./Dashboard/style";
import Report from "./Report";
import Repository from "./Repository";
import { Conversations, Dashboard, Schedule } from "./pages";
import {
  Container,
  ContainerAnimation,
  ContainerTitle,
  ProfileDrop,
} from "./style";
import IconHome from "../../assets/icons/IconHome";
import IconChat from "../../assets/icons/IconChat";
import IconSchedule from "../../assets/icons/IconShedule";
import IconReport from "../../assets/icons/IconReport";
import IconConfig from "../../assets/icons/IconConfig";
import IconStorage from "../../assets/icons/IconStorage";
import IconHomeFill from "../../assets/icons/IconHomeFIll";
import IconChatFill from "../../assets/icons/IconChatFill";
import IconScheduleFill from "../../assets/icons/IconSheduleFill";
import IconReportFill from "../../assets/icons/IconReportFill";
import IconStorageFill from "../../assets/icons/IconStorageFill";
import IconLightMode from "../../assets/icons/IconLightMode";
import IconDarkMode from "../../assets/icons/IconDarkMode";
import IconNotifications from "../../assets/icons/IconNotifications";
import IconAdd from "../../assets/icons/IconAdd";
import IconPlans from "../../assets/icons/IconPlans";
import IconPaperTerm from "../../assets/icons/IconPaperTerm";
import IconInfo from "../../assets/icons/IconInfo";
import IconPaperProtocol from "../../assets/icons/IconPaperProtocol";
import IconHelp from "../../assets/icons/IconHelp";
import IconLogout from "../../assets/icons/IconLogout";
import IconCopy from "../../assets/icons/IconCopy";
import { ButtonItemSchema } from "../../components/NavBar";
import IconSearch from "../../assets/icons/IconSearch";
import Protocol from "./Dashboard/Protocol";
import { ModalRef } from "../../components/Modal";
import { ReportContextProvider } from "../../contexts/ReportContext";

const routes: ButtonItemSchema[] = [
  {
    text: "Inicial",
    Icon: IconHome,
    IconFill: IconHomeFill,
    url: "/home/dashboard",
    component: Dashboard,
  },
  {
    text: "Conversas",
    Icon: IconChat,
    IconFill: IconChatFill,

    url: "/home/conversations",
    component: Conversations,
  },
  {
    text: "Agendas",
    Icon: IconSchedule,
    IconFill: IconScheduleFill,

    url: "/home/schedule",
    component: Schedule,
  },
  {
    text: "Relatórios",
    Icon: IconReport,
    IconFill: IconReportFill,

    url: "/home/report",
    component: Report,
  },
  {
    text: "Repositório",
    Icon: IconStorage,
    IconFill: IconStorageFill,

    url: "/home/repository",
    component: Repository,
  },

  {
    text: "Configurações",
    Icon: IconConfig,
    IconFill: IconReportFill,
    subItens: [
      {
        text: "Meu Perfil",
        url: "/settings",
        value: OptionConfig.MY_PERFIL,
      },
      {
        text: "Contatos",
        url: "/settings",
        value: OptionConfig.CONTACTS,
      },
      {
        text: "Temas e Convite",
        url: "/settings",
        value: OptionConfig.THEME,
      },
      // {
      //   text: "Privacidade",
      //   url: "/settings",
      //   value: OptionConfig.PRIVACY,
      // },
      {
        text: "Notificações",
        url: "/settings",
        value: OptionConfig.NOTIFICATION,
      },
    ],
  },
];

// const routes = [
//     { text: 'Inicial', src: ic_home, url: "/home/dashboard", component: Dashboard },
//     { text: 'Conversas', src: ic_chat, url: "/home/conversations", component: Conversations },
//     { text: 'Agenda', src: ic_calendar, url: "/home/schedule", component: Schedule },
//     { text: 'Relatórios', Icon: HiOutlineDocumentReport, url: "/home/report", component: Report },
//     { text: 'Configurações', src: ic_setting, url: "/settings" }
// ]

interface ToolbarSearchProps {
  onBack: () => void;
  chat: boolean;
}
const ToolbarSearch = ({ onBack, chat }: ToolbarSearchProps) => {
  return (
    <div className="toolbar-search">
      <button
        onClick={onBack}
        className="background-button-animation button-notification"
      >
        <IoIosArrowBack id="icon_notification" />
      </button>
      {chat ? (
        <Search InputCustom={InputToolbar} />
      ) : (
        <InputToolbar
          placeholder="Pesquisar"
          Icon={BsSearch}
          PosIcon={IoMdOptions}
        />
      )}
    </div>
  );
};
const Home = (props) => {
  const { location, abare, clearReducers } = props;
  const { title } = useContext(ThemeContext);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLocationDashboard = pathname === "/home/dashboard";
  const chat = pathname.split("/")[2] == "conversations";
  const [hasSearch, setHasSearch] = useState(false);
  const navBarMinimized = useSelector(
    (state: TypeConstRedux) => state.abare.navBarMinimized
  );
  const { notifications_dash } = useSelector(
    (state: TypeConstRedux) => state.dashboard
  );

  const [visibleSearch, setVisibleSearch] = useState(false);

  let number_notifications: number = 0;
  notifications_dash?.map((notification) => {
    if (!notification.status) {
      number_notifications = number_notifications + 1;
    }
  });
  const theme = useTheme();
  const modalRef = useRef<any>(null);
  const modalAllNotifications = useRef<any>(null);
  const modalProtocol = useRef<ModalRef>(null);
  function handleHasSearch() {
    setHasSearch((state) => !state);
  }

  const actionLogout = () => {
    clearReducers();
    logout();
    push("/login");
  };
  const newAccount = () => {
    clearReducers();
    logout();
    push("/signin/email");
  };

  const handleNavBarAuto = (value) => {
    dispatch(setNavBarState(!value));
  };

  const handleNavBar = (value: boolean) => {
    if (value !== navBarMinimized) {
      dispatch(setNavBarState(value));
    }
  };
  const handleTheme = () => {
    dispatch(toChangeTheme());
  };
  function sortNotificationByDate(a: TNotification, b: TNotification) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  function sortNotificationByState(a: TNotification, b: TNotification) {
    if (a.status == b.status) {
      return 0;
    }
    if (!a.status) {
      return -1;
    }
    return 1;
  }

  const offToolbarSearch = () => setVisibleSearch(false);
  const onToolbarSearch = () => setVisibleSearch(true);

  useEffect(() => {
    getNotifications().then((res) => {
      if (res.status === 200) {
        dispatch(
          setNotificationsDash(
            res.data.sort(sortNotificationByDate).sort(sortNotificationByState)
          )
        );
      }
    });
  }, []);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 1580 && !navBarMinimized) {
      handleNavBar(true);
    } else if (window.innerWidth > 1580 && navBarMinimized) {
      handleNavBar(false);
    }
  }, [navBarMinimized, handleNavBar]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [navBarMinimized]);

  const handleStatus = (notification) => {
    readNotification(notification.id).then((res) => {
      const index = notifications_dash.indexOf(notification);
      let copy = [...notifications_dash];
      copy[index].status = true;
      dispatch(setNotificationsDash(copy));
    });
  };

  const handleDelete = (notification) => {
    deleteNotification(notification.id).then((res) => {
      dispatch(
        setNotificationsDash(
          notifications_dash.filter(
            (notificationL) => notificationL !== notification
          )
        )
      );
    });
  };

  function copyNicknameToClipboard() {
    navigator.clipboard.writeText(abare.nickname);
  }

  return (
    <Container>
      <NavBar options={routes}>
        <ProfileMenu>
          <img
            id="profile"
            className={`${navBarMinimized ? "full" : "not-full"}`}
            src={navBarMinimized ? LogoMin : LogoNoturno}
          />
          <button
            id="change-state"
            className="background-button-animation button-notification"
            onClick={() => {
              console.log(navBarMinimized);
              dispatch(setNavBarState(navBarMinimized ? false : true));
            }}
          >
            <IoIosArrowUp
              className={
                navBarMinimized
                  ? "center down icon-arrow"
                  : "center up icon-arrow"
              }
              size={18}
            />
          </button>
        </ProfileMenu>
      </NavBar>

      <main id="routes-content">
        <ContainerTitle column1={true}>
          {visibleSearch ? (
            <ToolbarSearch chat={chat} onBack={offToolbarSearch} />
          ) : (
            <div className="div-toolbar">
              <div className="divRight">
                <img id="img-logo" src={LogoMin} />

                {isLocationDashboard ? (
                  <ContainerAnimation>
                    <div
                      onClick={() => handleHasSearch()}
                      className={`${
                        hasSearch ? "active" : "disabled"
                      } container`}
                    >
                      <InputToolbar
                        placeholder="(Em desenvolvimento)"
                        Icon={BsSearch}
                        PosIcon={IoMdOptions}
                      />
                      <span className="title-message">
                        Conectando povos e nações
                      </span>
                    </div>
                    <div
                      className={`${hasSearch ? "disabled" : "active"} float`}
                    >
                      <IconSearch
                        onClick={handleHasSearch}
                        color={theme.icon.dashboard}
                        size={24}
                        className="icon-search"
                        fill="transparent"
                      />
                      <span className="title-message">
                        Conectando povos e nações
                      </span>
                    </div>
                  </ContainerAnimation>
                ) : chat ? (
                  <Search InputCustom={InputToolbar} />
                ) : (
                  <InputToolbar
                    placeholder="Pesquisar"
                    Icon={BsSearch}
                    PosIcon={IoMdOptions}
                  />
                )}
                <button
                  className="background-button-animation button-notification buttonSearch"
                  onClick={onToolbarSearch}
                >
                  <IconSearch
                    color={theme.icon.dashboard}
                    size={24}
                    fill="transparent"
                  />
                </button>
              </div>
              <div className="flex">
                <button className="button-notification" onClick={handleTheme}>
                  {!(title == "light") ? (
                    <IconLightMode
                      id="icon_notification"
                      className="fill-icon"
                    />
                  ) : (
                    <IconDarkMode
                      id="icon_notification"
                      className="fill-icon"
                    />
                  )}
                </button>
                <Dropdown
                  isHoved={false}
                  defaultClass="bottom-left"
                  mobile={true}
                  notifications={true}
                  overflow={true}
                >
                  <div className="container_notification button-notification">
                    <IconNotifications id="icon_notification" />
                    {number_notifications > 0 && (
                      <p className="number_notifications">
                        {number_notifications}
                      </p>
                    )}
                  </div>
                  <ProfileDrop $overflow={true}>
                    <h3 className="title_noti center">Notificações </h3>
                    {notifications_dash?.length > 0 ? (
                      notifications_dash?.map(
                        (notification, index) =>
                          index <= 3 && (
                            <Notification
                              key={index}
                              handleStatus={handleStatus}
                              handleDelete={handleDelete}
                              notification={notification}
                            />
                          )
                      )
                    ) : (
                      <NoNotifications>
                        Não há notificações para hoje
                      </NoNotifications>
                    )}
                    {notifications_dash?.length > 2 && (
                      <More
                        onClick={() => modalAllNotifications.current.open()}
                      >
                        Ver tudo
                      </More>
                    )}
                  </ProfileDrop>
                </Dropdown>

                {/* DropDown de perfil */}
                <Dropdown isHoved={false} defaultClass="bottom-left">
                  <div className="button">
                    <Img id="profile_dash" src={photoUrl(abare)} />
                  </div>

                  <ProfileDrop>
                    <Img id="profile" src={photoUrl(abare)} />
                    <div className="text">
                      <h3 id="name">{nameFormat(abare.name)}</h3>
                      <span id="nickname">
                        @{abare.nickname}
                        <div
                          title="Copiar nickname"
                          onClick={copyNicknameToClipboard}
                        >
                          <IconCopy className="iconCopy" fill="none" />
                        </div>
                      </span>
                    </div>
                    <li onClick={() => newAccount()}>
                      {" "}
                      <IconAdd className="iconDrop" /> Nova Conta
                    </li>
                    <li
                      onClick={() => {
                        push("/plans");
                      }}
                    >
                      {" "}
                      <IconPlans className="iconDrop" /> Planos de Assinatura
                    </li>
                    <li className="in-production" onClick={() => {}}>
                      {" "}
                      <IconPaperTerm fill="none" className="iconDrop" /> Termo
                      de uso
                    </li>
                    <li className="in-production" onClick={() => {}}>
                      {" "}
                      <IconInfo className="iconDrop" /> Políticas de Privacidade
                    </li>

                    <li
                      onClick={() => {
                        modalProtocol.current?.open();
                      }}
                    >
                      {" "}
                      <IconPaperProtocol className="iconDrop" /> Protocolo
                    </li>
                    <li className="in-production" onClick={() => {}}>
                      {" "}
                      <IconHelp className="iconDrop" /> Ajuda
                    </li>
                    <li onClick={() => modalRef.current.open()}>
                      <IconLogout className="iconDrop" /> Sair
                    </li>
                  </ProfileDrop>
                </Dropdown>
              </div>
            </div>
          )}
        </ContainerTitle>{" "}
        <ReportContextProvider>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Switch location={location}>
              {routes.map(
                ({ url, component }, key) =>
                  component && (
                    <Route path={url} component={component} key={key} />
                  )
              )}
              <Redirect exact to="/home/dashboard" />
            </Switch>
          </div>
        </ReportContextProvider>
      </main>
      <ModalExit
        title="Encerrar sessão"
        action={actionLogout}
        modalRef={modalRef}
      />
      <Modal ref={modalAllNotifications} mobile={true}>
        <AllNotifications
          modalRef={modalAllNotifications}
          notifications={notifications_dash}
          handleStatus={handleStatus}
          handleDelete={handleDelete}
        />
        {/* <div style={{height:"100px", width: "100px", backgroundColor:"red"}}></div> */}
      </Modal>
      <Modal ref={modalProtocol} mobile={true}>
        <Protocol modalRef={modalProtocol} />
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  abare: state.abare.abare,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearReducers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
