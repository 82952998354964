import styled from "styled-components";
import { mobile } from "../../../../components/NavBar/style";

interface ItemProps {
  isView?: boolean;
  padding?: boolean;
}
export const ItemNotification = styled.li<ItemProps>`
  display: flex !important;
  flex-direction: row;
  position: relative;
  align-items: center;
  min-width: 300px !important;
  border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};

  padding: ${({ padding }) => (padding ? "0px 1.5rem 4px 1.5rem" : "inherit")};
  .div_content {
    display: flex;
    flex-direction: column;
    width: max-content;
flex: 1;
    .dateSend {
      font-size: 0.8rem;
      color: ${(props) => props.theme.texts.purpleDark};
font-weight: bold;
    }
    p {
      padding: 0px;
      margin: 0px;
      margin-left: 8px;
    }
    .content {
      flex: 1;
      width: max-content;
    }
  }
  #profile {
    margin-top: 12px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    align-self: center;
    object-fit: cover;
  }
  .notifications_options {
    z-index: 100 !important;
  }

  ul {
    z-index: 1000 !important;
  }
  .content {
    flex: 1;
    margin-left: 8px;
    width: max-content;
  }

  .container_icon {
    position: relative;
  }

  #icon_read {
    color: ${({ theme }) => theme.highlights.default};
    font-size: 12px;
    visibility: ${({ isView }) => (isView ? "visible" : "hidden")};
  }

  #icon_theme {
    color: green;
    font-size: 22px;
    margin-right: 8px;
    position: absolute;
    bottom: -4px;
    right: -12px;
    background-color: white;
    border-radius: 90px;
    padding: 4px;
  }
  #icon_drop {
    color: ${({ theme }) => theme.texts.black};
    font-size: 22px;
    padding: 8px;
    border-radius: 90px;
    background-color: ${({ theme }) => theme.backgrounds.white};

    &:hover {
      background-color: ${(props) => props.theme.hover.lightGreyIII};
    }
  }

  /* &:hover {
    background-color: inherit !important;
  } */
`;

export const Container = styled.form`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgrounds.white};
  padding: 0px 0rem 0rem 0rem;
  max-height: 960px;
  overflow: auto;
  max-height: 60vh;
  @media only screen and (max-width: ${mobile}) {
    max-height: initial;
  }
`;

export const ContainerAllNotifications = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  border-bottom: ${(props) =>
    props.theme.title == "light" && "1px solid rgba(255, 255, 255, 0.2)"};
  color: ${(props) => props.theme.texts.darkerGreyII};
  overflow-y: auto !important;
  overflow-x: hidden;
  height: 100%;

  .title_noti {
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey} !important ;
    padding-bottom: 8px;
    margin-bottom: 0px;
  }

  #profile {
    margin-top: 12px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    align-self: center;
    object-fit: cover;
  }

  .text {
    width: 100%;
    align-self: center;
    margin-left: 12px;
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};

    #name {
      margin: 0;
      font-size: 20px;
    }

    #nickname {
      margin: 0;
      font-size: 14px;
      font-weight: lighter;
    }
  }

  li {
    border-radius: 0px !important;
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey} !important  ;
  }
`;
