import axios from "axios";

export const getWeather = (longitude, latitude) => {
  return axios({
    method: "get",
    url: `https://weather.contrateumdev.com.br/api/weather?lat=${latitude}&lon=${longitude}`,
    headers: {},
  })
    .then((response) => JSON.stringify(response.data))
    .catch((error) => {
      if (error.response) {
        throw new Error(error.response);
      } else if (error.request) {
        throw new Error(error.request);
      } else {
        throw new Error(error.message);
      }
    });
};
