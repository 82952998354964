import styled from "styled-components";
import { getColorCategoryByCode } from "../../../../../util/categoryUtils";

interface ContainerProps {
  $category: string;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 4px 1fr;
  gap: 1.5rem;
  color: ${({ theme }) => theme.texts.contentCards};
  transition: background-color 0.2s ease-in-out;

  border-radius: 4px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.hover.default};
  }
  * {
    margin: 0px;
    box-sizing: border-box;
  }

  hr {
    margin: 0px;
    height: 100%;
    width: 6px;
    background: ${({ $category }) => getColorCategoryByCode($category)};
    border-radius: 16px;
    border: 0;
    align-self: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    overflow: hidden;
  }

  .title {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .date-event {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .tag {
    color: white;
    background-color: ${({ $category }) => getColorCategoryByCode($category)};
    border: 2px solid ${({ $category }) => getColorCategoryByCode($category)};
    font-family: "Inter", sans-serif;
    width: fit-content;
    font-size: 1rem;
    padding: 0rem 0.5rem;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
`;
