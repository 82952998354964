import { Fragment, useEffect, useState } from "react";
import { IoMdOptions } from "react-icons/io";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { BsSearch } from "../../../../../assets/icons";
import { InputSearch, Load } from "../../../../../components/index";
import { getUsersByName } from "../../../../../services/requests/user";
import {
  setGlobalMessages,
  setPersonal,
} from "../../../../../store/actions/messages/chatAction";
import { TPersonal, TypePersonal } from "../../../../../types/project";
import {
  keyClient,
  personalTemp,
  photoUrl,
} from "../../../../../util/verification";
import { Container, DropList, Item, Loading } from "./style";
import Img from "../../../../../components/Img";

export const ResultsLocal = ({ title, result, isLoading }) => {
  const { PERSONAL, GUEST } = TypePersonal;
  return (
    <Fragment>
      <h3 className="title">{title}</h3>
      {isLoading ? (
        <Loading>
          <div id="load">
            <Load dark={true} />
          </div>
        </Loading>
      ) : result.length > 0 ? (
        result.map((user, i) => (
          <Item key={i} onClick={user.onClick}>
            <Img className="img" src={photoUrl(user)} />
            <div id="col">
              <h1 id="name">{user.name ? user.name : "@" + user.nickname}</h1>
              {user.type == PERSONAL && user.name && (
                <h5 id="nickname">@{user.nickname}</h5>
              )}
            </div>
          </Item>
        ))
      ) : (
        <Item>Nenhum resultado</Item>
      )}
    </Fragment>
  );
};
const Search = (props) => {
  const { abare, folders, toChangePersonal, toManagerChat, InputCustom } =
    props;
  const [search, setSearch] = useState("");
  const [result, seResult] = useState<TPersonal[]>([]);
  const [resultLocal, seResultLocal] = useState<TPersonal[]>([]);
  const [load, setload] = useState(false);
  const folderGeneral = folders.find((item) => item.id === abare.id);
  const personals = folderGeneral?.itens ? folderGeneral.itens : [];
  const { PERSONAL, GUEST } = TypePersonal;
  const { push } = useHistory();
  useEffect(() => {
    if (search) {
      searchLocal();
      setload(true);
      const delayDebounceFn = setTimeout(() => {
        getUsersByName(search.toLowerCase()).then((res) => {
          setload(false);
          seResult(res);
        });
      }, 900);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [search]);

  const changeChat = (personal) => {
    const hasPersonalFolder = resultLocal.find(
      (local) => local.nickname == personal.nickname
    );
    const { Personal_id } = personal;
    const clientId = Personal_id ? Personal_id : personalTemp(personal, abare);

    var openClient: any = null;
    if (hasPersonalFolder) {
      openClient = hasPersonalFolder;
    } else if (Personal_id) {
      toManagerChat({
        [clientId]: {
          client: { ...personal, searched: true },
          messages: [],
          currentPage: 1,
        },
      });
      openClient = personal;
    } else {
      const client = { ...personal, [keyClient[GUEST]]: clientId, type: GUEST };
      toManagerChat({ [clientId]: { client, messages: [], currentPage: 1 } });
      openClient = client;
    }
    if (openClient) {
      toChangePersonal(openClient);
      push("/home/conversations/chat/dashboard");
    }
    setSearch("");
  };

  const searchLocal = () => {
    if (search !== "") {
      const newContactList = personals.filter((personal) => {
        let persona;
        persona = personal?.name?.toLowerCase()?.includes(search.toLowerCase());

        if (persona) {
          return persona;
        } else {
          return personal?.nickname
            ?.toLowerCase()
            ?.includes(search.toLowerCase());
        }
      });
      seResultLocal(newContactList.slice(0, 5));
    }
  };

  const Results = ({ title, result }) => (
    <Fragment>
      <h3 id="title">{title}</h3>
      {result.length > 0 ? (
        result.map((user, i) => (
          <Item key={i} onClick={() => changeChat(user)}>
            <img src={photoUrl(user)} />
            <div id="col">
              <h1 id="name">{user.name ? user.name : "@" + user.nickname}</h1>
              {user.name && <h5 id="nickname">@{user.nickname}</h5>}
            </div>
          </Item>
        ))
      ) : (
        <Item>Nenhum resultado</Item>
      )}
    </Fragment>
  );

  const ResultsLocal = ({ title, result }) => (
    <Fragment>
      <h3 id="title">{title}</h3>
      {result.length > 0 ? (
        result.map((user, i) => (
          <Item
            key={i}
            onClick={() => {
              setSearch("");
              toChangePersonal(user);
              push("/home/conversations/chat/dashboard");
            }}
          >
            <img src={photoUrl(user)} />
            <div id="col">
              <h1 id="name">{user.name ? user.name : "@" + user.nickname}</h1>
              {user.type == PERSONAL && user.name && (
                <h5 id="nickname">@{user.nickname}</h5>
              )}
            </div>
          </Item>
        ))
      ) : (
        <Item>Nenhum resultado</Item>
      )}
    </Fragment>
  );

  return (
    <Container id="inputToolbar">
      {InputCustom ? (
        <InputCustom
          PosIcon={IoMdOptions}
          Icon={BsSearch}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          type="text"
          required
          placeholder="Pesquisar usuários e grupos"
        />
      ) : (
        <InputSearch
          Icon={BsSearch}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          type="text"
          required
          placeholder="Pesquisar usuários e grupos"
        />
      )}{" "}
      {search.length ? (
        <DropList>
          <ResultsLocal title="Minhas conversas" result={resultLocal} />
          {!load ? (
            <Results title="Todos os usuários" result={result} />
          ) : (
            <Loading>
              <div id="load">
                <Load dark={true} />
              </div>
            </Loading>
          )}
        </DropList>
      ) : (
        ""
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    abare: state.abare.abare,
    folders: state.folder.list,
    messages: state.chat.messages,
    socket: state.socket,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toChangePersonal(personal) {
      dispatch(setPersonal(personal));
    },
    toManagerChat(obj) {
      dispatch(setGlobalMessages(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
