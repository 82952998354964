import styled from "styled-components";
import { mobile, samllNotebook } from "../../../../components/NavBar/style";
interface ContainerProps {
  border?: string;
  $open: boolean;
}
export const ContainerList = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;
  transition: all 0.8s ease;
  min-width: 300px;
  max-width: 400px;
  margin-right: ${({ $open }) => ($open ? "0px" : "-400px")};
  background-color: ${({ theme }) => theme.backgrounds.purpleLightI};
  overflow-y: auto;
  overflow-x: hidden;
  border-left: ${({ border, theme }) =>
    !border ? `1px solid ${theme.bordes.lightGrey}` : `inherit`};

  border-radius: ${({ border }) => (border ? border : `inherit`)};
  .teste {
    max-height: 35px;
    min-height: 35px;
    border: 1px solid #c6c6c6;
    border-radius: 16px;
    font-family: "Poppins light", sans-serif;
    font-size: 14px;
    padding: 0px 8px 0px 8px;
    width: 90px;

    &:hover {
      cursor: pointer !important;
    }
    &:focus {
      -webkit-text-fill-color: #666666;
      border: 1px solid;
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: #666666;
      opacity: 1;
      -webkit-text-fill-color: #666666;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #666666;
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #666666;
    }
  }
`;

export const Scroll = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 1rem 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.backgrounds.purpleLightI};

  h3 {
    flex: 1;
    margin: 0px;
    font-size: ${({ theme }) => theme.fonts.text_xl};
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
  }

  .icon-color {
    display: flex;
    margin-left: 8px;
    margin-right: 8px;
    color: #888888;
    border-radius: 100%;
    cursor: pointer;

    font-size: 22px;
    :hover {
      color: ${(props) => props.theme.highlights.default};
    }
  }
  #icon {
    display: none;
    margin-right: 0.5rem;
    font-size: ${({ theme }) => theme.fonts.text_2xl};
    cursor: pointer;
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
  }
  @media only screen and (max-width: ${samllNotebook}) {
    #icon {
      display: initial;
    }
  }
  #icon-back {
    font-size: 18px;

    cursor: pointer;
    color: ${({ theme }) => theme.purple.dark};
  }
`;

export const ButtonCreate = styled.button`
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 12px 14px;
  align-items: center;

  background-color: ${({ theme }) => theme.backgrounds.purpleLightI};

  border: 1px solid ${({ theme }) => theme.bordes.lightGrey};

  p {
    margin: 0px;
    margin-left: 8px;
    color: white;
    font-size: large;
    color: ${({ theme }) => theme.texts.darkerGreyI} !important;
  }

  :hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  :active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  #icon {
    color: ${({ theme }) => theme.texts.darkerGreyI} !important;
  }
`;

export const ContainerElements = styled.div`
  font-size: ${({ theme }) => theme.fonts.text_base};
  background-color: ${({ theme }) => theme.backgrounds.white};
  margin: 0px 0px 12px 0px;
  color: ${({ theme }) => theme.texts.darkerGreyI};
  padding-bottom: 1rem;

  #title {
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
    font-family: "inter-medium", sans-serif;
    margin: 0px;
    font-size: ${({ theme }) => theme.fonts.text_xl};

    padding: 1rem;
    padding-bottom: 0;
  }
  .flex-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    padding-right: 20px;
    .buttons {
      display: flex;
      flex-direction: row;
    }
    #title {
      padding: 0px;
    }
    svg {
      display: flex;
      margin-left: 4px;
      background-color: #888888;
      color: ${(props) =>
        props.theme.title === "light" ? "white" : "#092232"};
      padding: 6px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      height: fit-content;

      :hover {
        background-color: ${(props) => props.theme.highlights.default};
      }
    }
  }
  #icon {
    color: ${({ theme }) => theme.texts.darkerGreyI};

    font-size: 22px;
  }

  .description {
    font-family: "inter-medium", sans-serif;
    color: ${({ theme }) => theme.texts.darkerGreyI};
    font-size: ${(props) => props.theme.fonts.text_base};
    margin: 0px;
    padding: 0.7rem 0rem;
  }
`;
interface itemProps {
  $noPadding?: boolean;
  $borderBottom?: boolean;
}
export const ItemModal = styled.div<itemProps>`
  padding: 0.5rem 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px 8px;
  /* border-bottom: 1px solid ${({ theme }) => theme.bordes.lightGrey}; */
  box-sizing: border-box;

  ${({ $noPadding }) => $noPadding && "padding: 0px;"};
  /* ${({ $borderBottom, theme }) =>
    $borderBottom && `border-bottom: 1px solid ${theme.bordes.lightGrey} ;`}; */
  .padding {
    padding: 0.5rem 1rem;
  }
  .border {
    border-bottom: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  }
  @media only screen and (max-width: ${mobile}) {
    grid-template-columns: repeat(4, 1fr);
  }

  p {
    margin: 0px;
  }
  .flex {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      margin-right: 4px;
    }
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .width2 {
    min-width: 49%;
  }
  .col-1 {
    grid-column: span 1;
  }
  .col-2 {
    grid-column: span 2;
  }
  .col-3 {
    grid-column: span 3;
  }
  .col-4 {
    grid-column: span 4;
  }
  .col-5 {
    grid-column: span 5;
  }
  .col-6 {
    grid-column: span 6;
  }
  .col-8 {
    grid-column: span 8;
  }
  .title {
    margin: 0px 12px 0px 0px;
    padding: 0px;
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
    font-size: 1rem;
  }
  .margin_left {
    margin-left: 8px;
  }
`;
interface ColumnsProps {
  columns: number;
}
export const ColumnsExample = styled.div<ColumnsProps>`
  display: grid;
  grid-column: repeat(${({ columns }) => columns}, minmax(10px, 1fr));
`;

interface ElementProps {
  column?: boolean;
  border?: boolean;
  end?: boolean;
  $noStyle?: boolean;
  $noWrap?: boolean;
  $padding?: string;
}
export const Element = styled.div<ElementProps>`
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${({ $noWrap }) => ($noWrap ? "noWrap" : "wrap")};
  padding: ${({ $padding }) => ($padding ? $padding : "2px 1rem")};
  align-items: center;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  color: ${({ theme }) => theme.texts.darkerGreyI};
  margin: 4px 0px;
  justify-content: ${({ end }) => (end ? "flex-end" : "flex-start")};
  width: 100%;
  border: ${({ border, theme }) =>
    border ? `1px solid  ${theme.texts.darkerGreyI}` : "none"};
  align-items: ${({ column }) => (column ? "flex-start" : "center")};

  .teste {
    max-height: 35px;
    min-height: 35px;
    border: 1px solid #c6c6c6;
    border-radius: 16px;
    font-family: "Poppins light", sans-serif;
    font-size: 14px;
    padding: 0px 8px 0px 8px;
    width: 90px;

    &:hover {
      cursor: pointer !important;
    }
    &:focus {
      -webkit-text-fill-color: #666666;
      border: 1px solid;
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: #666666;
      opacity: 1;
      -webkit-text-fill-color: #666666;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #666666;
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #666666;
    }
  }

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${({ border, $noStyle }) =>
    border &&
    !$noStyle &&
    `padding: 2px 0px;
  margin: 4px 1rem;
  width: initial; `};
  .line {
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    align-items: center;
    .icon {
      display: flex;
      margin-left: 4px;
      background-color: #888888;
      color: ${(props) =>
        props.theme.title === "light" ? "white" : "#092232"};
      padding: 6px;
      border-radius: 100%;
      cursor: pointer;
      height: fit-content;

      :hover {
        background-color: ${(props) => props.theme.highlights.default};
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .end {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  label,
  input {
    :hover {
      cursor: pointer;
    }
  }

  p {
    margin: 0px;
  }
  .linkCSV {
    flex: 1;
    text-decoration: none;
  }
  .react-datepicker-wrapper {
    width: fit-content !important;
  }
  .allChecked {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 8px;
    box-sizing: border-box;
    .orange {
      padding-left: 4px;
      color: ${({ theme }) => theme.texts.purpleDarkTitle};
    }
    .line {
      display: flex;
      flex-direction: row;
      margin-top: 4px;
      margin-right: 4px;
    }
  }

  .icon {
    color: ${({ theme }) => theme.texts.darkerGreyI};
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .marginLeft {
    margin-left: 4px;
  }
`;
export const GroupColumns = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2px 4px;
  .line {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 1rem 1rem 0 1rem;
  .marginRight {
    margin-right: 0.5rem;
  }
  h3 {
    flex: 1;
    font-family: "inter-medium", sans-serif;

    margin: 0px;
    font-size: ${({ theme }) => theme.fonts.text_xl};
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
  }

  svg {
    display: flex;
    margin-left: 4px;
    background-color: #888888;
    color: ${(props) => (props.theme.title === "light" ? "white" : "#092232")};
    padding: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    height: fit-content;

    :hover {
      background-color: ${(props) => props.theme.highlights.default};
    }
  }
  .buttons {
    display: flex;
    flex-direction: row;
  }
`;

interface OptionsProps {
  select?: boolean;
  $borderTop?: boolean;
  $marginLef?: string;
}

export const OptionTitle = styled.div<OptionsProps>`
  display: flex;
  height: 45px;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: 8px 1rem;
  padding-left: ${({ $marginLef }) => ($marginLef ? $marginLef : "1rem")};

  box-sizing: border-box;
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .order {
    cursor: ns-resize;
    margin-right: 8px;
  }
  h3 {
    flex: 1;
    margin: 0px;
    font-size: ${({ theme }) => theme.fonts.text_base};
    color: gray;
  }
  h4 {
    flex: 1;
    margin: 0px;
    margin-left: 4px;
    font-size: ${({ theme }) => theme.fonts.text_base};
    /* color: gray; */
  }
  h5 {
    flex: 1;
    margin: 0px;
    color: ${({ theme }) => theme.texts.purpleDarkTitle};

    font-size: ${({ theme }) => theme.fonts.text_sm};
    /* color: gray; */
  }

  /* svg {
    margin-right: 1rem;
  } */

  .buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  ${({ select, theme }) =>
    select && `background-color: ${theme.backgrounds.lightGrey};`}
  ${({ $borderTop, theme }) =>
    $borderTop &&
    `
    border-top: 1px solid ${theme.bordes.lightGrey};`}

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.backgrounds.lightGrey};
  }
`;

interface OptionsProps {
  $paddingBottom?: string;
}
export const OptionSelected = styled.div<OptionsProps>`
  background-color: ${({ theme }) => theme.backgrounds.blueLight};
  /* border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; */

  padding-bottom: ${({ $paddingBottom }) =>
    $paddingBottom ? $paddingBottom : "8px"};
`;

export const Teste = styled.div`
  height: 100vh;
  background-color: red;
  width: 200px;
`;
