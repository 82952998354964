import { useState } from 'react';
import { Container, Header, Menu, Button } from './style'
import { IoIosArrowBack, HiOutlineTrash } from '../../../../../assets/icons'
import { Load, Search } from '../../../../../components';
import { TContact } from '../../../../../types/project';
import { Checked } from '../../../../../components/Checked';
import { useToasts } from 'react-toast-notifications';
import { deleteContacts } from '../../../../../services/requests/contact';
import ModalContainer from '../../../../../components/Modal/ModalContainer';


type TProps = {
    modal: any,
    contacts: TContact[],
    deleteContactsRedux: (contactsId: number[]) => {}
}

const DeleteContacts = (props: TProps) => {
    const { contacts, modal, deleteContactsRedux } = props;
    const [hidden, setHidden] = useState(false);
    const { addToast } = useToasts();
    const [users, setUsers] = useState<TContact[]>(structuredClone(contacts));
    const [loading, setLoading] = useState(false);

    const dropContacts = () => {
        if (hidden) {
            setLoading(true)
            let contactsRemove: Array<any> = [];

            users.forEach(user => {
                user.checked && contactsRemove.push(user.contactId)
            });

            deleteContacts(contactsRemove).then((res) => {
                setLoading(false)
                if (res.status == 200) {
                    deleteContactsRedux(contactsRemove);
                    addToast({
                        title: "Contatos removidos com sucesso!"
                    },
                        { appearance: 'success' });
                    modal.current.close()
                }
            })
        }
    }

    return (
        <ModalContainer padding={false} title='Excluir contatos' buttonBack={true} onClick={() => modal.current.close()}>
            
            <Menu>
                <Search itens={users || []} onSearch={(result) => {
                    setUsers(result)
                }} />
                {loading ?
                    <Load />
                    :
                    <Button onClick={() => dropContacts()} >
                        <HiOutlineTrash id="icon" className={!hidden ? "default" : "purple"} />
                    </Button>
                }

            </Menu>
            <div>
                <Checked contacts={users} setContacts={setUsers} setHidden={setHidden} />
            </div>
        </ModalContainer>
    );
}

export default DeleteContacts;