import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #b6b6b6;

  #title {
    font-weight: normal;
    margin: 0;
    color: ${props => props.theme.texts.darkGreyI};
    font-family: "inter-medium", sans-serif;
  }

  #close {
    font-size: 20px;
    color: ${props => props.theme.texts.darkGreyI};
    cursor: pointer;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;

  #alert {
    font-size: 45px;
    color: #d72424;
  }

  p {
    font-size: 14px;
    color: ${props => props.theme.texts.darkGreyI};
    text-align: center;
    margin: 0.5rem 0rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  padding: 0.6rem 1rem;
  border-top: 1px solid #b6b6b6;
  justify-content: center;
  
  button {
    color: #d30000;
    border: 1px solid #d30000;
    padding: 0.6rem 2rem;
    border-radius: 7px;

    &:hover{
        background-color: #FDE9E9;
    }
  }
`;
