import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  background-color: ${(props) => props.theme.backgrounds.purpleLightI};
  .barra {
    overflow: auto;
  }
  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: "inter-medium", sans-serif;
  }

  #name {
    color: ${(props) => props.theme.default.darker};
    font-size: 19px;
  }

  #nick {
    color: ${(props) => props.theme.default.darker};
    font-size: 14px;
  }
`;

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  margin: 0;
  padding: 1rem 1rem;
  color: ${(props) => props.theme.texts.purpleDarkTitle};

  h3 {
    margin: 0px;
    flex-grow:2;
    color: ${(props) => props.theme.fonts.text_lg};
    font-family: "inter-bold", sans-serif;
  }

  #icon {
    font-size: 24px;
    padding-right: 8px;
    cursor: pointer;
  }
`;
