import { connect } from 'react-redux';
import InputSwitch from 'react-switch';
import { TypeConstRedux } from '../../types/redux';

const Toggle = (props) => {
  const { value, hamburguer, theme, ...rest } = props;
  delete rest.dispatch

  return (
    <InputSwitch
      checked={value}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor={theme.title == 'light' ? "#700540" : "#DF6A00"}
      height={18}
      width={30}
      activeBoxShadow="0px 0px 1px 5px rgba(136, 26, 77, 0.2)"
      borderRadius={15}
      {...rest}
    />
  );
};

const mapStateToProps = (state: TypeConstRedux) => {
  return  {
    theme: state.abare.theme

  }
}

export default connect(mapStateToProps)(Toggle);
