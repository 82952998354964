import styled from "styled-components";

export const Container = styled.div`
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  background: white;
  padding: 16px;

  textarea {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 90%;
    background: transparent;
    height: 300px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 13px;
  }
`;