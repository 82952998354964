import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconInfo = ({ color = "#A6446A", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M13.426 21.424C13.042 21.808 12.5667 22 12 22C11.4347 22.0013 10.96 21.81 10.576 21.426C10.192 21.042 10 20.5667 10 20V12C9.99867 11.4347 10.19 10.96 10.574 10.576C10.958 10.192 11.4333 10 12 10C12.5653 9.99867 13.04 10.19 13.424 10.574C13.808 10.958 14 11.4333 14 12V20C14.0013 20.5653 13.81 21.04 13.426 21.424Z"
        fill="inherit"
      />
      <path
        d="M13.426 5.424C13.042 5.808 12.5667 6 12 6C11.4347 6.00134 10.96 5.81001 10.576 5.42601C10.192 5.04201 10 4.56667 10 4.00001C9.99867 3.43467 10.19 2.96001 10.574 2.57601C10.958 2.19201 11.4333 2.00001 12 2.00001C12.5653 1.99867 13.04 2.19001 13.424 2.57401C13.808 2.95801 14 3.43334 14 4.00001C14.0013 4.56534 13.81 5.04 13.426 5.424Z"
        fill="inherit"
      />
    </Icon>
  );
};

export default IconInfo;
