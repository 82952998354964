import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Maps, Modal } from '../../../components';
import Link from '../../../components/Link';
import { setNavBar } from '../../../store/actions/abareAction';
import { getAllClusters, insertItemInGeneral, updateMessageInFolders } from '../../../store/actions/folderAction';
import { dropConversation, setGlobalMessages, updateClient } from '../../../store/actions/messages/chatAction';
import { addMessage, readMessages, updateReadStatus } from '../../../store/actions/messages/messageAction';
import { TMessage } from '../../../types/project';
import { getClientId } from '../../../util/verification';
import { ContainerSwitch } from '../style';
import Chat from './Chat';
import ListConversation from './ListConversation';
import NoMessage from './NoMessage';
import { ContentChat } from './style';

const routes = [
    { path: "/dashboard", component: NoMessage },
    { path: "/chat", component: Chat },
    { path: "/maps", component: Maps },
]

const Conversations = (props) => {
    const { personal, abare, socket, addMessage, readMessages, updateClient, getAllClusters,
        updateMessageInFolders, dropMessage, setGlobalMessages, updateReadStatus, insertItemInGeneral, setNavBar } = props;
    const { path } = useRouteMatch();
    const modalLink = useRef<any>();

    useEffect(() => {
        socket.on("joinNewClass", (client) => {
            const id = getClientId(client)
            const result = { [id]: { client, messages: [], currentPage: 1 } }
            setGlobalMessages(result)
        });
        socket.on("updateClusters", () => {
            getAllClusters();
        });
        socket.on("message", (message) => {
            if (message.sendBy !== abare.id) {
                addMessage(message)
                readMessages(message)
            }
            updateMessageInFolders(message)
        });
        socket.on("readMessages", (message) => {
            const { clientId, msgsIds } = message;
            updateReadStatus(clientId, msgsIds)
        });
        socket.on("updateGroup", (client) => {
            updateClient(client)
        });
        socket.on("deleteMessage", (message: TMessage) => {
            message.sendBy != abare.id && dropMessage(message);
        });
        socket.on("changeBlocked", (message) => {
            updateClient({ ...message })
        });
        socket.on("addGroup", (message) => {
            insertItemInGeneral(message)
        });
        socket.on("removeGroup", (message) => {
            updateClient({ ...message })
        });
        return () => {
            socket.off("joinNewClass");
            socket.off("updateClusters");
            socket.off("message");
            socket.off("updateGroup");
            socket.off("deleteMessage");
        }
    }, [socket]);

    const { pathname, search } = useLocation();
    const [rightContent, setRightContent] = useState<any>(pathname.split('/')[3] != 'dashboard')
    let params = new URLSearchParams(search);
    const invite = params.get('invite');

    useEffect(() => {
        setRightContent(pathname.split('/')[3] != 'dashboard')
        if (pathname.split('/')[3] != 'chat') {
            setNavBar(true)
        }


    }, [pathname])

    useEffect(() => {
        invite && modalLink.current.open()
    }, [])

    return (
        <ContainerSwitch>

            <ContentChat personal={personal} rightContent={rightContent}>
                <ListConversation />
                <Switch location={props.location}>
                    {routes.map((route, key) => (
                        <Route {...route} path={path + route.path} key={key} />
                    ))}
                    {personal && screen.width > 550 ?
                        <Redirect exact to={path + "/chat"} />
                        :
                        <Redirect exact to={path + "/dashboard"} />
                    }
                </Switch>
                {invite && <Modal ref={modalLink}>
                    <Link modal={modalLink} invite={invite} abareId={abare.id} />
                </Modal>}
            </ContentChat >
        </ContainerSwitch>

    );
}

const mapStateToProps = state => ({
    abare: state.abare.abare,
    socket: state.socket,
    personal: state.chat.personal,
})
const mapDispatchToProps = dispatch => bindActionCreators({
    addMessage,
    readMessages,
    updateClient,
    getAllClusters,
    updateReadStatus,
    updateMessageInFolders,
    setGlobalMessages,
    insertItemInGeneral,
    dropConversation,
    setNavBar
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);