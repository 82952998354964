import styled from "styled-components";

export const ContainerView = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.backgrounds.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  overflow: hidden;
  border-radius: 14px;
  width: 100%;
  .div-pdf {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .div-options {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 14px;
    background-color: red;
  }
  .react-pdf__Document {
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    justify-content: center;
    height: 100%;
    overflow: auto;
    background: ${({ theme }) => theme.backgrounds.lightGreyIV};
    align-items: baseline;
  }
  canvas {
    margin: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
  }
`;

export const ToolbarView = styled.nav`
  display: flex;

  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.texts.black};
  z-index: 2;
  padding: 0 1rem;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.backgrounds.secondaryToolbar};
  color: white;
  hr {
    height: 12px;
  }

  .input {
    width: 50px;
    input {
      width: 50px;
    }
  }
  .icon {
    :hover {
      cursor: pointer;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: auto;
  }
  .div-name {
    flex: 1;
    white-space: nowrap; /* Impede quebras de linha */
    overflow: hidden; /* Oculta o conteúdo excedente */
    display: flex;
    align-items: center;
    text-overflow: ellipsis; /* Exibe reticências (...) quando o conteúdo é ocultado */

    margin: 0px;
    color: ${({ theme }) => theme.texts.secondaryToolbar};
    font-family: "inter-bold", sans-serif;
    font-size: 1.5rem;
  }
  .div-actions {
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-template-columns: 30px 60px 30px 10px 30px 65px 30px 30px;
  }
  .div-pages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .div-buttons {
    flex: 1;
    display: grid;
    grid-template-columns: 30px 30px;
    align-items: center;
    justify-content: flex-end;
    justify-items: flex-end;
    column-gap: 8px;
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem 1rem 1rem;
`;
