import React, { useState } from 'react';
import { IBillingToggle } from './plans.interfaces';
import { BToggle } from './style';

function BillingToggle(props: IBillingToggle) {
  const { onChange } = props;
  const [billing, setBilling] = useState('mensal');
  const billingTypes = ['mensal', 'semestral', 'anual'];

  function isActive(type: string) {
    return billing === type ? 'active' : '';
  }

  return (
    <BToggle>
      {
        billingTypes.map((type, index) => (
          <div key={index}
            className={`option ${type} ${isActive(type)}`}
            onClick={() => {
              setBilling(type);
              onChange(type);
            }}
          >
            {type}
          </div>
        ))
      }
      <span className={`toggle ${billing}`} />
    </BToggle>
  );
}

export default BillingToggle;
