import { api } from '../api';
import { getToken } from "../auth";

export const postMessage = (data) => {
    return api({
        url: "/message/",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        /* cancelToken: source.token, */
        data: data

    }).then(response => response)
        .catch(error => {
            if (error.response) {
                console.log("erro de response:", error.response )
                console.log(error.response)
                return error.response
            } else if (error.request) {
                console.log("erro de request:", error.request )
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getMessages = (personalId, page) => {
    return api({
        url: `/message/${personalId}?page=${page}&size=15`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteMessage = (personalId, msgId) => {
    return api({
        url: `/message/${personalId}/${msgId}`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchVisibleMessage = (userId, personalId, msgId) => {
    return api({
        url: `/message/${userId}/${personalId}/${msgId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};