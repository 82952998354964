import pt from "date-fns/locale/pt-BR";
import { ChangeEvent, RefObject, forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { BiCategoryAlt } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { MdOutlineDescription, MdOutlineLocationOn, MdOutlineTitle } from "react-icons/md";
import { VscCalendar } from "react-icons/vsc";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal, { ModalRef } from "..";
import { patchEvent, postEvent } from "../../../services/requests/event";
import { getCategorys } from "../../../services/requests/event-category";
import { getGroups } from "../../../services/requests/group";
import { addEventSchedule, updateEventSchedule } from "../../../store/actions/scheduleAction";
import { TCategory, TEvent } from "../../../types/project";
import { Queries } from "../../../types/queries";
import { EnumScheduleType, TypeConstRedux } from "../../../types/redux";
import { defaultCategories } from "../../../util/constantes";
import { ToastModel } from "../../../util/error";
import { InputControlled, InputSelect, TextArea } from "../../Input";
import InputSearchPlace from "../../Maps/InputSearchPlace";
import ModalContainer from "../ModalContainer";
import { Container, Element } from "./style";


interface ColumnsProps {
    modal: RefObject<ModalRef>;
    event?: TEvent | null;
    dateStart?: Date | undefined;
    dateEnd?: Date | undefined;
}


const ModalEvent = ({ modal, event, dateStart, dateEnd }: ColumnsProps) => {

    const { data, isLoading, isSuccess } = useQuery<TCategory[]>(Queries.CATEGORIES_EVENT, getCategorys)

    const [optionsCategory, setOptionsCategory] = useState([
        {
            label: "Categorias",
            options: defaultCategories,
        },
    ]);

    const defaultEvent: TEvent = {
        EventCategory_id: null,
        Event_id_root: null,
        confirmations: null,
        group_name: null,
        dateEnd: dateEnd ?? new Date(),
        dateStart: dateStart ?? new Date(),
        description: "",
        id: 0,
        status: "",
        title: "",
        Group_id: null,
        type: optionsCategory[0].options[2].value,
        edit: null,
        participation: null,
    }

    const stateEvent = useSelector((state: TypeConstRedux) => state.schedule);
    const dispatch = useDispatch();
    const dateActual = new Date();
    const edit = event != null;

    const title = edit ? "Editar evento" : "Criar Evento";

    const [eventMutable, setEventMutable] = useState<TEvent>(event ?? defaultEvent);
    const [optionsGroup, setOptionsGroup] = useState<any[]>([]);
    const [loadGroups, setLoadGroups] = useState(false);
    const [loadRequisition, setLoadRequisition] = useState(false);
    const [requiredTitle, setRequiredTitle] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        if (isSuccess) {
            const catogories = data.filter(({ active }) => active).map(({ id, name }) => { return { value: id, label: name } })
            setOptionsCategory(
                catogories.length > 0 ? [
                    {
                        label: "Padrões",
                        options: [...defaultCategories],
                    },
                    {
                        label: "Personalizadas",
                        options: [...catogories]
                    }] : [{
                        label: "Padrões",
                        options: [...defaultCategories],
                    },]
            );
        }
    }, [isLoading, data])
    useEffect(() => {
        if (stateEvent.filter == EnumScheduleType.GROUP) {
            setLoadGroups(true);
            getGroups().then((res) => {
                if (res.status == 200) {
                    optionsGroup.splice(0, optionsGroup.length);
                    res.data.forEach((grupo) => {
                        if (grupo.isAdm != false) {
                            optionsGroup.push({ value: grupo.Group_id, label: grupo.name });
                        }
                    });
                    setOptionsGroup([...optionsGroup]);
                }
            }).finally(() => { setLoadGroups(false) });
        }
    }, [stateEvent]);

    useEffect(() => {
        setEventMutable(event ?? defaultEvent);
        if (event) {
            handleDateStart(new Date(event?.dateStart));
            handleDateEnd(new Date(event?.dateEnd));
        }
        return () => {
            setEventMutable(defaultEvent);
        }
    }, [event]);

    useEffect(() => {
        if (dateEnd && dateStart) {
            const dateEndToday = new Date();
            dateEndToday.setHours(23, 55);
            handleDateStart(dateStart ?? new Date());
            handleDateEnd(dateEnd ?? dateEndToday);
        }
    }, [dateEnd, dateStart])


    registerLocale("pt", pt);
    setDefaultLocale("pt");

    type Props = {
        onClick?: () => void;
        value?: string;
    };
    type RefType = number;


    const filterPassedTimeToday = (time) => {
        const currentDate = new Date();
        const selectedDateTime = new Date(time);

        return selectedDateTime > currentDate;
    };

    const filterPassedTimeEnd = (time) => {
        const currentDate = new Date(eventMutable.dateStart);
        const selectedDateTime = new Date(time);

        return selectedDateTime > currentDate;
    };/*
    const filterPassedTimeEnd = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() > selectedDate.getTime();
    };*/
    const TimeCustomInput = forwardRef<RefType, Props>(
        ({ value, onClick }, ref) => (
            <input
                placeholder="--:--"
                value={value}
                className="inputTime"
                onClick={onClick}
                readOnly={true}
            />
        )
    );

    const TimeFinalCustomInput = forwardRef<RefType, Props>(
        ({ value, onClick }, ref) => (
            <input
                placeholder="--:--"
                value={value}
                className="inputTime"
                onClick={onClick}
                readOnly={true}
            />
        )
    );

    const DateCustomInput = forwardRef<RefType, Props>(
        ({ value, onClick }, ref) => (
            <input
                value={value}
                className="teste"
                onClick={onClick}
                readOnly={true}
            />
        )
    );

    // const filterTimeToday = (time) => {
    //     const currentDate = new Date();
    //     if (currentDate.toDateString() == startDate?.toDateString()) {
    //         const selectedDate = new Date(time);
    //         return currentDate.getTime() < selectedDate.getTime();
    //     }
    //     return true
    // };
    const filterPassedTime = (time) => {
        const currentDate = eventMutable.dateStart;
        const selectedDate = time;
        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleTitle = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        eventMutable.title = value;
        if (requiredTitle) {
            setRequiredTitle(false);
        }
        setEventMutable({ ...eventMutable });
    };

    const handleDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        eventMutable.description = value;
        setEventMutable({ ...eventMutable });
    };

    const handleDateStart = (date: Date) => {
        if (date.getTime() > dateActual.getTime()) {
            eventMutable.dateStart = date;
            setEventMutable({ ...eventMutable });
        } else {
            eventMutable.dateStart = dateActual;
            setEventMutable({ ...eventMutable });
        }
    }
    const handleLocal = (local: string) => {
        eventMutable.local = local;
        setEventMutable({ ...eventMutable });
    }


    const handleDateEnd = (date: Date) => {
        if (date.getTime() > dateActual.getTime()) {
            eventMutable.dateEnd = date;
            setEventMutable({ ...eventMutable });
        } else {
            eventMutable.dateEnd = dateActual;
            eventMutable.dateEnd.setHours(23, 55);
            setEventMutable({ ...eventMutable });
        }
    }


    const handleGroupSelect = (e) => {
        eventMutable.Group_id = e.value;
        eventMutable.name_group = e.label;
        setEventMutable({ ...eventMutable });
    }

    const handleTypeSelect = (e) => {
        eventMutable.type = e.value;
        setEventMutable({ ...eventMutable });
    }

    const checkFields = () => {
        if (eventMutable.title.length < 1) {
            setRequiredTitle(true);
            return false;
        }
        if (stateEvent.filter == EnumScheduleType.GROUP && !eventMutable.Group_id) {
            setRequiredTitle(true);
            return false;
        }
        return true;
    }

    const actionEvent = () => {
        if (checkFields()) {
            if (event) {
                editEvent();
            } else {
                addEvent();
            }
        }
    }

    const editEvent = () => {
        if (event) {
            const data = {
                title: eventMutable.title,
                Group_id: eventMutable.Group_id,
                dateStart: eventMutable.dateStart.toISOString(),
                dateEnd: eventMutable.dateEnd.toISOString(),
                type: eventMutable.type,
                description: eventMutable.description,
                local: eventMutable.local,
                name_group: eventMutable.name_group

            };
            setLoadRequisition(true);
            patchEvent(event.id, data).then((res) => {
                if (res.status == 200) {
                    ToastModel(
                        addToast,
                        "Evento atualizado!",
                        "Seu evento foi atualizado com sucesso.",
                        "success"
                    );
                } else {
                    ToastModel(
                        addToast,
                        "Erro de conexão!",
                        "Tente novamente mais tarde.",
                        "error"
                    );
                }
                modal.current?.close();
                dispatch(updateEventSchedule({ ...event, ...data }));
            }).finally(() => {
                setLoadRequisition(false);
            })
        }
    }
    const addEvent = () => {
        const data = {
            title: eventMutable.title,
            Group_id: eventMutable.Group_id,
            dateStart: eventMutable.dateStart.toISOString(),
            dateEnd: eventMutable.dateEnd.toISOString(),
            type: eventMutable.type,
            description: eventMutable.description,
            participation: null,
            local: eventMutable.local,
            name_group: eventMutable.name_group

        };
        setLoadRequisition(true);
        postEvent(data).then((res) => {
            if (res.status == 200) {
                ToastModel(
                    addToast,
                    "Evento criado!",
                    "Seu evento foi criado com sucesso.",
                    "success"
                );
                modal.current?.close();
                dispatch(addEventSchedule({ ...data, ...res.data.result }));
            } else {
                ToastModel(
                    addToast,
                    "Error!",
                    "Error ao criar seu evento", "error"
                );
            }
        }).finally(() => {
            setLoadRequisition(false);
        })
    }

    const valueInputCategory = () => {
        if (eventMutable.type) {
            return
        }
        return optionsCategory[0].options[2];
    }
    // const warningTitle = () => requiredTitle && eventMutable.title.length<1;
    return (
        <Modal $overflow={true} mobile={true} ref={modal} >
            <ModalContainer loadRequisition={loadRequisition} onButtonRight={actionEvent} padding={false} buttonConfirmation={true} title={title} onClick={() => modal.current?.close()} buttonBack={true}>
                {/* <ItemModal $noPadding={true} $borderBottom={true}>
                <div className="flex padding border" style={{ minWidth: "230px", flex: 1 }}>
                    <FilterOptions value={value} setValue={setValue} header={header} columnSelect={columnSelect} setColumnSelect={setColumnSelect} filterSelected={filterSelected} setFilterSelected={setFilterSelected} />
                </div>
            </ItemModal> */}
                <Container>


                    <Element title="Título" $required={requiredTitle}>
                        <div className="icon">
                            <MdOutlineTitle size="20px" />
                        </div>
                        <InputControlled isRequired={requiredTitle} placeholder="Titulo" value={eventMutable.title} onChange={handleTitle} />
                    </Element>
                    {stateEvent.filter == EnumScheduleType.GROUP && <Element title="Grupo" $required={requiredTitle}>
                        <div className="icon">
                            <BsPeople size="20px" />
                        </div>
                        {!edit ? <InputSelect
                            $flex={true}
                            onChange={handleGroupSelect}
                            name="inputGroup"
                            id="inputGroup"
                            isLoading={loadGroups}
                            placeholder="Grupo"
                            style={true}
                            options={optionsGroup}
                        /> : <p>{event!!.name_group!!}</p>}

                    </Element>}

                    <Element title="Descrição">
                        <div className="icon">
                            <MdOutlineDescription size="20px" />
                        </div>
                        <TextArea $flex={true} rows={4} placeholder="Descrição" value={eventMutable.description} onChange={handleDescription} />
                    </Element>

                    <Element title="Localização">
                        <div className="icon">
                            <MdOutlineLocationOn size="20px" />
                        </div>
                        <InputSearchPlace
                            label={false}
                            groupType="evento"
                            schedule={true}
                            setAddress={handleLocal}
                            address={eventMutable.local ?? ""}
                        />
                    </Element>

                    <Element title="Período">
                        <div className="icon">
                            <VscCalendar size="20px" />
                        </div>
                        <DatePicker
                            maxDate={new Date().setFullYear(9999)}
                            selected={new Date(eventMutable.dateStart)}
                            onChange={handleDateStart}
                            minDate={new Date()}
                            filterTime={filterPassedTimeToday}
                            selectsStart
                            endDate={new Date(eventMutable.dateEnd)}
                            dateFormat="MMMM d, yyyy HH:mm"
                            placeholderText="dd/mm/yyyy"
                            required={true}
                            className="teste"
                            showTimeSelect
                            customInput={<DateCustomInput />}
                        />
                        &nbsp; - &nbsp;
                        <DatePicker
                            maxDate={new Date().setFullYear(9999)}
                            selected={new Date(eventMutable.dateEnd)}
                            onChange={handleDateEnd}
                            minDate={eventMutable.dateStart}
                            dateFormat="MMMM d, yyyy HH:mm"
                            placeholderText="dd/mm/yyyy"
                            required={true}
                            startDate={new Date(eventMutable.dateStart)}
                            showTimzeSelect
                            selectsEnd
                            filterTime={filterPassedTimeEnd}
                            showTimeSelect
                            className="teste"
                            customInput={<DateCustomInput />}
                        />
                    </Element>
                    <Element title="Categoria">
                        <div className="icon">
                            {" "}
                            <BiCategoryAlt size="20px" />
                        </div>
                        <InputSelect
                            name="inputCategory"
                            id="category"
                            style={true}
                            isLoading={isLoading}
                            onChange={handleTypeSelect}
                            options={optionsCategory}
                            value={optionsCategory[0].options.find((option) => option.value == eventMutable.type!! || option.value.toString() == eventMutable.EventCategory_id)}
                        />
                    </Element>
                </Container>
            </ModalContainer>
        </Modal>
    );
}

export default ModalEvent;