import Notification from "./Notification";
import { Redirect, Route, Switch } from "react-router-dom";
import SoundSelect from "./Sound";

export const NotificationsAndSounds = (props) => {

    const SoundNotification = () => {
        return (
            <SoundSelect titleContainer="Notificações" titleItem="Som de Conversas" text="é o som de suas notificações de conversas" />
        );
    }

    const SoundGroups = () => {
        return (
            <SoundSelect titleContainer="Grupos" titleItem="Som de Grupos" text="é o som de suas notificações de grupos" />
        );
    }

    const SoundCalls = () => {
        return (
            <SoundSelect titleContainer="Chamadas" titleItem="Som de Chamadas" text="é o som de suas notificações de chamadas" />
        );
    }
    const { location } = props
    const routes = [
        { path: "/settings/notifications", component: Notification, exact: true },
        { path: "/settings/notifications/soundNotification", component: SoundNotification, exact: true },
        { path: "/settings/notifications/soundGroups", component: SoundGroups, exact: true },
        { path: "/settings/notifications/soundCalls", component: SoundCalls, exact: true },
    ]

    return (
        <Notification/>
    );
}

export default NotificationsAndSounds;