import { Fragment, useState } from "react";
import { Input } from "../../../../../../components/Input";
import { putNameAndNick } from "../../../../../../services/requests/user";
import ItemContainer from "../../../Components/ItemContainer";
import { Row } from "../../style";

const ProfileData = (props) => {
    const { user, editInfos, editNameAndNicknanme } = props;
    const [perfilState, setPerfilState] = useState(false);
    const [load, setLoad] = useState(false);
    const isUser = isNaN(user.contactId);

    const updatePerfil = e => {
        e.preventDefault()
        const { nameUser, nickname } = e.target.elements;
        const data = {
            name: nameUser.value == '' ? null : nameUser.value,
            ...(nickname && { nickname: nickname.value == '' ? null : nickname.value })
        }

        if (nameUser.value != user.name || nickname?.value != user.nickname) {
            const functionSucess = () => {
                user.name = nameUser.value;
                nickname && (user.nickname = nickname.value);
                setPerfilState(false)
                return user;
            }

            if (isUser) {
                editNameAndNicknanme(data, functionSucess, setLoad)
            } else {
                editInfos(data, functionSucess, setLoad)
            }
        } else {
            setPerfilState(false)
            setLoad(false)
        }
    }
    return (
        <Fragment>
            {perfilState ?
                <Fragment>
                    <form onSubmit={updatePerfil}>
                        <ItemContainer title="Perfil" onClick={() => setPerfilState(!perfilState)} load={false} isButton>
                            <Row>
                                Seu nome
                                <Input name="nameUser" placeholder="Digite seu nome" title="Apenas letras!"
                                    required="required" pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$" defaultValue={user.name} />
                            </Row>
                            {isUser && <Row lastLine>
                                Apelido
                                <Input name="nickname" placeholder="Digite seu apelido" title="Apenas letras, números e underline!"
                                    required="required" pattern="[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9_]+$" defaultValue={user.nickname} />
                            </Row>}
                        </ItemContainer>
                    </form>
                </Fragment>
                :
                <Fragment>
                    <ItemContainer title="Perfil" onClick={() => setPerfilState(!perfilState)} load isButton>
                        <Row>
                            Seu nome
                            <div className="inputs">
                                <input disabled defaultValue={user.name}></input>
                            </div>
                        </Row>
                        {isNaN(user.contactId) && <Row lastLine>
                            Apelido
                            <div className="inputs">
                                <input disabled defaultValue={user.nickname}></input>
                            </div>
                        </Row>}
                    </ItemContainer>
                </Fragment>
            }
        </Fragment>
    );
}

export default ProfileData;