import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { useMutation, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { InputSelect, Load } from "../../../../../../components";
import { deleteCategory } from "../../../../../../services/requests/event-category";
import { TCategory } from "../../../../../../types/project";
import { Queries } from "../../../../../../types/queries";
import { listMyCategorys } from "../../../../../../util/categoryUtils";
import { ToastModel } from "../../../../../../util/error";
import { Content, Footer, SelectContent } from "./style";

type SetCategorysType = React.Dispatch<React.SetStateAction<TCategory[]>>
interface TProps {
    hasAnyEvent: boolean;
    category: TCategory;
    setCategorys: SetCategorysType
    goBack: any;
    categorys: TCategory[]
}
const DeleteCategory = (props: TProps) => {
    const { hasAnyEvent, category, setCategorys, goBack, categorys } = props;
    let options = listMyCategorys();
    const { addToast } = useToasts();

    categorys.map((value) => {
        value.id != category.id && value.active && options.push({ value: value.id, label: value.name })
    })
    const [option, setOption] = useState<number>(options[4].value)
    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation((data: {
        categoryId: number,
        newCategoryId: any
    }) => deleteCategory(data), {
        onSuccess: ({ status }: AxiosResponse<TCategory>, data) => {
            if (status == 200) {
                queryClient.setQueryData(Queries.CATEGORIES_EVENT, (prevData) => {
                    if (Array.isArray(prevData)) {
                        return prevData.filter((item) => item.id != data.categoryId);
                    }
                    return [];
                });
                ToastModel(addToast, "Categoria removida", "", "success")
                goBack();
            } else {
                ToastModel(addToast, "Erro ao excluir categoria", "Por favor tente novamente mais tarde", "error")
            }
        },
        onError: (error: AxiosError) => {
            ToastModel(addToast, "Erro ao excluir categoria", "Por favor tente novamente mais tarde", "error")
        }
    });
    const dropCategory = () => {
        const data = {
            categoryId: category.id,
            newCategoryId: hasAnyEvent ? option : null
        }


        mutate(data)
    }
    return (
        <Content>
            <FiAlertTriangle id="alert" />

            {hasAnyEvent ?
                <p>Existem eventos associados a esta categoria. Caso você confirme a exclusão, então a categoria desses eventos
                    será mudada automaticamente  para <b>"outras" </b> ou para uma categoria que você selecionar a seguir. </p>
                :
                <p>Tem certeza que deseja excluir permanentemente essa categoria?</p>
            }
            {hasAnyEvent &&
                <SelectContent>
                    <span>Categoria:</span>
                    <InputSelect options={options} defaultValue={options[4]} onChange={(e) => setOption(e.value)} />
                </SelectContent>}
            <Footer>
                <button disabled={isLoading} onClick={() => dropCategory()} >
                    {isLoading
                        ?
                        <Load primaryColor={'light'} secundaryColor={'dark'} />
                        :
                        "Excluir"
                    }

                </button>
            </Footer>

        </Content>
    )
}
export default DeleteCategory;