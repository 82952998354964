import styled from "styled-components";

export const Container = styled.div`
  display: block;
  align-items: center;
  background-color: white;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 30px;
  align-items: center;
  background-color: transparent;
  margin: 8px 16px 8px 16px;

  input: disabled {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background-color: transparent;
    border: none;
    width: auto;
    height: 35px;
    font-family: "inter-semi-bold", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
    padding: 0px !important;
  }
`;

export const Button = styled.button`
  display: block;
  align-items: center;
  font-size: 11px;
  color: ${(props) => props.theme.texts.purpleDark};
  padding: 0px 8px;
`;
