import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { Container, StyledDataGrid } from "./style";
import { Dropdown, ItemFile } from "../../../../../components";
import { getTypeNameMedia } from "../../../../../util/verification";
import { FiMoreVertical } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { BsFillShareFill } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";

interface TableProps {
  itensList: any;
  handleRowClick: (params: GridRowParams, event: React.MouseEvent) => void;
  isLoading: boolean;
}

export function Table({ itensList, handleRowClick, isLoading }: TableProps) {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      renderCell: (params) => {
        console.log(params);
        return (
          <div
            style={{
              display: "grid",
              flexDirection: "row",
              alignItems: "center",
              gridTemplateColumns: "20px 1fr",
              gridColumnGap: "8px",
            }}
          >
            <ItemFile type={params.row.fileType} icons={true} />
            <p>{params.formattedValue}</p>
          </div>
        );
      },
    },
    {
      field: "tipo",
      headerName: "Tipo",
      renderCell: (params) => (
        <div>{getTypeNameMedia(params.row.fileType)}</div>
      ),
    },
    {
      field: "editedAt",
      headerName: "Última modificação",
      renderCell: (params) => new Date(params.row.editedAt).toLocaleString(),
      minWidth: 250,
    },
    {
      field: "actions", // Nome da coluna
      headerName: "", // Cabeçalho da coluna
      renderCell: (params) => {
        return (
          <div id="icon-table" className="dropdown">
            <Dropdown defaultClass="bottom-left">
              <FiMoreVertical id="menu" className="buttonDrop" />
              <div className="widthLista">
                <li onClick={() => {}}>
                  {" "}
                  <MdModeEdit className="icon" size={18} /> Editar
                </li>

                <form>
                  <li onClick={() => {}}>
                    {" "}
                    <FaTrash className="icon" size={14} /> Excluir
                  </li>
                </form>

                <form>
                  <li onClick={() => {}}>
                    {" "}
                    <BsFillShareFill className="icon" size={14} /> Compartilhar
                  </li>
                </form>

                <form>
                  <li>
                    {" "}
                    <HiDownload className="icon" size={14} /> Fazer Download
                  </li>
                </form>
              </div>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <Container>
      <StyledDataGrid
        columns={columns}
        rows={itensList}
        onRowClick={handleRowClick}
        getRowId={(row) => row.fileName ?? row.id}
        checkboxSelection={false}
        loading={isLoading}
      />
    </Container>
  );
}
