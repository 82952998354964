import { NotFound } from "../../../../components";
import { EmptyMessage } from "../../../../components/EmptyMessage";
import { ChatContainer } from "../../Conversations/Chat/style";

const NoMessage = () => {
  return (
    <ChatContainer id="no_content_message">
      <div id="noContent">
        <EmptyMessage type="report" />
      </div>
    </ChatContainer>
  );
};

export default NoMessage;
