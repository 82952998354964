import { useState, useRef } from "react";
import { connect } from "react-redux";
import { Accordion, Dropdown, Modal } from "../../../../components";
import { Header, MiddleContainer } from "../../Conversations/ListConversation/style";
import { AddGroup } from "../../Conversations/Group";
import {
  FaFolderPlus,
  MdMoreVert,
  MdNoteAdd,
} from "../../../../assets/icons";
import Search from "../../Conversations/ListConversation/Search";

const ListConversation = (props) => {
  const { personal, createNote } = props;
  const [newFolder, setNewFolder] = useState<Boolean>(false);
  const modalAddGroup = useRef<any>();

  return (
    <MiddleContainer className={personal ? "hideMenu" : ""}>
      <Header>
        <div className="top">
          <h3 className="title">Notas</h3>
          <Dropdown defaultClass="bottom-left">
            <MdMoreVert id="menu" />
            <div>
              <li onClick={() => setNewFolder(true)}><FaFolderPlus className="icon" /> Nova pasta</li>
              <li onClick={() => createNote(true)}><MdNoteAdd className="icon" size="22px" /> Nova nota</li>
            </div>
          </Dropdown>
        </div>
        <Search />
      </Header>
      <div className="container">
      </div>
      <Modal ref={modalAddGroup}>
        <AddGroup modalAdd={modalAddGroup} />
      </Modal>
    </MiddleContainer>
  );
};

function mapStateToProps(state) {
  return {
    abare: state.abare.abare,
    personal: state.chat.personal,
  };
}

export default connect(mapStateToProps)(ListConversation);
