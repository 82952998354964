import styled from "styled-components";
import { notebook, tablet } from "../../../components/NavBar/style";
import { ContainerDashBoard } from "../Dashboard/style";

export const MainSettings = styled.div`
  display: block;
  height: 100%;
  overflow: hidden;

 
  .float-back {
    display: flex;
    padding: 12px;
    margin-bottom: 6px;
    align-items: center;
    min-width: 100%;
    font-size: 16px;
    color: ${(props) => props.theme.texts.purpleDark};

    border-bottom: 1px solid ${(props) => props.theme.bordes.purpleLight};
  }

  #routes-content {
    display: flex;
    position: relative;
    flex-direction: column;
    background: rgba(112, 5, 64, 0.05);
    border-right: 1px solid ${(props) => props.theme.default.lightI};
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }

  @media only screen and (max-width: 540px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    .float-back {
      position: absolute;
      min-width: auto;
      width: 35px;
      height: 35px;
      padding: 0px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.begin.color.button.secondary};
      bottom: 60px;
      left: 12px;
      opacity: 0.9;
      z-index: 10;
      transition: all ease-in-out 300ms;
      box-shadow: 0px 37px 20px -15px rgba(0, 0, 0, 0.2);

      .icon {
        font-size: 20px;
      }

      &:hover {
        opacity: 1;
        background-color: ${(props) => props.theme.highlights.default};
      }
    }
  }

  @media only screen and (max-width: ${notebook}) {
    .float-back {
      justify-content: center;
    }
  }
  @media (max-width: ${tablet}) {
    grid-template-columns: auto 1fr;
    ${ContainerDashBoard} {
      display: none;
    }
  }
`;

interface StyledProps {
  lastLine?: boolean;
  isHover?: boolean;
  isSingle?: boolean;
  marginBottom?: boolean;
  justify?: string;
  noHover?: boolean;
  search?: boolean;
}

export const HorizontalRow = styled.div<StyledProps>`
  font-family: "inter", sans-serif;
  display: flex;
  ${(props) =>
    props.search &&
    ` display: grid;
  grid-template-columns: min-content 1fr 40px; `};
  align-items: center;
  padding: ${(props) => (props.lastLine ? "0px" : "8px 0px")};
  justify-content: ${(props) => props.justify && props.justify};
  width: 100%;

  &:hover {
    background-color: ${(props) =>
      props.isHover
        ? props.theme.hover.lightGrey
        : props.theme.backgrounds.white};
    ${(props) => !props.noHover && ` cursor: pointer; `};
  }

  span {
    font-size: ${(props) => props.theme.fonts.text_base};
    color: ${(props) => props.theme.texts.darkerGreyII};
    margin-left: 25px;
  }

  #icon {
    font-size: 19px;
    color: ${(props) => props.theme.texts.darkerGreyII};
  }

  .checkBlocked {
    margin-left: 8px;
  }

  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }
`;

export const VerticalRowItems = styled.div<StyledProps>`
  font-family: "inter", sans-serif;
  font-size: ${(props) => props.theme.fonts.text_base};
  color: ${(props) => props.theme.texts.darkerGreyII};
  padding-left: ${(props) => (props.isSingle ? "8px" : "16px")};
  margin-bottom: ${(props) => (props.marginBottom ? "16px" : "0px")};

  &:hover {
    background-color: ${(props) =>
      props.isHover
        ? props.theme.hover.lightGrey
        : props.theme.backgrounds.white};
    ${(props) => !props.noHover && ` cursor: pointer; `};
  }

  .line {
    margin-top: 8px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: ${(props) => props.theme.fonts.text_sm};
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  input {
    margin: 0px 5px 0px 0px;
    cursor: pointer;
  }

  span {
    color: ${(props) => props.theme.texts.darkerGreyII};
  }
`;

export const Exit = styled.div`
  text-align: center;

  #icon {
    color: ${(props) => props.theme.default.dark};
  }

  #message {
    margin: 0;
    color: ${(props) => props.theme.default.darker};
    font-weight: normal;
  }

  .action {
    padding: 18px 0px 16px 0px;

    button {
      padding: 10px 16px;
      border-radius: 6px;
      border: solid 1px;
      transition: all 0.3s ease-in;
    }

    #cancel {
      background-color: ${(props) => props.theme.purple.light};
      color: ${(props) => props.theme.default.light};
      border-color: ${(props) => props.theme.purple.darkI};
      margin-left: 15px;

      &:hover {
        background-color: ${(props) => props.theme.purple.dark};
      }
    }

    #exit {
      background-color: ${(props) => props.theme.default.ligtherI};
      color: ${(props) => props.theme.default.darkerI};
      border-color: ${(props) => props.theme.default.dark};

      &:hover {
        background-color: ${(props) => props.theme.default.light};
        border-color: ${(props) => props.theme.default.dark};
        color: ${(props) => props.theme.default.darkerI};
      }
    }
  }
`;
