import { useRef } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { DeleteRole, PromoteAdd, RemoveForRole } from "..";
import { AiFillCheckCircle, BsPersonDash, BsPersonPlus, FiTrash, IoIosArrowBack } from '../../../../../../assets/icons';
import { Colors, Input, Modal, TextArea } from "../../../../../../components";
import { patchGroupRole } from "../../../../../../services/requests/group-role";
import { updateGroupForAllUsers } from "../../../../../../store/actions/messages/chatAction";
import { TProfileGroup, TRoleInfo } from "../../../../../../types/project";
import { TAbareInfo, TypeConstRedux } from "../../../../../../types/redux";
import { getClientId, isFilled } from "../../../../../../util/verification";
import { Back, Container, Edit, Menu, Participants, Row } from './style';

type TProps = {
    abare: TAbareInfo
    groupInfo: TProfileGroup,
    updateGroupForAllUsers: (group: TProfileGroup) => void;
}

const EditRole = (props: TProps) => {
    const { abare, groupInfo, updateGroupForAllUsers } = props;
    const { id } = useParams<{ id }>();
    const roleInfo: TRoleInfo = groupInfo.roles.find(item => item.role.id == id) as TRoleInfo;
    const { role, users } = roleInfo;
    const isAdm = groupInfo?.participants?.find(user => user.id == abare.id && user.admin);
    const modalDeleteRole = useRef<any>();
    const modalAddParticipants = useRef<any>();
    const modalRemoveParticipants = useRef<any>();
    const { addToast } = useToasts();
    const { goBack } = useHistory()

    const updateRole = (event) => {
        event.preventDefault()
        const btn = event.nativeEvent.submitter.name

        if (btn == "buttton-check") {
            const { roleName, roleDesc, color, roleAcrom } = event.target.elements

            if (!isFilled(roleName.value)) {
                addToast({
                    title: "Campo nome inválido!",
                    description: "Digite um nome válido.",

                },
                    { appearance: 'warning' });
                return
            }

            const data = {
                name: roleName.value,
                color: color.value,
                description: isFilled(roleDesc.value) ? roleDesc.value : null,
                active: 1,
                acronym: isFilled(roleAcrom.value) ? roleAcrom.value : null,
            }


            patchGroupRole(groupInfo.id, role.id, data).then((res) => {
                if (res.status == 200) {
                    addToast({
                        title: "Função atualizada",
                        description: `Suas alterações foram salvas com sucesso.`

                    },
                        { appearance: 'success' });


                    const index = groupInfo.roles.findIndex(x => x.role.id == role.id)
                    groupInfo.roles[index].role.name = data.name;
                    groupInfo.roles[index].role.color = data.color;
                    groupInfo.roles[index].role.description = data.description
                    groupInfo.roles[index].role.acronym = data.acronym

                    updateGroupForAllUsers(groupInfo)
                    goBack()
                }
            })
        }
    }

    const updateMembersGroup = (members) => {
        const index = groupInfo.roles.findIndex(x => x.role.id === role.id);
        groupInfo.roles[index].users = members;

        updateGroupForAllUsers(groupInfo)
    }
    return (
        <Container>
            <form onSubmit={updateRole}>
                <Back>
                    <IoIosArrowBack size={22} id="icon-back" onClick={() => goBack()} />
                    <h3>Função</h3>
                    <div className="buttons">
                        {isAdm &&
                            <button type="button" onClick={() => modalDeleteRole.current.open()}>
                                <FiTrash size={26} className="icon-trash" />
                            </button>}
                        {isAdm &&
                            <button name="buttton-check" type="submit" >
                                <AiFillCheckCircle size={28} className={true ? "check show" : "check"} />
                            </button>}
                    </div>

                </Back>
                <Edit>
                    <Row>
                        <label id="label-role" htmlFor="role">Nome da função</label>
                        <Input name="roleName" disabled={!isAdm} maxLength={40} defaultValue={role.name} type="text" placeholder="Digite o nome aqui..." autoComplete="off" />
                        <label id="label-role" htmlFor="role">Acrônimo</label>
                        <Input name="roleAcrom" disabled={!isAdm} maxLength={20} defaultValue={role.acronym} type="text" placeholder="Digite o nome aqui..." autoComplete="off" />

                        <label id="label-role" htmlFor="role">Descrição</label>
                        <TextArea className="moreRows" name="roleDesc" disabled={!isAdm} cols="6" defaultValue={role.description} type="text" placeholder="Digite o nome aqui..." />

                    </Row>
                    <div id="hr" />
                    {isAdm &&
                        <Row className="pd-b-0">
                            <label id="label-role">Selecione uma cor</label>
                            <Colors color={role.color} />
                        </Row>
                    }
                </Edit>

                <Menu>
                    <label className="label-members"> Membros</label>

                    {isAdm && <div className="icon-circle" onClick={() => modalAddParticipants.current.open()}>
                        <BsPersonPlus id="icon" />
                    </div>}
                    {isAdm && <div className="icon-circle" onClick={() => modalRemoveParticipants.current.open()} >
                        <BsPersonDash id="icon" />
                    </div>}
                </Menu>

                {users.map((user, i) => (
                    <Participants key={i}>
                        <img alt="Perfil" src={user.photoUrl} />
                        <div className="name">{user.name ? user.name : '@' + user.nickname}</div>
                    </Participants>
                ))}

                <Modal ref={modalDeleteRole}>
                    <DeleteRole modal={modalDeleteRole} role={role} groupInfo={groupInfo} />
                </Modal>
                <Modal ref={modalAddParticipants}>
                    <PromoteAdd updateMembersGroup={updateMembersGroup} groupInfo={groupInfo} modal={modalAddParticipants}
                        roleId={role.id} participants={users} setParticipants={null} />
                </Modal>
                <Modal ref={modalRemoveParticipants}>
                    <RemoveForRole updateMembersGroup={updateMembersGroup} groupInfo={groupInfo} roleId={role.id}
                        modal={modalRemoveParticipants}
                        participants={users} />
                </Modal>
            </form>
        </Container>
    );
}

const mapStateToProps = (state: TypeConstRedux) => {
    return {
        abare: state.abare.abare,
        groupInfo: state.chat.globalMessages[getClientId(state.chat.personal)].client,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateGroupForAllUsers
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditRole);