import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconReport = ({
  color = "#666666",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
        <path
        d="M20.2699 6.9919C20.2705 6.99382 20.271 6.99578 20.2716 6.99777C20.2873 7.05333 20.3081 7.12999 20.3287 7.21456C20.3764 7.40981 20.4 7.55745 20.4 7.6104C20.4 7.61043 20.4 7.61046 20.4 7.6105V21.6C20.4 22.0243 20.2314 22.4313 19.9314 22.7314C19.6313 23.0314 19.2243 23.2 18.8 23.2H4.4C3.97565 23.2 3.56869 23.0314 3.26863 22.7314C2.96857 22.4313 2.8 22.0243 2.8 21.6V2.4C2.8 1.97565 2.96857 1.56869 3.26863 1.26863C3.56869 0.968571 3.97565 0.8 4.4 0.8H13.4719C13.4719 0.8 13.472 0.8 13.472 0.8C13.89 0.800078 14.2914 0.963749 14.5903 1.256L19.9183 6.46636L19.9184 6.4665C19.9562 6.50345 20.0453 6.62357 20.1518 6.79419C20.1979 6.86804 20.2381 6.93652 20.2669 6.9866C20.2679 6.98839 20.2689 6.99016 20.2699 6.9919Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.80005 13.1999H14M6.80005 17.9999H10.4M6.80005 8.3999H15.8"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default IconReport;
