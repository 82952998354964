import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/components/CommonPropTypes";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { ToastModel } from "../../../../../util/error";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import { ButtonCreate, ContainerElements, Element } from "../style";
import { useContext } from "react";
import { ReportContext } from "../../../../../contexts/ReportContext";

interface ButtonsProps {
  data: Object[] | undefined;
  header: HeaderTable;
  downloadPdf: () => void;
  isLoading: boolean;
  isWaiting: boolean;
}

const DownloadBlock = ({
  isLoading,
  isWaiting,
  data,
  header,
  downloadPdf,
}: ButtonsProps) => {
  const { addToast } = useToasts();
  const { stylesPDF } = useContext(ReportContext);
  const title = stylesPDF.title.text.toString();
  const headerCSV = () => {
    if (header) {
      const headerCSV: LabelKeyObject[] = [];
      Object.keys(header.itens).map((column) => {
        if (header.itens[column].checked) {
          const lineDate: LabelKeyObject = {
            label: header.itens[column].label,
            key: column,
          };
          headerCSV.push(lineDate);
        }
      });
      return headerCSV;
    }
    return [];
  };
  function donwloadType(type: number) {
    if (isWaiting) {
      ToastModel(addToast, "Selecione uma opção para busca", "", "warning");
      return false;
    } else if (isLoading) {
      ToastModel(addToast, "Carregando as informações", "", "warning");
      return false;
    } else {
      if (type == 1) {
        downloadPdf();
      } else {
        return true;
      }
    }
  }

  return (
    <ContainerElements>
      <h3 id="title">Baixar Arquivo</h3>
      <Element>
        <ButtonCreate
          onClick={() => {
            donwloadType(1);
          }}
        >
          <FaFilePdf id="icon" size={28} />
          <p>PDF</p>
        </ButtonCreate>
      </Element>
      <Element>
        <CSVLink
          className="linkCSV"
          headers={headerCSV()}
          onClick={() => {
            return donwloadType(2);
          }}
          filename={title}
          data={data === undefined ? [] : data}
        >
          <ButtonCreate>
            <FaFileCsv id="icon" size={28} />
            <p>CSV</p>
          </ButtonCreate>
        </CSVLink>
      </Element>
    </ContainerElements>
  );
};

export default DownloadBlock;
