import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineColumnHeight } from "react-icons/ai";
import { BiAlignMiddle, BiColorFill } from "react-icons/bi";
import { BsBorderOuter, BsCheckLg, BsXLg } from "react-icons/bs";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoReorderThreeOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { Checkbox, InputSelect, Modal } from '../../../../../components';
import { InputColor } from "../../../../../components/Input";
import { ContainerColor, ContainerInput } from "../../../../../components/Input/style";
import { EnumBackgroundTable, EnumBorderTable, ReportEnum, ReportStylesInterface } from "../../../../../types/project";
import { Utils } from "../../../../../util/constantes";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import { ContainerOption } from "../Blocks/VisualOptions/style";
import ColumnsModal from "../Modais/ColumnsModal";
import { Element, GroupColumns, OptionTitle } from "../style";
import { ReportContext } from "../../../../../contexts/ReportContext";

interface VisualBlockProps {
    report?: ReportEnum;

    header: HeaderTable;
    setHeader: React.Dispatch<React.SetStateAction<HeaderTable | undefined>>;
    listeners: SyntheticListenerMap | undefined;
    attributes: DraggableAttributes;
}

const TableOption = ({ header, setHeader, listeners, attributes }: VisualBlockProps) => {
    const {stylesPDF, handleStylesPDF} = useContext(ReportContext)
    const columnsTable = Object.keys(header.itens);
    const allChecked = !columnsTable.some((column) => !header.itens[column].checked);
    const someChecked = columnsTable.some((column) => header.itens[column].checked);

    const modalConfigColumns = useRef<any>();

    const borderStyle = [
        { value: EnumBorderTable.DEFAULT, label: "Com Bordas" },
        { value: EnumBorderTable.ONLY_HORIZONTAL, label: "Apenas bordas horizontais" },
        { value: EnumBorderTable.ONLY_HEADER, label: "Apenas borda no header" },
        { value: EnumBorderTable.NO_BORDER, label: "Sem Bordas" }];

    const backgroundStyle = [
        { value: EnumBackgroundTable.NO_BACKGROUND, label: "Sem background" },
        { value: EnumBackgroundTable.ONLY_HEADER, label: "Apenas no header" },
        { value: EnumBackgroundTable.ZEBRA, label: "Estilo zebra" },]

    // Estados dos componentes
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(true);

    const [selectedOption, setSelectedOption] = useState<EnumBorderTable>(stylesPDF.styleBorder);
    const [selectedBackgroundOption, setSelectedBackgroundOption] = useState<EnumBackgroundTable>(stylesPDF.background);
    const [colorBackground, setColorBackgound] = useState<string>(stylesPDF.colorBackground);
    const [colorBorder, setColorBorder] = useState<string>(stylesPDF.colorBorder);

    const [height, setHeight] = useState<number | "auto">(stylesPDF.lineHeight);

    useEffect(() => {
        closeEdit()
    }, [stylesPDF])

    function saveStyle() {
        handleStylesPDF({ ...stylesPDF, styleBorder: selectedOption, lineHeight: height, background: selectedBackgroundOption, colorBackground: colorBackground, colorBorder: colorBorder });
        setIsEdit(false);
    }

    function editVisible() {
        handleStylesPDF({ ...stylesPDF, visibleTable: !stylesPDF.visibleTable });
    }
    function closeEdit() {
        setHeight(stylesPDF.lineHeight);
        setSelectedOption(stylesPDF.styleBorder);
        setColorBorder(stylesPDF.colorBorder);
        setColorBackgound(stylesPDF.colorBackground);
        setHeight(stylesPDF.lineHeight);
        setIsEdit(false);
    }

    async function setAllChecked() {
        if (allChecked || someChecked) {
            await columnsTable.map((column) => {
                if (header.itens[column].checked) {
                    header.itens[column].checked = false;
                }
            });
            setHeader({ ...header });
        }
        else {
            await columnsTable.map((column) => {
                if (!header.itens[column].checked) {
                    header.itens[column].checked = true;
                }
            });
            setHeader({ ...header });

        }
    }

    return (
        <ContainerOption>
            <OptionTitle>
                <div className="flex">
                    <IoReorderThreeOutline className="order" {...listeners} {...attributes} />
                    <h5>Tabela</h5>
                </div>
                <div className="buttons">
                    {!isEdit ?
                        <>
                            {stylesPDF.visibleTable
                                ? <FiEye className="icon-color" onClick={() => editVisible()} />
                                : <FiEyeOff className="icon-color" onClick={() => editVisible()} />}
                            <MdModeEdit className="icon-color" onClick={() => setIsEdit(true)} size={18} />

                        </>
                        :
                        <>
                            <BsXLg className="icon-color" onClick={closeEdit} size={18} />
                            <BsCheckLg className="icon-color" onClick={saveStyle} size={18} />
                        </>}
                    {/* <IoIosArrowUp className={isOpen ? "center down" : "center up"} onClick={() => setIsEdit(true)} size={18} /> */}
                </div>
            </OptionTitle>

            {
                isOpen && <>
                    {/* Campo Estilo de Borda */}
                    <Element>
                        <div className="icon">
                            <BsBorderOuter title="Estilo da borda" size="20px" />
                        </div>
                        {isEdit ?
                            <>  <InputSelect
                                $flex={true}
                                value={borderStyle.find((option) => (option.value === selectedOption))}
                                onChange={(e) => setSelectedOption(e.value)}
                                placeholder="Selecione o estilo de borda" options={borderStyle} />
                                <InputColor value={colorBorder} onChange={(color) => { setColorBorder(color.hex!) }} />
                            </>
                            :
                            <>
                                <p>{borderStyle!.find((option) => (option.value === selectedOption))!.label}</p>
                                <ContainerColor $marginLeft="4px" color={colorBorder} />
                            </>
                        }
                    </Element>

                    {/* Campo Estilo do Backgroung */}
                    <Element>
                        <div className="icon">
                            <BiColorFill title="Estilo do background" size="20px" />
                        </div>
                        {isEdit ?
                            <><InputSelect
                                $flex={true}
                                value={backgroundStyle.find((option) => (option.value === selectedBackgroundOption))}
                                onChange={(e) => setSelectedBackgroundOption(e.value)}
                                placeholder="Selecione o estilo de background" options={backgroundStyle} />
                                <InputColor value={colorBackground} onChange={(color) => { setColorBackgound(color.hex!) }} />
                            </>
                            :
                            <>
                                <p>{backgroundStyle!.find((option) => (option.value === selectedBackgroundOption))!.label}</p>
                                <ContainerColor $marginLeft="4px" color={colorBackground} />
                            </>
                        }
                    </Element>

                    <Element>
                        {/* Campo de alinhamento da coluna */}
                        <div className="icon">
                            <BiAlignMiddle title="Alinhamento" size="20px" />
                        </div>
                        {isEdit ?
                            <InputSelect
                                $flex={true}
                                value={Utils.alignmentValues.find((option) => (option.value === stylesPDF.alignmentTable))}
                                onChange={(e) => {
                                    stylesPDF.alignmentTable = e.value
                                }} placeholder="Alinhamento" options={Utils.alignmentValues} />
                            :
                            <p>{Utils.alignmentValues.find((option) => (option.value === stylesPDF.alignmentTable))?.label}</p>
                        }
                    </Element>


                    {/* Campo Altura das Linhas da Tabela */}
                    <Element>

                        <div className="icon">
                            <AiOutlineColumnHeight title="Altura mínima da linha" size="20px" />
                        </div>

                        {isEdit ? <div className="end">
                            <div className="flex">
                                <ContainerInput $width="50px">
                                    <input
                                        name='title_report'
                                        value={(height != "auto") ? height : "30"}
                                        onChange={(e) => setHeight(parseInt(e.target.value))}
                                        id='title_report'
                                        type="number"
                                    // placeholder="Digite a altura das linhas ..."
                                    />
                                    pt
                                </ContainerInput>

                            </div>


                            <div className="line">
                                <Checkbox name="lineHeight" type="checkbox" value="lineHeight"
                                    checked={height == "auto"}
                                    onChange={() => setHeight("auto")} />
                                Padrão
                            </div></div>
                            : <p>{stylesPDF.lineHeight === "auto" ? "Padrão" : `${stylesPDF.lineHeight}pt`}</p>
                        }

                    </Element>

                    {/* Campo de escolhas das colunas */}
                    <Element border={true} column={true}>
                        <div className="allChecked">
                            {/* <BiColumns size="20px" /> */}
                            <p className="orange">Colunas da Tabela</p>
                            <div className="line">
                                <Checkbox name={"Todos"} type="checkbox" value={"All"}
                                    checked={allChecked || someChecked}
                                    line={!allChecked}
                                    onChange={setAllChecked} />
                                Todas
                                {isEdit && <div className="buttons">
                                    <MdModeEdit className="icon" onClick={() => modalConfigColumns.current.open()} size={18} />
                                </div>}

                            </div>

                        </div>
                        <GroupColumns>

                            {columnsTable.map((column) => (
                                <div className="line" key={column}>
                                    <Checkbox name={column} type="checkbox" value={column}
                                        checked={header.itens[column].checked?? false}
                                        onChange={() => {
                                            setHeader((oldHeader) => {
                                                if (oldHeader !== undefined) {
                                                    const header = { ...oldHeader };
                                                    const teste: boolean = !oldHeader.itens[column].checked;
                                                    header.itens[column].checked = teste
                                                    return { ...header };
                                                }
                                                return oldHeader;

                                            })
                                        }} />
                                    {header.itens[column].label}
                                </div>

                            ))}
                        </GroupColumns>
                    </Element>
                </>
            }

            <Modal mobile={true} ref={modalConfigColumns} >
                <ColumnsModal header={header} setHeader={setHeader} columnsTable={columnsTable} modal={modalConfigColumns} />
            </Modal>
        </ContainerOption>

    );
}

export default TableOption;