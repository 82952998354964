import { GoInbox, GoSearch } from "react-icons/go";
import { GoAlert } from "../../assets/icons";
import { abare_alert } from '../../assets/images/index';
import { Container } from './style';
interface NotFoundProps {
    text?: string;
    description?: string;
    type?: "notFound" | "empty" | "search" | "alert";
}

const NotFound = ({ text, description, type = "notFound" }: NotFoundProps) => {
    const Icon = () => {
        switch (type) {
            case "empty":
                return <GoInbox id="icon-alert" />
            case "notFound":
                return <GoAlert id="icon-alert" />
            case "search":
                return <GoSearch id="icon-alert" />
            case "alert":
                return <img src={abare_alert} id="icon-alert" />
            default:
                return <GoAlert id="icon-alert" />
        }
    }
    return (
        <Container>
            <Icon />
            <h1 id="text">{text}</h1>
            <h3 id="description">{description}</h3>
        </Container>
    );
}

export default NotFound;