import { MouseEventHandler, useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { IoIosArrowUp } from "react-icons/io";
import { IoAdd } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { ContainerOption } from "../../Blocks/VisualOptions/style";
import { OptionTitle } from "../../style";

export interface ModalProps {
    modal: React.ReactNode;
    modalItem: React.MutableRefObject<any>;
}
interface SectionProps {
    title: string;
    children: React.ReactNode;
    buttonHidden?: boolean;
    edit?: {
        isEdit: boolean;
        setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
        handleClose: MouseEventHandler<HTMLOrSVGElement>;
        handleSave: MouseEventHandler<HTMLOrSVGElement>;
    };
    modal?: ModalProps;
}

const SectionOption = ({ title, children, edit, modal, buttonHidden = true }: SectionProps) => {
    const [showItems, setShowItems] = useState(false);
    const toggleItems = () => {
        if (buttonHidden)
            setShowItems(!showItems);
    };
    return (
        <ContainerOption $borderBottom={true}>
            <OptionTitle onClick={toggleItems}>
                <IoIosArrowUp  className={showItems ? "center down icon-arrow" : "center up icon-arrow"} size={18} />

                <h3>{title}</h3>
                <div className="buttons">
                    {modal && <IoAdd className="icon-color" size={22} onClick={(e) =>(modal.modalItem.current.open(), e.stopPropagation())} />}
                    {edit && !edit.isEdit &&
                        <MdModeEdit className="icon-color" onClick={(e) => (edit.setIsEdit(true), e.stopPropagation(), setShowItems(true))} size={22} />}
                    {edit && edit.isEdit && <>
                        <BsXLg className="icon-color" onClick={(e) => (edit.handleClose(e), e.stopPropagation())} size={22} />
                        <BsCheckLg className="icon-color" onClick={(e) => (edit.handleSave(e), e.stopPropagation())} size={22} />
                    </>}
                </div>
            </OptionTitle>
            <div className={`child-div ${showItems ? 'show' : 'hide'} tab`}>
                {children}

            </div>
            {modal && modal.modal}
        </ContainerOption>
    );
}

export default SectionOption;