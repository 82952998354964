import { memo, useEffect, useState } from "react";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { IoIosArrowBack } from '../../../../../../assets/icons';
import { Load } from "../../../../../../components";
import { getUserForPrivacyGroup } from "../../../../../../services/requests/user-group";
import { TypeConstRedux } from "../../../../../../types/redux";
import {cpfOrCnpjFormat, dateFormat, getBioSex, getClientId, getTypeRelationship, photoUrl } from "../../../../../../util/verification";
import { Back, Container, Item, MainLoad, Row } from './style';

const ItemData = ({ itens }) => (
    itens.map(({ name, value, className }, i) => (
        value ?
            <Row className={className} key={i}>
                {name}
                <div className="end">
                    {value}
                </div>
            </Row>
            :
            null
    ))
)

const ProfileUser = (props) => {
    const { personal } = props;
    const [contact, setContact] = useState({} as any)
    const { goBack } = useHistory();
    const { id } = useParams<{ id }>();
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setLoad(true);
        getUserForPrivacyGroup(id, getClientId(personal)).then((res) => {
            if (res.status == 200) {
                const isContact = true
                setContact(res.data)
            }
            setLoad(false);
        })
    }, [])

    const datas = [
        {
            title: "Dados básicos",
            itens: [
                { name: 'CPF/CNPJ', value:  cpfOrCnpjFormat(contact?.cnpj || contact?.cpf, !contact?.cnpj) },
                { name: "Data de Aniversário", value: dateFormat(contact?.birthday) },
                { name: "Relacionamento", value: getTypeRelationship(contact?.relationship) },
                { name: "Sexo", value: getBioSex(contact.bioSex) },
            ]
        },
        {
            title: "Contato",
            itens: [
                { name: 'E-mail', value: contact?.email },
                { name: "Número de Telefone", value: formatPhoneNumberIntl(contact?.phoneNumber) },
                { name: "Endereço", value: contact?.address },
            ]
        },
        {
            title: "Título de Eleitor",
            className: "voterData",
            itens: [
                { name: 'N° Inscrição', value: contact?.voterNumber, className: "number" },
                { name: "Zona", value: contact?.voterZone, className: "zone" },
                { name: "Seção", value: contact?.voterSection, className: "section" },
            ]
        },

    ]

    return (
        !load ?
            <>
                <Back>
                    <IoIosArrowBack size={22} id="icon" onClick={() => goBack()} />
                    <h3>Informações do usuário</h3>
                </Back>
                <Container>
                    <div id="profile">
                        <img id="img" src={photoUrl(contact)} />
                        <h5 id="name">
                            {contact.name ?
                                contact.name
                                :
                                "@" + contact.nickname
                            }
                        </h5>
                        {contact.name && <h5 id="nick">@{contact.nickname}</h5>}
                    </div>
                    {/* <Item>
                        <h3 className="title-item">Mídia</h3>
                        <HorizontalMidia>
                            <Midia />
                        </HorizontalMidia>
                    </Item> */}
                    {datas.map(({ title, className, ...rest }, i) => (
                        <Item className={className} key={i}>
                            <h3 className="title-item">{title}</h3>
                            <ItemData {...rest} />
                        </Item>
                    ))}

                </Container>
            </>
            :
            <MainLoad>
                <Load width="30px" />
                <h4 id="loading">Carregando...</h4>
            </MainLoad>
    );
}
const mapStateToProps = (state: TypeConstRedux) => ({
    personal: state.chat.personal
})


export default connect(mapStateToProps)(memo(ProfileUser));