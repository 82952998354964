import styled from "styled-components";

export const Container = styled.button`
  font-size: 1rem;
  color: ${({ theme }) => theme.texts.secondaryToolbar};
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
