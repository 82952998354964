import styled from "styled-components";

export const Card = styled.div`
  border-color: ${({ theme }) => theme.texts.purpleDark};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  margin: 1rem;
  margin-top: 0rem;
  color: ${({ theme }) => theme.texts.black};
  border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};

  input {
    cursor: pointer;
  }

  header {
    z-index: 50;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon {
      font-size: 20px;
      background-color: ${({ theme }) => theme.backgrounds.buttonSchedule};
      border-radius: 50%;
      padding: 2px;
      color: ${({ theme }) => theme.texts.white};
      cursor: pointer;
    }
  }
  .title {
    font-family: "inter-bold", sans-serif;
    margin: 4px 0px 8px 0px;
    padding: 0;
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
  }

  .option {
    cursor: pointer;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.texts.black};
  }

  .lineChecked {
    p {
      margin: 0px;
      padding: 0px;
    }

    display: flex;
    align-items: center;
    margin-bottom: 2px;
    flex-direction: row;
    cursor: pointer;
  }
`;

export const Toolbar = styled.header`
  display: grid;
  grid-template-columns: 175px 1fr 175px;
  flex-direction: row;
  overflow: initial;
  @media only screen and (max-width: 640px) {
    grid-template-columns: 140px 1fr auto;
  }
  justify-content: space-between;
  margin-bottom: 0.9rem;
  align-items: center;
  .right {
    transform: rotate(180deg);
  }

  .toolbar-label {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
    }
    svg {
      margin: 4px;
      cursor: pointer;
    }
  }

  .toolbar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    button {
      margin: 4px;
    }
  }

  .option {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
`;

export const ButtonEvent = styled.button`
  display: flex;
  border-radius: 16px;
  align-items: center;
  padding: 8px 14px;
  color: ${(props) => props.theme.texts.black};
  background-color: ${(props) => props.theme.backgrounds.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};

  :hover {
    background-color: ${({ theme }) => theme.hover.buttonHover};
  }
  :active {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  }

  #icon {
    color: ${(props) => props.theme.texts.black};
  }
`;

export const ContainerEvent = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px;
  z-index: 98;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  a {
    flex: 1;
    align-self: center;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.texts.black};
    :hover {
      cursor: pointer;
    }
  }

  header {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    #ttt {
      color: black !important;
    }
    .iconButton {
      padding: 4px;
      color: inherit !important;
      cursor: pointer;
      border-radius: 50%;
      font-size: 20px;
      :hover {
        background-color: ${({ theme }) => theme.hover.default};
      }
    }
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .category {
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
    h2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0px;
      margin: 0px;
      margin-left: 8px;
    }
  }
  .date {
    margin-left: 20px;
    font-size: 0.8rem;
  }

  .actions {
    display: grid;
    grid-gap: 8px;
    margin-top: 8px;
    grid-template-columns: auto auto;
    justify-content: flex-end;
  }

  .description {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      margin-left: 8px;
    }
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  .icon {
    height: 12px;
    width: 12px;
  }
  .iconDropEvent {
    font-size: 25px;
    align-self: center;
    color: ${(props) => props.theme.begin.color.button.secondary};
    margin-right: 8px;
  }
`;

interface StyledProps {
  color?: string;
  justifyC?: string;
  border?: string;
  background?: string;
  marginTop?: string;
}
export const ButtonElement = styled.button<StyledProps>`
  display: grid;
  grid-template-columns: 35px auto;
  padding: 0px 14px;
  margin: 0px;
  min-height: 35px;
  border-radius: 8px;
  border: 1.5px solid ${(props) => (props.border ? props.border : "red")};
  color: ${(props) => (props.color ? props.color : "red")};
  background-color: ${(props) =>
    props.background ? props.background : "transparent"};
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: start;
  align-items: center;
  white-space: nowrap;
`;

export const Item = styled.div`
  .title {
    color: ${({ theme }) => theme.texts.black};
  }
  form {
    overflow: hidden;
    transition: all 0.4s ease-in-out;
  }
  .buttons {
    > * {
      margin-left: 4px;
    }
  }
  .icon-arrow {
    cursor: pointer;
    border-radius: 50%;
    padding: 2px;
    :hover {
      background-color: ${({ theme }) => theme.hover.default};
    }
  }
  .up {
    transform: rotate(0deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  .down {
    transform: rotate(180deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }
`;

export const ModalCalendarContainer = styled.div`
  position: absolute;
  background-color: red;
`;
