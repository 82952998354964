import styled from "styled-components";

export const Exit = styled.div`
  text-align: center;

    #icon {
      color: ${(props) => props.theme.texts.darkerGreyI};
    }

    #message {
      margin: 0px 0px 16px 0px;
      color: ${(props) => props.theme.texts.darkerGreyI};
      font-weight: normal;
    }

  .action {
    padding: 18px 0px 16px 0px;

    button {
      padding: 10px 16px;
      border-radius: 6px;
      border: solid 1px;
      transition: all 200ms ease-in 0s;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

interface ButtonProps{
  margin?: boolean;
}
export const ButtonPassWord = styled.button<ButtonProps>`
  display: block;
  align-items: center;
  font-size: 16px;
  color: ${({theme}) => theme.hover.red};
  border: 1px solid ${({theme}) => theme.hover.red};
  text-align: center;
  width: 10rem;
  height: 1.8rem;
  margin: 8px 12px 2rem 12px;
  border-radius: 2px;

  :hover {
    color: ${({theme}) => theme.texts.white};
    background-color: ${({theme}) => theme.hover.red};
  }
`;

export const ButtonConfirmPassWord = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background-color: ${({theme}) =>  theme.hover.green};
  text-align: center;
  width: 10rem;
  height: 1.8rem;
  margin: 8px 12px 2rem 8px;
  border-radius: 2px;
`;