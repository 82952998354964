import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { TypeConstRedux } from "../../types/redux";
import { Container, MenuList } from "./style";
import { SVGComponent } from "../../types/project";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import IconArrowDown from "../../assets/icons/IconArrowDown";
import IconDot from "../../assets/icons/IconDot";
import { setOptionConfig } from "../../store/actions/configAction";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  backdropClasses,
} from "@mui/material";
import Settings from "../../pages/home/Settings";
import { BsEye } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import IconConfig from "../../assets/icons/IconConfig";
import IconConfigFill from "../../assets/icons/IconConfigFill";
import { ItemWithOptions } from "./ItemWithOptions";

export interface ButtonItemSchema {
  text: string;
  Icon: any;
  url?: string;
  IconFill: any;
  component?: any;
  subItens?: { text: string; url: string; value: number }[];
}
const NavBar = (props) => {
  const { children, options } = props;
  const { navBarMinimized } = useSelector(
    (state: TypeConstRedux) => state.abare
  );
  const { option } = useSelector((state: TypeConstRedux) => state.config);
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpen = () => {
    if (isOpenModal) return dispatch(setOptionConfig(null));
  };

  const ButtunLayout = ({
    text,
    Icon,
    url,
    IconFill,
    subItens,
  }: ButtonItemSchema) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    return (
      <div title={text} className={`item-menu`}>
        {url ? (
          <NavLink
            to={url}
            className={(isActive) =>
              "nav-link " +
              " " +
              (isActive ? "actived" : null) +
              " " +
              (navBarMinimized ? "nav-link-minimized" : null)
            }
          >
            <div className="container-icons">
              {IconFill && <IconFill navBar={true} className="active-indicator" />}
              {Icon && <Icon className="icon icon-default" />}
            </div>

            <p className={`text ${navBarMinimized ? "hidden" : " "}`}>{text}</p>
          </NavLink>
        ) : null}
        {subItens ? (
          <ItemWithOptions
            Icon={Icon}
            isSmall={navBarMinimized}
            IconFill={IconFill}
            subItens={subItens}
            text={text}
            key={text}
          />
        ) : null}
      </div>
    );
  };

  const style = {
    position: "absolute" as "absolute",
    top: "0",
    right: "0",
    width: 400,
    height: "100vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    borderRadius: "8px",
  };

  useEffect(() => {
    if (option !== null) {
      setIsOpenModal(true);
    } else {
      setIsOpenModal(false);
    }
  }, [option]);

  return (
    <Container open={!navBarMinimized}>
      {children}
      <Modal
        open={isOpenModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        onClose={handleOpen}
        slotProps={{
          root: {
            style: {
              zIndex: 98,
            },
          },
          backdrop: {
            style: {
              backgroundColor: "transparent",
            },
          },
        }}
      >
        <Box sx={{ ...style, maxWidth: 400, width: "100%" }}>
          <Settings />
        </Box>
      </Modal>
      <MenuList>
        {options.map((option) => (
          <ButtunLayout key={option.text} {...option} />
        ))}
      </MenuList>
    </Container>
  );
};

export default NavBar;
