import styled from "styled-components";

export const Exit = styled.div`
  text-align: center;

    #icon {
      color: ${(props) => props.theme.texts.darkerGreyI};
    }

    #message {
      margin: 0px 0px 16px 0px;
      color: ${(props) => props.theme.texts.darkerGreyI};
      font-weight: normal;
    }

  .action {
    padding: 18px 0px 16px 0px;

    button {
      padding: 10px 16px;
      border-radius: 6px;
      border: solid 1px;
      transition: all 200ms ease-in 0s;
    }
  }
`;
