import { Size, Style } from "pdfmake/interfaces";
import { ReportEnum, TypeDataTable } from "../../types/project";

export type ColumnReportProps = {
  label: string;
  width: Size;
  type: TypeDataTable;
  checked: boolean | undefined;
};
export const dictionaryHeader: Record<string, ColumnReportProps> = {
  admin: {
    label: "Administrador",
    width: "auto",
    type: TypeDataTable.BOOLEAN,
    checked: false,
  },
  anniversary: {
    label: "Mês",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  bioSex: {
    label: "Sexo",
    width: "auto",
    type: TypeDataTable.SPECIAL_BIO_SEXY,
    checked: undefined,
  },
  birthday: {
    label: "Nascimento",
    width: "auto",
    type: TypeDataTable.DATA_BIRTHDAY,
    checked: true,
  },
  confirmations: {
    label: "Confirmações",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  cpf: {
    label: "CPF",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  cnpj: {
    label: "CNPJ",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  city: {
    label: "Cidade",
    width: "auto",
    type: TypeDataTable.NUMBER,
    checked: false,
  },
  countMessage: {
    label: "Mensagens",
    width: "auto",
    type: TypeDataTable.NUMBER,
    checked: true,
  },
  country: {
    label: "País",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  dateEnd: {
    label: "Fim",
    width: "auto",
    type: TypeDataTable.DATA,
    checked: true,
  },
  dateStart: {
    label: "Início",
    width: "auto",
    type: TypeDataTable.DATA,
    checked: true,
  },
  description: {
    label: "Descrição",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },
  edit: {
    label: "Editado",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  email: {
    label: "Email",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  Event_id_root: {
    label: "Id da rota do evento",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  EventCategory_id: {
    label: "Id da categoria do evento",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  geoRefLat: {
    label: "Latitude",
    width: "auto",
    type: TypeDataTable.NUMBER,
    checked: false,
  },
  geoRefLon: {
    label: "Longitude",
    width: "auto",
    type: TypeDataTable.NUMBER,
    checked: false,
  },
  Group_id: {
    label: "Id do grupo",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },

  group_name: {
    label: "Grupo",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },
  name_group: {
    label: "Grupo",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },
  id: {
    label: "Id",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  lastLoginAt: {
    label: "Último login em",
    width: "auto",
    type: TypeDataTable.DATA,
    checked: true,
  },
  lastName: {
    label: "Sobrenome",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },

  name: {
    label: "Nome",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },
  group: {
    label: "Grupo",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },
  nickname: {
    label: "Apelido",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  participation: {
    label: "Participações",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  phoneNumber: {
    label: "Telefone",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: true,
  },
  presence: {
    label: "Presença",
    width: "auto",
    type: TypeDataTable.PRESENCE,
    checked: true,
  },
  state: {
    label: "Estado",
    width: "auto",
    type: TypeDataTable.NUMBER,
    checked: false,
  },
  status: {
    label: "Status",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  relationship: {
    label: "Relação",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  title: {
    label: "Título",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },

  type: {
    label: "Tipo",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  updateAt: {
    label: "Atualizado em ",
    width: "auto",
    type: TypeDataTable.DATA,
    checked: true,
  },
  street: {
    label: "Logradouro",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  signature: {
    label: "Assinatura",
    width: "*",
    type: TypeDataTable.STRING,
    checked: true,
  },
  voterNumber: {
    label: "Título",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  voterSection: {
    label: "Seção",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },

  voterZone: {
    label: "Zona",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
  zipCode: {
    label: "CEP",
    width: "auto",
    type: TypeDataTable.STRING,
    checked: false,
  },
};

export interface ItemHeaderTable {
  label: string;
  checked: boolean;
  type: string;
  width: Size;
}
export interface HeaderTable {
  itens: Record<string, ColumnReportProps & Style & { text: string | null }>;
  length: number;
  countChecked: number;
}

export const mockEvent = [
  "title",
  "description",
  "group_name",
  "confirmations",
  "participation",
  "dateStart",
  "dateEnd",
  "status",
  "type",
];
export const mock_event_group = [
  "name_group",
  "title",
  "description",
  "confirmations",
  "participation",
  "dateStart",
  "dateEnd",
  "status",
  "type",
];
export const mock_user_group = [
  "name",
  "nickname",
  "cpf",
  "admin",
  "bioSex",
  "email",
  "phoneNumber",
  "voterNumber",
  "voterZone",

  "voterSection",
  "zipCode",
];

export const mock_group = [
  "group",
  "name",
  "nickname",
  "cpf",
  "bioSex",
  "email",
  "phoneNumber",
  "voterNumber",
  "voterZone",

  "voterSection",
  "zipCode",
];
export const contactsBirthdaysMock = [
  "name",
  "email",
  "birthday",
  "anniversary",
];
export const mock_contacts = [
  "name",
  "lastName",
  "email",
  "cpf",
  "cnpj",
  "phoneNumber",
  "bioSex",
  "birthday",
  "relationship",
  "street",
  "zipCode",
  "city",
  "state",
  "country",
  "geoRefLat",
  "geoRefLon",
  "voterNumber",
  "voterZone",
  "voterSection",
];

export const mock_events_confirmations = ["name", "nickname", "presence"];

export const typeRequiredSignature = (type: ReportEnum): boolean => {
  if (type === ReportEnum.GROUP_MEMBERS)
    return true;
  return false;
};

export const columnsTable = (type: ReportEnum): HeaderTable => {
  let columns: string[] = [];
  switch (type) {
    case ReportEnum.EVENT_PARTICULAR:
      columns = mockEvent;
      break;
    case ReportEnum.EVENT_GROUP:
      columns = mock_event_group;
      break;
    case ReportEnum.CONTACTS_BIRTHDAYS:
      columns = contactsBirthdaysMock;
      break;
    case ReportEnum.CONTACTS_ALL:
      columns = mock_contacts;
      break;
    case ReportEnum.GROUP_ALL:
      columns = mock_group;
      break;
    case ReportEnum.GROUP_MEMBERS:
      columns = mock_user_group;
      break;
    case ReportEnum.EVENT_CONFIRMATIONS:
      columns = mock_events_confirmations;
      break;
    default:
      columns = mockEvent;
      break;
  }
  const itens = {};
  columns.forEach((column) => {
    itens[column] = {
      label: dictionaryHeader[column]
        ? dictionaryHeader[column].label
        : "Adicionar objetos",
      checked:
        dictionaryHeader[column].checked !== undefined
          ? dictionaryHeader[column].checked
          : true,
      type: dictionaryHeader[column] ? dictionaryHeader[column].type : "string",
      width: dictionaryHeader[column] ? dictionaryHeader[column].width : "*",
      fontSize: 12,
      color: "#000",
      bold: false,
      italics: false,
      margin: [0, 0, 0, 0],
    };
  });
  if (typeRequiredSignature(type)) {
    itens["signature"] = {
      label: dictionaryHeader["signature"].label,
      checked: true,
      type: dictionaryHeader["signature"].type,
      width: dictionaryHeader["signature"].width,
      fontSize: 12,
      color: "#000",
      bold: false,
      italics: false,
    };
  }
  const header: HeaderTable = {
    itens: itens,
    length: columns.length,
    countChecked: columns.length,
  };

  return header;
};
