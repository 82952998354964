import { useEffect, useState } from "react";
import { User } from "../../assets/images/index";
import { transformUrlImage } from "../../util/verification";
import { ShimmerCircle } from "../Shimmers";

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  square?: boolean;
  fallbackSrc?: string;
  personal?: boolean;
}

const Img = ({
  src,
  square = true,
  fallbackSrc,
  personal = true,
  ...rest
}: ImgProps) => {
  const [mySrc, setMySrc] = useState(transformUrlImage(src) ?? "");
  const [onLoad, setOnLoad] = useState(false);

  useEffect(() => {
    setMySrc(transformUrlImage(src));
    setOnLoad(true);
  }, [src]);


  const handleImageLoad = () => {
    setOnLoad(false);
  };

  const handleError = () => {
    setOnLoad(false);
    setMySrc(fallbackSrc ?? User);
  };

  return (
    <>
      {onLoad ? <ShimmerCircle {...rest} /> : null}
      <img
        onLoad={handleImageLoad}
        src={mySrc}
        onError={handleError}
        
        style={{ display: (onLoad ? "none" : "block") }}
        {...rest}
      />
    </>
  );
};

export default Img;
