import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Checkbox, Load, NotFound, Search } from "../../../../../components";
import { Checked, handleChange } from "../../../../../components/Checked";
import Modal from "../../../../../components/Modal/ModalBlock";
import ModalContainer from "../../../../../components/Modal/ModalContent";
import { deleteUserBlock, getUsersBlock } from "../../../../../services/requests/privacy";
import { setAbare } from "../../../../../store/actions/abareAction";
import { TContact } from "../../../../../types/project";
import { ToastModel } from "../../../../../util/error";
import { HorizontalRow } from "../../style";

const Blockeds = (props) => {
    const { abare, toChangeAbare, modalBlockUser } = props
    const [contacts, setContacts] = useState<TContact[]>([]);
    const [hidden, setHidden] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const { addToast } = useToasts();
    const [usersIdsToRemoveBlock, setUsersIdsToRemoveBlock] = useState<string[]>([])

    useEffect(() => {
        getUsersBlock(abare.id).then((res) => {
            if (res.status == 200) {
                setContacts(res.data)
            }
        });
    }, [abare])

    const contactsBlock = (e) => {
        e.preventDefault();
        if (hidden) {
            var userIds: Array<any> = [];

            if (allChecked) {
                contacts.map(function (element) {
                    userIds.push(element.id)
                })
            } else {
                userIds = usersIdsToRemoveBlock
            }
            deleteUserBlock(abare.id, userIds).then((resp) => {
                if (resp.status == 200) {
                    toChangeAbare({ ...abare, resp })
                    modalBlockUser.current?.close();
                    ToastModel(addToast, "Usuário(s) desbloqueado", "Usuário(s) poderá enviar mensagens e fazer ligações", "success");
                } else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            })
        }
    }

    return (
        <Modal ref={modalBlockUser}>
            <ModalContainer title={contacts.some(contact => contact.checked) ? 'Desbloquear Contatos' : 'Contatos bloqueados'} onClick={() => modalBlockUser.current?.close()}>
                <div className="scroll">
                    <HorizontalRow search justify="center">
                        <h4>Contatos</h4>
                        <Search id="search" itens={contacts} onSearch={(result) => { setContacts(result) }} blocked />
                        <div className="checkBlocked">
                            <Checkbox className='checkbox' name="checkAll" value="checkAll"
                                checked={allChecked}
                                onChange={(e) => handleChange(e, { setContacts, setAllChecked, contacts, setHidden })} />
                        </div>
                    </HorizontalRow>
                    {contacts ?
                        contacts.length ?
                            <Checked contacts={contacts}
                                setContacts={setContacts} setAllChecked={setAllChecked} setHidden={setHidden}
                                onChecked={
                                    (id: string, checked: boolean) => {
                                        const isIdInList = usersIdsToRemoveBlock.includes(id);
                                        if (checked && !isIdInList) {
                                            setUsersIdsToRemoveBlock([...usersIdsToRemoveBlock, id]);
                                        }
                                        else if (!checked && isIdInList) {
                                            setUsersIdsToRemoveBlock(usersIdsToRemoveBlock.filter((userId) => userId !== id));
                                        }
                                    }
                                }
                            />
                            :
                            <div className="notFound">
                                <NotFound text="Não há contatos para bloquear" />
                            </div>
                        :
                        <div className="load">
                            <Load width="20px" />
                        </div>
                    }
                    {contacts.some(contact => contact.checked) ?
                        <div className='button-unblock'>
                            <button onClick={contactsBlock}>Desbloquear</button>
                        </div>
                        :
                        <div className="textoBlock">
                            O contato bloqueado estará impossibilitado de realizar ligações ou enviar mensagens
                        </div>
                    }
                </div>
            </ModalContainer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toChangeAbare(newAbare) {
            dispatch(setAbare(newAbare));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blockeds);