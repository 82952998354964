import styled from "styled-components";
import { ContainerSearch } from "../Input/style";

interface StyledProps {
  height?: string;
  last?: boolean;
}

export const Container = styled.div`
  transition: max-height 0.3s ease-out;

  .title {
    font-size: 14px;
    text-align: left;
    cursor: default;
    margin: 0;
    padding: 0.5rem;
    background: ${(props) => props.theme.backgrounds.lightGrey};
    color: ${(props) => props.theme.texts.darkerGreyII};
  }

  .fig {
    padding-right: 0.4rem;
  }
`;

export const Title = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.8rem 1rem;
  font-size: 15px;
  font-weight: bold;
  color: ${(props) => props.theme.texts.purpleDark};

  position: relative;
  top: -1px;

  :hover {
    background-color: ${({ theme }) => theme.hover.buttonHover};
  }
  .row {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: ${(props) => props.theme.fonts.text_lg};
  }
  .up {
    transform: rotate(180deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  .down {
    transform: rotate(90deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }
  .icon {
    color: ${(props) => props.theme.texts.purpleDark} !important;
  }
`;

export const Children = styled.div<StyledProps>`
  overflow: hidden;
  /* 0.33s  */
  .upAni {
    animation: up 0s forwards;
    @keyframes up {
      to {
        height: 0px;
      }
    }
  }

  .downAni {
    animation: down 0s forwards;

    @keyframes down {
      to {
        height: auto;
      }
    }
  }
`;

// .ac{
//   transition:all 2s ease-in-out;
// }

// .upAni {
//   max-height: 0px;
//   animation: up 5s forwards;
//   @keyframes up {
//     from {
//       max-height: 100%;
//     }
//     to {
//       max-height: 0px;
//     }
//   }
// }

// .downAni {
//   max-height: max-content;
//   animation: down 5s forwards;

//   @keyframes down {
//     from {
//       max-height: 0px;
//     }
//     to {
//       max-height: 200px;
//     }
//   }
// }

export const RenameInput = styled(ContainerSearch)`
  display: grid;
  grid-template-columns: auto 30px 30px;
  padding: 5px 1rem;
  border: 0px;
  color: ${(props) => props.theme.texts.purpleDark};
  -webkit-text-fill-color: ${(props) => props.theme.texts.purpleDark};
  border-radius: 0;
  border-bottom: 1px solid ${(props) => props.theme.bordes.purpleLightIIShadow} !important;
  background: ${(props) => props.theme.backgrounds.purpleLightI};

  .sub {
    transition: 0.7s ease;
    &:hover {
      #icon {
        color: ${(props) => props.theme.highlights.default};
      }
    }
  }

  &:focus-within {
    border: 0px;
    #icon {
      color: ${(props) => props.theme.texts.darkGreyI};
    }
  }

  #icon {
    font-size: 26px;
    color: ${(props) => props.theme.texts.darkGreyI};
  }
`;

// @keyframes up {
//   from {
//     margin-top: 0px;
//   }
//   to {
//     margin-top: -100vh;
//   }
// }
// }

// .downAni{
// animation: down 0.33s forwards;

// @keyframes down {
//   from {
//     margin-top: -100vh;
//   }
//   to {
//     margin-top: 0px;
//   }
// }
