import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconArrowDown = ({
  color = "#666666",
  size = 14,
  ...rest
}: SVGComponent) => {
  return (
    <Icon color={color} size={size} {...rest}>
      <path
        d="M1.4315 4.63143C1.59559 4.4674 1.81811 4.37524 2.05013 4.37524C2.28214 4.37524 2.50466 4.4674 2.66875 4.63143L7 8.96268L11.3313 4.63143C11.4963 4.47204 11.7173 4.38385 11.9467 4.38584C12.1761 4.38784 12.3956 4.47986 12.5578 4.64209C12.7201 4.80432 12.8121 5.02378 12.8141 5.25321C12.8161 5.48263 12.7279 5.70366 12.5685 5.86868L7.61863 10.8186C7.45454 10.9826 7.23202 11.0747 7 11.0747C6.76798 11.0747 6.54546 10.9826 6.38138 10.8186L1.4315 5.86868C1.26746 5.7046 1.17531 5.48208 1.17531 5.25006C1.17531 5.01804 1.26746 4.79552 1.4315 4.63143Z"
        fill={color}
      />
    </Icon>
  );
};

export default IconArrowDown;
