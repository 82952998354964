import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { BiLockAlt } from '../../assets/icons';
import { InputPasswordLogin } from "../../components";
import Loader from "../../components/Load";
import { putForgotPassword } from "../../services/requests/user";
import { errorDefault, ToastModel } from "../../util/error";
import { Button, Card, Container } from './style';

const ChangePassword = () => {
    const [loading, setLoading] = useState<Boolean>(false);
    const { addToast } = useToasts();
    const history = useHistory();

    const onSubmit = (event) => {
        event.preventDefault();
        const { password, confirmPassword } = event.target.elements;
        const token = window.location.pathname.split('d/')[1];
        setLoading(true);

        if (password.value != confirmPassword.value) {
            addToast({
                title: 'Senhas diferentes',
                description: "Verifique seus dados e tente novamente!"
            },
                { appearance: 'warning' });
        } else {
            putForgotPassword(token, password.value).then((res) => {
                const status = res.status
                if (status) {
                    if (status == 200) {
                        addToast({
                            title: 'Redefinição de senha foi realizada com sucesso!',
                            description: "Acesse sua conta com a nova senha."
                        },
                            { appearance: 'success' });

                        history.push('/')
                    } else {
                        errorPassword(status)
                    }
                } else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            });
        }
        setLoading(false);
    }

    const errorPassword = (status) => {
        switch (status) {
            case 404:
                addToast({
                    title: 'Token inválido',
                    description: "Acesse seu email e verifique o último email enviado pelo Abaré.Net."
                },
                    { appearance: 'error' });
                break;
            case 401:
                addToast({
                    title: 'Token expirado, gere um novo token',
                    description: "Envie novamente um email para recuperar sua senha."
                },
                    { appearance: 'error' });
                break;
            case 409:
                addToast({
                    title: 'Erro ao atualizar a senha',
                    description: "Tente novamente mais tarde"
                },
                    { appearance: 'error' });
                break;
            default:
                errorDefault(status, addToast)
        }
    };
    return (
        <Container className="vertical-gradient">
            <Card>
                <h2 id="title">Redefinir senha</h2>
                <h5>Insira sua nova senha nos campos abaixos</h5>
                <form onSubmit={onSubmit}>
                    <InputPasswordLogin Icon={BiLockAlt} name="password" label="Senha"
                        type="password" required placeholder="Digite nova senha aqui" />
                    <InputPasswordLogin Icon={BiLockAlt} name="confirmPassword" label="Confirmar senha"
                        type="password" required placeholder="Confirme seu senha aqui" />
                    <div className="text-center">
                        <Button type="submit" className="horizontal-gradient-login" >
                            {loading ?
                                <Loader />
                                :
                                "Redefinir"
                            }
                        </Button>
                    </div>
                </form>
            </Card>
        </Container>
    )
}

export default ChangePassword;