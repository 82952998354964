import styled from "styled-components";
import { colapsedSchedule, tablet } from "../../../../components/NavBar/style";

interface StyledProps {
  rightContent?: any;
  hidden?: boolean;
}

export const MiddleContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.bordes.lightGrey};
  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${({ theme }) => theme.backgrounds.white};

  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  .container-list {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding-top: 1rem;
  }
  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media only screen and (max-width: ${tablet}) {
    display: ${({ hidden }) => (hidden ? `none` : `flex`)};
    border-radius: 14px;
  }

  .active {
    background-color: ${(props) => props.theme.hover.buttonActive} !important;
  }
  .container {
    overflow-y: auto;
    height: 100%;
  }
`;

export const Header = styled.div`
  padding: 0rem 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  position: sticky;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: ${({ theme }) => theme.heightToolbar};
  min-height: ${({ theme }) => theme.heightToolbar};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.backgrounds.secondaryToolbar};
  color: ${({ theme }) => theme.texts.secondaryToolbar};
  z-index: 51;

  .logo {
    width: auto;
    height: 14px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .top {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    width: 100%;
    button {
      padding: 0;
    }

    .iconClose {
      color: ${({ theme }) => theme.texts.secondaryToolbar};
    }
    .title {
      margin: 0px;
      color: ${({ theme }) => theme.texts.secondaryToolbar};
      font-family: "inter-bold", sans-serif;
      font-size: 1.5rem;
    }
  }

  #btn-option {
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
    #icon {
      font-size: 25px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  box-sizing: content-box;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  margin-top: 0rem;
  color: ${({ theme }) => theme.texts.black};
  border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};

  .resize{
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  .visible{
    max-height: 800px;
  }

  .invisible{
    max-height: 0px;
  }
`;

export const Title = styled.h1`
  font-family: "inter-bold", sans-serif;

  color: ${({ theme }) => theme.texts.black};
  font-size: 1.2rem;
  text-align: start;
  margin: 0px;
  padding: 0.8rem 1rem;
`;
export const SubTitle = styled.h2`
  font-family: "inter-semi-bold", sans-serif;
  color: ${({ theme }) => theme.texts.black};
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  text-align: start;
  margin: 0px;
  padding: 0.2rem 1rem;
  cursor: pointer;
  transition: background-color 0.4s ease;

  :hover {
    background-color: ${({theme})=> theme.hover.default};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 1rem;
  color: ${({ theme }) => theme.texts.darkerGreyI};
  align-items: center;
  /* background-color: ${({ theme }) => theme.begin.background.primary}; */

  .icon {
    font-size: 24px;
  }
  p {
    padding: 0px;
    margin: 10px 0;
    margin-left: 8px;
  }

  &:hover {
    background-color: ${(props) => props.theme.hover.buttonHover};
    cursor: pointer;
  }
`;
