import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconReportFill = ({
  color = "#700540",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 21.6V2.4C2 1.76348 2.26339 1.15303 2.73223 0.702944C3.20107 0.252856 3.83696 0 4.5 0H13.95C14.6032 7.80473e-05 15.2305 0.245589 15.6975 0.684L21.2475 5.8944C21.4857 6.11789 21.6749 6.38481 21.8041 6.67949C21.9334 6.97417 22 7.29067 22 7.6104V21.6C22 22.2365 21.7366 22.847 21.2678 23.2971C20.7989 23.7471 20.163 24 19.5 24H4.5C3.83696 24 3.20107 23.7471 2.73223 23.2971C2.26339 22.847 2 22.2365 2 21.6ZM6.7998 7.5999C6.35798 7.5999 5.9998 7.95807 5.9998 8.3999C5.9998 8.84173 6.35798 9.1999 6.7998 9.1999H15.7998C16.2416 9.1999 16.5998 8.84173 16.5998 8.3999C16.5998 7.95807 16.2416 7.5999 15.7998 7.5999H6.7998ZM6.7998 12.3999C6.35798 12.3999 5.9998 12.7581 5.9998 13.1999C5.9998 13.6417 6.35798 13.9999 6.7998 13.9999H13.9998C14.4416 13.9999 14.7998 13.6417 14.7998 13.1999C14.7998 12.7581 14.4416 12.3999 13.9998 12.3999H6.7998ZM6.7998 17.1999C6.35798 17.1999 5.9998 17.5581 5.9998 17.9999C5.9998 18.4417 6.35798 18.7999 6.7998 18.7999H10.3998C10.8416 18.7999 11.1998 18.4417 11.1998 17.9999C11.1998 17.5581 10.8416 17.1999 10.3998 17.1999H6.7998Z"
        fill="inherit"
      />
    </Icon>
  );
};

export default IconReportFill;
