import styled from "styled-components";

export const Container = styled.div`
  .container {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: ${(props) => props.theme.backgrounds.purpleLightI};
  }

  .movieRow--item {
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.backgrounds.white};
    padding: 0px 24px 8px 24px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    #icon {
      font-size: 20px;
      color: ${(props) => props.theme.texts.darkGreyI};
    }

    #icon-purple {
      font-size: 20px;
      color: ${(props) => props.theme.texts.purpleDarkTitle};
    }

    .textoBlock {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      font-size: ${(props) => props.theme.fonts.text_xs};
      color: ${(props) => props.theme.texts.darkGreyI};
      padding: 0rem 3rem 0rem 3rem;
    }

    .titleSection {
      margin: 0px;
      font-size: 24px;
      font-family: "inter-semi-bold", sans-serif;
      color: ${(props) => props.theme.texts.darkGreyI};
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #row {
      display: flex;
      margin-top: 2px;
    }

    .link {
      cursor: pointer;
      text-align: center;
      font-family: "inter", sans-serif;
      font-size: 12px;
      margin-top: 8px;
      color: ${(props) => props.theme.texts.purpleDark};
    }

    .image_birthday {
      height: 55px;
      width: 55px;
      padding: 4px 0px;
      border-radius: 50%;
    }

    .textConfirmPassWord {
      text-align: center;
      font-family: "inter-medium", sans-serif;
      font-size: 18px;
      margin-top: 8px;
      margin-bottom: 24px;
      color: ${(props) => props.theme.texts.purpleDark};
    }

    .texto {
      text-align: center;
      font-size: 14px;
      color: ${(props) => props.theme.texts.darkGreyI};
      padding: 0rem 3rem 0rem 3rem;
    }

    .horizontalButtons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .msg {
      font-size: 10px;
      color: ${(props) => props.theme.texts.darkGreyI};
      text-align: right;
      display: block;
    }

    .button-unblock {
      display: flex;
      align-items: flex-end;
      justify-content: end;
      margin-right: 8px;

      button {
        background-color: ${(props) => props.theme.texts.purpleDarkTitle};
        font-size: 16px;
        color: ${(props) => props.theme.texts.white};
        text-align: center;
        padding: 5px 15px 5px 15px;
        margin: 10px 0px -10px 0px;
      }
    }

    .img-container {
      background-color: rgba(37, 129, 196, 0.05);
      margin: auto;
      border-radius: 10px;
      height: 247px;
      width: 383px;

      h3 {
        color: ${(props) => props.theme.texts.darkGreyI};
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .add-img-icon {
        color: #a6446a;
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        margin: auto;
        cursor: pointer;
      }
    }
  }

  .notFound {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .scroll {
    display: block;
    max-height: 430px;
    overflow-y: auto;
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.texts.purpleDark};
  margin: 0;
  padding: 1.11rem 1rem;
  font-size: ${(props) => props.theme.fonts.text_base};
  cursor: pointer;

  h3 {
    margin: 0px;
  }

  #icon {
    font-size: 16px;
    padding-right: 8px;
    cursor: pointer;
  }
`;
