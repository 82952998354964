import { useRef, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { ButtonEvent, NewFileStyle } from "./styles";

import { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Dropdown, Modal } from "../../../../../components";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import { ModalRef } from "../modal/ModalListOptions";
import ModalNewCluster from "../modal/modalNewCluster";
import { StorageMediaService } from "../../../../../services/requests/storage-media";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { BsEye } from "react-icons/bs";
import { MdAddBox, MdCreateNewFolder } from "react-icons/md";
import { FloatMenu } from "../../../../../components/Menu";

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const NewFile = () => {
  const fileRef = useRef<any>();
  const folderRef = useRef<ModalRef>(null);
  const folder = useSelector(
    (state: TypeConstRedux) => state.repository.folder
  );

  const folderID = folder.length > 0 ? folder[folder.length - 1].id : "";
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation(
    (dataForm: FormData) => StorageMediaService.post(dataForm),
    {
      onSuccess: (data: AxiosResponse) => {
        console.log(data);
        if (data.status == 201) {
          queryClient.invalidateQueries([Queries.REPOSITORY_FILES, folderID]);
        } else if (data.status == 403) {
          ToastModel(
            addToast,
            "Error ao salvar!",
            "Já existe um arquivo com o mesmo nome salvo na pasta.",
            "warning"
          );
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.files[0]) {
      handleUpload(e.target.files[0]);
    }
  };

  const handleUpload = (file) => {
    const dataForm = new FormData();
    dataForm.append("file", file);
    dataForm.append("name", file.name);
    if (folder.length > 0) {
      const id = folder[folder.length - 1].id;
      dataForm.append("storageClusterId", id.toString());
    }
    if (!isLoading) {
      mutate(dataForm);
    }
  };

  return (
    <NewFileStyle>
      <input
        ref={fileRef}
        style={{ display: "none" }}
        onChange={(e) => handleChange(e)}
        type="file"
        accept="*"
      />

      <FloatMenu>
        <ButtonEvent>
          <p>Novo</p>
          <IoMdAdd id="icon" size={32} />

        </ButtonEvent>

        <div>
          <MenuItem
            sx={{
              mx: 0.5,
              borderRadius: "6px",
              mb: 0.5,
              pr: 1,
            }}
            onClick={() => fileRef.current?.click()}
            selected={false}
          >
            <ListItemIcon>
              <MdAddBox />
            </ListItemIcon>
            <ListItemText>Arquivo</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{
              mx: 0.5,
              borderRadius: "6px",
              mb: 0.5,
              pr: 1,
            }}
            onClick={() => folderRef.current?.open()}
            selected={false}
          >
            <ListItemIcon>
              <MdCreateNewFolder />
            </ListItemIcon>
            <ListItemText>Pasta</ListItemText>
          </MenuItem>
        </div>
      </FloatMenu>
      <Modal mobile={true} ref={folderRef}>
        <ModalNewCluster modal={folderRef} />
      </Modal>
    </NewFileStyle>
  );
};

export default NewFile;
