import { api } from "../api";
import { getToken } from "../auth";

export const getNotifications = () => {
  return api({
    url: `/notifications/my-notifications`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};
export const readNotification = (notificationId) => {
  return api({
    url: `/notifications/read/${notificationId}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};
export const deleteNotification = (notificationId) => {
  return api({
    url: `/notifications/${notificationId}`,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};
