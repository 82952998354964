import { createRef, memo, useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Load } from "../../../../../components";
import MessageContent from "../../../../../components/MessageContent";
import { SET_ANSWER } from "../../../../../store/actions/actionTypes";
import { fetchMessage, getChatByPage } from "../../../../../store/actions/messages/getMessageAction";
import { dropMessage, readMessages, scrollController, setChat, setMessage } from "../../../../../store/actions/messages/messageAction";
import { TypeConstRedux } from "../../../../../types/redux";
import { getClientId, getDateMessages, personalTemp } from "../../../../../util/verification";
import { ContainerDate, Grid, Loading, Scroll } from './style';

const Body = ({ managerChat, ...props }) => {
    const { abare, clientId, getChatByPage, readMessages, scrollController, contacts, personal, selectMessages = false, setTest } = props;
    const dispatch = useDispatch()


    const scrollRef = useRef<any>(null);
    const observer = useRef<any>();
    const { messages, loadPage, scroll } = managerChat[clientId];

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight - scrollRef.current.clientHeight
            scrollController({ key: -1 });
        }
    };

    useEffect(() => {
        dispatch({ type: SET_ANSWER, payload: null })
    }, [personal])

    useEffect(() => {
        readMessages()
        scrollToBottom();
    }, [clientId])

    const scrollTo = (param) => {
        const { key, options, css = false } = param
        const objOption = options ? options : { block: 'start' }

        refs[key].current?.scrollIntoView(objOption)

        if (css) {
            if (refs[key].current?.childElementCount <= 1) {
                const div = refs[key].current?.firstChild.firstChild
                div.classList.add("animation")
                div.addEventListener("animationiteration", () => {
                    div.classList.remove("animation")
                }, false);
            } else {
                const div = refs[key].current?.lastChild.lastChild
                div.classList.add("animation")
                div.addEventListener("animationiteration", () => {
                    div.classList.remove("animation")
                }, false);

            }
        }
        scrollController({ key: -1 });
    }

    const refs = messages.reduce((acc, message) => {
        acc[message.msg_id] = createRef();
        return acc;
    }, {});



    useEffect(() => {
        if (scroll?.key !== -1) {
            scroll?.key ? scrollTo(scroll) : scrollToBottom()
        }
    }, [scroll])


    const lastElementRef = useCallback(node => {
        observer.current && observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                getChatByPage()
            }
        })
        node && observer.current.observe(node)
    }, [])

    const showDate = (index) => {
        return index == 0 || getDateMessages(messages[index - 1].createdAt) != getDateMessages(messages[index].createdAt)
    }

    const DateFormat = ({ date, i }) => (
        showDate(i) ?
            <ContainerDate>
                <div className="message">
                    <p>{getDateMessages(date)}</p>
                </div>
            </ContainerDate>
            :
            null
    )

    const [selectedMessages, setSelectedMessages] = useState<any>(messages.reduce((acc, message) => {
        acc[message.msg_id] = false;
        return acc;
    }, {}));

    useEffect(() => {
        setTest(selectedMessages)
    }, [selectedMessages])

    const handleSelectedMessages = (id) => {
        if (selectMessages) {
            let copy = Object.assign({}, selectedMessages)
            copy[id] = !copy[id];
            setSelectedMessages(copy);
        }

    }

    return (
        <Scroll ref={scrollRef}>

            {loadPage && <Loading>
                <div id="load">
                    <Load width="35px" />
                </div>
            </Loading>
            }
            {messages.length ?
                messages?.map((message: any, index: number) => {
                    const { sendBy, createdAt } = message
                    const sequence = index ? !(messages[index - 1].sendBy == sendBy) : true

                    return (
                        <div key={index} className="main2" ref={refs[message.msg_id]} >
                            {!loadPage && index == 0 &&
                                <div>
                                    <div ref={(el) => lastElementRef(el)} />
                                </div>
                            }
                            <DateFormat date={createdAt} i={index} />

                            <Grid onClick={() => handleSelectedMessages(message.msg_id)} selectMessage={selectMessages} selected={selectedMessages[message.msg_id]}>
                                <input onClick={() => handleSelectedMessages(message.msg_id)} checked={selectedMessages[message.msg_id]} className="hidden" type="checkbox" key={message.createdAt} />
                                <MessageContent scrollTo={scrollTo} bottomDrop={index == 0 || index == 1} personal={personal} messages={messages} message={message}  {...props} sequence={sequence} contacts={contacts} />

                            </Grid>


                        </div>)
                }
                )
                :
                <div ref={(el) => lastElementRef(el)} />
            }

        </Scroll >
    );
}
const mapStateToProps = (state: TypeConstRedux) => ({
    abare: state.abare.abare,
    personal: state.chat.personal,
    managerChat: state.chat.globalMessages,
    clientId: getClientId(state.chat.personal) || personalTemp(state.chat.personal, state.abare.abare),
    contacts: state.contacts.list
})
const mapDispatchToProps = dispatch => bindActionCreators({
    getChatByPage,
    readMessages,
    scrollController,
    setChat,
    dropMessage,
    setMessage,
    fetchMessage,
}, dispatch)

export default memo(connect(mapStateToProps, mapDispatchToProps)(Body));