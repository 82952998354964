import { Dispatch, SetStateAction, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { TypeConstRedux } from "../../../../types/redux";
import { Header } from "../../Report/ListReports/style";

import SidebarRepository from "../components/Sidebar";
import NewFile from "../components/Sidebar/NewFile";
import { MiddleContainer } from "./style";

interface ListOptions {
    overflow?: boolean;
    hidden?: boolean;
    modal?: boolean;
    setOpen?: Dispatch<SetStateAction<boolean>>;
}
const ListOptions = ({ overflow = true, hidden = true, modal = false, setOpen }: ListOptions) => {
   
    return (

        <MiddleContainer className={`${hidden ? `hidden` : ` `} ${modal ? `border` : ``}`} $overflow={false} onClick={(e) => e.stopPropagation()}>
            <Header>
                <div className="top">
                    <h3 className="title">Repositório</h3>
                    {(setOpen && modal) && <IoIosClose onClick={() => setOpen(false)} className="iconClose" />}
                </div>
            </Header>
            <div className="scroll-div">
                <SidebarRepository />
            </div>

        </MiddleContainer>

    );
}

export default ListOptions;