import { TProfileGroup } from "../../../types/project";
import { arrayOfUsers, getClientId } from "../../../util/verification";
import { SET_GLOBAL_MESSAGES, SET_NEW_GLOBAL_MESSAGES, SET_PERSONAL, UPDATE_PERSONAL } from "../actionTypes";
import { setListFolder } from "../folderAction";
import { getAllState, setChat } from "./messageAction";

export const setPersonal = (client) => {
    return {
        type: SET_PERSONAL, payload: client
    }
};

export const updateGroupForAllUsers = (group: TProfileGroup) => {
    return (dispatch, getState) => {
        const { abare, socket } = getAllState(getState())
        const ids = arrayOfUsers(group.participants, abare?.id)
        socket.emit("updateGroup", { groupInfo: group, ids })
        dispatch(updateClient({ ...group }))

    }
}


export const updateClient = (client) => {
    return (dispatch, getState) => {
        const { globalMessages, personal } = getAllState(getState())
        const clientId = getClientId(client)
        const conversation = globalMessages[clientId]

        if (conversation) {
            const clientUpdate = { ...conversation.client, ...client }
            dispatch(setChat(clientId, { client: { ...clientUpdate } }))
            clientId == getClientId(personal) && dispatch({ type: UPDATE_PERSONAL, payload: clientUpdate })
        }
    }
};

export const setGlobalMessages = (message) => {
    return {
        type: SET_GLOBAL_MESSAGES,
        payload: message,
    };
};

export const clearConversation = (clientId) => {
    return (dispatch, getState) => {
        const { personal } = getAllState(getState());

        dispatch(setChat(clientId, { messages: [] }))
        personal.lastMessage = null;
        dispatch(updateClient({ ...personal }))

    };
};

export const dropConversation = (client) => {
    return (dispatch, getState) => {
        const { globalMessages, personal, folders } = getAllState(getState())
        const clientId = getClientId(client);

        folders.map((folder) => {
            folder.itens = folder.itens.filter(item => getClientId(item) !== clientId)
            return folder
        });
        dispatch(setListFolder([...folders]));

        clientId == getClientId(personal) && dispatch(setPersonal(null));

        delete globalMessages[clientId]
        dispatch({ type: SET_NEW_GLOBAL_MESSAGES, payload: globalMessages })

    };
};
