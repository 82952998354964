
import { Load } from "../..";
import { BsCheckLg, IoIosArrowBack } from "../../../assets/icons";
import FloatingButton from "../../Button/FloatingButton/FloatingButton";
import { Container, Content, Title } from "./style";


interface ModalProps {
    title: string;
    onClick: React.MouseEventHandler<HTMLOrSVGElement> | undefined;
    children: React.ReactNode;
    padding?: boolean;
    buttonBack?: boolean;
    buttonConfirmation?: boolean;
    onButtonRight?: () => void;
    height?: string;
    $minHeight?: boolean;
    loadRequisition?: boolean;
    ButtonExtra?: any;
}
const ModalContainer = ({ height, onClick, title, children, buttonBack = false, padding = true, buttonConfirmation = false, onButtonRight, $minHeight = true, loadRequisition = false, ButtonExtra }: ModalProps) => {

    return (
        <Container $height={height} $minHeight={$minHeight}>
            <Title>
                <div className="right">
                    <IoIosArrowBack data-tip="oiiii" data-for='dark' onClick={onClick} id="icon" />
                    <b><h3>{title}</h3></b>
                </div>
                <div className="container_buttons">
                    {ButtonExtra != null && ButtonExtra}

                    {buttonConfirmation && (loadRequisition ? <Load dark={true} /> : <BsCheckLg className="icon" onClick={onButtonRight} size={18} />)}
                </div>
            </Title>
            <Content padding={padding}>
                <div className="scrolling" style={{ width: "100%", overflow: "auto" }}>
                    {children}
                </div>
            </Content>
            {buttonBack && <FloatingButton onclick={onClick} position="bottom-left" />}
        </Container>
    );
}

export default ModalContainer;