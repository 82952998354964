import { FiAlertCircle } from "react-icons/fi";
import { Modal } from "../../../../../components";
import { ButtonConfirmPassWord, ButtonPassWord } from '../../../Settings/User/style';
import { HorizontalRow } from '../../../Settings/style';
import { Exit } from './styles';

const ModalExit = ({ modalRef, action, title }) => {
    return (
        <Modal title={title} subTitle=" " ref={modalRef}>
            <Exit >
                <FiAlertCircle id="icon" size={30} />
                <h4 id="message">Você realmente deseja remover?</h4>
                <HorizontalRow justify='center' lastLine>
                    <ButtonPassWord onClick={() => action()}>
                        Sim, confirmar
                    </ButtonPassWord>
                    <ButtonConfirmPassWord onClick={() => modalRef.current.close()}>
                        Não, cancelar
                    </ButtonConfirmPassWord>
                </HorizontalRow>
            </Exit>
        </Modal>
    )
}

export default ModalExit;