import { ic_user_setting_white } from '../../assets/images';
import { Checkbox } from '../../components';
import { TypePersonal } from '../../types/project';
import { photoUrl } from '../../util/verification';
import Img from '../Img';
import { Default, ListConversation } from './style';



const Checked = (props) => {
    const { personals, contacts, users, leaders, onChecked = (id, checked) => { return }, onAllChecked = (id, checked) => { return } } = props;


    const Choise = (values) => {
        const { id, type, adm, user } = values;
        return (
            !user.hidden ?
                <ListConversation htmlFor={id}>
                    <Checkbox name={type} type="checkbox" id={id} value={id}
                        checked={!!user.checked}
                        onChange={
                            (e) => {
                                handleChange(e, props);
                                onChecked(id, !user.checked);
                            }
                        } />

                    <div className="relative">
                        <div>
                            <Img className="profile" src={photoUrl(user)} />
                        </div>
                        {adm && <Default className="vertical-gradient">
                            <Img className="icon-circle" src={ic_user_setting_white} width={15} />
                        </Default>}
                    </div>
                    <div id="col-message">
                        <h4 id="name">{user.name ? user.name : "@" + user.nickname}</h4>
                    </div>
                </ListConversation>
                :
                <></>
        )
    }

    const PersonalCheck = () => (
        personals.map((personal, key) => {
            let id;
            switch (personal.type) {
                case TypePersonal.GROUP:
                    id = personal.Group_id
                    break;
                default:
                    id = personal.Personal_id
                    break;
            }

            return (
                <Choise key={key} id={id} user={personal} type={"personal"} />
            )
        })
    )

    const ContactCheck = () => (
        contacts.map((contact, key) => {
            return (
                <Choise key={key} id={contact.User_id_contact || contact.id} user={contact} type={"contact"} />
            )
        })
    )
    const UsersCheck = () => (
        users.map((user, key) => {
            return (
                <Choise key={key} id={user.id} user={user} type={"user"} adm={user.admin ? true : false} />
            )
        })
    )

    const ChoiseLeaders = (values) => {
        const { id, type, isleader, member } = values;
        const user = member;
        return (
            !user.hidden ?
                <ListConversation htmlFor={id}>
                    <Checkbox name={type} type="checkbox" id={id} value={id}
                        checked={!!user.checked}
                        onChange={
                            (e) => {
                                handleChange(e, props);
                                onChecked(id, !user.checked);
                            }
                        } />

                    <div className="relative">
                        <div>
                            <Img className="profile" src={photoUrl(user)} />
                        </div>
                        {isleader && <Default className="vertical-gradient">
                            <Img className="icon-circle" src={ic_user_setting_white} width={15} />
                        </Default>}
                    </div>
                    <div id="col-message">
                        <h4 id="name">{user.name ? user.name : "@" + user.nickname}</h4>
                        <div id="message">
                            {user.groupRole.name}
                        </div>
                    </div>
                </ListConversation>
                :
                <></>
        )
    }

    const LeadersCheck = () => (
        leaders.map((member, key) => {
            const id = member.id + member.groupRole.id;
            return (
                <ChoiseLeaders key={key} id={id} member={member} type={"leaders"} isleader={member.userGroupRole.MapGroupLeader} />
            )
        })
    )
    return (
        <>
            {personals && <PersonalCheck />}
            {contacts && <ContactCheck />}
            {users && <UsersCheck />}
            {leaders && <LeadersCheck />}
        </>
    );
}

const handleChange = (e, props) => {
    const { personals, setPersonals, setAllChecked, setHidden, contacts, setContacts, users, setUsers, leaders } = props;

    let itemName = e.target.name;
    let itemValue = e.target.value;
    let checked = e.target.checked;
    let aux: Array<any> = [];

    const verifyAllChecked = () => {
        setAllChecked && setAllChecked(aux.every(item => item.checked))
        setHidden && setHidden(aux.some(item => item.checked))
    }

    switch (itemName) {
        case "checkAll":
            const validator = personals ? personals.length : contacts.some(x => !x.hidden)
            if (validator) {
                setAllChecked(checked);
                aux = personals ? personals.map(item => !item.hidden ? { ...item, checked } : item) : contacts.map(item => !item.hidden ? { ...item, checked } : item)

                personals ? setPersonals(aux) : setContacts(aux)
                setHidden && setHidden(checked)
            }
            break;
        case "contact":
            aux = contacts.map(item =>
                (item.User_id_contact || item.id) == itemValue ? { ...item, checked } : item
            );
            setContacts(aux)
            verifyAllChecked()
            break
        case "user":
            aux = users.map(item =>
                item.id == itemValue ? { ...item, checked } : item
            );
            setUsers(users => (users.map(item => item.id == itemValue ? { ...item, checked } : item)))
            verifyAllChecked()
            break
        case "leaders":
            aux = leaders.map(item =>
                item.id + item.groupRole.id == itemValue ? { ...item, checked } : item

            );
            setUsers(aux)
            verifyAllChecked()
            break
        default:
            aux = personals.map(item =>
                (item.Personal_id == itemValue || item.Group_id == itemValue) ? { ...item, checked } : item
            );
            setPersonals(personals => personals.map(item =>
                (item.Personal_id == itemValue || item.Group_id == itemValue) ? { ...item, checked } : item
            ))
            verifyAllChecked()
            break;
    }
};

export { Checked, handleChange };
