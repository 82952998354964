import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ChangePassword, Home, LoginEmail, LoginPhone, RegisterEmail, UserData } from './pages';

import CallPage from './pages/Call';
import { PlansPage } from './pages/Signature';

import { bindActionCreators } from 'redux';
import CallNotification from './pages/Call/call-notification.component';
import Protocol from './pages/home/Dashboard/Protocol';
import Settings from './pages/home/Settings';
import { isAuthenticated } from './services/auth';
import { ContextProvider } from './services/socket';
import { getAbare } from './store/actions/abareAction';

const Private: FC<any> = ({ component: Component, abare, getAbare, ...rest }) => {

  useEffect(() => {
    getAbare()
  }, [])

  return (
    <Route {...rest}
      render={(props) => (
        isAuthenticated() ? (
          abare?.id ? <Component {...props} /> : null
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ))}
    />
  )
};

const mapStateToProps = (state) => ({
  abare: state.abare.abare,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAbare,
}, dispatch)

const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(Private);

const Router: FC<any> = ({ component: Component, ...rest }) => (
  <Route {...rest}
    render={props => (
      isAuthenticated() ? (
        <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      ))
    }
  />);

function NotFound() {
  return <div>Not found</div>;
}

function NotFoundRedirect() {
  return <Redirect to="/not-found" />;
}

const Routes = () => (
  <BrowserRouter>
    <ContextProvider>

      <CallNotification />
      <Switch>
      <Route exact path="/blocked/:tokenBlock" component={Protocol}/>
        <Router exact path="/">
          <Redirect to="/login" />
        </Router>
        <Router path="/login" component={LoginEmail} />
        {/* <Router path="/phone" component={LoginPhone} /> */}
        <Router path="/signin/email" component={RegisterEmail} />
        {/* <Router path="/signin/phone" component={RegisterPhone} /> */}
        <PrivateRoute path="/signin/data" component={UserData} />
        <Router path="/changePassword/:token" component={ChangePassword} />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/settings" component={Settings} />
        <Route exact path="/plans" component={PlansPage} />
        <Route exact path="/call" render={props => props.history.action == "POP" ?
          <Redirect to={{ pathname: '/', state: { from: props.location } }} /> :
          <CallPage {...props} />
        } />
        <Route exact path="/phone" component={LoginPhone} />
        <Route path='/not-found' component={NotFound} />
        <Route component={NotFoundRedirect} />
      </Switch>
    </ContextProvider>
  </BrowserRouter>
);

export default Routes;
