import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { Container } from './style'

const names = {
    smileys_people: 'PESSOAS',
    animals_nature: 'ANIMAIS',
    food_drink: 'COMIDAS E BEBIDAS',
    travel_places: 'VIAGENS E LUGARES',
    activities: 'ATIVIDADES',
    objects: 'OBJETOS',
    symbols: 'SÍMBOLOS',
    flags: 'BANDEIRAS',
    recently_used: 'RECENTES',
}

const Emoji = ({ setText, text, children }) => {
    const onEmojiClick = (event, emojiObject) => {
        setText(text + emojiObject.emoji);
    };
    return (
        <Container>
            <div className="display">
                <div className="mb">
                    <Picker
                        pickerStyle={{ width: '100%', height: '100%' }}
                        onEmojiClick={onEmojiClick}
                        disableAutoFocus={true}
                        groupNames={names}
                        disableSkinTonePicker
                        disableSearchBar
                        native
                    />
                </div>
            </div>
            {children}
        </Container >
    );
}

export default Emoji