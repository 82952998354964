import { Dispatch, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StorageClusterService } from "../../../../../services/requests/storage-cluster";
import { FolderRepository, TypeMessage } from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";

interface UseFoldersRepository {
  folders: FolderRepository[];
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  error?: string;
  isError: boolean;
  setSelectedId: Dispatch<React.SetStateAction<string>>;
}

export const UseFoldersRepository = (): UseFoldersRepository => {
  const [selectedId, setSelectedId] = useState<string>("");
  const [isVisible, setIsVisible] = useState(true);
  const repositoryInformation = useSelector(
    (store: TypeConstRedux) => store.repository
  );
  const { folder, filters } = repositoryInformation;
  const { types } = filters;

  useEffect(() => {
    const lengthCluster = folder.length;
    if (lengthCluster == 0) {
      setSelectedId("");
    } else {
      const clusterSelected = folder[lengthCluster - 1];
      setSelectedId(clusterSelected.id);
    }
  }, [folder]);

  const filterCluster = useCallback(
    (folders: FolderRepository[]) => {
      if (
        types.length < 1 ||
        types.some((filter) => filter == TypeMessage.FOLDER)
      )
        return folders;
      return [];
    },
    [types]
  );

  const selectFunction = () => {
    if (selectedId == "") return StorageClusterService.getAllCluster();
    return StorageClusterService.getAllSubCluster(selectedId.toString());
  };
  const {
    data: folders = [],
    isLoading,
    isFetching,
    error,
    isSuccess,
    isError,
  } = useQuery([Queries.REPOSITORY_FOLDERS, selectedId], selectFunction, {
    refetchOnWindowFocus: false,
    retry: 2,
    select: filterCluster,
    refetchInterval: false,
  });

  return {
    folders,
    isLoading,
    isFetching,
    setSelectedId,
    isSuccess,
    isError,
  };
};
