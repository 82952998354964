import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { AiOutlineSend, IoIosArrowBack } from '../../../../../assets/icons';
import { Load, NotFound, Search } from "../../../../../components";
import { ButtonArrow } from "../../../../../components/Button/style";
import { Checked } from "../../../../../components/Checked";
import { forwardMessageAction } from "../../../../../store/actions/messages/messageAction";
import { TMessage } from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { getClientId, isEqualsArray } from "../../../../../util/verification";
import { Container, Header, Main, Menu, Scroll } from '../../Group/Add/style';

const Forward = (props) => {
    const { modalAdd, message, forwardMessageAction, messages = null, setSelectMessages, resetMessages } = props;
    const typeMessage: TMessage = message;
    const onlyMessage = (!messages || (messages && messages?.length === 1))
    const [contacts, setContacts] = useState<any>
        (useSelector((state: TypeConstRedux) => state.folder.list[0].itens.filter((item) => (getClientId(item) != getClientId(state.chat.personal)))));
    const [hidden, setHidden] = useState(contacts?.some(x => x.checked));

    const forwardMessage = async () => {
        if (messages) {
            messages.map(async (msg) => {
                const auxMsg: TMessage = msg;
                auxMsg.msg_id_root = null;
                auxMsg.Group_id = null;
                auxMsg.Personal_id = null;
                auxMsg.blobUrl = null;
                await Promise.all(contacts.map(async (key) => {
                    if (key.checked) {
                        forwardMessageAction(auxMsg, key);
                    }
                }));
            })
            setSelectMessages(false);
            resetMessages();
            modalAdd.current.close();
        } else {
            typeMessage.msg_id_root = null;
            typeMessage.Group_id = null;
            typeMessage.Personal_id = null;
            typeMessage.blobUrl = null;

            await Promise.all(contacts.map(async (key) => {
                if (key.checked) {
                    forwardMessageAction(typeMessage, key);
                }
            }));
            modalAdd.current.close();
        }
    };

    return (
        <Main>
            <Container>
                <Header>
                    <IoIosArrowBack id="back" onClick={() => modalAdd.current.close()} />
                    <h5>Encaminhar {onlyMessage ? `Mensagem` : `Mensagens`}</h5>
                </Header>
                <Menu>
                    <Search placeholder="Pesquisar Contatos" itens={contacts || []} onSearch={(result) => {
                        !isEqualsArray(contacts || [], result) && setContacts(result)
                    }} />
                    <ButtonArrow onClick={() => { forwardMessage() }}>
                        <AiOutlineSend id={!hidden ? "icon" : "icon-purple"} />
                    </ButtonArrow>
                </Menu>
                <>
                    {contacts ?
                        <Scroll>
                            {contacts.length ?
                                <Checked personals={contacts} setPersonals={setContacts} setHidden={setHidden} />
                                :
                                <NotFound text={"Não há contatos"} description="Adicione um contato para encaminhar mensagens" />
                            }
                        </Scroll>
                        :
                        <div className="load">
                            <Load width="25px" />
                            <h4>Carregando...</h4>
                        </div>
                    }
                </>
            </Container>
        </Main>)
};

const mapDispatchToProps = dispatch => bindActionCreators({
    forwardMessageAction
}, dispatch);

export default connect(null, mapDispatchToProps)(Forward);
