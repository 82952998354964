import { Container, Load } from "./style";

const Loader = (props) => {
    const { width, size, margin, message, dark, ...rest } = props;

    return (
        !message ?
            <Load dark={dark} width={width} margin={margin} size={size} {...rest} />
            :
            <Container dark={dark}>
                <div className="loadText">
                    <Load dark={dark} margin={margin} width={width} size={size} />
                    <h4>{message}</h4>
                </div>
            </Container>
    );
}

export default Loader;