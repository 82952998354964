import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  border-top: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  color: ${({ theme }) => theme.texts.darkerGreyI};
  padding: 0rem 1rem;
  height: 60px;
  width: 100%;

  p {
    margin: 0px;
    padding: 0px;
  }

  .margin {
    margin-right: 16px;
  }

  .container_close {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .icon {
    color: ${({ theme }) => theme.texts.darkerGreyII};
    z-index: 3;
    :hover {
      cursor: pointer;
      color: ${({ theme }) => theme.hover.purpleDark};
    }
  }
`;
