import styled from "styled-components";

interface ContainerProps {
  $padding: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: ${({ $padding }) => ($padding ? "1rem 2rem" : "0rem")};
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 20rem;
  .message {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme }) => theme.texts.contentCards};
  }

  button {
    border-radius: 16px;
    display: flex;

    color: ${({ theme }) => theme.texts.white};
    background-color: ${({ theme }) => theme.button.hoverEvent};
    border: 3px solid ${({ theme }) => theme.button.hoverEvent};

    padding: 0rem;
    justify-content: center;
    align-items: center;

    font-family: "inter-semi-bold", sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem; /* 120% */
    transition: background-color 0.1s ease-out;
    transition: color 0.1s ease-out;

    :hover {
      border: 3px solid ${({ theme }) => theme.button.textEvent};
      color: ${({ theme }) => theme.button.textEvent};
      background-color: ${({ theme }) => theme.button.backgroundEvent};
    }
  }
`;
