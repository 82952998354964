import styled from "styled-components";
import {
  ContainerSearch
} from "../../../../../components/Input/style";
import {
  Conversation
} from "../ItemFolder/style";

export const ListConversation = styled(Conversation)
`
  padding: 0.5rem;
  padding-inline: 1rem;
`;

export const Container = styled.div `
  display: grid;
  grid-template-rows: 50px 50px 250px;
  overflow: hidden;
  .load{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.texts.purpleDark};
    h4{
      margin: 8px 0px;
    }
  }
`;

export const Scroll = styled.div `
    overflow-y: auto;
`;

export const Header = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.4rem;
  padding-bottom: 0px;

  
  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  
  .none{
    display: none;
  }


  .icon-folder{
    font-size: 20px;
    color: ${(props) => props.theme.highlights.default};
  }
`;

export const Title = styled.h5 `
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0px 0.5rem ;

  justify-self: start;
  margin: 0px;
  color: ${(props) => props.theme.texts.purpleDark};
  font-size: 18px;
`;

export const Menu = styled.div `
  display: grid;
  grid-template-columns: min-content 1fr 50px;
  align-items: center;
justify-content: center;
  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${({ theme }) => theme.texts.purpleDarkTitle};
    font-size: 15px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }

`;

export const Input = styled(ContainerSearch)
`
  width: 85%;
  align-self: stretch;
  #icon {
    font-size: 13px;
  }
  input {
    height: 35px;
  }
`;

export const Button = styled.button `
  display:flex;
  align-self: center;
  background: transparent;

  .default{
    color: ${(props) => props.theme.default.darkI};
  }
  .purple {
    color: ${(props) => props.theme.highlights.default};
  }
`;

export const RenameInput = styled.input `
  border: none;
  outline: none !important;
  text-decoration: none;
  color: ${(props) => props.theme.texts.purpleDark};
  background: transparent;
  height: 40px;
  width: 100%;
  font-size: 16px;
  font-family: "inter", sans-serif;
`;

export const CheckBox = styled.input ``;