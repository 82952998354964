import { AxiosResponse } from "axios";
import { RefObject, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { InputColor, InputControlled } from "../../../../../components/Input";
import { ModalRef } from "../../../../../components/Modal";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { StorageClusterService } from "../../../../../services/requests/storage-cluster";
import { FolderRepository } from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import { ToastModel } from "../../../../../util/error";
import { Container } from "./styles";
import { UseFolderUpdateName } from "../../hooks/Folders/useFolderUpdateName";
import { UseFolderUpdateColor } from "../../hooks/Folders/useFolderUpdateColor";

interface ColumnsProps {
  modal: RefObject<ModalRef>;
  folder: FolderRepository;
}

const ModalUpdateCluster = ({ modal, folder }: ColumnsProps) => {
  const [nameCluster, setNameCluster] = useState(folder.name);
  const [corCluster, setCorCluster] = useState(folder.color);

  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const inputRef = useRef<HTMLInputElement>(null);

  const mutateName = UseFolderUpdateName();
  const mutateColor = UseFolderUpdateColor();

  useEffect(() => {
    if (modal.current?.open) {
      inputRef.current?.focus();
    }
  }, [modal]);

  useEffect(() => {
    if (mutateColor.isSuccess || mutateName.isSuccess) {
      modal.current?.close();
    }
  }, [mutateName.isSuccess, mutateColor.isSuccess]);

  function saveChange() {
    if (nameCluster == folder.name && corCluster == folder.color) {
      ToastModel(
        addToast,
        "Faça alguma alteração para alterar pasta!",
        "",
        "warning"
      );
    } else {
      if (nameCluster != folder.name) {
        mutateName.mutate({
          storageClusterId: folder.id,
          name: nameCluster,
          folder: folder,
        });
      }
      if (corCluster != folder.color) {
        mutateColor.mutate({
          storageClusterId: folder.id,
          color: corCluster,
          folder: folder,
        });
      }
    }
  }

  const handleName = (e) => {
    if (e.target.value.length > 100) return;
    setNameCluster(e.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      saveChange();
    }
  };

  return (
    <ModalContainer
      loadRequisition={mutateColor.isLoading || mutateName.isLoading}
      padding={false}
      height="230px"
      $minHeight={false}
      onButtonRight={saveChange}
      buttonConfirmation={true}
      title="Editar pasta"
      onClick={(e) => {
        e.stopPropagation(), modal.current?.close();
      }}
      buttonBack={true}
    >
      <Container>
        <InputControlled
          label="Nome"
          onKeyPress={handleKeyPress}
          ref={inputRef}
          value={nameCluster}
          onChange={handleName}
          placeholder="Nome"
          maxLength={100}
        />
        <InputColor
          label="Cor"
          name="cor"
          value={corCluster}
          onChange={(color) => {
            setCorCluster(color.hex!);
          }}
        />
      </Container>
    </ModalContainer>
  );
};

export default ModalUpdateCluster;
