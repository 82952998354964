import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconDarkMode = ({
  color = "#666666",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>

      <g clipPath="url(#clip0_5766_2388)">
        <path
          d="M10.7076 0.00484117C4.69209 0.659862 0 5.76635 0 11.969C0 15.1598 1.26755 18.22 3.5238 20.4762C5.78005 22.7325 8.84018 24 12.031 24C18.2203 24 23.3268 19.3213 23.9952 13.3058C24.1155 12.2497 22.9525 11.4076 21.9365 12.0358C20.8453 12.7324 19.5869 13.1228 18.293 13.1663C16.9991 13.2097 15.7173 12.9046 14.5818 12.2829C13.4463 11.6611 12.4987 10.7456 11.8383 9.63213C11.1779 8.51864 10.8289 7.24811 10.8279 5.9535C10.8279 4.53652 11.2423 3.19974 11.9508 2.09021C12.5523 1.19457 11.8973 -0.0887333 10.7076 0.00484117Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5766_2388">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconDarkMode;
