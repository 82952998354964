import { ContentText } from "pdfmake/interfaces";
import { useContext, useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import { ContentTextPosition, EnumPositionHorizontal, ReportStylesInterface } from "../../../../../../types/project";
import TextOptions from "../../FormContent/TextOptions";
import { OptionTitle } from "../../style";
import { ReportContext } from "../../../../../../contexts/ReportContext";

interface PaginationProps {
}

const PaginationOption = ({  }: PaginationProps) => {
    const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
    const [valueTemp, setValueTemp] = useState<ContentText & { position: EnumPositionHorizontal }>(stylesPDF.footer.pagination);
    const [isEdit, setIsEdit] = useState(false)

    function saveChange() {
        stylesPDF.footer.pagination = valueTemp;
        handleStylesPDF({ ...stylesPDF });
        setIsEdit(false);
    }

    function deleteItem() {
        // if (value) {
        //     const index = stylesPDF.body.indexOf(value);
        //     if (index > -1) {
        //         stylesPDF.body.splice(index, 1)
        //     }
        //     setIsEdit(false);
        //     setStylesPDF({ ...stylesPDF });
        // }
    }

    function closeEdit() {
        setValueTemp(stylesPDF.footer.pagination);
        setIsEdit(false);
    }

    return (
        <>
            <OptionTitle >
                <div className="flex">
                    <h5>Paginação</h5>

                </div>
                <div className="buttons">
                    {!isEdit ? <>
                        <FiTrash className="icon-color" onClick={deleteItem} size={18} />
                        <MdModeEdit className="icon-color" onClick={() => setIsEdit(true)} size={18} />
                    </>
                        :
                        <>
                            <BsXLg className="icon-color" onClick={closeEdit} size={18} />
                            <BsCheckLg className="icon-color" onClick={saveChange} size={18} />
                        </>}
                    {/* <IoIosArrowUp className={isOpen ? "center down" : "center up"} onClick={() => setIsEdit(true)} size={18} /> */}
                </div>

            </OptionTitle>

            <TextOptions<ContentTextPosition> isEditing={isEdit} setValueText={setValueTemp} valueText={valueTemp} textEditable={false} />

        </>

    );
}

export default PaginationOption;