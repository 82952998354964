import React, { useState, useCallback, forwardRef, useImperativeHandle } from "react";
import { Container } from "./style";
import Cropper from 'react-easy-crop'
import getCroppedImg from "./cropImage";
import { useToasts } from "react-toast-notifications";

const CropImage = forwardRef((props: any, ref) => {
    const { url, ...rest } = props;
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const { addToast } = useToasts();

    useImperativeHandle(ref, () => {
        return {
            cropImage: () => cropImage(),
        }
    });

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const cropImage = async () => {
        try {
            const { preview, file } = await getCroppedImg(
                url,
                croppedAreaPixels,
                rotation
            )
            return { file, defaultUrl: url, newUrl: preview };
        } catch (e) {
            addToast({
                title: "Erro",
                description: "Não foi possível recortar imagem",

            },
                { appearance: 'error' });
        }
    }

    return (
        <Container {...rest}>
            <Cropper
                image={url}
                crop={crop}
                zoom={zoom}
                style={{ cropAreaStyle: { color: 'rgba(164, 164, 164, 0.1)' } }}
                aspect={3 / 3}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />
        </Container>
    );
});

export default CropImage;