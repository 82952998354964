import {api} from '../api';
import { getToken } from "../auth";

export const postCluster = (userId, name) => {
    return api({
        url: "/cluster",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { userId, name }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const getClusters = (userId) => {
    return api({
        url: "/cluster/" + userId,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};


export const getPersonalsByCluster = (userId, clusterId) => {
    return api({
        url: `/cluster/get/itens/${userId}/${clusterId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const postGeneral = (userId) => {
    return api({
        url: "/cluster/general",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { userId }
    }).then(response => response.data)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};


export const putCluster = (clusterId, name) => {
    return api({
        url: "/cluster",
        method: "PUT",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { clusterId, name }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const deleteCluster = (clusterId) => {
    return api({
        url: "/cluster",
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { clusterId }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const getNameClusters = (userId, clusterId) => {
    return api({
        url: `/cluster/${userId}/${clusterId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { userId, clusterId }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const postItem = (folderId, itens) => {
    return api({
        url: "cluster/itens/"+folderId,
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { itens }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const deleteItem = (folderId, itens) => {
    return api({
        url: "cluster/itens/"+folderId,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { itens }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};

export const getItensForAdd = (userId, clusterId) => {
    return api({
        url: `/cluster/get/itens-for-add/${userId}/${clusterId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error', error.message);
                return error.message
            }
        });
};