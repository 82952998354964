import styled from "styled-components";

export const MainInput = styled.div`
  label {
    display: inline-block;
    margin-top: 0.7rem;
    font-size: 14px;
    line-height: 17px;
    font-family: "inter", sans-serif;
    color: ${props => props.theme.texts.darkerGreyII};
  }

  .div-limit {
    text-align: right;

    .limit {
        color: ${props => props.theme.texts.darkerGreyII};
        font-size: 10px;
        font-weight: 300;
        line-height: 12px;
      }
  }
  
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(37, 129, 196, 0.05);
  border-radius: 6px;
  padding-inline: 0.5rem;
  height: 48px;
  max-width: 370px;

  #icon {
    color: ${props => props.theme.texts.purpleDarkTitle};
    padding-inline: 0.2rem;
  }

  input,
  textarea {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 40px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 13px;

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: ${(props) => props.theme.texts.darkGrey};
      opacity: 1;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkGrey};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkGreyI};
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.texts.purpleDarkTitle};
    }

    &:focus {
      -webkit-text-fill-color: ${(props) => props.theme.texts.purpleDarkTitle};
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus-within {
    color: ${(props) => props.theme.texts.purpleDarkTitle};
    -webkit-text-fill-color: ${(props) => props.theme.texts.purpleDarkTitle};
    border: 0.1px solid ${(props) => props.theme.texts.purpleDarkTitle};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: auto;
    padding-top: 0.7rem;
  }
`;