import { useDispatch, useSelector } from "react-redux";
import { TypeConstRedux } from "../../../../../types/redux";
import ButtonPath from "../ButtonPath";
import { getSource } from ".";
import { Dropdown } from "../../../../../components";
import { IoMdArrowDropright } from "react-icons/io";
import { MdArrowDropDown, MdModeEdit } from "react-icons/md";
import {
  AiFillEye,
  AiFillFileAdd,
  AiFillFolder,
  AiFillFolderAdd,
} from "react-icons/ai";
import { BsFillShareFill, BsThreeDots } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { useRef } from "react";
import Modal, { ModalRef } from "../../../../../components/Modal";
import ModalNewCluster from "../modal/modalNewCluster";
import ModalUpdateCluster from "../modal/modalUpdateCluster";
import ModalExit from "../modal/modalConfirmation";
import { useToasts } from "react-toast-notifications";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { StorageClusterService } from "../../../../../services/requests/storage-cluster";
import { AxiosError, AxiosResponse } from "axios";
import { Queries } from "../../../../../types/queries";
import { ToastModel } from "../../../../../util/error";
import ModalShare from "../modal/modalShare";
import { setRepositoryFolderSelected } from "../../../../../store/actions/storageClusterAction";
import { StorageMediaService } from "../../../../../services/requests/storage-media";

const PathFile = () => {
  const { folder, fileSelected, filters, typeSelect, itemSelected } =
    useSelector((state: TypeConstRedux) => state.repository);
  const lastFolder = folder[folder.length - 1];
  const raiz = getSource(typeSelect);
  const folderRef = useRef<ModalRef>(null);
  const folderUpdateRef = useRef<ModalRef>(null);
  const folderDeleteRef = useRef<ModalRef>(null);
  const shareRef = useRef<ModalRef>(null);
  const fileRef = useRef<any>();

  const queryClient = useQueryClient();

  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation(
    (id: string) => StorageClusterService.deleteById(id),
    {
      onSuccess: ({ status }: AxiosResponse) => {
        if (status == 200) {
          queryClient.setQueryData(
            [Queries.REPOSITORY_FOLDERS, lastFolder.StorageCluster_id ?? ""],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.filter((item) => item.id != lastFolder.id);
              }
              return [];
            }
          );
          folderDeleteRef.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  const dispatch = useDispatch();

  const handleFolder = () => folderRef.current?.open();
  const handleUpdateFolder = () => folderUpdateRef.current?.open();
  const onDelete = () => folderDeleteRef.current?.open();
  const onShare = () => shareRef.current?.open();
  const onNewFile = () => fileRef.current?.click();
  const handleDelete = () => {
    if (!isLoading) {
      mutate(lastFolder.id);
    }
  };

  const handleFileSelected = () => {
    dispatch(setRepositoryFolderSelected(lastFolder));
  };

  const mutateNewFile = useMutation(
    (dataForm: FormData) => StorageMediaService.post(dataForm),
    {
      onSuccess: (data: AxiosResponse) => {
        console.log(data);
        if (data.status == 201) {
          queryClient.invalidateQueries([
            Queries.REPOSITORY_FILES,
            lastFolder.id,
          ]);
        } else if (data.status == 403) {
          ToastModel(
            addToast,
            "Error ao salvar!",
            "Já existe um arquivo com o mesmo nome salvo na pasta.",
            "warning"
          );
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: (err: AxiosError) => {
        ToastModel(addToast, "Erro de conexão!", err.message, "error");
      },
    }
  );

  const handleChange = (e) => {
    if (e.target.files[0]) {
      handleUpload(e.target.files[0]);
    }
  };

  const handleUpload = (file) => {
    const dataForm = new FormData();
    dataForm.append("file", file);
    dataForm.append("name", file.name);

    dataForm.append("storageClusterId", lastFolder.id);

    if (!isLoading) {
      mutateNewFile.mutate(dataForm);
    }
  };

  const ItensPath = () => {
    const isCompressed = folder.length > 3;
    const folderVisible = isCompressed
      ? folder.slice(folder.length - 2, folder.length)
      : folder;
    const folderHidden = isCompressed
      ? folder.slice(0, folder.length - 2)
      : null;

    return (
      <>
        {folderHidden ? (
          <Dropdown defaultClass="bottom-right">
            <div className="item-arrow">
              <IoMdArrowDropright />
              <div className="points">
                <BsThreeDots />
              </div>
            </div>
            <div>
              {folderHidden.map((folderChild) => (
                <li className="item-list">
                  {" "}
                  <ButtonPath folderActual={folderChild}>
                    <AiFillFolder />
                    {folderChild.name}
                  </ButtonPath>
                </li>
              ))}
            </div>
          </Dropdown>
        ) : null}
        {folderVisible.map((folderChild, index) =>
          index != folderVisible.length - 1 ? (
            <div className="item-arrow">
              <IoMdArrowDropright />
              <div className="line item" key={folderChild.id}>
                <ButtonPath folderActual={folderChild} />
              </div>
            </div>
          ) : (
            <div className="dropdown">
              <Dropdown
                isHoved={false}
                defaultClass="bottom-right"
                mobile={true}
                notifications={true}
                overflow={true}
              >
                <div className="item-arrow">
                  <IoMdArrowDropright />
                  <div className="line item" key={folderChild.id}>
                    <ButtonPath folderActual={folderChild} />
                    <MdArrowDropDown />
                  </div>
                </div>
                <div className="widthLista">
                  <li className="item-list" onClick={onNewFile}>
                    {" "}
                    <AiFillFileAdd className="icon" size={18} /> Upload de
                    arquivo
                  </li>
                  <li className="item-list" onClick={handleFolder}>
                    {" "}
                    <AiFillFolderAdd className="icon" size={18} /> Nova pasta
                  </li>

                  <li className="item-list" onClick={handleUpdateFolder}>
                    {" "}
                    <MdModeEdit className="icon" size={18} /> Editar
                  </li>

                  <li className="item-list" onClick={onDelete}>
                    {" "}
                    <FaTrash className="icon" size={14} /> Excluir
                  </li>

                  <li className="item-list" onClick={onShare}>
                    {" "}
                    <BsFillShareFill className="icon" size={14} /> Compartilhar
                  </li>
                  <li className="item-list" onClick={handleFileSelected}>
                    {" "}
                    <AiFillEye className="icon" size={14} /> Ver detalhes
                  </li>
                </div>
              </Dropdown>
            </div>
          )
        )}
      </>
    );
  };

  return (
    <nav>
      <input
        ref={fileRef}
        style={{ display: "none" }}
        onChange={(e) => handleChange(e)}
        type="file"
        accept="*"
      />

      <div className="item">
        <ButtonPath folderActual={raiz} />
      </div>

      <ItensPath />
      <Modal mobile={true} ref={folderRef}>
        <ModalNewCluster modal={folderRef} />
      </Modal>
      <Modal mobile={true} ref={folderUpdateRef}>
        <ModalUpdateCluster
          folder={folder[folder.length - 1]}
          modal={folderUpdateRef}
        />
      </Modal>
      <ModalExit
        action={handleDelete}
        title="Deletar arquivo"
        modalRef={folderDeleteRef}
      />
      <Modal mobile={true} ref={shareRef}>
        <ModalShare type="folder" modal={shareRef} folder={lastFolder} />
      </Modal>
    </nav>
  );
};

export default PathFile;
