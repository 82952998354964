import { BsCheckAll, BsFillCircleFill } from "react-icons/bs";
import { FaBirthdayCake } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";
import { MdEvent } from "react-icons/md";
import { RiAlertLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Logo64 } from "../../../../assets/images";
import { Dropdown } from "../../../../components";
import { setDateSelect, setFilter } from "../../../../store/actions/scheduleAction";
import { EnumCategoryNotification, TNotification } from "../../../../types/project";
import { TypeConstRedux } from "../../../../types/redux";
import { calcularTempoDecorrido } from "../../../../util/date";
import { getImageEvent, photoUrl } from "../../../../util/verification";
import { ItemNotification } from "./style";
interface NotificationProps {
    notification: TNotification;
    handleDelete: (notification) => any;
    handleStatus: (notification) => any;
    padding?: boolean;
    firstItem?: boolean;
    modal?: any;
}

export const switchIconNotUser = (type: EnumCategoryNotification) => {
    if (type === EnumCategoryNotification.BIRTHDAY) {
        return FaBirthdayCake;
    }
    if (type === EnumCategoryNotification.EVENT) {
        return MdEvent;
    }
    return RiAlertLine;
}

const Notification = ({ modal, notification, handleDelete, handleStatus, padding = false, firstItem = false }: NotificationProps) => {
    const Icon = switchIconNotUser(notification.category);
    const global = useSelector<TypeConstRedux>(state => state.chat.globalMessages);
    const history = useHistory();
    const dispatch = useDispatch();

    function getImage() {
        if (notification.user) {
            return photoUrl(notification.user);
        }
        if (notification.event && global) {
            return getImageEvent(notification.event, global);
        }
        return Logo64;
    }
    const redirect = () => {
        if (notification.event) {
            //Se o status da notificação for "not read" muda para "read" 
            if (!notification.status) {
                handleStatus(notification);
            }  
            //Muda o filtro da pagina da agenda para o filtro do evento selecionado
            if (notification.event.Group_id) {
                dispatch(setFilter(1));
            } else {
                dispatch(setFilter(0));
            }
            //Muda a data da pagina da agenda para a data do evento selecionda
            dispatch(setDateSelect(new Date(notification.event.dateStart)));

            //Se notification estiver em um modal fecha o modal
            if (modal) {
                modal.current.close()
            }
            //Redireciona rota para rota da agenda
            history.push(`/home/schedule`, notification.event);

        }
    }


    return (
        <ItemNotification onClick={redirect} isView={!notification.status} padding={padding} >
            <div className="container_icon">
                <img onLoad={() => { console.log("imagem carregada") }} id="profile" src={getImage()} />
                <Icon id="icon_theme" />
            </div>
            <div className="div_content">
                <p className="content">{notification.description}</p>
                <p className="dateSend">• {calcularTempoDecorrido(notification.createdAt)}</p>
            </div>

            <Dropdown defaultClass={firstItem ? "bottom-left" : "top-left"} notifications={false}>
                <FiMoreVertical id="icon_drop" />
                <div className="notifications_options">
                    {!notification.status && <li onClick={() => handleStatus(notification)}>
                        <BsCheckAll className="icon" size={22} />Marcar como lida
                    </li>}
                    <li onClick={() => handleDelete(notification)}>
                        {" "}
                        <GiCancel className="icon" size={18} />Excluir notificação
                    </li>
                </div>
            </Dropdown>
            <BsFillCircleFill id="icon_read" />
        </ItemNotification>
    )
};

export default Notification;
