import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { BsCheckLg, BsPeople, BsTextLeft } from 'react-icons/bs';
import { CgClose, CgUserList } from 'react-icons/cg';
import { FaUserLock } from 'react-icons/fa';
import { FiMoreVertical, FiUserCheck, FiUserX } from 'react-icons/fi';
import { IoClose, IoTrash } from 'react-icons/io5';
import { MdModeEdit, MdOutlineLocationOn } from 'react-icons/md';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Dropdown, Load } from '../../../../../components';
import Modal, { ModalRef } from '../../../../../components/Modal';
import ModalEvent from '../../../../../components/Modal/ModalEvent';
import { deleteEvent } from '../../../../../services/requests/event';
import { pacthEventUser, postEventUser } from '../../../../../services/requests/event-user';
import { removeEventSchedule, updateEventSchedule } from '../../../../../store/actions/scheduleAction';
import { TEvent } from '../../../../../types/project';
import { EnumScheduleType, TypeConstRedux } from '../../../../../types/redux';
import { getColorCategory } from '../../../../../util/categoryUtils';
import { formatarPeriodo } from '../../../../../util/date';
import { ToastModel } from '../../../../../util/error';
import FilesEvent from '../../Content/Event/Files';
import PermissionEvent from '../../Content/Event/Permission';
import PresenceEvent from '../../Content/Event/Presence';
import { ButtonElement, ContainerEvent } from '../style';

interface ModalProps {
  event: TEvent,
  onClose: () => void,
  style: React.CSSProperties,
  modalRef: React.RefObject<HTMLDivElement>,
  transition: boolean,
}

const EventDetailsModal = ({ event, onClose, style, modalRef, transition }: ModalProps) => {
  const { addToast } = useToasts();
  const [mounted, setMounted] = useState(false);

  const state = useSelector((state: TypeConstRedux) => state);
  const events = state.folder;
  const optionsEvents = (state.schedule.filter == EnumScheduleType.GROUP && event.Group_id) || (state.schedule.filter == EnumScheduleType.PERSONAL && !event.Group_id) || (state.schedule.filter == EnumScheduleType.PERSONAL && event.participation == 1);
  const isAdm = event?.User_id == state.abare.abare.id;
  const moreOptions = ((isAdm && event.Group_id) || event.isCanPresence || event.isCanEdit || event.isCanDelete);
  const isGroupAgenda = state.schedule.filter === EnumScheduleType.GROUP;
  const { weather_dash } = useSelector((state: TypeConstRedux) => state.dashboard);
  const showEdit = true;
  const showDelete = true;
  const showMoreOptions = true;
  const showFiles = true;

  const dispatch = useDispatch();
  const modalEdit = useRef<ModalRef>(null);
  const modalFiles = useRef<ModalRef>(null);
  const modalPresence = useRef<ModalRef>(null);
  const modalPermissions = useRef<ModalRef>(null);

  const mutateDelete = useMutation(() => deleteEvent(event.id), {
    onSuccess: ({ status }: AxiosResponse) => {
      if (status == 200) {
        dispatch(removeEventSchedule(event));
        ToastModel(addToast, "Evento excluído!", "Seu evento foi excluído com sucesso.", 'success');
      } else {
        ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
      }
    },
    onError: (error: AxiosError) => {
      ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
    }
  })

  const mutateCreateEventUser = useMutation((participation: boolean) => postEventUser(event.id, participation), {
    onSuccess: ({ status, }: AxiosResponse, data) => {
      if (status == 200) {
        event.participation = data ? 1 : 0;
        dispatch(updateEventSchedule({ ...event }));
        if (data) {
          ToastModel(addToast, "Convite confirmado!", "Convite confirmado com sucesso.", 'success')
        } else {
          ToastModel(addToast, "Convite recusado!", "Convite recusado com sucesso.", 'success')
        }
      } else {
        ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
      }
    },
    onError: () => {
      ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
    }
  });

  const mutatePatchEventUser = useMutation((participation: 1 | 0) => pacthEventUser(event.id, participation), {
    onSuccess: ({ status }: AxiosResponse, data) => {
      if (status == 200) {
        event.participation = data ? true : false;
        dispatch(updateEventSchedule({ ...event }));
        if (data) {
          ToastModel(addToast, "Convite confirmado!", "Convite confirmado com sucesso.", 'success')
        } else {
          ToastModel(addToast, "Compromisso cancelado!", "Seu compromisso foi cancelado com sucesso.", 'success')
        }
      } else {
        ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
      }
    },
    onError: () => {
      ToastModel(addToast, "Erro de conexão!", "Tente novamente mais tarde.", 'error');
    }
  });

  useEffect(() => {
    if (mounted) {
      onClose();
    } else {
      setMounted(true);
    }
  }, [events]);

  const handleDelete = () => {
    mutateDelete.mutate();
  }

  const onEdit = () => {
    modalEdit.current?.open();
  }

  const onFiles = () => {
    modalFiles.current?.open();
  }
  const onPresence = () => {
    modalPresence.current?.open();
  }
  const onPermissions = () => {
    modalPermissions.current?.open();
  }
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      console.log("oiiiiiiiiiii")
      console.log(target)
      const testElement = document.getElementById('modalEventDetails');
      console.log(testElement)
      if ((!testElement || !testElement.contains(target)) && (!target.classList.contains('rbc-event-content') && !target.classList.contains('rbc-event'))) {
        onClose();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <ContainerEvent className={transition ? "transition" : "noTransition"} id="modalEventDetails" onClick={(e) => e.stopPropagation()} ref={modalRef} style={style}>
      <header>
        {optionsEvents &&
          <>
            {/* Botão para edição do evento */}
            {(event.isCanEdit || !event.Group_id) && <MdModeEdit className='iconButton' title="Editar" onClick={onEdit} />}

            {/* Botão para Exclusão do evento */}
            {(event.isCanDelete || !event.Group_id) && (
              mutateDelete.isLoading ? <Load primaryColor={'light'} secundaryColor={'dark'} />
                : <IoTrash className='iconButton' onClick={handleDelete} title="Excluir" />
            )}

            {/* Botão para Anexar arquivo */}
            <AiOutlinePaperClip className='iconButton' title="Anexar arquivo" onClick={onFiles} />

            {/* Botão para mais opções do evento */}
            {moreOptions && (mutatePatchEventUser.isLoading ? <Load primaryColor={'light'} secundaryColor={'dark'} /> : <Dropdown event={true} defaultClass="bottom-right">
              <FiMoreVertical id="ttt" className='iconButton' />
              <div className="widthLista">
                {
                  event.isCanPresence && <li onClick={onPresence}>
                    {" "}
                    <CgUserList className="iconDropEvent" size={18} />Presença
                  </li>
                }
                {
                  isAdm && event.Group_id && <li onClick={onPermissions}>
                    {" "}
                    <FaUserLock className="icon" size={18} />Permissões
                  </li>
                }
                {event.participation === 0 && isGroupAgenda && true && true && <li onClick={() => (mutatePatchEventUser.mutate(1))}>
                  {" "}
                  <BsCheckLg className="icon" size={14} /> Participar
                </li>}
                {event.participation === 1 && true && true && <li onClick={() => (mutatePatchEventUser.mutate(0))}>
                  {" "}
                  <CgClose className="icon" size={14} /> Cancelar
                </li>}

              </div>
            </Dropdown>)}
          </>}

        {/* Botão para fechar Modal */}
        <IoClose className='iconButton' title="Fechar" onClick={onClose} />
      </header>

      <div className='content'>
        <div className="title">
          <div className="category" style={{ backgroundColor: getColorCategory(event) }}></div>
          <h2 title={event.title}>{event.title}</h2>
        </div>
        <p className='date'>{formatarPeriodo(event.dateStart, event.dateEnd)}</p>

        {event.name_group && <div className='description'>
          <BsPeople className='icon' />
          <p title={event.name_group}>{event.name_group}</p>
        </div>}

        {event.description.length > 0 && <div className='description'>
          <BsTextLeft className='icon' />
          <p title={event.description}>{event.description}</p>
        </div>}
        {event.local ? <div className='description'>
          <a data-tip data-for='maps' href={`https://www.google.com.br/maps/dir/${weather_dash ? `${weather_dash.coord.lat},${weather_dash.coord.lon}` : ``}/${event.local && event.local.replace(" ", "+")}/@`} className="button_maps" target="_blank" rel="noopener noreferrer">
            <MdOutlineLocationOn className='icon' />
            <p title={event.local}>{event.local}</p>
          </a>
        </div> : null}
        {(event.participation === null && true) && <div className='actions'>

          {mutateCreateEventUser.isLoading ? <Load primaryColor={'light'} secundaryColor={'dark'} /> : <>
            <ButtonElement onClick={() => mutateCreateEventUser.mutate(false)}>
              {mutateCreateEventUser.isLoading ? <Load primaryColor={'light'} secundaryColor={'dark'} /> : <FiUserX size="20px" />}
              Recusar
            </ButtonElement>
            <ButtonElement onClick={() => mutateCreateEventUser.mutate(true)} background="#03BB85" color="white" border="#03BB85">
              {mutateCreateEventUser.isLoading ? <Load primaryColor={'light'} secundaryColor={'dark'} /> : <FiUserCheck size="20px" />}
              Confirmar
            </ButtonElement>
          </>}

        </div>}
      </div>
      {/* Modal para edição do evento */}
      <ModalEvent modal={modalEdit} event={event} />

      {/* Modal para Administração dos arquivos do evento */}
      <Modal ref={modalFiles} mobile={true}>
        <FilesEvent modal={modalFiles} event={event} />
      </Modal>

      {/* Modal Para confirmar a presença no evento */}
      <Modal ref={modalPresence}>
        <PresenceEvent modal={modalPresence} event={event} />
      </Modal>

      {/* Modal para permissões do evento */}
      <Modal ref={modalPermissions}>
        <PermissionEvent modal={modalPermissions} event={event} />
      </Modal>
    </ContainerEvent>

  );
};

export default EventDetailsModal;
