import { MouseEvent, useCallback, useRef, useState } from "react";
import { Container } from "./style";

const classOption = {
    'top-left': 'bottom-left',
    'bottom-left': 'top-left',
    'top-right': 'bottom-right',
    'bottom-right': 'top-right'
}

const Dropdown = (props) => {
    const { isHoved = true, children, color, defaultClass, message, mobile = false, notifications = false, overflow = false, inputColor = false, event = false } = props;
    const observer = useRef<any>();
    const [hoved, setHoved] = useState<boolean>(false);
    const [className, setClassName] = useState(defaultClass);
    const ulRef = useRef<any>();

    const handleClass = () => {
        if (defaultClass.split("-")[0] !== 'top') {
            setClassName(classOption[defaultClass]);
        }
    };

    const dropRef = useCallback(node => {
        observer.current && observer.current.disconnect();
        if (!dropRef) return;
        if (!hoved) return;

        observer.current = new IntersectionObserver(entries => {
            const contentPositions = entries[0].boundingClientRect;
            const contentDimensions = ulRef.current;

            if (message) {
                //drop de msg; se for abrir pro lado esquerdo nao faz nada 
                if (contentDimensions.className == defaultClass.split("-")[0] + '-left') return;

                //para saber se a posicao mais a largura do componente ultrapassa a tela
                if (contentPositions.x + contentDimensions.clientWidth > innerWidth) {
                    setClassName(defaultClass.split("-")[0] + '-left');
                    return;
                }

            }
            if (event) {
                if (contentPositions.x + contentDimensions.clientWidth > innerWidth) {
                    setClassName(defaultClass.split("-")[0] + '-left');
                    return;
                }
            }
            if (contentPositions.x + contentDimensions.clientWidth > innerWidth) {
                setClassName(defaultClass.split("-")[0] + '-left');
                return;
            }
            contentPositions.top < contentDimensions.clientHeight + 100 ?
                setClassName(defaultClass)
                :
                contentPositions.top + contentDimensions.clientHeight + 20 > innerHeight ?
                    handleClass()
                    :
                    setClassName(defaultClass)
        });
        node && observer.current.observe(node);
    }, [hoved])

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        setHoved(!hoved);
    }
    const handleMouseEnter = () => {
        if (isHoved) {
            setHoved(true);
        }
    }

    const handleMouseLeave = () => {
        if (isHoved) {
            setHoved(false);
        }
    }

    return (
        <Container
            notifications={notifications}
            mobileNav={mobile}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            $overflow={overflow}
            ref={dropRef} color={color} $widthUl={props.widthUl}>

            {children[0]}
            {
                hoved && <ul onClick={(e) => {
                    if (!inputColor) {
                        e.stopPropagation()
                        setHoved(false)
                    }
                }} ref={ulRef} className={className} >
                    {children[1]}
                </ul>
            }


        </Container >
    );
}

export default Dropdown;