import styled from "styled-components";

interface StyledProps {
  width?: number;
  size?: string;
  margin?: string;
  secundaryColor?: any;
  primaryColor?: any;
  dark?: boolean;
}

export const Load = styled.div<StyledProps>`
  pointer-events: none;
  margin-left: ${({ margin }) => (margin ? margin : "0px")};
  width: ${(props) => (props.width ? props.width : "13px")};
  height: ${(props) => (props.width ? props.width : "13px")};
  border: ${(props) => (props.size ? props.size : "0.2em")} solid transparent;
  border-color: ${(props) =>
    props.secundaryColor
      ? props.theme.default[props.secundaryColor]
      : props.theme.backgrounds.lightGreyII};
  border-top-color: ${(props) =>
    props.primaryColor
      ? props.theme.purple[props.primaryColor]
      : props.dark
      ? props.theme.hover.purpleDark
      : props.theme.backgrounds.purpleDark};
  border-right-color: ${(props) =>
    (props.primaryColor)
      ? props.theme.purple[props.primaryColor]
      : (props.dark)
      ? props.theme.hover.purpleDark
      : props.theme.backgrounds.purpleDark};
  border-radius: 50%;
  animation: loadingspin 1s linear infinite;

  @keyframes loadingspin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
interface ContainerProps {
  dark?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  flex: 1;
  height: 100%;
  color: ${(props) => props.dark? props.theme.hover.input : props.theme.backgrounds.purpleDark};
  .loadText {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      margin: 7px 0px;
    }
  }
`;
