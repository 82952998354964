import { api } from '../api'
import { getToken } from '../auth'

export const getUserWithPrivacy = (userId, userIdInfo) => {
  return api({
    url: `/users/with-privacy/${userId}/${userIdInfo}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  })
    .then(response => response)
    .catch(error => {
      if (error.response) {
        console.log(error.response)
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}

export const patchUpdatePrivacyUser = (userId, data) => {
  return api({
    url: `/privacy/${userId}`,
    method: 'PATCH',
    headers: {
      Authorization: 'Bearer ' + getToken()
    },
    data: data
  })
    .then(response => response)
    .catch(error => {
      if (error.response) {
        console.log(error.response)
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}

export const getPrivacyData = userId => {
  return api({
    url: `/privacy/${userId}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  })
    .then(response => response)
    .catch(error => {
      if (error.response) {
        console.log(error.response)
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}

export const postUserBlock = (userId, userIds) => {
  return api({
    url: `/user-block/${userId}`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + getToken()
    },
    data: { userIds }
  })
    .then(response => response.data)
    .catch(error => {
      if (error.response) {
        console.log(error.response)
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}

export const deleteUserBlock = (userId, userIds) => {
  return api({
    url: `/user-block/${userId}`,
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + getToken()
    },
    data: { userIds }
  })
    .then(response => response.data)
    .catch(error => {
      if (error.response) {
        console.log(error.response)
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}

export const getUsersBlock = idUser => {
  return api({
    url: `/user-block/${idUser}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  })
    .then(response => response)
    .catch(error => {
      if (error.response) {
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}


export const getICanCall = idUser => {
  return api({
    url: `/privacy/search/${idUser}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  })
    .then(response => response)
    .catch(error => {
      if (error.response) {
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        console.log('Error', error.message)
        return error.message
      }
    })
}