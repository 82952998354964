import { ContainerWelcome } from "./style";
import photoIcon from "../../../../../../assets/images/photo_icon.svg";
import { useSelector } from "react-redux";
import { TypeConstRedux } from "../../../../../../types/redux";
export function CardWelcome() {
  const { abare } = useSelector((state: TypeConstRedux) => state.abare);
  return (
    <ContainerWelcome>
      <div className="container-welcome ">
        <header>
          <h3>Olá,</h3>
          <h1>{abare.name}</h1>
        </header>
        <footer>
          <span>
            Boas Vindas ao <strong>Abaré.net!</strong>
          </span>
          <span>
            Crie conexões, compartilhe e aproveite a nossa plataforma.
          </span>
        </footer>
      </div>
      <img
        src={photoIcon}
        alt="Uma foto de duas pessoas apresentado um aplicativo"
      />
    </ContainerWelcome>
  );
}
