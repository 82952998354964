import { memo, useEffect, useRef, useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import {
  BiBlock,
  BiTrashAlt,
  BiUserPlus,
  IoIosClose,
} from "../../../../../assets/icons";
import { Load } from "../../../../../components";
import Img from "../../../../../components/Img";
import { getInfoPersonal } from "../../../../../services/requests/personal";
import {
  deleteUserBlock,
  getUserWithPrivacy,
  postUserBlock,
} from "../../../../../services/requests/privacy";
import { updateClient } from "../../../../../store/actions/messages/chatAction";
import { setChat } from "../../../../../store/actions/messages/messageAction";
import { TypePersonal } from "../../../../../types/project";
import { ToastModel } from "../../../../../util/error";
import {
  cpfOrCnpjFormat,
  dateFormat,
  getBioSex,
  getClientId,
  getTypeRelationship,
  photoUrl,
  userIdContact,
} from "../../../../../util/verification";
import DeletePersonal from "./Delete";
import { Back, Button, Container, Item, MainLoad, Row } from "./style";

const ItemData = ({ itens }) =>
  itens.map(({ name, value, className }, i) =>
    value ? (
      <Row className={className} key={i}>
        {name}
        <div className="end">{value}</div>
      </Row>
    ) : null
  );

const Profile = (props) => {
  const { abare, contact, setChat, updateClient, socket, theme } = props;
  const modalDelete = useRef<any>();
  const personalId = getClientId(contact);
  const { push } = useHistory();
  const { addToast } = useToasts();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    switch (contact.type) {
      case TypePersonal.GUEST:
        setLoad(true);
        getUserWithPrivacy(abare.id, contact.id).then((res) => {
          if (res.status == 200) {
            const isContact = true;
            setChat(personalId, {
              client: { ...contact, ...res.data, isContact },
            });
          }
          setLoad(false);
        });
        break;

      case TypePersonal.PERSONAL:
        if (contact.searched) {
          setLoad(true);
          const userIdInfo = userIdContact(abare.id, contact);
          getInfoPersonal(abare.id, userIdInfo, personalId).then((res) => {
            if (res.status == 200) {
              const isContact = res.data.contactInfo ? true : false;
              setChat(personalId, {
                client: {
                  ...contact,
                  ...res.data.user,
                  ...res.data.contactInfo,
                  isContact,
                },
              });
            }
            setLoad(false);
          });
        }
        break;
      default:
        break;
    }
  }, []);

  const BlockUser = () => {
    const userIds = [userIdContact(abare.id, contact) || contact.id];
    contact.iBlocked
      ? deleteUserBlock(abare.id, userIds).then((res) => {
          if (res.status == 200) {
            updateClient({ ...contact, iBlocked: false });
            ToastModel(
              addToast,
              "Usuário desbloqueado",
              "Você poderá receber mensagens e ligações novamente",
              "success"
            );
            socket.emit("changeBlocked", {
              userIdContact: userIdContact(abare.id, contact),
              photoUrl: abare.photoUrl,
              Personal_id: personalId,
              blockedMe: false,
            });
          } else {
            ToastModel(
              addToast,
              "Erro de conexão!",
              "Tente novamente mais tarde",
              "error"
            );
          }
        })
      : postUserBlock(abare.id, userIds).then((resp) => {
          if (resp.status == 200) {
            updateClient({ ...contact, iBlocked: true });
            ToastModel(
              addToast,
              "Usuário bloqueado",
              "Usuário não poderá enviar mensagens e fazer ligações",
              "warning"
            );
            socket.emit("changeBlocked", {
              userIdContact: userIdContact(abare.id, contact),
              photoUrl: null,
              Personal_id: personalId,
              blockedMe: true,
            });
          } else {
            ToastModel(
              addToast,
              "Erro de conexão!",
              "Tente novamente mais tarde",
              "error"
            );
          }
        });
  };

  const datas = [
    {
      title: "Dados básicos",
      itens: [
        {
          name: "CPF/CNPJ",
          value: cpfOrCnpjFormat(contact?.cnpj || contact?.cpf, !contact?.cnpj),
        },
        { name: "Data de Aniversário", value: dateFormat(contact?.birthday) },
        {
          name: "Relacionamento",
          value: getTypeRelationship(contact?.relationship),
        },
        { name: "Sexo", value: getBioSex(contact.bioSex) },
      ],
    },
    {
      title: "Contato",
      itens: [
        { name: "E-mail", value: contact?.email },
        {
          name: "Número de Telefone",
          value: formatPhoneNumberIntl(contact?.phoneNumber),
        },
        { name: "Endereço", value: contact?.address },
      ],
    },
    {
      title: "Título de Eleitor",
      className: "voterData",
      itens: [
        {
          name: "N° Inscrição",
          value: contact?.voterNumber,
          className: "number",
        },
        { name: "Zona", value: contact?.voterZone, className: "zone" },
        { name: "Seção", value: contact?.voterSection, className: "section" },
      ],
    },
  ];

  return !load ? (
    <div className="informations-chat">
      <Back>
        <h3>Detalhes</h3>
        <IoIosClose id="icon" onClick={() => window.history.back()} />
      </Back>
      <Container>
        <div id="profile">
          <Img id="img" src={photoUrl(contact)} />
          <h5 id="name">
            {contact.name ? contact.name : "@" + contact.nickname}
          </h5>
          {contact.name && <h5 id="nick">@{contact.nickname}</h5>}
        </div>
        {/* <Item>
                        <h3 className="title-item">Mídia</h3>
                        <HorizontalMidia>
                            <Midia />
                        </HorizontalMidia>
                    </Item> */}
        {datas.map(({ title, className, ...rest }, i) => {
          return (
            <Item className={className} key={i}>
              <h3 className="title-item">{title}</h3>
              <ItemData {...rest} />
            </Item>
          );
        })}
        <div className="btn-content">
          {!contact.isContact && (
            <Button
              color={theme.hover.green}
              onClick={() => push("/home/conversations/chat/addContact")}
            >
              <BiUserPlus size={20} id="icon" />
              Adicionar aos contatos
            </Button>
          )}

          <Button
            color={contact.iBlocked ? theme.hover.green : theme.hover.red}
            onClick={() => BlockUser()}
          >
            <BiBlock size={20} />
            {contact.iBlocked ? "Desbloquear" : "Bloquear"}
          </Button>
          {/*  <Button color={theme.hover.red}>
                            <FiAlertTriangle size={20} />
                            Denunciar
                        </Button> */}
          {contact.lastMessage && (
            <Button
              color={theme.hover.red}
              onClick={() => modalDelete.current.open()}
            >
              <BiTrashAlt size={20} />
              Apagar conversa
            </Button>
          )}
        </div>
        <DeletePersonal modalDelete={modalDelete} />
      </Container>
    </div>
  ) : (
    <MainLoad>
      <Load width="30px" />
      <h4 id="loading">Carregando...</h4>
    </MainLoad>
  );
};
const mapStateToProps = (state) => ({
  abare: state.abare.abare,
  contact: state.chat.globalMessages[getClientId(state.chat.personal)].client,
  socket: state.socket,
  theme: state.abare.theme,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setChat,
      updateClient,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(memo(Profile));
