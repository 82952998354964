import pt from "date-fns/locale/pt-BR";
import { forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { BsKeyFill } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import { MdViewColumn } from "react-icons/md";
import { InputSelect } from "../../../../../components";
import { InputControlled, InputSelectCreatable } from "../../../../../components/Input";
import { OptionSelect, ReportOperationFilter, ReportSelectFilter, ReportTypesValueFilter, TypeDataTable } from "../../../../../types/project";
import { ReportFilters } from "../../../../../util/report/reportFilters";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import { Element, ItemModal } from "../style";

interface ColumnsProps {
    isEditing?: boolean;
    header: HeaderTable;
    value: ReportTypesValueFilter;
    setValue: React.Dispatch<React.SetStateAction<ReportTypesValueFilter>>;
    columnSelect: string;
    setColumnSelect: React.Dispatch<React.SetStateAction<string>>;
    filterSelected: ReportSelectFilter;
    setFilterSelected: React.Dispatch<React.SetStateAction<ReportSelectFilter>>;
}

const FilterOptions = ({ isEditing = true, header, value, setValue, columnSelect, setColumnSelect, filterSelected, setFilterSelected }: ColumnsProps) => {
    const columnsTable = Object.keys(header.itens);
    const columnValues = columnsTable.map((column) => { return { value: column, label: column } });
    const [filterValues, setFilterValues] = useState<ReportSelectFilter[]>(ReportFilters.valuesLabel(header.itens[columnSelect].type));
    const booleanValues = [{ value: true, label: "Verdadeiro" }, { value: false, label: "Falso" }];

    const [inputValueTeste, setInputValueTeste] = useState('');

    useEffect(() => {
        const tempValues = ReportFilters.valuesLabel(header.itens[columnSelect].type);
        setFilterValues(tempValues);
        switch (header.itens[columnSelect].type) {
            case TypeDataTable.BOOLEAN:
                setValue(true);
                break;
            case TypeDataTable.NUMBER:
                setValue(0);
                break;
            case TypeDataTable.STRING:
                setValue("");
                break;
            case TypeDataTable.DATA:
            case TypeDataTable.DATA_BIRTHDAY:
                setValue(new Date());
                break;
        }
        setFilterSelected(tempValues[0]);

    }, [columnSelect]);

    registerLocale("pt", pt);
    setDefaultLocale("pt");
    type Props = {
        onClick?: () => void;
        value?: string;
    };
    type RefType = number;

    const DateCustomInput = forwardRef<RefType, Props>(
        ({ value, onClick }, ref) => (
            <input
                value={value}
                className="teste"
                onClick={onClick}
                readOnly={true}
            />
        )
    );
    const inputValueWithIcon = (children) => {

        return <Element $padding="0px">
            <div className="flex" style={{ minWidth: "100px", flex: "1" }}>
                <div style={{ marginRight: "4px" }}>
                    <BsKeyFill title="Valor" size="20px" />
                </div>
                {children}
            </div>
        </Element>
    }

    const inputValue = () => {
        const isAnyValue = filterSelected.value === ReportOperationFilter.IS_ANY_OF;
        const isValueArray = Array.isArray(value);
        switch (filterSelected.value) {
            case ReportOperationFilter.EMPTY:
            case ReportOperationFilter.NOT_EMPTY:
                return <p></p>
            default: { }
        }
        if (isEditing) {
            switch (header.itens[columnSelect].type) {
                case TypeDataTable.BOOLEAN:
                    return inputValueWithIcon(<InputSelect
                        $flex={true}
                        value={booleanValues.find((option) => (option.value === value))}
                        onChange={(e) => { setValue(e.value) }}
                        placeholder="Valor" options={booleanValues} />)

                case TypeDataTable.NUMBER:
                    if (isAnyValue)
                        return inputValueWithIcon(<InputSelectCreatable isNumber={true} dropdownIndicator={false} style={true} $flex={true} inputValue={inputValueTeste} setInputValue={setInputValueTeste} value={value as OptionSelect[]} setValue={setValue as React.Dispatch<React.SetStateAction<OptionSelect[]>>} />)
                    return inputValueWithIcon(<InputControlled type="number" value={value} onChange={(e) => setValue(e.target.value)} />)
                case TypeDataTable.STRING:
                    if (isAnyValue)
                        return inputValueWithIcon(<InputSelectCreatable dropdownIndicator={false} style={true} $flex={true} inputValue={inputValueTeste} setInputValue={setInputValueTeste} value={value as OptionSelect[]} setValue={setValue as React.Dispatch<React.SetStateAction<OptionSelect[]>>} />)
                    return inputValueWithIcon(<InputControlled type="text" value={value} onChange={(e) => setValue(e.target.value)} />)
                case TypeDataTable.DATA:
                case TypeDataTable.DATA_BIRTHDAY:
                    return inputValueWithIcon(
                        <DatePicker
                            selected={value}
                            onChange={(date: Date) => setValue(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            required={true}
                            showMonthDropdown
                            customInput={<DateCustomInput />}

                            showYearDropdown
                            yearDropdownItemNumber={30}
                            scrollableYearDropdown
                            selectsStart
                            className="teste"

                        />

                    )
                default: { }
            }
        } else {
            if (isAnyValue && isValueArray) {
                let message = ''
                if (value.length > 0) {
                    message = (value as OptionSelect[]).map(item => item.label).reduce((acm, value, index, array) => {
                        if (index == 0) {
                            return acm + value;
                        }
                        return acm + ', ' + value;
                    });
                }

                return inputValueWithIcon(<p>{message}</p>)

            }
            else if (typeof value == "object")
                return inputValueWithIcon(<p>{(value as Date).toLocaleDateString()}</p>)
            else if (typeof value == "boolean")
                return inputValueWithIcon(<p>{value ? "Verdadeiro" : "Falso"}</p>)

            else
                return inputValueWithIcon(<p>{value}</p>)

        }
    }
    return (
        <ItemModal >
            <div className="flex" style={{ minWidth: "175px", flex: "1" }}>
                <div className="icon">
                    <MdViewColumn title="Coluna" size="20px" />
                </div>
                {isEditing ?
                    <InputSelect
                        $flex={true}
                        value={columnValues.find((option) => (option.value === columnSelect))}
                        onChange={(e) => { setColumnSelect(e.value) }}
                        placeholder="Coluna" options={columnValues} />
                    :
                    <p>{columnValues.find((option) => (option.value === columnSelect))?.label}</p>
                }
            </div>
            <div className="flex" style={{ minWidth: "175px", flex: "1" }}>
                <div className="icon">
                    <FiFilter title="Tipo de filtro" size="20px" />
                </div>
                {isEditing ?
                    <InputSelect
                        $flex={true}
                        value={filterSelected}
                        onChange={(e) => {
                            const isValueArray = Array.isArray(value);
                            if (e.value == ReportOperationFilter.IS_ANY_OF && !isValueArray) {
                                setValue([]);
                            } else if (e.value !== ReportOperationFilter.IS_ANY_OF && isValueArray) {
                                setValue("");
                            }
                            setFilterSelected(e)
                        }}
                        placeholder="Tipo filtro" options={filterValues} />
                    :
                    <p>{filterSelected.label}</p>
                }
            </div>

            {inputValue()}
        </ItemModal>
    );
}

export default FilterOptions;