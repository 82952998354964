import { TEvent } from "../types/project";
import { getRandomColor } from "./verification";

export const listMyCategorys = () => {
  return [
    { value: 1, label: "Aniversário" },
    { value: 3, label: "Atividade" },
    { value: 4, label: "Compromisso" },
    { value: 2, label: "Reunião" },
    { value: 0, label: "Outros" },
  ];
};

const categorys = {
  "0": { title: "Outros", color: "#473C5F" },
  "1": { title: "Aniversário", color: "#036AF5" },
  "2": { title: "Reunião", color: "#09976D" },
  "3": { title: "Atividade", color: "#E25003" },
  "4": { title: "Compromisso", color: "#3B6309" },
};

const colors = ["#473C5F", "#036AF5", "#09976D", "#E25003", "#3B6309"];

export const getValueCategory = (event) => {
  if (event.category) {
    const ramdomNumber = Math.floor(Math.random() * 5);
    return { title: event.category.name, color: colors[ramdomNumber] };
  }
  if (categorys[event.type]) {
    return categorys[event.type];
  }
  return { title: "Não definido", color: "#E25003" };
};

export const getColorCategory = (event: TEvent) => {
  switch (event.type?.toString()) {
    case "0":
      return "#473C5F";
    case "1":
      return "#036AF5";
    case "2":
      return "#09976D";
    case "3":
      return "#E25003";
    case "4":
      return "#3B6309";
    default:
      return getRandomColor(event.EventCategory_id?.toString());
  }
};

export const getColorCategoryByCode = (type: string) => {
  switch (type) {
    case "0":
      return "#473C5F";
    case "1":
      return "#036AF5";
    case "2":
      return "#09976D";
    case "3":
      return "#E25003";
    case "4":
      return "#3B6309";
    default:
      return "#473C5F";
  }
};

export const getNameCategory = (type: string) => {
  switch (type) {
    case "0":
      return "Outros";
    case "1":
      return "Aniversário";
    case "2":
      return "Reunião";
    case "3":
      return "Atividade";
    case "4":
      return "Compromisso";
    default:
      return "Outros";
  }
};
