import styled from "styled-components";

interface StyledProps {
  lastLine?: boolean;
}

export const Row = styled.div<StyledProps>`
  font-family: "inter-medium", sans-serif;
  font-size: ${(props) => props.theme.fonts.text_lg};
  padding-bottom: ${(props) => (props.lastLine ? "0px" : "16px")};
  color: ${(props) => props.theme.texts.darkerGreyII};

  #input-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .date {
      width: 95px;
      margin-top: 5px;
    }
  }

  p {
    font-size: 10px;
    color: #92406d;
    display: flex;
    align-items: flex-end;
  }

  .line {
    margin-top: 5px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: 14px;
    color: ${(props) => props.theme.default.darkerI};
  }

  .text {
    text-align: center;
    font-family: "inter", sans-serif;
    font-size: 12px;
    margin-top: 8px;
  }

  .inputs {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .icon {
      margin-right: 8px;
    }
  }

  input:disabled {
    background-color: ${(props) => props.theme.backgrounds.white};
    color: ${(props) => props.theme.texts.darkerGreyI};
    border: none;
    font-size: ${(props) => props.theme.fonts.text_base};

    .cellItem {
      padding-top: 16px;

      .marginInput {
        margin: 36px;
      }
    }

    #codeSMS {
      display: none;
    }
  }

  label{
    :hover{
      cursor: pointer;
    }
  }

  .marginLeft{
    margin-left: 8px;
  }
`;

export const ModalRow = styled.div`
  font-family: "inter-medium", sans-serif;
  font-size: ${(props) => props.theme.fonts.text_lg};
  color: ${(props) => props.theme.texts.darkerGreyII};

  input:disabled {
    font-size: ${(props) => props.theme.fonts.text_base};
    color: ${(props) => props.theme.texts.darkerGrey};
    border: ${(props) => props.theme.backgrounds.white};
    background: ${(props) => props.theme.backgrounds.white};
  }

  .line {
    margin-top: 5px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: 14px;
    color: ${(props) => props.theme.default.darkerI};
  }

  .text {
    text-align: left;
    font-family: "inter", sans-serif;
    font-size: 12px;
    margin-top: 8px;
  }

  .inputs {
    display: flex;
    align-items: center;
    margin-top: 8px;

    .icon {
      margin-right: 8px;
    }
  }

  h3 {
    margin: 4px 0 0 0;
    font-weight: normal;
    font-size: ${(props) => props.theme.fonts.text_base};
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  .cellItem {
    font-size: ${(props) => props.theme.fonts.text_lg};
    padding-top: 16px;
    .marginInput {
      margin: 36px;
    }
  }

  h5 {
    margin: 0;
    margin-top: 1rem;
    font-weight: normal;
  }

  .disabledfalse {
    opacity: 60%;
    filter: grayscale(30%);
  }

  #codeSMS {
    display: none;
  }

  .code {
    color: ${(props) => props.theme.default.darkerI};
    h5 {
      margin: 0;
      margin-top: 1rem;
      font-weight: normal;
    }
  }

  .disabledfalse {
    opacity: 60%;
    filter: grayscale(30%);
  }
`;

export const HorizontalRow = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.texts.darkerGreyII};

  #bioSexM {
    height: 40px;
    width: 100px;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  #bioSexF {
    height: 40px;
    width: 100px;
    margin-bottom: 30px;
  }
`;

export const HorizontalAutorizacao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: normal;
  padding: 1rem 0px 1rem 0px;
  border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};
  border-top: 1px solid ${(props) => props.theme.bordes.lightGrey};
`;

export const Button = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  font-size: 16px;
  color: ${(props) => (props.color ? props.theme.green.darker : "#000")};
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;

export const PasswordPerfil = styled.div`
  font-family: "inter-medium", sans-serif;
  font-size: ${(props) => props.theme.fonts.text_lg};
  padding-left: 24px;
  color: ${(props) => props.theme.texts.darkerGreyII};

  .line {
    margin-top: 5px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: ${(props) => props.theme.fonts.text_sm};
    color: ${(props) => props.theme.texts.darkerGreyI};
  }
`;

export const ItemButton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  margin: 0px 0px 8px 0px;

  .HorizontalTitle {
    position: relative;
    justify-content: center;
  }
`;

export const ButtonBioSex = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  font-size: 16px;
  border: 1px solid ${(props) => props.theme.purple.dark};
  text-align: center;
  width: 7rem;
  height: 1.8rem;
  margin-top: 8px;
  margin-bottom: 2rem;
  margin-right: 24px;
  border-radius: 2px;
  color: #fff;
  background-color: ${(props) => props.theme.purple.dark};
`;

export const ButtonEdit = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.backgrounds.purpleDark};
  color: white; 
  margin-top: 10px;
  padding: 6px;
  border-radius: 100%;
  height: 15px;
  cursor: pointer;

  :hover {
    color: ${(props) => props.theme.hover.orange};
    background-color: ${(props) => props.theme.backgrounds.purpleDark};
  }
`;

export const ButtonPassWord = styled.button`
  display: block;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.theme.hover.red};
  border: 1px solid ${(props) => props.theme.hover.red};
  text-align: center;
  width: 8rem;
  height:100%;
  margin-top: 8px;
  margin-bottom: 2rem;
  margin-right: 24px;
  border-radius: 2px;

  :hover {
    color: ${(props) => props.theme.texts.white};
    background-color: ${(props) => props.theme.hover.red};
  }
`;

export const ButtonConfirmPassWord = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background-color: ${(props) => props.theme.hover.green};
  text-align: center;
  width: 8rem;
  height: 1.8rem;
  margin-top: 8px;
  margin-bottom: 2rem;
  border-radius: 2px;
`;

export const ButtonOk = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background-color: ${(props) => props.theme.purple.dark};
  text-align: center;
  width: 5rem;
  height: 1.8rem;
  margin-top: 8px;
  margin-bottom: 2rem;
  margin-right: 24px;
  border-radius: 8px;
`;
