import styled from "styled-components";
import { mobile } from "../../../../../components/NavBar/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 1rem;

  #title {
    color: ${(props) => props.theme.texts.purpleDark};
    margin-bottom: 0;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100px;
  }

  #options {
    display: flex;
  }

  .center-button {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
  }

  #icon {
    font-size: 18px;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  #icon-purple {
    font-size: 18px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .load {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${(props) => props.theme.texts.purpleDark};
    h4 {
      margin: 8px 0px;
    }
  }

  .iconChannel {
    font-size: 25px;
    align-self: center;
    color: ${(props) => props.theme.begin.color.button.secondary};
    margin-right: 8px;
  }

  .filterChannel {
    margin: 0px 1rem 1rem 1rem;
    display: flex;
    flex-direction: row;
  }
`;

export const OutlineButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: none;
  padding: 5px 8px;
  border-radius: 10px;
  border: solid 1px ${(props) => props.theme.texts.purpleDark};
  transition: box-shadow 0.3s;
  margin-right: 5px;

  .item {
    font-size: 20px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .right {
    margin-right: 4px;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    background-color: ${(props) => props.theme.texts.lightGrey};
  }
`;

export const Main = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-rows: 350px;

  @media only screen and (max-width: ${mobile}) {
    height: 100%;
    width: 100vw;
    grid-template-rows: 100%;
  }
`;

export const Scroll = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 12px 8px;
  padding-inline: 0.8rem;
  background-color: ${(props) => props.theme.backgrounds.white};

  #width {
    width: 90%;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }

  h5 {
    justify-self: start;
    margin: 0px;
    padding: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }
`;

export const HeaderBack = styled(Header)`
  grid-template-columns: 20px auto;
  padding: 1rem;
  padding-inline: 0.5rem;

  #back {
    justify-self: start;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Default = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;

  #profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    color: white;
    border: 1px solid ${(props) => props.theme.texts.darkerGreyII};
  }
`;

export const BtnFile = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: ${(props) => props.theme.backgrounds.white};
  border: 1px solid ${(props) => props.theme.texts.purpleDark};
  color: ${(props) => props.theme.texts.purpleDark};
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 50%;
`;

export const FormGroup = styled.form`
  padding-inline: 1rem;
`;

export const Menu = styled.div`
  display: flex;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;

  div {
    flex: 1;
    margin-left: 8px;
  }
  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }

  .margin {
    margin-left: 8px;
  }
  @media only screen and (max-width: ${mobile}) {
    h4 {
      display: none;
    }
    margin-bottom: 1rem;
  }
`;
