import styled from "styled-components";

interface StyledProps {
  data?: any;
}

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backgrounds.purpleLightI};

  .pd-b-0 {
    padding-bottom: 0px;
  }

  .toogle-status {
    font-size: 12px;
    color: ${(props) => props.theme.texts.darkerGrey};
    text-align: left;
    margin: 0px 2.5rem 0px 2.5rem;
  }

  .toogle-status-off {
    visibility: hidden;
  }

  .div-notify {
    padding: 0rem 1rem 0.5rem 1rem;
  }

  .div-silence {
    min-height: 55px;
    padding: 0rem 1rem 0.5rem 1rem;
  }

  .toogle-text {
    font-size: 14px;
    color: ${(props) => props.theme.texts.darkerGreyII};
    padding: 0rem 1rem;
    margin: 0px;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #informations-group {
    overflow-y: auto;
    background-color: ${({ theme }) => theme.backgrounds.white};
  }
  #profile {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background-color: ${(props) => props.theme.backgrounds.white};
  }

  h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: "inter-medium", sans-serif;
  }
  #name {
    font-size: ${(props) => props.theme.fonts.text_xl};
    color: ${(props) => props.theme.texts.darkerGreyI};
  }

  #number-members {
    color: ${(props) => props.theme.texts.darkGrey};
    font-size: ${(props) => props.theme.fonts.text_base};
  }

  #nick {
    color: ${(props) => props.theme.texts.darkGrey};
    font-size: 14px;
  }

  #img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding-inline: 0.5rem;
  }

  .m-0 {
    margin: 0px;
    font-size: 5px;
  }
`;

export const Circle = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  font-size: 20px;
  color: ${(props) => props.theme.backgrounds.white};
  cursor: pointer;
  transition: 0.1s ease-in;

  &:hover {
    filter: brightness(80%);
  }

  justify-content: center;
  align-items: center;
`;

export const Back = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.backgrounds.white};
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.backgrounds.secondaryToolbar};
  min-height: ${({ theme }) => theme.heightToolbar};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
  position: sticky;
  top: 0;

  h3 {
    font-size: ${(props) => props.theme.fonts.text_2xl};
    font-family: ${({ theme }) => theme.fonts.family_toolbar};
    margin: 0px;
    color: ${({ theme }) => theme.texts.secondaryToolbar};
  }

  #icon {
    font-size: ${(props) => props.theme.fonts.text_3xl};
    cursor: pointer;
    color: ${({ theme }) => theme.texts.secondaryToolbar};
  }

  #icon-back {
    font-size: 18px;

    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
  }
`;

export const Item = styled.div<StyledProps>`
  background-color: ${(props) => props.theme.backgrounds.white};
  color: ${(props) => props.theme.texts.black};
  font-family: "inter-medium", sans-serif;
  font-size: ${(props) => props.theme.fonts.text_lg};
  padding: 0rem 1rem;
  margin: 0.4rem 0px;

  #midia {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  #icon {
    font-size: 22px;
  }

  .description {
    font-family: "inter", sans-serif;
    color: ${(props) => props.theme.texts.darkGrey};
    font-size: ${(props) => props.theme.fonts.text_base};
    margin: 0px;
    padding: 0.7rem 0rem;
  }
`;

export const ItemNopd = styled(Item)`
  padding: 0px;
  background-color: ${(props) => props.theme.backgrounds.white};
  a {
    background-color: inherit;
    text-decoration: none;
    color: inherit;
  }

  .disabled {
    pointer-events: none;
  }
  .container {
    font-family: "inter", sans-serif;
  }
`;

export const HorizontalMidia = styled.div`
  display: flex;
  overflow-x: hidden;

  div {
    display: grid;
    grid-template-columns: 70px 70px 70px 70px 70px;
    grid-template-rows: 70px;
    align-items: center;
    overflow: hidden;
    width: 100%;
    max-height: 70px;
    border-radius: 3px;

    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 4px;
      justify-self: center;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  display: grid;
  grid-template-columns: 45px auto;
  padding: 1rem 1rem;
  margin: 0.1rem 0rem;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.text_base};
  background-color: ${(props) => props.theme.backgrounds.white};
  color: ${(props) =>
    props.color
      ? props.theme.green.darker
      : props.theme.title == "light"
      ? "#C10000"
      : "#c13232"};
  text-align: left;
  border-top: 1px solid ${(props) => props.theme.bordes.lightGreyI};

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGrey};
  }

  #icon {
    font-size: 18px;
  }
`;
interface OptionProps {
  noHover?: boolean;
}
export const Option = styled.div<OptionProps>`
  display: grid;
  grid-template-columns: 25px auto 25px;
  padding: 0rem 1rem 0.5rem 1rem;
  align-items: center;
  font-size: 14px;
  margin: 0px;
  cursor: pointer;

  .div-option {
    padding: 0.7rem 2rem 0.7rem 0.9rem;
  }

  .option-description {
    font-size: ${(props) => props.theme.fonts.text_xs};
    color: ${(props) => props.theme.texts.darkGrey};
    margin: 0px;
  }

  .option-text {
    font-size: ${(props) => props.theme.fonts.text_base};
    color: ${(props) => props.theme.texts.darkerGreyII};
    margin: 0px;
  }

  ${(props) =>
    !props.noHover &&
    ` &:hover {
    background-color: ${props.theme.hover.lightGrey};
  } `};

  img {
    color: ${(props) => props.theme.texts.purpleDark};
  }
`;

export const OptionNoHv = styled(Option)`
  cursor: default;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: ${(props) => props.theme.backgrounds.white};
  }
`;
export const ContainerList = styled.div`
  overflow-y: auto;
  max-height: 270px;
`;

export const User = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: 50px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: 1px solid ${(props) => props.theme.bordes.lightGrey};
  cursor: pointer;

  .add {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.purple.dark};
    border-radius: 50%;
    height: 40px;
    width: 40px;

    #icon {
      color: #fff;
      font-size: 20px;
    }
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .name {
    color: ${(props) => props.theme.texts.darkerGreyII};
    font-size: ${(props) => props.theme.fonts.text_base};
  }

  .add-name {
    font-family: "inter-medium", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;

export const Office = styled.div`
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: ${(props) => props.theme.fonts.text_lg};
  padding: 1rem 1rem 0.5rem 1rem;
  color: ${(props) => props.color};

  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.texts.purpleDark};
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
    #icon {
      color: ${(props) => props.theme.backgrounds.white};
      font-size: 20px;
    }
  }

  .two-colums {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 35px auto;
  }
`;

export const NoInfo = styled.div<StyledProps>`
  display: ${(props) => (props.data ? "none" : "block")};
  text-align: center;
  padding: 0.5rem;
  font-size: 14px;
  color: ${(props) => props.theme.texts.darkGrey};
  font-family: "inter", sans-serif;
`;

export const MainLoad = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: ${(props) => props.theme.texts.purpleDarkTitle};
  border-left: 1px solid ${(props) => props.theme.bordes.lightGreyI};

  #loading {
    margin: 6px 0px;
  }
`;

export const Notifications = styled.div`
  margin-left: -15px;
  min-height: 55px;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;

  .text-toogle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  .toogle {
    margin-top: -10px;
    position: absolute;
    margin-left: 250px;
  }

  p {
    margin-top: -12px;
    margin-bottom: -10px;
    margin-left: 17px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: 10px;
    padding-right: 230px;
    padding-bottom: 10px;
    color: ${(props) => props.theme.texts.darkGreyI};
  }
`;
