import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StorageClusterSharingService } from "../../../../services/requests/storage-cluster-sharing";
import { StorageMediaSharingService } from "../../../../services/requests/storage-media-sharing";
import { Queries } from "../../../../types/queries";
import { TypeConstRedux } from "../../../../types/redux";

export interface DetailsItem {
  id: string;
  name: string;
  email: string;
  url: string;
  level: boolean;
}
interface UseFilesRepository {
  items: DetailsItem[];
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export const UseDetailsItem = (
  id: string | undefined,
  type: "file" | "folder" | undefined
): UseFilesRepository => {
  const { fileSelected, itemSelected, folderSelected } = useSelector(
    (store: TypeConstRedux) => store.repository
  );

  const selectFunction = () => {
    if (!id) {
      return;
    } else {
      if (type == "file") {
        return StorageMediaSharingService.getDetails(id);
      } else {
        return StorageClusterSharingService.getDetails(id);
      }
    }
  };

  const {
    data: items = [],
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useQuery([Queries.REPOSITORY_DETAILS_ITEM, id], selectFunction, {
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    enabled: id !== undefined,
  });

  return {
    items,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  };
};
