import { Socket } from "socket.io-client";
import { DefaultTheme } from "styled-components";
import {
  FileRepository,
  FolderRepository,
  OptionConfig,
  TClient,
  TCluster,
  TContact,
  TMessage,
  TypeMessage,
} from "./project";

export type TypeConstRedux = {
  abare: TypeReduxAbare;
  socket: Socket;
  folder: TFolder;
  chat: TChatRedux;
  contacts: {
    list: TContact[];
  };
  dashboard: any;
  schedule: TypeReduxSchedule;
  repository: TStorageCluster;
  config: ReducerConfig;
};

export type TFolder = {
  list: TCluster[];
  ativa: string | null;
};

export type ReducerConfig = {
  option: OptionConfig | null;
};

export type TypeReduxAbare = {
  abare: TAbareInfo;
  theme: DefaultTheme;
  navBar: boolean;
  navBarMinimized: boolean;
  folder: any;
};
export interface TAbareInfo {
  id: string;
  photoUrl: string | null;
  nickname: string;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  cpf: string | null;
  cnpj: string | null;
  birthday: string | null;
  voterNumber: string | null;
  voterZone: string | null;
  voterSection: string | null;

  privacy: any;
  packageInfo: TPackageInfo;
}

export enum EnumScheduleType {
  PERSONAL,
  GROUP,
}
export type TypeReduxSchedule = {
  commitment: any;
  invitation: any;
  filter: EnumScheduleType;
  dateSelect: Date;
  categoriesVisible: number[];
};

// export type TFolder = {
//   list: TCluster[];
//   ativa: string | null;
// };

export interface TPackageInfo {
  name: string;
  startAt: Date;
  finishAt: Date;
  hdLimit: number;
  hdUsed: number;
  map: boolean;
  group: number;
  canal: number;
  fileSend: number;
  mediaSend: number;
  messageSend: boolean;
  messageRec: boolean;
  eventFile: boolean;
  eventCategory: boolean;
  relatory: boolean;
  contactLoad: boolean;
  fileManeger: boolean;
  partnership: boolean;
}

export type TChatRedux = {
  personal: TClient;
  answer: TMessage;
  globalMessages: TGlobalMessages[];
  details: boolean;
};

export type TGlobalMessages = {
  client: TClient;
  messages: TMessage[];
  currentPage: any;
  loadPage: any | null;
  scroll: any | null;
  totalItems: number | null;
  totalPages: number;
};
export type TClusterRepository = {
  id: number;
  name: string;
  invite: null | string;
  sharing: number;
  createdAt: Date;
  editedAt: Date;
  User_id: string;
  StorageCluster_id: string | null;
  TClusterRepository: undefined | TClusterRepository[];
};
export enum EnumTypeRepository {
  MY_SPACE,
  SHARE_WITH_ME,
  SHARE_WITH_FRIENDS,
  CONVERSATION,
  SCHEDULE,
  REPORT,
}
export type TStorageCluster = {
  folder: FolderRepository[];
  fileSelected: undefined | FileRepository;
  folderSelected: undefined | FolderRepository;
  itemSelected: "file" | "folder" | undefined;
  filters: {
    types: TypeMessage[];
  };
  typeSelect: EnumTypeRepository;
};
