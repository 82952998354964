import { useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { BiLockAlt } from "../../../../../assets/icons";
import { InputPassword, Modal } from "../../../../../components";
import ModalContainer from "../../../../../components/Modal/ModalContent";
import { patchUpdatePassword } from "../../../../../services/requests/user";
import { setAbare } from "../../../../../store/actions/abareAction";
import { ToastModel } from "../../../../../util/error";
import { ButtonConfirmPassWord, ButtonOk, ButtonPassWord, Row } from "../style";

const ChangePassword = (props) => {
    const { abare, modalEditPassWord } = props;
    const [confirm, setConfirm] = useState(false)
    const { addToast } = useToasts();

    const confirmNewPassword = e => {
        e.preventDefault()
        const [password, newPassword, confirmPassword] = e.target.elements;

        if (newPassword.value == confirmPassword.value) {
            patchUpdatePassword(abare.id, password.value, newPassword.value).then((res) => {
                if (res.status == 200) {
                    setConfirm(true)
                } else {
                    res.status == 401 && ToastModel(addToast, 'Senhas atual incorreta!', "Digite com cuidado e tente novamente.", "error");
                }

            })
        } else {
            ToastModel(addToast, 'Senhas diferentes!', "A nova senha e a senha de confirmação precisam ser iguais.", "warning");
        }
    }

    return (
        <Modal ref={modalEditPassWord}>
            <ModalContainer title="Alterar senha" onClick={() => modalEditPassWord.current.close()}>
                {confirm ?
                    <div>
                        <div className="textConfirmPassWord">
                            Senha alterada com sucesso!
                        </div>
                        <div className="horizontalButtons">
                            <ButtonOk onClick={() => { modalEditPassWord.current.close() }}>
                                OK
                            </ButtonOk>
                        </div>
                    </div>
                    :
                    <div>
                        <form onSubmit={confirmNewPassword}>
                            <Row>
                                <InputPassword Icon={BiLockAlt} name="password" type="password" required placeholder="Digite a senha atual" label="Senha atual" />
                            </Row>
                            <Row>
                                <InputPassword barra={true} Icon={BiLockAlt} name="newPassword" type="password" required placeholder="Digite sua nova senha" label="Nova senha" />
                                <label className="msg">(mínimo de 8 caracteres) </label>
                            </Row>
                            <Row>
                                <InputPassword Icon={BiLockAlt} name="confirmPassword" type="password" required placeholder="Confirme a senha" label="Confirmar nova senha" />
                            </Row>
                            <div className="horizontalButtons">
                                <ButtonPassWord onClick={(event) => { event.preventDefault(); modalEditPassWord.current.close() }}>
                                    Cancelar
                                </ButtonPassWord>
                                <ButtonConfirmPassWord type="submit">
                                    Confirmar
                                </ButtonConfirmPassWord>
                            </div>
                            <div className="texto">
                                Como recomendação, defina uma senha forte para manter a sua conta livre de acessos indesejados
                            </div>
                        </form>
                    </div>
                }
            </ModalContainer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toChangeAbare(newAbare) {
            dispatch(setAbare(newAbare));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);