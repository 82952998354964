
import moment from 'moment';
import 'moment/locale/pt-br'; // Importe o idioma pt-BR do Moment.js
import { Calendar, SlotInfo, View, momentLocalizer } from 'react-big-calendar';

import { useCallback, useEffect, useRef, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { BsFillCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { ModalRef } from '../../../../../components/Modal';
import ModalEvent from '../../../../../components/Modal/ModalEvent';
import { ShimmerCard } from '../../../../../components/Shimmers';
import { setDateSelect } from '../../../../../store/actions/scheduleAction';
import { TEvent } from '../../../../../types/project';
import { EnumScheduleType, TypeConstRedux } from '../../../../../types/redux';
import { getColorCategory } from '../../../../../util/categoryUtils';
import EventDetailsModal from '../EventDetailsModal';
import CustomToolbar from './CustomToolbar';
import Year from './Year'; // Importe o componente Year personalizado
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
// Traduções das opções em português
const messages = {
    allDay: 'Dia Inteiro',
    previous: 'Anterior',
    next: 'Próximo',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    showMore: (total) => `+${total} mais`,

};

interface CalendarProps {
    isLoading: boolean;
}

const MyCalendar = ({ isLoading }: CalendarProps) => {
    const [selectedEvent, setSelectedEvent] = useState<TEvent | null>(null);
    const [modalPosition, setModalPosition] = useState<any>({});
    const modalRef = useRef<HTMLDivElement>(null);
    const { commitment, categoriesVisible, filter, dateSelect } = useSelector((state: TypeConstRedux) => state.schedule);
    const convertedEvents = convertEvents(commitment);
    const isGroupAgenda = (filter === EnumScheduleType.GROUP);
    const dispatch = useDispatch();
    const modalEvent = useRef<ModalRef>(null);
    const dateMockStart = new Date();
    const dateMockEnd = new Date();
    dateMockEnd.setHours(dateMockEnd.getHours() + 1);
    const [selectedEventElement, setSelectedEventElement] = useState<DOMRect | null>(null);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [viewSelect, setViewSelect] = useState<View>("month");
    const handleSelectSlot = useCallback(
        (slotInfo: SlotInfo) => {
            dispatch(setDateSelect(slotInfo.start));
            setSelectedEvent(null);
            setStartDate(slotInfo.start);
            setEndDate(slotInfo.end);
            modalEvent.current?.open();
        }, []
    )

    const defaultEvent: any[] = [{
        end: dateMockEnd,
        start: dateMockStart,
        description: "",
        id: 0,
        title: "",

    }, {
        end: dateMockEnd,
        start: dateMockStart,
        description: "",
        id: 1,
        title: "",

    }]
    const EventoPersonalizado = ({ event }) => {
        const warning = event.participation === null && isGroupAgenda;
        return (isLoading ?
            <ShimmerCard onClick={(e) => e.stopPropagation()} height={"25px"} />
            :
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p className='title-event'>{event.title}</p>
                {warning && <BsFillCircleFill id="icon_read" size={10} />}
            </div>
        );
    };
    function convertEvents(events) {
        return events ? events.map((event) => ({
            ...event,
            start: new Date(event.dateStart),
            end: new Date(event.dateEnd)
        })).filter((event) => {
            if (filter == EnumScheduleType.GROUP) {
                return event.Group_id ? true : false;

            } else {
                return ((!event.Group_id || event.participation)) ? true : false;
            }
        }).filter(filterEvent) : [];
    };

    function filterEvent(event) {
        return categoriesVisible.some((category) => (event.type && category == event.type) || (event.EventCategory_id && category == event.EventCategory_id))
    }
    const handleNavigate = (date: Date, view: View) => {
        dispatch(setDateSelect(date));
    };
    const handleShowMore = (eventList) => {
        setViewSelect("week");
        // Lógica adicional que você deseja executar ao exibir mais eventos
    };

    const handleSelectEvent = useCallback(
        (event: any, e: React.SyntheticEvent<HTMLElement>) => {
            e.stopPropagation();
            console.log("oiii")
            setSelectedEventElement(e.currentTarget.getBoundingClientRect());
            setStartDate(undefined);
            setEndDate(undefined);
            setSelectedEvent(event);
        }, [])

    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: isLoading ? "inherit" : getColorCategory(event), // Usando a cor definida em cada evento
            borderRadius: '5px',
            opacity: 0.8,
            zIndex: 999,
            color: 'white',
            border: 'none',
            display: 'block',
        };

        return {
            style,
        };
    };

    useEffect(() => {
        if (selectedEventElement && selectedEvent) {
            console.log(selectedEventElement)
            const rect = selectedEventElement;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            console.log(rect)
            console.log(window)
            const modalWidth = modalRef.current?.offsetWidth ?? 300; // Largura estimada do modal
            const modalHeight = modalRef.current?.offsetHeight ?? 200; // Altura estimada do modal
            console.log(modalRef)
            let position: any = {};

            if (rect.top + rect.height + modalHeight > windowHeight) {
                position.top = rect.top - modalHeight;
            } else {
                position.top = rect.top;

            }

            if (rect.left + rect.width + modalWidth + 8 < windowWidth) {
                position.left = rect.right + 8;
            } else if (rect.left - 8 - modalWidth > 0) {
                position.right = windowWidth - rect.left + 8;
            } else {
                position.left = (windowWidth - 300) / 2

                // position.left = 16
                // position.right = windowWidth - 16
                // position.width = windowWidth - 64
            }
            if (modalPosition.left == 0 && modalPosition.top == 0) {
                position.transition = false;
            } else {
                position.transition = true;

            }
            console.log(position)
            console.log(modalPosition)
            setModalPosition(position);
        } else {
            setModalPosition({ top: 0, left: 0, transition: false });
        }
    }, [selectedEvent, modalRef.current])
    // useEffect(() => {
    //     const handleWindowResize = () => {
    //         // Recalcula a posição do modal ao redimensionar a janela
    //         if (selectedEvent) {
    //             const rect = selectedEvent.target.getBoundingClientRect();
    //             const windowWidth = window.innerWidth;
    //             const windowHeight = window.innerHeight;

    //             const modalWidth = 300; // Largura estimada do modal
    //             const modalHeight = 200; // Altura estimada do modal

    //             const position = {
    //                 top: rect.top + rect.height + modalHeight > windowHeight ? rect.top - modalHeight : rect.top + rect.height,
    //                 left: rect.left + rect.width + modalWidth > windowWidth ? rect.left - modalWidth : rect.left + rect.width,
    //             };

    //             setModalPosition(position);
    //         }
    //     };

    //     window.addEventListener('resize', handleWindowResize);

    //     return () => {
    //         window.removeEventListener('resize', handleWindowResize);
    //     };
    // }, [selectedEvent]);

    const handleCloseModal = () => {
        setSelectedEvent(null);
    };
    return <div className="myCustomHeight">
        <Calendar
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            selectable
            onNavigate={handleNavigate}
            onShowMore={handleShowMore} // Função para controlar a ação do botão "More"
            date={dateSelect}
            components={{
                toolbar: (toolbarProps) => (
                    <CustomToolbar
                        {...toolbarProps}
                        setView={setViewSelect}
                    />),
                event: EventoPersonalizado

            }}
            view={viewSelect}
            eventPropGetter={eventStyleGetter} // Definindo o getter de estilos de evento
            messages={messages}
            views={{
                month: true,
                week: true,
                day: true,
                agenda: Year, // Use o componente Year personalizado
            }}
            events={isLoading ? defaultEvent : convertedEvents}
        />
        <ModalEvent dateEnd={endDate} dateStart={startDate} modal={modalEvent} event={selectedEvent} />

        {selectedEvent && (
            <EventDetailsModal
                transition={modalPosition.transition}
                modalRef={modalRef}
                event={selectedEvent}
                onClose={handleCloseModal}
                style={modalPosition}
            />
        )}
    </div>
}

export default MyCalendar;