import { api } from '../api';

export const getBlockRegister = (token) => {
    return api({
        url: "/user-register/find-block",
        method: "GET",
        headers: {
            Authorization: "Bearer " + token
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};