import styled from "styled-components";
import { mobile } from "../../../src/components/NavBar/style";

export const TwoCol = styled.div`
  display: grid;
  grid-template-columns: auto 15px auto;
`;

interface containerProps {
  agenda?: boolean;
}

export const ContainerInput = styled.div<containerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.backgrounds.blueLight};
  border: 0.1px solid ${(props) => props.theme.bordes.input};
  border-radius: 6px;
  padding-inline: 0.5rem;

  .moreRows {
    height: auto;
    min-height: 50px;
  }
  #timer {
    margin: 0;
    font-size: 13px;
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  h5 {
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  .phone-width {
    width: 100%;
  }

  #icon {
    color: ${(props) => props.theme.purple.dark};
    padding-inline: 0.2rem;
  }

  #eye {
    font-size: 19px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.darkerGrey};

    &:hover {
      color: ${(props) => props.theme.hover.input};
    }
  }

  #send {
    font-size: 15px;
    background: none;
    color: ${(props) => props.theme.texts.darkerGreyI};
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.hover.input};
    }
  }

  input,
  textarea {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 40px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 13px;
    color: ${(props) => props.theme.backgrounds.darkI};

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: ${(props) =>
        props.agenda
          ? props.theme.backgrounds.perfilNaveBar
          : props.theme.texts.darkerGreyII};
      opacity: 1;
      -webkit-text-fill-color: ${(props) =>
        props.agenda
          ? props.theme.backgrounds.perfilNaveBar
          : props.theme.texts.darkerGreyII};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: ${(props) =>
        props.agenda
          ? props.theme.backgrounds.perfilNaveBar
          : props.theme.texts.darkerGreyII};
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) =>
        props.agenda
          ? props.theme.backgrounds.perfilNaveBar
          : props.theme.texts.darkerGreyII};
    }

    &:focus {
      -webkit-text-fill-color: ${(props) =>
        props.agenda
          ? props.theme.backgrounds.perfilNaveBar
          : props.theme.texts.darkerGreyII};
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus-within {
    color: ${({theme}) => theme.texts.purpleDark};
    -webkit-text-fill-color:  ${({theme}) => theme.texts.purpleDark};
    border: 0.1px solid  ${({theme}) => theme.texts.purpleDark};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: auto;
    padding-top: 0.7rem;
  }
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @media only screen and (max-width: ${mobile}) {
    position: absolute;
    height: 100%;
  }
  .loading {
    height: 100%;
    width: 100%;
  }

  .map {
    height: 100%;
    width: 100%;
  }

  .map-marker {
    margin-top: -30px;
  }

  .address {
    box-sizing: border-box;
    width: 240px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    position: absolute;
    left: 50%;
    margin-left: -120px;
    margin-top: 12px;
  }

  .addressField {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    padding: 0 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
  }

  .margin-box {
    margin: 4px 0;
  }

  .bold {
    font-weight: bold;
  }

  .margin-atividade {
    margin-left: 16px;
  }
`;

export const Window = styled.div`
  background-color: white;
  height: calc(100% - 1rem);
  position: absolute;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;
  z-index: 1000;
  background-color: ${(props) => props.theme.backgrounds.purpleLightI};
  border-radius: 15px 15px 0 0;

  display: grid;
  overflow-y: hidden;
  overflow-x: hidden;
  border-left: 1px solid ${(props) => props.theme.default.lightI};

  .background {
    width: 300px;
    height: 100%;
    background: rgba(112, 5, 64, 0.05);
    display: flex;
    overflow-y: auto;
    flex-direction: column;
  }
  .scroll {
    overflow-y: auto;
  }

  @media only screen and (max-width: ${mobile}) {
    border-radius: 0;
    height: calc(100vh - 58px);
    width: 100vw;
    margin-top: 0px;
    margin-right: 0px;
    .background {
      width: 100%;
      height: 100%;
    }
  }

  .icon {
    font-size: 20px;
    margin-right: 4px;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
  }

  .pd-b-0 {
    padding-bottom: 0px;
  }

  .toogle-status {
    font-size: 12px;
    color: ${(props) => props.theme.default.darker};
    text-align: left;
    margin: 0px 2.5rem 0px 2.5rem;
  }

  .toogle-status-off {
    visibility: hidden;
  }

  .div-notify {
    padding: 0rem 1rem 0.5rem 1rem;
  }

  .div-silence {
    min-height: 55px;
    padding: 0rem 1rem 0rem 1rem;
  }

  .toogle-text {
    font-size: 14px;
    color: ${(props) => props.theme.default.black};
    padding: 0rem 1rem;
    margin: 0px;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background-color: ${(props) => props.theme.backgrounds.white};
  }

  h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: "inter-medium", sans-serif;
  }
  #name {
    color: ${(props) => props.theme.texts.darkerGreyI};
    font-size: 19px;
  }

  #number-members {
    color: ${(props) => props.theme.default.dark};
    font-size: 14px;
  }

  #nick {
    color: ${(props) => props.theme.default.darker};
    font-size: 14px;
  }

  #img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding-inline: 0.5rem;
  }

  .m-0 {
    margin: 0px;
    font-size: 5px;
  }
`;

export const Circle = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:hover {
    filter: brightness(80%);
  }

  justify-content: center;
  align-items: center;
`;

export const Back = styled.div`
  display: grid;
  grid-template-columns: 25px auto 20px;
  align-items: center;
  margin: 0;
  border-radius: 15px 15px 0 0;
  padding: 1rem 1rem;
  font-size: 14px;
  background: rgba(112, 5, 64, 0.05);

  @media only screen and (max-width: ${mobile}) {
    border-radius: 0;
  }

  h3 {
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  #icon {
    font-size: 25px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  #icon-back {
    font-size: 18px;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
  }
`;

export const HorizontalMidia = styled.div`
  display: flex;
  overflow-x: hidden;

  div {
    display: grid;
    grid-template-columns: 70px 70px 70px 70px 70px;
    grid-template-rows: 70px;
    align-items: center;
    overflow: hidden;
    width: 100%;
    max-height: 70px;
    border-radius: 3px;
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 4px;
      justify-self: center;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  display: grid;
  grid-template-columns: 25px auto;
  padding: 1rem 1rem;
  margin: 0.1rem 0rem;
  align-items: center;
  font-size: 15px;
  background-color: #fff;
  color: ${(props) => (props.color ? props.theme.green.darker : "#C10000")};
  text-align: left;
  border-top: 1px solid ${(props) => props.theme.default.ligtherI};
  &:hover {
    background-color: ${(props) => props.theme.default.ligtherI};
  }

  #icon {
    padding-left: 2px;
    font-size: 18px;
  }
`;

interface StyledProps {
  data?: any;
}

export const Item = styled.div<StyledProps>`
  background-color: ${(props) => props.theme.backgrounds.white};
  color: ${(props) => props.theme.texts.purpleDarkTitle};
  font-family: "inter-medium", sans-serif;
  font-size: 16px;
  padding: 1rem 1rem;
  margin: 0.2rem 0px 0px;

  #midia {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  #icon {
    font-size: 22px;
  }

  .description {
    font-family: "inter", sans-serif;
    color: ${(props) => props.theme.default.darker};
    font-size: 14px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0.5rem 0rem;
  }

  .location {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon {
      color: #ff9e02;
    }
  }
`;

export const ItemNopd = styled(Item)`
  padding: 0px;
  background-color: ${(props) => props.theme.backgrounds.white};

  .container {
    font-family: "inter", sans-serif;
  }

  .margin {
    margin-left: 1rem !important;
  }

  .padding {
    padding-bottom: 1.2rem !important;
    padding-top: 0px !important;
  }
`;

export const Option = styled.div`
  display: grid;
  grid-template-columns: 25px auto 25px;
  padding: 0rem 1rem 0.5rem 1rem;
  align-items: center;
  font-size: 14px;
  margin: 0px;
  cursor: pointer;

  .div-option {
    padding: 0.7rem 2rem 0.7rem 0.9rem;
  }

  .option-description {
    font-size: 12px;
    color: ${(props) => props.theme.default.darker};
    margin: 0px;
  }

  .option-text {
    font-size: 14px;
    color: ${(props) => props.theme.default.black};
    margin: 0px;
  }

  &:hover {
    background-color: ${(props) => props.theme.default.ligtherI};
  }
`;

export const OptionNoHv = styled(Option)`
  cursor: default;
  margin: 0;
  padding: 0;
  &:hover {
    background-color: #fff;
  }
`;

export const User = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: 50px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: 1px solid ${(props) => props.theme.bordes.lightGrey};
  cursor: pointer;

  .add {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.purple.dark};
    border-radius: 50%;
    height: 40px;
    width: 40px;

    #icon {
      color: #fff;
      font-size: 20px;
    }
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .name {
    color: ${(props) => props.theme.texts.darkerGreyII};
    font-size: 15px;
  }

  .div_funcoes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${(props) => props.theme.texts.darkerGreyII};
    font-size: 15px;

    p {
      margin: 0;
    }

    .funcoes {
      font-family: "inter", sans-serif;
      color: ${(props) => props.theme.default.darker};
      font-size: 12px;
      margin-right: 1rem;
    }
  }

  .add-name {
    font-family: "inter-medium", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;

export const Office = styled.div`
  display: grid;
  grid-template-rows: 30px;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
  color: ${(props) => props.color};

  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.texts.purpleDark};
    border-radius: 50%;
    height: 30px;
    width: 30px;
    cursor: pointer;
    #icon {
      color: ${(props) => props.theme.backgrounds.white};
      font-size: 15px;
    }
  }

  .two-colums {
    display: grid;
    grid-template-columns: 35px auto;
  }
`;

export const NoInfo = styled.div<StyledProps>`
  display: ${(props) => (props.data ? "none" : "block")};
  text-align: center;
  padding: 0.5rem;
  font-size: 14px;
  color: ${(props) => props.theme.default.darker};
  font-family: "inter", sans-serif;
`;

interface LoadProps {
  load?: boolean;
  window?: boolean;
}

export const MainLoad = styled.div<LoadProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  color: ${(props) => props.theme.purple.dark};
  border-left: 1px solid ${(props) => props.theme.default.lightI};
  ${({ load }) =>
    load &&
    ` overflow: none;
  width: 175px; border: none;`}

  #loading {
    margin: 6px 0px;
  }

  ${({ window }) =>
    !window &&
    ` @media only screen and (max-width: ${mobile}) {
      position: absolute;
      height: 100%;
      width: 100%;
      background: white;
    }`}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25vw;
  min-width: 175px;

  .lideres {
    display: flex;
    flex-direction: row;
  }
  .p_lideres {
    margin: 0px 0px 2px 4px;
  }
  .title-pasta {
    padding: 0px;
    margin: 0px;
    padding-bottom: 8px;
    font-weight: lighter;
  }

  .noMargin {
    padding: 0px;
    margin: 0px;
  }

  .campo-contato {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .button-completo {
    color: gray;
    margin: 0px;
    padding: 0;
    margin-top: 1rem;
    align-self: flex-start;
    font-size: 0.7rem;
  }

  .buttons {
    display: flex;
    margin-top: 12px;
    margin-bottom: 4px;
    button,
    a {
      color: black;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 1;
      :hover {
        color: ${(props) => props.theme.backgrounds.buttonSchedule};
      }
    }
  }
`;

export const ContainerBack = styled.button`
  width: 35px;
  height: 35px;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 10;
  left: 5px;
  bottom: 35px;
  position: absolute;
  background-color: ${(props) => props.theme.backgrounds.purpleDark};
  border-radius: 50%;
  transition: all ease-in-out 300ms;

  @media only screen and (min-width: ${mobile}) {
    display: none;
  }
  .icon {
    font-size: 20px;
  }
  &:hover {
    opacity: 1;
    color: ${(props) => props.theme.default.light};
  }
`;

export const ContainerNoPlace = styled.div`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.texts.purpleDark};
`;
