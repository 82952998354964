import styled from "styled-components";

export const Button = styled.button`
  padding: 0.7rem;
  padding-inline: 2.5rem;
  cursor: pointer;
  color: ${(props) => props.theme.texts.white};
  border-radius: 30px;
  background: linear-gradient(
    267.35deg,
    ${(props) => props.theme.backgrounds.buttonModalGroup1} 0.4%,
    ${(props) => props.theme.backgrounds.buttonModalGroup2} 100%
  );
  margin-top: 0.7rem;
`;

export const ButtonArrow = styled.button`
  display: block;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.theme.backgrounds.white};
  padding-inline: 0.5rem;

  #icon {
    font-size: 30px !important;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  #icon-purple {
    font-size: 30px !important;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }
`;
