import styled from "styled-components";

export const Container = styled.div`

  background: rgba(112, 5, 64, 0.05);

  .img-container {
    height: auto;
    background-color: ${props => props.theme.backgrounds.white};
    margin-top: -15px
  }

`;

