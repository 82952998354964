import { FiAlertCircle } from "react-icons/fi";
import Modal from '../';
import { ButtonConfirmPassWord, ButtonPassWord, ContainerButtons, Exit } from './style';

const ModalDropMessage = ({ modalRef, action, title, all = true, onlyMessage }) => {
    const message = !onlyMessage ? `estas mensagens?` : `esta mensagem?`;
    return (
        <Modal title={title} subTitle=" " ref={modalRef}>
            <Exit>
                <FiAlertCircle id="icon" size={30} />
                <h4 id="message">Você deseja excluir {message}</h4>
                <ContainerButtons >
                    <ButtonPassWord onClick={() => action(false)}>
                        Apagar para mim
                    </ButtonPassWord>
                    {all && <ButtonPassWord margin={true} onClick={() => action(true)}>
                        Apagar para todos
                    </ButtonPassWord>}
                    <ButtonConfirmPassWord onClick={() => modalRef.current.close()}>
                        Cancelar
                    </ButtonConfirmPassWord>
                </ContainerButtons>
            </Exit>
        </Modal>
    )
}

export default ModalDropMessage;