import { useEffect, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { MdViewColumn } from "react-icons/md";
import { InputSelect } from "../../../../../../components";
import { EnumOrdering, ReportOptionsInterface } from "../../../../../../types/project";
import { HeaderTable } from "../../../../../../util/report/reportTypes";
import { ItemModal } from "../../style";
import SectionOption from "./SectionOption";

interface OrderingProps {
    header: HeaderTable;
    optionsPDF: ReportOptionsInterface;
    setOptionsPDF: React.Dispatch<React.SetStateAction<ReportOptionsInterface>>;
}

const OrderingOption = ({ header, optionsPDF, setOptionsPDF }: OrderingProps) => {
    const sortObject = optionsPDF.ordering;
    const columnValues = Object.keys(header.itens).map((column) => { return { value: column, label: column } });
    const [columnSelect, setColumnSelect] = useState(sortObject.column ?? columnValues[0].label);
    const [isEdit, setIsEdit] = useState(false);
    const [sortSelect, setSortSelect] = useState(sortObject.type);
    const sortValues = [{ value: EnumOrdering.CRESCENT, label: "Crescente" }, { value: EnumOrdering.DESCENDING, label: "Decrescente" }];

    useEffect(() => {
        if (optionsPDF.ordering.column !== columnSelect) {
            setColumnSelect(optionsPDF.ordering.column ?? columnValues[0].label);
            setSortSelect(optionsPDF.ordering.type);
        }
    }, [optionsPDF])
    function saveChange() {
        optionsPDF = { ...optionsPDF, ordering: { column: columnSelect, type: sortSelect } };
        setOptionsPDF({ ...optionsPDF });
        setIsEdit(false);
    }

    function closeEdit() {
        setColumnSelect(sortObject.column ?? columnValues[0].label);
        setSortSelect(sortObject.type);
        setIsEdit(false);
    }

    return <SectionOption title="Ordenação" edit={{ handleClose: closeEdit, handleSave: saveChange, isEdit: isEdit, setIsEdit: setIsEdit }}>
        <ItemModal >
            <div className="flex" style={{ minWidth: "175px", flex: "1" }}>
                <div className="icon">
                    <MdViewColumn title="Coluna" size="20px" />
                </div>
                {isEdit ?
                    <InputSelect
                        $flex={true}
                        value={columnValues.find((option) => (option.value === columnSelect))}
                        onChange={(e) => { setColumnSelect(e.value) }}
                        placeholder="Coluna" options={columnValues} />
                    :
                    <p>{columnValues.find((option) => (option.value === columnSelect))?.label}</p>
                }
            </div>
            <div className="flex" style={{ minWidth: "175px", flex: "1" }}>
                <div className="icon">
                    <BiSortAlt2 title="Coluna" size="20px" />
                </div>
                {isEdit ?
                    <InputSelect
                        $flex={true}
                        value={sortValues.find((option) => (option.value === sortSelect))}
                        onChange={(e) => { setSortSelect(e.value) }}
                        placeholder="Coluna" options={sortValues} />
                    :
                    <p>{sortValues.find((option) => (option.value === sortSelect))?.label}</p>
                }
            </div>
        </ItemModal>
    </SectionOption>
}

export default OrderingOption;