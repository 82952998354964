import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AiFillCheckCircle, IoIosArrowBack } from '../../../../../../assets/icons';
import { NotFound, Search } from '../../../../../../components';
import { ButtonArrow } from '../../../../../../components/Button/style';
import { Checked } from '../../../../../../components/Checked';
import { ShimmerCard } from '../../../../../../components/Shimmers';
import { getUsersInEvent, patchEventPresence } from '../../../../../../services/requests/event-user';
import { TEvent, TUserPresence } from '../../../../../../types/project';
import { ToastModel } from '../../../../../../util/error';
import { isEqualsArray } from '../../../../../../util/verification';
import { Container, Header, Menu } from './style';


type TProps = {
    modal: any,
    event: TEvent
}

const PresenceEvent = (props: TProps) => {
    const { modal, event } = props;
    const [hidden, setHidden] = useState(false);
    const [req, setReq] = useState(false)
    const { addToast } = useToasts();
    const isCheckedPresence = (participants) => {
        participants.forEach(user => {
            user.checked = Boolean(user.presence);
            user.hidden = false;
        });
        return participants;
    }

    const [users, setUsers] = useState<TUserPresence[]>([]);
    const [staticUsers, setStaticUsers] = useState<TUserPresence[]>([]);;


    useEffect(() => {
        getUsersInEvent(event.id, event.Group_id).then((res) => {
            if (res.status == 200) {
                const participants = isCheckedPresence(res.data.users);
                setStaticUsers(participants);
                setUsers(participants);
                setReq(true)
            } else if (res.status == 401) {
                ToastModel(addToast, 'Usuário não autorizado!', "Você não tem permissão para realizar esta ação.", "error");
            }
        })
    }, [])
    useEffect(() => {
        //hidden null para evitar bug por conta do search que muda o valor de hiddens
        isEqualsArray(staticUsers.map(user => { return { ...user, hidden: null } }), users.map(user => { return { ...user, hidden: null } })) ? setHidden(false) : setHidden(true)
    }, [users])

    const updatePresence = () => {
        if (hidden) {
            let usersAdd: Array<any> = [];
            let usersRemove: Array<any> = [];

            users.forEach(user => {
                if (user.presence) {
                    !user.checked && usersRemove.push(user.id)
                } else {
                    user.checked && usersAdd.push(user.id)
                }
            });

            patchEventPresence(event.id, usersAdd, usersRemove).then((res) => {
                if (res.status == 200) {
                    addToast({
                        title: "Presença atualizada!"
                    },
                        { appearance: 'success' });
                    modal.current.close()
                } else if (res.status == 401) {
                    ToastModel(addToast, 'Usuário não autorizado!', "Você não tem permissão para realizar esta ação.", "error");
                }
            })
        }
    }

    const ListForCreator = () => {
        return (
            users.length > 0 ? <div>
                <Checked users={users} setUsers={setUsers} />
            </div> : <NotFound description="Não há presenças para marcar" />
        );
    }

    return (
        <Container>
            <Header>
                <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                <h5>Lista de presença</h5>
            </Header>
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    setUsers(result)
                }} />
                <ButtonArrow onClick={() => updatePresence()} >
                    <AiFillCheckCircle id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>
            </Menu>
            <div>
                {new Date() < new Date(event.dateStart) ? <NotFound description="O evento ainda não esta acontecendo" /> 
                : req ? <ListForCreator />
                    : <ShimmerCard height={"75px"} />}

            </div>
        </Container>
    );
}



export default PresenceEvent;