import { useEffect, useState } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ShimmerCard } from "../../../../../../components/Shimmers/styles";
import {
  setDateSelect,
  setFilter,
} from "../../../../../../store/actions/scheduleAction";
import { birthdayFormat } from "../../../../../../util/date";
import { Item, More, Title } from "./style";
import { TypeConstRedux, TypeReduxAbare } from "../../../../../../types/redux";
import { ContainerEvents } from "../../../style";
import { ItemEvent } from "../../ItemEvent.tsx";
import { EmptyContent } from "../../EmptyContent";

const CardEvent = ({ column1 }) => {
  const commitment = useSelector(
    (state: TypeConstRedux) => state.dashboard.commitment_dash
  );
  const [tamanhoLista, setTamanhoLista] = useState<Number | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (commitment) {
      setTamanhoLista(commitment.sort(ordenarEventos).filter(dataHoje).length);
    }
  }, [commitment]);

  const redirect = (path) => {
    history.push(path);
  };

  const redirectEvent = (event) => {
    if (event.Group_id) {
      dispatch(setFilter(1));
    } else {
      dispatch(setFilter(0));
    }
    //Muda a data da pagina da agenda para a data do evento selecionda
    dispatch(setDateSelect(new Date(event.dateStart)));

    //Redireciona rota para rota da agenda
    history.push(`/home/schedule`);
  };

  function dataHoje(data) {
    const dataEvent = new Date(data.dateStart);
    const dataHoje = new Date();
    return dataEvent.getTime() >= dataHoje.getTime();
  }

  function ordenarEventos(a, b) {
    const dataInicio = new Date(a.dateStart).getTime();
    const dataFim = new Date(b.dateStart).getTime();
    return dataInicio < dataFim ? -1 : dataInicio > dataFim ? 1 : 0;
  }

  return (
    <ContainerEvents column1={column1}>
      <div className="teste">
        <Title>Programação de eventos</Title>
        {commitment === null ? (
          <div className="div-itens">
            <ShimmerCard height={"72px"} margin={"0"} />
            <ShimmerCard height={"72px"} margin={"0"} />
            <ShimmerCard height={"72px"} margin={"0"} />
          </div>
        ) : tamanhoLista === 0 ? (
          <EmptyContent
            messageButton="Criar evento"
            onAction={() => redirect("/home/schedule")}
            message="Não há registro de eventos na sua programação"
          />
        ) : (
          <>
            {" "}
            <div className="div-itens">
              {commitment
                .sort(ordenarEventos)
                .filter(dataHoje)
                .map((event) => (
                  <ItemEvent
                    onClick={() => redirectEvent(event)}
                    key={event.id}
                    event={event}
                  />
                ))}
            </div>
            <More
              relative={tamanhoLista}
              onClick={() => redirect("/home/schedule")}
            >
              Ver tudo
            </More>
          </>
        )}
      </div>
    </ContainerEvents>
  );
};

export default CardEvent;
