import { api } from "../api";
import { getToken } from "../auth";

export const postGroup = (data) => {
  return api({
    url: "/group-chat",
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getInfoGroup = (userId, groupId) => {
  return api({
    url: `/group-chat/info-group/${userId}/${groupId}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getGroups = () => {
  return api({
    url: `/group-chat`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getUsersForAdd = (userId, groupId) => {
  return api({
    url: `/group-chat/users-for-add/${userId}/${groupId}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getGroupsForFolders = (clusterId) => {
  return api({
    url: `/group-chat/report/get-info-participants/${clusterId}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const patchDataGroup = (userId, data) => {
  return api({
    url: `/group-chat/${userId}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const patchUpdateLinkGroup = (groupId) => {
  return api({
    url: `/group-chat/reset-link/${groupId}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getLinkGroup = (groupId) => {
  return api({
    url: `/group-chat/find-link/group/${groupId}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getGroupForInvite = (invite) => {
  return api({
    url: `/group-chat/find-group-for-link/group-link/${invite}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};

export const getGroupById = (id) => {
  console.log("Teste");
  console.log(id);
  return api({
    url: `/group-chat/findOne/${id}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error: ", error.message);
        return error.message;
      }
    });
};
