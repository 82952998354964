import { useTheme } from "styled-components";
import IconChatFill from "../../../../../../assets/icons/IconChatFill";
import IconScheduleFill from "../../../../../../assets/icons/IconSheduleFill";
import { ContainerProgrammingEvents } from "./style";
export function CardProgrammingEvents() {
  const theme = useTheme();
  return (
    <ContainerProgrammingEvents>
      <header>Eventos agendados</header>
      <main>
        <select name="filter-category" id="">
          <option value="" disabled selected>
            Filtrar categoria
          </option>
          <option value="">Exibir todas</option>
          <option value="">Atividade</option>
          <option value="">Aniversário</option>
          <option value="">Compromisso</option>
          <option value="">Reunião</option>
          <option value="">Outros</option>
        </select>
        <div className="container-messages">
          <span className="count-messages">9</span>
          <span className="information-message">
            Nenhuma programação foi incluída na sua agenda
          </span>
        </div>
      </main>
      <IconScheduleFill
        className="float-icon"
        color={theme.icon.cardProgrammingEvent}
        size={125}
      />
    </ContainerProgrammingEvents>
  );
}
