import {  useRef} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaUserTimes } from "../../../../../assets/icons";
import { setAbare } from "../../../../../store/actions/abareAction";
import { TUserPrivacy } from "../../../../../types/project";
import { getOptionsPrivacyDictionary } from "../../../../../util/privacy";
import ItemContainer from "../../Components/ItemContainer";
import Container from "../../Components/SettingsContainer";
import { HorizontalRow, VerticalRowItems } from "../../style"
import Blockeds from "../Blockeds";

const optionPrivacy = (op) => {
    try {
        return getOptionsPrivacyDictionary[ op]
    } catch (error) {
        return null;
    }
}
const optionResctriction = (op) => {
    if (!op) return null
    const optionsPrivacyDict = {
        "1": 'Ninguém',
        "2": 'Meus Contatos',
        "3": 'Todos',
    }
    return optionsPrivacyDict[op];
}

type Tprops = {
    abare: TUserPrivacy,
}

const Main = (props: Tprops) => {
    const { abare } = props;
    const modalBlockUser = useRef<any>();
    const { push } = useHistory();
   
    const items = [
        { name: "Nome", option: optionPrivacy(abare.privacy?.name), marginBottom: true, onClick: () => push('/settings/privacy/name') },
        { name: "E-mail", option: optionPrivacy(abare.privacy?.email), marginBottom: true, onClick: () => push('/settings/privacy/email') },
        { name: "Telefone/Celular", option: optionPrivacy(abare.privacy?.phone), marginBottom: true, onClick: () => push('/settings/privacy/phone') },
        { name: "Data de aniversário", option: optionPrivacy(abare.privacy?.birthday), marginBottom: true, onClick: () => push('/settings/privacy/birthday') },
        { name: "CPF", option: optionPrivacy(abare.privacy?.cpf), marginBottom: true, onClick: () => push('/settings/privacy/cpf') },
        { name: "Dados eleitorais", option: optionPrivacy(abare.privacy?.voter), marginBottom: false, onClick: () => push('/settings/privacy/electoral') },
    ]

    return (
        <Container title="Segurança e Privacidade">
            <ItemContainer title="Atividades gerais">
                <HorizontalRow isHover lastLine onClick={() => modalBlockUser.current?.open()}>
                    <FaUserTimes id="icon" />
                    <span>Bloqueados</span>
                </HorizontalRow>
            </ItemContainer>
            <ItemContainer title="Visibilidade de dados">
                {items.map((it, key) => (
                    <VerticalRowItems isSingle isHover marginBottom={it.marginBottom} key={key} onClick={it.onClick}>
                        {it.name}
                        <div className="line">{it.option}</div>
                    </VerticalRowItems>
                ))}
            </ItemContainer>
            <ItemContainer title="Restrições">
                <VerticalRowItems isSingle isHover marginBottom={true} onClick={() => push('/settings/privacy/restrictionCall')}>
                    Quem pode me ligar?
                    <div className="line">{optionResctriction(abare.privacy?.call)}</div>
                </VerticalRowItems>
                <VerticalRowItems isSingle isHover onClick={() => push('/settings/privacy/restrictionGroup')}>
                    Quem pode me adicionar em grupos?
                    <div className="line">{optionResctriction(abare.privacy?.groupAdd)}</div>
                </VerticalRowItems>
            </ItemContainer>
            <Blockeds modalBlockUser={modalBlockUser} />
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare
    }
}

export default connect(mapStateToProps)(Main);