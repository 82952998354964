import { getContacts } from "../../services/requests/contact"
import { ADD_CONTACT, SET_CONTACTS } from "./actionTypes"
import { getAllState } from "./messages/messageAction"

export const setContacts = (contacts) => {
    return {
        type: SET_CONTACTS,
        payload: contacts
    }
}

export const addContact = (contact) => {
    return {
        type: ADD_CONTACT,
        payload: contact
    }
}

export const findAllContacts = () => {
    return (dispatch, getState) => {
        const { abare } = getAllState(getState());
        getContacts(abare?.id).then((res) => {
            if (res.status == 200) {
                dispatch(setContacts(res.data))
            }
        })
    }
}


export const editContact = (contact) => {
    return (dispatch, getState) => {
        const { contacts } = getAllState(getState());
        const index = contacts.findIndex(user => user.contactId == contact.contactId);
        contacts.splice(index, 1, contact)
        dispatch(setContacts([...contacts]))
    }
}

export const deleteContactsRedux = (contactsIds: number[]) => {
    return (dispatch, getState) => {
        const { contacts } = getAllState(getState());
        const aux = contacts.filter(contact => !contactsIds.some(contactId => contactId == contact.contactId))
        dispatch(setContacts([...aux]))
    }
}