export const light = {
  error: "#F45",
  heightToolbar: "55px",
  color: {
    secondary_cinza: {
      navigation_drawer: "#F1F1F1",
    },
  },
  title: "light",
  general: {
    column_gap: "1rem",
  },
  fonts: {
    text_xs: "0.75rem",
    text_sm: "0.875rem",
    text_base: "1rem",
    text_lg: "1.125rem",
    text_xl: "1.25rem",
    text_2xl: "1.5rem",
    text_3xl: "1.875rem",
    text_4xl: "2.25rem",
    text_5xl: "3rem",
    text_6xl: "3.75rem",
    text_7xl: "4.5rem",
    text_8xl: "6rem",
    text_9xl: "8rem",
    family_toolbar: "inter-bold, sans-serif",
  },
  card: {
    primary: "#fff",
    text_primary: "#2F2E2E",
    text_secondaty: "#121068",
  },

  begin: {
    color: {
      background: {
        primary: "#fff",
        secondary: "#f8f2f5",
        footer: "#7C1A40",
      },
      font: {
        primary: "#2F2E2E",
        secondary: "black",
        title: "#484848",
      },
      button: {
        primary: "#700540",
        text_primary: "#700540",
        more: "none",
        secondary: "#700540",
        text_secondary: "white",
      },
      information: {
        background: "#f2f2f2",
      },
      card: {
        font: "#2F2E2E",
        button: "#A6446A",
        font_header: "#FFFFFF",
        background_header: "#000",
        border: "#dddddd",
      },
    },
    background: {
      primary: "#fff",
      secondary: "#f8f2f5",
      footer: "#7C1A40",
    },
  },
  purple: {
    ligther: "#FDCDE0",
    ligtherI: "#F5A6C5",

    light: "#893A66",
    lightI: "#A13660",
    lightII: "#92406d",

    dark: "#700540",
    darkI: "#70012C",

    darker: "#4E001E",
    darkerI: "#300013",
  },
  default: {
    ligther: "#FFFFFF",
    ligtherI: "#EDEDED",

    light: "#DDDCDC",
    lightI: "#C6C6C6",

    dark: "#AEAEAE",
    darkI: "#848383",

    darker: "#666666",
    darkerI: "#484848",

    black: "#2F2E2E",
    blackI: "#000000",
  },
  colors: {
    primary: "#C62E65",
    secundary: "#D63AF9",
    background: "#f5f5f5",
    text: "#333",
  },
  blue: {
    ligther: "#D9F8FB",
    ligtherI: "#93F2FC",

    light: "#56E2F1",
    lightI: "#26BDCD",

    dark: "#009FB0",
    darkI: "#008493",

    darker: "#00646F",
    darkerI: "#013F46",
  },
  darkBlue: {
    ligther: "#B19AE5",
    ligtherI: "#8676AA",

    light: "#705E97",
    lightI: "#54427B",

    dark: "#473C5F",
    darkI: "#393246",

    darker: "#292433",
    darkerI: "#1D1A24",
  },
  green: {
    ligther: "#E0FFB9",
    ligtherI: "#C9F394",

    light: "#ABDC6D",
    lightI: "#87BC46",

    dark: "#4E840C",
    darkI: "#3B6309",

    darker: "#324816",
    darkerI: "#313D22",

    black: "#2B2F26",
    blackI: "#151515",
  },
  chat: {
    message: {
      backgroundMyI: "#EAD9E2",
      backgroundMyII: "#EAD9E2",
      backgroundOtherI: "#f7f7f7",
      backgroundOtherII: "#f7f7f7",
      send: "#848383",
      read: "#00646F",
      backgroundData: "#2581C41A",
      backgroundRound: "#C8ADB7",
      borderRound: "#BC718E",
    },
  },
  button: {
    backgroundEvent: "transparent",
    textEvent: "#A6446A",
    hoverEvent: "#A6446A",
  },
  texts: {
    contentCards: "#484848",
    hintEventCard: "#63A0F2",
    hintDailyCardI: "#87BC46",
    hintDailyCardII: "#A6446A",
    primaryNavBar: "#700540",
    secondaryNavBar: "#666666",
    titleInformations: "#484848",

    white: "#FFFFFF",
    titleCards: "#484848",
    lightGrey: "#EDEDED",
    lightGreyI: "#DDDCDC",
    lightGreyII: "#C6C6C6",
    darkGrey: "#AEAEAE",
    darkGreyI: "#848383",
    secondaryToolbar: "#ffffff",
    darkerGrey: "#666666",
    darkerGreyI: "#484848",
    darkerGreyII: "#2F2E2E",
    black: "#000000",
    pinkII: "#A13660",
    purpleDark: "#700540",
    purpleDarkTitle: "#700540",
    orange: "#848383",
  },

  backgrounds: {
    cardWheather: "#09976D",

    primary: "#ffffff",
    secondary: "#F4F5FA",
    white: "#FFFFFF",
    default: "rgb(244, 245, 250)",
    secondaryToolbar: "#700540",
    answer: "#dfb6cd;",
    blueLight: "#2581C41A",
    buttonSchedule: "#ad4c72",
    pinkII: "#A13660",
    purpleLightI: "#F8F2F5",
    purpleDark: "#700540",
    lightGreyII: "#C6C6C6",
    lightGreyIV: "#F3F3F3",
    perfilNaveBar: "#700540",
    folderTalk: "#FFF",
    lightGrey: "#EDEDED",
    lightGreyI: "#DDDCDC",
    darkI: "#848383",
    toolbarSchedule: "#DDDCDC",
    conversaSelecionada: "#C6C6C6",
    buttonModalGroup1: "#920b42",
    buttonModalGroup2: "#6f0c5f",
    buttonModalGroup1Login: "#920b42",
    buttonModalGroup2Login: "#6f0c5f",
    gradient2: "#8a2b52",
    gradient3: "#9e5573",
  },
  hover: {
    navBar: "#F1F1F1",

    purpleLightII: "#92406d",
    purpleDark: "#700540",
    lightGrey: "#EDEDED",
    lightGreyIII: "#f6f6f6",
    orange: "#DDDCDC",
    input: "#700540",
    red: "#ff4c2f",
    green: "#09976D",
    default: "#DDDCDC",
    buttonHover: "#EDECEC",
    buttonActive: "#BBB",
  },
  highlights: {
    default: "#700540",
    navBar: "#7005401A",
  },
  icon: {
    perfil: "#a6446a",
    dashboard: "#666666",
    cardDailyMessage: "#C5E799",
    cardProgrammingEvent: "#92BDF6",
  },
  bordes: {
    card: "#f4f4f4",

    purpleLight: "#893A66",
    purpleLightIIShadow: "#700540a3",
    lightGrey: "rgba(58, 53, 65, 0.12)",
    lightGreyI: "#DDDCDC",
    lightGreyII: "#C6C6C6",
    input: "#DCDCDC",
    calendar: "#E4E3E3",

    footerChat: "#d3d3d3",
    rose: "#ffffff33",
  },
  boxShadow: {
    card: "0px 0px 7px 0px rgba(117, 117, 117, 0.10), 0px 0px 15px 0px rgba(216, 216, 216, 0.09), 0px 4px 2px 0px rgba(0, 0, 0, 0.02)",
  },
  linearGradient: {
    cardWheather: "linear-gradient(180deg, #27AB84 0%, #09976D 49.48%)",
  },
  link: "#551A8B",
};
