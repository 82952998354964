import React from "react";
import { Modal } from "../../../components";
import { HiPhone, HiOutlineMail, FcGoogle, FaFacebook } from '../../../assets/icons'
import { Authentication, ButtonAuth, Button } from "../style";
import { useHistory, useLocation } from "react-router-dom";
import { postLoginFacebook, postLoginGoogle } from '../../../services/requests/user'
// import { GoogleLogin } from 'react-google-login';
import { errorDefault, ToastModel } from "../../../util/error";
import { useToasts } from "react-toast-notifications";
import { login } from '../../../services/auth';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const Others = (props) => {
    const clientGoogleId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || ''
    const clientFacebookId = process.env.REACT_APP_FACEBOOK_AUTH_CLIENT_ID
    const { modalAuth, email } = props;
    const history = useHistory();
    const { addToast } = useToasts();
    const { search } = useLocation();

    const responseGoogle = (response) => {
        if ('accessToken' in response) {
            postLoginGoogle(response.profileObj).then((res) => {
                const status = res.status
                if (status) {
                    if (status == 200) {
                        const token = res.result.access_token
                        login(token)
                        history.push('/signin/data')
                    } else {
                        errorLogin(status, res.message.pt)
                    }
                } else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            });
        } else {
            ToastModel(addToast, "Erro ao se conectar com o google", "Tente novamente mais tarde", "error")
        }
    }

    const responseFacebook = (response) => {
        if ('accessToken' in response) {
            postLoginFacebook(response).then((res) => {
                const status = res.status
                if (status) {
                    if (status == 200) {
                        const token = res.result.access_token
                        login(token)
                        history.push('/signin/data')
                    } else {
                        errorLogin(status, res.message.pt)
                    }
                } else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            });
        } else {
            ToastModel(addToast, "Erro ao se conectar com o Facebook", "Tente novamente mais tarde", "error")
        }
    }

    const errorLogin = (status, message) => {
        switch (status) {
            case 400:
                ToastModel(addToast, message, "Tente novamente mais tarde", "error")
                break
            default:
                errorDefault(status, addToast)
        }
    }
    return (
        <Modal ref={modalAuth}>
            <Authentication>
                <h3 className="center">Autenticações alternativas</h3>
                <legend>Selecione uma das opções para se cadastrar</legend>
                <div className="header">
                    {email ?
                        <ButtonAuth onClick={() => history.push('/signin/phone'+search)} >
                            <HiPhone id="icon" />
                            <div id="text">Número de Telefone</div>
                        </ButtonAuth>
                        :
                        <ButtonAuth onClick={() => history.push('/signin/email'+search)} >
                            <HiOutlineMail id="icon-email" />
                            <div id="text">E-mail e senha</div>
                        </ButtonAuth>
                    }
                    {/* <GoogleLogin
                        clientId={clientGoogleId}
                        buttonText="Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        icon={false}
                        render={renderProps => (
                            <ButtonAuth onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                <FcGoogle id="icon" />
                                <div id="text">Google</div>
                            </ButtonAuth>
                        )}
                    />
                    <FacebookLogin
                        appId={clientFacebookId}
                        callback={responseFacebook}
                        fields="name,email,picture"
                        render={renderFacebook => (
                            <ButtonAuth onClick={renderFacebook.onClick}>
                                <FaFacebook id="facebook" />
                                <div id="text">Facebook</div>
                            </ButtonAuth>
                        )}
                    /> */}
                    <Button className="horizontal-gradient-login" onClick={() => modalAuth.current.close()}>Voltar</Button>
                </div>
            </Authentication>
        </Modal>
    )
}

export default Others;