import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ShimmerCard} from "../../../../../../components/Shimmers";
import {setWeatherDash} from "../../../../../../store/actions/dashboardAction";
import {Container} from "./style";
import {getWeather} from "../../../../../../services/requests/weather";

const CardWeather = ({weather, setWeatherDash}) => {
    const [permissao, setPermissao] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    function showError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setError("Ative a localização para descobrir o tempo atual :P");
                break;
            case error.POSITION_UNAVAILABLE:
                setError("Sua localização está indisponível no momento :(");
                break;
            case error.TIMEOUT:
                setError("Tempo de requisição expirado :(");
                break;
            case error.UNKNOWN_ERROR:
                setError(
                    "Algum error desconhecido, contacte a equipe de desenvolvimento :P"
                );
                break;
        }
        setWeatherDash(null);
    }

    useEffect(() => {
        !weather &&
        navigator.geolocation.getCurrentPosition((location) => {
            const {latitude, longitude} = location.coords;
            getWeather(longitude, latitude)
                .then((res) => {
                    const respo = JSON.parse(res);
                    if (respo.cod == 200) {
                        setWeatherDash(JSON.parse(res));
                        setError(null);
                    } else {
                        throw new Error("Not response ");
                    }
                })
                .catch(() => {
                    setError("Informação de temperatura não disponível no momento");
                });
        }, showError);
    }, [permissao]);

    return (
        <>
            {error != null ? (
                <Container
                    click={true}
                    onClick={() => {
                        navigator.geolocation, setPermissao(!permissao);
                    }}
                >
                    <div className="content-message">
                        <p className="title">Quais os planos para hoje?</p>
                        <p className="content">
                            Ative a&nbsp;
                            <strong>localização</strong>
                            &nbsp;para saber as consições climáticas de sua cidade.
                        </p>
                    </div>
                    <span className="circle"/>
                </Container>
            ) : weather == null ? (
                <Container $loading={true}>
                    <ShimmerCard height="100%" width="100%"/>
                </Container>
            ) : weather.cod == 200 ? (
                <Container>
                    <div className="content-message">
                        <p className="title">Quais os planos para hoje?</p>
                        <p className="content">
                            Em&nbsp;
                            <strong>
                                {weather.name === "Parque das Laranjeiras"
                                    ? "Manaus"
                                    : weather.name}
                            </strong>
                            , a temperatura é de:
                        </p>
                    </div>

                    {weather.main?.temp ? (
                        <p className="tempo">{weather.main.temp}°</p>
                    ) : (
                        <p>No momento não foi possivel buscar a temperatura</p>
                    )}
                    <img
                        className="icon"
                        src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                    />
                    <span className="circle"/>
                </Container>
            ) : (
                <Container
                    click={true}
                    onClick={() => {
                        navigator.geolocation, setPermissao(!permissao);
                    }}
                >
                    <div className="content-message">
                        <p className="title">Quais os planos para hoje?</p>
                        <p className="content">
                            Ative a&nbsp;
                            <strong>localização</strong>
                            &nbsp;para saber as consições climáticas de sua cidade.
                        </p>
                    </div>
                    <span className="circle"/>
                </Container>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    weather: state.dashboard.weather_dash,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setWeatherDash,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardWeather);
