import styled from "styled-components";

export const Limit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: ${(props) => props.theme.texts.darkerGrey};
  padding: 0px 6px;
`;

interface MainProps {
  $flex?: boolean;
  $required?: boolean;
  $marginLabel?: string;
}
export const MainInput = styled.div<MainProps>`
  label {
    display: inline-block;
    margin-top: ${({ $marginLabel }) =>
      $marginLabel ? $marginLabel : "0.7rem"};
    font-size: 1rem;
    color: ${(props) => props.theme.texts.darkerGreyII};
  }
  ${({ $flex }) => $flex && ` flex: 1;`};

  ${({ $required }) =>
    $required &&
    `
    border-color:  rgb(255, 76, 81) !important;
    color:  rgb(255, 76, 81) !important;
    >*{
      color:  rgb(255, 76, 81) !important;
    }
    .icon{
      color:  rgb(255, 76, 81) !important;
    }
  `};

  position: relative;
  .required {
    color: rgb(255, 76, 81);
    margin: 0px;
    padding: 0px;
  }
`;

export const ToolbarInput = styled(MainInput)`
  min-width: 50%;
  border-radius: 25%;
`;
export const IsDisabled = styled.div`
  width: 100%;
`;
export const ContainerInputLogin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.theme.title == "light" ? "#2581C41A" : "#e8f0fe"};
  border: 0.1px solid ${(props) => props.theme.bordes.input};
  border-radius: 6px;
  padding-inline: 0.5rem;

  #timer {
    margin: 0;
    font-size: 13px;
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  h5 {
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  .phone-width {
    width: 100%;
  }

  #icon {
    color: ${(props) => props.theme.purple.dark};
    padding-inline: 0.2rem;
  }

  #eye {
    font-size: 19px;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};

    &:hover {
      color: ${(props) => props.theme.backgrounds.purpleDark};
    }
  }

  #send {
    font-size: 15px;
    background: none;
    color: ${(props) => props.theme.purple.dark};
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.backgrounds.purpleDark};
    }
  }

  input,
  textarea {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 40px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 1rem;

    color: ${(props) => (props.theme.title == "light" ? "#2F2E2E" : "#092232")};

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: ${(props) => props.theme.texts.darkGrey};
      opacity: 1;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkGrey};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: ${(props) => props.theme.default.black};
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme }) => theme.backgrounds.purpleDark};
    }

    &:focus {
      -webkit-text-fill-color: ${({ theme }) => theme.backgrounds.purpleDark};
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus-within {
    color: ${(props) => props.theme.backgrounds.purpleDark};
    -webkit-text-fill-color: ${(props) => props.theme.backgrounds.purpleDark};
    border: 0.1px solid ${(props) => props.theme.backgrounds.purpleDark};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: auto;
    padding-top: 0.7rem;
  }
`;
interface ContainerInputInterface {
  $width?: string;
  $marginRight?: string;
  $marginLeft?: string;
  $invisible?: boolean;
  $required?: boolean;
}
export const ContainerInput = styled.div<ContainerInputInterface>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: ${({ $width }) => ($width ? "initial" : 1)};
  width: ${({ $width }) => ($width ? $width : `inherit`)};
  margin-right: ${({ $marginRight }) =>
    $marginRight ? $marginRight : "initial"};
  margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : "initial")};
  align-items: center;
  background-color: ${(props) => props.theme.backgrounds.blueLight};
  border: 0.1px solid ${(props) => props.theme.bordes.input};
  border-radius: 6px;
  padding-inline: 0.5rem;
  visibility: ${({ $invisible }) => ($invisible ? "hidden" : "visible")};

  ${({ $required }) =>
    $required &&
    `
    border-color:  rgb(255, 76, 81) !important;
    color:  rgb(255, 76, 81) !important;
  `};
  .moreRows {
    height: auto;
    min-height: 50px;
  }
  #timer {
    margin: 0;
    font-size: 13px;
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  h5 {
    color: ${(props) => props.theme.texts.darkerGrey};
  }

  .phone-width {
    width: 100%;
  }

  #icon {
    color: ${(props) => props.theme.purple.dark};
    padding-inline: 0.2rem;
  }

  #eye {
    font-size: 19px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.darkerGrey};

    &:hover {
      color: ${(props) => props.theme.hover.input};
    }
  }

  #send {
    font-size: 15px;
    background: none;
    color: ${(props) => props.theme.texts.darkerGreyI};
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.hover.input};
    }
  }

  input,
  textarea {
    flex: 1;
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 35px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 14px;
    color: ${(props) => props.theme.texts.darkerGreyII};

    ::placeholder,
    ::-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: ${({ theme }) => theme.texts.darkGrey};
      opacity: 1;
      -webkit-text-fill-color: ${({ theme }) => theme.texts.darkGrey};
    }

    ::placeholder {
      color: ${({ theme, $required }) =>
        $required ? theme.error : theme.chat.message.send};
      opacity: 1;
      -webkit-text-fill-color: ${({ theme, $required }) =>
        $required ? theme.error : theme.chat.message.send};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGreyI};
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.texts.purpleDark};
    }

    &:focus {
      -webkit-text-fill-color: ${(props) => props.theme.texts.purpleDark};
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(0.8) brightness(50%) sepia(100%) saturate(0%)
      hue-rotate(0deg);
  }

  &:focus-within {
    ::placeholder {
      color: ${({ theme }) => theme.texts.purpleDark};
    }
    color: ${({ theme }) => theme.texts.purpleDark};
    -webkit-text-fill-color: ${({ theme }) => theme.texts.purpleDark};
    border: 0.1px solid ${({ theme }) => theme.texts.purpleDark};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: auto;
    padding-top: 0.7rem;
  }

  input[type="date"] {
    display: flex;
    align-items: center;
  }
`;

interface ColorProps {
  color: string;
  margin?: boolean;
  $marginLeft?: string;
}
export const ContainerColor = styled.div<ColorProps>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : "initial")};
  margin: ${({ margin }) => (margin ? "12px 0px" : "px")};
`;

export const ContainerToolbar = styled(ContainerInput)`
  border-radius: 16px;
  font-size: 18px;
  box-sizing: border-box;

  height: 52px;
  width: 100%;
  max-width: 550px;
  #icon {
    :hover {
      cursor: pointer;
    }
    font-size: 12px !important;
    width: 18px;
    height: 18px;
    color: ${(props) => props.theme.texts.darkGrey};
  }
`;
export const ContainerSearch = styled(ContainerInput)`
  width: auto;

  #icon {
    :hover {
      cursor: pointer;
    }
    font-size: 12px;
    color: ${(props) => props.theme.texts.darkGrey};
  }

  input {
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 35px;
    padding: 0px;
    padding-left: 5px;
    font-family: "inter", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  &:focus-within {
    color: ${(props) => props.theme.hover.input};
    -webkit-text-fill-color: ${(props) => props.theme.hover.input};
    border: 1px solid ${(props) => props.theme.hover.input};
    #icon {
      color: ${(props) => props.theme.hover.input};
    }
  }
`;

export const ContainerSearchBlock = styled(ContainerInput)`
  width: 390px;
  background-color: ${(props) => props.theme.backgrounds.white};
  margin-top: 5px;

  #icon {
    font-size: 12px;
    color: ${(props) => props.theme.texts.darkerGreyI};
  }
  input {
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 35px;
    padding: 0px;
    padding-left: 5px;
    font-family: "inter", sans-serif;
  }

  &:focus-within {
    color: ${(props) => props.theme.purple.dark};
    -webkit-text-fill-color: ${(props) => props.theme.purple.dark};
    border: 1px solid ${(props) => props.theme.purple.dark};
    #icon {
      color: ${(props) => props.theme.purple.dark};
    }
  }
`;

export const ContainerInputNote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.backgrounds.blueLight};
  border: 0.1px solid ${(props) => props.theme.default.lightI};
  border-radius: 6px;
  width: auto;
  padding-inline: 0.5rem;

  input {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    border: solid 1px transparent;
    height: 35px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 13px;

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: ${(props) => props.theme.texts.darkGrey};
      opacity: 1;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkGrey};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGreyI};
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.purple.dark};
    }

    &:focus {
      -webkit-text-fill-color: ${(props) => props.theme.purple.dark};
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus-within {
    color: ${(props) => props.theme.purple.dark};
    -webkit-text-fill-color: ${(props) => props.theme.purple.dark};
    border: 0.1px solid ${(props) => props.theme.purple.dark};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: auto;
    padding-top: 0.7rem;
  }
`;
export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;
interface StyledProps {
  width?: string;
  $color?: string;
}
export const CheckboxContainer = styled.div<StyledProps>`
  position: relative;
  margin-bottom: -5px;
  .checked {
    ${Icon} {
      visibility: "visible";
    }
    background: ${({ $color, theme }) =>
      $color ? $color : theme.default.dark};
  }

  .noChecked {
    ${Icon} {
      visibility: "hidden";
    }
    background: white;
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  opacity: 0;
  position: absolute;
  width: 18px;
  height: 18px;
  left: -5px;
  cursor: pointer;
  top: -5px;
`;

export const StyledCheckbox = styled.div<StyledProps>`
  display: inline-block;
  width: 14px;
  height: 14px;

  border-radius: 3px;
  margin-right: 0.5rem;
  transition: all 150ms;
  cursor: pointer;
  border: solid 1px
    ${({ $color, theme }) => ($color ? $color : theme.default.dark)};
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px
      ${({ theme, color }) => (color ? color : theme.purple.ligther)};
  }
`;

export const ContainerInputDate = styled.div<StyledProps>`
  display: block;
  padding: 0px 8px 0px 8px;
  width: ${(props) => (props.width ? props.width : "90px")};
  max-height: 35px;
  min-height: 35px;
  border: 1px solid #c6c6c6;
  color: #c6c6c6;
  border-radius: 16px;
  font-family: "Poppins light", sans-serif;
  font-size: 14px;

  input {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    border: solid 1px transparent;
    height: 35px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 13px;

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: #666666;
      opacity: 1;
      -webkit-text-fill-color: #666666;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: #666666;
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #666666;
    }

    &:focus {
      -webkit-text-fill-color: #666666;
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus-within {
    color: #666666;
    -webkit-text-fill-color: #666666;
    border: 0.1px solid #666666;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
