import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AiFillCheckCircle, IoIosArrowBack, RiFolderOpenFill } from '../../../../../../assets/icons';
import { Load, NotFound, Search } from "../../../../../../components";
import { Checked } from "../../../../../../components/Checked";
import { getItensForAdd, postItem } from "../../../../../../services/requests/cluster";
import { setListFolder } from "../../../../../../store/actions/folderAction";
import { TCluster, TUser, TypePersonal } from "../../../../../../types/project";
import { Button, Container, Header, Menu, Scroll, Title } from '../style';

type TProps = {
    abare: TUser
    folder: TCluster,
    toListFolder: any,
    folders: TCluster[],
    modalAdd: any
}

const Add = ({ folder, toListFolder, folders, modalAdd, abare }: TProps) => {
    const [personals, setPersonals] = useState<any>(null);
    const [hidden, setHidden] = useState(false);
    const [loading, setLoading] = useState(false);

    const addItens = () => {
        if (hidden) {
            var itensId: Array<any> = [];
            var itens: Array<any> = [];
            setLoading(true);
            personals.map((key) => {
                if (key.checked && key.type == TypePersonal.GROUP) {
                    itensId.push({ code: key.Group_id, type: TypePersonal.GROUP })
                    itens.push(key)
                } else if (key.checked && key.type == TypePersonal.PERSONAL) {
                    itensId.push({ code: key.Personal_id, type: TypePersonal.PERSONAL })
                    itens.push(key)
                }
            })
            postItem(folder.id, itensId).then((res) => {
                if (res.status == 200) {
                    modalAdd.current.close()

                    folder.itens = folder.itens.concat(itens)

                    const index = folders.findIndex(x => x.id === folder.id)
                    folders.splice(index, 1, folder)

                    toListFolder([...folders])
                }
            })
        }
    }

    useEffect(() => {
        getItensForAdd(abare.id, folder.id).then((res) => {
            if (res.status == 200) {
                setPersonals(res.data)
            }
        })
    }, [])

    return (
        <Container>
            <Header>
                <IoIosArrowBack id="back" onClick={() => modalAdd.current.close()} />
                <RiFolderOpenFill className="icon-folder" />
                <Title>
                    {folder.name}
                </Title>
            </Header>
            <Menu>
                <h4>Conversas</h4>
                <Search itens={personals || []} onSearch={(result) => {
                    { personals && setPersonals(result) }
                }} />

                {!loading ?
                    <Button onClick={() => addItens()}>
                        <AiFillCheckCircle size={32} id="icon" className={!hidden ? "default" : "purple"} />
                    </Button>
                    :
                    <Load margin="8px" width="18px" />
                }
            </Menu>
            {personals ?
                <Scroll>
                    {personals.length ?
                        <Checked personals={personals} setPersonals={setPersonals} setHidden={setHidden} />
                        :
                        <NotFound description="Não há conversas ou todas já foram adicionadas." />
                    }
                </Scroll>
                :
                <div className="load">
                    <Load width="25px" />
                    <h4>Carregando...</h4>
                </div>
            }
        </Container>

    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
        folders: state.folder.list
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toListFolder(list) {
            dispatch(setListFolder(list));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Add);