import styled from "styled-components";

interface StyledProps {
  isMy?: boolean;
  color?: string;
  fixed?: boolean;
  hasDescription?: boolean;
}

const formatDoc = `
  padding: 8px;
  margin-top: 4px;
`;

export const Doc = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  border-radius: 7px;
  justify-self: start;
  align-items: center;
  ${(props) => props.hasDescription && formatDoc};
  background: ${(props) => props.hasDescription ? props.isMy ? "rgba(255, 255, 255, 0.2)" : props.theme.default.ligtherI : null};

  
  .data-file {
      padding-left: 14px;
      max-width: 250px;
      color: ${(props) => props.theme.texts.darkerGreyI};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .size {
        color: ${(props) => props.theme.blue.darker};
        font-size: 12px;
      }
    }

  .download {
    color: ${(props) => props.theme.purple.dark};
    cursor: pointer;
  }
`;
