
import { useDispatch, useSelector } from 'react-redux';
import { navigationStorageClusterFolder } from '../../../../../store/actions/storageClusterAction';
import { FolderRepository } from '../../../../../types/project';
import { TypeConstRedux } from '../../../../../types/redux';
import { Container } from './style';

interface FolderInterface {
    folderActual: FolderRepository;
    children?: React.ReactNode;
}

const ButtonPath = ({ folderActual,children }: FolderInterface) => {
    const { folder } = useSelector((state: TypeConstRedux) => state.repository)
    const dispatch = useDispatch();

    const handleClickButton = () => {
        if (folder.length > 0 && !(folderActual.id == folder[folder.length - 1].id)) {
            dispatch(navigationStorageClusterFolder(folderActual));
        }
    }

    return (
        <Container title={folderActual.name} onClick={handleClickButton}>
            {children ? children : folderActual.name}
        </Container>
    )
}

export default ButtonPath