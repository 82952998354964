import styled from "styled-components";
import { mobile } from "../../../../../../components/NavBar/style";

export const ContainerWelcome = styled.div`
  border-radius: 14px;
  background: #2f80ed;
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 990px) {
    grid-column: 1 / span 1;
  }
  .container-welcome {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 2rem;
  }
  h3 {
    color: #fff;
    font-family: "inter", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    margin: 0px;
    margin-top: 1.5rem;
    line-height: normal;
  }
  h1 {
    margin: 0px;
    padding: 0px;
    color: #fff;
    font-family: "inter-bold", sans-serif;
    font-size: 2rem;
    font-style: normal;
    line-height: normal;
  }

  span {
    display: block;
    color: #fff;
    font-family: "inter";
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  @media only screen and (max-width: ${mobile}) {
    grid-template-columns: 1fr;
  }
`;
