import { createContext, useEffect, useRef, useState } from 'react';
/* import Peer from 'simple-peer'; */
import { connect } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { getAllClusters } from '../store/actions/folderAction';
import { findAllContacts } from '../store/actions/contactsAction';
import { bindActionCreators } from 'redux';
import { setNewSocket } from '../store/actions/socket';
import { TIME_CALLING } from '../util/verification';
import { patchNotAnswer, postCreateRegister } from './requests/call-register';
import { CallRegisterStatus } from '../types/project';

const SocketContext = createContext();

const ContextProviderInterface = (props) => {
  const { children, abareId, name, socket, getAllClusters, findAllContacts, setNewSocket } = props;
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [call, setCall] = useState(null);
  const { goBack } = useHistory()
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef();
  const [timeForAccept, setTimeForAccept] = useState(null);
  const userId = useLocation().state?.userToCall || null;

  useEffect(() => {
    socket.on('calluser', ({ from, name, signal }) => {
      document.title = process.env.REACT_APP_NAME + " (1)"
      setCall({ isReceivingCall: true, from, name, signal });

      setTimeout(() => {
        setCall(null);
      }, TIME_CALLING);
    });
  }, []);

  useEffect(() => {
    if (!callAccepted) {
      if (timeForAccept > 0) {
        const delayDebounceFn = setTimeout(() => {
          const time = (timeForAccept + 1 <= (TIME_CALLING + 4000) / 1000) ? timeForAccept + 1 : 0;
          setTimeForAccept(time)
        }, 1000);
        return () => clearTimeout(delayDebounceFn)
      } else if (timeForAccept !== null) {
        const data = {
          userIdContact: userId,
          type: true,
          createdAt: new Date(),
          status : CallRegisterStatus.NAO_ATENDEU,
        }
        console.log("O CARA NAO ATENDEUUUUUUUUUUUUUUU")

        postCreateRegister(data).then((res)=>{
          console.log("res", res)
        })
      }
    } else {
      setTimeForAccept(null)
    }
  }, [timeForAccept, callAccepted])

  useEffect(() => {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || null;

    if (!connection) {
      return;
    }

    connection.addEventListener('change', function () {
      setNewSocket()
    });

  }, []);

  useEffect(() => {
    function updateSocketIDRoom() {
      if (abareId) {
        socket.emit("join", abareId);
        getAllClusters();
        findAllContacts();
      }
    }
    updateSocketIDRoom();
  }, [socket, abareId])

  useEffect(() => {
    if (callEnded) {
      setCall(null);
      setCallAccepted(false);
    }
  }, [callEnded]);


  const getMyVideo = () => {
    return (navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((currentStream) => {
        setStream(currentStream);
        myVideo.current.srcObject = currentStream;
        return currentStream;
      }));
  }

  const answerCall = (myStream) => {
    setCallAccepted(true);

    const peer = new window.SimplePeer({ initiator: false, trickle: false, stream: myStream });

    //peer.addStream(myStream);
    peer.on('signal', (data) => {
      socket.emit('answercall', { signal: data, to: call.from });
    });

    peer.on('stream', (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    peer.on('close', function () {
      console.log('close');
      leaveCall()
      socket.off("callaccepted");

    });

    peer.signal(call.signal);
    connectionRef.current = peer;
  };

  const callUser = (id, myStream) => {
    const peer = new window.SimplePeer({ initiator: true, trickle: false, stream: myStream });
    //peer.addStream(myStream)

    setTimeForAccept(1);
    peer.on('signal', (data) => {
      socket.emit('calluser', { userToCall: id, signalData: data, from: abareId, name });
    });

    peer.on('stream', (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });

    socket.on('callaccepted', (signal) => {
      peer.signal(signal);
      setCallAccepted(true);
      setTimeout(() => {
        //peer.removeStream(myStream)
        //peer.destroy()
        /*  peer.emit('close') */
      }, 3000);
    });

    peer.on('close', function () {
      console.log("close")
      leaveCall();
      socket.off("callaccepted");
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {
    console.log("stream", stream)
    if (stream) {
      setCallEnded(true);
      setCall(null);
      setCallAccepted(false);
      stream.getTracks().forEach(function (track) {
        console.log("stop audio e camera")
        try {
          track.stop();

        } catch (error) {
          console.log("error ao fechar a camera", error)
        }
      });
      connectionRef.current.destroy();
      goBack();
    }
  };

  const mutedAudio = (enabled) => {
    const newAudio = Object.assign(stream.getAudioTracks()[0], { enabled })
    connectionRef.current.replaceTrack(stream.getAudioTracks()[0], newAudio, stream);
  };

  const mutedVideo = (enabled) => {
    const newVideo = Object.assign(stream.getVideoTracks()[0], { enabled })
    connectionRef.current.replaceTrack(stream.getVideoTracks()[0], newVideo, stream);
  };
  return (
    <SocketContext.Provider value={{
      call,
      callAccepted,
      myVideo,
      userVideo,
      getMyVideo,
      callUser,
      answerCall,
      leaveCall,
      mutedAudio,
      mutedVideo,
      setCall
    }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    abareId: state.abare.abare.id,
    name: state.abare.abare.name,
    socket: state.socket
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  getAllClusters,
  findAllContacts,
  setNewSocket
}, dispatch)


const ContextProvider = connect(mapStateToProps, mapDispatchToProps)(ContextProviderInterface);

export { ContextProvider, SocketContext };
