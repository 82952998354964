import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.backgrounds.white};
  min-width: 275px;
  height: 185px;
  max-height: 185px;
  margin-top: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05),
    0px 0px 4px rgba(196, 196, 196, 0.15);
  border-radius: 8px;
  padding: 16px;
  max-width: 275px;
  transform: scale(0.9);
  transition: all ease 0.2s;
  :hover {
    transform: scale(1);
  }

  .image_birthday {
    height: 55px;
    width: 55px;
    padding: 4px 0px;
    border-radius: 50%;
  }

  p {
    margin: 0px;
  }

  .p_title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #a6446a;
    line-height: 21px;
    padding-bottom: 8px;
  }

  .p_group {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #2f2e2e;
    padding-bottom: 8px;
  }

  .p_type {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    padding-bottom: 8px;

    border-radius: 5px;
    width: fit-content;
    padding: 2px 8px;
    color: #fff;
  }

  .p_date {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    padding-bottom: 4px;
    padding-top: 4px;
    align-items: center;

    color: #848383;
  }

  .p_content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 8px;

    color: #484848;
  }

  .div_buttom {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }

  .button_confirm {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    padding: 2px 16px;
    margin-right: 8px;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #fff;
    background: #bc718e;
    border-radius: 5px;

    border: 1.5px solid;

    :hover {
      cursor: pointer;
    }
  }

  .button_recuse {
    font-family: "Roboto";
    font-style: normal;
    padding: 2px 16px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #848383;
    border: 1.5px solid #848383;
    border-radius: 5px;

    :hover {
      cursor: pointer;
    }
  }

  .button_cancel {
    font-family: "Roboto";
    padding: 2px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #c94342;
    border: 1.5px solid #c94342;
    border-radius: 5px;

    :hover {
      cursor: pointer;
    }
  }
`;

export const Title = styled.p`
  font-family: "Inter";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: ${({theme})=>theme.texts.titleCards};

`;

interface StyledProps {
  relative?: number | null;
}

export const More = styled.div<StyledProps>`
  color: #47a845;
  background-color:transparent;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  ${(props) => props.relative && props.relative <= 3 && ` position: relative; `}
  height: 42px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  z-index: 2;

  :hover {
    cursor: pointer;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 1rem);
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-bottom: 1px solid #efefef;
  p {
    margin: 0px;
  }

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    line-height: 27px;

    color: #121068;
  }

  .description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;

    color: #595959;
  }

  .div_type {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .type_event {
    align-self: flex-start;
    color: white;
    min-width: 100px;
    text-align: center;
    padding: 4px 1rem;
    border-radius: 25px;
  }
`;

export const Month = styled.div`
  p {
    font-size: 16px;
    font-family: "inter-medium", sans-serif;
    color: ${({ theme }) => theme.texts.contentCards};
  }

  .div-img {
    display: inline-flex;

    :hover {
      cursor: pointer;
    }
  }

  .spaceImage {
    margin-left: -12px;
  }
`;
