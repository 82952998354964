import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../../../store/actions/scheduleAction";
import { EnumScheduleType, TypeConstRedux } from "../../../../../types/redux";
import { Card } from "../style";


const CardTypeEvent = () => {
    const type = useSelector((state: TypeConstRedux) => state.schedule.filter);
    const dispatch = useDispatch();

    const handleType = (type: EnumScheduleType) => {
        dispatch(setFilter(type));
    }

    return <Card>
        <p className="title">Tipo
        </p>
        <form>
            <div className="option" onClick={() => handleType(EnumScheduleType.PERSONAL)} >
                <input type="radio" checked={type === EnumScheduleType.PERSONAL} onChange={() => handleType(EnumScheduleType.PERSONAL)} />
                Agenda Pessoal
            </div>
            <div className="option" onClick={() => handleType(EnumScheduleType.GROUP)}>
                <input type="radio" checked={type === EnumScheduleType.GROUP} onChange={() => handleType(EnumScheduleType.GROUP)} />
                Agenda de Grupos
            </div>
        </form>
    </Card>

}

export default CardTypeEvent;