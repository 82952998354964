import { CancelTokenSource } from "axios";
import {
  Alignment,
  Content,
  ContentImage,
  ContentText,
  PageOrientation,
  PageSize,
} from "pdfmake/interfaces";

export enum ReportEnum {
  CONTACTS_ALL = 1,
  CONTACTS_BIRTHDAYS = 2,
  GROUP_ALL = 31,
  GROUP_MEMBERS = 32,
  EVENT_PARTICULAR = 61,
  EVENT_GROUP = 62 ,
  EVENT_CONFIRMATIONS = 63,
}

export enum ReportOperationFilter {
  //Operação para string
  EQUALS,
  CONTAIN,
  START_WITH,
  END_WITH,
  IS_ANY_OF,

  //Operação para número
  LIKE,
  DIFFERENT,
  LARGER,
  LARGER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,

  //Operação para data
  IS_DATA,
  IS_NOT,
  IS_AFTER,
  IS_ON_OR_AFTER,
  IS_BEFORE,
  IS_ON_OR_BEFORE,

  //Operação para boolean
  IS,

  //Operação para todos
  EMPTY,
  NOT_EMPTY,
}

export enum TypeDataTable {
  BOOLEAN,
  DATA,
  DATA_BIRTHDAY,
  PRESENCE,
  STRING,
  NUMBER,
  SPECIAL_BIO_SEXY,
}

export enum ReportTypeFilter {
  GROUP_ID = "Group_id",
  FOLDER_ID = "Folder_group_id",
  CITY = "city",
  BIO_SEXY = "bioSex",
  SEARCH = "search",
}
export enum EnumCategoryNotification {
  BIRTHDAY = "1",
  AVISO = "2",
  EVENT = "3",
}

export enum EnumBorderTable {
  NO_BORDER,
  ONLY_HORIZONTAL,
  ONLY_HEADER,
  DEFAULT,
}

export enum EnumBackgroundTable {
  NO_BACKGROUND,
  ZEBRA,
  ONLY_HEADER,
}

export enum EnumPositionHorizontal {
  LEFT,
  CENTER,
  RIGHT,
}
export type ReportTypesValueFilter =
  | undefined
  | string
  | number
  | boolean
  | Date
  | OptionSelect[];
export type ReportFilterValue = {
  key: string;
  type: ReportOperationFilter;
  value: ReportTypesValueFilter;
};

export type ReportSelectFilter = {
  label: string;
  value: ReportOperationFilter;
};
export type ContentHeader =
  | (ContentText & { position: EnumPositionHorizontal })
  | (ContentImage & { position: EnumPositionHorizontal; url: string });

export type ContentBody =
  | (ContentText & { id: string })
  | (ContentImage & { id: string });
export type ContentTextPosition = ContentText & {
  position: EnumPositionHorizontal;
};
export interface ReportStylesInterface {
  lineHeight: number | "auto";
  orientation: PageOrientation;
  title: ContentText;
  signature: boolean;
  page: PageSize;
  visibleTable: boolean;
  visibleTitle: boolean;
  body: ContentBody[];
  sortBody: string[] | undefined;
  margins: [number, number, number, number];
  styleBorder: EnumBorderTable;
  background: EnumBackgroundTable;
  colorBackground: string;
  alignmentTable: Alignment;
  marginTable: [number, number, number, number];
  colorBorder: string;
  headerContent: [any[], any[], any[]];
  footerContent: [Content[], Content[], Content[]];
  footer: {
    pagination: ContentTextPosition;
    dateCreated: ContentTextPosition;
  };
}

export interface optionsFilter {
  key: ReportTypeFilter;
  values: OptionSelect[];
}

export enum EnumOrdering {
  CRESCENT,
  DESCENDING,
}

export type ReportOrdering = {
  column: string | undefined;
  type: EnumOrdering;
};
export interface ReportOptionsInterface {
  key: string | null | TEvent;
  filters: optionsFilter[];
  filterUnion: boolean;
  filtersOperations: ReportFilterValue[];
  requiredId: boolean;
  message: string | undefined;
  datePeriod: [Date, Date] | undefined;
  requiredPeriod: boolean;
  ordering: ReportOrdering;
  generalSearch: string;
}

export type OptionSelect = {
  value: number | string | TEvent;
  label: string;
};

export type TNotification = {
  User_id: string;
  category: EnumCategoryNotification;
  createdAt: Date;
  description: string;
  id: number;
  message?: string;
  status: boolean;
  user?: TUser;
  event?: TEvent;
};

export type TUser = {
  id: string;
  name: string;
  nickname: string | null;
  photoUrl: string | null;
  email: string | null;
  phoneNumber: string | null;
  cpf: string | null;
  cnpj: string | null;
  birthday: string | null;
  voterNumber: string | null;
  voterZone: string | null;
  voterSection: string | null;

  admin: boolean | null;
  checked: boolean | null;
  hidden: boolean | null;
};

export interface TUserGroupCheck extends TUser {
  admin: boolean;
  checked: boolean | null;
  hidden: boolean | null;
}

export interface TUserPrivacy extends TUser {
  privacy: TPrivacy;
}

export interface TPrivacy {
  name: string | null;
  email: string | null;
  birthday: string | null;
  cpf: string | null;
  geoRef: string | null;
  phone: boolean | null;
  voter: string | null;
  groupAdd: string | null;
  call: string | null;
}

export type TMessage = {
  Personal_id: string | null;
  nameUser: string | null;
  msgGroup_id_root: string | null;
  User_id: string;
  msg_id: string | null;
  createdAt: string | Date | null;
  description: string | null;
  status: string;
  sendBy: string;
  type: TypePersonal | null;
  file: Blob | null;
  fileType: string | null;
  fileName: string | null;
  fileNamePreview: string | null;
  fileURL: string | null;
  fileURLPreview: string | null;
  fileTime: string | null;
  msg_id_root: string | null;
  uuid: string | null;
  blobUrl: string | null;
  source: CancelTokenSource | null;
  //if it's a group
  statusRequest: TypeStatusRequest | null;
  readAt: Date | null;
  Group_id: string | null;
};

export type TMessageGroup = {
  Group_id: string | null;
  User_id: string | null;
  createdAt: string | null;
  description: string | null;
  msgGroup_id: string | null;
  nickname: string | null;
  status: string | null;
  fileType: string | null;
  fileName: string | null;
  fileURL: string | null;
  finalTime: string | null;
  msgGroup_id_root: string | null;
  namesView: string;
};

export interface TProfileGroup extends TGroupInfo {
  lastMessagesWithMidia: any;
  participants: any[];
  roles: TRoleInfo[];
  leaders: any[];
  //if null: user is not a member (user exit group)
  userGroup?: TUserGroup | null;
  isRequest?: boolean;
}

export interface TUserGroup {
  Group_id: string;
  User_id: string;
  admin: boolean;
  configSilence: boolean;
  dateBegin: Date;
  dateEnd: Date;
  fix: boolean;
  privacyBirthday: TypePrivacyUser;
  privacyCpf: TypePrivacyUser;
  privacyEmail: TypePrivacyUser;
  privacyName: TypePrivacyUser;
  privacyPhone: TypePrivacyUser;
  privacyVoter: TypePrivacyUser;
}

export type TGroupInfo = {
  Group_id_root: string | null;
  User_id_creator: string;
  configMessage: string | null;
  description: string | null;
  fix: boolean | null;
  geoRefLat: number | null;
  geoRefLon: number | null;
  id: string;
  groupType: boolean | null;
  lastAccess: Date | null;
  link: string | null;
  memberLimit: number | null;
  memberNumber: number | null;
  messageTime: number | null;
  name: string;
  photoUrl: string | null;
  invite: string | null;
  address: string | null;
};

export type TRoleInfo = {
  role: TRole;
  users: any[];
};

export type TRole = {
  Group_id: number;
  acronym: string | null;
  active: boolean;
  color: string;
  description: string | null;
  id: number;
  name: string;
};

export enum TypeMessage {
  UNDEFINED = "0",
  IMAGE = "1",
  VIDEO = "2",
  AUDIO = "3",
  PDF = "4",
  DOC = "5",
  DOCX = "6",
  PPT = "7",
  PPTX = "8",
  XLS = "9",
  XLSX = "10",
  ZIP = "11",
  VOICE = "12",
  FOLDER = "13",
  // null = mensagem normal (sem mídia)
}

export const DownloadType = {
  [TypeMessage.IMAGE]: "jpg",
  [TypeMessage.VIDEO]: "video",
  [TypeMessage.AUDIO]: "mp3",
  [TypeMessage.PDF]: "pdf",
  [TypeMessage.DOC]: "doc", //doc
  [TypeMessage.DOCX]: "docx", //doc
  [TypeMessage.PPTX]: "pptx",
  [TypeMessage.PPT]: "ppt",
  [TypeMessage.XLS]: "xls", //xls
  [TypeMessage.XLSX]: "xlsx", //xlsx*/
  [TypeMessage.ZIP]: "zip",
};

export enum TypeStatusSharing {
  READER,
  EDITOR,
}

export enum TypeStatusRequest {
  UPLOADING = "-1",
  DOWNLOADING = "0",
  SUCCESS = "1",
  ERRODOWN = "2",
  ERROUP = "3",
  FILETOOLARGE = "4",
  LIMITMIDIA = "5",
}

export enum TypeStatusMessage {
  NOTSEND = "0",
  NOTRECEIVED = "1",
  SEND = "2",
  READ = "3",
  CANCELED = "4",
}

export enum TypeApplication {
  AUDIO = "audio",
  VIDEO = "video",
  IMAGE = "image",
  VOICE = "VOICE",
  PDF = "pdf",
  DOC = "msword", //doc
  DOCX = "vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
  PPT = "vnd.ms-powerpoint", //ppt
  PPTX = "vnd.openxmlformats-officedocument.presentationml.presentation", //pptx
  XLS = "vnd.ms-excel", //xls
  XLSX = "vnd.openxmlformats-officedocument.spreadsheetml.sheet", //xlsx
  ZIP = "zip",
}

export interface TClient extends TPersonal, TGroup {
  name: string;
  photoUrl: string;
  type: TypePersonal;
  countMessage: number;
  lastMessage: TMessage | null;

  checked?: boolean | null;
}
export interface TPersonal {
  Personal_id: string | null;
  nickname: string;
  User_id_0: string | null;
  User_id_1: string;
  contactInfo: TContact | null;
  blockedMe: boolean;
  iBlocked: boolean;
  photoUrl: string;
  status?: number;
}

export interface TGroup extends TProfileGroup {
  Group_id: string;
  nameLastMsg: string | null;
  namesView: string[];
}

export enum TypePersonal {
  GROUP = 0,
  PERSONAL = 1,
  SECRET = 2,
  GUEST = 100,
}

export enum TypePrivacyUser {
  ME = "1",
  CONTACTS = "2",
  GROUPS = "3",
  EVERYBODY = "4",
}

export type TCluster = {
  id: string;
  name: string;
  order: string | null;
  fix: string | null;
  User_id: string | null;
  qtdeItens: string | null;
  itens: TClient[];
  isClose?: boolean;
};

export type TContact = {
  id: string;
  name: string;
  birthday: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  relationship: TypeRelationship | null;
  address: string | null;
  User_id_owner: string;
  User_id_contact: string;
  checked: boolean | null;
  contactId: number | null;
  photoUrl: string;
};

export type TypeImage = {
  defaultUrl?: string;
  newUrl?: string;
  mainUrl?: string;
  file?: any;
  type?: any;
};

export enum TypeRelationship {
  FAMILY = "1",
  WORK = "2",
  FRIEND = "3",
  SCHOOL = "4",
  SOCIETY = "5",
}

export enum TypeDeleteMessage {
  ME = 1,
  ALL = 2,
}

export type TEvent = {
  EventCategory_id: string | null;
  Event_id_root: string | null;
  confirmations: number | null;
  group_name: string | null;
  dateEnd: Date;
  dateStart: Date;
  description: string;
  local?: string;
  User_id?: string;
  id: number;
  status: string;
  title: string;
  Group_id: null | string;
  type: number | null;
  edit: boolean | null;
  participation: boolean | null | 1 | 0;
  name_group?: string;
  isCanPresence?: boolean;
  isCanEdit?: boolean;
  isCanDelete?: boolean;
};

export type TUserPresence = {
  Event_id: number;
  checked: boolean;
  hidden: boolean;
  id: string;
  name: string;
  nickname: string;
  participation: number;
  percentage: number;
  photoUrl: string;
  presence: boolean;
};

export enum TypeStatusEvent {
  PROGRAMMED = "0",
  HAPPENING = "1",
  CLOSED = "2",
  CALLEDOFF = "3",
  SUSPENDED = "4",
}

export enum CONSTS {
  GENERAL = 0,
}

export enum UserStatus {
  CRIADO = "0",
  ATIVADO = "1",
  DESATIVADO = "2",
  SUSPENSO = "3",
  BLOQUEADO = "4",
}

export enum CallRegisterStatus {
  NAO_ATENDEU = "0",
  REJEITOU = "1",
  ACEITOU = "2",
}

export enum OptionConfig {
  MY_PERFIL,
  CONTACTS,
  THEME,
  PRIVACY,
  NOTIFICATION,
}

export interface TCategory {
  id: number;
  name: string;
  active: boolean;
  hidden?: boolean;
}
export type UserSharing = {
  id: string;
  name: string;
  url: string;
};

export type FileRepository = {
  fileName: string;
  name: string;
  invite: null | boolean;
  sharing: number;
  fileType: string;
  fileURL: string;
  fileNamePreview: string;
  fileUrlPreview: string;
  createdAt: Date;
  editedAt: Date;
  filePath: string;
  User_id: string;
  StorageCluster_id: string | null;
  usersSharing: UserSharing[];
};

export type FolderRepository = {
  id: string;
  name: string;
  invite: null | boolean;
  sharing: number;
  usersSharing: UserSharing[];
  color: string;
  filesNumber: number;
  size: number;
  createdAt: Date;
  editedAt: Date;
  User_id: string;
  StorageCluster_id: string | null;
};

export interface SVGComponent extends React.SVGProps<SVGSVGElement> {
  color?: string;
  size?: number;
  navBar?: boolean;
}
