import { saveAs } from "file-saver";
import { getMessages } from "../../../services/requests/message";
import { getMessagesGroup } from "../../../services/requests/message-group";
import {
    DownloadType,
    TMessage,
    TypeMessage,
    TypePersonal,
    TypeStatusRequest,
} from "../../../types/project";
import { getClientId, transformUrlImage } from "../../../util/verification";
import { getAllState, setChat } from "./messageAction";

const { DOWNLOADING, SUCCESS, ERRODOWN } = TypeStatusRequest;
const { IMAGE, VIDEO, VOICE } = TypeMessage;
const { GUEST, PERSONAL } = TypePersonal;

export const spliceMessage = (message: TMessage, id?: string) => {
  return (dispatch: any, getState: any) => {
    const { globalMessages } = getAllState(getState());
    const clientId = getClientId(message);

    const { messages } = globalMessages[clientId];
    const { msg_id } = message;
    const indexImg = messages.findIndex(
      (msg) => msg.msg_id == msg_id || msg.msg_id == id
    );
    messages[indexImg] = message;
    dispatch(setChat(clientId, { messages: messages }));
  };
};

export const fetchMessage = (message: TMessage) => {
  return (dispatch: any) => {
    dispatch(spliceMessage({ ...message, statusRequest: DOWNLOADING }));
    fetch(transformUrlImage(message.fileURL!))
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else {
          dispatch(spliceMessage({ ...message, statusRequest: ERRODOWN }));
        }
      })
      .then((blob) => {
        const { fileName, fileType } = message;
        const getNameFile = () => {
          const name = fileName!.split(/-(.*)/s)[1];
          return name ? name : fileName;
        };

        if (blob) {
          if (fileType == IMAGE || fileType == VIDEO || fileType == VOICE) {
            dispatch(
              spliceMessage({
                ...message,
                statusRequest: SUCCESS,
                blobUrl: URL.createObjectURL(blob),
              })
            );
          } else {
            saveAs(blob, getNameFile() + "." + DownloadType[fileType!]);
            dispatch(
              spliceMessage({
                ...message,
                statusRequest: SUCCESS,
                blobUrl: URL.createObjectURL(blob),
              })
            );
          }
        }
      })
      .catch(() => {
        dispatch(spliceMessage({ ...message, statusRequest: ERRODOWN }));
      });
  };
};

export const getChatByPage = (newClient?) => {
  return (dispatch, getState) => {
    const { globalMessages, personal } = getAllState(getState());
    const client = newClient ? newClient : personal;
    const clientId = getClientId(client);

    if (client.type !== GUEST) {
      const { loadPage, currentPage, messages, totalPages } =
        globalMessages[clientId];

      if ((!loadPage && !currentPage) || currentPage < totalPages) {
        dispatch(setChat(clientId, { loadPage: true }));
        const getRoute =
          client.type == TypePersonal.GROUP ? getMessagesGroup : getMessages;
        getRoute(clientId, currentPage).then((res) => {
          if (res.status == 200) {
            const msg = currentPage
              ? [...res.data.data, ...messages]
              : res.data.data;
            dispatch(
              setChat(clientId, {
                ...res.data,
                messages: msg,
                data: null,
                currentPage: currentPage + 1,
                scroll: messages.length ? { key: messages[0]?.msg_id } : null,
              })
            );
          }
        });
      }
    }
  };
};
