import styled, { keyframes } from "styled-components";
import { mobile } from "../../components/NavBar/style";

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background: linear-gradient(252.44deg, #251f33 0%, #1d1a23 100%);
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 56px;
  line-height: 84px;
  margin: 3rem 0 1rem 0;

  .icon_title {
    height: 50px;
    width: auto;
  }

  @media only screen and ((max-width: 1450px) or (max-height: 660px)) {
    line-height: 16px;
    .icon_title {
      height: 40px;
      width: auto;
    }
  }

  @media only screen and (max-height: 520px) {
    display: none;
  }
`;

const WaveEffect = keyframes`
     0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(4);
        opacity: 0;
    }
`;
export const Waves = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & .delay1 {
    -webkit-animation: ${WaveEffect} 2.5s linear;
    animation: ${WaveEffect} 2.5s linear;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  & .delay2 {
    -webkit-animation: ${WaveEffect} 2.5s linear 0.7s forwards;
    animation: ${WaveEffect} 2.5s linear 0.7s forwards;
  }
  & .delay3 {
    -webkit-animation: ${WaveEffect} 2.5s linear 1.3s forwards;
    animation: ${WaveEffect} 2.5s linear 1.3s forwards;
  }
  & .delay4 {
    -webkit-animation: ${WaveEffect} 2.5s linear 1.9s forwards;
    animation: ${WaveEffect} 2.5s linear 1.9s forwards;
  }

  & .circle {
    display: block;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background: rgba(38, 255, 199, 0.1);
    animation-iteration-count: infinite;
    transition: 5s ease;
    position: absolute;
    top: calc(50% - 45px);
  }
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  z-index: 10;
  background: linear-gradient(221.46deg, #085a6d 7.6%, #0a3e4b 95.57%);
`;

export const CallVideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 150px;

  @media only screen and (max-width: 1350px) {
    column-gap: 50px;
  }

  @media only screen and (max-height: 520px) {
    align-content: center;
    flex: 1;
    width: 100%;
  }

  @media only screen and (max-width: ${mobile}) {
    .myMobile {
      position: absolute;
      width: auto;
      height: 20vh;
      background: none;
      z-index: 2;
      bottom: 15vh;
      right: 0;
      margin-right: 0.5rem;
      border-radius: 12px !important;
    }

    .myNotebook {
      position: absolute;
      width: auto;
      height: 20vh;
      background: none;
      box-shadow: none;
      margin-right: 0.5rem;
      z-index: 2;
      bottom: 15vh;
      right: 0;
      border-radius: 12px !important;
    }

    .userMobile {
      position: absolute;
      z-index: 1;
      border: none;
      width: 100vw;
      height: 100%;
      object-fit: cover;
      top: 0;
      right: 0;
      border-radius: 0px !important;
    }

    .userNotebook {
      position: absolute;
      z-index: 1;
      border: none;
      width: 100vw;
      object-fit: cover;
      height: 60vh;
      margin: auto;
      top: 0px;
      bottom: 0px;
      right: 0;
      box-shadow: none;
      border-radius: 12px !important;
    }
  }
`;

export const CallVideo = styled.video`
  width: 560px;
  transform: rotateY(180deg);
  height: 420px;
  background-color: #000;
  background: linear-gradient(240.62deg, #0b3c48 -5.37%, #085b6e 101.71%);
  box-shadow: 0px 4px 4px rgba(18, 123, 149, 0.15);
  border-radius: 16px;

  @media only screen and (max-width: 1200px) {
    width: 420px;
    height: 315px;
  }
  @media only screen and (max-width: 900px) {
    width: 336px;
    height: 252px;
  }

  @media only screen and (max-height: 520px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 252px;
  }

  @media only screen and (max-height: 320px) {
    width: 100%;
    max-height: 212px;

  }
`;

export const CallMyVideo = styled.video`
  width: 560px;
  height: 420px;
  transform: rotateY(180deg);

  background-color: #000;
  background: linear-gradient(240.62deg, #0b3c48 -5.37%, #085b6e 101.71%);
  box-shadow: 0px 4px 4px rgba(18, 123, 149, 0.15);
  border-radius: 16px;

  @media only screen and (max-width: 1200px) {
    width: 420px;
    height: 315px;
  }
  @media only screen and (max-width: 900px) {
    width: 336px;
    height: 252px;
  }

  @media only screen and (max-height: 520px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 252px;
  }

  @media only screen and (max-height: 320px) {
    width: 100%;
    max-height: 212px;

  }
`;
export const CallOptionsList = styled.div`
  display: flex;
  width: 15%;
  z-index: 3;
  min-width: 374px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
  .hidden {
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }

  @media only screen and (max-height: 520px) {
    .hidden {
      display: flex;
    }
    margin-bottom: 1rem;
  }
  /* @media only screen and (max-width: 650px) {
    .hidden {
      display: none;
    }
  } */
  @media only screen and (max-height: 520px) {
    width: 100%;
    justify-content: center;
  }

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    justify-content: center;
  }

  .noMargin {
    margin-right: 0 !important;
  }
`;

export const CallOption = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  transition: all 0.3s ease;

  .icon {
    font-size: 32px;
  }

  @media only screen and (max-width: 1450px) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (max-height: 660px){
    width: 60px;
    height: 60px;
  }

  @media only screen and (max-height: 520px){
    width: 45px;
    height: 45px;
    margin-right: 2.5rem;
    .icon {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: ${mobile}) {
    width: 45px;
    height: 45px;
    margin-right: 2.5rem;
    .icon {
      font-size: 24px;
    }
  }
`;

CallOption.defaultProps = {
  color: "#A9B9C9",
};
