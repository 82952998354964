import { } from 'react'
import { TypeMessage, TypeStatusRequest } from '../../../types/project'
import { Doc } from './style'
import { FiUpload, MdOutlineFileDownloadOff, MdOutlineFileDownloadDone, FiDownload } from '../../../assets/icons';
import { FileCircle, FileLoad } from '../RequestStatus';

const Document = (props) => {
    const { message, myMessage, setMessage, fetchMessage } = props
    const { fileName, description, fileType } = message
    const { DOWNLOADING, SUCCESS, ERRODOWN, ERROUP, UPLOADING } = TypeStatusRequest


    const getNameFile = () => {
        const name = fileName.split(/-(.*)/s)[1]
        return name ? name : fileName
    }

    const StatusFile = () => {
        switch (message.statusRequest) {
            case DOWNLOADING:
            case UPLOADING:
                return (
                    <FileLoad myMessage={myMessage} onClick={() => { }} />
                );

            case ERROUP:
                return (
                    <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => setMessage(message)} >
                        <FiUpload />
                    </FileCircle>
                )

            case ERRODOWN:
                return (
                    <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => fetchMessage(message)} >
                        <MdOutlineFileDownloadOff />
                    </FileCircle>
                );
            case SUCCESS:
                return (
                    <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => fetchMessage(message)}>
                        <MdOutlineFileDownloadDone />
                    </FileCircle>
                );

            default:
                return (
                    <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => fetchMessage(message)}>
                        <FiDownload />
                    </FileCircle>
                )
        }
    }

    return (
        fileType != TypeMessage.IMAGE && fileType ? (
            <Doc isMy={myMessage} hasDescription={description ? true : false}>
                <StatusFile />
                <div className="data-file">
                    {fileName ? getNameFile() : "Arquivo sem título"}
                    <div className="size"> {message.fileSize}</div>
                </div>
            </Doc>
        )
            :
            null
    )
}

export default Document