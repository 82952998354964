import { ContentText } from "pdfmake/interfaces";
import { BiAlignMiddle, BiBold, BiCaretDownSquare, BiCaretLeftSquare, BiCaretRightSquare, BiCaretUpSquare, BiFontColor, BiFontFamily, BiFontSize, BiItalic } from "react-icons/bi";
import { FiGrid } from "react-icons/fi";
import { MdSubtitles } from "react-icons/md";
import { TbBoxMargin } from "react-icons/tb";
import { InputSelect } from "../../../../../components";
import { Checkbox, InputColor } from "../../../../../components/Input";
import { ContainerColor, ContainerInput } from "../../../../../components/Input/style";
import { ReportStylesInterface } from "../../../../../types/project";
import { Utils } from "../../../../../util/constantes";
import { ItemModal } from "../style";

interface ColumnsProps<T extends ContentText> {
    textEditable?: boolean;
    isEditing?: boolean;
    valueText: T;
    setValueText: React.Dispatch<React.SetStateAction<T>>;
}

const TextOptions = <T extends ContentText>({ textEditable = true, isEditing = true, valueText, setValueText }: ColumnsProps<T>) => {
    const alignmentValues = [
        { value: "left", label: "Esquerda" },
        { value: "right", label: "Direita" },
        { value: "center", label: "Centralizada" },
        { value: "justify", label: "Justificada" }];
    const chaves = Object.keys(valueText) as (keyof T)[];
    return (
        <ItemModal>
            {chaves.some((elem) => elem == "position") && <div className="flex" style={{ minWidth: "230px", flex: 1, display: "flex", flexDirection: "row" }}>
                <div className="icon margin-right">
                    <FiGrid title="Alinhamento" size="20px" />
                </div>
                {
                    isEditing ?
                        <InputSelect
                            $flex={true}
                            value={Utils.positionValues.find((option) => (option.value === valueText["position"]))}
                            onChange={(e) => {
                                valueText["position"] = e.value;
                                setValueText({ ...valueText })
                            }}
                            placeholder="Selecione a posição do elemento" options={Utils.positionValues} />
                        : <p>{Utils.positionValues.find((option) => (option.value === valueText["position"]))?.label ?? "Nenhum valor definido"}</p>
                }

            </div>}
            {textEditable && <div className="flex" style={{ minWidth: "230px", flex: "1" }}>
                {/* Texto */}
                <div className="icon">
                    <MdSubtitles title="Texto" size="20px" />
                </div>
                {isEditing ?
                    <ContainerInput>
                        <textarea
                            name='value_text'
                            value={valueText.text.toString()}
                            onChange={(e) => {
                                valueText.text = e.target.value;
                                setValueText({ ...valueText })
                            }}
                            id='value_text'
                            placeholder="Digite o texto aqui ..."
                        />
                    </ContainerInput> :
                    <p>{valueText.text.toString()}</p>}
            </div>}
            <div className="flex" style={{ minWidth: isEditing ? "150px" : "250px", flex: "1" }}>
                {/* Campo de alinhamento da coluna */}
                <div className="icon">
                    <BiAlignMiddle title="Alinhamento" size="20px" />
                </div>
                {isEditing ?
                    <InputSelect
                        $flex={true}
                        value={alignmentValues.find((option) => (option.value === valueText.alignment))}
                        onChange={(e) => {
                            valueText.alignment = e.value
                            setValueText({ ...valueText })
                        }} placeholder="Alinhamento" options={alignmentValues} />
                    :
                    <p>{alignmentValues.find((option) => (option.value === valueText.alignment))?.label}</p>
                }
            </div>
            <div className="flex" style={{ minWidth: isEditing ? "150px" : "300px", flex: "1" }}>

                <div className="icon">
                    <BiFontFamily title="Familia da fonte" size="20px" />
                </div>
                {
                    isEditing ? <InputSelect
                        $flex={true}
                        value={Utils.fontValues.find((option) => (option.value === valueText["font"])) ?? Utils.fontValues[0]}
                        onChange={(e) => {
                            valueText["font"] = e.value;
                            setValueText({ ...valueText })
                        }}  placeholder="Familia da fonte" options={Utils.fontValues} /> :
                        <p>{Utils.fontValues.find((option) => (option.value === valueText["font"]))?.label ?? Utils.fontValues[0].label}</p>
                }

            </div>
            <div className="flex" style={{ minWidth: "60px" }}>
                {/* Tamanho fonte */}
                <div className="icon">
                    <BiFontSize title="Tamanho fonte" size="20px" />
                </div>
                {
                    isEditing ? <ContainerInput $width="50px">
                        <input
                            name='font_size'
                            value={valueText.fontSize?.toString()}
                            onChange={(e) => {
                                valueText.fontSize = parseInt(e.target.value)
                                setValueText({ ...valueText })
                            }}
                            id='font_size'
                            type="number"
                        // placeholder="Digite o tamanho da fonte..."
                        />
                        pt
                    </ContainerInput> :
                        <p>{valueText.fontSize?.toString()}</p>
                }

            </div>
            <div className="flex" style={{ minWidth: "45px" }}>

                {/* Campo para font em negrito */}
                <div className="icon">
                    <BiBold title="Negrito" size="20px" />
                </div>
                {
                    isEditing ? <Checkbox
                        checked={valueText.bold ?? false}
                        name='bold_font'
                        onChange={() => {
                            valueText.bold = !valueText.bold;
                            setValueText({ ...valueText })
                        }} id='bold_font'
                    /> :
                        <p>{valueText.bold ? "Sim" : "Não"}</p>
                }
            </div>
            <div className="flex" style={{ minWidth: "45px" }}>

                {/* Campo para font em negrito */}
                <div className="icon">
                    <BiItalic title="Itálico" size="20px" />
                </div>
                {
                    isEditing ? <Checkbox
                        checked={valueText.italics ??false}
                        name='italic_font'
                        onChange={() => {
                            valueText.italics = !valueText.italics;
                            setValueText({ ...valueText })
                        }} id='italic_font'
                    /> :
                        <p>{valueText.italics ? "Sim" : "Não"}</p>
                }
            </div>
            <div className="flex" style={{ minWidth: "60px" }}>

                {/* Cor fonte */}
                <div className="icon">
                    <BiFontColor title="Cor da fonte" size="20px" />
                </div>
                {
                    isEditing ? <InputColor value={valueText.color!} onChange={(color) => {
                        valueText.color = color.hex;
                        setValueText({ ...valueText })
                    }} />
                        :
                        <ContainerColor color={valueText.color!} />

                }

            </div>
            {/* Campo de Margin da pagina */}
            <div className="flex" style={{ minWidth: "230px", flex: "1" }}>
                <div className="icon">
                    <TbBoxMargin title="Margem" size="20px" />
                </div>

                {isEditing ? <>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_left'
                            title="Margem esquerda"
                            value={valueText.margin![0]}
                            onChange={(e) => {
                                valueText.margin = [parseInt(e.target.value), valueText.margin![1], valueText.margin![2], valueText.margin![3]]
                                setValueText({ ...valueText })
                            }}

                            id='margin_left'
                            type="number"
                            placeholder="Esquerda"
                        />
                        <BiCaretLeftSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_top'
                            title="Margem superior"

                            value={valueText.margin![1]}
                            onChange={(e) => {
                                valueText.margin = [valueText.margin![0], parseInt(e.target.value), valueText.margin![2], valueText.margin![3]]

                                setValueText({ ...valueText })
                            }}
                            id='margin_top'
                            type="number"
                            placeholder="Topo"
                        />
                        <BiCaretUpSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_right'
                            title="Margem direita"

                            value={valueText.margin![2]}
                            onChange={(e) => {
                                valueText.margin = [valueText.margin![0], valueText.margin![1], parseInt(e.target.value), valueText.margin![3]]

                                setValueText({ ...valueText, })
                            }}
                            id='margin_right'
                            type="number"
                            placeholder="Direita"
                        />
                        <BiCaretRightSquare />
                    </ContainerInput>
                    <ContainerInput>
                        <input
                            title="Margem inferior"
                            name='margin_bottom'
                            value={valueText.margin![3]}
                            onChange={(e) => {
                                valueText.margin = [valueText.margin![0], valueText.margin![1], valueText.margin![2], parseInt(e.target.value)]
                                setValueText({ ...valueText })
                            }}
                            id='margin_bottom'
                            type="number"
                            placeholder="Baixo"
                        />
                        <BiCaretDownSquare />

                    </ContainerInput>
                </>
                    :
                    <p>{`[${valueText.margin![0]},${valueText.margin![1]},${valueText.margin![2]},${valueText.margin![3]}]`}</p>
                }

            </div>
        </ItemModal>
    );
}

export default TextOptions;