import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 100%;
  text-align: center;
  flex: 1;

  #icon-alert {
    font-size: 45px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
    margin-bottom: 5px;
  }

  #text {
    margin: 0;
    margin: 3px 32px 0px 32px;
    font-weight: normal;
    color: ${props => props.theme.texts.darkGreyI};
    font-size: 16px;
  }

  #description {
    margin: 3px 32px 8px 32px;
    word-break: normal;
    font-weight: normal;
    font-size: 16px;
    font-family: "inter", sans-serif;
    color: ${props => props.theme.texts.darkGreyI};
  }
`;
