import { TEvent } from "../../../../../types/project";
import { getNameCategory } from "../../../../../util/categoryUtils";
import { formatarPeriodo } from "../../../../../util/date";
import { Container } from "./style";

interface ItemProps {
  event: TEvent;
  onClick: () => void;
}

export function ItemEvent({ event, onClick }: ItemProps) {
  return (
    <Container
      onClick={onClick}
      title={event.title}
      $category={event.type?.toString() ?? ""}
    >
      <hr />
      <div className="content">
        <p className="tag">{getNameCategory(event.type?.toString() ?? "")}</p>
        <p className="title">{event.title}</p>

        <p className="date-event">
          {formatarPeriodo(event.dateStart, event.dateEnd)}
        </p>
      </div>
    </Container>
  );
}
