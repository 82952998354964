import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconPaperProtocol = ({
  color = "#A6446A",
  size = 24,
  fill= 'none',
  ...rest
}: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M4.006 17.4112C4.05987 18.3279 4.46199 19.1893 5.13009 19.8193C5.79818 20.4492 6.68175 20.8001 7.6 20.8H11.0036C11.012 20.6632 11.0336 20.524 11.0684 20.3836L11.5184 18.586C11.6864 17.9115 12.0348 17.2955 12.5264 16.804L17.3224 12.008C17.7689 11.5619 18.3867 11.1492 19 11C19.8569 10.7915 20.8 11.5604 20.8 11.7688V7.6L20.794 7.3888C20.7401 6.47213 20.338 5.61069 19.6699 4.98074C19.0018 4.35079 18.1183 3.99995 17.2 4H12.4V8.8L12.394 9.0112C12.3423 9.89151 11.9693 10.7222 11.3458 11.3458C10.7222 11.9693 9.89151 12.3423 9.0112 12.394L8.8 12.4H4V17.2L4.006 17.4112ZM4.5496 10.4164C4.35861 10.6484 4.21288 10.9142 4.12 11.2H8.8L8.9884 11.1928C9.55985 11.148 10.0964 10.9001 10.5009 10.494C10.9055 10.0879 11.1513 9.55042 11.194 8.9788L11.2 8.8L11.1988 4.12C10.8406 4.23709 10.515 4.43688 10.2484 4.7032L4.7032 10.2484L4.5496 10.4164Z"
        fill="inherit"
      />
      <path
        d="M17.8082 12.5473L12.979 17.3769V17.3779C12.6969 17.6587 12.497 18.0114 12.4011 18.3978L12.0262 19.8957C11.9892 20.0446 11.9914 20.2007 12.0325 20.3485C12.0737 20.4964 12.1523 20.6312 12.2609 20.7396C12.3695 20.8481 12.5043 20.9267 12.6522 20.9677C12.8001 21.0087 12.9561 21.0107 13.105 20.9736L14.6028 20.5996C14.9888 20.5029 15.3413 20.3031 15.6226 20.0217L20.4518 15.193C20.6255 15.0195 20.7633 14.8133 20.8574 14.5865C20.9514 14.3596 20.9999 14.1164 21 13.8709C21.0001 13.6253 20.9518 13.3821 20.8579 13.1551C20.764 12.9282 20.6264 12.722 20.4528 12.5483C20.2792 12.3745 20.0731 12.2367 19.8463 12.1426C19.6194 12.0486 19.3763 12.0001 19.1307 12C18.8852 11.9999 18.642 12.0482 18.4151 12.1421C18.1881 12.236 17.9819 12.3737 17.8082 12.5473Z"
        fill="inherit"
      />
    </Icon>
  );
};

export default IconPaperProtocol;
