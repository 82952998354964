import styled from "styled-components";
import { mobile, mobileHeight } from "../../NavBar/style";

interface ContainerProps {
  $height?: string;
  $minHeight: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: ${(props) => props.theme.backgrounds.purpleLightI};
  max-height: 70vh;
  min-height: ${({ $minHeight }) => ($minHeight ? `300px` : `auto`)};
  ${({ $height }) =>
    $height &&
    `
   height: ${$height};
   @media only screen and (max-height: ${$height})  {
    height: 100vh;
  }
  `}
  .icon-trash {
    cursor: pointer;
    color: red;
    margin-right: 4px;

  }

  @media only screen and ((max-height: ${mobileHeight}) or (max-width: ${mobile})) {
    max-height: 100vh;
    height: 100vh;
  }

  .movieRow--item {
    display: flex;
    margin-bottom: 16px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .notFound {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .scroll {
    display: block;
    max-height: 430px;
    overflow-y: auto;
  }
`;

export const Title = styled.div`
  display: flex;
  position: sticky;
  top: 0px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.backgrounds.purpleLightI};
  color: ${(props) => props.theme.texts.purpleDarkTitle};
  margin: 0;
  padding: 12px 1rem;
  font-size: ${(props) => props.theme.fonts.text_base};
  .container_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    display: flex;
    margin-left: 4px;
    background-color: #888888;
    color: ${(props) => (props.theme.title === "light" ? "white" : "#092232")};
    padding: 6px;
    border-radius: 100%;
    cursor: pointer;
    height: fit-content;

    :hover {
      background-color: ${(props) => props.theme.highlights.default};
    }
  }
  @media only screen and (max-width: ${mobile}) {
    padding: 12px 12px;
  }
  h3 {
    margin: 0px;
  }

  #icon {
    font-size: 16px;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    :hover {
      background-color: ${(props) => props.theme.backgrounds.white};
    }
  }
`;

interface ContentProps {
  padding?: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.backgrounds.white};
  color: ${({ theme }) => theme.texts.darkerGreyII};

  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  .scrolling {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${({ padding }) => (padding ? `1rem 2rem` : `0px`)};
  }
  @media only screen and (max-width: ${mobile}) {
    .scrolling {
      padding: ${({ padding }) => (padding ? `1rem 1.5rem` : `0px`)};
    }
  }

  .only_line {
    color: ${({ theme }) => theme.texts.darkGreyI};
    margin: 0px;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    .hint {
      color: white;
      background-color: ${({ theme }) => theme.begin.color.button.secondary};
      border-radius: 50%;
      margin: 0px 4px;
      padding-bottom: 1px;
    }
  }
  #icon {
    font-size: 20px;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  #icon-purple {
    font-size: 20px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  .textoBlock {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: ${(props) => props.theme.fonts.text_xs};
    color: ${(props) => props.theme.texts.darkGreyI};
    padding: 0rem 3rem 0rem 3rem;
  }

  .titleSection {
    margin: 0px;
    font-size: 24px;
    font-family: "inter-semi-bold", sans-serif;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #row {
    display: flex;
    margin-top: 2px;
  }

  .link {
    cursor: pointer;
    text-align: center;
    font-family: "inter", sans-serif;
    font-size: 12px;
    margin-top: 8px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .image_birthday {
    height: 55px;
    width: 55px;
    padding: 4px 0px;
    border-radius: 50%;
  }

  .textConfirmPassWord {
    text-align: center;
    font-family: "inter-medium", sans-serif;
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 24px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .texto {
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme.texts.darkGreyI};
    padding: 0rem 3rem 0rem 3rem;
  }

  .horizontalButtons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .msg {
    font-size: 10px;
    color: ${(props) => props.theme.texts.darkGreyI};
    text-align: right;
    display: block;
  }

  .button-unblock {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    margin-right: 8px;

    button {
      background-color: ${(props) => props.theme.texts.purpleDarkTitle};
      font-size: 16px;
      color: ${(props) => props.theme.texts.white};
      text-align: center;
      padding: 5px 15px 5px 15px;
      margin: 10px 0px -10px 0px;
    }
  }

  .img-container {
    background-color: rgba(37, 129, 196, 0.05);
    margin: auto;
    border-radius: 10px;
    height: 247px;
    width: 383px;

    h3 {
      color: ${(props) => props.theme.texts.darkGreyI};
      font-family: Inter;
      font-style: normal;
      font-weight: 300;
      font-size: 30px;
      line-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .add-img-icon {
      color: #a6446a;
      height: 120px;
      width: 120px;
      display: flex;
      align-items: center;
      margin: auto;
      cursor: pointer;
    }
  }
`;
