import { Redirect, Route, Switch } from "react-router-dom";
import Main from "./Main";
import DataPrivacy from "./DataVisibility/DataPrivacy";

const Privacy = () => {
  const routes = [
    {
      path: "/settings/privacy/name",
      titleContainer: "Nome",
      titleItem: "Visibilidade do meu nome",
      text: "ver o nome",
    },
    {
      path: "/settings/privacy/email",
      titleContainer: "E-mail",
      titleItem: "Visibilidade do meu E-mail",
      text: "ver o E-mail",
    },
    {
      path: "/settings/privacy/phone",
      titleContainer: "Telefone/Celular",
      titleItem: "Visibilidade do meu número de telefone/celular",
      text: "ver o número",
    },
    {
      path: "/settings/privacy/birthday",
      titleContainer: "Data de aniversário",
      titleItem: "Visibilidade da minha data de aniversário",
      text: "ver a data de aniversário",
    },
    {
      path: "/settings/privacy/cpf",
      titleContainer: "CPF",
      titleItem: "Visibilidade do meu CPF",
      text: "ver o CPF",
    },
    {
      path: "/settings/privacy/electoral",
      titleContainer: "Dados Eleitorais",
      titleItem: "Visibilidade dos meus dados eleitorais",
      text: "ver os dados eleitorais",
    },
    // { path: "/settings/privacy/chanel", component: VisibilityChanel ,titleContainer:"Canais" titleItem: "Visibilidade dos meus canais", text:"ver os meus canais"},
    {
      path: "/settings/privacy/restrictionCall",
      titleContainer: "Ligações",
      titleItem: "Restrições para me ligar",
      text: "ligar",
      message: true,
    },
    {
      path: "/settings/privacy/restrictionGroup",
      titleContainer: "Grupos",
      titleItem: "Restrições para me adicionar em um grupo",
      text: "adicionar em grupos",
      message: true,
    },
  ];

  return (
    <>
      <Main></Main>
      <DataPrivacy titleItem="Visibilidade do meu nome" text="ver o nome" />
    </>

    // <Switch location={location}>
    //     <Route path="/settings/privacy" component={Main} exact={true} />
    //     {routes.map(({ path, ...rest }, key) => (
    //         <Route path={path} key={key} component={() => <DataPrivacy {...rest} />} />
    //     ))}
    //     <Redirect exact to="/settings/privacy" />
    // </Switch>
  );
};

export default Privacy;
