import { PageOrientation, PageSize } from "pdfmake/interfaces";
import { useContext, useEffect, useState } from "react";
import { AiOutlineRotateLeft } from "react-icons/ai";
import { BiCaretDownSquare, BiCaretLeftSquare, BiCaretRightSquare, BiCaretUpSquare } from "react-icons/bi";
import { RiFilePaper2Line } from "react-icons/ri";
import { TbBoxMargin } from "react-icons/tb";
import { InputSelect } from '../../../../../../components';
import { ContainerInput } from "../../../../../../components/Input/style";
import { ReportStylesInterface } from "../../../../../../types/project";
import { Element } from "../../style";
import SectionOption from "./SectionOption";
import { ReportContext } from "../../../../../../contexts/ReportContext";

interface PageOptionsProps {

}

const PageOptions = ({ }: PageOptionsProps) => {
    const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
    const pageType: PageSize[] = ['4A0', '2A0'
        , 'A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'A10'
        , 'B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10'
        , 'C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10'
        , 'RA1', 'RA2', 'RA3', 'RA4'
        , 'SRA1', 'SRA2', 'SRA3', 'SRA4'
        , 'EXECUTIVE', 'FOLIO', 'LEGAL', 'LETTER', 'TABLOID'];
    const pageTypeSelect = pageType.map((type) => { return { value: type, label: type } })

    // Estados dos componentes
    const [isEdit, setIsEdit] = useState(false);

    const [selectedOptionPage, setSelectedOptionPage] = useState<PageSize>(stylesPDF.page);
    const [marginsPage, setMarginsPage] = useState<[number, number, number, number]>(stylesPDF.margins);
    const [orientation, setOrientation] = useState<PageOrientation>(stylesPDF.orientation);

    useEffect(() => {
        closeEdit()
    }, [stylesPDF])

    function saveStyle() {
        handleStylesPDF({ ...stylesPDF, orientation: orientation, page: selectedOptionPage, margins: marginsPage });
        setIsEdit(false);
    }

    function closeEdit() {
        setOrientation(stylesPDF.orientation);
        setIsEdit(false);
    }

    return <SectionOption  title="Página" edit={{ handleClose: closeEdit, handleSave: saveStyle, isEdit: isEdit, setIsEdit: setIsEdit }}>
            {/* Campo Título do PDF */}

            {/* Campo de Orientação do PDF */}
            <Element>
                <div className="icon">
                    <AiOutlineRotateLeft title="Orientação" size="20px" />
                </div>
                {isEdit ? <>
                    <label><input onChange={() => { setOrientation("portrait") }} checked={orientation == 'portrait'} type="radio" />Retrato</label>
                    <label className="marginLeft" ><input readOnly={true} onChange={() => { setOrientation("landscape") }} checked={orientation == 'landscape'} type="radio" />Paisagem</label>

                </> : <p>{stylesPDF.orientation === "portrait" ? "Retrato" : "Paisagem"}</p>}
            </Element>

            {/* Campo de Margin da pagina */}
            <Element>
                <div className="icon">
                    <TbBoxMargin title="Margem" size="20px" />
                </div>

                {isEdit ? <>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_left'
                            title="Margem esquerda"
                            value={marginsPage[0]}
                            onChange={(e) => {
                                setMarginsPage([parseInt(e.target.value), marginsPage[1], marginsPage[2], marginsPage[3]])
                            }}
                            id='margin_left'
                            type="number"
                            placeholder="Esquerda"
                        />
                        <BiCaretLeftSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_top'
                            title="Margem superior"

                            value={marginsPage[1]}
                            onChange={(e) => {
                                setMarginsPage([marginsPage[0], parseInt(e.target.value), marginsPage[2], marginsPage[3]])
                            }} id='margin_top'
                            type="number"
                            placeholder="Topo"
                        />
                        <BiCaretUpSquare />
                    </ContainerInput>
                    <ContainerInput $marginRight="8px">
                        <input
                            name='margin_right'
                            title="Margem direita"

                            value={marginsPage[2]}
                            onChange={(e) => {
                                setMarginsPage([marginsPage[0], marginsPage[1], parseInt(e.target.value), marginsPage[3]])
                            }} id='margin_right'
                            type="number"
                            placeholder="Direita"
                        />
                        <BiCaretRightSquare />
                    </ContainerInput>
                    <ContainerInput>
                        <input
                            title="Margem inferior"
                            name='margin_bottom'
                            value={marginsPage[3]}
                            onChange={(e) => {
                                setMarginsPage([marginsPage[0], marginsPage[1], marginsPage[2], parseInt(e.target.value)])
                            }} id='margin_bottom'
                            type="number"
                            placeholder="Baixo"
                        />
                        <BiCaretDownSquare />

                    </ContainerInput>
                </>
                    : <p>{`[${stylesPDF.margins[0]},${stylesPDF.margins[1]},${stylesPDF.margins[2]},${stylesPDF.margins[3]}]`}</p>
                }

            </Element>

            {/* Campo Tipo de Página */}
            <Element>
                <div className="icon">
                    <RiFilePaper2Line title="Tipo da página" size="20px" />
                </div>
                {isEdit ?
                    <InputSelect
                        $flex={true}
                        value={pageTypeSelect.find((option) => (option.value === selectedOptionPage))}
                        onChange={(e) => setSelectedOptionPage(e.value)}
                        placeholder="Selecione o estilo de borda" options={pageTypeSelect} />

                    : <p>{pageTypeSelect!.find((option) => (option.value === selectedOptionPage))!.label}</p>
                }
            </Element>
    </SectionOption>

}

export default PageOptions;