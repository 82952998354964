import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import raw from "../publicKey.pem";
var encrypt = new JSEncrypt();

function readPEMFile() {
  return fetch(raw)
    .then(async (response) => {
      const txt = await response.text();
      return txt;
    })
    .catch((error) => {
      console.error("Erro ao ler o arquivo .pem:", error);
      throw error;
    });
}

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
});

readPEMFile()
  .then((pemContent) => {
    const publicKey = pemContent;
    encrypt.setPublicKey(publicKey);

    function encryptData(data) {
      var encryptedData = encrypt.encrypt(JSON.stringify(data));
      return encryptedData;
    }

    api.interceptors.request.use((config) => {
        if (config.data && !(config.data instanceof FormData)) {
            config.headers["x-data-encrypted"] = true
            config.data = { encryptedData: encryptData(config.data)};
        }
        return config;
    });
  })
  .catch((error) => {
    console.error("Não foi possível definir a chave de criptografia:", error);
  });

export { api };
