import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AiFillCheckCircle, IoIosArrowBack } from '../../../../../../assets/icons';
import { ic_user_setting_white } from '../../../../../../assets/images';
import { Search } from '../../../../../../components';
import { ButtonArrow } from '../../../../../../components/Button/style';
import { Checked } from '../../../../../../components/Checked';
import { patchUpdateAdmin } from '../../../../../../services/requests/user-group';
import { TProfileGroup, TUser, UserStatus } from '../../../../../../types/project';
import { isEqualsArray, photoUrl } from '../../../../../../util/verification';
import { Container, Default, Header, ListConversation, Menu } from './style';


type TProps = {
    modal: any,
    groupInfo: TProfileGroup,
    updateGroupForAllUsers: any,
    isAdm: any,
}

const AdminsGroup = ({ groupInfo, modal, isAdm, updateGroupForAllUsers }: TProps) => {
    const nameTypeGroup = groupInfo.groupType ? 'canal' : 'grupo';
    const [hidden, setHidden] = useState(false);
    const participants = structuredClone(groupInfo.participants.filter(user=> user.status == UserStatus.ATIVADO));
    const { addToast } = useToasts();

    const checkedAdmin = () => {
        if (isAdm) {
            participants.forEach(user => {
                user.checked = user.admin;
                user.hidden = false;
            });
            return participants;
        } else {
            return (participants.filter(x => x.admin));
        }
    }

    const [users, setUsers] = useState<TUser[]>(checkedAdmin());
    const staticUsers = checkedAdmin();


    const updateAdmin = () => {
        if (hidden) {
            let usersAdd: Array<any> = [];
            let usersRemove: Array<any> = [];

            users.forEach(user => {
                if (user.admin) {
                    !user.checked && usersRemove.push(user.id);
                } else {
                    user.checked && usersAdd.push(user.id);
                }
            });

            patchUpdateAdmin(groupInfo.id, usersAdd, usersRemove).then((res) => {
                if (res.status == 200) {
                    updateGroupInfo(usersAdd, usersRemove);
                    addToast({
                        title: "Administradores atualizados!"
                    },
                        { appearance: 'success' });
                    modal.current.close();
                } else if (res.status == 428) {
                    addToast({
                        title: "Não foi possível atualizar os administradores",
                        description: 'Você deve deixar pelo menos 1 administrador no grupo.'
                    },
                        { appearance: 'warning' });
                }
            })
        }
    }

    const updateGroupInfo = (usersAdd, usersRemove) => {
        participants.forEach(user => {
            if (usersRemove.includes(user.id)) {
                user.admin = false;
            } else if (usersAdd.includes(user.id)) {
                user.admin = true;
            }
            user.checked = false;
        });
        updateGroupForAllUsers(groupInfo);
    }

    useEffect(() => {
        //hidden null para evitar bug por conta do search que muda o valor de hiddens
        isEqualsArray(staticUsers.map(user => { return { ...user, hidden: null } }), users.map(user => { return { ...user, hidden: null } })) ? setHidden(false) : setHidden(true)
    }, [users])


    const ListForAdm = () => {
        return (
            <div>
                <Checked users={users} setUsers={setUsers} />
            </div>
        );
    }

    const ListForMember = ({ user }) => {
        return (
            <ListConversation htmlFor={user.id}>
                <div className="relative">
                    <div>
                        <img className="profile" src={photoUrl(user)} />
                    </div>
                    {user.admin && <Default className="vertical-gradient">
                        <img className="icon-circle" src={ic_user_setting_white} width={15} />
                    </Default>}
                </div>
                <div id="col-message">
                    <h4 id="name">{user.name ? user.name : "@" + user.nickname}</h4>
                </div>
            </ListConversation>
        );
    }

    return (
        <Container>
            <Header>
                <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                <h5>Administradores do {nameTypeGroup}</h5>
            </Header>
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    setUsers(result)
                }} />
                {isAdm && <ButtonArrow onClick={() => updateAdmin()} >
                    <AiFillCheckCircle size={32} id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>}
            </Menu>
            <div className='scroll'>
                {isAdm ?
                    <ListForAdm />
                    :
                    users.map((user, key) => (
                        !user.hidden && <ListForMember key={key} user={user} />
                    ))
                }
            </div>
        </Container>
    );
}



export default AdminsGroup;