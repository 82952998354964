import { useContext, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPersonFill, BsXLg } from "react-icons/bs";
import { FaCity } from "react-icons/fa";
import { MdGroup } from "react-icons/md";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { InputSelect } from "../../../../../../components";
import { InputClear } from "../../../../../../components/Input";
import { getGroups } from "../../../../../../services/requests/group";
import {
  OptionSelect,
  ReportEnum,
  ReportOptionsInterface,
  ReportTypeFilter,
} from "../../../../../../types/project";
import { TypeConstRedux } from "../../../../../../types/redux";
import { errorDefault } from "../../../../../../util/error";
import { Element } from "../../style";
import { ReportContext } from "../../../../../../contexts/ReportContext";

interface FilterProps {
  position: number;
  edit: boolean;
  optionsPDF: ReportOptionsInterface;
  keySelect: any | null;
  option: ReportTypeFilter;
  valuesSelected: OptionSelect[][];
  setValuesSelected: React.Dispatch<React.SetStateAction<OptionSelect[][]>>;
}

function FilterOption({
  keySelect,
  edit,
  option,
  position,
  valuesSelected,
  optionsPDF,
  setValuesSelected,
}: FilterProps) {
  const { optionReportSelected } = useContext(ReportContext);
  const folders = useSelector((state: TypeConstRedux) => state.folder.list);
  const { addToast } = useToasts();
  const [isLoad, setIsLoad] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const empty =
    !valuesSelected[position] ||
    valuesSelected[position].length < 1 ||
    (option == ReportTypeFilter.SEARCH &&
      valuesSelected[position][0].label.length < 1);
  const [valuesOptions, setValueOptions] = useState<OptionSelect[]>([]);
  const Icon = () => {
    switch (option) {
      case ReportTypeFilter.GROUP_ID:
      case ReportTypeFilter.FOLDER_ID:
        return <MdGroup size="20px" />;
      case ReportTypeFilter.CITY:
        return <FaCity size="20px" />;
      case ReportTypeFilter.BIO_SEXY:
        return <BsPersonFill size="20px" />;
      case ReportTypeFilter.SEARCH:
        return <AiOutlineSearch size="20px" />;
      default:
        return "Adicionar icon";
    }
  };
  const messageDefault = () => {
    switch (option) {
      case ReportTypeFilter.GROUP_ID:
      case ReportTypeFilter.FOLDER_ID:
        return "Grupos";
      case ReportTypeFilter.CITY:
        return "Cidades";
      case ReportTypeFilter.BIO_SEXY:
        return "Género";
      case ReportTypeFilter.SEARCH:
        return "Pesquisa";
      default:
        return "Mensagem a ser adicionada";
    }
  };

  useEffect(() => {
    setIsLoad(true);
    switch (option) {
      case ReportTypeFilter.GROUP_ID:
        getGroups()
          .then((res) => {
            if (!errorDefault(res.status, addToast) && res.data) {
              const values: OptionSelect[] = [];
              res.data.map((group) => {
                values.push({ value: group.Group_id, label: group.name });
              });
              values.push({ value: "all", label: "Todos" });
              setValueOptions(values);
            }
          })
          .finally(() => {
            setIsLoad(false);
          });
        break;

      case ReportTypeFilter.CITY:
        setValueOptions([
          { value: "manaus", label: "Manaus" },
          { value: "all", label: "Todos" },
        ]);
        setIsLoad(false);
        break;
      case ReportTypeFilter.SEARCH:
        valuesSelected[position] = [{ value: "search", label: "" }];
        setValuesSelected({ ...valuesSelected });
        setIsLoad(false);
        break;
      case ReportTypeFilter.BIO_SEXY:
        setValueOptions([
          { value: "all", label: "Masculino" },
          { value: "all", label: "Feminino" },
          { value: "all", label: "Outro" },
          { value: "all", label: "Todos" },
        ]);
        setIsLoad(false);
        break;
      case ReportTypeFilter.FOLDER_ID:
        if (keySelect) {
          const values = folders
            .find((folder) => folder.id == keySelect.value)
            ?.itens.filter((item) => item.Group_id)
            .map((item) => {
              return { value: item.Group_id, label: item.name };
            })
            .concat({ value: "all", label: "Todos" });
          if (values) {
            setValueOptions(values);
          } else {
            setValueOptions([]);
          }
        } else {
          setValueOptions([]);
        }
        setIsLoad(false);
        break;
    }
  }, [optionReportSelected]);

  useEffect(() => {
    switch (option) {
      case ReportTypeFilter.FOLDER_ID:
        setIsLoad(true);

        if (keySelect) {
          const values = folders
            .find((folder) => folder.id == keySelect.value)
            ?.itens.filter((item) => item.Group_id)
            .map((item) => {
              return { value: item.Group_id, label: item.name };
            })
            .concat({ value: "all", label: "Todos" });
          if (values) {
            setValueOptions(values);
          } else {
            setValueOptions([]);
          }
        } else {
          setValueOptions([]);
        }
        setIsLoad(false);
        break;
    }
  }, [optionsPDF, keySelect]);

  const changeList = (e) => {
    const isAllOne = e.length > 1 && e[0].value === "all";
    const isAllSelected = e.some((option) => option.value === "all");

    if (isAllOne) {
      valuesSelected[position] = e.slice(1);
    } else if (isAllSelected) {
      valuesSelected[position] = [{ value: "all", label: "Todos" }];
    } else {
      valuesSelected[position] = e;
    }
    setValuesSelected({ ...valuesSelected });
  };

  return (
    <>
      <Element $noWrap={true}>
        <div title={messageDefault()} className="icon">
          {Icon()}
        </div>
        {empty && option == ReportTypeFilter.SEARCH ? (
          <></>
        ) : edit ? (
          option !== ReportTypeFilter.SEARCH ? (
            <InputSelect
              value={valuesSelected[position]}
              onChange={(e) => {
                changeList(e);
              }}
              isMulti
              name="filterOptions"
              id="filterOptions"
              isLoading={isLoad}
              placeholder={messageDefault()}
              style={true}
              options={valuesOptions}
            />
          ) : (
            <InputClear
              actionClear={() => {
                valuesSelected[position] = [{ value: "search", label: "" }];
                setValuesSelected({ ...valuesSelected });
              }}
              Icon={BsXLg}
              onChange={(e) => {
                valuesSelected[position] = [
                  { value: "search", label: e.target.value },
                ];
                setValuesSelected({ ...valuesSelected });
              }}
              value={
                valuesSelected[position][0]
                  ? valuesSelected[position][0].label
                  : "teste"
              }
              placeholder="Pesquisar ..."
            />
          )
        ) : (
          <p>
            {empty ? (
              option == ReportTypeFilter.SEARCH ? (
                <p>Todos</p>
              ) : (
                <p>Nenhuma opção selecionada</p>
              )
            ) : (
              valuesSelected[position].map((item, index) => {
                return `${index != 0 ? ", " : ""}${item.label}`;
              })
            )}
          </p>
        )}
      </Element>
    </>
  );
}

export default FilterOption;
