import { api } from '../api';
import { getToken } from "../auth";

export const postContact = (data) => {
    return api({
        url: "/contact",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: data
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const postUploadContacts = (data) => {
    return api({
        url: "/contact/many",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: data
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getContacts = (userId) => {
    return api({
        url: "/contact/my-contacts/" + userId,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getContactsWithAccount = () => {
    return api({
        url: "/contact/add-group",
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchContact = (contactId, data) => {
    return api({
        url: "/contact/" + contactId,
        method: "Patch",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: data
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchPhotoContact = (contactId, data) => {
    return api({
        url: "/photo-contact/" + contactId,
        method: "Patch",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: data
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteContacts = (itens) => {
    return api({
        url: "/contact",
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { itens }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};