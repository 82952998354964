import { ChangeEvent, useCallback, useState } from "react";
import { postUploadContacts } from "../../../../../services/requests/contact";
import { Container, ContainerContent, ContainerTitle, Item } from "./style";
import FloatingButton from "../../../../../components/Button/FloatingButton/FloatingButton";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { ItemFile, } from "../../../../../components";
import DropZone from "../../../../../components/DropZone";
import { TypeImage } from "../../../../../types/project";
import { ToastModel } from "../../../../../util/error";
import { useToasts } from "react-toast-notifications";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

const options = [
    { value: 'name', label: 'Nome' },
    { value: 'lastName', label: 'Sobrenome' },
    { value: 'email', label: 'E-mail' },
    { value: 'phone', label: 'Telefone' },
    { value: 'birthday', label: 'Data de Nascimento' },
    { value: 'bioSex', label: 'Sexo Biológico' },
    { value: 'cpf', label: 'CPF' },
    { value: 'cnpj', label: 'CNPJ' },
    { value: 'voterNumber', label: 'Número do Título de Eleitor' },
    { value: 'voterZone', label: 'Zona Eleitoral' },
    { value: 'voterSection', label: 'Seção Eleitoral' },
    { value: 'relationship', label: 'Relacionamento' },
    { value: 'geoRefLat', label: 'Latitude' },
    { value: 'geoRefLon', label: 'Longitude' },
    { value: 'street', label: 'Rua' },
    { value: 'zipCode', label: 'CEP' },
    { value: 'nbhood', label: 'Bairro' },
    { value: 'city', label: 'Cidade' },
    { value: 'state', label: 'Estado' },
    { value: 'country', label: 'País' },
    { value: 'observation', label: 'Observação' },
];

const UploadContacts = (props) => {
    const { modalRef } = props;
    const [file, setFile] = useState<TypeImage | null>(null)
    const [sending, isSending] = useState<boolean>(false);
    const { addToast } = useToasts();
    const [selected, setSelected] = useState<any>([]);

    const sendFile = (jsonObject) => {
        postUploadContacts(jsonObject).then((res) => {
            sendFile(false)
            console.log("🚀 ~ file: index.tsx:26 ~ postUploadContacts ~ res:", res)
        })
    }
    const create = (e) => {
        e.preventDefault();
        console.log("OI OI OI OI OI O I OI OI OI OI o")

        if (!file) {
            ToastModel(
                addToast,
                "Arquivo necessário!",
                "Escolha algum arquivo para upload dos contatos.",
                "warning"
            );
            return
        }
        if (!sending) {
            isSending(true);

            const reader = new FileReader();
            reader.onload = () => {
                try {
                    console.log(reader.result);
                } catch (error) {
                    console.error('Erro ao converter o arquivo JSON:', error);
                    isSending(false);
                }
            };
            reader.readAsText(file.file);
        }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFile({ file: acceptedFiles[0] })
    }, []);

    return (
        <Container onSubmit={create}>
            <FloatingButton mobile={false} type="submit" size="large" icon={sending ? "load" : "send"} position="bottom-right" />
            <ContainerTitle>
                <div className="name">
                    <IoIosArrowBack size={26} id="icon-back" onClick={() => modalRef.current.close()} />
                    Upload de contatos</div>
            </ContainerTitle>
            <ContainerContent >
                <h3>Escolha um arquivo, selecione os atributos que você tem na ORDEM em que esta seu arquivo CSV. </h3>
                <DropZone disabled={false} onDrop={onDrop} multiple={false}
                    accept={
                        {
                            "text/csv": [".csv"],
                            "'application/json'": [".json"]
                        }
                    }
                    message={"Você pode arrastar e soltar o arquivo aqui para adicioná-lo (json, csv)."} />
                {file &&
                    <Item>
                        <div className="media">
                            <ItemFile className="img-video" type={null} />
                        </div>
                        <p>{file.file.name}</p>
                        <IoIosClose onClick={(e) => (e.stopPropagation(), setFile(null))} />
                    </Item>
                }
                 <DualListBox
                    options={options}
                    selected={selected}
                    disabled={file ? false : true}
                    onChange={(value) => setSelected(value)}
                    preserveSelectOrder
                    showOrderButtons
                    className="custom-dual-listbox"
                />
            </ContainerContent>

        </Container>
    )

}

export default UploadContacts;