import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 55px 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  background: white;
`;

export const TitleBar = styled.div`
  background: ${(props) => props.theme.texts.purpleDark};
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  align-items: center;

  .title {
    font-family: "inter-semi-bold", sans-serif;
    color: white;
    font-size: 18px;
    margin-left: 8px;
  }
`;

export const Attachments = styled.div`
  background: white;
  padding: 0px 16px;
`;
