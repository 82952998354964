import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  deleteMessage,
  patchVisibleMessage,
  postMessage,
} from "../../../services/requests/message";
import {
  deleteMessageGroup,
  postMessageGroup,
} from "../../../services/requests/message-group";
import {
  patchVisibleMessageGroup,
  updateReadMessageGroup,
} from "../../../services/requests/message-group-user";
import {
  postPersonal,
  updateReadMessage,
} from "../../../services/requests/personal";
import {
  TMessage,
  TypeDeleteMessage,
  TypePersonal,
  TypeStatusMessage,
  TypeStatusRequest,
} from "../../../types/project";
import { TypeConstRedux } from "../../../types/redux";
import {
  getClientId,
  keyClient,
  userIdContact,
} from "../../../util/verification";
import { setGlobalMessages, updateClient } from "./chatAction";
import { spliceMessage } from "./getMessageAction";

const { CANCELED, NOTSEND, SEND, READ } = TypeStatusMessage;
const { UPLOADING, DOWNLOADING, ERROUP, SUCCESS, FILETOOLARGE, LIMITMIDIA } =
  TypeStatusRequest;

const { PERSONAL } = TypePersonal;

export const setChat = (id, obj) => {
  return (dispatch, getState) => {
    const { globalMessages } = getAllState(getState());
    const conversation = globalMessages[id];
    dispatch(setGlobalMessages({ [id]: { ...conversation, ...obj } }));
  };
};

export const scrollController = (obj: any) => {
  return (dispatch, getState) => {
    const { personal } = getAllState(getState());
    const clientId = getClientId(personal);
    dispatch(setChat(clientId, { loadPage: false, scroll: { ...obj } }));
  };
};

export const setMessage = (message: TMessage) => {
  return (dispatch, getState) => {
    const { personal, abare } = getAllState(getState());

    if (message.status != CANCELED) {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      message = {
        ...message,
        [keyClient[personal.type]]: getClientId(personal),
        User_id: abare!!.id,
        sendBy: abare!!.id,
        status: NOTSEND,
        createdAt: new Date(),
        source: source,
        msg_id: uuidv4(),
        type: personal.type,
        statusRequest: UPLOADING,
      };
      dispatch(addMessage(message));
    }

    if (!personal.blockedMe) {
      message.type == TypePersonal.GUEST
        ? dispatch(createPersonal())
        : dispatch(uploadMessage(message));
    }
  };
};

export const forwardMessageAction = (message: TMessage, personal) => {
  return (dispatch, getState) => {
    const { abare } = getAllState(getState());

    if (message.status != CANCELED) {
      let CancelToken = axios.CancelToken;
      let source = CancelToken.source();
      message = {
        ...message,
        [keyClient[personal.type]]: getClientId(personal),
        User_id: abare!!.id,
        sendBy: abare!!.id,
        status: NOTSEND,
        createdAt: new Date(),
        source: source,
        msg_id: uuidv4(),
        type: personal.type,
        statusRequest: UPLOADING,
      };
      dispatch(addMessageForward(message));
    }

    if (!personal.blockedMe) {
      message.type == TypePersonal.GUEST
        ? dispatch(createPersonal())
        : dispatch(uploadMessage(message));
    }
  };
};

export const addMessage = (message: TMessage) => {
  return (dispatch, getState) => {
    const { globalMessages } = getAllState(getState());
    const clientId = getClientId(message);
    const { messages } = globalMessages[clientId];

    const options = { block: "end", behavior: "smooth" };
    dispatch(
      setChat(clientId, {
        messages: [...messages, message],
        scroll: { key: message.msg_id, options: options },
      })
    );
  };
};

export const addMessageForward = (message: TMessage) => {
  return (dispatch, getState) => {
    const { globalMessages } = getAllState(getState());
    const clientId = getClientId(message);
    const { messages } = globalMessages[clientId];

    const options = { block: "end", behavior: "smooth" };
    dispatch(setChat(clientId, { messages: [...messages, message] }));
  };
};

const uploadMessage = (message: TMessage) => {
  return (dispatch, getState) => {
    const { abare, socket, globalMessages } = getAllState(getState());
    const client = globalMessages[getClientId(message)].client;
    const dataForm = new FormData();

    message.file && dataForm.append("file", message.file!!);
    message.fileType && dataForm.append("fileType", message.fileType!!);
    dataForm.append(keyClient[message.type!!], getClientId(message));
    message.description && dataForm.append("description", message.description);
    message.msgGroup_id_root &&
      dataForm.append("msgGroup_id_root", message.msgGroup_id_root);
    message.msg_id_root && dataForm.append("msg_id_root", message.msg_id_root);
    message.fileName && dataForm.append("fileName", message.fileName);
    message.fileNamePreview &&
      dataForm.append("fileNamePreview", message.fileNamePreview);

    dispatch(
      spliceMessage({ ...message, status: NOTSEND, statusRequest: UPLOADING })
    );
    const post =
      message.type == TypePersonal.GROUP ? postMessageGroup : postMessage;
    post(dataForm).then((res) => {
      const status = res.status;
      if (status == 201) {
        dispatch(
          spliceMessage(
            { ...message, ...res.data, statusRequest: SUCCESS },
            message.msg_id!!
          )
        );
        const { recreate, usersIds, userIdContact } = res.data;
        if (recreate) {
          const { nickname, photoUrl } = abare!!;
          socket.emit(
            "userJoinClass",
            {
              ...client,
              name: null,
              nickname,
              photoUrl,
              idUser: userIdContact,
            },
            () => {
              socket.emit("sendMessage", {
                ...res.data,
                ids: usersIds,
                readAt: null,
              });
            }
          );
        } else {
          socket.emit("sendMessage", {
            ...res.data,
            ids: usersIds,
            nameUser: message.type ? null : "@" + abare?.nickname,
            readAt: null,
          });
        }
      } else if (status == 413) {
        dispatch(
          spliceMessage({
            ...message,
            status: CANCELED,
            statusRequest: FILETOOLARGE,
          })
        );
      } else if (status == 403) {
        dispatch(
          spliceMessage({
            ...message,
            status: CANCELED,
            statusRequest: LIMITMIDIA,
          })
        );
      } else if (status != 401) {
        dispatch(
          spliceMessage({ ...message, status: CANCELED, statusRequest: ERROUP })
        );
      }
    });
  };
};

const bufferMessages = () => {
  return (dispatch, getState) => {
    const { personal, globalMessages } = getAllState(getState());
    const clientId = getClientId(personal);
    const { messages } = globalMessages[clientId];

    messages.forEach((message) => {
      if (message.status == NOTSEND) {
        dispatch(uploadMessage(message));
      }
    });
  };
};

const createPersonal = () => {
  return (dispatch, getState) => {
    const { abare, socket, personal } = getAllState(getState());
    if (personal.status !== 0) {
      dispatch(updateClient({ ...personal, status: 0 }));
      postPersonal(abare?.id, personal.id).then((res) => {
        if (res.status == 200) {
          const result = res.data.result;
          const newPersonal = {
            ...result,
            Personal_id: result.id,
            type: PERSONAL,
          };
          const { nickname, photoUrl } = abare!!;
          socket.emit(
            "userJoinClass",
            {
              ...newPersonal,
              name: null,
              nickname,
              photoUrl,
              idUser: userIdContact(abare?.id, newPersonal),
            },
            () => {
              dispatch(updateClient(newPersonal));
              dispatch(bufferMessages());
            }
          );
        }
      });
    }
  };
};

export const readMessages = (message?) => {
  return (dispatch, getState) => {
    const { abare, personal, globalMessages, socket } = getAllState(getState());
    const clientId = getClientId(message || personal);
    const { messages } = globalMessages[clientId];
    const userIsInConversation = message
      ? getClientId(personal) == getClientId(message)
      : true;

    if (userIsInConversation) {
      let read;
      let isSomeMessageNotRead;
      if (personal.type == TypePersonal.GROUP) {
        read = updateReadMessageGroup;
        isSomeMessageNotRead =
          messages.some(
            (msg) => msg.readAt == null && msg.sendBy != abare?.id
          ) || personal.countMessage > 0;
      } else {
        read = updateReadMessage;
        isSomeMessageNotRead =
          messages.some(
            (msg) => msg.status == SEND && msg.sendBy != abare?.id
          ) || personal.countMessage > 0;
      }

      isSomeMessageNotRead &&
        read(abare?.id, clientId).then((res) => {
          if (res.status == 200) {
            const { globalMessages } = getAllState(getState());
            const { client } = globalMessages[clientId];
            client.countMessage = 0;
            dispatch(updateClient({ ...client }));

            const msgsIds = res.data.msgsReadByAll;

            if (personal.type == TypePersonal.PERSONAL) {
              socket.emit("readMessages", {
                msgsIds,
                ids: [userIdContact(abare?.id, client), abare?.id],
                clientId,
              });
            } else {
              socket.emit("readMessages", {
                msgsIds,
                ids: Object.keys(client.namesView),
                clientId,
              });
            }
            updateReadStatus(clientId, msgsIds);
          }
        });
    }
  };
};

export const updateReadStatus = (clientId, msgsIds: Array<any>) => {
  return (dispatch, getState) => {
    const { globalMessages } = getAllState(getState());
    const { messages } = globalMessages[clientId];

    const messagesReads = messages.map((msg) =>
      msgsIds.some((id) => id == msg.msg_id)
        ? { ...msg, readAt: new Date(), status: READ }
        : { ...msg }
    );
    messages.length && dispatch(setChat(clientId, { messages: messagesReads }));
  };
};

export const dropMessage = (message, type?) => {
  return (dispatch, getState) => {
    const { abare, socket, globalMessages } = getAllState(getState());
    const { ME, ALL } = TypeDeleteMessage;
    const clientId = getClientId(message);
    const { client } = globalMessages[clientId];

    const update = () => {
      const { globalMessages } = getAllState(getState());
      const { messages, client } = globalMessages[clientId];

      const indexMessage = messages.findIndex(
        (item) => item.msg_id === message.msg_id
      );
      const indexLastMsg = messages.length - 1;
      const result = messages.filter((item) => item.msg_id !== message.msg_id);

      indexMessage == indexLastMsg &&
        dispatch(
          updateClient({ ...client, lastMessage: messages[indexLastMsg - 1] })
        );

      dispatch(setChat(clientId, { messages: result }));
    };

    switch (type) {
      case ME:
        const patchVisible =
          message.type == TypePersonal.GROUP
            ? patchVisibleMessageGroup
            : patchVisibleMessage;
        patchVisible(abare?.id, clientId, message.msg_id).then((res) => {
          if (res.status == 200) {
            update();
          }
        });
        break;
      case ALL:
        const deleteMsg =
          message.type == TypePersonal.GROUP
            ? deleteMessageGroup
            : deleteMessage;
        deleteMsg(clientId, message.msg_id).then((res) => {
          if (res.status == 200) {
            update();
            const ids =
              message.type == TypePersonal.GROUP
                ? [Object.keys(client.namesView)]
                : [userIdContact(abare?.id, client)];
            socket.emit("deleteMessage", { ...message, ids });
          }
        });
        break;
      default:
        update();
        break;
    }
  };
};

export const getAllState = (state: TypeConstRedux) => {
  const { abare, socket, folder, contacts } = state;
  const { personal, globalMessages } = state.chat;
  const folders = folder.list;

  return {
    abare: abare.abare,
    socket,
    globalMessages,
    personal,
    folders,
    contacts: contacts.list,
  };
};
