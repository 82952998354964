import styled from "styled-components";
import { mobile } from "../NavBar/style";

interface ContainerProps {
  mobileNav?: boolean;
  large?: boolean;
  notifications?: boolean;
  $overflow?: boolean;
  $widthUl?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: auto;

  color: ${(props) => props.theme.texts.darkerGreyII};

  .round {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    background: ${(props) => props.theme.chat.message.backgroundRound};
    border: 1px solid ${(props) => props.theme.chat.message.borderRound};
  }
  #menu {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }

  .icon {
    font-size: 20px;
    margin-right: 6px;
    color: ${(props) => props.theme.highlights.default};
  }

  ul {
    display: none;
    width: "auto";
    max-width: ${({ $widthUl }) => $widthUl ?? "500px"};
    transition: 0s display;
    position: absolute;
    border: 1px solid
      ${({ theme }) =>
        theme.title == "dark" ? theme.bordes.rose : theme.bordes.lightGrey};
    border-radius: 10px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    background-color: ${(props) => props.theme.backgrounds.white};
    list-style-type: none;
    z-index: 1000;
    overflow: ${({ $overflow }) => ($overflow ? `visible` : `hidden`)};

    @media only screen and (max-width: ${mobile}) {
      ${({ notifications }) =>
        notifications ? `right: -64px; max-width: 90vw;` : ``}
    }
    li {
      display: grid;
      box-sizing: border-box;
      grid-template-columns: 30px auto;
      align-items: center;
      text-align: start;
      padding: 0.7rem 1.1rem;
      cursor: pointer;
      white-space: ${({ notifications }) =>
        notifications ? "normal" : "nowrap"};
      border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};

      color: ${(props) => props.theme.texts.darkerGreyII};
      font-size: 14px;
      min-width: 180px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      &:hover {
        background-color: ${(props) => props.theme.hover.lightGreyIII};
      }
      @media only screen and (max-width: ${mobile}) {
        ${({ mobileNav }) =>
          mobileNav
            ? `min-width: auto !important; white-space:normal; `
            : `min-width: inherit !important;
          white-space: nowrap !important;`}
      }
    }

    &:hover {
      display: block;
    }
  }
  &:hover {
    ul {
      display: block;
    }
  }

  &:focus {
    ul {
      display: block;
    }
  }

  &:focus-within {
    ul {
      display: block;
    }
  }

  .top-left {
    bottom: 100%;
    right: 0px;
  }

  .top-right {
    bottom: 100%;
    left: 0px;
  }

  .bottom-right {
    top: 95%;
    left: 0px;
  }

  .bottom-left {
    top: 95%;
    right: 0;
    @media only screen and (max-width: ${mobile}) {
      ${({ notifications }) => (notifications ? `right: -64px;` : ``)}
    }
  }

  .folder-menu {
    top: -270px !important;
    right: 0px;
  }
`;
