import {
  SET_ANSWER,
  SET_DETAILS,
  SET_GLOBAL_MESSAGES,
  SET_NEW_GLOBAL_MESSAGES,
  SET_PERSONAL,
  UPDATE_PERSONAL,
} from "../actions/actionTypes";

const initialState = {
  personal: null,
  answer: null,
  details: false,
  globalMessages:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PERSONAL:
      return {
        ...state,
        personal: action.payload,
      };
    case UPDATE_PERSONAL:
      return {
        ...state,
        personal: action.payload,
      };
    case SET_GLOBAL_MESSAGES:
      return {
        ...state,
        globalMessages: { ...state.globalMessages, ...action.payload },
      };
    case SET_NEW_GLOBAL_MESSAGES:
      return {
        ...state,
        globalMessages: action.payload,
      };
    case SET_ANSWER:
      return {
        ...state,
        answer: action.payload,
      };
    case SET_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    default:
      return { ...state }; /*  */
  }
}
