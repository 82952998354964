import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { patchContact, patchPhotoContact } from "../../../../services/requests/contact";
import { patchPhoto, patchUserData, putNameAndNick } from "../../../../services/requests/user";
import { setAbare } from "../../../../store/actions/abareAction";
import { editContact } from "../../../../store/actions/contactsAction";
import { TypeConstRedux } from "../../../../types/redux";
import { ToastModel, errorDefault } from "../../../../util/error";
import Container from "../Components/SettingsContainer";
import Address from "./Edit/Address";
import DisabledOption from "./Edit/DisabledOption";
import ElectoralData from "./Edit/ElectoralData";
import EditImage from "./Edit/Image";
import ModalAcess from "./Edit/ModalAccess";
import ModalPassWord from "./Edit/ModalPassWord";
import PersonalInfor from "./Edit/PersonalInfor";
import ProfileData from "./Edit/ProfileData";

const User = (props) => {
  const { abare, toChangeAbare, editContact, contacts } = props;
  const { id } = useParams<{ id: string }>();
  const user = id ? contacts.find(user => user.contactId == id) : abare;
  const title = id ? user.name : 'Meu perfil';
  const { addToast } = useToasts();
  const { goBack } = useHistory();

  const onClick = () => {
    if (id) return goBack();
    return;
  }
  const editNameAndNicknanme = (data, functionSucess, setLoad) => {
    putNameAndNick(user.id, data.name, data.nickname).then((res) => {
      if (res.status == 200) {
        const userResponse = functionSucess()
        toChangeAbare({ ...userResponse })
        ToastModel(addToast, 'Dados Atualizados!', "Seus dados foram atualizados com sucesso.", "success")
      } else if (res.status == 409) {
        ToastModel(addToast, 'Apelido já está cadastrado!', "Por favor, informe outro apelido.", "warning")
      } else {
        errorDefault(res.status, addToast)
      }
      setLoad(false)
    })

  }


  const editInfos = (data, functionSucess, setLoad) => {
    if (id) {
      patchContact(user.contactId, data).then((res) => {
        if (res.status == 200) {
          const userResponse = functionSucess()
          editContact({ ...userResponse })
          ToastModel(addToast, 'Dados Atualizados!', "Os dados do contato foram atualizados com sucesso.", "success")
        } else {
          errorDefault(res.status, addToast)
        }
      })
    } else {
      patchUserData(data).then((res) => {
        if (res.status == 200) {
          const userResponse = functionSucess()
          toChangeAbare({ ...userResponse })
          ToastModel(addToast, 'Dados Atualizados!', "Seus dados foram atualizados com sucesso.", "success")
        } else {
          errorDefault(res.status, addToast)
        }
        setLoad(false)
      })
    }
  }

  const editPhoto = (dataForm, isDeleteImage, image) => {
    if (id) {
      patchPhotoContact(user.contactId, dataForm).then((res) => {
        if (res.status == 200) {
          addToast({
            title: "Imagem atualizada!",
            description: "Imagem do contato foi atualizada com sucesso !",
          }, {
            appearance: 'success'
          });
          isDeleteImage ? (user.photoUrl = null) : image.newUrl && (user.photoUrl = image.newUrl);
          editContact({ ...user })
        }
      })
    } else {
      patchPhoto(dataForm).then((res) => {
        if (res.status == 200) {
          addToast({
            title: "Imagem atualizada!",
            description: "Imagem do perfil atualizada com sucesso !",
          }, {
            appearance: 'success'
          });
          isDeleteImage ? (user.photoUrl = null) : image.newUrl && (user.photoUrl = image.newUrl);
          toChangeAbare({ ...user })
        }
      })
    }
  }


  const propsComponents = { editInfos, user }
  return (
      <Container title={title} isIcon={id} onClick={onClick} >
        <EditImage user={user} editPhoto={editPhoto} />
        <ProfileData  {...propsComponents} editNameAndNicknanme={editNameAndNicknanme} />
        <PersonalInfor  {...propsComponents} />
        <Address  {...propsComponents} />
        <ElectoralData  {...propsComponents} />
        {!id &&
          [<ModalAcess key='modalAcess' />,
          <ModalPassWord key='modalPassword' />,
          <DisabledOption key='modalDisabled' />
          ]
        }

      </Container>

  );
}

const mapStateToProps = (state: TypeConstRedux) => {
  return {
    abare: state.abare.abare,
    contacts: state.contacts.list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toChangeAbare(newAbare) {
      dispatch(setAbare(newAbare));
    },
    editContact(contact) {
      dispatch(editContact(contact));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);

