import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  background: ${(props) => props.theme.purple.dark};
  padding: 0px 16px;
`;

export const Items = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  max-height: 50px;
`;

export const Button = styled.button`
  display: block;
  align-items: center;
  font-size: 11px;
  color: white;
  padding: 0px 16px;
`;
