import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 1rem 0;
`;

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderWidth: "0px !important",
  width: "auto",
  color: theme.title === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-root": {
    borderWidth: "0px !important",
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.title === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: `1px solid black`,
    borderRadius: "20px",
    backgroundColor: "#f4f6f8",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    // borderRight: `1px solid ${theme.title === 'light' ? '#f4f6f8' : '#303030'
    //   }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    // borderBottom: `1px solid ${theme.title === 'light' ? '#f4f6f8' : '#303030'
    //   }`,
  },
  "& .MuiDataGrid-row": {
    // border: `1px solid ${theme.title === 'light' ? '#f4f6f8' : '#303030'
    //   }`,
    boxSizing: "border-box",
    marginTop: "20px",

    ":hover": {
      backgroundColor: "rgb(255, 255, 255) !important",
      boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
    },
  },
  "& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type": {
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
  },
  "& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type": {
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    borderRight: "1px solid rgba(145, 158, 171, 0.16)",
  },
  "& .MuiDataGrid-cell": {
    boxSizing: "border-box",

    color:
      theme.title === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",

    ":has(#icon-table)": {
      overflow: "visible",
    },
    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
  },

  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
}));
