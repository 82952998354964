import styled from "styled-components";
import { HeaderBack } from "../style";

export const Header = styled(HeaderBack)`
 display: grid;
 grid-template-columns: auto 1fr auto auto;
 gap:5px;

 .btn-arrow{
    padding:0
 }
 #icon {
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  #icon-purple {
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }
`

export const FlexToogle = styled.div`
    display: flex;
    align-items:center;
    gap:10px;
`