import styled from "styled-components";

export const SideBAr = styled.div`
  grid-area: nav;
  width: 100%;
  height: auto;
  .margin-cluster {
    padding-left: 12px;
  }
  cursor: pointer;
  hr {
    background-color: rgb(1997, 197, 197);
    margin: 0px;
  }

  .divider-item {
    background-color: ${({ theme }) => theme.backgrounds.lightGrey};
  }
`;

export const SidebarItemContainer = styled.div`
  .storage-repository {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    cursor: default;
  }
  .p-informations-storage {
    box-sizing: border-box;
    font-size: 0.8rem;
    margin: 12px 36px 0px 36px;
  }
  .lineBar {
    border-radius: 16px;
    overflow: hidden;
    margin: 0px 36px;
  }
  .margin {
    margin-right: 8px;
  }
`;

export const NewFileStyle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  > {
    background-color: ${({ theme }) => theme.backgrounds.white};
  }
`;

export const ButtonEvent = styled.button`
  display: flex;
  border-radius: 16px;
  align-items: center;
  padding: 2px 14px;
  color: ${(props) => props.theme.texts.black};
  background-color: ${(props) => props.theme.backgrounds.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};
  p {
    margin: 0px;
  }
  svg {
    margin: 0px !important;
  }
  :hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  :active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  #icon {
    color: ${(props) => props.theme.texts.black};
  }
`;

interface SideBarProps {
  $selected: boolean;
  $paddingLeft: string;
  $active: boolean;
}
export const SidebarItemStyle = styled.div<SideBarProps>`
  display: flex;
  color: ${({ $active }) => ($active ? "inherit" : "#8e8e8e")};
  pointer-events: ${({ $active }) => ($active ? "inherit" : "none")};
  padding: 10px 0;
  padding-left: ${({ $paddingLeft }) => `${$paddingLeft}px`};
  border-radius: 0 100px 100px 0;
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.hover.lightGrey : "inherit"};

  .up {
    transform: rotate(90deg);
    transition: transform 0.4s linear;
  }

  .down {
    transform: rotate(0deg);
    transition: transform 0.4s linear;
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const SidebarItemArrow = styled.div`
  width: 28px;
  margin-left: 12px;
  :hover {
    cursor: default;
  }
`;

export const SidbarItemMain = styled.div`
  display: flex;
  p {
    margin: 0px;
    padding: 0px;
    margin-left: 12px;
  }
`;
