import { } from 'react';
import { IoIosClose } from "../../../assets/icons";
import { Load } from '../../index';
import ItemFile from '../../ItemFile';
import { ButtonDownload, LoadUpload } from '../style';
import { DownloadFile } from './style';

const FileCircle = ({ myMessage, fileType, children, ...rest }) => (
    <DownloadFile isMy={myMessage}>
        <button className="iconFile" {...rest}>
            <ItemFile type={fileType} />
            <div className="float-download">
                {children}
            </div>
        </button>
    </DownloadFile>
);
const FileLoad = ({ myMessage, ...rest }) => (
    <ButtonDownload isMy={myMessage} {...rest}>
        <LoadUpload color='purple'>
            <Load width="20px" size="2px" primaryColor={'light'} secundaryColor={'dark'} />
            <div className="cancel-icon">
                <IoIosClose className="icon" />
            </div>
        </LoadUpload>
    </ButtonDownload>
)

export { FileLoad, FileCircle };
