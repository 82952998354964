import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { InputControlled } from "../../../../../components/Input";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { StorageMediaService } from "../../../../../services/requests/storage-media";
import { FileRepository } from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import { Container } from "./styles";

interface ColumnsProps {
  modal: any;
  file: FileRepository;
}

const ModalUpdateMedia = ({ modal, file }: ColumnsProps) => {
  const fileName = file.name.split(".")[0];
  const [nameMedia, setNameMedia] = useState(fileName);
  const queryClient = useQueryClient();
  const inputRef = useRef<HTMLInputElement>(null);

  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation(
    (data: { name: string }) =>
      StorageMediaService.patchName({
        storageMediaId: file.fileName,
        name: data.name,
      }),
    {
      onSuccess: ({ status }: AxiosResponse) => {
        if (status == 200) {
          queryClient.setQueryData(
            [
              Queries.REPOSITORY_FILES,
              file.StorageCluster_id ? file.StorageCluster_id : "",
            ],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.map((item) => {
                  if (item.fileName === file.fileName) {
                    return { ...file, name: nameMedia };
                  }
                  return item;
                });
              }
              return [prevData];
            }
          );
          modal.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  useEffect(() => {
    if (modal.current?.open) {
      inputRef.current?.focus();
    }
  }, [modal]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      saveChange();
    }
  };
  function saveChange() {
    if (!isLoading) {
      mutate({ name: nameMedia });
    }
  }

  const handleName = (e) => {
    if (e.target.value.length > 100) return;
    setNameMedia(e.target.value);
  };

  return (
    <ModalContainer
      loadRequisition={isLoading}
      padding={false}
      onButtonRight={saveChange}
      buttonConfirmation={true}
      title="Editar arquivo"
      onClick={() => modal.current.close()}
      buttonBack={true}
    >
      <Container>
        <InputControlled
          maxLength={100}
          onKeyPress={handleKeyPress}
          ref={inputRef}
          value={nameMedia}
          onChange={handleName}
          placeholder="Nome"
        />
      </Container>
    </ModalContainer>
  );
};

export default ModalUpdateMedia;
