import { IoIosArrowBack } from "react-icons/io";
import { TGroupInfo } from "../../types/project";
import { photoUrl } from "../../util/verification";
import { ButtonPassWord } from "../../pages/home/Settings/User/style";
import { ButtonConfirmPassWord, Container, Header } from './style';
import { useEffect, useState } from "react";
import { getGroupForInvite } from "../../services/requests/group";
import { bindActionCreators } from "redux";
import { insertItemInGeneral } from '../../store/actions/folderAction';
import { connect } from "react-redux";
import { postAddUserInGroup } from "../../services/requests/user-group";
import { ToastModel } from "../../util/error";
import { useToasts } from "react-toast-notifications";
import { TypeConstRedux } from "../../types/redux";
import { Socket } from "socket.io-client";


type TProps = {
    modal: any;
    invite: string;
    abareId: string;
    insertItemInGeneral: any;
    socket: Socket
}

const Link = (props: TProps) => {
    const { modal, invite, insertItemInGeneral } = props
    const [group, setGroup] = useState<TGroupInfo>()
    const { addToast } = useToasts();
    useEffect(() => {
        getGroupForInvite(invite).then((res) => {
            if (res.status == 200) {
                setGroup(res.data.group)
            }
        })
    }, [])

    const enterInGroup = () => {
        postAddUserInGroup(group?.id).then((res) => {
            if (res.status == 200) {
                const groupInfo = res.data.result;
                insertItemInGeneral({ ...groupInfo });
                modal.current.close()
            } else if (res.status == 403) {
                ToastModel(addToast, "Limite excedido!", `O limite é ${res.data.limitUsers} pessoas. Contate o criador do grupo para assinar um pacote superior.`, "error");
            } else if (res.status == 409) {
                ToastModel(addToast, "Você já está no grupo", `Procure o grupo na pasta Geral`, "error");
            }
        })
    }

    return (
        <Container >
            <Header>
                <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                <h5>{group?.groupType ? "Canal" : "Grupo"} - Entrar</h5>
            </Header>
            <div className="grupo">
                <img id="img" src={photoUrl(group)} />
                <h5 id="name">{group?.name}</h5>
            </div>
            <h5>{group?.memberNumber} Participantes</h5>
            <div className="buttons">
                <ButtonPassWord onClick={() => modal.current.close()}>
                    Cancelar
                </ButtonPassWord>
                <ButtonConfirmPassWord onClick={() => enterInGroup()}>
                    Entrar no {group?.groupType ? "canal" : "grupo"}
                </ButtonConfirmPassWord>
            </div>
        </Container>
    );
}
const mapStateToProps = (state: TypeConstRedux) => ({
    socket: state.socket,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    insertItemInGeneral,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Link);