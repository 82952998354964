import styled from "styled-components";

export const Back = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f5f6;
  margin: 0;
  padding: 1.11rem 1rem;
  font-size: 14px;
  position: sticky;
  top: 0;
  z-index: 2;

  h3 {
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  #icon {
    font-size: 16px;
    padding-right: 30px;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
  }
`;

export const HorizontalRowCheck = styled.div`
  font-family: "inter", sans-serif;
  display: flex;
  align-items: center;
  padding: 1% 25%;
  padding-top: 8px;
  cursor: pointer;

  span {
    font-size: 10px;
    margin-top: -3px;
  }
`;

export const HorizontalRow = styled.div`
  cursor: pointer;
  font-family: "inter", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 5px 0px;
  background-color: ${(props) => props.theme.backgrounds.white};
  margin-top: -25px;

  .Privadas {
    cursor: pointer;
  }

  .search-plataform {
    display: flex;
    align-items: center;
    gap: -10px;

    .buttons {
      margin-bottom: 5px;
      margin-right: 5px;
    }
  }

  .icon-text {
    margin-left: -6px;
    margin-bottom: 20px;
  }

  .title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-bottom: -9.2px;
    color: ${(props) => props.theme.texts.darkerGreyII};
  }

  span {
    font-size: 12px;
    color: #fff;
  }

  .icon-info {
    margin-left: 10px;
    margin-top: 15px;
    font-size: 13px;
  }

  .icon {
    font-size: 13px;
  }

  .active {
    width: 2rem;
    height: 2rem;
    color: #700540;
  }

  .inactive {
    width: 2rem;
    height: 2rem;
    color: #afafaf;
    margin-left: -5px;
  }

  .buttons {
    justify-content: stretch;
    margin-bottom: -3px;
    margin-right: 5px;
  }

  .btn-add {
    padding-right: 7px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  .btn-check {
    cursor: pointer;
  }
`;

export const ModalContainer = styled.div`
  .icon-back {
    color: #7c1a40;
    padding-right: 15px;
  }

  .container {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: rgba(237, 237, 237, 0.25);
  }

  .container-wallpaper {
    text-align: -webkit-center;
    background-color: ${(props) => props.theme.backgrounds.white};
  }

  .item {
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.backgrounds.white};
    padding: 24px;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    .crop-buttons {
      display: -webkit-box !important;
      margin: auto;

      .btn-cancel {
        margin-left: 30px;
      }
    }

    .wallpaper-inputs {
      max-width: 370px;
      width: 100%;
      display: inline-block;
      text-align: center;
    }

    .input-label {
      text-align: left;
      color: #000;
      padding-bottom: 5px;
    }

    .desc {
      margin-top: 1rem;
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    #row {
      display: flex;
      margin-top: 2px;
    }

    .texto {
      text-align: center;
      font-size: 14px;
      color: #adadad;
      padding: 0rem 3rem 0rem 3rem;
    }

    .horizontalButtons {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .msg {
      font-size: 10px;
      color: #2f2e2e;
      text-align: right;
      display: block;
    }

    .img-container {
      background-color: rgba(37, 129, 196, 0.05);
      margin: auto;
      border-radius: 10px;
      height: 247px;
      max-width: 383px;

      @media only screen and (max-width: 660px) {
        height: 180px;
      }

      @media only screen and (max-height: 690px) {
        height: 170px;
      }

      #profile {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      h3 {
        color: #2f2e2e;
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;

        @media only screen and (max-width: 660px) {
          font-size: 25px;
        }

        @media only screen and (max-height: 690px) {
          font-size: 25px;
        }
      }

      .add-img-icon {
        color: #a6446a;
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        margin: auto;
        cursor: pointer;

        @media only screen and (max-width: 660px) {
          height: 80px;
          width: 80px;
        }

        @media only screen and (max-height: 690px) {
          height: 80px;
          width: 80px;
        }
      }

      .add-wallpaper {
        padding-top: 1.5rem;
      }
    }
  }

  .item-wallpaper {
    min-height: 260px;
    max-height: 560px;
    width: 80%;
    background: rgba(237, 237, 237, 0.5);
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;

    @media only screen and (max-height: 690px) {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 20px;
    }
  }
`;

export const Row = styled.div`
  font-family: "inter", sans-serif;
  font-size: 16px;

  .line {
    margin-top: 5px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: 14px;
    color: ${(props) => props.theme.default.darkerI};
  }

  .check-text {
    font-size: 10px;
  }

  .inputs {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  input: disabled {
    background-color: #fbfbfb;
    border: none;
    font-size: 14px;
    margin-left: 8px;
  }
`;

export const Default = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: red;

  #profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    border-radius: 20px;
    color: ${props => props.theme.backgrounds.white};
    border: 1px solid ${(props) => props.theme.default.dark};
  }
`;

export const OutlineButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: none;
  padding: 5px 8px;
  border-radius: 10px;
  border: solid 1px #2f2e2e;
  transition: box-shadow 0.3s;
  margin-right: 5px;
  margin-top: 5px;

  #icon {
    font-size: 20px;
    color: #2f2e2e;
  }

  .right {
    margin-right: 4px;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    background-color: ${(props) => props.theme.default.ligtherI};
  }
`;

export const FormGroup = styled.form`
  padding-inline: 1rem;
`;

export const ButtonLoad = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  margin: auto;
  font-size: 14px;
  line-height: 17px;
  font-family: "inter", sans-serif;
  color: #fff;
  background-color: ${(props) => props.theme.texts.purpleDarkTitle};
  text-align: center;
  width: 6rem;
  height: 1.8rem;
  margin-top: 18px;
  border-radius: 5px;

  @media only screen and (max-height: 690px) {
    margin-top: 14px;
  }
`;

export const ButtonCrop = styled.button`
  width: 100%;
  display: block;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  font-family: "inter", sans-serif;
  color: #fff;
  background-color: ${(props) => props.theme.purple.dark};
  text-align: center;
  width: 6rem;
  height: 1.8rem;
  margin-top: 18px;
  border-radius: 5px;
`;

export const ModalBox = styled.div`
  max-width: 1024px;
`;
