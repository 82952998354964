import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AiOutlineClose, AiOutlineDownload } from "react-icons/ai";
import { BiCaretLeft, BiCaretRight, BiZoomIn, BiZoomOut } from "react-icons/bi";
import { MdOutlineRotate90DegreesCcw } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";
import { Load } from "../../../../components";
import { InputControlledPdf } from "../../../../components/Input";
import {
  ReportStylesInterface,
} from "../../../../types/project";
import { ContainerView, ToolbarView } from "./style";
import { ReportContext } from "../../../../contexts/ReportContext";

interface ViewProps {
  urlPdf: string | undefined;
  isLoading: boolean;
  downloadPdf: () => void;
}

// Função para converter base64 em Blob
function base64ToBlob(base64Data, contentType = "application/octet-stream") {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays: any[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

const ViewPdf = ({
  urlPdf,
  isLoading,
  downloadPdf,
}: ViewProps) => {
  const { stylesPDF } = useContext(ReportContext);
  const pdfData = urlPdf;

  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const { closeOptionReportSelected } = useContext(ReportContext);
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [inputZoon, setInputZoom] = useState((zoom * 100).toString());
  const [inputPage, setInputPage] = useState("1");

  useEffect(() => {
    setInputPage(pageNumber.toString());
  }, [pageNumber]);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const disabledSkipPageStyle = () =>
    numPages == pageNumber ? `disabled` : ``;
  const disabledPreviousPageStyle = () => (pageNumber == 1 ? `disabled` : ``);

  const disabledZoomOutStyle = () => (zoom < 0.2 ? `disabled` : ``);
  const disabledZoomInStyle = () => (zoom > 1.9 ? `disabled` : ``);

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleLastPage = () => setPageNumber(numPages);

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleFirstPage = () => setPageNumber(1);

  const handleZoomIn = () => {
    if (zoom + 0.1 <= 2) {
      setZoom(zoom + 0.1);
      setInputZoom(((zoom + 0.1) * 100).toFixed(0).toString());
    }
  };

  const handleZoomOut = () => {
    if (zoom - 0.1 >= 0.1) {
      setZoom(zoom - 0.1);
      setInputZoom(((zoom - 0.1) * 100).toFixed(0).toString());
    }
  };

  const handleInputZoom = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputZoom(e.target.value);

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      // Remover o foco do input
      event.currentTarget.blur();
    }
  };

  const handleInputBlur = () => {
    try {
      let parsedValue = parseInt(inputZoon);

      if (!isNaN(parsedValue)) {
        if (parsedValue > 200) {
          parsedValue = 200;
        } else if (parsedValue < 10) {
          parsedValue = 10;
        }
        setZoom(parsedValue / 100);
        setInputZoom(parsedValue.toString());
      } else {
        setInputZoom((zoom * 100).toString());
      }
    } catch (error) {
      setInputZoom((zoom * 100).toString());
    }
  };

  const handleInputPage = (e: React.ChangeEvent<HTMLInputElement>) =>
    setInputPage(e.target.value);

  const handleInputPageKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      // Remover o foco do input
      event.currentTarget.blur();
    }
  };

  const handleInputPageBlur = () => {
    try {
      let parsedValue = parseInt(inputPage);

      if (!isNaN(parsedValue)) {
        if (parsedValue > numPages) {
          parsedValue = numPages;
        } else if (parsedValue < 1) {
          parsedValue = 1;
        }
        setPageNumber(parsedValue);
        setInputPage(parsedValue.toString());
      } else {
        setInputPage(pageNumber.toString());
      }
    } catch (error) {
      setInputPage(pageNumber.toString());
    }
  };

  const handleRotate = () => {
    if (rotate == 0) {
      setRotate(270);
    } else {
      setRotate(rotate - 90);
    }
  };
  return (
    <ContainerView>
      {isLoading || !urlPdf ? (
        <Load dark={true} message="Carregando ..." width={"48px"} />
      ) : (
        <div className="div-pdf">
          <ToolbarView>
            <div className="div-name">{stylesPDF.title.text}</div>
            <div className="div-actions">
              {/* <BiSkipPrevious title='Voltar para primeira página' onClick={handleFirstPage} className={`${disabledPreviousPageStyle()} icon`} /> */}
              <BiCaretLeft
                title="Voltar página"
                onClick={handlePreviousPage}
                className={`${disabledPreviousPageStyle()} icon`}
              />
              <div className="div-pages">
                <InputControlledPdf
                  report={true}
                  onBlur={handleInputPageBlur}
                  onKeyPress={handleInputPageKeyPress}
                  onChange={handleInputPage}
                  value={inputPage}
                  type="number"
                />
                <p> {`/${numPages}`}</p>
              </div>
              <BiCaretRight
                title="Passar página"
                onClick={handleNextPage}
                className={`${disabledSkipPageStyle()} icon`}
              />
              {/* <BiSkipNext title='Passa para última página' onClick={handleLastPage} className={`${disabledSkipPageStyle()} icon`} /> */}
              <hr />

              <BiZoomOut
                title="Diminuir zoom"
                onClick={handleZoomOut}
                className={`${disabledZoomOutStyle()} icon`}
              />
              <InputControlledPdf
                percentagem={true}
                report={true}
                onBlur={handleInputBlur}
                onKeyPress={handleInputKeyPress}
                onChange={handleInputZoom}
                value={inputZoon}
                type="number"
              />
              <BiZoomIn
                title="Aumentar zoom"
                onClick={handleZoomIn}
                className={`${disabledZoomInStyle()} icon`}
              />
              <MdOutlineRotate90DegreesCcw
                onClick={handleRotate}
                className="icon"
                title="Girar em sentido anti-horário"
              />
            </div>
            <div className="div-buttons">
              <AiOutlineDownload
                onClick={downloadPdf}
                title="Baixar documento"
                className="icon"
              />
              <AiOutlineClose
                onClick={closeOptionReportSelected}
                title="Fechar documento"
                className="icon"
              />
            </div>
          </ToolbarView>

          <Document
            onLoadError={(error) => console.log(error)}
            onLoadSuccess={onDocumentLoadSuccess}
            file={pdfData}
          >
            <Page
              rotate={rotate}
              scale={zoom}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>
        </div>
      )}
    </ContainerView>
  );
};

export default ViewPdf;
