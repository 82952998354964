import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { Modal, NotFound } from "../../../../../../components";
import Img from "../../../../../../components/Img";
import { ShimmerCard } from "../../../../../../components/Shimmers";
import { TContact } from "../../../../../../types/project";
import { TypeConstRedux } from "../../../../../../types/redux";
import {
  numberMounthByActual,
  optionsMounthDate,
} from "../../../../../../util/date";
import {
  ordenarAnivesarios,
  photoUrl,
} from "../../../../../../util/verification";
import AllBirthday from "./AllBirthday";
import { Month, More, Title } from "./style";
import { EmptyContent } from "../../../components/EmptyContent";
import SkeletonBirthdays from "../../../../../../components/Skeleton/SkeletonBirthdays";
import { ContainerBirthday } from "../../../style";

const CardBirthday = ({ column1 }) => {
  const birthdays: TContact[] = useSelector(
    (state: TypeConstRedux) => state.contacts.list
  );
  const modalAllBirtday = useRef<any>();
  const [meses, setMeses] = useState<any | never>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    if (birthdays) {
      const mesesA: any[] = [[], [], [], [], [], [], [], [], [], [], [], []];
      birthdays.sort(ordenarAnivesarios).map((contact) => {
        if (contact.birthday) {
          mesesA[parseInt(contact.birthday.substring(5, 7)) - 1].push(contact);
        }
      });
      const mesAtual = new Date().getMonth();

      setMeses([...mesesA.slice(mesAtual), ...mesesA.slice(0, mesAtual)]);
      setIsLoading(false);
    }
  }, [birthdays]);

  const isEmpty = () => {
    let empty = true;
    if (meses) {
      meses.map((mes) => {
        if (mes.length > 0) {
          empty = false;
        }
      });
    }
    return empty;
  };
  return (
    <ContainerBirthday column1={column1}>
      <div className="scroll">
        <Title>Aniversariantes</Title>

        {birthdays == null || isLoading ? (
          <div className="divItens">
            <SkeletonBirthdays />
            <SkeletonBirthdays />

            <SkeletonBirthdays />
          </div>
        ) : isEmpty() ? (
          <EmptyContent
            padding={false}
            message="Procure criar novos contatos na plataforma para ficar por dentro de suas datas comemorativas"
          />
        ) : (
          <>
            <div className="divItens">
              {meses.map((mes, key) => {
                return (
                  mes.length > 0 && (
                    <Month key={key}>
                      <p className="month">
                        {optionsMounthDate[numberMounthByActual(key)].label}
                      </p>
                      <div className="div-img">
                        {mes.map((contact, key) => {
                          const tooltip = `${contact.birthday.substring(
                            8,
                            10
                          )} de ${
                            optionsMounthDate[
                              parseInt(contact.birthday.substring(5, 7))
                            ].label
                          } é o anivesário de ${contact.name}`;
                          let css = "image_birthday";
                          if (key > 0) {
                            css += " spaceImage";
                          }
                          return (
                            <div key={key} data-tip={tooltip}>
                              <Img
                                key={key}
                                className={css}
                                id="profile"
                                src={photoUrl(contact)}
                              />
                              <ReactTooltip
                                textColor="#fff"
                                border={true}
                                borderColor="#BDBDBD"
                                backgroundColor="#000"
                                place="right"
                                effect="solid"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Month>
                  )
                );
              })}
            </div>

            <More
              relative={meses.length}
              onClick={() => modalAllBirtday.current?.open()}
            >
              Ver tudo
            </More>
          </>
        )}
      </div>

      <Modal mobile={true} ref={modalAllBirtday}>
        <AllBirthday
          modalAllBirthday={modalAllBirtday}
          defaultContacts={birthdays}
        />
      </Modal>
    </ContainerBirthday>
  );
};

export default CardBirthday;
