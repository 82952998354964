import styled from "styled-components";

export const Main = styled.div`
  .some{
    display: none;
  }
`;

export const Container = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  .vermelho {
    color: #c94342 !important;
  }

  .amarelo {
    color: #ffa500 !important;
  }

  .verde {
    color: #23ad5c !important;
  }

  .test {
    font-size: 14px;
    text-align: left;
  }

  p {
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
  }

  .icon {
    color: #848383;
    margin-left: 4px;
  }

  .palavra_status {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;

    color: #c94342;
  }

  .barra {
    height: 14px;
    width: 100%;
    margin-bottom: 4px;

    border-radius: 4px;
    border: 1px solid #bdbdbd;

    .noDisplay {
      display: none;
    }

    .barra_fraca {
      border-radius: 4px;

      height: 100%;
      width: 33%;
      background-color: #c94342;
      z-index: -1;
    }

    .barra_media {
      border-radius: 4px;

      height: 100%;
      width: 66%;
      background-color: #ffa500;
      z-index: -1;
    }

    .barra_forte {
      border-radius: 4px;

      height: 100%;
      width: 100%;
      background-color: #23ad5c;
      z-index: -1;
    }
  }

  .footer {
    justify-content: start;

    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #848383;
  }
`;