import React, { useState, useEffect } from 'react';
import Plan from './plan.component';
import { IPlanInfo, IPlansList } from './plans.interfaces';
import { index as signatureIndex } from './signature.service';
import { PlansList as PList } from './style';

function PlansList(props: IPlansList) {
  const { billingType } = props;
  const [plans, setPlans] = useState<IPlanInfo[]>([]);

  useEffect(() => {
    getPlansList();
  }, []);

  const verifyTimePlan = (duration) =>{
    switch (duration) {
      case 30:
      case 31:
        return "mensal"
      case 365:
      case 366:
        return "anual"
      default:
        return "semestral"
    }
  }
  async function getPlansList(): Promise<void> {
    var data = await signatureIndex();
    data = data.map((dataInfo) => ({ ...dataInfo, billing: verifyTimePlan(dataInfo.duration) }))
    if (plans) {
      setPlans(data);
    }
  }

  return (
    <PList>
      {
        plans?.filter((plan) => plan.billing === billingType).map((plan) => (
          <Plan key={plan.id} info={plan} />
        ))
      }
    </PList>
  );
}

export default PlansList;
