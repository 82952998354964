import styled from "styled-components";

interface StyledProps {
  click?: boolean;
  $loading?: boolean;
}
export const Container = styled.div<StyledProps>`
  grid-column: span 1;
  grid-row: span 1;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.bordes.card};
  background: ${({ theme }) => theme.backgrounds.cardWheather};
  box-shadow: ${({ theme }) => theme.boxShadow.card};

  border-radius: 14px;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
  max-height: 370px;
  padding: ${({ $loading }) =>
    $loading ? "0px" : "1.5rem 1.5rem 0.5rem 1rem"};

  box-sizing: border-box;

  ${(props) => props.click && "cursor: pointer;"};

  p {
    margin: 0;
  }
  .circle {
    background: ${({ theme }) => theme.linearGradient.cardWheather};
    position: absolute;
    border-radius: 50%;
    top: 50%;
    width: 100%;
    padding-bottom: 100%;
    right: 40%;
    z-index: 1;
  }
  .content-message {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .title {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.texts.white};
  }

  .content {
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 120% */
  }

  .tempo {
    z-index: 2;

    color: #fff;
    font-family: "inter-bold", sans-serif;
    font-size: 3.375rem;
    font-style: normal;
    line-height: normal;
  }

  .icon {
    position: absolute;
    right: 10px;
    bottom: 0px;
    height: 100px;
    width: 100px;
  }
`;
