import { memo, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineMenu } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { ModalRef } from "../../../../components/Modal";
import ModalEvent from "../../../../components/Modal/ModalEvent";
import { getEvents } from "../../../../services/requests/event";
import { setCommitment, setInvitation } from "../../../../store/actions/scheduleAction";
import { EnumScheduleType, TypeConstRedux } from "../../../../types/redux";
import MyCalendar from "../components/Calendar/Calendar";
import ModalCalendar from "../components/ModalCalendar";
import { ButtonEvent, Container, ContainerCalendar, ContainerTitle } from "./style";

const Content = (props) => {
    const { commitment, invitation } = useSelector((state: TypeConstRedux) => state.schedule);
    const dispatch = useDispatch();
    const type = useSelector((state: TypeConstRedux) => state.schedule.filter);
    const title = type === EnumScheduleType.GROUP ? "Agenda de Grupos" : "Agenda Pessoal"
    const modalEvent = useRef<ModalRef>(null);
    const [shadow, setShadow] = useState(false);
    const refContainer = useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!commitment) {
            setIsLoading(true);
        }
        getEvents().then((res) => {
            console.log(res)
            if (res.status == 200) {
                dispatch(setCommitment(res.data.events))
                dispatch(setInvitation(res.data.invitations))
            }
        }).finally(() => { setIsLoading(false) });

    }, [])

    const handleMenu = () => {
        setMenuOpen(true);
    }
    return (
        <Container id="modal-calendar" ref={refContainer}>
            <ContainerTitle $shadow={shadow}>
                <p className="title">
                    <AiOutlineMenu id="icon-menu" size={24} onClick={handleMenu} />
                    {title}
                </p>
                <ButtonEvent onClick={() => modalEvent.current?.open()}>
                    <p> Novo </p>
                    <IoAdd id="icon" size={32} />
                </ButtonEvent>
            </ContainerTitle>
            <ContainerCalendar>
                <MyCalendar isLoading={isLoading} />

            </ContainerCalendar>
            <ModalEvent modal={modalEvent} />
            <ModalCalendar open={menuOpen} setOpen={setMenuOpen} />
        </Container>
    );
}

export default memo(Content);
