import Notification from "..";
import ModalContainer from "../../../../../components/Modal/ModalContainer";


const AllNotifications = ({ modalRef, notifications, handleStatus, handleDelete }) => {

    return (
        <ModalContainer padding={false} title="Notificações" onClick={() => modalRef.current.close()} buttonBack={true}>
            {notifications?.map((notification, index) => (<Notification modal={modalRef} key={index} firstItem={index === 0} padding={true} handleStatus={handleStatus} handleDelete={handleDelete} notification={notification} />))}
        </ModalContainer>
    )
};

export default AllNotifications;
