import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";
import { HiOutlineUsers } from "react-icons/hi";
import { RiUserSettingsLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Load } from "..";
import { IoIosArrowBack } from '../../assets/icons';
import { setListAtiva } from "../../store/actions/folderAction";
import { Container, ContainerBack, ContainerNoPlace, InfoContainer, MainLoad } from "./style";

import { FaDirections } from "react-icons/fa";
import { MdOutlineReadMore } from "react-icons/md";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import { getInfoGroup } from "../../services/requests/group";
import { updateClient } from "../../store/actions/messages/chatAction";
import mapStyles from "./mapStyle";
import WindowGroup from "./WindowGroup";

const libraries = ["places"];
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
}
const center = {
  lat: -3.079047,
  lng: -59.995807,
}

const styleMap = { width: "100%", height: "100%" }

const MapPage = (props) => {
  const { agenda = false, ativo, abare, globalMessages, updateClient, weather } = props
  const { goBack } = useHistory()

  const [markerSelected, setMarkerSelected] = useState(null)
  const [informationComplete, setInformationComplete] = useState(null)

  const [load, setLoad] = useState(false)
  const [markers, setMarkers] = useState([])

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyD-QsVLrPfZ6tN0U4KAcNSMgOpbw-2bP-I",
    libraries,
    id: 'google-map-script',
  })

  const mapRef = useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  useEffect(() => {
    setMarkers([])
    ativo.itens && ativo.itens.map((item) => {
      if (item.Group_id && globalMessages[item.Group_id].client.geoRefLat) {
        setMarkers((markers) => ([...markers, globalMessages[item.Group_id].client]))
      }
    })
  }, [ativo])

  function activeMarker(markerSelect) {
    setMarkerSelected(markerSelect)
    if (markerSelect.userGroup === undefined) {
      setLoad(true)
      getInfoGroup(abare.id, markerSelect.Group_id).then((res) => {
        if (res.status == 200) {
          updateClient({ ...res.data, isRequest: false })
          setMarkers((markers) => {
            const id = markers.indexOf(markerSelect)
            markers[id] = { ...res.data, isRequest: false }
            return [...markers]
          })
          setMarkerSelected({ ...res.data, isRequest: false })
        }
        setLoad(false)
      })
    }
    setInformationComplete(false)
  }

  function showInformation(marker) {
    setInformationComplete(marker)
    if (screen.width <= 540) {
      mapRef.current.panTo({ lat: marker.geoRefLat, lng: marker.geoRefLon })
    } else {
      mapRef.current.panTo({ lat: marker.geoRefLat, lng: (marker.geoRefLon + 0.01) })
      // mapRef.current.panTo({ lat: marker.geoRefLat, lng: (marker.geoRefLon + 0.005) })
    }
    mapRef.current.setZoom(15)
  }


  function closeWindow() {
    if (informationComplete && mapRef.current.zoom >= 14) {
      mapRef.current.setZoom(13)
    }
    setMarkerSelected(null)
    setInformationComplete(false)
  }

  if (loadError) return <div className="loading">{'Error ' + loadError}</div>
  if (!isLoaded) return <MainLoad>
    <Load width="30px" />
    <h4 id="loading">Carregando...</h4>
  </MainLoad>

  return (
    <>
      {(!markers || markers.length < 1) ?
        <ContainerNoPlace>
          No Momento não há nenhum endereço cadastrado nesta pasta
        </ContainerNoPlace> :

        <Container>
          <ContainerBack className="back" onClick={() => goBack()}>
            <IoIosArrowBack className="icon" />
          </ContainerBack>
          {informationComplete && <WindowGroup groupInfo={informationComplete} setMarkerSelected={setMarkerSelected} setInformationComplete={setInformationComplete} />}
          <GoogleMap
            id="map"
            mapContainerStyle={styleMap}
            center={center}
            options={options}
            zoom={12}
            onLoad={onMapLoad}
          >
            {markers.map((marker) => (
              <Marker
                key={`${marker.geoRefLat}-${marker.geoRefLon}`}
                position={{ lat: marker.geoRefLat, lng: marker.geoRefLon }}
                onClick={() => (activeMarker(marker))}
                onMouseOver={() => (activeMarker(marker))}
                onMouseOut={() => { }}
              >
                {markerSelected && markerSelected.name === marker.name &&
                  <InfoWindow
                    onCloseClick={() => (closeWindow())}
                  >
                    {
                      load
                        ? <MainLoad window load={true}>
                          <Load width="15px" />
                          <h4 id="loading">Carregando...</h4>
                        </MainLoad> :
                        <InfoContainer>
                          {/* CAMPO TÍTULO GRUPO */}
                          <h4 className="title-pasta">
                            Pasta <strong>{ativo.name}</strong>
                          </h4>
                          <h2 className="noMargin">
                            {marker.name}
                          </h2>

                          {
                            marker.Group_id &&
                            <>
                              {/* CAMPO COORDENADOR */}
                              <div className="lideres margin-box">
                                <RiUserSettingsLine />
                                <div>
                                  {(marker.leaders && marker.leaders.length > 0) ? marker.leaders.map(({ name, id }, i) => {
                                    if (i <= 2)
                                      return <p className="p_lideres" key={id}>{name}</p>
                                  })
                                    : <p className="p_lideres">Não há lideres nesse grupo</p>}
                                </div>
                              </div>

                              {/* CAMPO CONTATOS */}
                              <div className="campo-contato">
                                <p className="margin-box">
                                  <HiOutlineUsers />
                                  {` ${marker.memberNumber} Membros`}</p>
                              </div>
                            </>
                          }
                          <div className="buttons">
                            <a data-tip data-for='maps' href={`https://www.google.com.br/maps/dir/${weather ? `${weather.coord.lat},${weather.coord.lon}` : ``}/${marker.address ? marker.address.replace(" ", "+") : `${marker.geoRefLat},${marker.geoRefLon}`}/@`} className="button_maps" target="_blank" rel="noopener noreferrer">

                              <FaDirections size={22} /></a>
                            {!informationComplete && <button data-tip data-for='information' className="button_information" onClick={() => (showInformation(marker))}>

                              <MdOutlineReadMore size={22} /></button>}
                          </div>
                          {/* {!informationComplete &&
                            <button
                              className="button-completo"
                              onClick={() => (showInformation(marker))}>
                              Click para informações detalhadas</button>} */}
                        </InfoContainer>
                    }

                  </InfoWindow>
                }
                <ReactTooltip id='maps'>
                  <span>Abrir rotas até grupo</span>
                </ReactTooltip>
                <ReactTooltip
                  id='information'>
                  <span>Mais informações</span>
                </ReactTooltip>
              </Marker>
            ))}
          </GoogleMap>
        </Container>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    ativo: state.folder.ativa,
    abare: state.abare.abare,
    personal: state.chat.personal,
    globalMessages: state.chat.globalMessages,
    weather: state.dashboard.weather_dash,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateClient,
  setListAtiva,

}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(MapPage);