import styled from "styled-components";

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  img,
  video {
    max-height: 300px;
    max-width: 100%;
    min-height: 150px;
    min-width: 150px;
    width: 100%;
    height: auto;
  }

  .blur {
    filter: blur(2px);
  }

  .uploading {
    position: absolute;
    width: min-content;
    height: min-content;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;