import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { patchUpdatePrivacyUser } from "../../../../../services/requests/privacy";
import { setAbare } from "../../../../../store/actions/abareAction";
import { ToastModel } from "../../../../../util/error";
import ItemContainer from "../../Components/ItemContainer";
import Container from "../../Components/SettingsContainer"
import ToogleSilence from "../../Components/ToogleSilence";
import { VerticalRowItems } from "../../style";

const Notification = (props) => {
    const { abare, setAbare } = props;
    const { addToast } = useToasts();


    const changePrivacy = (data) => {
        patchUpdatePrivacyUser(abare.id, data)
            .then((res) => {
                if (res.status == 200) {
                    setAbare({ ...abare, privacy: res.data.result })
                }
                else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            })
    }
    return (
        <Container title="Notificações"> {/* Notificações e sons */}
            <ItemContainer title="Mensagens">
                <ToogleSilence title='Notificação de Aniversáriantes' value={abare.privacy?.birthayNotifica}
                    onEnable={() => changePrivacy({ birthayNotifica: true })}
                    onDisable={() => changePrivacy({ birthayNotifica: false })}
                />
                <ToogleSilence title='Prévia de Mensagens' value={abare.privacy?.previewNotifica}
                    onEnable={() => changePrivacy({ previewNotifica: true })}
                    onDisable={() => changePrivacy({ previewNotifica: false })} />
                {/* <VerticalRowItems isSingle isHover onClick={() => push('/settings/notifications/soundNotification')}>
                    Som de Notificação
                    <div className="line">Silencioso</div>
                </VerticalRowItems> */}
            </ItemContainer>
            {/* <!-- notificações de sistema -->*/}
            <ItemContainer title="Grupos">
                <ToogleSilence title='Notificação de mensagens de agendamento' value={abare.privacy?.eventNotifica}
                    onEnable={() => changePrivacy({ eventNotifica: true })}
                    onDisable={() => changePrivacy({ eventNotifica: false })}
                />
                <ToogleSilence title='Notificação de marcação de mensagens' value={abare.privacy?.tagNotifica}
                    onEnable={() => changePrivacy({ tagNotifica: true })}
                    onDisable={() => changePrivacy({ tagNotifica: false })}
                />
                {/* <VerticalRowItems isSingle isHover onClick={() => push('/settings/notifications/soundGroups')}>
                    Som de Notificação
                    <div className="line">Silencioso</div>
                </VerticalRowItems> */}
            </ItemContainer>
            {/* <ItemContainer title="Chamadas">
                <VerticalRowItems isSingle isHover onClick={() => push('/settings/notifications/soundCalls')}>
                    Som de Notificação
                    <div className="line">Silencioso</div>
                </VerticalRowItems>
            </ItemContainer> */}
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAbare,
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(Notification);