import { ReducerConfig } from "../../types/redux";
import { SET_OPTION_CONFIG } from "../actions/actionTypes";

const initialState: ReducerConfig = {
  option: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_OPTION_CONFIG:
      return { ...state, option: action.payload };
    default:
      return { ...state }; /*  */
  }
}
