import { AxiosError, AxiosResponse } from "axios";
import { RefObject, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { useToasts } from "react-toast-notifications";
import { InputControlled } from "../../../../components/Input";
import Modal, { ModalRef } from "../../../../components/Modal";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import {
  ReportTemplateData,
  ReportTemplateService,
  ReportTemplateUpdate,
} from "../../../../services/requests/report-template";
import { Queries } from "../../../../types/queries";
import { ToastModel } from "../../../../util/error";
import { ContainerModal } from "./style";

interface ModalProps {
  modal: RefObject<ModalRef>;
  template: ReportTemplateData;
}

const ModalEditTemplate = ({ modal, template }: ModalProps) => {
  const [name, setName] = useState(template.name);

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation(
    (data: ReportTemplateUpdate) => ReportTemplateService.updateName(data),
    {
      onSuccess: ({ data, status }: AxiosResponse, dataSend) => {
        if (status == 200) {
          queryClient.setQueryData(Queries.REPORT_TEMPLATES, (prevData) => {
            if (Array.isArray(prevData)) {
              return prevData.map((item) => {
                if (item.fileName == dataSend.fileNameId) {
                  return { ...item, name: dataSend.name };
                }
                return item;
              });
            }
            return [data];
          });
          modal.current?.close();
          ToastModel(
            addToast,
            "Template atualizado!",
            "Nome atualizado com sucesso",
            "success"
          );
        } else {
          ToastModel(addToast, "Erro de conexão!", "Error", "error");
        }
      },
      onError: (err: AxiosError) => {
        ToastModel(addToast, "Erro de conexão!", err.message, "error");
      },
    }
  );

  function saveChange() {
    if (!isLoading) {
      mutate({ name: name, fileNameId: template.fileName });
    }
  }

  const onBack = () => modal.current?.close();

  return (
    <Modal mobile={true} ref={modal}>
      <ModalContainer
        loadRequisition={isLoading}
        padding={false}
        onButtonRight={saveChange}
        buttonConfirmation={true}
        title="Editar template"
        onClick={onBack}
        buttonBack={true}
      >
        <ContainerModal>
          <InputControlled value={name} onChange={handleName} label={"Nome"} />
        </ContainerModal>
      </ModalContainer>
    </Modal>
  );
};

export default ModalEditTemplate;
