import { ChangeEvent, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { CropImage, Input, InputPhone, InputSelect, Load, Modal } from "..";
import {
  BsImages,
  FaUser,
  FiCrop,
  FiTrash,
  IoIosClose,
} from "../../assets/icons";
import { Back } from "../../pages/home/Conversations/Chat/Profile/style";
import { postContact } from "../../services/requests/contact";
import { addContact } from "../../store/actions/contactsAction";
import { updateClient } from "../../store/actions/messages/chatAction";
import { setChat } from "../../store/actions/messages/messageAction";
import { TypeImage } from "../../types/project";
import { ToastMessage } from "../../util/error";
import { cnpjMask, cpfMask } from "../../util/mask";
import {
  createUrl,
  getFirstName,
  getLastName,
  isFilled,
  photoUrl,
  someNumberInString,
  userIdContact,
} from "../../util/verification";
import { Button } from "../Button/style";
import { BtnFile, Default, Form, OutlineButton } from "./style";

const options = [
  { value: "1", label: "Família" },
  { value: "2", label: "Trabalho" },
  { value: "3", label: "Amigos" },
  { value: "4", label: "Escola" },
  { value: "5", label: "Sociedade" },
];

const InputDoc: any = ({
  isCpf,
  setIsCpf,
  handleDoc,
  cpfUser,
  titleDoc,
  regexDoc,
}) => (
  <>
    <label>
      <input
        checked={isCpf}
        onChange={() => setIsCpf(true)}
        type="radio"
        name="CPF"
      />
      CPF
    </label>
    <label className="marginLeft">
      <input
        checked={!isCpf}
        onChange={() => setIsCpf(false)}
        type="radio"
        name="CNPJ"
      />
      CNPJ
    </label>
    <Input
      name="cpf"
      value={cpfUser}
      placeholder="Digite o número de inscrição"
      autoFocus
      title={titleDoc}
      onChange={handleDoc}
      pattern={regexDoc}
    />
  </>
);

const MapFields: any = ({ fields }) =>
  fields.map(({ Component, ...rest }, key) =>
    Component ? (
      <div className={rest.name} key={key}>
        <Component {...rest} />
      </div>
    ) : (
      <Input
        key={key}
        className={rest.name}
        placeholder="Digite aqui "
        {...rest}
      />
    )
  );

const AddContact = (props) => {
  const {
    abare,
    personal,
    setChat,
    globalMessages,
    updateClient,
    addContact,
    onClose,
  } = props;
  const { addToast } = useToasts();
  const { path } = useRouteMatch();
  const hasUser = false;
  const personalId = personal?.Personal_id;
  const contact =
    hasUser && personalId && globalMessages[personalId]
      ? globalMessages[personalId].client
      : null;
  const [phone, setPhone] = useState(contact?.phoneNumber);
  const [cpfUser, setCpfUser] = useState("");
  const [loading, setLoading] = useState(false);

  const { push, goBack } = useHistory();
  const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || "";
  const fileInput = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<TypeImage>();
  const modalImage = useRef<any>();
  const refCrop = useRef<any>(null);

  const [isCpf, setIsCpf] = useState<boolean>(true);
  const regexDoc: string = isCpf
    ? "[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}$"
    : "[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}$";
  const titleDoc: string = isCpf ? "999.999.999-99" : "99.999.999/9999-99";

  const updateArray = (contact, name) => {
    personal.name = name;
    personal.contactInfo = contact;
    updateClient({ ...personal });
  };

  const handleDoc = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setCpfUser(isCpf ? cpfMask(target.value) : cnpjMask(target.value));
  };

  const fields = [
    {
      name: "name",
      label: "Nome",
      defaultValue: getFirstName(contact?.name),
      required: true,
    },
    {
      name: "lastName",
      label: "Sobrenome",
      defaultValue: getLastName(contact?.name),
    },
    {
      name: "doc",
      label: "CPF",
      placeholder: "Digite o número de inscrição",
      isCpf,
      setIsCpf,
      handleDoc,
      cpfUser,
      titleDoc,
      regexDoc,
      Component: InputDoc,
    },
    {
      name: "email",
      label: "E-mail",
      defaultValue: contact?.email,
      placeholder: "Digite o e-mail aqui",
    },
    {
      name: "birthday",
      label: "Data de aniversário",
      defaultValue: contact?.birthday,
      placeholder: "Digite a data aqui",
      type: "date",
    },
    {
      name: "relationship",
      label: "Relacionamento",
      placeholder: "Selecione aqui",
      options: options,
      Component: InputSelect,
    },
    {
      name: "phone",
      label: "Número de Telefone",
      placeholder: "Digite o número aqui",
      onChange: () => setPhone,
      value: phone,
      Component: InputPhone,
    },
    {
      name: "zipCode",
      label: "CEP",
      placeholder: "Digite seu CEP aqui",
      maxLength: 8,
      title: "Apenas números!",
      pattern: "[0-9]+$",
    },
    {
      name: "street",
      label: "Logradouro (Rua, Av, ...)",
      placeholder: "Digite seu logradouro aqui",
      maxLength: 100,
    },
    {
      name: "nbhood",
      label: "Bairro",
      placeholder: "Digite seu bairro aqui",
      maxLength: 100,
    },
    {
      name: "city",
      label: "Cidade",
      placeholder: "Digite sua cidade aqui",
      maxLength: 70,
    },
    {
      name: "state",
      label: "Estado",
      placeholder: "Digite seu estado aqui",
      maxLength: 70,
    },
    {
      name: "country",
      label: "País",
      placeholder: "Digite seu país aqui",
      maxLength: 70,
    },
  ];

  const createContact = (e) => {
    e.preventDefault();
    const {
      name,
      lastName,
      email,
      relationship,
      birthday,
      zipCode,
      street,
      nbhood,
      city,
      state,
      country,
      cpf,
    } = e.target.elements;
    if (isFilled(name.value)) {
      const dataForm = new FormData();

      const cpfOrCnpj = someNumberInString(cpf.value);
      const data = {
        User_id_contact: hasUser
          ? userIdContact(abare.id, personal) || personal.id
          : null,
        name: name.value,
        birthday: isFilled(birthday.value) ? birthday.value : null,
        lastName: isFilled(lastName.value) ? lastName.value : null,
        email: isFilled(email.value) ? email.value : null,
        phoneNumber: phone ? phone : null,
        relationship: isFilled(relationship.value) ? relationship.value : null,
        zipCode: isFilled(zipCode.value) ? zipCode.value : null,
        street: isFilled(street.value) ? street.value : null,
        nbhood: isFilled(nbhood.value) ? nbhood.value : null,
        city: isFilled(city.value) ? city.value : null,
        [isCpf ? "cpf" : "cnpj"]: cpfOrCnpj,
        state: isFilled(state.value) ? state.value : null,
        country: isFilled(country.value) ? country.value : null,
      };

      Object.entries(data).forEach(([key, value]) => {
        value && dataForm.append(key, value);
      });
      image?.file && dataForm.append("file", image?.file);

      setLoading(true);
      postContact(dataForm).then((res) => {
        if (res.status == 200) {
          addToast(ToastMessage("savedContact"), { appearance: "success" });
          if (hasUser) {
            const photoUrl = contact.photoUrl;
            addContact({ ...res.data.result, photoUrl });

            const nameUser = name.value + " " + lastName.value;
            updateArray(res.data.result, nameUser);

            setChat(personalId, {
              client: {
                ...contact,
                ...res.data.result,
                name: nameUser,
                isContact: true,
              },
            });
            onClose();
          } else {
            addContact({
              ...res.data.result,
              photoUrl: image?.newUrl ? image.newUrl : null,
            });
            onClose();
          }
        } else {
          setLoading(false);
          addToast(ToastMessage(res.status), { appearance: "error" });
        }
      });
    } else {
      addToast(
        {
          title: "Campo nome inválido!",
          description: "Digite um nome válido.",
        },
        { appearance: "warning" }
      );
    }
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = createUrl(file);
      file.size <= MAX_FILE_SIZE
        ? openModal({ mainUrl: url, file: file })
        : addToast(
            {
              title: "Imagem muito grande",
              description: "Tamanho máximo de 6mb",
            },
            {
              appearance: "error",
            }
          );
    }
  };

  const openModal = (obj?) => {
    obj && setImage({ ...image, ...obj });
    modalImage.current.open();
  };

  const crop = async () => {
    const data = await refCrop.current.cropImage();
    setImage({ ...image, ...data });
    modalImage.current.close();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Back>
        <IoIosClose id="icon" onClick={() => onClose()} />
        <h3> Cadastrar novo contato</h3>
      </Back>
      <Form onSubmit={createContact}>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInput}
          value={""}
          onChange={handleImage}
          accept="image/png, image/gif, image/jpeg"
        />

        {hasUser ? (
          <div className="center">
            <img id="profile" src={photoUrl(personal)} />
          </div>
        ) : (
          <div className="center">
            {image?.newUrl || image?.defaultUrl ? (
              <>
                <Default className="vertical-gradient">
                  <img
                    id="profile"
                    src={image.newUrl ? image.newUrl : image.defaultUrl}
                  />
                </Default>
                <div id="row">
                  <OutlineButton
                    type="button"
                    onClick={() => openModal({ mainUrl: image.defaultUrl })}
                  >
                    <FiCrop id="icon" />
                  </OutlineButton>
                  <OutlineButton type="button" onClick={() => setImage({})}>
                    <FiTrash id="icon" />
                  </OutlineButton>
                  <OutlineButton
                    type="button"
                    onClick={() => fileInput.current?.click()}
                  >
                    <BsImages id="icon" />
                  </OutlineButton>
                </div>
              </>
            ) : (
              <Default className="vertical-gradient">
                <FaUser size={40} />
                <BtnFile
                  type="button"
                  onClick={() => fileInput.current?.click()}
                >
                  <BsImages size={15} />
                </BtnFile>
              </Default>
            )}
          </div>
        )}

        <div id="fields">
          <MapFields fields={fields} />
        </div>
        <div className="center">
          <Button
            className="horizontal-gradient-login"
            type="submit"
            disabled={loading}
          >
            {!loading ? "Cadastrar" : <Load width="13px" />}
          </Button>
        </div>
      </Form>
      <Modal
        title="Recortar Foto"
        subTitle="Arraste para ajustar a imagem"
        ref={modalImage}
      >
        <CropImage height="280px" ref={refCrop} url={image?.mainUrl} />
        <div className="text-center">
          <Button type="button" onClick={() => crop()}>
            Ajustar Imagem
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  abare: state.abare.abare,
  personal: state.chat.personal,
  globalMessages: state.chat.globalMessages,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateClient,
      setChat,
      addContact,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
