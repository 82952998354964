import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  overflow-y: auto;
  overflow-x: hidden;

  .tabs-back {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;
  }

  h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: "inter-medium", sans-serif;
  }
  #name {
    color: ${(props) => props.theme.texts.darkerGrey};
    font-size: 19px;
  }

  #nick {
    color: ${(props) => props.theme.texts.darkGrey};
    font-size: 14px;
  }

  #img {
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding-inline: 0.5rem;
  }

  .search {
    color: red;
  }

  .searchPlataform {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  background-color: #faf1f6;
  margin: 0;
  padding: 1.11rem 1rem;
  font-size: 14px;

  h3 {
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  #icon {
    font-size: 16px;
    padding-right: 30px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }
`;

export const Button = styled.button`
  width: 100%;
  display: grid;
  grid-template-columns: 25px auto;
  padding: 1rem 0rem 0rem 0rem;
  margin: 0.1rem 0rem;
  align-items: center;
  font-size: 16px;
  color: ${(props) => (props.color ? props.theme.hover.green : props.theme.texts.darkerGreyII)};
  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGreyIII};
  }
`;

export const Item = styled.div`
  background-color: ${props => props.theme.backgrounds.white};
  color: ${(props) => props.theme.texts.purpleDark};
  font-family: "inter-medium", sans-serif;
  font-size: 16px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 0.7rem;

  .tabs {
    align-items: center;
    display: flex;
    flex-wrap: nowrap !important;
  }

  .tabs-hidden {
    display: none;
  }

  .tabs > div {
    margin: auto;
    margin-top: 0px;
    cursor: pointer;
    padding: 0px 5px 0px 5px;
  }

  .active {
    color: ${(props) => props.theme.texts.purpleDarkTitle};
    border-bottom: 2px solid ${props => props.theme.texts.purpleDarkTitle};
    padding: 0px 5px 10px 5px !important;
  }

  .inactive {
    color: ${props => props.theme.texts.darkerGrey};
  }
`;

export const HorizontalRowImg = styled.div`
  font-family: "inter", sans-serif;
  display: flex;
  align-items: center;
  padding: 10px 0px 5px 0px;
  cursor: pointer;
  margin-bottom: -10px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;

  #public {
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 14px;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Row = styled.div`
  font-family: "inter", sans-serif;
  font-size: 16px;
  color: ${props => props.theme.texts.darkerGreyII};
  padding-left: 16px;

  .line {
    margin-top: 5px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: 10px;
    color: ${(props) => props.theme.default.darkerI};
  }
`;

export const MainInput = styled.div`
  background-color: ${props => props.theme.backgrounds.white};
  padding-bottom: 20px;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.backgrounds.blueLight};
  border: 0.1px solid ${(props) => props.theme.texts.lightGreyII};
  border-radius: 6px;
  padding-inline: 0.5rem;
  margin: 0px 15px;

  #timer {
    margin: 0;
    font-size: 13px;
    color: ${(props) => props.theme.texts.darkGrey};
  }

  #icon {
    color: ${(props) => props.theme.texts.purpleDarkTitle};
    padding-inline: 0.2rem;
  }

  input,
  textarea {
    display: block;
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 100%;
    background: transparent;
    height: 45px;
    padding-right: 0.5rem;
    font-family: "inter", sans-serif;
    font-size: 13px;

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
      color: ${(props) => props.theme.texts.darkGrey};
      opacity: 1;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkGrey};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: none;
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGrey};
    }

    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGreyII};
    }

    &:focus {
      -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGreyII};
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  &:focus-within {
    color: ${(props) => props.theme.texts.darkerGreyII};
    -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGreyII};
    border: 0.1px solid ${(props) => props.theme.texts.darkerGreyII};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
    height: auto;
    padding-top: 0.7rem;
  }
`;

export const ContainerSearch = styled(ContainerInput)`
  margin-bottom: -10px;

  #icon {
    font-size: 12px;
    color: ${(props) => props.theme.texts.darkerGreyI};
  }
  input {
    border: none;
    outline: none !important;
    text-decoration: none;
    width: 95%;
    background: transparent;
    height: 35px;
    padding: 0px;
    padding-left: 5px;
    font-family: "inter", sans-serif;
    caret-color: ${(props) => props.theme.texts.purpleDark};
  }

  &:focus-within {
    color: ${(props) => props.theme.texts.darkerGreyII};
    -webkit-text-fill-color: ${(props) => props.theme.texts.darkerGreyII};
    border: 1px solid ${(props) => props.theme.texts.darkerGreyII};
    #icon {
      color: ${(props) => props.theme.texts.darkerGreyII};
    }
  }
`;
