import styled from "styled-components";

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgrounds.white};
  padding: 1rem;
  margin: 0px 0px 8px 0px;

  #row {
    display: flex;
    margin-top: 2px;
    justify-content: center;
    margin-bottom: 16px;
  }

  .line {
    margin-top: 8px;
    word-break: normal;
    font-family: "inter", sans-serif;
    font-size: ${(props) => props.theme.fonts.text_sm};
    color: ${(props) => props.theme.texts.darkGreyI};
  }
`;

export const Back = styled.div`
  position: relative;
  justify-content: center;
`;

export const TitlePerfil = styled.div`
  font-family: "inter-semi-bold", sans-serif;
  color: ${(props) => props.theme.texts.purpleDarkTitle};
  font-size: ${(props) => props.theme.fonts.text_lg};

  text-align: start;
  margin-bottom: 1rem;
`;
interface StyledProps {
  relative?: boolean;
  active?:boolean;
  padding ?: boolean;
}
export const ButtonEdit = styled.button<StyledProps>`
  display: flex; 
  ${(props) => !props.relative && ` position: absolute; `};
  ${(props) => props.padding && ` margin: 8px 8px 0px 0px; `};

  top: 0px;
  right: 0px;  
  background-color:${(props) => props.active ? props.theme.highlights.default : `#888888 `};
  color: ${(props) => (props.theme.title === "light" ? "white" : "#092232")};
  padding: 6px;
  border-radius: 100%;
  cursor: pointer;
  height: fit-content;

  :hover{
    background-color: ${(props) => props.theme.highlights.default};

  }
`;
