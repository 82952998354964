import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconChat = ({ color = "#666666", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <g clipPath="url(#clip0_5707_639)">
        <g clipPath="url(#clip1_5707_639)">
          <path
            d="M12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C10.0848 24.0026 8.19699 23.545 6.49561 22.6656L1.90441 23.946C1.64826 24.0175 1.37771 24.0196 1.12048 23.9521C0.863249 23.8846 0.628588 23.7499 0.440541 23.5619C0.252494 23.3738 0.117822 23.1392 0.0503226 22.8819C-0.0171767 22.6247 -0.0150758 22.3541 0.05641 22.098L1.33801 17.5104C0.456432 15.8075 -0.00248256 13.9175 1.01003e-05 12C1.01003e-05 5.3724 5.37241 0 12 0ZM12 1.8C9.2948 1.8 6.70039 2.87464 4.78752 4.78751C2.87465 6.70038 1.80001 9.29479 1.80001 12C1.80001 13.764 2.24761 15.4596 3.08761 16.9644L3.26761 17.2884L1.93321 22.0692L6.71761 20.7348L7.04161 20.9148C8.40287 21.6716 9.91937 22.1067 11.4748 22.1866C13.0303 22.2665 14.5834 21.9891 16.015 21.3757C17.4466 20.7623 18.7188 19.8292 19.7339 18.648C20.7491 17.4668 21.4802 16.0687 21.8713 14.5611C22.2624 13.0535 22.303 11.4764 21.99 9.95062C21.6771 8.42488 21.0189 6.99106 20.066 5.75912C19.113 4.52717 17.8906 3.52982 16.4924 2.84355C15.0943 2.15727 13.5575 1.8003 12 1.8ZM8.10001 13.2H13.4976C13.7256 13.2001 13.9451 13.2867 14.1118 13.4424C14.2784 13.598 14.3797 13.8112 14.3952 14.0387C14.4108 14.2661 14.3394 14.4911 14.1955 14.668C14.0516 14.8448 13.8459 14.9605 13.62 14.9916L13.4976 15H8.10001C7.87198 14.9999 7.65248 14.9133 7.48586 14.7576C7.31924 14.602 7.21792 14.3888 7.20238 14.1613C7.18683 13.9338 7.25823 13.7089 7.40213 13.532C7.54603 13.3551 7.75171 13.2395 7.97761 13.2084L8.10001 13.2ZM8.10001 9H15.906C16.1339 9.00037 16.3532 9.0872 16.5196 9.24297C16.686 9.39874 16.7871 9.61184 16.8025 9.83925C16.8179 10.0667 16.7464 10.2914 16.6025 10.4682C16.4586 10.6449 16.253 10.7605 16.0272 10.7916L15.906 10.8H8.10001C7.87198 10.7999 7.65248 10.7133 7.48586 10.5576C7.31924 10.402 7.21792 10.1888 7.20238 9.96134C7.18683 9.73384 7.25823 9.50893 7.40213 9.33204C7.54603 9.15515 7.75171 9.03948 7.97761 9.0084L8.10001 9Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5707_639">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_5707_639">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconChat;
