import styled from "styled-components";
import { mobile } from "../NavBar/style";

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  #animated {
    height: 100%;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);

  &:hover {
    cursor: pointer;
  }
`;

interface ModalProps {
  mobile?: boolean;
  $overflow?: boolean;
}

export const ModalBox = styled.div<ModalProps>`
  position: relative;
  top: 50%;
  left: ${({ mobile }) => (mobile ? "50%" : "49.5%")};
  transform: translate(-50%, -50%);
  max-width: 550px;
  overflow-y: auto;
  overflow: ${({ $overflow }) => ($overflow ? `initial ` : `auto`)};

  background-color: ${(props) => props.theme.backgrounds.white};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  border-radius: 6pt;

  @media only screen and (max-width: ${mobile}) {
    height: ${({ mobile }) => (mobile ? "100%" : "inherit")};
    border-radius: ${({ mobile }) => (mobile ? "0px" : "6pt")};
  }

  .text-center {
    padding-bottom: 1rem;
  }
`;

export const Header = styled.div`
  align-items: center;
  padding: 12px 0px 8px 8px;

  #top {
    display: flex;
    align-items: center;

    #back {
      color: ${(props) => props.theme.texts.purpleDark};
      font-size: 22px;
      margin-right: 8px;
      cursor: pointer;
      transition: ease 0.5s;

      &:hover {
        ${(props) => props.theme.hover.purpleDark};
      }
    }
    #data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;

      #title {
        font-size: 16px;
        color: ${(props) => props.theme.texts.purpleDark};
        margin: 0;
        padding: 0;
      }

      #subTitle {
        color: ${(props) => props.theme.texts.darkGrey};
        margin: 0;
        font-size: 13px;
        padding: 0;
      }
    }
  }
`;
