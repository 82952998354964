import { ContentImage, ContentText } from "pdfmake/interfaces";
import { useContext, useState } from "react";
import { TbGridDots } from "react-icons/tb";
import { InputSelect } from "../../../../../components";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { ReportStylesInterface } from "../../../../../types/project";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import { generateId } from "../../../../../util/verification";
import ImageOptions from "../FormContent/ImageOptions";
import TextOptions from "../FormContent/TextOptions";
import { ItemModal } from "../style";
import { ReportContext } from "../../../../../contexts/ReportContext";

interface ColumnsProps {
  modal: any;
}

const AddBodyModal = ({ modal }: ColumnsProps) => {
  const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
  const typeValues = [
    { value: "text", label: "Texto" },
    { value: "image", label: "Imagem" },
  ];

  const [typeSelect, setTypeSelect] = useState(typeValues[0].value);
  const [valueText, setValueText] = useState<ContentText>({
    text: "",
    alignment: "center",
    fontSize: 12,
    color: "#000",
    bold: false,
    italics: false,
    margin: [4, 4, 4, 4],
  });
  const [valueImage, setValueImage] = useState<ContentImage>({
    image: "",
    alignment: "center",
    fit: [50, 50],
    fontSize: 12,
    color: "#000",
    bold: false,
    italics: false,
    margin: [4, 4, 4, 4],
  });

  function saveChange() {
    const id = generateId();
    if (typeSelect === "text") {
      const objeto = { ...valueText, id };
      stylesPDF.body.push(objeto);
    } else {
      const objeto = { ...valueImage, id };
      stylesPDF.body.push(objeto);
    }
    stylesPDF.sortBody?.push(id);
    handleStylesPDF({ ...stylesPDF });
    modal.current.close();
  }
  return (
    <ModalContainer
      padding={false}
      onButtonRight={saveChange}
      buttonConfirmation={true}
      title="Adicionar item no corpo da página"
      onClick={() => modal.current.close()}
      buttonBack={true}
    >
      <ItemModal $noPadding={true} $borderBottom={true}>
        <div
          className="flex padding border"
          style={{ minWidth: "230px", flex: 1 }}
        >
          <div className="icon margin-right">
            <TbGridDots title="Tipo do item" size="20px" />
          </div>
          <InputSelect
            $flex={true}
            value={typeValues.find((option) => option.value === typeSelect)}
            onChange={(e) => {
              setTypeSelect(e.value);
            }}
            placeholder="Selecione a coluna que deseja modificar"
            options={typeValues}
          />
        </div>
        {typeSelect == "text" ? (
          <TextOptions valueText={valueText} setValueText={setValueText} />
        ) : (
          <ImageOptions valueImage={valueImage} setValueImage={setValueImage} />
        )}
      </ItemModal>
    </ModalContainer>
  );
};

export default AddBodyModal;
