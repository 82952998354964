import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ThemeContext } from "styled-components";
import { BiLockAlt, HiOutlineMail } from '../../../assets/icons';
import { Figlogin, FigloginDark, Logo } from '../../../assets/images';
import { InputLogin, InputPasswordLogin, Load } from '../../../components';
import { postRegister } from '../../../services/requests/user';
import { errorDefault, ToastModel } from '../../../util/error';
import Others from '../Others';
import { Button, Card, Container, Header } from '../style';


const Email = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { title } = useContext(ThemeContext);
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const invite = params.get('invite');
  const modalAuth = useRef<any>();
  const [loading, setLoading] = useState(false);
  const img = (title == "light" ? Figlogin : FigloginDark);

  const test = [
    { package: "IBE", email: "gabriel@gmail.com" },
    { package: "IBE", email: "sergio@gmail.com" },
    { package: "IBE", email: "junior@gmail.com" },
    { package: "IBE", email: "gabriel@gmail.com" },
    { package: "EPI", email: "francisco@gmail.com" },
    { package: "EPI", email: "maria@gmail.com" },
    { package: "EPI", email: "sergio.bezerra@ifam.edu.br" },
    { package: "PS", email: "123@gmail.com" },
    { package: "PS", email: "123321@gmail.com" },
  ]
  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const { email, password, confirmePassword } = event.target.elements;

    if (password.value == confirmePassword.value) {
      createUser(email.value, password.value);
    } else {
      addToast(
        {
          title: 'Senhas diferentes',
          description: 'Verifique seus dados e tente novamente!',
        },
        { appearance: 'warning' },
      );
      setLoading(false);
    }
  };

  const createUser = (email, password) => {
    postRegister(email, password, invite).then((res) => {
      const { status } = res;
      if (status) {
        if (status == 200) {
          addToast(
            {
              title: 'Primeira etapa do cadastro concluída',
              description: 'Verifique seu email para confirmar o cadastro',
            },
            { appearance: 'info' },
          );

          history.push('/');
        } else if (status == 404) {
          ToastModel(addToast, "Email não encontrado",
            "Plataforma em teste. Você precisa de autorização para se registrar!",
            'error'
          )
        } else {
          errorRegistrer(status);
        }
      } else {
        addToast(
          {
            title: 'Erro de conexão!',
            description: 'Tente novamente mais tarde',
          },
          { appearance: 'error' },
        );
      }
      setLoading(false);
    });
  };

  const errorRegistrer = (status) => {
    switch (status) {
      case 400:
        addToast(
          {
            title: 'Email já está cadastrado',
            description: 'Informe outro endereço ou conecte-se à sua conta',
          },
          { appearance: 'info' },
        );
        break;
      default:
        errorDefault(status, addToast);
    }
  };
  return (
    <Container className="vertical-gradient">
      <img className="fig" src={img} />
      <Card>
        <Header className="vertical-gradient">
          <img id="logo" src={Logo} alt="Logo Abaré.Net" />
        </Header>
        <h1 id="title">Cadastre-se</h1>
        <h4>Preencha os campos abaixo para realizar seu cadastro</h4>
        <form onSubmit={onSubmit} autoComplete="off">
          <InputLogin
            Icon={HiOutlineMail}
            name="email"
            label="Endereço de e-mail"
            type="text"
            required
            placeholder="Digite seu e-mail aqui"
            autocomplete="off"
          />
          <InputPasswordLogin
            Icon={BiLockAlt}
            name="password"
            label="Senha"
            type="password"
            required
            placeholder="Digite sua senha aqui"
            autocomplete="off"

          />
          <InputPasswordLogin
            Icon={BiLockAlt}
            name="confirmePassword"
            label="Confirmar senha"
            type="password"
            required
            placeholder="Digite sua senha aqui"
            autocomplete="off"
          />

          <div className="text-center">
            <Button type="submit" className="horizontal-gradient-login">
              {loading
                ? <Load />
                : 'Cadastrar'}
            </Button>
            <p>
              Já tem conta?
              {' '}
              <Link id="link" to="/login">Entre agora</Link>
            </p>
            {/*  <p id="alternative">
              Cadastre-se com
              {' '}
              <a id="link" onClick={() => modalAuth.current.open()}>
                autenticações alternativas
              </a>
            </p> */}
          </div>
        </form>
      </Card>
      <Others modalAuth={modalAuth} email />
    </Container>
  );
};

export default Email;
