import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  #animated {
    height: 100%;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);

  &:hover {
    cursor: pointer;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  top: 50%;
  left: 49.5%;
  transform: translate(-50%, -50%);
  max-width: 550px;
  height: auto;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 101;
  border-radius: 6pt;

  .text-center{
    padding-bottom: 1rem;
  }
`;

export const Header = styled.div`
  align-items: center;
  padding: 1rem;
  padding-bottom: 0;
  #top {
    display: flex;
    align-items: center;
    #back {
      color: ${(props) => props.theme.texts.purpleDark};
      font-size: 25px;
      cursor: pointer;
      transition: ease 0.5s;
      &:hover{
        ${(props) => props.theme.purple.darker};
      }
    }
    #data{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;
      #title {
        font-size: 18px;
        color: ${(props) => props.theme.texts.purpleDark};
        margin: 0;
        padding: 0;
      }
      #subTitle {
        color: ${(props) => props.theme.default.darker};
        margin: 0;
        font-size: 14px;
        padding: 0;
      }
    }
  }
`;