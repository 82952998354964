import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { getBlockRegister } from "../../../services/requests/user-register";
import { Body, ContainerBlocked, HeaderBlock } from "./style";

interface iUserRegister {
    User_id: string;
    createdAt: string
    description: string
    fileName: string | null
    fileURL: string | null
    finishedAt: string | null
    id: string
}
const BlockPlataform = ({ modalBlock, token }) => {
    const [userRegister, setUserRegister] = useState<iUserRegister | null>(null)
    useEffect(() => {
        getBlockRegister(token).then((res) => {
            if (res.status == 200) {
                setUserRegister(res.data)
            }
        })
    }, [])
    return (
        <ContainerBlocked>
            <HeaderBlock>
                <IoIosArrowBack id="back" onClick={() => modalBlock.current.close()} />
                <h5>Usuário não autorizado</h5>
            </HeaderBlock>
            <Body>
                <p id="bold">Você foi bloqueado. Motivo:</p>
                <p>{userRegister?.description}</p>
                <p>Caso haja necessidade para resolver um problema, você pode abrir um protocolo <a href={"/blocked/" + token}>neste link.</a></p>
            </Body>

        </ContainerBlocked>
    )
}

export default BlockPlataform;