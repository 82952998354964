import usePersistedState from "../../util/usePersistedState";
import {
  SET_ABARE,
  SET_CONFIG,
  SET_NAVBAR,
  SET_NAVBAR_STATE,
  SET_THEME,
} from "../actions/actionTypes";

const initialState = {
  abare: {},
  theme: usePersistedState(),
  navBar: true,
  navBarMinimized: window.innerWidth < 1580,
  config: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NAVBAR:
      return {
        ...state,
        navBar: action.payload,
      };
    case SET_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case SET_ABARE:
      return {
        ...state,
        abare: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_NAVBAR_STATE:
      return {
        ...state,
        navBarMinimized: action.payload,
      };
    default:
      return { ...state };
  }
}
