import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { HiOutlineMail } from '../../assets/icons';
import { postResetPassword } from "../../services/requests/user";
import { errorDefault, ToastModel } from "../../util/error";
import { InputLogin, Modal } from "../index";
import { Button, Container } from "./style";


const Forgot = (props) => {
    const { addToast } = useToasts();
    const { modalForgot } = props;
    const [load, setLoad] = useState(false);

    const submit = (event) => {
        event.preventDefault();
        const { email } = event.target.elements;
        setLoad(true);
        postResetPassword(email.value).then((res) => {
            const status = res.status
            if (status) {
                switch (status) {
                    case 201:
                        addToast({
                            title: 'Email enviado!',
                            description: "Verifique sua caixa de email para mudar sua senha."
                        },
                            { appearance: 'success' });
                        break;
                    case 404:
                        ToastModel(addToast, res.data.message.pt, "Por favor digite seu email!", "error")
                        break;
                    default:
                        errorDefault(status, addToast)
                }
            } else {
                ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
            }
            setLoad(false);
        });
    }

    return (
        <Modal ref={modalForgot}>
            <Container>
                <h3>Recuperar senha</h3>
                <legend>Uma mensagem de confirmação será enviada para seu e-mail.</legend>
                <form onSubmit={submit} >
                    <InputLogin isButton={true} Icon={HiOutlineMail} loading={load} name="email" label="E-mail"
                        type="email" required placeholder="Digite seu e-mail aqui" />
                </form>
                <div className="text-center">
                    <Button onClick={() => modalForgot.current.close()}  className="horizontal-gradient-login">Voltar</Button>
                </div>
            </Container>
        </Modal>
    );


}

export default Forgot;