import styled from "styled-components";

export const Back = styled.div`
  display: flex;
  align-items: center;
  background: transparent;
  margin: 0;
  padding: 1.11rem 1rem;
  font-size: 14px;

  h3 {
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .especial-title {

  }

  #icon {
    font-size: 16px;
    padding-right: 30px;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
  }
`;

export const Container = styled.div`

  background: rgba(112, 5, 64, 0.05);
  margin-top: -15px;

  .img-container {
    height: auto;
    background-color: ${props => props.theme.backgrounds.white};
  }

`;