import IconChatFill from "../../../../../../assets/icons/IconChatFill";
import { ContainerDailyMessages } from "./style";

export function CardDailyMessages() {
  return (
    <ContainerDailyMessages>
      <header>Mensagens diárias</header>
      <main>
        <p className="message-content">
          <span className="realce-content">@ 7</span>mencionaram você
        </p>
        <div className="container-messages">
          <div className="information-content">
            <span className="content">Recebidas</span>
            <span className="realce receive-realce">15</span>
          </div>
          <div className="line" ></div>
          <div className="information-content">
            <span className="content">Não lidas</span>
            <span className="realce not-read-realce">10</span>
          </div>
        </div>
        <IconChatFill className="float-icon" color="#ABDC6D" size={125} />
      </main>
    </ContainerDailyMessages>
  );
}
