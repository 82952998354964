import { api } from "../api";
import { getToken } from "../auth";

export const 

postProtocol = (data, token) => {
  return api({
    url: `/protocol/`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + (getToken() || token),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getProtocolCategory = (token) => {
  return api({
    url: `/protocol-category`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + (getToken() || token),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getProtocols = (token) => {
  return api({
    url: `/protocol`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + (getToken() || token),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const getProtocol = (protocolId) => {
  return api({
    url: `/protocol/${protocolId}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};