import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconStorage = ({ color = "#666666", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_5711_403)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.782666 23.216C1.30533 23.7387 1.93333 24 2.66667 24H21.3333C22.0658 24.0009 22.6933 23.74 23.216 23.2173C23.7387 22.6947 24 22.0667 24 21.3333V4.7C24.0009 4.38889 23.9511 4.08889 23.8507 3.8C23.7502 3.51112 23.6 3.24445 23.4 3.00001L21.7333 0.966673C21.4898 0.656451 21.1844 0.417785 20.8173 0.250674C20.4502 0.0835625 20.0667 7.05467e-06 19.6667 7.05467e-06H4.33333C3.93244 -0.000881834 3.54889 0.0822293 3.18267 0.24934C2.81644 0.416451 2.51111 0.655562 2.26667 0.966673L0.6 3.00001C0.400889 3.24445 0.251111 3.51112 0.150666 3.8C0.0502221 4.08889 0 4.38889 0 4.7V21.3333C-0.000888888 22.0658 0.26 22.6933 0.782666 23.216ZM2.3737 4.5C2.21396 4.5 2.11869 4.32197 2.20729 4.18906L3.5547 2.16795C3.8329 1.75065 4.30125 1.5 4.80278 1.5H12H19.1972C19.6988 1.5 20.1671 1.75065 20.4453 2.16795L21.7927 4.18906C21.8813 4.32197 21.786 4.5 21.6263 4.5H2.3737ZM22.5 20.5C22.5 21.6046 21.6046 22.5 20.5 22.5H3.5C2.39543 22.5 1.5 21.6046 1.5 20.5V7C1.5 6.44772 1.94772 6 2.5 6H21.5C22.0523 6 22.5 6.44772 22.5 7L22.5 20.5Z"
          fill={color}
        />
        <path
          d="M10.816 9.384C11.072 9.128 11.3889 9 11.7667 9C12.1436 8.99912 12.46 9.12667 12.716 9.38267C12.972 9.63867 13.1 9.95556 13.1 10.3333V14.3586C13.1 14.4477 13.2077 14.4923 13.2707 14.4293L14.3 13.4C14.5444 13.1556 14.8556 13.0333 15.2333 13.0333C15.6111 13.0333 15.9222 13.1556 16.1667 13.4C16.4111 13.6444 16.5333 13.9556 16.5333 14.3333C16.5333 14.7111 16.4111 15.0222 16.1667 15.2667L12.7 18.7333C12.4333 19 12.1222 19.1333 11.7667 19.1333C11.4111 19.1333 11.1 19 10.8333 18.7333L7.36667 15.2667C7.12222 15.0222 7 14.7111 7 14.3333C7 13.9556 7.12222 13.6444 7.36667 13.4C7.61111 13.1556 7.92222 13.0333 8.3 13.0333C8.67778 13.0333 8.98889 13.1556 9.23333 13.4L10.2626 14.4293C10.3256 14.4923 10.4333 14.4477 10.4333 14.3586V10.3333C10.4324 9.95645 10.56 9.64 10.816 9.384Z"
          stroke={color}
          strokeWidth="1.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_5711_403">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconStorage;
