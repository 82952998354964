import { EnumTypeRepository } from "../../types/redux";
import {
  ADD_EVENT,
  ADD_VISIBLE_CATEGORY,
  REMOVE_EVENT,
  REMOVE_VISIBLE_CATEGORY,
  SET_COMMITMENT,
  SET_CREATE_CATEGORY,
  SET_DATE_SELECT_SCHEDULE,
  SET_FILTER_SCHEDULE,
  SET_INVITATION,
  SET_SELECT_REPOSITORY,
  UPDATE_EVENT,
} from "./actionTypes";

export const setInvitation = (invitation) => {
  return {
    type: SET_INVITATION,
    payload: invitation,
  };
};

export const setCommitment = (commitment) => {
  return {
    type: SET_COMMITMENT,
    payload: commitment,
  };
};

export const setFilter = (filter) => {
  return {
    type: SET_FILTER_SCHEDULE,
    payload: filter,
  };
};

export const addEventSchedule = (event) => {
  return {
    type: ADD_EVENT,
    payload: event,
  };
};

export const removeEventSchedule = (event) => {
  return {
    type: REMOVE_EVENT,
    payload: event,
  };
};
export const updateEventSchedule = (event) => {
  return {
    type: UPDATE_EVENT,
    payload: event,
  };
};

export const setDateSelect = (dateSelect: Date) => {
  return {
    type: SET_DATE_SELECT_SCHEDULE,
    payload: dateSelect,
  };
};

export const addVisibleCategory = (dateSelect: number) => {
  return {
    type: ADD_VISIBLE_CATEGORY,
    payload: dateSelect,
  };
};

export const removeVisibleCategory = (dateSelect: any) => {
  return {
    type: REMOVE_VISIBLE_CATEGORY,
    payload: dateSelect,
  };
};

export const setCreatedCategory = (categories: number[]) => {
  return {
    type: SET_CREATE_CATEGORY,
    payload: categories,
  };
};

export const setVisibleCategory = (dateSelect: any) => {
  return {
    type: REMOVE_VISIBLE_CATEGORY,
    payload: dateSelect,
  };
};

export const setTypeRepository = (type: EnumTypeRepository) => {
  return {
    type: SET_SELECT_REPOSITORY,
    payload: type,
  };
};
