import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AiFillCheckCircle, BsPersonDash, BsPersonPlus, BsSearch, IoIosArrowBack } from '../../../../../../assets/icons';
import { InputSearch, NotFound, Toggle } from '../../../../../../components';
import { ButtonArrow } from '../../../../../../components/Button/style';
import { Checked } from '../../../../../../components/Checked';
import { ShimmerCard } from '../../../../../../components/Shimmers';
import { deletePeoplePermissionEvent, getUsersPermissions, getUsersWithoutPermissions, patchPermissionsUserEvent, postPeoplePermissionEvent } from '../../../../../../services/requests/event-group-permission';
import { TEvent } from '../../../../../../types/project';
import { ToastModel } from '../../../../../../util/error';
import { nameFormat, photoUrl } from '../../../../../../util/verification';
import { Container, Header, Menu, User } from './style';

type TProps = {
    modal: any,
    event: TEvent,
}

const PermissionEvent = (props: TProps) => {
    const { modal, event } = props;
    const { addToast } = useToasts();
    const [menu, setMenu] = useState(1);
    const [req, setReq] = useState(false);

    const [userEdit, setUserEdit] = useState<any>(null);
    const [usersPermissions, setUsersPermissions] = useState<any[]>([]);
    const [usersWithoutPermissions, setUsersWhithoutPermissions] = useState<any[]>([]);

    const checkedWithPermission = usersPermissions.filter(user => (user.checked)).length > 0 ? true : false
    const checkedWithoutPermission = usersWithoutPermissions.filter(user => (user.checked)).length > 0 ? true : false

    useEffect(() => {
        getUsersPermissions(event.id, event.Group_id).then((res) => {
            if (res.status == 200) {
                const participants = isCheckedPresence(res.data.result);
                setUsersPermissions(participants);
                setReq(true)
            } else if (res.status == 401) {
                ToastModel(addToast, 'Usuário não autorizado!', "Somente o criador do evento pode realizar essa ação.", "error");
            }
        })
        getUsersWithoutPermissions(event.id, event.Group_id).then((res) => {
            if (res.status == 200) {
                const participants = isCheckedPresence(res.data.result);
                setUsersWhithoutPermissions(participants);

                setReq(true)
            } else if (res.status == 401) {
                ToastModel(addToast, 'Usuário não autorizado!', "Somente o criador do evento pode realizar essa ação.", "error");
            }
        })
    }, [])

    function isCheckedPresence(participants) {
        participants.forEach(user => {
            user.checked = Boolean(false);
            user.hidden = false;
        });
        return participants;
    }

    function addPeoplePermissions() {
        let usersAdd: Array<any> = [];
        usersWithoutPermissions.forEach(user => (user.checked && usersAdd.push(user.id)));

        postPeoplePermissionEvent(event.id, event.Group_id, usersAdd).then((res) => {
            if (res.status == 200) {
                addToast(
                    { title: "Permissões atualizadas!" },
                    { appearance: 'success' });
                usersWithoutPermissions.forEach(user => (user.checked &&
                    setUsersPermissions(users => ([...users, { ...user, checked: false,edit: true, delete: true,presence: true }]))));
                setUsersWhithoutPermissions(usersWithoutPermissions.filter((user) => !user.checked))
            } else if (res.status == 401) {
                ToastModel(addToast, 'Usuário não autorizado!', "Somente o criador do evento pode realizar essa ação.", "error");
            }
            modal.current.close()
        });

    }

    function deletePeoplePermissions() {
        let usersRemove: Array<any> = [];
        usersPermissions.forEach(user => (user.checked && usersRemove.push(user.id)));

        deletePeoplePermissionEvent(event.id, event.Group_id, usersRemove).then((res) => {
            if (res.status == 200) {
                addToast(
                    { title: "Permissões atualizadas!" },
                    { appearance: 'success' });

                usersPermissions.forEach(user => (user.checked &&
                    setUsersWhithoutPermissions(users => ([...users, { ...user, checked: false }]))));
                setUsersPermissions(usersPermissions.filter((user) => !user.checked))
            } else if (res.status == 401) {
                ToastModel(addToast, 'Usuário não autorizado!', "Somente o criador do evento pode realizar essa ação.", "error");
            }
            modal.current.close()

        });

    }

    function updatePermissionUser(remove, edit, presence) {
        const data = {
            edit: edit,
            delete: remove,
            presence: presence,
        }
        patchPermissionsUserEvent(event.id, event.Group_id, userEdit.id, data).then((res) => {
            if (res.status == 200) {
                addToast(
                    { title: "Permissões atualizadas!" },
                    { appearance: 'success' });
                setUsersPermissions(users => {
                    const index = users.indexOf(userEdit)
                    users[index] = { ...users[index], ...data }
                    return [...users];
                })
            } else if (res.status == 401) {
                ToastModel(addToast, 'Usuário não autorizado!', "Somente o criador do evento pode realizar essa ação.", "error");
            }
            modal.current.close()

        });

    }

    const Permissions = () => {
        const ListPeopleWithPermissionEdit = () => (
            <>
                {
                    usersPermissions.length > 0 ?
                        <div>
                            {usersPermissions.map((user) =>
                            (<User key={user.id} onClick={() => { setUserEdit(user), setMenu(4) }}>
                                <img alt="Perfil" src={photoUrl(user)} />
                                <h4 className="name">{user.name ? user.name : "@" + user.nickname}</h4>
                            </User>))}
                        </div>
                        : 
                        <NotFound description="Não há ninguém com permissões" />
                }
            </>
        );
        return (
            <Container>
                <Header>
                    <div>
                        <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                        <h5>Permissões</h5>
                    </div>
                    <div>
                        <div className="icon-circle" onClick={() => setMenu(2)}>
                            <BsPersonPlus id="icon" />
                        </div>
                        <div className="icon-circle" onClick={() => setMenu(3)}>
                            <BsPersonDash id="icon" />
                        </div>
                    </div>

                </Header>
                <div className='scroll'>
                    {req ? <ListPeopleWithPermissionEdit />
                        : <div>
                            <ShimmerCard height={"50px"} />
                            <ShimmerCard height={"50px"} />
                            <ShimmerCard height={"50px"} />
                        </div>}
                </div>
            </Container>
        )
    }

    const AddPerson = () => {
        const [search, setSearch] = useState<string>('');
        const ListPeopleWithoutPermission = () => {
            const filter =usersWithoutPermissions && usersWithoutPermissions.filter(user =>{ 
                const name = user.name ? user.name : user.nickname;
                
                return name.toLowerCase().includes(search.toLowerCase())})   
            return <>
                {
                    usersWithoutPermissions.length > 0 ?
                        filter.length > 0 ?
                            <div><Checked users={filter} setUsers={setUsersWhithoutPermissions} /></div>
                            : <NotFound description="Não há ninguém correspondente à pesquisa" />
                        :
                        <NotFound description="Não há usuário para dar permissão" />
                }
            </>
        };

        return (
            <Container three={true}>
                <Header> 
                    <div>
                        <IoIosArrowBack id="back" onClick={() => setMenu(1)} />
                        <h5>Permissões - Adicionar</h5>
                    </div>
                </Header>
                <Menu>
                    <h4>Membros</h4>
                    <InputSearch Icon={BsSearch} value={search} onChange={(e) => setSearch(e.target.value)} name="search" type="text" required placeholder="Pesquisar" />
                    <ButtonArrow onClick={() => { addPeoplePermissions() }} >
                        <AiFillCheckCircle id={!checkedWithoutPermission ? "icon" : "icon-purple"} />
                    </ButtonArrow>
                </Menu>
                <div className='scroll'>
                    {req ? <ListPeopleWithoutPermission />
                        :
                        <div>
                            <ShimmerCard height={"75px"} />
                            <ShimmerCard height={"75px"} />
                        </div>}
                </div>
            </Container>
        )
    }

    const RemovePerson = () => {
        const [search, setSearch] = useState<string>('');

        const ListPeopleWithPermission = () => {
            const filter = usersPermissions.filter(user =>{ 
                const name = user.name ? user.name : user.nickname;

                return name.toLowerCase().includes(search.toLowerCase())})
            return <>
                {
                    usersPermissions.length > 0 ?
                        filter.length > 0 ?
                            <div><Checked users={filter} setUsers={setUsersPermissions} /></div>
                            : <NotFound description="Não há ninguém correspondente à pesquisa" />
                        :
                        <NotFound description="Não há ninguém com permissões para remover" />
                }
            </>
        };

        return (
            <Container three={true}>
                <Header>
                    <div>
                        <IoIosArrowBack id="back" onClick={() => setMenu(1)} />
                        <h5>Permissões - Remover</h5>
                    </div>
                </Header>
                <Menu>
                    <h4>Membros</h4>
                    <InputSearch Icon={BsSearch} value={search} onChange={(e) => setSearch(e.target.value)} name="search" type="text" required placeholder="Pesquisar" />
                    <ButtonArrow onClick={() => { deletePeoplePermissions() }} >
                        <AiFillCheckCircle id={!checkedWithPermission ? "icon" : "icon-purple"} />
                    </ButtonArrow>
                </Menu>
                <div className='scroll'>
                    {req ? <ListPeopleWithPermission />
                        : <ShimmerCard height={"75px"} />}
                </div>
            </Container>
        )
    }

    const EditPerson = () => {
        const [isDelete, setIsDelete] = useState<boolean>(userEdit.delete);
        const [isEdit, setIsEdit] = useState<boolean>(userEdit.edit);
        const [isPresence, setIsPresence] = useState<boolean>(userEdit.presence);
 
        return (
            <Container edit={true}>
                <Header>
                    <div>
                        <IoIosArrowBack id="back" onClick={() => { setUserEdit(null), setMenu(1) }} />
                        <h5>Permissões - Editar</h5>
                    </div>
                    <ButtonArrow onClick={() => { updatePermissionUser(isDelete, isEdit, isPresence) }} >
                        <AiFillCheckCircle id="icon-purple" />
                    </ButtonArrow>
                </Header>
                <div className='scroll edit'>
                    <div className='perfil'>
                        <img id="profile" src={photoUrl(userEdit)} />
                        <h3 id="name">{nameFormat(userEdit.name)}</h3>
                    </div>
                    <div className='permissions'>
                        <div className='item'>
                            <p>Editar</p>
                            <Toggle value={isEdit} onChange={() => setIsEdit(!isEdit)} />
                        </div>
                        <div className='item'>
                            <p>Excluir</p>
                            <Toggle value={isDelete} onChange={() => setIsDelete(!isDelete)} />
                        </div>
                        <div className='item'>
                            <p>Presença</p>
                            <Toggle value={isPresence} onChange={() => setIsPresence(!isPresence)} />
                        </div>
                    </div>
                </div>
            </Container>
        )
    }

    const HandleMenu = () => {
        switch (menu) {
            case 1: {
                return <Permissions />
            }
            case 2: {
                return <AddPerson />
            }
            case 3: {
                return <RemovePerson />
            }
            case 4: {
                return <EditPerson />
            }
            default: {
                return <Permissions />
            }
        }

    }
    return <HandleMenu />
}

export default PermissionEvent;