import { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  BsCheckLg,
  BsImages,
  FaUsers,
  FiCrop,
  FiTrash,
  MdModeEdit,
} from "../../../../../../assets/icons";
import { CropImage, Modal } from "../../../../../../components";
import { Button } from "../../../../../../components/Button/style";
import { patchPhoto } from "../../../../../../services/requests/user";
import { setAbare } from "../../../../../../store/actions/abareAction";
import { TypeImage } from "../../../../../../types/project";
import { createUrl } from "../../../../../../util/verification";
import { OutlineButton } from "../../../../Conversations/Group/Edit/style";
import {
  Back,
  ButtonEdit,
  Item,
  TitlePerfil,
} from "../../../Components/ItemContainer/style";
import { ButtonFile, ContentImage } from "./style";
import Img from "../../../../../../components/Img";

const EditImage = (props) => {
  const { user, editPhoto } = props;
  const [imageState, setImageState] = useState(false);
  const [image, setImage] = useState<TypeImage>({ defaultUrl: user.photoUrl });
  const fileInput = useRef<HTMLInputElement>(null);
  const modalImage = useRef<any>();
  const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || "";
  const refCrop = useRef<any>(null);
  const { addToast } = useToasts();

  const openModal = (obj?) => {
    obj && setImage({ ...image, ...obj });
    modalImage.current.open();
  };

  const handleImage = (e) => {
    !imageState && setImageState(!imageState);
    const file = e.target.files[0];
    if (file) {
      const url = createUrl(file);
      file.size <= MAX_FILE_SIZE
        ? openModal({ mainUrl: url, file: file })
        : addToast(
            {
              title: "Imagem muito grande",
              description: "Tamanho máximo de 6mb",
            },
            {
              appearance: "error",
            }
          );
    }
  };

  const crop = async () => {
    const data = await refCrop.current.cropImage();
    console.log(data);
    setImage((image) => {
      return { ...image, ...data };
    });
    modalImage.current.close();
  };

  const editImage = (event) => {
    event.preventDefault();
    setImageState(!imageState);

    if (
      image.newUrl != undefined &&
      user.photoUrl != image.newUrl &&
      imageState
    ) {
      const isDeleteImage = Object.keys(image).length === 0;

      let dataForm = new FormData();

      dataForm.append("file", image.file);

      if (image.file || isDeleteImage) {
        dataForm.append("changePhoto", "true");
      }

      editPhoto(dataForm, isDeleteImage, image);
    }
  };
  return (
    <Fragment>
      <form onSubmit={editImage}>
        <Item>
          {image?.newUrl || image?.defaultUrl ? (
            <Fragment>
              <Back>
                <TitlePerfil>Foto do Perfil</TitlePerfil>
                <ButtonEdit active={imageState} type="submit">
                  {imageState ? (
                    <BsCheckLg size={18} />
                  ) : (
                    <MdModeEdit size={18} />
                  )}
                </ButtonEdit>
              </Back>
              <ContentImage className="vertical-gradient">
                <Img id="profile" src={image.newUrl ?? image.defaultUrl} />
              </ContentImage>
              {imageState && (
                <div id="row">
                  <OutlineButton
                    type="button"
                    onClick={() => openModal({ mainUrl: image.defaultUrl })}
                  >
                    <FiCrop id="icon" />
                  </OutlineButton>
                  <OutlineButton type="button" onClick={() => setImage({})}>
                    <FiTrash id="icon" />
                  </OutlineButton>
                  <OutlineButton
                    type="button"
                    onClick={() => fileInput.current?.click()}
                  >
                    <BsImages id="icon" />
                  </OutlineButton>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Back>
                <TitlePerfil>Foto do Perfil</TitlePerfil>
                <ButtonEdit
                  active={imageState}
                  disabled={!imageState}
                  type="submit"
                >
                  {imageState ? (
                    <BsCheckLg size={18} />
                  ) : (
                    <MdModeEdit size={18} />
                  )}
                </ButtonEdit>
              </Back>
              <ContentImage className="vertical-gradient">
                <FaUsers size="60px" />
                <ButtonFile onClick={() => fileInput.current?.click()}>
                  <BsImages size={25} />
                </ButtonFile>
              </ContentImage>
            </Fragment>
          )}
        </Item>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInput}
          value={""}
          onChange={handleImage}
          accept="image/png, image/gif, image/jpeg"
        />
        <Modal
          title="Recortar Foto"
          subTitle="Arraste para ajustar a imagem"
          ref={modalImage}
        >
          <CropImage height="280px" ref={refCrop} url={image?.mainUrl} />
          <div className="text-center">
            <Button
              className="horizontal-gradient-login"
              type="button"
              onClick={() => crop()}
            >
              Ajustar Imagem
            </Button>
          </div>
        </Modal>
      </form>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    toChangeAbare(newAbare) {
      dispatch(setAbare(newAbare));
    },
  };
};

export default connect(null, mapDispatchToProps)(EditImage);
