import { FaList } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineCalendarViewDay, MdOutlineCalendarViewMonth, MdOutlineCalendarViewWeek } from "react-icons/md";
import { InputSelect } from "../../../../../components";
import { ButtonEvent, Toolbar } from "../style";

const CustomToolbar = ({ label, onNavigate, onView, setView, view }) => {

  const options = [
    { value: "month", label: <div className="option"><MdOutlineCalendarViewMonth /> Mês</div> },
    { value: "week", label: <div className="option"><MdOutlineCalendarViewWeek /> Semana</div> },
    { value: "day", label: <div className="option"><MdOutlineCalendarViewDay /> Dia</div> },
    { value: "agenda", label: <div className="option"><FaList /> Agenda</div> },
  ]
  const handlePrev = () => {
    onNavigate('PREV');
  };

  const handleNext = () => {
    onNavigate('NEXT');
  };

  const handleToday = () => {
    onNavigate('TODAY');
  };

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <Toolbar>

      <InputSelect
        calendar={true}
        name="inputCategory"
        id="category"
        style={true}
        options={options}
        value={options.find((option) => option.value == view)}
        onChange={(e) => handleViewChange(e.value)}
      />
      <div className="toolbar-label">
        <IoIosArrowBack onClick={handlePrev} />
        <p>{label}</p>
        <IoIosArrowBack onClick={handleNext} className="right" />

      </div>
      <div className="toolbar-buttons">
        <ButtonEvent
          onClick={handleToday}>
          {/* <IoIosToday id="icon" size={32} /> */}
          Hoje
        </ButtonEvent>
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;
