import { useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { Dropdown } from "..";
import { FaMapMarkedAlt, FaTrash, FiMoreHorizontal, IoIosArrowUp, IoIosCheckmark, IoIosClose, MdModeEdit, MdPersonAddAlt1, MdPersonRemoveAlt1 } from '../../assets/icons';
import { AddItem, DeleteFolder, RemoveItem } from "../../pages/home/Conversations/Folder/pages";
import { putCluster } from "../../services/requests/cluster";
import { changeRowFolder, setListAtiva, setListFolder } from "../../store/actions/folderAction";
import Modal from "../Modal";
import { Children, Container, RenameInput, Title } from "./style";

const Accordion = (props) => {
    const { folder, children, folders, setListFolder, abare, setListAtiva, changeRowFolder, last } = props;
    const isClose = folder.isClose;
    /* const [animation, setAnimation] = useState(false); */
    const modalDelete = useRef<any>();
    const modalAdd = useRef<any>();
    const modalRemoveItem = useRef<any>();
    const [rename, setRename] = useState(false);
    const [nameFolder, setNameFolder] = useState(folder.name);
    const { addToast } = useToasts();
    const { push } = useHistory()

    const updateFoder = () => {
        putCluster(folder.id, nameFolder).then((res) => {
            if (res.status == 200) {
                setRename(false);
                addToast({
                    title: "Pasta renomeada!",
                    description: "A pasta '" + folder.name + "' foi renomeada para " + "'" + nameFolder + "'",

                },
                    { appearance: 'success' });

                folder.name = nameFolder
                const index = folders.findIndex(x => x.id === folder.id)
                folders.splice(index, 1, folder)
                setListFolder([...folders]);

            }
        });
    }

    return (
        <Container >

            {!rename ?

                <Title last>
                    <div className="row" onClick={() => (/* setOpen(!isClose), */ changeRowFolder(folder.id, !isClose) /* setAnimation(true) */)}>
                        <IoIosArrowUp size={18} className={isClose ? "down" : "up"} />
                        {nameFolder}
                    </div>
                    {!(folder.id == abare.id) &&
                        <Dropdown defaultClass="bottom-left">
                            <FiMoreHorizontal className="icon" id="menu" />
                            <div>
                                <h3 className="title">Pasta</h3>
                                {/* <li> <AiFillPushpin className="icon" /> Fixar</li> */}
                                {abare.packageInfo?.map && <li onClick={() => { push('/home/conversations/maps'), setListAtiva(folder) }}> <FaMapMarkedAlt className="icon" size={18} />Mapa</li>}
                                <li onClick={() => setRename(true)} > <MdModeEdit className="icon" size={18} /> Renomear</li>
                                <li onClick={() => modalDelete.current.open()}> <FaTrash className="icon" size={14} /> Excluir</li>

                                <h3 className="title">Conversa</h3>
                                <li onClick={() => modalAdd.current.open()}><MdPersonAddAlt1 className="icon" size={18} />Incluir</li>
                                <li onClick={() => modalRemoveItem.current.open()}><MdPersonRemoveAlt1 className="icon" size={18} />Remover</li>
                            </div>
                        </Dropdown>
                    }
                </Title>
                :
                <RenameInput >
                    <input autoComplete="off" maxLength={30} value={nameFolder} onChange={e => setNameFolder(e.target.value)}
                        onKeyPress={e => e.key === "Enter" ? updateFoder() : null}
                        type="text" autoFocus onFocus={(e) => e.target.select()} placeholder="Nome da pasta" />
                    <button className="sub" onClick={() => updateFoder()} ><IoIosCheckmark id="icon" /></button>
                    <button onClick={() => setRename(false)} className="sub" > <IoIosClose id="icon" /> </button>
                </RenameInput>
            }
            <Children draggable={false}>
                <div className={/* animation ?  */ isClose ? "upAni" : "downAni" /* : "" */}>
                    {children}
                </div>
            </Children>
            <Modal ref={modalAdd}>
                <AddItem folder={folder} modalAdd={modalAdd} />
            </Modal>
            <Modal ref={modalRemoveItem}>
                <RemoveItem folder={folder} modalRemoveItem={modalRemoveItem} />
            </Modal>
            <Modal ref={modalDelete}>
                <DeleteFolder modalDelete={modalDelete} folder={folder} />
            </Modal>
        </Container >

    );
};


const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
        folders: state.folder.list,
        ativo: state.folder.ativa,
    }
}


const mapDispatchToProps = (dispatch) => bindActionCreators({
    setListFolder,
    setListAtiva,
    changeRowFolder
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Accordion);