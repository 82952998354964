import styled from "styled-components";
import { colapsedSchedule, tablet } from "../../../../components/NavBar/style";

interface StyledProps {
  rightContent?: any;
}

export const MiddleContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.bordes.lightGrey};
  overflow: hidden;
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  background-color: ${({ theme }) => theme.backgrounds.white};

  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  @media only screen and (max-width: ${tablet}) {
    border-radius: 14px;
  }

  .container {
    overflow-y: auto;
    height: 100%;
  }
  .e-calendar {
    max-width: 100%;
    margin: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

export const Header = styled.div`
  padding: 0px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: ${({ theme }) => theme.heightToolbar};
  min-height: ${({ theme }) => theme.heightToolbar};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};
  background-color: ${({ theme }) => theme.backgrounds.secondaryToolbar};

  .icon-header {
    color: ${({ theme }) => theme.texts.secondaryToolbar} !important;
  }
  .logo {
    width: auto;
    height: 14px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  .top {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    width: 100%;
    button {
      padding: 0;
    }

    .title {
      margin: 0px;
      color: ${({ theme }) => theme.texts.secondaryToolbar};
      font-family: "inter-bold", sans-serif;
      font-size: 1.5rem;
    }
  }

  #btn-option {
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
    #icon {
      font-size: 25px;
    }
  }
`;
