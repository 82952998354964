export enum Queries {
  CATEGORIES_EVENT = "CATEGORIES_EVENT",
  STATE_WEATHER = "STATE_WEATHER",
  REPOSITORY_FILES = "REPOSITORY_FILES",
  REPOSITORY_FILES_SHARING_RECEIVE = "REPOSITORY_FILES_SHARING_RECEIVE",
  REPOSITORY_DETAILS_ITEM = "REPOSITORY_DETAILS_ITEM",
  REPOSITORY_FILES_SHARING_SEND = "REPOSITORY_FILES_SHARING_SEND",
  REPOSITORY_FOLDERS = "REPOSITORY_FOLDERS",
  REPOSITORY_FOLDERS_SHARING_RECEIVE = "REPOSITORY_FOLDERS_SHARING_RECEIVE",
  REPOSITORY_FOLDERS_SHARING_SEND = "REPOSITORY_FOLDERS_SHARING_SEND",
  REPORT_TEMPLATES = "REPORT_TEMPLATES",
}
