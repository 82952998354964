import { AddRole, EditRole } from "../Role";
import { EditGroup } from "../index";
import ProfileGroup from './ProfileInfo'

import PrivacyGroup from "./Privacy"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ProfileUser from "./ProfileUser";

const GroupProfile = (props) => {
    const { path } = useRouteMatch();
    
    const routes = [
        { path: "/profile", component: ProfileGroup, exact: true },
        { path: "/profile/user/:id", component: ProfileUser, exact: true },
        { path: "/profile/editGroup", component: EditGroup },
        { path: "/profile/addRole", component: AddRole },
        { path: "/profile/editRole/:id", component: EditRole },
        { path: "/profile/privacyGroup", component: PrivacyGroup },
    ]

    return (
        <Switch location={props.location}>
            {routes.map((route, key) => (
                <Route {...route} path={path + route.path} key={key} />
            ))}
            <Redirect exact to={path + "/profile"} />
        </Switch>
    );
}

export default GroupProfile;
