import styled from "styled-components";

interface StyledProps {
    isMy?: boolean;
    color?: string;
    fixed?: boolean;
    hasDescription?: boolean;
}

export const DownloadFile = styled.div<StyledProps>`
    display: flex;
    width: 43px;
    height: 43px;

    .iconFile {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      font-size: 20px;
      border-radius: 50%;
      color: ${(props) => props.theme.default.ligther};
      background-color: ${(props) => props.theme.purple.dark};
      transition: 1s;

      .float-download {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: -4px;
        right: -4px;
        background-color: ${(props) => props.theme.purple.ligther};
        color: ${(props) => props.theme.highlights.default};
        border-radius: 50%;
        font-size: 15px;
        transition: 0.3s; 
      }
    }

    &:hover {
      .iconFile {
        background-color: transparent;
        .float-download {
          width: 38px;
          height: 38px;
          bottom: 0px;
          right: 0px;
        }
      }
    }
`;