import { useEffect, useState } from "react";
import { MdOutlineEventNote } from "react-icons/md";
import { InputSelect } from "../../../../../../components";
import { getEventsGroup } from '../../../../../../services/requests/event';
import { OptionSelect, ReportEnum, ReportOptionsInterface, TEvent } from "../../../../../../types/project";
import { Element } from "../../style";

interface EventFilterProps {
    edit: boolean;
    optionsPDF: ReportOptionsInterface;
    startDate: Date;
    endDate: Date;
}
type OptionEvent = {
    value: TEvent;
    label: string;
};
function EventsOptions({ edit, optionsPDF, startDate, endDate }: EventFilterProps) {

    const [eventOptions, setEventOptions] = useState<OptionSelect[]>([]);
    const [eventSelected, setEventSelected] = useState<OptionEvent | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const isEvent = typeof optionsPDF?.key !== "string";

    useEffect(() => {
        return () => {
            setEventOptions([]);
            setEventSelected(undefined);
            setIsLoading(false);
        };

    }, []);
    useEffect(() => {
        if (eventSelected && typeof eventSelected.value !== "number") {
            optionsPDF.key = eventSelected!.value;
        }
    }, [eventSelected]);

    useEffect(() => {
        setIsLoading(true);

        getEventsGroup({
            start: startDate,
            finish: endDate,
        }).then((res) => {
            eventOptions.length = 0;
            res.data && res.data.forEach((event: TEvent) => {
                eventOptions.push({ value: event, label: event.title });
            })
            setEventOptions(eventOptions);
        }).finally(() => { setIsLoading(false) });
    }, [startDate, endDate]);

    return (
        <Element $noWrap={true}>
            <div className="icon">
                <MdOutlineEventNote />

            </div>
            {
                edit ? <InputSelect value={eventSelected} onChange={(e) => { setEventSelected(e), console.log(e) }} noOptionsMessage="Sem eventos para o período" name="filterOptions" id="filterOptions" isLoading={isLoading}
                    placeholder="Eventos" style={true} options={eventOptions} /> :
                    <p>{optionsPDF.key && typeof optionsPDF?.key !== "string" ? optionsPDF.key.title : `Nenhum evento selecionado`}</p>
            }
        </Element>
    );
}

export default EventsOptions;