import { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Checkbox } from "../../../../../components";
import { addVisibleCategory, removeVisibleCategory } from "../../../../../store/actions/scheduleAction";
import { Item } from "../style";

type Category = {
    label: string;
    color: string;
    checked: boolean;
    value: number;
}

interface CustomProps {
    category: Category;
    handleChecked: (cat: Category) => void;
}
const CustomCheck = ({ category, handleChecked }: CustomProps) => {
    return <div onClick={() => handleChecked(category)} className="lineChecked" >
        <Checkbox onChange={() => { }} checked={category.checked} color={category.color} />
        <p>{category.label}</p>
    </div>
}

const CardCategoryEvent = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(true);
    const [alturaFormulario, setAlturaFormulario] = useState<"auto" | number>(200);

    const calcularAltura = () => {
        const formulario = document.getElementById("form-category-event");
        console.log(formulario)
        console.log(formulario?.children)
        const alturaFilhos = Array.from(formulario!!.children).reduce(
            (alturaTotal, filho) => alturaTotal + filho.clientHeight,
            0
        );
        setAlturaFormulario(alturaFilhos);
    };

    const getColor = (type) => {
        switch (type) {
            case 0:
                return "#473C5F";
            case 1:
                return "#036AF5";
            case 2:
                return "#09976D";
            case 3:
                return "#E25003";
            case 4:
                return "#3B6309";
            default:
                return "#473C5F";
        }
    }

    const handleChange = (category: Category) => {
        const index = values.indexOf(category);
        const value = values[index];
        const valueChecked = value.checked ?? false;
        values[index] = { ...value, checked: !valueChecked };
        if (!valueChecked) {
            dispatch(addVisibleCategory(value.value));
        } else {
            dispatch(removeVisibleCategory(value.value));
        }
        setValues([...values]);
    }

    const handleOpen = () => {
        if (isOpen) {
            console.log("0000")
            setAlturaFormulario(0);
        }
        else {
            console.log("calculo")
            calcularAltura();
        }
        setIsOpen(!isOpen);

    }
    const [values, setValues] = useState<Category[]>([
        { value: 0, label: "Outros", checked: true, color: getColor(0) },
        { value: 1, label: "Aniversário", checked: true, color: getColor(1) },
        { value: 2, label: "Reunião", checked: true, color: getColor(2) },
        { value: 3, label: "Atividade", checked: true, color: getColor(3) },
        { value: 4, label: "Compromisso", checked: true, color: getColor(4) }])


    return <Item>
        <header>
            <p className="title">Padrão</p>
            <IoIosArrowUp onClick={handleOpen} className={isOpen ? "center down icon-arrow" : "center up icon-arrow"} size={18} />
        </header>
        <form id="form-category-event" style={{ maxHeight: `${alturaFormulario}px` }}>
            <div>
                {values.map((value) => <CustomCheck category={value} handleChecked={handleChange} key={value.value} />)}
            </div>
        </form>

    </Item>

}

export default CardCategoryEvent;