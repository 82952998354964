import styled from "styled-components";
import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import { colapsedSchedule } from "../../../../components/NavBar/style";

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;

  img {
    max-width: 600px;
    width: 100%;
    height: auto;
  }
  .alert {
    display: grid;
    grid-template-rows: auto auto;

    align-content: center;
    text-align: center;

    #title {
      font-size: 60px;
      color: ${(props) => props.theme.texts.purpleDark};
      font-family: "inter-medium";
      margin: 0;
    }

    #message {
      max-width: 600px;
      font-size: 25px;
      font-family: "InterLight";
      font-weight: normal;
    }
  }
`;

interface StyledProps {
  rightContent?: any;
  overflow: boolean;
}

export const MiddleContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  box-sizing: border-box;

  background-color: ${(props) => props.theme.backgrounds.white};
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.bordes.lightGrey};
  overflow: auto;
  overflow-x: ${({ overflow }) => (overflow ? "auto" : "hidden")};
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;

  .container-list {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding-top: 1rem;
  }
  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .iconClose {
    font-size: 22px;
    color: ${({ theme }) => theme.texts.black};
    cursor: pointer;
  }
  .container {
    overflow-y: auto;
    height: 100%;
  }
  .e-calendar {
    max-width: 100%;
    margin: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: initial;
    margin-top: 0px;
    border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};
  }

  .e-btn.e-flat.e-primary,
  .e-css.e-btn.e-flat.e-primary {
    background-color: transparent;
    border-color: transparent;
    color: ${(props) => props.theme.backgrounds.buttonSchedule} !important;
  }

  .e-calendar .e-content td.e-today span.e-day,
  .e-calendar .e-content td.e-focused-date.e-today span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
  .e-bigger.e-small
    .e-calendar
    .e-content
    td.e-focused-date.e-today
    span.e-day {
    background: none;
    border: 1px solid ${(props) => props.theme.backgrounds.buttonSchedule} !important;
    border-radius: 50%;
    color: ${(props) => props.theme.backgrounds.buttonSchedule};
  }

  .e-calendar .e-content td.e-selected span.e-day,
  .e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
    background-color: ${(props) =>
      props.theme.backgrounds.buttonSchedule} !important;
    border: none;
    border-radius: 50%;
    color: #fff !important  ;
  }
`;
