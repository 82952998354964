import { CalendarComponent } from "@syncfusion/ej2-react-calendars";

class CalendarDate extends CalendarComponent {
    constructor(props: any) {
        super(props);
    }    
    get getCurrentDate(){
        return this.currentDate;
    }
}

export default CalendarDate;