import { getClusters } from "../../services/requests/cluster"
import { CONSTS, TClient } from "../../types/project"
import { arrayMove, getClientId, getNameUserGroup, keyClient } from "../../util/verification"
import { SET_LIST_ATIVA, SET_LIST_FOLDER } from "./actionTypes"
import { setGlobalMessages, updateClient } from "./messages/chatAction"
import { getAllState } from "./messages/messageAction"

export const setListFolder = (list) => {
    return {
        type: SET_LIST_FOLDER,
        payload: list
    }
}

export const changeRowFolder = (folderId: string, row: boolean) => {
    return (dispatch, getState) => {
        const { folders } = getAllState(getState());
        const index = folders.findIndex(folder => folder.id == folderId);
        folders[index].isClose = row;
        dispatch(setListFolder([...folders]));
    }
}

export const setListAtiva = (ativa) => {
    return {
        type: SET_LIST_ATIVA,
        payload: ativa
    }
}

export const updateMessageInFolders = (message) => {
    return (dispatch, getState) => {
        const { abare, personal, folders, globalMessages } = getAllState(getState())
        const clientId = getClientId(message)
        var client = globalMessages[clientId]?.client


        const updateItem = (conversation: TClient) => {
            var item = { ...conversation, lastMessage: message }
            const notOpenConversation = getClientId(message) != getClientId(personal)
            const verifyCountMessage = message.sendBy != abare?.id && notOpenConversation

            if (verifyCountMessage) {
                item = { ...item, countMessage: ((conversation.countMessage || 0) + 1) }
            }

            if (message.Group_id) {
                item = { ...item, nameLastMsg: getNameUserGroup(conversation, message.sendBy) }
            }
            dispatch(updateClient({...item}))
            return item;
        }

        client = updateItem(client)

        folders.map((folder) => {
            const itens = folder.itens;
            const index = itens.findIndex(item => getClientId(item) == clientId);
            const hasConversation = index !== -1;

            if (hasConversation) {
                folder.itens = arrayMove(itens, index, 0, client);
            } else if(folder.id == abare?.id){
                folder.itens = [client, ...itens]
            }
        });

        dispatch(setListFolder([...folders]))
    }
}


export const getAllClusters = () => {
    return (dispatch, getState) => {
        const { abare, socket, folders } = getAllState(getState())
        !folders.length && getClusters(abare?.id).then((folder) => {
            if (folder.status == 200) {
                const itensGeneral = folder.data[0].itens

                var result = itensGeneral.reduce((acc, client) => {
                    let id = getClientId(client);
                    acc[id] = { client: client, messages: [], currentPage: 0 };
                    return acc;
                }, {});
                socket.emit("join", abare?.id);
                dispatch(setGlobalMessages(result))
                dispatch(setListFolder(folder.data));
            }
        })
    }
}

export const insertItemInGeneral = (client: TClient) => {
    return (dispatch, getState) => {
        const { folders } = getAllState(getState())
        const clientId = getClientId(client);
        const itens = folders[CONSTS.GENERAL].itens;

        const hasConversation = itens.find(item => item[keyClient[client.type]] == getClientId(client))

        if (hasConversation) {
            client.isRequest = true;
            dispatch(updateClient({ ...client }))
        } else {
            folders[CONSTS.GENERAL].itens = [...itens, client]
            dispatch(setListFolder([...folders]));


            const result = { [clientId]: { client: client, messages: [], currentPage: 0 } };

            dispatch(setGlobalMessages(result))
        }
    }
}