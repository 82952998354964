import { } from 'react'
import { TypeStatusRequest } from '../../../types/project';
import { FiUpload, FiDownload, MdOutlineFileDownloadOff } from '../../../assets/icons';
import Waveform from '../../../pages/home/Conversations/Chat/Footer/wave';
import { VoiceMessage } from './style';
import { FileCircle, FileLoad } from '../RequestStatus';

const Voice = (props) => {
    const { message, fetchMessage, myMessage, displayName, setMessage } = props
    const { DOWNLOADING, SUCCESS, ERRODOWN, ERROUP, UPLOADING } = TypeStatusRequest
    const { fileType } = message

    const DefaultPreview = ({ children }) => (
        <div className="waveform-container">
            {children}
            <div className="defaultBar" />
        </div>
    )

    const StatusVoice = () => {
        switch (message.statusRequest) {
            case UPLOADING:
            case DOWNLOADING:
                return (
                    <DefaultPreview>
                        <div className="uploading">
                            <FileLoad myMessage={myMessage} onClick={() => { }} />
                        </div>
                    </DefaultPreview>
                );

            case ERROUP:
                return (
                    <DefaultPreview>
                        <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => setMessage(message)} >
                            <FiUpload />
                        </FileCircle>
                    </DefaultPreview>
                )
            case ERRODOWN:
                return (
                    <DefaultPreview>
                        <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => fetchMessage(message)} >
                            <MdOutlineFileDownloadOff />
                        </FileCircle>
                    </DefaultPreview>

                );
            default:
                return (
                    message.blobUrl ?
                        <Waveform url={message.blobUrl} />
                        :
                        <DefaultPreview>
                            <FileCircle fileType={fileType} myMessage={myMessage} onClick={() => fetchMessage(message)}>
                                <FiDownload />
                            </FileCircle>
                        </DefaultPreview>
                );
        }
    }
    return (
        <VoiceMessage displayName={displayName}>
            <StatusVoice />
        </VoiceMessage >
    );
}

export default Voice;