import { ItemFile } from "../../../../../components";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { TypeImage } from "../../../../../types/project";
import { ContainerContent, Item } from "../style";

interface ProtocolProps {
    description: string;
    number: string;
    tema: string;
    images: TypeImage[];
    modalRef: any;
}

const ProtocolSend = ({ modalRef, description, number, tema, images }: ProtocolProps) => {
    return (
        <ModalContainer padding={false} title={` O número do seu protocolo é ${number}`} onClick={() => modalRef.current.close()} buttonBack={true}  >
            <ContainerContent >
                <p className="p_send"><strong>Tema: </strong>{tema}</p>
                <p className="p_send"><strong>Descrição: </strong>{description}</p>
                {images && images.map(({ newUrl, type, file }, i) => (
                    <Item key={i} >
                        <div className="media">
                            <ItemFile className="img-video" type={type} src={newUrl} />
                        </div>
                        <p>{file.name}</p>
                    </Item>
                ))}
            </ContainerContent>
        </ModalContainer>
    )
};

export default ProtocolSend;

