import { AxiosResponse } from "axios";
import { api } from "../api";
import { getToken } from "../auth";

export interface StorageClusterSharingPayload {
  level: boolean;
  User_ids: string[];
  storageClusterId: string;
}

const post = (data: StorageClusterSharingPayload): Promise<AxiosResponse> => {
  return api({
    url: `/storage-cluster-sharing`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

const getReceive = () => {
  return api({
    url: `/storage-cluster-sharing/sharing-receive-cluster`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

const getSend = () => {
  return api({
    url: `/storage-cluster-sharing/sharing-send-cluster`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

const getDetails = (storageClusterId: string) => {
  return api({
    url: `/storage-cluster-sharing/sharing-detail-cluster`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: {
      storageClusterId: storageClusterId,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

export type DeleteSharingSendCLusterPayload = {
  userIds: string[];
  storageClusterId: string;
};
const deleteSharingSend = (data: DeleteSharingSendCLusterPayload) => {
  return api({
    url: `/storage-cluster-sharing/remove-send`,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};
export const StorageClusterSharingService = {
  getReceive,
  getSend,
  getDetails,
  post,
  deleteSharingSend
};
