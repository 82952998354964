import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconPaperTerm = ({ color = "#A6446A", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M16.9167 7.5C16.9167 7.32319 16.8464 7.15362 16.7214 7.0286L11.7214 2.0286C11.5964 1.90357 11.4268 1.83333 11.25 1.83333H5.625C4.25681 1.83333 3.08333 3.00681 3.08333 4.375V15.625C3.08333 16.9932 4.25681 18.1667 5.625 18.1667H14.375C15.7432 18.1667 16.9167 16.9932 16.9167 15.625V7.5Z"
        fill="inherit"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 3.75L11.25 6.50001C11.25 7.05229 11.6977 7.5 12.25 7.5H16.25"
        fill="#F1E6EC"
      />
      <path
        d="M11.25 3.75L11.25 6.50001C11.25 7.05229 11.6977 7.5 12.25 7.5H16.25"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33331 8.8667H11"
        stroke="#F1E6EC"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M5.33334 12.1047H11"
        stroke="#F1E6EC"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <path
        d="M5.33334 15.3428H11"
        stroke="#F1E6EC"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </Icon>
  );
};

export default IconPaperTerm;
