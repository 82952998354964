
import { useEffect, useState } from "react";
import { ContainerSwitch } from "../style";
import Content from "./Content";
import ListOptions from "./ListOptions";
import { ContentSchedule } from "./style";

const Schedule = () => {
    const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);
    const handleWidth = () => setWidthWindow(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", handleWidth);
        return () => {
            window.removeEventListener("resize", handleWidth);
        }
    });
    
    return <ContainerSwitch>
        <ContentSchedule>
            {widthWindow >= 925 && <ListOptions />}
            <Content />
        </ContentSchedule>
    </ContainerSwitch>
} 
export default Schedule;