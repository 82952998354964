import styled from "styled-components";
import { Conversation } from "../../../../../pages/home/Conversations/Folder/ItemFolder/style";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 50px 250px;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  justify-items: center;
  align-items: center;

  h5 {
    justify-self: start;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  padding-left:0.5rem;
padding-top: 1rem;
  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }
`;

export const Button = styled.button`
  display:flex;
  align-self: center;
  background: transparent;
  #icon {
    font-size: 20px;
  }
  .default{
    color: ${(props) => props.theme.default.darkI};
  }
  .purple {
    color: ${(props) => props.theme.highlights.default};
  }
`;
 
export const ListConversation = styled(Conversation)`
  padding: 0.5rem;
  padding-inline: 1rem;
  pointer-events: none; 
  .relative{
    position: relative;
    padding: 0.5rem;
  }

  
`;

export const Default = styled.div`
  position: absolute;
  top: 30px;
  right:0px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border:2px solid white; 
    

`;