import styled from "styled-components";

export const NameColor = styled.div`
  color: ${(props) => props.color};
`;

export const Circle = styled.div`
  display: flex;
  height: 39px;
  width: 39px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.1s ease-in;
  &:hover {
    filter: brightness(80%);
  }

  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  padding: 0.5rem 0rem;
  flex-flow: row wrap;


  .label-color {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin-right: 0.3rem;
    transition: 2s ease-in;
    
    ${Circle}:hover + ${NameColor} {
      opacity: 9;
    }

    .none {
      opacity: 0;
    }
  }
`;
