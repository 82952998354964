let optionDay = [{ value: 0, label: "Dia" }];

function dayList() {
  for (let i = 1; i < 32; i++) {
    let valueOption = i;
    let labelOption = i.toString();
    optionDay.push({ value: valueOption, label: labelOption });
  }
}
function areDatesEqual(date1, date2) {
  const day1 = date1.getDate();
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();

  const day2 = date2.getDate();
  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();

  return day1 === day2 && month1 === month2 && year1 === year2;
}
export function areDatesEqualMonth(date1, date2) {
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();

  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();

  return month1 === month2 && year1 === year2;
}
export function calcularTempoDecorrido(dataEnvioString) {
  const dataAtual = new Date();
  const dataEnvio = new Date(dataEnvioString);
  const diff = Math.abs(dataAtual - dataEnvio);

  const segundos = Math.floor(diff / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);
  const semanas = Math.floor(dias / 7);
  const meses = Math.floor(dias / 30);
  const anos = Math.floor(dias / 365);

  if (anos > 0) {
    return `${anos} ${anos === 1 ? "ano" : "anos"} atrás`;
  } else if (meses > 0) {
    return `${meses} ${meses === 1 ? "mês" : "meses"} atrás`;
  } else if (semanas > 0) {
    return `${semanas} ${semanas === 1 ? "semana" : "semanas"} atrás`;
  } else if (dias > 0) {
    return `${dias} ${dias === 1 ? "dia" : "dias"} atrás`;
  } else if (horas > 0) {
    return `${horas} ${horas === 1 ? "hora" : "horas"} atrás`;
  } else if (minutos > 0) {
    return `${minutos} ${minutos === 1 ? "minuto" : "minutos"} atrás`;
  } else {
    return `${segundos} ${segundos === 1 ? "segundo" : "segundos"} atrás`;
  }
}
dayList();

export const optionsDayDate = optionDay;

export const numberMounthByActual = (value) => {
  const monthActual = new Date().getMonth();
  if(value + monthActual <= 11){
    return value + monthActual + 1
  }
  return value + monthActual + 1 - 12
}
export const optionsMounthDate = [
  { value: 0, label: "Mês" },
  { value: 1, label: "Janeiro" },
  { value: 2, label: "Fevereiro" },
  { value: 3, label: "Março" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Maio" },
  { value: 6, label: "Junho" },
  { value: 7, label: "Julho" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Setembro" },
  { value: 10, label: "Outubro" },
  { value: 11, label: "Novembro" },
  { value: 12, label: "Dezembro" },
];

let optionsYear = [{ value: 0, label: "Ano" }];

function yearList() {
  for (let i = 2022; i >= 1900; i--) {
    let valueOption = i;
    let labelOption = i.toString();
    optionsYear.push({ value: valueOption, label: labelOption });
  }
}

yearList();

export const optionsYearDate = optionsYear;

export const dateBirthday = (day, mounth, year) => {
  let dayDate = day.toString();
  let mounthDate = mounth.toString();
  let yearDate = year.toString();
  dayDate = dayDate.length == 1 ? "0" + day : day;
  mounthDate = mounthDate.length == 1 ? "0" + mounth : mounth;
  yearDate = yearDate;

  return yearDate + "-" + mounthDate + "-" + dayDate;
};

export const birthdayFormat = (d) => {
  let year = d.substring(0, 4);
  let mounth = d.substring(5, 7);
  let day = d.substring(8, 10);
  day = dayFormat(day);

  if (day && mounth && year) {
    if (mounth == "01") {
      mounth = "de Janeiro de";
    } else if (mounth == "02") {
      mounth = "de Fevereiro de";
    } else if (mounth == "03") {
      mounth = "de Março de";
    } else if (mounth == "04") {
      mounth = "de Abril de";
    } else if (mounth == "05") {
      mounth = "de Maio de";
    } else if (mounth == "06") {
      mounth = "de Junho de";
    } else if (mounth == "07") {
      mounth = "de Julho de";
    } else if (mounth == "08") {
      mounth = "de Agosto de";
    } else if (mounth == "09") {
      mounth = "de Setembro de";
    } else if (mounth == "10") {
      mounth = "de Outubro de";
    } else if (mounth == "11") {
      mounth = "de Novembro de";
    } else {
      mounth = "de Dezembro de";
    }
    const date = day + " " + mounth + " " + year;
    if (date == "00 de Dezembro de 0000") {
      return "Não informado";
    }
    return date;
  }
  return "Não informado";
};

export const dayFormat = (d) => {
  if (d) {
    if (d == "01") {
      d = "1";
    } else if (d == "02") {
      d = "2";
    } else if (d == "03") {
      d = "3";
    } else if (d == "04") {
      d = "4";
    } else if (d == "05") {
      d = "5";
    } else if (d == "06") {
      d = "6";
    } else if (d == "07") {
      d = "7";
    } else if (d == "08") {
      d = "8";
    } else if (d == "09") {
      d = "9";
    } else {
      return d;
    }
    return d;
  }
  return;
};

export const defaultDay = (d) => {
  if (d) {
    d = d.substring(8, 10);
    d = dayFormat(d);
    d = parseInt(d);
    return d;
  }
  return 0;
};

export const defaultMounth = (d) => {
  if (d) {
    d = d.substring(5, 7);
    d = dayFormat(d);
    d = parseInt(d);
    return d;
  }
  return 0;
};

const yearFormat = (d) => {
  if (d) {
    let years = ["null"];
    let aux,
      x = 0;

    for (let i = 2022; i >= 1900; i--) {
      years.push(i);
    }
    while (d != years[x]) {
      aux = years[x];
      x += 1;
    }
    return x;
  }
  return;
};

export const defaultYear = (d) => {
  if (d) {
    d = d.substring(0, 4);
    d = parseInt(d);
    d = yearFormat(d);
    return d;
  }
  return 0;
};

export const defaultAbareYear = (d) => {
  if (d) {
    d = d.substring(0, 4);
    d = parseInt(d);
    return d;
  }
  return 0;
};

export const verificationYearDate = (year, yearValue, day, mounth) => {
  let yearDef;

  if ((year >= 0, yearValue >= 0)) {
    let y = year.toString();
    yearDef = y.length < 4 ? yearValue : year;
  }
  if (year == 0 && mounth == 0 && day == 0) {
    yearDef = "0000";
  }
  return yearDef;
};

function formatarDataDia(data) {
  const diasSemana = [
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
  ];
  const meses = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const diaSemana = diasSemana[data.getDay()];
  const dia = data.getDate();
  const mes = meses[data.getMonth()];
  const ano = data.getFullYear();

  return `${diaSemana}, ${dia} de ${mes} de ${ano}`;
}

function formatarDataMes(inicio, fim) {
  const meses = [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const diaInicio = inicio.getDate();
  const diaFim = fim.getDate();
  const mesInicio = meses[inicio.getMonth()];
  const mesFim = meses[fim.getMonth()];
  const ano = inicio.getFullYear();

  let periodo = `${diaInicio} - ${diaFim} ${mesInicio} ${ano}`;

  if (mesInicio !== mesFim) {
    periodo = `${diaInicio} ${mesInicio} - ${diaFim} ${mesFim} ${ano}`;
  }

  return periodo;
}

export function formatarPeriodo(inicio, fim) {
  const dataInicio = new Date(inicio);
  const dataFim = new Date(fim);

  if (dataInicio.toDateString() === dataFim.toDateString()) {
    return formatarDataDia(dataInicio);
  } else {
    return formatarDataMes(dataInicio, dataFim);
  }
}
