import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  border-radius: 16px;
  padding: 20px;
  .grid {
    display: grid;
    grid-template-columns: calc(100% - 20px) 20px;
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-folder {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .informations-cluster {
    display: flex;
    flex-direction: column;
    .icon-user {
      height: 30px;
      width: 30px;
      object-fit: cover;
    }
  }
  .share-photos {
    margin-top: 8px;
  }
  .cluster-informations {
    font-size: 0.8rem;
    margin-bottom: 4px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .random-color {
    border-radius: 50%;
    color: ${({ theme }) => theme.texts.white};
    font-size: 0.8rem;
    background-color: ${({ theme }) => theme.backgrounds.buttonSchedule};
  }
  .sharing-users {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    & > * {
      z-index: 1;
      margin-left: -8px;
    }
  }

  .no-margin {
    margin: 0;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  box-shadow: none;
  background-color: #f7f9fc;
  border: 1px solid rgba(145, 158, 171, 0.24);

  margin-bottom: 1rem;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.hover.lightGrey};
  }

  .buttonDrop {
    border-radius: 50%;
    padding: 2px;
    :hover {
      background-color: ${({ theme }) => theme.hover.lightGreyIII};
    }
  }
`;
