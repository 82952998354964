import { Fragment, useState } from "react";
import { Input } from "../../../../../../components/Input";
import { isFilled } from "../../../../../../util/verification";
import ItemContainer from "../../../Components/ItemContainer";
import { Row } from "../../style";

const Address = (props) => {
    const { user, editInfos } = props;
    const [perfilState, setPerfilState] = useState(false);
    const [load, setLoad] = useState(false);

    const updatePerfil = e => {
        e.preventDefault()
        const { zipCode, street, nbhood, city, state, country } = e.target.elements;

        const data = {
            zipCode: isFilled(zipCode.value) ? zipCode.value : null,
            street: isFilled(street.value) ? street.value : null,
            nbhood: isFilled(nbhood.value) ? nbhood.value : null,
            city: isFilled(city.value) ? city.value : null,
            state: isFilled(state.value) ? state.value : null,
            country: isFilled(country.value) ? country.value : null,
        }

        if (zipCode.value != user.zipCode || street.value != user.street || nbhood.value != user.nbhood || city.value != user.city || state.value != user.state || country.value != user.country) {
            const functionSucess = () => {
                user.zipCode = data.zipCode;
                user.street = data.street;
                user.nbhood = data.nbhood;
                user.city = data.city;
                user.state = data.state;
                user.country = data.country;

                setPerfilState(false)
                return user;
            }
            editInfos(data, functionSucess, setLoad)
        } else {
            setPerfilState(false)
            setLoad(false)
        }
    }


    const fields = [
        { name: "zipCode", label: "CEP", placeholder: "Digite seu CEP aqui", defaultValue: user.zipCode, maxLength: 8, title: "Apenas números!" },
        { name: "street", label: "Logradouro", placeholder: "Digite seu logradouro aqui", defaultValue: user.street, maxLength: 100 },
        { name: "nbhood", label: "Bairro", placeholder: "Digite seu bairro aqui", defaultValue: user.nbhood, maxLength: 100 },
        { name: "city", label: "Cidade", placeholder: "Digite sua cidade aqui", defaultValue: user.city, maxLength: 70 },
        { name: "state", label: "Estado", placeholder: "Digite seu estado aqui", defaultValue: user.state, maxLength: 70 },
        { name: "country", label: "País", placeholder: "Digite seu país aqui", defaultValue: user.country, maxLength: 70 },

    ]
    return (
        <Fragment>
            {perfilState ?
                <Fragment>
                    <form onSubmit={updatePerfil}>
                        <ItemContainer title="Endereço" onClick={() => setPerfilState(!perfilState)} load={false} isButton>
                            {fields.map(({ label, title, ...rest }, index) => (
                                <Row key={index}>
                                    {label}
                                    <Input title={title || label}
                                        pattern={title ? "[0-9]+$" : "[A-Za-0-9záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"}
                                        {...rest}
                                    />
                                </Row>
                            ))}
                        </ItemContainer>
                    </form>
                </Fragment>
                :
                <Fragment>
                    <ItemContainer title="Endereço" onClick={() => setPerfilState(!perfilState)} load isButton>
                        {fields.map(({ label, defaultValue }, index) => (
                            <Row key={index}>
                                {label}
                                <div className="inputs">
                                    <input disabled defaultValue={defaultValue || 'Não informado'}></input>
                                </div>
                            </Row>
                        ))}
                    </ItemContainer>
                </Fragment>
            }
        </Fragment>
    );
}

export default Address;