import styled from "styled-components";
import { mobile } from "../../../components/NavBar/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  align-items: center;
  background: #fafcfc;
  width: 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: ${mobile}) {
    padding-top: 6rem;
  }
`;

export const Header = styled.header`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
  height: 5rem;
  min-height: 64px;
  max-height: 125px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 40px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(115, 123, 135, 0.2);

  .content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 575px;
    margin-right: 8px;
    & .back {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      color: #787e94;
      cursor: pointer;
      & .icon {
        font-size: 20px;
        margin-right: 8px;
      }
    }

    & .title {
      font-weight: 600;
      line-height: 54px;
      font-size: 28px;
      color: #042149;
    }

    @media screen and (max-width: 640px) {
      p {
        display: none;
      }
      justify-content: start;
    }
  }

  .logo {
    height: 22px;
    width: auto;
  }

  @media screen and (max-width: 570px) {
    .logo {
      display: none;
    }
    padding: 0px 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  width: 100%;
  max-width: 1500px;
  & .title {
    font-weight: 600;
    font-size: 4rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 96px;
    margin-bottom: 0;

    & .orange {
      color: #ff762d;
    }
    & .green {
      color: #03bb85;
    }
    & .red {
      color: #b80504;
    }
  }

  & .subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    color: #1d1a24;
  }

  @media screen and (max-width: ${mobile}) {
    & .title {
      font-size: 3rem;
    }
  }
`;

export const BToggle = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  width: 525px;
  height: 63px;
  max-width: 100%;
  margin-top: 5rem;
  box-sizing: border-box;
  background: #eeeeee;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  justify-content: space-around;
  align-items: center;
  color: #484848;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  & div {
    z-index: 10;
  }

  & div {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  & .toggle {
    position: absolute;
    margin-left: -350px;
    z-index: 0;
    width: 175px;
    height: 63px;
    background: #0253c0;
    border-radius: 32px;
    transition: all 0.3s ease-in-out;
    &.anual {
      margin-left: 350px;
    }
    &.semestral {
      margin-left: 30px;
    }
  }

  & .active {
    color: #fff;
  }
`;

export const PlansList = styled.div`
  display: flex;
  margin: 0px 2rem;
  height: auto;
  min-height: 600px;
  justify-content: center;
  box-sizing: border-box;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 40px;

  @media screen and (max-width: ${mobile}) {
    margin: 0px 1rem;
  }
`;

export const PlanCard = styled.div`
  display: flex;
  min-width: 341px;
  max-width: 532px;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  width: auto;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  margin: 20px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 52px 52px 32px 52px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  cursor: default;
  transition: all 0.3s ease;

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  &:hover {
    border: 1px solid #0253c0;
    & .btn-buy {
      background: #0253c0;
      color: #fff;
    }
  }

  & .name {
    color: #300012;
    font-family: "Poppins semi-bold", sans-serif;
    font-weight: 600;
    font-size: 32px;
    margin: 0;
  }

  & .original-price {
    font-family: "Poppins bold", sans-serif;
    margin: 0;
    font-size: 18px;
    text-decoration-line: line-through;
    color: #aaaaaa;
  }

  & .price {
    color: #300012;
    font-family: "Poppins bold", sans-serif;
    font-weight: 700;
    font-size: 48px;
    margin: 0;
  }

  & .description {
    font-weight: 400;
    font-size: 18px;
    margin: 0px;
    color: #666666;
  }
  & .features {
    padding: 0;
    list-style: none;

    & li {
      display: grid;
      grid-template-columns: 32px 1fr;
      align-items: center;
      color: #666666;
      margin: 8px 0;
      & p {
        margin: 0;
      }
    }

    & .icon {
      color: #4e001e;
    }
  }
  & .btn-buy {
    display: flex;
    border: 2px solid #0253c0;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #0253c0;
    padding: 10px 0px;
    transition: all 0.3s ease;
  }
`;
