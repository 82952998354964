import styled from "styled-components";

interface ContainerProps{
  $borderBottom?: boolean;
}
export const ContainerOption = styled.div<ContainerProps>`
  ${({ $borderBottom, theme }) =>
    $borderBottom &&
    `
    border-bottom: 1px solid ${theme.bordes.lightGrey};`}
  .child-div {
    transition: height 1s;
  }
  .tab{
    padding-left: 1rem;
  }

  .hide {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .show {
    height: auto;
  }

  .icon-arrow{
    color: "#888888";
  }
  .icon-color {
    display: flex;
    margin-left: 8px;
    margin-right: 8px;
    background-color: #888888;
    color: ${(props) => (props.theme.title === "light" ? "white" : "#092232")};
    padding: 6px;
    border-radius: 100%;
    cursor: pointer;

    :hover {
      background-color: ${(props) => props.theme.highlights.default};
    }
  }
  .last-item {
    padding-bottom: 8px;
  }

  .up {
    transform: rotate(90deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  .down {
    transform: rotate(180deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: row;
  img {
    width: auto;
    height: 75px;
    object-fit: cover;
  }
  svg {
    display: flex;
    margin-left: 4px;
    background-color: #888888;
    color: ${(props) => (props.theme.title === "light" ? "white" : "#092232")};
    padding: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    height: fit-content;

    :hover {
      background-color: ${(props) => props.theme.highlights.default};
    }
  }
`;
