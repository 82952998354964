import { } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ItemFile } from '../../../../../../components';
import { setNavBar } from '../../../../../../store/actions/abareAction';

import Img from '../../../../../../components/Img';
import { setPersonal } from '../../../../../../store/actions/messages/chatAction';
import { TClient, TPersonal, TypePersonal } from '../../../../../../types/project';
import { TypeConstRedux } from '../../../../../../types/redux';
import { getClientId, getHourMessage, photoUrl } from '../../../../../../util/verification';
import { Conversation } from '../style';

type TProps = {
    client: TClient,
    setPersonal: any,
    personal: TPersonal;
    setNavBar: any;
    previewNotifica: boolean
}
const ItemConversation = (props: TProps) => {
    const { client, setPersonal, personal, setNavBar, previewNotifica } = props
    const { push } = useHistory()

    const lastMessage = () => {
        const lastMessage = client.lastMessage?.description;
        switch (client.type) {
            case TypePersonal.GROUP:
                const lastMsg = client.nameLastMsg + ": " + lastMessage;
                return lastMsg
            default:
                return lastMessage
        }
    }

    const getName = () => {
        if (client.name || client.nickname) {
            return client.name ? client.name : "@" + client.nickname
        } else {
            return "Sem Nome"
        }
    }

    return (
        <Conversation onClick={() => {
            setNavBar(false)
            setPersonal(client);
            push('/home/conversations/chat')
        }}
            active={getClientId(personal) == getClientId(client)}>
            <div>
                <Img className="profile" src={photoUrl(client)} />
            </div>
            <div id="col-message">
                <h4 id="name">{getName()}</h4>
                {previewNotifica && <div id="message">
                    {client.lastMessage &&
                        (client.lastMessage.fileType ?
                            <div className="fileIcon">
                                <ItemFile icons type={client.lastMessage?.fileType} size={15} />
                                <div className="fileName">{client.lastMessage?.fileName}</div>
                            </div>
                            :
                            lastMessage())

                    }
                </div>}
            </div>
            <div id="col-end">
                {client.countMessage > 0 &&
                    <div id="notification">
                        {client.countMessage}
                    </div>
                }
                <div id={client.countMessage > 0 ? "time-notify" : "time"}>
                    {getHourMessage(client.lastMessage ? client.lastMessage.createdAt : null)}
                </div>
            </div>
        </Conversation>
    );
}

const mapStateToProps = (state: TypeConstRedux) => ({
    personal: state.chat.personal,
    previewNotifica: state.abare.abare.privacy?.previewNotifica
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setPersonal, setNavBar
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ItemConversation)