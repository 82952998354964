import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconPlus = ({ color = "#fff", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon width={size} height={size} viewBox="0 0 24 24" fill="none" {...rest}>
      <path
        d="M18 10H14V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4C11.4696 4 10.9609 4.21071 10.5858 4.58579C10.2107 4.96086 10 5.46957 10 6L10.071 10H6C5.46957 10 4.96086 10.2107 4.58579 10.5858C4.21071 10.9609 4 11.4696 4 12C4 12.5304 4.21071 13.0391 4.58579 13.4142C4.96086 13.7893 5.46957 14 6 14L10.071 13.929L10 18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V13.929L18 14C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12C20 11.4696 19.7893 10.9609 19.4142 10.5858C19.0391 10.2107 18.5304 10 18 10Z"
        fill={color}
      />
    </Icon>
  );
};

export default IconPlus;
