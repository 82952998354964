import DataCreated from "./DataCreated";
import PaginationOption from "./PaginationOptions";
import SectionOption from "./SectionOption";

interface VisualBlockProps {
}

const FooterOptions = ({  }: VisualBlockProps) => {

    return (
        <SectionOption title="Rodapé">
            <PaginationOption />
            <DataCreated />
        </SectionOption>
    );
}

export default FooterOptions;