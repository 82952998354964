import { memo, useRef } from "react";
import { GoInfo } from "react-icons/go";
import Linkify from 'react-linkify';
import { ItemFile } from '../';

import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { IoIosArrowDown } from "../../assets/icons";
import Forward from "../../pages/home/Conversations/ListConversation/Forward";
import { SET_ANSWER } from "../../store/actions/actionTypes";
import { TMessage, TypeDeleteMessage, TypeMessage, TypePersonal, TypeStatusMessage, TypeStatusRequest } from "../../types/project";
import { getContactName } from "../../util/verification";
import { Dropdown } from '../index';
import Modal from "../Modal";
import Document from "./Document";
import ImgVideo from "./ImgVideo";
import Status from "./Status";
import { Bubble, ContainerAnswer, ContainerMessage, MsgLayout } from "./style";
import Voice from "./Voice";

const MessageContent = (props) => {
    const { abare, message, dropMessage, sequence, contacts, personal, messages, bottomDrop, scrollTo, selectMessages = false } = props
    const dispatch = useDispatch()
    const answer = message.msg_id_root ? messages.find((mes) => mes.msg_id == message.msg_id_root) : message.msgGroup_id_root ? messages.find((mes) => mes.msg_id == message.msgGroup_id_root) : null
    const { CANCELED, SEND, READ } = TypeStatusMessage
    const { ME, ALL } = TypeDeleteMessage;
    const { description, nameUser, sendBy } = message
    const myMessage = sendBy == abare.id
    const userContact = message.type == TypePersonal.GROUP ? getContactName(contacts, message.sendBy) : null;
    const nameUserView = (message.type == TypePersonal.GROUP && userContact ? userContact : nameUser);
    /*  const nameUserView = (message.type == TypePersonal.GROUP && nameUser) */
    const { FILETOOLARGE, LIMITMIDIA } = TypeStatusRequest;
    const displayName = message.type == TypePersonal.GROUP && !myMessage && nameUserView && sequence;
    const isImageOrVideo = (message.fileType == TypeMessage.IMAGE) || (message.fileType == TypeMessage.VIDEO);
    const modalForward = useRef<any>();

    const directionDrop = bottomDrop ? "bottom" : "top";

    const Message = () => {
        return (
            <MsgLayout isMy={myMessage} nameUser={displayName}>
                <Linkify >
                    <div className="messageContent">
                        {description && <div className="text">{description}</div>}
                        <Document {...props} myMessage={myMessage} />
                    </div>
                </Linkify>

            </MsgLayout>
        );
    };

    const Content = () => {
        switch (message.fileType) {
            case TypeMessage.IMAGE:
            case TypeMessage.VIDEO:
                return (
                    <>
                        <ImgVideo {...props} />
                        {description && <Message />}
                    </>
                )

            case TypeMessage.VOICE:
                return <Voice {...props} myMessage={myMessage} displayName={displayName} />
            default:
                return <Message />
        }
    }
    const messageTipSpan = {
        [FILETOOLARGE]: "Arquivo muito grande.",
        [LIMITMIDIA]: `Armazenamento excedido. Apague mídias ou atualize seu pacote.`
    }

    function descriptionAnswer() {
        if (answer) {
            if (answer.description && answer.description.length > 0) {
                return <>{answer.description}</>
            } else if (answer.fileType) {
                if (answer.fileType == TypeMessage.IMAGE) {
                    return <><ItemFile icons type={answer.fileType} size={15} /> Foto</>
                } else if (answer.fileType == TypeMessage.VIDEO) {
                    return <><ItemFile icons type={answer.fileType} size={15} /> Video</>
                } else if (answer.fileName) {
                    return <><ItemFile icons type={answer.fileType} size={15} /> {answer.fileName.split('-')[1]}</>
                }
            }
        }
    }

    function answerImage() {
        if (answer) {
            return answer.fileType == TypeMessage.IMAGE || answer.fileType == TypeMessage.VIDEO
        }
        return false
    }

    function getNameDisplay(answer: TMessage) {
        if (abare.id === answer.sendBy) {
            return "Você"
        }
        if (answer.type == TypePersonal.GROUP) {
            const name = getContactName(contacts, answer.sendBy)
            return name ? name : answer.nameUser
        }
        return personal.name ? personal.name : `@${personal.nickname}`
    }

    return (
        <ContainerMessage isMy={message.sendBy == abare.id} sequence={sequence}>
            {message.status == CANCELED && <div className="alert">
                <span data-tip={messageTipSpan[message.statusRequest]} data-for="alert" tabIndex={1}>
                    <GoInfo size={20} />
                    <ReactTooltip
                        id='alert' />
                </span>
            </div>
            }


            {sequence && <Bubble isMy={myMessage} />}
            <div className="container" >
                {answer && <ContainerAnswer onClick={() => scrollTo({ key: answer.msg_id, options: { block: 'center', behavior: "smooth" }, css: false })} group={answer && answer.type == TypePersonal.GROUP && !myMessage} image={answerImage()}>
                    {
                        answerImage() && <div className="image">
                            <img src={answer.fileURL} />
                        </div>
                    }
                    <div className="title">
                        <p>{getNameDisplay(answer)}</p>
                    </div>
                    <p className="message">
                        {descriptionAnswer()}
                    </p>
                </ContainerAnswer>}
                <Content />
                <Status message={message} myMessage={message.sendBy == abare.id} />
                {((message.status == SEND || message.status == READ) && !selectMessages) &&
                    <div className="float-drop">
                        <Dropdown defaultClass={myMessage ? directionDrop + "-left" : directionDrop + "-right"} message={true}>
                            <div className={(isImageOrVideo || answerImage()) ? "round" : ""}>
                                <IoIosArrowDown id="menu" size={16} />
                            </div>
                            <div id="list-width">
                                <li className="title" onClick={() => dropMessage(message, ME)}>Apagar para mim</li>
                                {myMessage && <li className="title" onClick={() => dropMessage(message, ALL)}>Apagar para todos</li>}
                                <li className="title" onClick={() => (dispatch({ type: SET_ANSWER, payload: message }))}>Responder</li>
                                <li className="forward" onClick={() => (modalForward.current.open())}>Encaminhar</li>

                            </div>
                        </Dropdown>
                    </div>}
                {displayName && <div className="user-name">{nameUserView}</div>}
            </div>
            <Modal mobile={true} ref={modalForward}>
                <Forward {...props} modalAdd={modalForward} groupType={false} message={message} messages={null} />
            </Modal>
        </ContainerMessage>

    );
}

export default memo(MessageContent);