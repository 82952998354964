export const SET_ABARE = "SET_ABARE";
export const SET_THEME = "SET_THEME";
export const SET_LIST_FOLDER = "SET_LIST_CONVERSATION";
export const SET_LIST_ATIVA = "SET_LIST_ATIVA";
export const SET_PERSONAL = "SET_PERSONAL";
export const UPDATE_PERSONAL = "UPDATE_PERSONAL";
export const SET_GLOBAL_MESSAGES = "SET_GLOBAL_MESSAGES";
export const DESTROY_SESSION = "destroy_session";
export const SET_NEW_GLOBAL_MESSAGES = "SET_NEW_GLOBAL_MESSAGES";
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_COMMITMENT = "SET_COMMITMENT";
export const SET_NAVBAR_STATE = "SET_NAVBAR_STATE";
export const SET_DETAILS = "SET_DETAILS";
export const SET_OPTION_CONFIG = "SET_OPTION_CONFIG";
export const SET_INVITATION = "SET_INVITATION";
export const SET_FILTER_SCHEDULE = "SET_FILTER_SCHEDULE";
export const SET_DATE_SELECT_SCHEDULE = "SET_DATE_SELECT_SCHEDULE";
export const SET_COMMITMENT_DASH = "SET_COMMITMENT_DASH";
export const SET_BIRTHHDAYS_DASH = "SET_BIRTHDAYS_DASH";
export const SET_WEATHER_DASH = "SET_WEATHER_DASH";
export const SET_NAVBAR = "SET_NAVBAR";
export const SET_SOCKET = "SET_SOCKET";
export const SET_ANSWER = "SET_ANSWER";
export const SET_CONFIG = "SET_CONFIG";

//Repository
export const CLEAR_FILTERS_REPOSITORY = "CLEAR_FILTERS_REPOSITORY"; //
export const SET_TYPES_FILTER_REPOSITORY = "SET_TYPES_FILTER_REPOSITORY"; //
export const REMOVE_TYPE_FILTER_REPOSITORY = "REMOVE_TYPE_FILTER_REPOSITORY";
export const SET_SELECT_REPOSITORY = "SET_SELECT_REPOSITORY";


export const SET_NOTIFICATIONS_DASH = "SET_NOTIFICATIONS_DASH";
export const ADD_VISIBLE_CATEGORY = "ADD_VISIBLE_CATEGORY";
export const SET_CREATE_CATEGORY = "SET_CREATE_CATEGORY";

export const UPDATE_EVENT = "UPDATE_EVENT";

export const REMOVE_EVENT = "REMOVE_EVENT";
export const REMOVE_VISIBLE_CATEGORY = "REMOVE_VISIBLE_CATEGORY";
export const SET_VISIBLE_CATEGORY = "SET_VISIBLE_CATEGORY";

export const ADD_EVENT = "ADD_EVENT";

export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_STORAGECLUSTER_FOLDER = "ADD_STORAGECLUSTER_FOLDER";
export const SET_STORAGECLUSTER_FOLDER = "SET_STORAGECLUSTER_FOLDER";
export const SET_REPOSITORY_FILE_SELECTED = "SET_REPOSITORY_FILE_SELECTED";
export const SET_REPOSITORY_FOLDER_SELECTED = "SET_REPOSITORY_FOLDER_SELECTED";

export const NAVIGATION_STORAGECLUSTER_FOLDER =
  "NAVIGATION_STORAGECLUSTER_FOLDER";


export const LOAD_USER_LOADING = "REDUX_THUNK_LOAD_USER_LOADING";
export const LOAD_USER_SUCCESS = "REDUX_THUNK_LOAD_USER_SUCCESS";
export const LOAD_USER_ERROR = "REDUX_THUNK_LOAD_USER_ERROR";
