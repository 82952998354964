import { connect } from "react-redux";
import { MdOutlineTimer } from "../../../../../../assets/icons";
import { ElementoInfo } from "../style";

const Status = (props) => {

    const { status, theme } = props;

    const statusSelection = {
        "0": "Programado",
        "1": "Acontecendo",
        "2": "Encerrado",
        "3": "Cancelado",
        "4": "Suspenso",
    }


    return (
        <ElementoInfo color={theme.title == 'light' ? "grey" : "white"} border="0">
            <MdOutlineTimer />
            {statusSelection[status] ? statusSelection[status] : "Programado"}
        </ElementoInfo>
    );
}

const mapStateToProps = (state) => {
    return {
      theme: state.abare.theme
    }
  }
  
  export default connect(mapStateToProps)(Status);