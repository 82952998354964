import { IoIosClose, FiAlertTriangle } from "../../../assets/icons";
import { Container, Header, Body, Footer } from "./style";


const ModalDelete = ({ modal, title, children, buttonAction, action }) => {
    return (
        <Container>
            <Header>
                <h4 id="title">{title} </h4>
                <IoIosClose id="close" onClick={() => modal.current.close()} />
            </Header>
            <Body>
                <FiAlertTriangle id="alert" />
                {children}
            </Body>
            <Footer>
                <button onClick={() => action()}>
                    {buttonAction}
                </button>
            </Footer>
        </Container>
    );
}



export default ModalDelete;