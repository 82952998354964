import styled from "styled-components";
import { ContainerInput } from "../../../../../components/Input/style";
import { mobile } from "../../../../../components/NavBar/style";

interface ContainerProps {
  text?: string;
}

export const Container = styled.div<ContainerProps>`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-items: center;

  color: ${(props) => props.theme.texts.darkerGreyII};

  .option {
    cursor: pointer;
    font-size: 30px;
    &:hover {
      color: ${(props) => props.theme.hover.purpleDark};
    }
  }
  ${(props) =>
    props.text &&
    ` @media only screen and (max-width: ${mobile}) {
   .hidden {
    display: none;
   }
  }`};

  .record {
    display: grid;
    grid-template-columns: 40px 120px 40px;
    align-items: center;
    padding: 0px 1rem;

    #circle-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      font-size: 13px;
      padding: 0.8rem 0.8rem;
      border: 1px solid ${(props) => props.theme.default.lightI};

      #icon {
        color: yellow;
        margin-right: 3px;
        font-size: 18px;
        animation: fadeIn 1s infinite alternate;
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
      }
    }
  }
`;


interface AnswerProps {
  image?: boolean;
}
export const ContainerAnswer = styled.div<AnswerProps>`
  margin: 16px;
  position: relative;
  max-height: 100px;
  background: ${(props) => props.theme.backgrounds.answer};
  border-radius: 8px; 
  border-left: 8px solid ${(props) => props.theme.texts.purpleDark};
  display: flex;
  flex-direction: column;
  padding: 8px;

  p {
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .image{
    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 15%;
    z-index: 0;
    img{
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  } 
  .message {
    ${({ image }) => image && `margin-right: 15%; `}
    margin-bottom: 4px;
  }

  .title {
    ${({ image }) => image && `margin-right: 15%; `}
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;

    #icon {
      color: ${(props) => props.theme.texts.purpleDark};
      z-index: 3;
      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.bordes.lightGrey};
`;

export const Input = styled(ContainerInput)`
  padding: 2px;
  width: 96%;

  input,
  textarea {
    height: 22px;
    font-size: 1rem;
    /* border: 1px solid red; */
    padding: 8px;
  }
  #send {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.texts.darkerGreyI};
  }
`;

export const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.texts.darkerGreyII};

  .close {
    font-size: 22px;
    color: #b82121;
  }

  .finish {
    font-size: 28px;
    color: #008b0e;
  }

  .play {
    font-size: 20px;
    color: ${({theme}) => theme.texts.purpleDark};
  }
`; 

export const PreviewAudio = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  grid-template-rows: 70px;
  align-items: center;
  justify-items: center;
  border-top: 1px solid lightgray;
  color: ${(props) => props.theme.default.darkerI};
  padding: 0px 1rem;

  .waveform-container {
    display: grid;
    grid-template-columns: auto 1fr 100px;
    background-color: rgba(112, 5, 64, 0.15);
    font-size: 11px;
    padding: 0.5rem 0.5rem;
    align-items: center;
    border-radius: 14px;
    min-width: 90%;

    .duration {
      justify-self: center;
    }
  }

  #waveform-audio {
    cursor: pointer;
  }

  audio {
    width: 90%;
  }
`;

export const ContainerBlock = styled.div`
  display: grid;
  grid-template-rows: 55px;
  align-items: center;
  justify-items: center;
  border-top: 1px solid lightgray;
  font-size: 14px;
  color: ${(props) => props.theme.green.darker};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.default.ligtherI};
  }
`;
