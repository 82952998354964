import {
  OptionSelect,
  ReportFilterValue,
  ReportOperationFilter,
  ReportSelectFilter,
  TypeDataTable,
} from "../../types/project";

const objectString: ReportSelectFilter[] = [
  { value: ReportOperationFilter.EQUALS, label: "Igual a" },
  { value: ReportOperationFilter.CONTAIN, label: "Contém" },
  { value: ReportOperationFilter.START_WITH, label: "Começa com" },
  { value: ReportOperationFilter.END_WITH, label: "Termina com" },
  { value: ReportOperationFilter.IS_ANY_OF, label: "É algum valor" },
];

const objectNumber: ReportSelectFilter[] = [
  { value: ReportOperationFilter.LIKE, label: "=" },
  { value: ReportOperationFilter.DIFFERENT, label: "!=" },
  { value: ReportOperationFilter.LARGER, label: ">" },
  { value: ReportOperationFilter.LARGER_OR_EQUAL, label: ">=" },
  { value: ReportOperationFilter.LESS, label: "<" },
  { value: ReportOperationFilter.LESS_OR_EQUAL, label: "<=" },
  { value: ReportOperationFilter.IS_ANY_OF, label: "É algum valor" },
];

const objectDefault: ReportSelectFilter[] = [
  { value: ReportOperationFilter.EMPTY, label: "Esta vazio" },
  { value: ReportOperationFilter.NOT_EMPTY, label: "Não esta vazio" },
];
const objectBoolean: ReportSelectFilter[] = [
  { value: ReportOperationFilter.IS, label: "É" },
];

const objectDate: ReportSelectFilter[] = [
  { value: ReportOperationFilter.IS_DATA, label: "É igual" },
  { value: ReportOperationFilter.IS_NOT, label: "Não é" },
  { value: ReportOperationFilter.IS_AFTER, label: "É depois" },
  { value: ReportOperationFilter.IS_ON_OR_AFTER, label: "É igual ou depois" },
  { value: ReportOperationFilter.IS_BEFORE, label: "É antes" },
  { value: ReportOperationFilter.IS_ON_OR_BEFORE, label: "É igual ou antes" },
];

const valuesLabel = (type: TypeDataTable): ReportSelectFilter[] => {
  switch (type) {
    case TypeDataTable.BOOLEAN:
      return objectBoolean;
    case TypeDataTable.STRING:
      return objectString.concat(objectDefault);
    case TypeDataTable.NUMBER:
      return objectNumber.concat(objectDefault);
    case TypeDataTable.DATA:
    case TypeDataTable.DATA_BIRTHDAY:
      return objectDate.concat(objectDefault);
    default:
      return objectDefault;
  }
};

const filterData = (
  data: any[],
  filters: ReportFilterValue[],
  filterUnion: boolean
): any[] => {
  if (filterUnion) {
    let dataFilter: any[] = [];

    filters.forEach((filter) => {
      dataFilter = [
        ...dataFilter,
        ...data.filter(
          (value) =>
            filterValue(value, filter) &&
            !dataFilter.some((item) => item == value)
        ),
      ];
    });
    return dataFilter;
  } else {
    filters.forEach((filter) => {
      data = data.filter((value) => filterValue(value, filter));
    });
    return data;
  }
};

const filterValue = (data: any, filter: ReportFilterValue): boolean => {
  const filterValue = filter.value !== undefined;

  const isString = typeof data[filter.key] == "string";
  const isNumber = typeof data[filter.key] == "number";
  const isBool = typeof data[filter.key] == "boolean";

  let isDate = true;
  let dataDate = new Date();

  const valueString = filter.value as string;
  const valueNumber = filter.value as number;
  const valueBool = filter.value as boolean;
  const valueArray = Array.isArray(filter.value);
  let valueDate = new Date();

  try {
    valueDate = new Date(valueString);
    dataDate = new Date(data[filter.key]);
  } catch (e) {
    isDate = false;
  }

  switch (filter.type) {
    case ReportOperationFilter.IS_ANY_OF:
      if (valueArray) {
        return (filter.value as OptionSelect[]).some(
          (item) =>
            (isNumber ? parseFloat(item!.value! as string) : item.value) ===
            data[filter.key]
        );
      }
      return false;
    case ReportOperationFilter.EQUALS:
      if (isString) {
        return data[filter.key] == filter.value;
      }
      return false;
    case ReportOperationFilter.CONTAIN:
      if (filterValue && isString) {
        return (data[filter.key] as string).search(valueString) !== -1;
      }
      return false;
    case ReportOperationFilter.START_WITH:
      if (filterValue && isString) {
        return (data[filter.key] as string).startsWith(valueString);
      }
      return false;
    case ReportOperationFilter.END_WITH:
      if (filterValue && isString) {
        return (data[filter.key] as string).endsWith(valueString);
      }
      return false;
    case ReportOperationFilter.LIKE:
      if (isNumber) {
        return data[filter.key] == filter.value;
      }
      return false;
    case ReportOperationFilter.DIFFERENT:
      if (filterValue && isNumber) {
        return data[filter.key] != valueNumber;
      }
      return false;
    case ReportOperationFilter.LARGER:
      if (filterValue && isNumber) {
        return data[filter.key] > valueNumber;
      }
      return false;
    case ReportOperationFilter.LARGER_OR_EQUAL:
      if (filterValue && isNumber) {
        return data[filter.key] >= valueNumber;
      }
      return false;
    case ReportOperationFilter.LESS:
      if (filterValue && isNumber) {
        return data[filter.key] < valueNumber;
      }
      return false;
    case ReportOperationFilter.LESS_OR_EQUAL:
      if (filterValue && isNumber) {
        return data[filter.key] <= valueNumber;
      }
      return false;
    case ReportOperationFilter.IS:
      return data[filter.key] == valueBool;
    case ReportOperationFilter.IS_DATA:
      if (filterValue && isDate) {
        return valueDate.getTime() == dataDate.getTime();
      }
      return false;
    case ReportOperationFilter.IS_NOT:
      if (filterValue && isDate) {
        return valueDate.getTime() !== dataDate.getTime();
      }
      return false;
    case ReportOperationFilter.IS_AFTER:
      if (filterValue && isDate) {
        return valueDate.getTime() < dataDate.getTime();
      }
      return false;
    case ReportOperationFilter.IS_ON_OR_AFTER:
      if (filterValue && isDate) {
        return valueDate.getTime() <= dataDate.getTime();
      }
      return false;
    case ReportOperationFilter.IS_BEFORE:
      if (filterValue && isDate) {
        return valueDate.getTime() > dataDate.getTime();
      }
      return false;
    case ReportOperationFilter.IS_ON_OR_BEFORE:
      if (filterValue && isDate) {
        return valueDate.getTime() >= dataDate.getTime();
      }
      return false;
    case ReportOperationFilter.EMPTY:
      return data[filter.key] == null || data[filter.key] == undefined;
    case ReportOperationFilter.NOT_EMPTY:
      return data[filter.key] !== null && !data[filter.key] !== undefined;
    default:
      return false;
  }
};

export const ReportFilters = {
  filterData,
  valuesLabel,
};
