import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ContentImage, ContentText } from "pdfmake/interfaces";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { IoReorderThreeOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { ThemeContext } from "styled-components";
import { ContentBody } from "../../../../../../types/project";
import { HeaderTable } from "../../../../../../util/report/reportTypes";
import ImageOptions from "../../FormContent/ImageOptions";
import TableOption from "../../FormContent/TableOption";
import TextOptions from "../../FormContent/TextOptions";
import TitleOptions from "../../FormContent/TitleOptions";
import { OptionTitle } from "../../style";
import { ReportContext } from "../../../../../../contexts/ReportContext";

interface ColumnsProps {
    value: ContentBody | undefined;
    background?: boolean;
    setHeader: Dispatch<SetStateAction<HeaderTable | undefined>>;
    header: HeaderTable;
    id: string;
}

const BodyItemOption = ({ id, value, background = false, header, setHeader }: ColumnsProps) => {
    const {stylesPDF, handleStylesPDF} = useContext(ReportContext);
    const isText = value && value.text !== undefined ? true : false;
    const [valueTemp, setValueTemp] = useState<ContentBody | undefined>(value);
    const [isEdit, setIsEdit] = useState(false)
    const { title, backgrounds } = useContext(ThemeContext);
    useEffect(() => {
        closeEdit();
    }, [stylesPDF])
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        background: background ? (title == "light" ? "white" : backgrounds.lightGrey) : "initial",
        opacity: isDragging ? "0" : "1",
    };
    function saveChange() {
        if (value) {
            const index = stylesPDF.body.indexOf(value);
            if (index > -1) {
                stylesPDF.body[index] = { ...value, ...valueTemp } as ContentBody;
            }
            handleStylesPDF({ ...stylesPDF });
            setIsEdit(false);
        }
    }

    function deleteItem() {
        if (value) {
            const index = stylesPDF.body.indexOf(value);
            if (index > -1) {
                stylesPDF.body.splice(index, 1)
            }
            setIsEdit(false);
            handleStylesPDF({ ...stylesPDF });
        }
    }

    function closeEdit() {
        setValueTemp(value);
        setIsEdit(false);
    }

    return (
        <div ref={setNodeRef} style={style} >
            {id == "title" ? <TitleOptions attributes={attributes} listeners={listeners}  /> :
                id == "table" ? <TableOption attributes={attributes} listeners={listeners}  setHeader={setHeader} header={header} />

                    : value && <>
                        <OptionTitle $borderTop={true}>
                            <div className="flex">
                                <IoReorderThreeOutline className="order" {...listeners} {...attributes} />
                                <h5>{isText ? value.text : `Imagem`} </h5>

                            </div>
                            <div className="buttons">
                                {!isEdit ? <>
                                    <FiTrash className="icon-color" onClick={deleteItem} size={18} />
                                    <MdModeEdit className="icon-color" onClick={() => setIsEdit(true)} size={18} />
                                </>
                                    :
                                    <>
                                        <BsXLg className="icon-color" onClick={closeEdit} size={18} />
                                        <BsCheckLg className="icon-color" onClick={saveChange} size={18} />
                                    </>}
                                {/* <IoIosArrowUp className={isOpen ? "center down" : "center up"} onClick={() => setIsEdit(true)} size={18} /> */}
                            </div>
                        </OptionTitle>

                        {valueTemp!!.text ? <TextOptions isEditing={isEdit} valueText={valueTemp as ContentText} setValueText={setValueTemp as React.Dispatch<React.SetStateAction<ContentText>>} /> :
                            <ImageOptions isEdit={isEdit} valueImage={valueTemp as ContentImage} setValueImage={setValueTemp as React.Dispatch<React.SetStateAction<ContentImage>>} />}
                    </>
            }

        </div>
    );
}

export default BodyItemOption;