import styled from "styled-components";

export const ContainerDailyMessages = styled.div`
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.bordes.card};
  background: ${(props) => props.theme.backgrounds.primary};
  box-shadow: ${({ theme }) => theme.boxShadow.card};

  grid-column: span 1;
  grid-row: span 1;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  header {
    font-family: "inter-bold", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    line-height: normal;
    color: ${({ theme }) => theme.texts.titleCards};
  }

  .message-content {
    font-family: "inter", sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
    color: ${({ theme }) => theme.texts.contentCards};
  }

  .realce-content {
    color: #ee9b08;
    font-family: "inter-bold", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    line-height: normal;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  .container-messages {
    display: flex;
    flex-direction: row;
    width: fit-content;
    gap: 3.5rem;
  }
  .information-content {
    display: flex;
    flex-direction: column;
  }

  .line {
    width: 3px;
    background-color: #c6c6c6;
    padding: 0px;
    margin: 0px;
    height: 40px;
    border: 0;
    align-self: center;
    border-radius: 4px;
  }
  .content {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
    color: ${({ theme }) => theme.texts.contentCards};
  }

  .realce {
    font-family: "inter-bold";
    font-size: 3rem;
    font-style: normal;
    line-height: normal;
  }
  .receive-realce {
    color: ${({ theme }) => theme.texts.hintDailyCardI};
  }

  .not-read-realce {
    color: ${({ theme }) => theme.texts.hintDailyCardII};
  }

  .float-icon {
    position: absolute;
    right: -65px;
    top: 50%;
    fill: ${({ theme }) => theme.icon.cardDailyMessage} !important;

    transform: translateY(-50%);
  }
`;
