import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 56px min-content;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: ${(props) => props.theme.backgrounds.purpleLightI};
`;

export const Back = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 1rem 1rem;

  h3 {
    flex: 1;
    font-size: ${(props) => props.theme.fonts.text_lg};
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
  }

  #icon-back {
    margin-right: 8px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.purpleDark};
  }
`;

export const Item = styled.div`
  background-color: ${(props) => props.theme.backgrounds.white};
  margin: 0.5rem 0rem;
  padding: 1rem 1rem 0rem 1rem;
  display: grid;
  grid-template-columns: auto 150px;
  align-items: center;
  font-size: 13px;
  font-family: "inter", sans-serif;

  h3 {
    font-size: ${(props) => props.theme.fonts.text_lg};
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  h4 {
    font-size: ${(props) => props.theme.fonts.text_base};
    margin: 0px;
    padding: 0.5rem 0rem;
    font-family: "inter", sans-serif;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  h5 {
    font-size: ${(props) => props.theme.fonts.text_xs};
    margin: 0px;
    padding: 0.5rem 0rem;
    color: ${(props) => props.theme.texts.darkGrey};
  }

  .two-columns {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
  }

  .div-voter {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }
  .border-dashed {
    border: 1px dashed ${(props) => props.theme.texts.darkGreyI};
    margin: 0rem 0rem 1rem;
  }

  #div-left {
    padding: 0rem 0.7rem 0rem 0rem;
  }
  #number-voter {
    text-align: right;
  }
`;

/* style={{border:"1px dashed"}} */
