import styled from "styled-components";


interface StyledProps {
  three?: boolean;
  edit?:boolean;
}
export const Container = styled.div<StyledProps>`
  display: grid;
  grid-template-rows: 50px 250px;
 
  ${({ three }) => three && ` grid-template-rows: 50px 50px 250px; `};
  ${({ edit }) => edit && ` grid-template-rows: 50px 180px; `};

  .scroll {
    overflow-y: auto; 
    flex: 1;
    ${({ edit }) => edit && ` justify-content: center; align-items: center; `};

  }
  .edit {
    display: flex;
    padding: 0px 8px;
    flex-direction: column;

    .perfil {
      display: flex;
      align-items: center;
    }

    .permissions {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          color: ${(props) => props.theme.texts.darkerGreyI};
        }
      }
    }
    #profile {
      margin-top: 12px;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      align-self: center;
      object-fit: cover;
    }
  }
  #icon {
    font-size: 28px;
    color: ${(props) => props.theme.default.darkI};
  }

  #icon-purple {
    font-size: 28px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }
  #name {
    margin: 0;
    padding: 0px 0px 0px 4px;
    color: ${(props) => props.theme.texts.darkerGreyI};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px; 
  }
`;

export const Main = styled.div`
  overflow: hidden;
  height: 350px;

  .none{
    display: none;
  }

  .slide-left {
    animation: 0.2s slide-left;
    width: 100%;
  }

  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }

  .slide-right {
    animation: 0.2s slide-right;
    width: 100%;
  }
  @keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;

  div {
    display: flex;
    flex-direction: row;
  }

  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.texts.purpleDark};
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-right: 8px;
    cursor: pointer;

    #icon {
      color: ${(props) => props.theme.backgrounds.white};
      font-size: 24px;
    }
    :hover {
      background-color: ${(props) => props.theme.backgrounds.buttonSchedule};
      #icon {
        color: white;
      }
    }
  }

  h5 {
    justify-self: start;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr 50px;
  align-items: center;

  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;