import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  AiFillEye,
  AiFillFileAdd,
  AiFillFolder,
  AiFillFolderAdd,
  AiOutlineMenu,
} from "react-icons/ai";
import { BiGrid, BiListUl } from "react-icons/bi";
import {
  BsFillFileDiffFill,
  BsFillShareFill,
  BsThreeDots,
} from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { FiFilter, FiMoreVertical } from "react-icons/fi";
import { HiDocumentAdd, HiDownload } from "react-icons/hi";
import { IoMdArrowDropright } from "react-icons/io";
import { MdArrowDropDown, MdModeEdit, MdOutlineNoteAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import { Dropdown, ItemFile } from "../../../../../components";
import { ShimmerCard } from "../../../../../components/Shimmers";
import {
  addStorageClusterFolder,
  setRepositoryFileSelected,
} from "../../../../../store/actions/storageClusterAction";
import { FolderRepository, TypeMessage } from "../../../../../types/project";
import { EnumTypeRepository, TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import {
  getTypeNameMedia,
  getTypeRepository,
  pluralWords,
} from "../../../../../util/verification";
import { UseFilesReceiveRepository } from "../../hooks/Files/useFilesReceiveRepository";
import { UseFilesRepository } from "../../hooks/Files/useFilesRepository";
import { UseFilesSendRepository } from "../../hooks/Files/useFilesSendRepository";
import { UseFoldersReceiveRepository } from "../../hooks/Folders/useFoldersReceiveRepository";
import { UseFoldersRepository } from "../../hooks/Folders/useFoldersRepository";
import { UseFoldersSendRepository } from "../../hooks/Folders/useFoldersSendRepository";
import ButtonPath from "../ButtonPath";
import FileCard from "../Card/File";
import FolderCard from "../Card/Folder";
import DetailsFile from "../DetailsFile";
import FilterToolbar from "../Filters/FilterToolbar";
import FilterTypes from "../Filters/FilterTypes";
import ModalListOptions from "../modal/ModalListOptions";
import {
  ChatContainer,
  Container,
  FileContent,
  GridContainer,
  Margin,
  SemiTitle,
  Title,
} from "./styles";
import { VscNewFile, VscNewFolder } from "react-icons/vsc";
import PathFile from "./PathFile";
import NewFile from "../Sidebar/NewFile";
import { Table } from "../Table";

const LoadingShimmers = ({ height, marginBottom }) => {
  return (
    <>
      <ShimmerCard height={height} marginBottom={marginBottom} />
      <ShimmerCard height={height} marginBottom={marginBottom} />
      <ShimmerCard height={height} marginBottom={marginBottom} />
      <ShimmerCard height={height} marginBottom={marginBottom} />
    </>
  );
};

const unionFolderMedia = (listMedia: any[], listCluster: any[]) => {
  return [
    ...listCluster.map((item) => {
      return { ...item, fileType: TypeMessage.FOLDER };
    }),
    ...listMedia,
  ];
};

// export const filterDataRepository = ()

export const getSource = (type: EnumTypeRepository): FolderRepository => {
  return {
    id: "",
    filesNumber: 0,
    size: 0,
    color: "#A9A9A9",
    name: getTypeRepository(type),
    usersSharing: [],
    sharing: 0,
    createdAt: new Date(),
    editedAt: new Date(),
    invite: null,
    StorageCluster_id: null,
    User_id: "",
  };
};
function drive() {
  const { folder, fileSelected, filters, typeSelect, itemSelected } =
    useSelector((state: TypeConstRedux) => state.repository);
  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const raiz = getSource(typeSelect);

  const [isVisualList, setIsVisualList] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const queryMedia = UseFilesRepository();
  const queryMediaReceive = UseFilesReceiveRepository();
  const queryMediaSend = UseFilesSendRepository();
  const queryClusterReceive = UseFoldersReceiveRepository();
  const queryClusterSend = UseFoldersSendRepository();

  const { folders, isLoading, isSuccess, isError } = UseFoldersRepository();

  const itensList =
    isError || queryMedia.isError || isLoading || queryMedia.isLoading
      ? []
      : unionFolderMedia(queryMedia.files ?? [], folders ?? []);

  const handleFileSelected = (file) => {
    dispatch(setRepositoryFileSelected(file));
  };

  const handleFolder = (folder) => {
    dispatch(addStorageClusterFolder(folder));
  };

  const handleRowClick = (params: GridRowParams, event: React.MouseEvent) => {
    if (params.row.fileURL) {
      handleFileSelected(params.row);
    } else {
      handleFolder(params.row);
    }
  };

  useEffect(() => {
    if (isError || queryMedia.isError) {
      ToastModel(
        addToast,
        "Erro de conexão!",
        "Error ao carregar pastas.",
        "error"
      );
    }
  }, [isError, queryMedia.isError]);

  const handleMenu = () => setOpenMenu(true);

  return (
    <ChatContainer
      id="modal-repository"
      rightContent={itemSelected !== undefined}
    >
      <Container>
        <Title>
          <div className="flex-row flex-center">
            <AiOutlineMenu id="icon-menu" size={24} onClick={handleMenu} />

            <span>
              <PathFile />
            </span>
          </div>
          <div className="flex-row center">
            <div className="dropdown">
              <Dropdown widthUl="765px" defaultClass="bottom-left">
                <FiFilter />
                <FilterTypes />
              </Dropdown>
            </div>

            {isVisualList ? (
              <BiListUl
                title="Layout de lista"
                className={`icon ${isVisualList ? `selected` : ``}`}
                onClick={() => setIsVisualList(false)}
              />
            ) : (
              <BiGrid
                title="Layout de grade"
                className={`icon ${!isVisualList ? `selected` : ``}`}
                onClick={() => setIsVisualList(true)}
              />
            )}
            <NewFile />
          </div>
        </Title>
        <FilterToolbar />
        {isVisualList ? (
          <Table
            isLoading={queryMedia.isLoading && isLoading}
            itensList={itensList}
            handleRowClick={handleRowClick}
          />
        ) : (
          <FileContent>
            <Margin>
              <SemiTitle>
                <p className="main">Pastas</p>
                {isSuccess ? (
                  <p className="sub">
                    {folders.length} {pluralWords("pasta", folders.length)}
                  </p>
                ) : null}
              </SemiTitle>

              <GridContainer>
                {isLoading ? (
                  <LoadingShimmers marginBottom="16px" height="52px" />
                ) : (
                  isSuccess &&
                  folders.map((f) => (
                    <FolderCard
                      ancester={
                        folder.length ? folder[folder.length - 1] : raiz
                      }
                      folder={f}
                      key={f.id}
                    />
                  ))
                )}
              </GridContainer>
            </Margin>

            <SemiTitle>
              <p className="main">Arquivos</p>
              {queryMedia.isSuccess ? (
                <p className="sub">
                  {queryMedia.files.length}{" "}
                  {pluralWords("arquivo", queryMedia.files.length)}
                </p>
              ) : null}
            </SemiTitle>

            <GridContainer>
              {queryMedia.isLoading ? (
                <LoadingShimmers marginBottom="16px" height="225px" />
              ) : (
                queryMedia.isSuccess &&
                !queryMedia.isError &&
                queryMedia.files.map((file) => (
                  <FileCard file={file} key={file.fileName} />
                ))
              )}
            </GridContainer>
          </FileContent>
        )}
      </Container>
      <DetailsFile />
      <ModalListOptions open={openMenu} setOpen={setOpenMenu} />
    </ChatContainer>
  );
}

export default drive;
