import styled from "styled-components";

export const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.texts.darkerGreyII};
`;

interface CreatorProps {
  margin?: boolean;
}
export const Creator = styled.p<CreatorProps>`
  font-family: "inter", sans-serif;
  font-size: 12px;
  /* background: ${(props) =>
    props.theme.title === "light"
      ? props.theme.backgrounds.lightGreyIV
      : "#5a9fd11a"}; */
  border-radius: 4px;
  padding:0px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  color: ${(props) => props.theme.texts.darkerGreyI};
  white-space: nowrap;
  padding-right: 20px;
  margin: 0px;
  margin-bottom: 8px;
  ${({ margin }) => margin && `margin-top: 0.5rem;`}

  .name {
    font-weight: bold;
  }
`;

export const Description = styled.div`
  font-family: "Poppins light", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.texts.darkerGreyI};
  margin-top: 4px;
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 16px;
  justify-content: flex-start;
  div {
    flex: 1;
  }
  a {
    flex: 1;
    align-self: center;
    text-decoration: none;
  }

  .address {
    :hover {
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
`;

interface StyledProps {
  color?: string;
  justifyC?: string;
  border?: string;
  background?: string;
  marginTop?: string;
}

export const ElementoInfo = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 25px auto;
  margin-right: 8px;
  padding-right: 8px;
  white-space: normal !important;
  box-sizing: border-box;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : " ")};
  border-right: ${(props) =>
    props.border ? props.border : "1px solid #666666"};
  align-items: center;
  justify-content: ${(props) => (props.justifyC ? props.justifyC : "center")};
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: ${(props) => (props.color ? props.color : "#666666")};
  white-space: nowrap;

  @media only screen and (max-width: 760px) {
    border: none;
  }
  .red {
    color: #ff0018;
  }
`;

export const ContainerButton = styled.div<StyledProps>`
  display: flex;
  @media only screen and (max-width: 617px) {
    flex-direction: column;
  }
`;

export const ButtonElement = styled.button<StyledProps>`
  display: grid;
  grid-template-columns: 35px auto;
  padding: 0px 14px;
  margin: 0px 16px 8px 0px;
  min-height: 40px;
  border-radius: 20px;
  border: 1.5px solid ${(props) => (props.border ? props.border : "red")};
  color: ${(props) => (props.color ? props.color : "red")};
  background-color: ${(props) =>
    props.background ? props.background : "transparent"};
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  text-align: start;
  align-items: center;
  white-space: nowrap;
`;
