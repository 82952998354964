import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { ContentText } from "pdfmake/interfaces";
import { useContext, useEffect, useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoReorderThreeOutline } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { ReportStylesInterface } from "../../../../../types/project";
import { ContainerOption } from "../Blocks/VisualOptions/style";
import { OptionTitle } from "../style";
import TextOptions from "./TextOptions";
import { ReportContext } from "../../../../../contexts/ReportContext";

interface PageOptionsProps {
    listeners: SyntheticListenerMap | undefined;
    attributes: DraggableAttributes;
}

const TitleOptions = ({  listeners,attributes }: PageOptionsProps) => {
    const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
    // Estados dos componentes
    const [isEdit, setIsEdit] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    const [valueText, setValueText] = useState<ContentText>(stylesPDF.title);


    useEffect(() => {
        closeEdit()
    }, [stylesPDF])

    function saveStyle() {
        stylesPDF.title = valueText;
        handleStylesPDF({ ...stylesPDF });
        setIsEdit(false);
    }

    function closeEdit() {
        setValueText(stylesPDF.title)
        setIsEdit(false);
    }
    function editVisible() {
        handleStylesPDF({ ...stylesPDF, visibleTitle: !stylesPDF.visibleTitle });
    }
    return (
        <ContainerOption>
            <OptionTitle>
                <div className="flex">
                    <IoReorderThreeOutline className="order" {...listeners} {...attributes} />
                    <h5>Título</h5>

                </div>
                <div className="buttons">
                    {!isEdit ?
                        <>
                            {stylesPDF.visibleTitle
                                ? <FiEye className="icon-color" onClick={() => editVisible()} />
                                : <FiEyeOff className="icon-color" onClick={() => editVisible()} />}
                            <MdModeEdit className="icon-color" onClick={() => setIsEdit(true)} size={18} />

                        </>

                        :
                        <>
                            <BsXLg className="icon-color" onClick={closeEdit} size={18} />
                            <BsCheckLg className="icon-color" onClick={saveStyle} size={18} />
                        </>}
                    {/* <IoIosArrowUp className={isOpen ? "center down" : "center up"} onClick={() => setIsEdit(true)} size={18} /> */}
                </div>
            </OptionTitle>

            {
                isOpen &&
                <TextOptions isEditing={isEdit} valueText={valueText} setValueText={setValueText} />
            }

        </ContainerOption>)
}

export default TitleOptions;