import { useEffect, useState } from "react";
import { BsSearch } from '../../assets/icons';
import { isFilled } from "../../util/verification";
import { InputSearch } from "../index";

const Search = (props) => {
    const { itens, onSearch, blocked, placeholder="Pesquisar" } = props;
    const [search, setSearch] = useState('');
    useEffect(() => {
        searchLocal()
    }, [search])

    const searchLocal = () => {
        const result = itens.filter(({ name, nickname }) => {
            if (isFilled(search)) {
                if (blocked) {
                    return name?.toLowerCase()?.includes(search.toLowerCase())
                }
                else {
                    const nameSearch = name || nickname
                    return nameSearch?.toLowerCase()?.includes(search.toLowerCase())
                }
            }
            return true;
        });

        const aux = itens.map(item =>
            result.includes(item) ? { ...item, hidden: false } : { ...item, hidden: true }
        );

        onSearch(aux)
    }

    return (
        <InputSearch  Icon={BsSearch} value={search} onChange={(e) => setSearch(e.target.value)} name="search" type="text" required placeholder={placeholder} />
    );
}

export default Search;