import { RefObject, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Img from "../../../../../components/Img";
import { InputSelect, InputWithOptions } from "../../../../../components/Input";
import { ModalRef } from "../../../../../components/Modal";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import {
  StorageClusterSharingPayload,
  StorageClusterSharingService,
} from "../../../../../services/requests/storage-cluster-sharing";
import {
  StorageMediaSharingPayload,
  StorageMediaSharingService,
} from "../../../../../services/requests/storage-media-sharing";
import { getUsersByName } from "../../../../../services/requests/user";
import {
  FileRepository,
  FolderRepository,
  TClient,
  TypeStatusSharing,
} from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import { photoUrl } from "../../../../../util/verification";
import { ResultsLocal } from "../../../Conversations/ListConversation/Search";
import { DropList } from "../../../Conversations/ListConversation/Search/style";
import { Container } from "./styles";
import { UseDetailsItem } from "../../hooks/useDetailsItem";

interface ShareProps {
  modal: RefObject<ModalRef>;
  type: "file" | "folder";
  file?: FileRepository;
  folder?: FolderRepository;
}

const ModalShare = ({ modal, type, file, folder }: ShareProps) => {
  const { list } = useSelector((state: TypeConstRedux) => state.folder);
  const path = useSelector((state: TypeConstRedux) => state.repository.folder);
  const folderActual = path.length > 0 ? path[path.length - 1].id : "";
  const { abare } = useSelector((state: TypeConstRedux) => state.abare);
  const [inputActive, setInputActive] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { addToast } = useToasts();
  const inputRef = useRef<HTMLInputElement>(null);
  const id = type == "file" ? file?.fileName : folder?.id;
  const { items, isLoading, isSuccess } = UseDetailsItem(id, type);
  const [searchList, setSearchList] = useState<any[]>([]);
  const [itensSelected, setItensSelected] = useState<TClient[]>([]);
  const folderGeneral = list.find((item) => item.id === abare.id);
  const personals = folderGeneral?.itens ? folderGeneral.itens : [];
  const [name, setName] = useState("");
  const optionsShare = [
    { value: TypeStatusSharing.READER, label: "Leitor" },
    { value: TypeStatusSharing.EDITOR, label: "Editor" },
  ];
  const [optionSelected, setOptionsSelected] = useState(
    TypeStatusSharing.READER
  );
  const queryClient = useQueryClient();
  const usersPayload = itensSelected.map((item) => {
    return {
      name: item.name,
      url: photoUrl(item),
    };
  });
  const mutateFile = useMutation(
    (payload: StorageMediaSharingPayload) =>
      StorageMediaSharingService.post(payload),
    {
      onSuccess: ({ status }, data) => {
        if (status == 201) {
          queryClient.setQueryData(
            [Queries.REPOSITORY_FILES, folderActual],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.map((item) => {
                  if (item.fileName === file!.fileName) {
                    if (item.sharing < 3) {
                      return {
                        ...item,
                        usersSharing: [...item.usersSharing, ...usersPayload],
                        sharing: item.sharing + 1,
                      };
                    }
                    return { ...item, sharing: item.sharing + 1 };
                  }
                  return item;
                });
              }
              return [prevData];
            }
          );
          queryClient.invalidateQueries([
            Queries.REPOSITORY_DETAILS_ITEM,
            data.storageMediaFileName,
          ]);

          modal.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  const mutateFolder = useMutation(
    (payload: StorageClusterSharingPayload) =>
      StorageClusterSharingService.post(payload),
    {
      onSuccess: ({ status }, data) => {
        if (status == 201) {
          queryClient.invalidateQueries([
            Queries.REPOSITORY_DETAILS_ITEM,
            data.storageClusterId,
          ]);
          queryClient.setQueryData(
            [Queries.REPOSITORY_FOLDERS, folderActual],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.map((item) => {
                  if (item.id === folder?.id) {
                    if (item.sharing < 3) {
                      return {
                        ...item,
                        usersSharing: [...item.usersSharing, ...usersPayload],
                        sharing: item.sharing + 1,
                      };
                    }
                    return { ...item, sharing: item.sharing + 1 };
                  }
                  return item;
                });
              }
              return [prevData];
            }
          );
          modal.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  useEffect(() => {
    if (name.length) {
      setIsLoad(true);
      console.log(items);
      const delayDebounceFn = setTimeout(() => {
        if (name.length) {
          getUsersByName(name.toLowerCase())
            .then((res) => {
              setSearchList(
                res
                  .filter(
                    (item) =>
                      !itensSelected.some((select) => select.id == item.id) &&
                      !items.some((itemShare) => itemShare.id == item.id)
                  )
                  .map((item) => {
                    return { ...item, onClick: () => addItem(item) };
                  })
              );
            })
            .finally(() => setIsLoad(false));
        } else {
          setSearchList([]);
        }
      }, 900);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [name]);

  function saveChange() {
    if (itensSelected.length) {
      const ids = itensSelected.map((item) => item.id);

      if (type == "file") {
        const payload: StorageMediaSharingPayload = {
          User_ids: ids,
          level: optionSelected ? true : false,
          storageMediaFileName: file!!.fileName,
        };
        mutateFile.mutate(payload);
      } else {
        const payload: StorageClusterSharingPayload = {
          User_ids: ids,
          level: optionSelected ? true : false,
          storageClusterId: folder!!.id.toString(),
        };
        mutateFolder.mutate(payload);
      }
    }
  }

  const addItem = (item: TClient) => {
    itensSelected.push(item);
    setItensSelected([...itensSelected]);
    const updateSearch = searchList.filter((i) => i.id !== item.id);
    setSearchList(updateSearch);
    setName("");
    inputRef.current?.focus();
  };

  const removeItem = (item: TClient) => {
    const updateItens = itensSelected.filter((i) => i.id != item.id);
    setItensSelected(updateItens);
  };

  const ItensSelecteds = () => {
    return (
      <div className="flex" style={{ width: "100%" }}>
        {itensSelected.map((item) => (
          <div style={{ width: "100%" }}>
            <div
              className="flex item_selected"
              style={{ width: "fit-content" }}
            >
              <Img className="img" src={photoUrl(item)} />
              <p className="name_item">{item.name}</p>
              <button onClick={() => removeItem(item)}>
                <AiOutlineClose onClick={() => removeItem(item)} />
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleInputFocus = () => {
    setInputActive(true);
  };

  const handleInputBlur = () => {
    setInputActive(false);
  };

  useEffect(() => {
    if (modal.current?.open) {
      inputRef.current?.focus();
    }
  }, [modal]);
  return (
    <ModalContainer
      loadRequisition={mutateFile.isLoading || mutateFolder.isLoading}
      padding={false}
      onButtonRight={saveChange}
      buttonConfirmation={true}
      title="Compartilhar"
      onClick={(e) => {
        e.stopPropagation(), modal.current?.close();
      }}
      buttonBack={true}
    >
      <Container onFocus={handleInputFocus} onBlur={handleInputBlur}>
        <InputWithOptions
          ref={inputRef}
          placeholder={itensSelected.length ? "" : "Adicione pessoas"}
          Option={ItensSelecteds}
          value={name}
          onChange={(e) => setName(e.target.value)}
        >
          <DropList
            className={inputActive && name.length > 0 ? `is_visible` : ``}
          >
            {searchList.length > 0 ||
            (name.length > 0 && (inputActive || isLoad)) ? (
              <ResultsLocal
                isLoading={isLoad}
                title={"Usuários"}
                result={searchList}
              />
            ) : null}
          </DropList>
        </InputWithOptions>
        {itensSelected.length ? (
          <InputSelect
            minWidth="100px"
            onChange={(e) => setOptionsSelected(e.value)}
            options={optionsShare}
            defaultValue={optionsShare[0]}
          />
        ) : null}
      </Container>
    </ModalContainer>
  );
};

export default ModalShare;
