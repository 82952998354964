import { AiOutlineClose } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
interface FloatingProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const FloatingButton: React.FC<FloatingProps> = ({
  children,
  isOpen,
  setIsOpen,
}) => {
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="floating-button">
      {/* {transition((style) => <animated.div style={springProps} className="floating-button__window">
        {children}
      </animated.div>)} */}
      <div className="floating-button__window">{children}</div>

      <button className="floating-button__button" onClick={handleClick}>
        {isOpen ? <AiOutlineClose /> : <MdModeEditOutline />}
      </button>
    </div>
  );
};

export default FloatingButton;
