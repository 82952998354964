import styled from "styled-components";

interface Props{
  height?: string
}
export const ContentManager = styled.div<Props>`
    display: flex;
    flex-direction: column;
    height: ${props => props.height ? props.height : "450px"} ;
    h5 {
        justify-self: start;
        margin: 0px;
        padding: 0px;
        color: ${(props) => props.theme.texts.purpleDark};
        font-size: 18px;
  }

  h3{
    font-size:  ${(props) => props.theme.fonts.text_lg};
    color: ${(props) => props.theme.texts.darkerGreyI};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .border{
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};
  }

  .pd-8{
    padding:1rem;
    padding-top:0.5rem;
  }
  .icon-trash{
    cursor: pointer;
    color: red;
  }
`

export const HeaderBack = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 1rem;
  padding-inline: 0.5rem;
  background-color: ${(props) => props.theme.backgrounds.white};
  #back {
    justify-self: start;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  gap:8px;
  margin-top: 1rem;
  padding-left:1rem;
  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }

  .icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.texts.purpleDark};
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;

    :hover {
      opacity: 0.8;
    }
    #icon {
      color: ${(props) => props.theme.backgrounds.white};
      font-size: 20px;
    }
  }
`;

export const List = styled.div` 
    overflow:auto ;
    height:100% ;

    h3{
      text-align: center;
      align-self: center;
      margin-top: 1rem;
      padding: 0px;
      color: ${(props) => props.theme.texts.purpleDark};
      font-size: 18px;
    }
`

export const ItemCategory = styled.div`
    display:flex;
    align-items:center;
    gap:15px;
    padding: 0.5rem;
    padding-inline: 1rem;
    cursor:pointer ;
    &:hover {
        background-color: ${(props) => props.theme.backgrounds.lightGrey};
  }


`
interface TPointer{
    active: boolean;
}
export const Pointer = styled.div<TPointer>`
    background: ${(props)=> props.active ? "green":"red" } ;
    border-radius: 50%;
    width:15px;
    height:15px;
`
export const Filter =  styled.div`
    display:flex ;
    margin-top: 1rem;
    padding-left:1rem;
    margin-bottom:0.5rem;
    label{
        margin-left:0.5rem;
        margin-right:1rem;
        font-size:  ${(props) => props.theme.fonts.text_sm};
        color: ${(props) => props.theme.texts.darkerGreyI};
    }
    input, label{
      cursor:pointer ;
    }
`