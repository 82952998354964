import { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { useToasts } from "react-toast-notifications";
import { Socket } from "socket.io-client";
import { NotFound, Search } from "../../../../../../../components";
import { ButtonArrow } from "../../../../../../../components/Button/style";
import { Checked } from "../../../../../../../components/Checked";
import { ShimmerCard } from "../../../../../../../components/Shimmers";
import { getUsersForAdd } from "../../../../../../../services/requests/group";
import { postAddUsersGroup } from "../../../../../../../services/requests/user-group";
import { TProfileGroup, TUserGroupCheck } from "../../../../../../../types/project";
import { ToastModel } from "../../../../../../../util/error";
import { arrayOfUsers } from "../../../../../../../util/verification";
import { Container, Header, Menu } from '../../../Role/Promote/style';


type TProps = {
    abare: any,
    groupInfo: TProfileGroup,
    socket: Socket;
    updateClient: any;
    modal: any;
}

const ParticipantsAdd = (props: TProps) => {
    const { abare, groupInfo, socket, updateClient, modal } = props;
    const [hidden, setHidden] = useState(false);
    const [users, setUsers] = useState<TUserGroupCheck[]>(null as any);
    const { addToast } = useToasts();

    useEffect(() => {
        getUsersForAdd(abare.id, groupInfo.id).then((res) => {
            if (res.status == 200) {
                setUsers(res.data)
            }
        })
    }, [])

    const addParticipants = () => {
        if (hidden) {
            const [itensIdAdd, itens]: Array<any> = [[], []];

            users.map((user) => {
                if (user.checked) {
                    itensIdAdd.push(user.id)
                    itens.push({ ...user, admin: false, checked: false })
                }
            })

            postAddUsersGroup(groupInfo.id, itensIdAdd).then((res) => {
                if (res.status == 200) {
                    addToast({
                        title: "Membro(s) adicionado(s)"
                    },
                        { appearance: 'success' });

                    addUsers(itens, itensIdAdd)
                }else if(res.status == 403){
                    ToastModel(addToast, "Limite excedido!", `O limite é ${res.data.limitUsers} pessoas. Contate o criador do grupo para assinar um pacote superior.`, "error");
                }
            })
        }
    }

    const addUsers = (itens, idsForAdd) => {
        const idsMembers = arrayOfUsers(groupInfo.participants, abare.id)

        groupInfo.participants = [...groupInfo.participants, ...itens];
        socket.emit('addGroup', { ids: idsForAdd, groupInfo });
        socket.emit("updateGroup", { groupInfo, ids: idsMembers })
        updateClient(groupInfo)
        modal.current.close();

    }

    return (
        <Container three={true}>
            <Header>
                <div> 
                    <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                    <h5>Participantes - Adicionar</h5>
                </div>
            </Header>
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    { users && setUsers(result) }
                }} />
               <ButtonArrow onClick={() => addParticipants()}>
                    <AiFillCheckCircle id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>
            </Menu>
          
            <div className="scroll">
                {users ?
                    users.length ?
                        <Checked users={users} setUsers={setUsers} setHidden={setHidden} />
                        :
                        <NotFound description="Não há contatos para adicionar" />
                    :
                    <div>
                        <ShimmerCard height={"75px"} />
                        <ShimmerCard height={"75px"} />
                    </div>
                }
            </div>
        </Container>
    );
}



export default ParticipantsAdd;