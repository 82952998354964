import styled from "styled-components";

export const ContainerProgrammingEvents = styled.div`
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.bordes.card};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
  overflow: hidden;
  background: ${({ theme }) => theme.backgrounds.primary};
  box-shadow: ${({ theme }) => theme.boxShadow.card};
  grid-column: span 1;
  grid-row: span 1;
  width: 100%;
  height: 100%;

  padding: 1.5rem 2rem;
  box-sizing: border-box;

  header {
    font-family: "inter-bold", sans-serif;
    font-size: 1.25rem;
    font-style: normal;
    line-height: normal;
    color: ${({ theme }) => theme.texts.titleCards};

  }
  main {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  .container-messages {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 75%;
    gap: 0.5rem;
  }
  .count-messages {
    color: ${({ theme }) => theme.texts.hintEventCard};
    font-family: "inter-bold", sans-serif;
    font-size: 4rem;
    font-style: normal;
    line-height: 0.75;
  }

  select {
    display: inline-flex;
    border-radius: 0.5rem;
    background: #63a0f2;
    color: white;
    padding: 0.25rem 1rem;
    width: fit-content;
    color: #fff;
    font-family: "inter-semi-bold", sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
    border: none;
  }

  select:focus-visible {
    border: none;
  }
  option {
    background-color: white;
    color: #484848;
    border-bottom: 1px solid #484848;
  }

  .information-message {
    font-family: "inter-regular", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme }) => theme.texts.contentCards};

  }
  .float-icon {
    position: absolute;
    right: -45px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
