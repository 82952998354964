import { IoAdd } from "react-icons/io5";
import { AiOutlineSend, IoIosArrowBack } from "../../../assets/icons";
import { Load } from "../../Load/style";
import { ButtonFloat, Container } from "./style";

interface FloatingProps {
    onclick?:React.MouseEventHandler<HTMLOrSVGElement> | undefined;
    position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
    mobile?: boolean;
    icon?: "back" | "send" | "load" | "add";
    size?: "default" | "large";
    type?: "button" | "submit" | "reset";
}

const Icon = (icon: "back" | "send" | "load" | "add") => {
    if (icon === "back")
        return <IoIosArrowBack className="icon" />;
    if (icon === "load")
        return <Load />
    if (icon === "add")
        return <IoAdd className="icon"/>
    else
        return <AiOutlineSend className="icon" />
}

const FloatingButton = ({ onclick = () => { }, type, mobile = true, position = "bottom-right", icon = "back", size = "default" }: FloatingProps) => {
    return (
        <Container mobile={mobile}>
            <ButtonFloat type={type} className={`${position} ${size}`} onClick={onclick}>
                {Icon(icon)}

            </ButtonFloat>
        </Container>
    );
};

export default FloatingButton;