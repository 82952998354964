import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { AiOutlineMail, BiLockAlt, BsPhone, MdModeEdit } from "../../../../../assets/icons";
import { Input, InputPassword, InputPhone, Modal } from "../../../../../components";
import ModalContainer from "../../../../../components/Modal/ModalContent";
import { patchUserData, postChangeEmail, postRegisterEmail } from "../../../../../services/requests/user";
import { setAbare } from "../../../../../store/actions/abareAction";
import { errorDefault, ToastModel } from "../../../../../util/error";
import { ButtonEdit } from "../../Components/ItemContainer/style";
import { ButtonConfirmPassWord, ButtonPassWord, HorizontalAutorizacao, ModalRow } from "../style";

const Acess = (props) => {
    const { abare, toChangeAbare, modalAcess } = props;
    const [btnEditEmailState, setBtnEditEmailState] = useState(false);
    const editEmail = abare.email ? true : false;
    const [resendMsgState, setResendMsgState] = useState(false);
    const [btnCellState, setBtnCellState] = useState(false);
    const { addToast } = useToasts();

    const sendEmailPassword = (event) => {
        event.preventDefault();
        const { email, password, confirmPassword } = event.target.elements;

        if (password.value == confirmPassword.value) {
            postRegisterEmail(abare.id, email.value, password.value).then((res) => {
                if (res.status == 200) {
                    ToastModel(addToast, 'Um email de confirmação foi enviado!', "Verifique no email cadastrado para confirmar novo acesso.", "info");
                    setBtnEditEmailState(false)
                }
                res.status == 409 &&
                    ToastModel(addToast, 'Email já está cadastrado!', "Informe outro email para alteração.", "error")
            })
        } else {
            ToastModel(addToast, 'Senhas diferentes!', "Verifique seus dados e tente novamente.", "warning")
        }
    }

    const AccessCellPhone = () => {
        const [phone, setPhone] = useState<String>(abare.phoneNumber);
        const [timer, setTimer] = useState(0);
        const [send, setSend] = useState(false);
        const [loading, setLoading] = useState(false);
        /* const [code, setCode] = useState<String>(""); */

        useEffect(() => {
            if (send && timer > 0) {
                setTimeout(() => {
                    setTimer(timer - 1);
                }, 1000);
            }
            if (timer == 0) {
                setSend(false)
            }
        }, [timer, send])

        /*   const submit = (event) => {
              event.preventDefault();
              if (phone) {
                  setLoading(true);
                  putPhoneNumber(abare.id, phone).then((res) => {
                      const status = res.status
                      if (status) {
                          if (status == 200) {
                              ToastModel(addToast, "SMS enviado com sucesso!", "Verifique o código que foi enviado para seu celular", "success");
                              setTimer(45);
                          } else {
                              const message = res.data.message.pt
                              switch (status) {
                                  case 400:
                                      ToastModel(addToast, 'Erro ao enviar o SMS!', "Informe um número de celular válido", "error")
                                      break;
                                  case 409:
                                      ToastModel(addToast, "Número já cadastrado", "Informe outro número.", "warning");
                                      break;
                                  default:
                                      errorDefault(status, addToast)
                              }
                          }
                          setSend(true);
  
                      } else {
                          ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                      }
                      setLoading(false);
                  });
              }
          } */
        const updatePhone = () => {
            const data = {
                phoneNumber: phone
            }
            patchUserData(data).then((res) => {
                if (res.status == 200) {
                    toChangeAbare({ ...abare, phoneNumber: phone })
                    ToastModel(addToast, "Número atualizado", "", "success")
                } else {
                    errorDefault(res.status, addToast)
                }
            })
        }
        /* const verifyCode = () => {
            if (code.length == 6) {
                postRegisterVerifyCode(phone, code).then((res) => {
                    const status = res.status;
                    if (status) {
                        if (status == 200) {
                            setBtnCellState(false);
                            toChangeAbare({ ...abare, phoneNumber: phone })
                            ToastModel(addToast, "Celular verificado", "Você já pode usar seu número para entrar na plataforma", "success")
                        } else {
                            ToastModel(addToast, 'Código incorreto!', "Tente novamente", "error")
                        }
                    } else {
                        ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                    }
                });
            }
        }
 */
        return (
            btnCellState ?
                <ModalRow>
                    <div className="cellItem">
                        Celular
                        <div className="text-center">
                            <div className="marginInput">
                                {/*  <form onSubmit={submit}> */}
                                <InputPhone onChange={setPhone} value={phone} disabled={send} timer={timer}
                                    loading={loading} name="phone" type="text" placeholder="Digite seu número aqui" hidden={true} />
                                {/* <div className="text">Informe o número que deseja utilizar para acessar sua conta.</div> */}
                                {/*  </form> */}
                            </div>
                            {/* <div className={"code disabled" + send}>
                                <h5>Insira o código que foi enviado para seu celular</h5>
                                <CodeSMS
                                    disabled={!send}
                                    autoFocus
                                    isNumberInput
                                    length={6}
                                    className="otpContainer"
                                    inputClassName="otpInput"
                                    onChangeOTP={(otp) => setCode(otp)}
                                />
                            </div> */}
                        </div>
                        <div className="horizontalButtons">
                            <ButtonPassWord onClick={() => setBtnCellState(false)}>
                                Cancelar
                            </ButtonPassWord>
                            <ButtonConfirmPassWord onClick={() => updatePhone()}>
                                Confirmar
                            </ButtonConfirmPassWord>
                        </div>
                    </div>
                </ModalRow>
                :
                <HorizontalAutorizacao>
                    <ModalRow>
                        Celular
                        <div className="inputs">
                            <BsPhone className="icon" size={18} />
                            <h3>{abare.phoneNumber ? abare.phoneNumber : 'Não informado'}</h3>
                        </div>
                    </ModalRow>
                    <ButtonEdit relative={true} onClick={() => { setBtnEditEmailState(false); setBtnCellState(true) }}>
                        <MdModeEdit size={18} />
                    </ButtonEdit>
                </HorizontalAutorizacao>
        )
    }

    const sendChangeEmail = (email, setResendMsgState?) => {
        postChangeEmail(email).then(res => {
            if (res.status == 201) {
                toChangeAbare({ ...abare, changeEmail: email })
                ToastModel(addToast, 'Um email de confirmação foi enviado!', "Verifique no novo email para confirmar a alteração.", "info");
                setResendMsgState ? setResendMsgState(true) : setBtnEditEmailState(false)
            } else {
                res.status == 409 &&
                    ToastModel(addToast, 'Email já está cadastrado!', "Informe outro email para alteração.", "error")
            }
        })
    }

    const EditEmail = () => {

        const changeEmail = (event) => {
            event.preventDefault();
            const email = event.target.elements.email.value;

            if (email != abare.email) {
                sendChangeEmail(email)
            } else {
                ToastModel(addToast, 'Tente outro email', "O novo email não pode ser igual ao anterior", "warning")
            }
        }
        return (
            <form onSubmit={changeEmail}>
                <ModalRow>
                    <Input Icon={AiOutlineMail} name="email" type="email" placeholder="Digite seu email" label="E-mail" required defaultValue={abare.email}></Input>
                    <div className="text">Altere o e-mail que utiliza para acessar a sua conta na plataforma.</div>
                </ModalRow>
                <div className="horizontalButtons">
                    <ButtonPassWord onClick={(event) => { event.preventDefault(); setBtnEditEmailState(false) }}>
                        Cancelar
                    </ButtonPassWord>
                    <ButtonConfirmPassWord type="submit">
                        Alterar
                    </ButtonConfirmPassWord>
                </div>
            </form>
        )
    }
    return (
        <Modal ref={modalAcess}>
            <ModalContainer title="Acessos autorizados" onClick={() => modalAcess.current.close()}>
                {btnEditEmailState ?
                    <div>
                        {editEmail ?
                            <EditEmail />
                            :
                            <form onSubmit={sendEmailPassword}>
                                <ModalRow>
                                    <Input Icon={AiOutlineMail} name="email" type="email" placeholder="Digite seu email" label="E-mail" required />
                                    <div className="text">Para finalizar o cadastro do email, é preciso definir uma senha de acesso da sua conta.</div>
                                    <InputPassword Icon={BiLockAlt} name="password" type="password" required placeholder="Digite sua nova senha" label="Nova senha" />
                                    <label className="msg">(mínimo de 8 caracteres) </label>
                                    <InputPassword Icon={BiLockAlt} name="confirmPassword" type="password" placeholder="Confirme a senha" label="Confirmar nova senha" />
                                </ModalRow>
                                <div className="horizontalButtons">
                                    <ButtonPassWord onClick={(event) => { event.preventDefault(); setBtnEditEmailState(false) }}>
                                        Cancelar
                                    </ButtonPassWord>
                                    <ButtonConfirmPassWord type="submit" >
                                        Confirmar
                                    </ButtonConfirmPassWord>
                                </div>
                            </form>
                        }
                    </div>
                    :
                    <div>
                        <HorizontalAutorizacao>
                            <ModalRow>
                                E-mail
                                <div className="inputs">
                                    <AiOutlineMail className="icon" size={18} />
                                    <input disabled defaultValue={abare.email ? abare.email : 'Não informado'} />
                                </div>
                                {abare.changeEmail && <div>
                                    <div className="text">Valide a alteração no email enviado para {abare.changeEmail} pela plataforma.</div>
                                    <a className="link" onClick={() => { sendChangeEmail(abare.changeEmail, setResendMsgState) }}>
                                        {resendMsgState ?
                                            <u>Mensagem enviada</u>
                                            :
                                            <u>Reenviar mensagem</u>
                                        }
                                    </a>
                                </div>
                                }
                            </ModalRow>
                            <ButtonEdit relative={true} onClick={() => { setBtnCellState(false); setBtnEditEmailState(true) }}>
                                <MdModeEdit size={18} />
                            </ButtonEdit>
                        </HorizontalAutorizacao>
                    </div>
                }
                <AccessCellPhone />
            </ModalContainer >
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toChangeAbare(newAbare) {
            dispatch(setAbare(newAbare));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Acess);