import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconLogout = ({
  color = "#A6446A",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M13.5717 15.7145V11.0716H7.09833C6.90889 11.0716 6.7272 10.9963 6.59325 10.8624C6.45929 10.7284 6.38403 10.5467 6.38403 10.3573C6.38403 10.1678 6.45929 9.98615 6.59325 9.85219C6.7272 9.71823 6.90889 9.64298 7.09833 9.64298H13.5717V5.00004C13.5709 4.33721 13.3073 3.70172 12.8386 3.23303C12.3699 2.76433 11.7344 2.50071 11.0716 2.5H2.50004C1.83721 2.50071 1.20172 2.76433 0.733028 3.23303C0.264333 3.70172 0.000709041 4.33721 0 5.00004V15.7145C0.000709041 16.3773 0.264333 17.0128 0.733028 17.4815C1.20172 17.9502 1.83721 18.2138 2.50004 18.2145H11.0716C11.7344 18.2138 12.3699 17.9502 12.8386 17.4815C13.3073 17.0128 13.5709 16.3773 13.5717 15.7145ZM17.5615 11.0716L15.2096 13.4238C15.0813 13.5589 15.0109 13.7387 15.0132 13.925C15.0156 14.1112 15.0907 14.2892 15.2224 14.4209C15.3541 14.5526 15.5321 14.6277 15.7183 14.6301C15.9046 14.6325 16.0844 14.562 16.2195 14.4337L19.791 10.8622C19.9248 10.7282 20 10.5466 20 10.3573C20 10.1679 19.9248 9.9863 19.791 9.85235L16.2195 6.28087C16.0844 6.15256 15.9046 6.08209 15.7183 6.08448C15.5321 6.08686 15.3541 6.16191 15.2224 6.29363C15.0907 6.42535 15.0156 6.60331 15.0132 6.78957C15.0109 6.97583 15.0813 7.15566 15.2096 7.2907L17.5615 9.64298H13.5717V11.0716H17.5615Z"
        fill="inherit"
      />
    </Icon>
  );
};

export default IconLogout;
