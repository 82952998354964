import { AxiosResponse } from "axios";
import { useCallback, useMemo, useRef } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsFillFolderFill, BsFillShareFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Dropdown, Modal } from "../../../../../../components";
import Img from "../../../../../../components/Img";
import { ModalRef } from "../../../../../../components/Modal";
import { StorageClusterService } from "../../../../../../services/requests/storage-cluster";
import {
  addStorageClusterFolder,
  setRepositoryFolderSelected,
} from "../../../../../../store/actions/storageClusterAction";
import { FolderRepository } from "../../../../../../types/project";
import { Queries } from "../../../../../../types/queries";
import { TypeConstRedux } from "../../../../../../types/redux";
import { ToastModel } from "../../../../../../util/error";
import ModalExit from "../../modal/modalConfirmation";
import ModalShare from "../../modal/modalShare";
import ModalUpdateCluster from "../../modal/modalUpdateCluster";
import { Container } from "./style";
import { FcFolder } from "react-icons/fc";
import { formatBytes, pluralWords } from "../../../../../../util/verification";

interface FolderInterface {
  folder: FolderRepository;
  ancester: FolderRepository;
}

const FolderCard = ({ folder, ancester }: FolderInterface) => {
  const shareRef = useRef<ModalRef>(null);

  const { addToast } = useToasts();
  const folderRef = useRef<ModalRef>(null);
  const confirmationRef = useRef<ModalRef>(null);
  const mock = `${formatBytes(folder.size)} - ${
    folder.filesNumber
  } ${pluralWords("arquivo", folder.filesNumber)}`;
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (id: string) => StorageClusterService.deleteById(id),
    {
      onSuccess: ({ status }: AxiosResponse) => {
        if (status == 200) {
          queryClient.setQueryData(
            [Queries.REPOSITORY_FOLDERS, ancester.id],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.filter((item) => item.id != folder.id);
              }
              return [];
            }
          );
          confirmationRef.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  const handleFolder = () => {
    dispatch(addStorageClusterFolder(folder));
  };

  const handleDelete = () => {
    if (!isLoading) {
      mutate(folder.id);
    }
  };

  const handleFileSelected = () => {
    dispatch(setRepositoryFolderSelected(folder));
  };
  const onShare = () => shareRef.current?.open();

  const openEdit = () => folderRef.current?.open();
  const openDelete = () => confirmationRef.current?.open();

  return (
    <Container onClick={handleFolder}>
      <div className="grid ">
        <div title={folder.name} className="flex flex-column">
          <BsFillFolderFill
            style={{ color: folder.color }}
            className="icon-folder"
          />
          <span className="truncate"> {folder.name}</span>
        </div>

        <div className="dropdown">
          <Dropdown
            isHoved={false}
            defaultClass="bottom-left"
            mobile={true}
            notifications={true}
            overflow={true}
          >
            <FiMoreVertical id="menu" className="buttonDrop" />
            <div className="widthLista">
              <li onClick={openEdit}>
                {" "}
                <MdModeEdit className="icon" size={18} /> Editar
              </li>

              <form>
                <li onClick={openDelete}>
                  {" "}
                  <FaTrash className="icon" size={14} /> Excluir
                </li>
              </form>

              <form>
                <li onClick={onShare}>
                  {" "}
                  <BsFillShareFill className="icon" size={14} /> Compartilhar
                </li>
              </form>
              <li onClick={handleFileSelected}>
                {" "}
                <AiFillEye className="icon" size={14} /> Ver detalhes
              </li>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="informations-cluster">
        <p className="cluster-informations">{mock}</p>
        <div className="sharing-users">
          {folder.sharing && folder.sharing > 3 ? (
            <div className="center icon-user random-color">{`+${
              folder.sharing - 3
            }`}</div>
          ) : null}

          {folder.usersSharing
            ? folder.usersSharing.map((user, index) => (
                <Img
                  key={user.id}
                  className={`icon-user ${
                    index == folder.usersSharing.length - 1 ? "no-margin" : ""
                  }`}
                  src={user.url}
                  title={user.name}
                />
              ))
            : null}
        </div>
      </div>
      <Modal mobile={true} ref={folderRef}>
        <ModalUpdateCluster folder={folder} modal={folderRef} />
      </Modal>

      <Modal mobile={true} ref={shareRef}>
        <ModalShare type="folder" modal={shareRef} folder={folder} />
      </Modal>
      <ModalExit
        action={handleDelete}
        title="Deletar arquivo"
        modalRef={confirmationRef}
      />
    </Container>
  );
};

export default FolderCard;
