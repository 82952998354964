import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useSelector } from "react-redux";
import { TypeConstRedux } from "../../../../types/redux";
import {
  DeleteSharingSendPayload,
  StorageMediaSharingService,
} from "../../../../services/requests/storage-media-sharing";
import { AxiosError, AxiosResponse } from "axios";
import { useToasts } from "react-toast-notifications";
import { ToastModel } from "../../../../util/error";
import { Queries } from "../../../../types/queries";
import {
  DeleteSharingSendCLusterPayload,
  StorageClusterSharingService,
} from "../../../../services/requests/storage-cluster-sharing";
import { FolderRepository } from "../../../../types/project";

interface UseRemoveSharing {
  mutate: UseMutateFunction<AxiosResponse<any>, AxiosError<any>, void, unknown>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export const UseRemoveSharing = (
  type: "file" | "folder",
  id: string,
  usersIds: string[],
  folder?: FolderRepository
): UseRemoveSharing => {
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const selectFunction = () => {
    if (type == "file") {
      const payload: DeleteSharingSendPayload = {
        storageMediaId: id,
        userIds: usersIds,
      };
      return StorageMediaSharingService.deleteSharingSend(payload);
    }
    const payload: DeleteSharingSendCLusterPayload = {
      storageClusterId: id,
      userIds: usersIds,
    };
    return StorageClusterSharingService.deleteSharingSend(payload);
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    selectFunction,
    {
      onSuccess: ({ status }: AxiosResponse) => {
        if (status == 200) {
          queryClient.setQueryData(
            [Queries.REPOSITORY_DETAILS_ITEM, id],
            (prevData) => {
              console.log(prevData);
              if (Array.isArray(prevData)) {
                return prevData.filter(
                  (user) => !usersIds.some((id) => id == user.id)
                );
              }
              return [];
            }
          );
          if (folder) {
            queryClient.invalidateQueries([
              Queries.REPOSITORY_FOLDERS,
              folder.StorageCluster_id ?? "",
            ]);
          }
        } else {
          ToastModel(addToast, "Erro de conexão!", "Error", "error");
        }
      },
      onError: (err: AxiosError) => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde",
          "error"
        );
      },
    }
  );
  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};
