import { useContext, useRef, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { Modal } from "../../../../../../components";
import { ReportStylesInterface } from "../../../../../../types/project";
import { HeaderTable } from "../../../../../../util/report/reportTypes";
import AddHeaderModal from "../../Modais/AddHeaderModal";
import { OptionSelected, OptionTitle } from "../../style";
import HeaderItemOption from "./HederItemOption";
import SectionOption, { ModalProps } from "./SectionOption";
import { ReportContext } from "../../../../../../contexts/ReportContext";

interface VisualBlockProps {
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderOptions = ({ setUpdate }: VisualBlockProps) => {
  const { stylesPDF } = useContext(ReportContext);
  const [isOpen, setIsOpen] = useState(false);
  const addItemRef = useRef<any>();

  const [openLeft, setOpenLeft] = useState(false);
  const [openCenter, setOpenCenter] = useState(false);
  const [openRight, setOpenRight] = useState(false);

  const modalProps: ModalProps = {
    modal: (
      <Modal mobile={true} ref={addItemRef}>
        <AddHeaderModal modal={addItemRef} />
      </Modal>
    ),
    modalItem: addItemRef,
  };
  return (
    <SectionOption title="Cabeçalho" modal={modalProps}>
      <>
        {stylesPDF.headerContent[0].length > 0 && (
          <>
            <OptionTitle
              select={openLeft}
              onClick={() => {
                setOpenLeft(!openLeft);
              }}
            >
              <IoIosArrowUp
                className={openLeft ? "center down" : "center up"}
                size={18}
              />

              <h4>Area Esquerda</h4>
            </OptionTitle>
            {openLeft && (
              <OptionSelected>
                {" "}
                {stylesPDF.headerContent[0].map((item, key) => (
                  <HeaderItemOption
                    positionHeader={0}
                    key={key}
                    position={key}
                    value={item}
                    setUpdate={setUpdate}
                  />
                ))}
              </OptionSelected>
            )}
          </>
        )}
        {stylesPDF.headerContent[1].length > 0 && (
          <>
            <OptionTitle
              select={openCenter}
              onClick={() => {
                setOpenCenter(!openCenter);
              }}
            >
              <IoIosArrowUp
                className={openCenter ? "center down" : "center up"}
                size={18}
              />

              <h4>Area Central</h4>
            </OptionTitle>
            {openCenter && (
              <OptionSelected>
                {stylesPDF.headerContent[1].map((item, key) => (
                  <HeaderItemOption
                    positionHeader={1}
                    key={key}
                    position={key}
                    value={item}
                    setUpdate={setUpdate}
                  />
                ))}
              </OptionSelected>
            )}
          </>
        )}
        {stylesPDF.headerContent[2].length > 0 && (
          <>
            <OptionTitle
              select={openRight}
              onClick={() => {
                setOpenRight(!openRight);
              }}
            >
              <IoIosArrowUp
                className={openRight ? "center down" : "center up"}
                size={18}
              />

              <h4>Area Direita</h4>
            </OptionTitle>
            {openRight && (
              <OptionSelected>
                {stylesPDF.headerContent[2].map((item, key) => (
                  <HeaderItemOption
                    positionHeader={2}
                    key={key}
                    position={key}
                    value={item}
                    setUpdate={setUpdate}
                  />
                ))}
              </OptionSelected>
            )}
          </>
        )}
      </>
    </SectionOption>
  );
};

export default HeaderOptions;
