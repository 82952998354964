import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "../../../../../../assets/icons";
import { InputSelect } from "../../../../../../components";
import { patchConfigUserGroup } from "../../../../../../services/requests/user-group";
import { TGroup, TProfileGroup } from "../../../../../../types/project";
import { TAbareInfo, TypeConstRedux } from "../../../../../../types/redux";
import { cpfOrCnpjFormat, dateFormat, getClientId, phoneNumberFormat, voterNumberFormat } from "../../../../../../util/verification";
import { Back, Container, Item } from "./style";

type TProps = {
    abare: TAbareInfo,
    groupInfo: TProfileGroup,
    client: TGroup
}
const PrivacyGroup = (props: TProps) => {
    const { abare, groupInfo, client } = props;
    const groupId = client.Group_id;
    const nameTypeGroup = groupInfo.groupType ? 'canal' : 'grupo';
    const [userGroup, setUserGroup] = useState(groupInfo?.userGroup);
    const { privacyCpf, privacyEmail, privacyName, privacyPhone, privacyVoter, privacyBirthday } = userGroup!!;
    const { goBack } = useHistory()
    
    let options = [
        { value: '0', label: 'Somente você' },
        { value: '1', label: 'Admin' },
        { value: '2', label: 'Admin e função' },
        { value: '3', label: 'Todos' },
    ];

    const opnDescriptions = [
        `Apenas você terá acesso a esta informação nesse ${nameTypeGroup}.`,
        `Apenas os administradores terão acesso.`,
        `Membros com função e administradores terão acesso.`,
        `Todos terão acesso.`
    ]


    const updatePrivacy = (data) => {
        patchConfigUserGroup(abare.id, groupId, data).then((res) => {
            if (res.status = 200) {
                const result = { ...userGroup, ...data }
                groupInfo.userGroup = result;
                setUserGroup(result)

            }
        })
    }


    return (
        <Container>
            <Back>
                <IoIosArrowBack size={22} id="icon-back" onClick={() => goBack()} />
                <h3>Visibilidade das informações </h3>
            </Back>
            <div>
                {abare.name && <Item>
                    <div id="div-left">
                        <h3>Nome</h3>
                        <h4>{abare.name}</h4>
                        <h5>{opnDescriptions[privacyName]}</h5>
                    </div>
                    <InputSelect name="relationship" options={options} defaultValue={options[privacyName]} onChange={(e) => updatePrivacy({ privacyName: e.value })} />
                </Item>}
                {abare.email && <Item>
                    <div id="div-left">
                        <h3>E-mail</h3>
                        <h4>{abare.email}</h4>
                        <h5>{opnDescriptions[privacyEmail]}</h5>
                    </div>
                    <InputSelect name="relationship" options={options} defaultValue={options[privacyEmail]} onChange={(e) => updatePrivacy({ privacyEmail: e.value })} />
                </Item>}
                {abare.phoneNumber && <Item>
                    <div id="div-left">
                        <h3>Número de telefone</h3>
                        <h4>{phoneNumberFormat(abare.phoneNumber)}</h4>
                        <h5>{opnDescriptions[privacyPhone]}</h5>
                    </div>
                    <InputSelect name="relationship" options={options} defaultValue={options[privacyPhone]} onChange={(e) => updatePrivacy({ privacyPhone: e.value })} />
                </Item>}
                {(abare.cpf|| abare.cnpj ) && <Item>
                    <div id="div-left">
                        <h3>CPF/CNPJ</h3>
                        <h4>{cpfOrCnpjFormat(abare.cnpj || abare.cpf, !abare.cnpj)}</h4>
                        <h5>{opnDescriptions[privacyCpf]}</h5>
                    </div>
                    <InputSelect name="relationship" options={options} defaultValue={options[privacyCpf]} onChange={(e) => updatePrivacy({ privacyCpf: e.value })} />
                </Item>}
                {abare.birthday && <Item>
                    <div id="div-left">
                        <h3>Data de aniversário</h3>
                        {/* <div className="two-columns">
                            <Toggle value={toogle} onChange={() => { setToogle(!toogle) }} />
                            <h4>{dateFormat(abare.birthday)}</h4>
                        </div> */}
                        <h4>{dateFormat(abare.birthday)}</h4>
                        <h5>{opnDescriptions[privacyBirthday]}</h5>
                    </div>
                    <InputSelect name="relationship" options={options} defaultValue={options[privacyBirthday]} onChange={(e) => updatePrivacy({ privacyBirthday: e.value })} />
                </Item>}

                {abare.voterNumber && <Item>
                    <div id="div-left">
                        <h3>Título de eleitor</h3>
                        <div>
                            <div className="div-voter">
                                <h4>N° Inscrição</h4>
                                <h4 id="number-voter">{voterNumberFormat(abare.voterNumber)}</h4>
                            </div>
                            <hr className="border-dashed" />
                            <div className="div-voter">
                                <h4>Zona</h4>
                                <h4 id="number-voter">{abare.voterZone}</h4>
                            </div>
                            <hr className="border-dashed" />
                            <div className="div-voter">
                                <h4>Seção</h4>
                                <h4 id="number-voter">{abare.voterSection}</h4>
                            </div>
                            <hr className="border-dashed" />
                        </div>
                        <h5>{opnDescriptions[privacyVoter]}</h5>
                    </div>
                    <InputSelect name="relationship" options={options} defaultValue={options[privacyVoter]} onChange={(e) => updatePrivacy({ privacyVoter: e.value })} />
                </Item>}
            </div>
        </Container>
    );
}


const mapStateToProps = (state: TypeConstRedux) => {
    return {
        abare: state.abare.abare,
        client: state.chat.personal,
        groupInfo: state.chat.globalMessages[getClientId(state.chat.personal)].client,
    }
}


export default connect(mapStateToProps)(PrivacyGroup);