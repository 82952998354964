import { useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { AiFillCheckCircle, BsImages, FaUsers, FiCrop, FiTrash, IoIosArrowBack } from '../../../../../assets/icons';
import { CropImage, Input, Load, Modal, TextArea } from "../../../../../components";
import { Button } from "../../../../../components/Button/style";
import InputSearchPlace from "../../../../../components/Maps/InputSearchPlace";
import { patchDataGroup } from "../../../../../services/requests/group";
import { updateGroupForAllUsers } from "../../../../../store/actions/messages/chatAction";
import { TProfileGroup, TypeImage } from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { createUrl, getClientId, isFilled } from "../../../../../util/verification";
import { Loading } from "../../../style";
import { Back, BtnFile, Container, Default, Edit, OutlineButton } from './style';


type TProps = {
    groupInfo: TProfileGroup;
    updateGroupForAllUsers: (group: TProfileGroup) => void;
}


const EditGroup = (props: TProps) => {

    const { groupInfo, updateGroupForAllUsers } = props
    const isChannel = groupInfo.groupType;
    const nameTypeGroup = groupInfo.groupType ? 'canal' : 'grupo';
    const { addToast } = useToasts();
    const fileInput = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<TypeImage>({ defaultUrl: groupInfo.photoUrl as any });
    const modalImage = useRef<any>();
    const [load, setload] = useState(false);
    const refCrop = useRef<any>(null);
    const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || '';
    const { goBack } = useHistory()
    const [address, setAddress] = useState<any>(groupInfo.address)
    const [location, setLocation] = useState<any>(null)


    const openModal = (obj?) => {
        obj && setImage({ ...image, ...obj });
        modalImage.current.open();
    }

    const handleImage = e => {
        const file = e.target.files[0];
        if (file) {
            const url = createUrl(file);
            file.size <= MAX_FILE_SIZE ?
                openModal({ mainUrl: url, file: file })
                :
                addToast({
                    title: 'Imagem muito grande',
                    description: "Tamanho máximo de 6mb"
                }, {
                    appearance: 'error'
                });
        }
    };
    const editGroup = (event) => {
        event.preventDefault()
        var { name, description } = event.target.elements;
        name = name.value;
        description = description.value;
        const isDeleteImage = Object.keys(image).length === 0;
        const descValue = isFilled(description) ? description : "";

        const isChangeInfo = (groupInfo.name != name || groupInfo.description != descValue) || isDeleteImage || image;

        if (isFilled(name) /* && isChangeInfo */) {
            setload(true)
            let dataForm = new FormData()


            dataForm.append('file', image.file);
            dataForm.append('name', name);
            dataForm.append('description', descValue);

            if (address) {
                dataForm.append('address', address);
            }
            if (location) {
                dataForm.append('geoRefLat', location.lat);
                dataForm.append('geoRefLon', location.lng);
            }
            if (image.file || isDeleteImage) {
                dataForm.append('changePhoto', "true");
            }

            patchDataGroup(groupInfo.id, dataForm).then((res) => {
                if (res.status == 200) {
                    const { photoUrl } = res.data.result;
                    addToast({
                        title: "Dados atualizados!",
                        description: "O dados do grupo foram atualizados com sucesso !",
                    }, {
                        appearance: 'success'
                    });

                    groupInfo.name = name;
                    groupInfo.description = descValue;
                    if (address) {
                        groupInfo.address = address
                    }
                    if (location) {
                        groupInfo.geoRefLat = location.lat
                        groupInfo.geoRefLon = location.lng
                    }
                    isDeleteImage ? (groupInfo.photoUrl = null) : photoUrl && (groupInfo.photoUrl = photoUrl);

                    updateGroupForAllUsers(groupInfo)
                    goBack()
                } else {
                    setload(false)
                }
            })
        }
    }

    const crop = async () => {
        const data = await refCrop.current.cropImage();
        setImage({ ...image, ...data })
        modalImage.current.close()
    }

    return (
        <Container>
            <form onSubmit={editGroup}>
                <Back>
                    <IoIosArrowBack size={22} id="icon-back" onClick={() => goBack()} />
                    <h3>Editar {nameTypeGroup}</h3>
                    <button name="buttton-check" type="submit">
                        <AiFillCheckCircle size={30} className={load ? "check" : "check show"} />
                    </button>
                </Back>
                {!load ?
                    <Edit>
                        <div className="center">
                            {(image?.newUrl || image?.defaultUrl) ?
                                <>
                                    <Default className="vertical-gradient">
                                        <img id="profile" src={image.newUrl ? image.newUrl : image.defaultUrl} />
                                    </Default>
                                    <div id="row">
                                        <OutlineButton type="button" onClick={() => openModal({ mainUrl: image.defaultUrl })}>
                                            <FiCrop id='icon' />
                                        </OutlineButton>
                                        <OutlineButton type="button" onClick={() => setImage({})}>
                                            <FiTrash id='icon' />
                                        </OutlineButton>
                                        <OutlineButton type="button" onClick={() => fileInput.current?.click()}>
                                            <BsImages id='icon' />
                                        </OutlineButton>
                                    </div>
                                </>
                                :
                                <Default className="vertical-gradient">
                                    <FaUsers size={40} />
                                    <BtnFile type="button" onClick={() => fileInput.current?.click()}><BsImages size={15} /></BtnFile>
                                </Default>
                            }
                        </div>

                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={fileInput}
                            value={""}
                            onChange={handleImage}
                            accept="image/png, image/gif, image/jpeg"
                        />

                        <Input label={`Nome do ${nameTypeGroup}`} required name="name" type="text" maxLength={40} defaultValue={groupInfo.name} placeholder="Digite o nome aqui..." autoComplete="off" />
                        <TextArea label="Descrição" name="description" maxLength={190} type="text" defaultValue={groupInfo.description} cols="6" placeholder="Descreva aqui" />

                        {!isChannel &&
                            <InputSearchPlace
                                label={true}
                                groupType={nameTypeGroup}
                                setAddress={setAddress}
                                address={address}
                                setLocation={setLocation}
                            />}

                    </Edit>
                    :
                    <Loading>
                        <div id="load">
                            <Load width="35px" />
                        </div>
                    </Loading>
                }

                <Modal title="Recortar Foto" subTitle="Arraste para ajustar a imagem" ref={modalImage}>
                    <CropImage height="280px" ref={refCrop} url={image?.mainUrl} />
                    <div className="text-center">
                        <Button type="button" onClick={() => crop()} >Ajustar Imagem</Button>
                    </div>
                </Modal>
            </form >
        </Container >
    );
}

const mapStateToProps = (state: TypeConstRedux) => {
    return {
        groupInfo: state.chat.globalMessages[getClientId(state.chat.personal)].client,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateGroupForAllUsers
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);