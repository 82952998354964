import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { DefaultTheme, ThemeProvider } from "styled-components";
import Routes from "./Routes";
import { GlobalStyle } from "./globalStyles";
import { setTheme } from "./store/actions/themeAction";
import { TypeConstRedux } from "./types/redux";

const App = (props) => {
  const {theme} = useSelector((store:TypeConstRedux)=>store.abare)
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({title:"", message:""});

  // onMessageListener()
  //   .then((payload)=>{
  //     setNotification({
  //       title: payload.notification.title,
  //       message: payload.notification.body
  //     })
  //     setShowNotification(true)
  //   })
  //   .catch((err)=>console.log("failed: ", err));

  return (  
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes />
        {/* {
          showNotification ? (
            <ReactNotification title={notification.title} message={notification.message}/>
          ) : (
            <>
            </>
          )
        }
        <Notifications/> */}
      </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.abare.theme as DefaultTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toChangeTheme(theme) {
      dispatch(setTheme(theme));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
