import { BsCheckLg, MdModeEdit } from "../../../../../assets/icons";
import { Back, ButtonEdit, Item, TitlePerfil } from "./style";

const ItemContainer = (props) => {

  const { title, children, iconName, onClick, load, isButton } = props

  return (
    <Item>
      <Back>
        {title && <TitlePerfil>{title}</TitlePerfil>}
        {isButton && (
          load ?
            <ButtonEdit onClick={onClick} ><MdModeEdit size={18} /></ButtonEdit>
            :
            <ButtonEdit active={true} type="submit"><BsCheckLg size={18} /></ButtonEdit>)
        }
      </Back>
      {children}
    </Item>
  );
};

export default ItemContainer;
