import { EnumScheduleType, TypeReduxSchedule } from "../../types/redux";
import {
  ADD_EVENT,
  ADD_VISIBLE_CATEGORY,
  REMOVE_EVENT,
  REMOVE_VISIBLE_CATEGORY,
  SET_COMMITMENT,
  SET_CREATE_CATEGORY,
  SET_DATE_SELECT_SCHEDULE,
  SET_FILTER_SCHEDULE,
  SET_INVITATION,
  SET_VISIBLE_CATEGORY,
  UPDATE_EVENT,
} from "../actions/actionTypes";

const initialState: TypeReduxSchedule = {
  commitment: null,
  invitation: null,
  filter: EnumScheduleType.PERSONAL,
  dateSelect: new Date(),
  categoriesVisible: [0, 1, 2, 3, 4],
};

const removeCategory = (state, category) => {
  const index = state.categoriesVisible.indexOf(category);
  state.categoriesVisible.splice(index, 1);
  return state.categoriesVisible;
};
const removeEvent = (state, event) => {
  const eventTarget = state.commitment.find(
    (eventState) => eventState.id == event.id
  );
  const index = state.commitment.indexOf(eventTarget);
  state.commitment.splice(index, 1);
  return state.commitment;
};

const updateEvent = (state, event) => {
  const eventTarget = state.commitment.find(
    (eventState) => eventState.id == event.id
  );
  if (eventTarget) {
    const index = state.commitment.indexOf(eventTarget);
    state.commitment[index] = { ...state.commitment[index], ...event };
  }
  return { ...state };
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_COMMITMENT:
      return {
        ...state,
        commitment: action.payload,
      };
    case UPDATE_EVENT:
      return {
        ...updateEvent(state, action.payload),
      };

    case ADD_VISIBLE_CATEGORY:
      state.categoriesVisible.push(action.payload);
      return {
        ...state,
      };
    case SET_CREATE_CATEGORY:
      if (state.categoriesVisible.some((category) => category == -1)) {
        return {
          ...state,
        };
      }
      action.payload.forEach((category) =>
        state.categoriesVisible.push(category)
      );
      state.categoriesVisible.push(-1);
      return {
        ...state,
      };
    case SET_VISIBLE_CATEGORY:
      return {
        ...state,
        categoriesVisible: action.payload,
      };
    case REMOVE_VISIBLE_CATEGORY:
      return {
        ...state,
        categoriesVisible: removeCategory(state, action.payload),
      };
    case REMOVE_EVENT:
      return {
        ...state,
        commitment: removeEvent(state, action.payload),
      };
    case SET_INVITATION:
      return {
        ...state,
        invitation: action.payload,
      };
    case ADD_EVENT:
      state.commitment.push(action.payload);
      return {
        ...state,
      };
    case SET_FILTER_SCHEDULE:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_DATE_SELECT_SCHEDULE:
      return {
        ...state,
        dateSelect: action.payload,
      };
    default:
      return { ...state };
  }
}
