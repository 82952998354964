
const defaultMessages = {
    0: { title: 'Erro!', description: "Tente novamente mais tarde." },
    404: { title: 'Sem conexão com servidor', description: "Tente novamente mais tarde." },
    500: { title: 'Erro no servidor!', description: "Tente novamente mais tarde." },
    503: { title: 'Erro 503: Serviço indisponível', description: "Tente novamente mais tarde." },
    startDownload: { title: 'Baixando...', description: "Estamos processando seu documento." },
    savedContact: { title: 'Contato adicionado!', description: "Cadastro realizado com sucesso." },
}

export const ToastMessage = (type) => {
    return defaultMessages[type] ? defaultMessages[type] : defaultMessages[0]
}

export const errorDefault = (status, addToast) => {
    switch (status) {
        case 500:
            addToast({
                title: 'Erro no servidor!',
                description: "Tente novamente mais tarde."
            },
                { appearance: 'error' });
            return true
        case 503:
            addToast({
                title: 'Erro 503: Serviço indisponível',
                description: "Tente novamente mais tarde."
            },
                { appearance: 'error' });
            return true
        default:
            return false
    }
}

export const ToastModel = (addToast, title, description, appearance : "success" | "warning" | "error" | "info") => {
    addToast({
        title,
        description
    },
        { appearance });
}