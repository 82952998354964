import { HowlOptions } from "howler";
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSound from 'use-sound';
import { LogoNoturno } from '../../assets/images';
import { callOff, callRing } from '../../assets/sounds';
import { SocketContext } from '../../services/socket';
import CallOptions from './call-options.components';
import CallVideos from './call-videos.component';
import Calling from './calling.component';
import { Container, Content, Title } from './style';
interface ICall {
  status: string;
}

const CallPage = (props) => {
  const userToCall = useLocation<any>().state?.userToCall || null;
  const [call, setCall] = useState<ICall>({ status: 'Abaré.Net' });
  const [waves, setWaves] = useState(true);
  const [muted, setMuted] = useState(false);
  const [cameraOff, setCameraOff] = useState(false);
  const [playCallRing, callRingController] = useSound<HowlOptions>(callRing, { loop: true });
  const [playCallClose] = useSound(callOff);
  const { callUser, answerCall, getMyVideo, leaveCall } = useContext(SocketContext);

  useEffect(() => {
    playCallRing();
    getMyVideo().then((res) => {
      if (userToCall) {
        callUser(userToCall, res);
      } else {
        answerCall(res);
      }
    });
    /* return () =>{
      leaveCall();
    } */
  }, []);

  useEffect(() => {

  },[])
  useEffect(() => {
    if (!waves) {
      callRingController.pause();
    } else /* if (call.status === 'chamando') */ {
      playCallRing();
    }
  }, [waves]);

  return (
    <Container>
      <Content>
        <Title>{call.status === 'Abaré.Net' ? <img className='icon_title' src={LogoNoturno} />
          : call.status}</Title>

        {call.status === 'chamando'
          && <Calling waves={waves} />}

        {call.status === 'Abaré.Net'
          && <CallVideos />}

        <CallOptions muted={muted} setMuted={setMuted}
          cameraOff={cameraOff} setCameraOff={setCameraOff} onCloseCall={
            () => {
              /* setWaves(!waves) */
              playCallClose();
              leaveCall();
              /* goBack() */
            }
          } />
      </Content>
    </Container>
  );
};

export default CallPage;