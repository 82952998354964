import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { bindActionCreators } from "redux";
import { AiFillCopy, AiOutlineBell, BiReset, BiTrashAlt, BsPersonDash, BsPersonPlus, BsPlusLg, FaBirthdayCake, FaUserCog, FaUserLock, GiKing, IoIosClose, MdLogout, MdModeEdit, MdOutlineDoNotDisturbOnTotalSilence, MdOutlineLocationOn } from '../../../../../../assets/icons';
import { Load, Modal, Toggle } from "../../../../../../components";
import ModalDelete from "../../../../../../components/Modal/ModalDelete";
import ModalExit from "../../../../../../components/Modal/ModalExit";
import ModalShare from "../../../../../../components/Modal/ModalShare";
import { getInfoGroup, getLinkGroup, patchUpdateLinkGroup } from "../../../../../../services/requests/group";
import { patchVisibleAllMessageGroup } from "../../../../../../services/requests/message-group-user";
import { deleteExitGroup, deleteGroupForUser, patchConfigUserGroup } from "../../../../../../services/requests/user-group";
import { clearConversation, dropConversation, updateClient, updateGroupForAllUsers } from "../../../../../../store/actions/messages/chatAction";
import { TProfileGroup } from "../../../../../../types/project";
import { TypeConstRedux } from "../../../../../../types/redux";
import { ToastModel } from "../../../../../../util/error";
import { photoUrl } from "../../../../../../util/verification";
import { ButtonEdit } from "../../../../Settings/Components/ItemContainer/style";
import AdminsGroup from "../Admin";
import Leaders from "../Leaders";
import ParticipantsAdd from "../Participants/Add";
import ParticipantsRemove from "../Participants/Remove";
import { Back, Button, Circle, Container, ContainerList, Item, ItemNopd, MainLoad, Office, Option, OptionNoHv, User } from './style';
import Img from "../../../../../../components/Img";
import AllBirthday from "../../../../Dashboard/components/Cards/CardBirthday/AllBirthday";

const ProfileGroup = (props) => {
    const { abare, personal, globalMessages, updateClient, socket, updateGroupForAllUsers, clearConversation, dropConversation, setPersonal, weather } = props;
    const groupId = personal?.Group_id;
    const groupInfo: TProfileGroup = globalMessages[groupId]?.client;
    const nameTypeGroup = groupInfo.groupType ? 'canal' : 'grupo';
    const isChannel = groupInfo.groupType;
    const isAdm = groupInfo?.participants?.find(user => user.id == abare.id && user.admin);
    const [modalRemoveParticipants, modalAdmins, modalAddParticipants, modalExit, modalHappyBirthday, modalLeader, modalDeleteGroup, modalClearGroup] =
        [useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>(), useRef<any>()];
    const { addToast } = useToasts();
    const { path } = useRouteMatch();
    const { push } = useHistory();
    const shareRef = useRef<any>();
    const [shareUrl, setShareUrl] = useState('');
    const exitGroup = () => {
        deleteExitGroup(groupInfo.id).then((res) => {
            if (res.status == 200) {
                addToast({
                    title: `Você saiu do grupo ${groupInfo.name} `
                },
                    { appearance: 'success' });

                groupInfo.userGroup = null;
                groupInfo.photoUrl = null;
                groupInfo.roles.map((role) => {
                    role.users = role.users.filter(user => user.id !== abare.id)
                });
                groupInfo.participants = groupInfo.participants.filter(user => user.id != abare.id)

                updateGroupForAllUsers(groupInfo)
                modalExit.current.close();
            } else if (res.status == 403) {
                ToastModel(addToast, `Você não pode sair do ${nameTypeGroup}`, `O criador do ${nameTypeGroup} não pode sair.`, 'error')
            }
        })
    }
    const copyLink = () => {
        try {
            getLinkGroup(groupInfo.id).then((res) => {
                if (res.status == 200) {
                    const url = process.env.REACT_APP_URL_WEB + "/home/conversations/dashboard/?invite=" + res.data.invite
                    setShareUrl(url);
                    if (navigator.share) {
                        navigator.share({ title: `Venha conhecer a plataforma de mensagens AbareNet\n`, url: url })
                    } else {
                        shareRef && shareRef.current!!.open()
                    }
                } else {
                    addToast({
                        title: "Error ao gerar link",
                    },
                        { appearance: 'error' });
                }
            })
        } catch (error) {
            addToast({
                title: "Error ao gerar link",
            },
                { appearance: 'error' });
            console.log("erro ao copiar", error)
        }
    }

    const resetLink = () => {
        patchUpdateLinkGroup(groupInfo.id).then((res) => {
            if (res.status == 200) {
                setShareUrl(process.env.REACT_APP_URL_WEB + "/home/conversations/dashboard/?invite=" + res.data.result.invite);
                addToast({
                    title: "Link redefinido!",
                },
                    { appearance: 'success' });
            } else {
                addToast({
                    title: "Error ao gerar link",
                },
                    { appearance: 'error' });
            }
        })
    }

    const dropGroup = () => {
        deleteGroupForUser(groupInfo.id).then((res) => {
            if (res.status == 200) {
                dropConversation(groupInfo)
            }
        })
    }

    const clearMessages = () => {
        patchVisibleAllMessageGroup(abare.id, groupInfo.id).then((res) => {
            if (res.status == 200) {
                clearConversation(groupInfo.id)
                modalClearGroup.current.close()
            }
        })
    }

    const myprops = { groupInfo, abare, isAdm, updateGroupForAllUsers };

    useEffect(() => {
        if (groupInfo.userGroup === undefined || groupInfo.isRequest) {
            getInfoGroup(abare.id, personal.Group_id).then((res) => {
                if (res.status == 200) {
                    updateClient({ ...res.data, isRequest: false })
                }
            })
        }
    }, [groupInfo.isRequest])

    const ToogleNotify = () => {
        const [toogle, setToogle] = useState(groupInfo.userGroup?.configSilence || false);
        const [disabled, setDisabled] = useState(false);

        const changeToogle = () => {
            const data = {
                configSilence: !toogle
            }
            setDisabled(true)
            patchConfigUserGroup(abare.id, groupId, data).then((res) => {
                if (res.status = 200) {
                    groupInfo.userGroup!!.configSilence = !toogle
                    setToogle(!toogle)
                } else {
                    addToast({
                        title: "Erro!",
                        description: "Não foi possível ativar/desativar as notificações do grupo .",

                    },
                        { appearance: 'error' });
                }

                setDisabled(false)
            })
        }

        return (
            <div className="div-notify">
                <OptionNoHv>
                    <AiOutlineBell id="icon" />
                    <p className="toogle-text">{toogle ? "Habilitar notificações" : "Desativar notificações"}</p>
                    <Toggle value={toogle} onChange={() => changeToogle()} disabled={disabled} />
                </OptionNoHv>
                <p className={toogle ? "toogle-status" : "toogle-status toogle-status-off"}>Silenciado</p>
            </div>
        )
    }

    const ToogleSilence = () => {
        const [toogle, setToogle] = useState(groupInfo.userGroup?.configSilence || false);
        const [disabled, setDisabled] = useState(false);

        const changeToogle = () => {
            setToogle(!toogle)
        }
        return (
            <div className="div-silence">
                <OptionNoHv>
                    <MdOutlineDoNotDisturbOnTotalSilence id="icon" />
                    <p className="toogle-text">{toogle ? "Desabilitar silenciamento" : "Silenciar grupo"}</p>
                    <Toggle value={toogle} onChange={() => changeToogle()} disabled={disabled} />
                </OptionNoHv>
                <p className="toogle-status">{toogle ? "Silenciado" : "Fique sem receber as mensagens e notificações do grupo"}</p>
            </div>
        )
    }

    return (
        groupInfo.userGroup !== undefined ?
            <Container>
                <Back>
                    <h3>Informações do {nameTypeGroup}</h3>
                    <IoIosClose id="icon" onClick={() => window.history.back()} />

                </Back>
                <div id="informations-group">
                    <div id="profile">
                        {isAdm && <ButtonEdit padding={true}><MdModeEdit onClick={() => push(path + "/editGroup")} size={18} /></ButtonEdit>}
                        <img id="img" src={photoUrl(groupInfo)} />
                        <h5 id="name">{groupInfo.name}</h5>
                        {groupInfo.userGroup && <h5 id="number-members">{groupInfo.participants.length} {groupInfo.participants.length == 1 ? "membro" : "membros"}</h5>}
                    </div>
                    {groupInfo.userGroup ?
                        <div>
                            <Item >
                                Descrição do {nameTypeGroup}
                                <p className="description">
                                    {groupInfo.description ? groupInfo.description : "Sem descrição"}
                                </p>
                            </Item>

                            {!isChannel && <ItemNopd>
                                <a href={`https://www.google.com.br/maps/dir/${(weather && weather.coord.lat) ? `${weather.coord.lat},${weather.coord.lon}` : ``}/${groupInfo.address ? groupInfo.address.replace(` `, `+`) : `${groupInfo.geoRefLat},${groupInfo.geoRefLon}`}/@`} target="_blank" rel="noopener noreferrer" className={!groupInfo.address ? 'disabled' : ''}>
                                    <Office>
                                        Localização do {nameTypeGroup}
                                    </Office>
                                    <Option data-tip data-for='maps' noHover={!groupInfo.address}>
                                        <MdOutlineLocationOn size={22} />
                                        <div className="div-option">
                                            <p className="option-text"> {groupInfo.address ? groupInfo.address : "Sem localização cadastrada"}</p>
                                        </div>
                                    </Option>
                                </a >
                            </ItemNopd>}
                            {/* <ItemNopd>
                            <Office>
                                Notificações
                            </Office>
                            <ToogleNotify />
                        </ItemNopd>
                        <ItemNopd>
                            <Office>
                                Silenciamento
                            </Office>
                            <ToogleSilence />
                        </ItemNopd> */}
                            {!isChannel && <ItemNopd onClick={() => push(path + "/privacyGroup")}>
                                <Office>
                                    Privacidade
                                </Office>
                                <Option>
                                    <FaUserLock size={22} />
                                    <div className="div-option">
                                        <p className="option-text">Visibilidade de informações</p>
                                        <p className="option-description">Defina quem pode ver os seus dados pessoais</p>
                                    </div>
                                </Option>
                            </ItemNopd>}
                            <ItemNopd onClick={() => modalAdmins.current.open()}>
                                <Office>
                                    Administração
                                </Office>
                                <Option>
                                    <FaUserCog size={22} />
                                    <div className="div-option">
                                        <p className="option-text">Administradores do {nameTypeGroup}</p>
                                    </div>
                                </Option>
                            </ItemNopd>
                            {(isAdm && !isChannel) && <ItemNopd onClick={() => modalLeader.current.open()}>
                                <Office>
                                    Líderes
                                </Office>
                                <Option>
                                    <GiKing size={22} />
                                    <div className="div-option">
                                        <p className="option-text">Associação de Membros</p>
                                    </div>
                                </Option>
                            </ItemNopd>}
                            {!isChannel && <ItemNopd onClick={() => modalHappyBirthday.current.open()}>
                                <Office>
                                    Aniversariantes
                                </Office>
                                <Option>
                                    <FaBirthdayCake size={22} />
                                    <div className="div-option">
                                        <p className="option-text">Aniversariantes do {nameTypeGroup}</p>
                                    </div>
                                </Option>
                            </ItemNopd>}
                            {/*   {groupInfo.lastMessagesWithMidia.length > 0 &&
                    <Item>
                        <div id="midia">
                            Mídia
                            <IoIosArrowForward />
                        </div>

                        <HorizontalMidia>
                            <div>
                                {groupInfo.lastMessagesWithMidia.map((media, i) =>
                                    <img key={i} src={media.fileURL} />
                                )}
                            </div>
                        </HorizontalMidia>
                    </Item>
                } */}

                            {!isChannel && <ItemNopd>
                                <Office>
                                    Funções
                                    {isAdm &&
                                        <div className="icon-circle" onClick={() => {
                                            push(path + "/addRole")
                                        }}>
                                            <BsPlusLg id="icon" />
                                        </div>}
                                </Office>
                                <ContainerList>
                                    {groupInfo.roles.map((info, i) => (
                                        <div key={i}>
                                            {<User onClick={() => push(path + `/editRole/${info.role.id}`)}>
                                                <Circle color={info.role.color} />
                                                <div className="name">{info.role.name}</div>
                                            </User>
                                            }
                                        </div>
                                    ))}
                                </ContainerList>

                            </ItemNopd>}
                            <ItemNopd>
                                <Office>
                                    Convite via link
                                </Office>
                                <Option>
                                    <AiFillCopy size={22} />
                                    <div className="div-option" onClick={() => copyLink()} >
                                        <p className="option-text">Copiar link</p>
                                    </div>
                                </Option>
                                {/*  <Option>
                            <Option>
                                <BsFillShareFill size={22} />
                                <div className="div-option">
                                    <p className="option-text">Compartilhar link</p>
                                </div>
                            </Option>
                            </Option> */}
                                <Option onClick={() => resetLink()}>
                                    <BiReset size={22} />
                                    <div className="div-option">
                                        <p className="option-text">Redefinir link</p>
                                    </div>
                                </Option>

                            </ItemNopd>
                            <ItemNopd>
                                <Office>
                                    Membros
                                    {isAdm &&
                                        <div className="two-colums">
                                            <div className="icon-circle" onClick={() => modalAddParticipants.current.open()}>
                                                <BsPersonPlus id="icon" />
                                            </div>
                                            <div className="icon-circle" onClick={() => modalRemoveParticipants.current.open()}>
                                                <BsPersonDash id="icon" />
                                            </div>
                                        </div>}
                                </Office>
                                <ContainerList>
                                    {groupInfo.participants.map((user, i) => (
                                        <User key={i} onClick={() => push(path + `/user/${user.id}`)}>
                                            <Img alt="Perfil" src={photoUrl(user)} />
                                            <div className="name">{user.name ? user.name : "@" + user.nickname}</div>
                                        </User>
                                    ))}
                                </ContainerList>

                            </ItemNopd>
                        </div>
                        :
                        <div></div>
                    }

                    <ReactTooltip id='maps'>
                        <span>Abrir rota até grupo</span>
                    </ReactTooltip>
                    {personal.lastMessage && personal.userGroup && <Button onClick={() => modalClearGroup.current.open()}>
                        <BiTrashAlt id='icon' />
                        Limpar conversa
                    </Button>}
                    {personal.userGroup == undefined && <Button onClick={() => modalDeleteGroup.current.open()}>
                        <BiTrashAlt id='icon' />
                        Excluir grupo
                    </Button>}
                    {groupInfo.userGroup && <Button onClick={() => { modalExit.current.open() }}>
                        <MdLogout id='icon' />
                        Sair do {nameTypeGroup}
                    </Button>}
                </div>
                <Modal ref={modalAddParticipants}>
                    <ParticipantsAdd {...myprops} socket={socket} updateClient={updateClient} modal={modalAddParticipants} />
                </Modal>
                <Modal ref={modalRemoveParticipants}>
                    <ParticipantsRemove {...myprops} socket={socket} modal={modalRemoveParticipants} />
                </Modal>
                <Modal ref={modalAdmins}>
                    <AdminsGroup {...myprops} modal={modalAdmins} isAdm={isAdm} />
                </Modal>
                <ModalShare modalRef={shareRef} title={`Venha conhecer a plataforma de mensagens AbareNet\n`} url={shareUrl} />
                <Modal ref={modalLeader}>
                    <Leaders {...myprops} modal={modalLeader} />
                </Modal>
                <Modal ref={modalDeleteGroup}>
                    <ModalDelete modal={modalDeleteGroup} title='Excluir grupo' buttonAction='Excluir' action={dropGroup}>
                        <p>Você está prestes a excluir o grupo <b>{groupInfo.name}</b>. </p>
                        <p>Ao confirmar, o grupo será excluido definitivamente </p>
                    </ModalDelete>
                </Modal>
                <Modal ref={modalClearGroup}>
                    <ModalDelete modal={modalClearGroup} title='Limpar conversa' buttonAction='Excluir mensagens' action={clearMessages}>
                        <p>Você está prestes a excluir todas as suas mensagens no grupo <b>{groupInfo.name}</b>. </p>
                        <p>As mensagens serão excluídas apenas para você. </p>
                    </ModalDelete>
                </Modal>
                <ModalExit title="Sair do grupo" action={exitGroup} modalRef={modalExit} />
                <Modal ref={modalHappyBirthday}>
                    <AllBirthday noTitle={true} modalAllBirthday={modalHappyBirthday} group={true} />
                </Modal>
            </Container >
            :
            <MainLoad>
                <Load width="30px" />
                <h4 id="loading">Carregando...</h4>
            </MainLoad>

    );
}


const mapStateToProps = (state: TypeConstRedux) => {
    return {
        personal: state.chat.personal,
        abare: state.abare.abare,
        globalMessages: state.chat.globalMessages,
        socket: state.socket,
        weather: state.dashboard.weather_dash,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateClient,
    updateGroupForAllUsers,
    clearConversation,
    dropConversation,
    /* setPersonal */
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGroup);