import styled from "styled-components";
import {
  colapsedCalendar,
  colapsedSchedule,
  samllNotebook,
  tablet,
} from "../../../components/NavBar/style";
import { ContainerDashBoard } from "../Dashboard/style";

interface StyledProps {
  colRight?: boolean;
  personal?: boolean;
  rightContent?: any;
}

export const ContentSchedule = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  column-gap: ${({ theme }) => theme.general.column_gap};
  overflow: hidden;
  border: none;
  .border {
    border-radius: 14px !important;
  }
  @media only screen and (max-width: ${colapsedCalendar}) {
    grid-template-columns: 1fr;

    .hidden {
      display: none;
    }
  }
  @media only screen and (max-width: ${colapsedSchedule}) {
    grid-gap: 0px;
  }

  @media only screen and (max-width: ${samllNotebook}) {
    grid-template-columns: ${(props) => props.personal && "min-content auto"};
    grid-template-columns: ${(props) =>
      props.personal && props.rightContent && "1fr"};
  }

  @media only screen and (max-width: ${tablet}) {
    grid-template-columns: 1fr;
    ${ContainerDashBoard} {
      display: none;
    }
    #no_content_message {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;

  img {
    max-width: 600px;
    width: 100%;
    height: auto;
  }
  .alert {
    display: grid;
    grid-template-rows: auto auto;

    align-content: center;
    text-align: center;

    #title {
      font-size: 60px;
      color: ${(props) => props.theme.texts.purpleDark};
      font-family: "inter-medium";
      margin: 0;
    }

    #message {
      max-width: 600px;
      font-size: 25px;
      font-family: "InterLight";
      font-weight: normal;
    }
  }
`;
