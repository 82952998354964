import {
  KeyboardEventHandler,
  forwardRef,
  memo,
  useContext,
  useState,
} from "react";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { CompactPicker } from "react-color";
import { shallowEqual } from "react-redux";
import { ThemeContext } from "styled-components";
import { FiEye, FiEyeOff } from "../../assets/icons";
import { BarraSenha, Dropdown, Load } from "../../components";
import { OptionSelect } from "../../types/project";
import {
  CheckboxContainer,
  ContainerColor,
  ContainerInput,
  ContainerInputDate,
  ContainerInputLogin,
  ContainerInputNote,
  ContainerSearch,
  ContainerSearchBlock,
  ContainerToolbar,
  HiddenCheckbox,
  Icon,
  Limit,
  MainInput,
  StyledCheckbox,
  ToolbarInput,
} from "./style";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const Input = forwardRef((props: any, ref) => {
  const {
    label,
    name,
    Icon,
    className,
    defaultValue,
    maxLength,
    inText,
    ...rest
  } = props;
  const [text, setText] = useState(defaultValue ? defaultValue : "");

  const LimitCharacter = () => {
    return maxLength ? (
      inText ? (
        <Limit>{maxLength - inText.length}</Limit>
      ) : (
        <Limit>{maxLength - text.length}</Limit>
      )
    ) : null;
  };

  return (
    <MainInput className={className ? className : ""}>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput>
        {Icon && <Icon id="icon" size={24} />}
        <input
          ref={ref}
          name={name}
          value={text}
          maxLength={maxLength}
          onChange={(e) => setText(e.target.value)}
          id={name}
          {...rest}
        />
        <LimitCharacter />
      </ContainerInput>
    </MainInput>
  );
});

const InputToolbar = forwardRef((props: any, ref) => {
  const {
    label,
    name,
    Icon,
    PosIcon,
    className,
    defaultValue,
    maxLength,
    inText,
    ...rest
  } = props;
  const [text, setText] = useState(defaultValue ? defaultValue : "");

  const LimitCharacter = () => {
    return maxLength ? (
      inText ? (
        <Limit>{maxLength - inText.length}</Limit>
      ) : (
        <Limit>{maxLength - text.length}</Limit>
      )
    ) : null;
  };

  return (
    <ToolbarInput id="inputToolbar" className={className ? className : ""}>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerToolbar>
        {Icon && <Icon id="icon" size={24} />}
        <input
          ref={ref}
          name={name}
          value={text}
          maxLength={maxLength}
          onChange={(e) => setText(e.target.value)}
          id={name}
          spellCheck="false"
          {...rest}
        />
        {PosIcon && <PosIcon id="icon" size={24} />}
      </ContainerToolbar>
    </ToolbarInput>
  );
});

const InputControlled = forwardRef<HTMLInputElement, any>((props, ref) => {
  const {
    label,
    name,
    Icon,
    className,
    defaultValue,
    maxLength,
    inText,
    inputFlex = true,
    isRequired,
    value,
    ...rest
  } = props;
  const LimitCharacter = () => {
    return maxLength ? (
      inText ? (
        <Limit>{maxLength - inText.length}</Limit>
      ) : (
        <Limit>{maxLength - value.length}</Limit>
      )
    ) : null;
  };
  return (
    <MainInput
      $marginLabel="0px"
      $required={isRequired}
      $flex={true}
      className={className ? className : ""}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput $required={isRequired}>
        {Icon && <Icon id="icon" size={24} />}
        <input
          name={name}
          maxLength={maxLength}
          id={name}
          ref={ref}
          value={value}
          spellCheck="false"
          style={inputFlex ? { flex: "1" } : {}}
          {...rest}
        />
        {maxLength ? <LimitCharacter /> : null}{" "}
      </ContainerInput>
      {isRequired && <p className="required">Campo obrigatório</p>}
    </MainInput>
  );
});

const InputControlledPdf = (props) => {
  const {
    label,
    name,
    Icon,
    className,
    defaultValue,
    maxLength,
    inText,
    inputFlex = true,
    isRequired,
    percentagem,
    report,
    ...rest
  } = props;

  return (
    <MainInput
      $required={isRequired}
      $flex={true}
      className={className ? className : ""}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput $required={isRequired}>
        {Icon && <Icon id="icon" size={24} />}
        <input
          name={name}
          maxLength={maxLength}
          id={name}
          style={report ? { color: "white" } : {}}
          {...rest}
        />
        {percentagem ? "%" : ""}
      </ContainerInput>
      {isRequired && <p className="required">Campo obrigatório</p>}
    </MainInput>
  );
};

const InputWithOptions = forwardRef<HTMLInputElement, any>((props, ref) => {
  const {
    label,
    name,
    Icon,
    className,
    defaultValue,
    maxLength,
    inText,
    inputFlex = true,
    isRequired,
    Option,
    children,
    ...rest
  } = props;

  return (
    <MainInput
      $required={isRequired}
      $flex={true}
      className={className ? className : ""}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput $required={isRequired}>
        {Icon && <Icon id="icon" size={24} />}
        {Option ? <Option /> : null}
        <input
          name={name}
          maxLength={maxLength}
          id={name}
          ref={ref}
          style={inputFlex ? { flex: "1" } : {}}
          {...rest}
        />
        {children}
      </ContainerInput>
      {isRequired && <p className="required">Campo obrigatório</p>}
    </MainInput>
  );
});

const InputLogin = (props) => {
  const {
    label,
    name,
    Icon,
    className,
    defaultValue,
    maxLength,
    isButton = false,
    ...rest
  } = props;
  const [text, setText] = useState(defaultValue ? defaultValue : "");

  const LimitCharacter = () => {
    return maxLength ? <Limit>{maxLength - text.length}</Limit> : null;
  };

  return (
    <MainInput className={className ? className : ""}>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInputLogin>
        {Icon && <Icon id="icon" size={24} />}
        <input
          name={name}
          value={text}
          maxLength={maxLength}
          onChange={(e) => setText(e.target.value)}
          id={name}
          {...rest}
        />
        <button id="send" type="submit" hidden={!isButton}>
          Enviar
        </button>
        <LimitCharacter />
      </ContainerInputLogin>
    </MainInput>
  );
};

const InputPhone = (props) => {
  const { label, name, disabled, timer, loading, hidden, ...rest } = props;
  return (
    <MainInput>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput>
        {/* <IsDisabled theme={{ status: disabled }}> */}
        <PhoneInput
          className="phone-width"
          disabled={disabled}
          international
          maxLength="17"
          defaultCountry="BR"
          name={name}
          id={name}
          {...rest}
        />
        {/* </IsDisabled> */}
        {timer == 0 ? (
          loading ? (
            <Load />
          ) : hidden ? (
            <></>
          ) : (
            <button type="submit" id="send">
              Enviar
            </button>
          )
        ) : (
          <h6 id="timer">{timer ? timer + "s" : null}</h6>
        )}
      </ContainerInput>
    </MainInput>
  );
};

const InputPasswordLogin = (props) => {
  const { label, name, type, Icon, pattern, barra, ...rest } = props;
  const [eye, setEye] = useState<Boolean>();
  const [password, setPassword] = useState("");
  const changeEye = (e) => {
    e.preventDefault();
    setEye(eye ? false : true);
  };
  return (
    <MainInput>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInputLogin>
        {Icon && <Icon id="icon" size={24} />}
        <input
          onChange={(event) => setPassword(event.target.value)}
          type={eye ? "text" : type}
          name={name}
          id={name} /* pattern=".{8,}" title="Mínimo de 8 caracteres" */
          {...rest}
          ref={(ref) => ref && eye !== undefined && ref.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
        />
        {type == "password" &&
          (eye ? (
            <FiEye onClick={changeEye} id="eye" />
          ) : (
            <FiEyeOff onClick={changeEye} id="eye" />
          ))}
      </ContainerInputLogin>
      {barra && barra == true && <BarraSenha senha={password} />}
    </MainInput>
  );
};

const InputPassword = (props) => {
  const { label, name, type, Icon, pattern, barra, ...rest } = props;
  const [eye, setEye] = useState<Boolean>();
  const [password, setPassword] = useState("");
  const changeEye = (e) => {
    e.preventDefault();
    setEye(eye ? false : true);
  };
  return (
    <MainInput>
      {label && (
        <label className="login" htmlFor={name}>
          {label}
        </label>
      )}
      <ContainerInput>
        {Icon && <Icon id="icon" size={24} />}
        <input
          onChange={(event) => setPassword(event.target.value)}
          type={eye ? "text" : type}
          name={name}
          id={name} /* pattern=".{8,}" title="Mínimo de 8 caracteres" */
          {...rest}
          ref={(ref) => ref && eye !== undefined && ref.focus()}
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
        />
        {type == "password" &&
          (eye ? (
            <FiEye onClick={changeEye} id="eye" />
          ) : (
            <FiEyeOff onClick={changeEye} id="eye" />
          ))}
      </ContainerInput>
      {barra && barra == true && <BarraSenha senha={password} />}
    </MainInput>
  );
};

const InputButton = (props) => {
  const { label, name, disabled, Icon, loading, ...rest } = props;
  return (
    <MainInput>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput>
        {Icon && <Icon id="icon" size={24} />}
        <input name={name} id={name} {...rest} />
        {loading ? (
          <Load />
        ) : (
          <button id="send" type="submit" disabled={disabled}>
            Enviar
          </button>
        )}
      </ContainerInput>
    </MainInput>
  );
};

const InputSearch = (props) => {
  const { name, Icon, ...rest } = props;
  return (
    <MainInput>
      <ContainerSearch>
        {Icon && <Icon id="icon" />}
        <input name={name} id={name} autoComplete="off" {...rest} />
      </ContainerSearch>
    </MainInput>
  );
};

const InputClear = (props) => {
  const { name, Icon, actionClear, value, ...rest } = props;
  return (
    <MainInput>
      <ContainerSearch>
        <input
          value={value}
          name={name}
          id={name}
          autoComplete="off"
          {...rest}
        />
        {Icon && value && value.length > 0 ? (
          <Icon id="icon" onClick={actionClear} />
        ) : (
          <div />
        )}
      </ContainerSearch>
    </MainInput>
  );
};

const InputSearchBlocks = (props) => {
  const { name, Icon, ...rest } = props;
  return (
    <MainInput>
      <ContainerSearchBlock>
        {Icon && <Icon id="icon" />}
        <input name={name} id={name} autoComplete="off" {...rest} />
      </ContainerSearchBlock>
    </MainInput>
  );
};

const InputTitleNote = (props) => {
  const { name, Icon, ...rest } = props;
  return (
    <ContainerInputNote>
      <input name={name} id={name} autoComplete="off" {...rest} />
    </ContainerInputNote>
  );
};

const MaskedInput = (props) => {
  const { name, value, onChange, mask, placeholder, defaultValue, ...rest } =
    props;

  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        value: onlyNumbers(event.target.value),
      },
    });
  }

  return (
    <MainInput>
      <ContainerSearch>
        <InputMask
          name={name}
          value={value}
          autoFocus={true}
          placeholder={placeholder}
          onChange={handleChange}
          mask={mask}
          defaultValue={defaultValue}
          {...rest}
        />
      </ContainerSearch>
    </MainInput>
  );
};

const TextArea = (props) => {
  const { label, name, $flex, ...rest } = props;
  return (
    <MainInput $flex={$flex}>
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput>
        <textarea ro name={name} {...rest}></textarea>
      </ContainerInput>
    </MainInput>
  );
};

const InputDate = (props) => {
  const { name, width, ...rest } = props;
  return (
    <MainInput>
      <ContainerInputDate width={width}>
        <input name={name} autoComplete="off" {...rest} />
      </ContainerInputDate>
    </MainInput>
  );
};

interface InputColorProps {
  name?: string;
  label?: string;
  value: string;
  onChange: (color: any) => void;
}
const InputColor = ({
  name,
  label,
  value,
  onChange,
  ...rest
}: InputColorProps) => {
  return (
    <MainInput $marginLabel="0px">
      {label && <label htmlFor={name}>{label}</label>}
      <ContainerInput>
        <Dropdown defaultClass="bottom-left" inputColor={true}>
          <ContainerColor margin={true} color={value} />
          <CompactPicker color={value} onChange={onChange} />
        </Dropdown>
      </ContainerInput>
    </MainInput>
  );
};
interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  line?: boolean;
  color?: string;
}
const arePropsEqual = (prevProps, nextProps) => {
  return shallowEqual(prevProps, nextProps);
};

const Checkbox = memo(
  ({ checked, line = false, color, ...rest }: CheckboxProps) => {
    return (
      <CheckboxContainer $color={color}>
        <HiddenCheckbox {...rest} type="checkbox" checked={checked} />
        <StyledCheckbox
          $color={color}
          className={`${checked ? `checked` : `noChecked`}`}
        >
          <Icon viewBox={line ? "-2 -7 28 29" : "-2 0 28 29"}>
            <polyline points={line ? "24 6 -5 6 " : "20 6 9 17 4 12"} />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
    );
  }
);

const styleSelect = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "rgba(37, 129, 196, 0.1)",
    padding: "0.2rem",
    boxShadow: "none",
    border: state.isFocused ? "1px solid  #700540" : "1px solid  #DCDCDC",
    fontSize: "13px",
    "&:hover": {
      borderColor: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.isFocused ? "white" : "black",
    backgroundColor: state.isFocused ? "#700540" : "white",
    borderBottom: "1px solid #EDEDED",
    "&:active": {
      color: "black",
      backgroundColor: "#FDCDE0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    margin: "0px",
    fontSize: "13px",
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: "inter",
    fontSize: "13px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
};

const styleSelectDark = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "rgba(37, 129, 196, 0.1)",
    padding: "0.2rem",
    boxShadow: "none",
    border: state.isFocused ? "1px solid  #fff" : "1px solid  #848383",
    fontSize: "13px",
    "&:hover": {
      borderColor: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.isFocused ? "white" : "black",
    backgroundColor: state.isFocused ? "#DF6A00" : "white",
    borderBottom: "1px solid #EDEDED",
    "&:active": {
      color: "black",
      backgroundColor: "#FDCDE0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    margin: "0px",
    fontSize: "13px",
  }),
  input: (styles) => ({
    ...styles,

    fontFamily: "inter",
    fontSize: "13px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "13px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#fff",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
};

const styleSelectSchedule = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    padding: "1px",
    minWidth: "175px",
    width: "auto",
    height: "auto",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    border: state.isFocused && "1px solid#666666",
    boxSizing: "border-box",
    borderRadius: "16px",
    fontFamily: "Poppins light, sans-serif",
    fontSize: "14px",
    color: "#666666",
    "&:hover": {
      borderColor: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.isFocused ? "white" : "black",
    backgroundColor: state.isFocused ? "#700540" : "white",
    borderBottom: "1px solid #EDEDED",
    "&:active": {
      color: "black",
      backgroundColor: "#FDCDE0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    margin: "0px",
    fontSize: "14px",
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: "inter",
    fontSize: "14px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "14px",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
};

const styleSelectScheduleDark = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    padding: "1px",
    minWidth: "175px",
    width: "auto",
    height: "auto",
    boxShadow: "none",
    border: state.isFocused && "1px solid#666666",
    boxSizing: "border-box",
    borderRadius: "16px",
    fontFamily: "Poppins light, sans-serif",
    fontSize: "14px",
    color: "#666666",
    "&:hover": {
      borderColor: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.isFocused ? "white" : "black",
    backgroundColor: state.isFocused ? "#DF6A00" : "white",
    borderBottom: "1px solid #EDEDED",
    "&:active": {
      color: "black",
      backgroundColor: "#FDCDE0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    margin: "0px",
    fontSize: "14px",
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: "inter",
    fontSize: "14px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "14px",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
};

const styleChannelCss = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "white",
    padding: "1px",
    minWidth: "175px",
    width: "auto",
    height: "auto",
    boxShadow: "none",
    border: state.isFocused && "1px solid#666666",
    boxSizing: "border-box",
    borderRadius: "16px",
    fontFamily: "Poppins light, sans-serif",
    fontSize: "14px",
    color: "#666666",
    "&:hover": {
      borderColor: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    color: state.isFocused ? "white" : "black",
    backgroundColor: state.isFocused ? "#DF6A00" : "white",
    borderBottom: "1px solid #EDEDED",
    "&:active": {
      color: "black",
      backgroundColor: "#FDCDE0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    margin: "0px",
    fontSize: "14px",
  }),
  input: (styles) => ({
    ...styles,
    fontFamily: "inter",
    fontSize: "14px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "14px",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 999 }),
};
const InputSelect = (props) => {
  const {
    label,
    name,
    style,
    options,
    $flex,
    noOptionsMessage,
    calendar = false,
    minWidth,
    ...rest
  } = props;
  const { title } = useContext(ThemeContext);
  const styleModeSchedule =
    title == "light" ? styleSelectSchedule : styleSelectScheduleDark;
  const styleMode = title == "light" ? styleSelect : styleSelectDark;
  let css = style ? styleModeSchedule : styleMode;
  if (calendar) {
    css = {
      ...css,
      control: (styles, state) => ({
        ...styles,
        backgroundColor: "white",
        padding: "1px",
        minWidth: "140px",
        width: "auto",
        height: "auto",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        border: state.isFocused && "1px solid#666666",
        boxSizing: "border-box",
        borderRadius: "16px",
        fontFamily: "Poppins light, sans-serif",
        fontSize: "14px",
        color: "#666666",
        "&:hover": {
          borderColor: "none",
        },
      }),
    };
  }
  css = minWidth
    ? {
        ...css,
        control: (styles, state) => ({
          ...styles,
          backgroundColor: "rgba(37, 129, 196, 0.1)",
          padding: "0.2rem",
          boxShadow: "none",
          border: state.isFocused ? "1px solid  #700540" : "1px solid  #DCDCDC",
          fontSize: "13px",
          "&:hover": {
            borderColor: "none",
          },
          minWidth: minWidth,
        }),
      }
    : css;
  return (
    <MainInput $flex={$flex}>
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        required
        menuPlacement={"auto"}
        isSearchable={false}
        name={name}
        id={name}
        styles={css}
        noOptionsMessage={() =>
          noOptionsMessage ? noOptionsMessage : "Nenhuma opção"
        }
        options={options}
        menuPortalTarget={document.body}
        {...rest}
      />
    </MainInput>
  );
};

interface InputSelectCreatable {
  inputValue: string;
  setInputValue: React.Dispatch<string>;
  value: OptionSelect[];
  setValue: React.Dispatch<React.SetStateAction<OptionSelect[]>>;
  $flex?: boolean;
  label?: string;
  name?: string;
  noOptionsMessage?: string;
  style?: boolean;
  dropdownIndicator?: boolean;
  isNumber?: boolean;
}

const InputSelectCreatable = ({
  inputValue,
  setInputValue,
  value,
  setValue,
  $flex,
  label,
  name,
  noOptionsMessage,
  style,
  dropdownIndicator = true,
  isNumber = false,
}: InputSelectCreatable) => {
  const { title } = useContext(ThemeContext);
  const styleModeSchedule =
    title == "light" ? styleSelectSchedule : styleSelectScheduleDark;
  const styleMode = title == "light" ? styleSelect : styleSelectDark;
  const css = style ? styleModeSchedule : styleMode;

  const createOption = (label: string): OptionSelect => ({
    label,
    value: label,
  });

  const componentsEdit = {
    DropdownIndicator: null,
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  function isNumberFunction(n) {
    return (!isNaN(parseFloat(n)) && isFinite(n)) || n == "" || n == "-";
  }

  return (
    <MainInput $flex={$flex}>
      {label && <label htmlFor={name}>{label}</label>}
      <CreatableSelect
        required
        isClearable
        isMulti
        inputValue={inputValue}
        components={dropdownIndicator ? {} : componentsEdit}
        onChange={(newValue) => setValue(newValue)}
        onInputChange={(newValue) => {
          if (!isNumber || isNumberFunction(newValue)) setInputValue(newValue);
        }}
        onKeyDown={handleKeyDown}
        placeholder="Pressione Enter para adicionar..."
        value={value}
        formatCreateLabel={(userInput) => `Adicionar ${userInput}`}
        styles={css}
        menuPlacement={"auto"}
        name={name}
        id={name}
        noOptionsMessage={() =>
          noOptionsMessage
            ? noOptionsMessage
            : "Adicione outra opção para busca"
        }
      />
    </MainInput>
  );
};
export {
  Checkbox,
  Input,
  InputButton,
  InputClear,
  InputColor,
  InputControlled,
  InputControlledPdf,
  InputDate,
  InputLogin,
  InputPassword,
  InputPasswordLogin,
  InputPhone,
  InputSearch,
  InputSearchBlocks,
  InputSelect,
  InputSelectCreatable,
  InputTitleNote,
  InputToolbar,
  InputWithOptions,
  MaskedInput,
  TextArea,
};
