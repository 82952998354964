import { useState } from "react";
import IconArrowDown from "../../assets/icons/IconArrowDown";
import { useDispatch } from "react-redux";
import { setOptionConfig } from "../../store/actions/configAction";
import IconDot from "../../assets/icons/IconDot";
import { ListItemText, Menu, MenuItem } from "@mui/material";
import Item from "../../pages/home/Dashboard/components/Cards/CardBirthday/Item";

interface ItemWithOptionsProps {
  text: string;
  Icon: any;
  IconFill?: any;
  subItens: any[];
  isSmall: boolean;
}

export function ItemWithOptions({
  text,
  Icon,
  IconFill,
  subItens,
  isSmall,
}: ItemWithOptionsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isTablet = window.innerWidth <= 1580;
  const isMobile = window.innerWidth <= 540;

  function onOpen(event: React.MouseEvent<HTMLDivElement>) {
    if (open) {
      setIsOpen(false);
      setAnchorEl(null);
      return;
    }
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setIsOpen(false);
    setAnchorEl(null);
  }
  return (
    <div>
      <div
        className={
          "nav-link " +
          "grid-arrow" +
          " " +
          " " +
          (isSmall || isTablet ? "nav-link-minimized" : null)
        }
        onClick={onOpen}
      >
        {" "}
        <div className="container-icons">
          {IconFill && <IconFill className="active-indicator" />}
          {Icon && <Icon className="icon icon-default" />}
        </div>
        <p className={`text ${isSmall ? "hidden" : " "}`}>{text}</p>
        {!isSmall && !isTablet ? (
          <IconArrowDown className={`arrow ${isOpen ? `up` : `down`}`} />
        ) : null}
      </div>
      <div
        style={{
          height: `${isOpen ? 200 : 0}px`,
        }}
        className={`movel `}
      >
        {!isSmall && !isTablet ? (
          subItens.map((item) => (
            <div
              key={item.text}
              className={
                "nav-link " +
                " " +
                " " +
                (isSmall ? "nav-link-minimized" : null)
              }
              onClick={() => {
                dispatch(setOptionConfig(item.value));
              }}
            >
              <div className="container-icons">
                <IconDot className="icon icon-default" />
              </div>
              <p className={`text ${isSmall ? "hidden" : " "}`}>{item.text}</p>
            </div>
          ))
        ) : (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  width: "150px",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1,
                  borderRadius: "6px",
                },
              },
            }}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            // anchorOrigin={{
            //   vertical: "top",
            //   horizontal: "right",
            // }}
            anchorOrigin={{
              vertical: "top", // Define a posição vertical como 'top'
              horizontal: "right",
            }}
            transformOrigin={
              isMobile
                ? {
                    vertical: "bottom", // Define a posição vertical como 'bottom'
                    horizontal: "right",
                  }
                : undefined
            }
          >
            <div onMouseLeave={handleClose}>
              {subItens.map((item) => (
                <MenuItem
                  sx={{
                    mx: 0.5,
                    borderRadius: "6px",
                    mb: 0.5,
                    pr: 1,
                  }}
                  onClick={() => {
                    dispatch(setOptionConfig(item.value));
                  }}
                >
                  <ListItemText>{item.text}</ListItemText>
                </MenuItem>
              ))}
            </div>
          </Menu>
        )}
      </div>
    </div>
  );
}
