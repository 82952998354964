import pt from "date-fns/locale/pt-BR";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { BsCheckLg, BsIntersect, BsUnion, BsXLg } from "react-icons/bs";
import { FaFilter, FaFolder } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { IoAdd } from "react-icons/io5";
import { MdGroup, MdModeEdit } from "react-icons/md";
import { VscCalendar } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { InputSelect, Modal } from "../../../../../components";
import { getGroups } from "../../../../../services/requests/group";
import {
  OptionSelect,
  ReportEnum,
  ReportOptionsInterface,
} from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel, errorDefault } from "../../../../../util/error";
import { ReportFunctions } from "../../../../../util/report/reportFunctions";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import AddFilterModal from "../Modais/AddFilterModal";
import { ContainerElements, Element, OptionTitle } from "../style";
import EventsOptions from "./FilterOptions/EventsOptions";
import FilterOption from "./FilterOptions/FilterOption";
import FilterItemOptions from "./VisualOptions/FilterItemOption";
import OrderingOption from "./VisualOptions/OrderingOptions";
import { ContainerOption } from "./VisualOptions/style";
import { ReportContext } from "../../../../../contexts/ReportContext";
interface ContentBlockProps {
  header: HeaderTable;
  optionsPDF: ReportOptionsInterface;
  setOptionsPDF: React.Dispatch<React.SetStateAction<ReportOptionsInterface>>;
}

const ContentBlock = ({
  header,
  optionsPDF,
  setOptionsPDF,
}: ContentBlockProps) => {
  const { optionReportSelected } = useContext(ReportContext);
  const folders = useSelector((state: TypeConstRedux) => state.folder.list);
  const hasId = !optionsPDF.requiredId || optionsPDF.key !== undefined;
  const { addToast } = useToasts();
  const { datePeriod } = optionsPDF;
  const hasOptions =
    optionsPDF.requiredId ||
    optionsPDF.filters.length > 0 ||
    optionsPDF.requiredPeriod;
  const [startDate, setStartDate] = useState<Date>(
    datePeriod ? datePeriod[0] : ReportFunctions.defaultDatePeriod[0]
  );
  const [endDate, setEndDate] = useState<Date>(
    datePeriod ? datePeriod[1] : ReportFunctions.defaultDatePeriod[0]
  );
  const [isEdit, setIsEdit] = useState(hasId);
  const eventSelected = optionReportSelected === ReportEnum.EVENT_CONFIRMATIONS;
  const [isLoading, setIsLoading] = useState(false);
  const [keySelected, setKeySelected] = useState<any | null>(null);
  const [optionsGroup, setOptionsGroup] = useState<any>([]);
  const [valuesOptions, setValueOptions] = useState<OptionSelect[][]>(
    optionsPDF.filters.map((filter) => filter.values)
  );
  const modalFilterAdd = useRef<any>();
  const [showItemsFilter, setShowItemsFilter] = useState(false);
  const [showItems, setShowItems] = useState(false);

  function saveChange() {
    if (optionsPDF.requiredId && !keySelected && !optionsPDF.key) {
      console.log(optionsPDF);
      ToastModel(addToast, "Selecione uma opção para busca", "", "warning");
    } else {
      if (optionsPDF.requiredId && !eventSelected) {
        optionsPDF.key = keySelected.value;
      }
      optionsPDF.datePeriod = [startDate, endDate];
      optionsPDF.filters = optionsPDF.filters.map((filter, index) => {
        return { ...filter, values: valuesOptions[index] };
      });
      setOptionsPDF({ ...optionsPDF });
      setIsEdit(false);
    }
  }

  function closeEdit() {
    setStartDate(datePeriod ? datePeriod[0] : new Date());
    setEndDate(datePeriod ? datePeriod[0] : new Date());
    setValueOptions(optionsPDF.filters.map((filter) => filter.values));
    setIsEdit(false);
  }

  const getData = async (type: ReportEnum | undefined) => {
    if (optionReportSelected) {
      switch (type) {
        case ReportEnum.GROUP_MEMBERS:
          // if (optionsPDF.key) {
          setIsLoading(true);
          setOptionsGroup([]);
          getGroups()
            .then((res) => {
              if (!errorDefault(res.status, addToast)) {
                console.log(res);
                setOptionsGroup(
                  res.data.map((group) => {
                    return { value: group.Group_id, label: group.name };
                  })
                );
              }
            })
            .finally(() => setIsLoading(false));
          // }
          break;
        case ReportEnum.GROUP_ALL:
          // if (optionsPDF.key) {
          setOptionsGroup(
            folders
              .filter((folder, key) => {
                const hasGroup = folder.itens.some(
                  (conversation) => conversation.Group_id
                );
                return !(key == 0 || !hasGroup);
              })
              .map((folder) => {
                return { value: folder.id, label: folder.name };
              })
          );
          // }
          break;
      }
    }
  };

  useEffect(() => {
    if (optionReportSelected) {
      getData(optionReportSelected);
      setKeySelected(null);
      if (ReportFunctions.getOptions(optionReportSelected).requiredId) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [optionReportSelected]);

  registerLocale("pt", pt);
  setDefaultLocale("pt");

  type Props = {
    onClick?: () => void;
    value?: string;
  };
  type RefType = number;

  const DateCustomInput = forwardRef<RefType, Props>(
    ({ value, onClick }, ref) => (
      <input
        value={value}
        className="teste"
        onClick={onClick}
        readOnly={true}
      />
    )
  );

  const Icon = () => {
    switch (optionReportSelected) {
      case ReportEnum.GROUP_MEMBERS:
        return <MdGroup size="20px" />;
      case ReportEnum.GROUP_ALL:
        return <FaFolder size="20px" />;
      default:
        return <FaFilter size="20px" />;
    }
  };

  const hintString = () => {
    switch (optionReportSelected) {
      case ReportEnum.GROUP_MEMBERS:
        return "Grupo";
      case ReportEnum.GROUP_ALL:
        return "Pasta";
      default:
        return "Filtro";
    }
  };

  return (
    <ContainerElements>
      <div className="flex-title">
        <h3 id="title">Gerador de dados</h3>
      </div>
      {hasOptions && (
        <ContainerOption $borderBottom={true}>
          <OptionTitle
            onClick={() => {
              setShowItems(!showItems);
            }}
          >
            <IoIosArrowUp
              className={
                showItems ? "center down icon-arrow" : "center up icon-arrow"
              }
              size={18}
            />

            <h3>Geral</h3>
            <div className="buttons">
              {!isEdit ? (
                <MdModeEdit
                  className="icon-color"
                  onClick={(e) => {
                    setIsEdit(true), e.stopPropagation();
                  }}
                  size={22}
                />
              ) : (
                <>
                  <BsXLg
                    className="icon-color"
                    onClick={(e) => (closeEdit(), e.stopPropagation())}
                    size={22}
                  />
                  <BsCheckLg
                    className="icon-color"
                    onClick={(e) => (saveChange(), e.stopPropagation())}
                    size={22}
                  />
                </>
              )}
            </div>
          </OptionTitle>
          <div className={`${showItems ? "" : "hidden"} tab`}>
            {optionsPDF.requiredPeriod && (
              <Element>
                <div className="icon">
                  <VscCalendar size="20px" />
                </div>
                {!isEdit ? (
                  <p>
                    {startDate.toLocaleDateString().substring(0, 10)}-
                    {endDate.toLocaleDateString().substring(0, 10)}
                  </p>
                ) : (
                  <>
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      required={true}
                      selectsStart
                      className="teste"
                      customInput={<DateCustomInput />}
                      startDate={startDate}
                      endDate={endDate}
                    />
                    {`-`}
                    <DatePicker
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                      dateFormat="dd/MM/yyyy"
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="dd/mm/yyyy"
                      required={true}
                      className="teste"
                      customInput={<DateCustomInput />}
                    />
                  </>
                )}
              </Element>
            )}
            {optionsPDF.requiredId && !eventSelected && (
              <Element>
                <div className="icon">
                  <Icon />
                </div>
                {isEdit ? (
                  <InputSelect
                    value={keySelected}
                    onChange={(e) => {
                      setKeySelected(e);
                    }}
                    name="filterParticipants"
                    id="filterParticipants"
                    isLoading={isLoading}
                    placeholder={hintString()}
                    style={true}
                    options={optionsGroup}
                  />
                ) : (
                  <p>
                    {keySelected
                      ? keySelected.label
                      : `Selecione uma opção de busca`}
                  </p>
                )}
              </Element>
            )}
            {eventSelected && (
              <EventsOptions
                edit={isEdit}
                endDate={endDate}
                startDate={startDate}
                optionsPDF={optionsPDF}
              />
            )}
            {optionsPDF.filters.map((filter, index) => (
              <FilterOption
                keySelect={keySelected}
                optionsPDF={optionsPDF}
                key={filter.key}
                valuesSelected={valuesOptions}
                setValuesSelected={setValueOptions}
                position={index}
                edit={isEdit}
                option={filter.key}
              />
            ))}
          </div>
        </ContainerOption>
      )}

      <OrderingOption
        header={header}
        optionsPDF={optionsPDF}
        setOptionsPDF={setOptionsPDF}
      />
      <ContainerOption $borderBottom={true}>
        <OptionTitle
          onClick={() => {
            setShowItemsFilter(!showItemsFilter);
          }}
        >
          <IoIosArrowUp
            className={
              showItemsFilter
                ? "center down icon-arrow"
                : "center up icon-arrow"
            }
            size={18}
          />

          <h3>Filtros</h3>

          <div className="buttons">
            {!optionsPDF.filterUnion ? (
              <BsIntersect
                title="União"
                className="icon-color"
                size={20}
                onClick={(e) => (
                  setOptionsPDF({
                    ...optionsPDF,
                    filterUnion: !optionsPDF.filterUnion,
                  }),
                  e.stopPropagation()
                )}
              />
            ) : (
              <BsUnion
                title="Interseção"
                className="icon-color"
                size={20}
                onClick={(e) => (
                  setOptionsPDF({
                    ...optionsPDF,
                    filterUnion: !optionsPDF.filterUnion,
                  }),
                  e.stopPropagation()
                )}
              />
            )}
            <IoAdd
              title="Adicionar filtro à coluna"
              className="icon-color"
              size={22}
              onClick={() => {
                modalFilterAdd.current.open();
              }}
            />
          </div>
        </OptionTitle>
        {showItemsFilter &&
          optionsPDF.filtersOperations.map((filter, position) => (
            <FilterItemOptions
              optionsPDF={optionsPDF}
              setOptionsPDF={setOptionsPDF}
              position={position}
              key={position}
              header={header}
            />
          ))}
      </ContainerOption>
      <Modal mobile={true} ref={modalFilterAdd}>
        <AddFilterModal
          header={header}
          modal={modalFilterAdd}
          optionsPDF={optionsPDF}
          setOptionsPDF={setOptionsPDF}
        />
      </Modal>
    </ContainerElements>
  );
};

export default ContentBlock;
