import { memo, useEffect, useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import { OptionSelect, ReportOptionsInterface, ReportSelectFilter } from "../../../../../../types/project";
import { ReportFilters } from "../../../../../../util/report/reportFilters";
import { HeaderTable } from "../../../../../../util/report/reportTypes";
import FilterOptions from "../../FormContent/FilterOptions";
import { OptionTitle } from "../../style";

interface ColumnsProps {
    position: number;
    header: HeaderTable;
    optionsPDF: ReportOptionsInterface;
    setOptionsPDF: React.Dispatch<React.SetStateAction<ReportOptionsInterface>>;
}

const FilterItemOptions = ({ position, header, optionsPDF, setOptionsPDF }: ColumnsProps) => {
    const [isEdit, setIsEdit] = useState(false);
    const actual = optionsPDF.filtersOperations[position];
    const [columnSelect, setColumnSelect] = useState(actual.key);
    const valuesLabel = ReportFilters.valuesLabel(header.itens[columnSelect].type);
    const [value, setValue] = useState<undefined | string | number | boolean | Date | OptionSelect[]>(actual.value);
    const item = valuesLabel.find(item => item.value == actual.type) ?? valuesLabel[0];
    const [filterSelected, setFilterSelected] = useState<ReportSelectFilter>(item);
    console.log(optionsPDF);
    console.log(header.itens[columnSelect])
    console.log(ReportFilters.valuesLabel(header.itens[columnSelect].type))
    console.log(item)
    console.log(filterSelected)
    console.log(ReportFilters.valuesLabel(header.itens[columnSelect].type).find(item => item.value == actual.type))
    console.log(actual)

    console.log(position)
    useEffect(() => {
        setFilterSelected(item);
        setValue(actual.value);
    }, [optionsPDF]);

    function saveChange() {
        optionsPDF.filtersOperations[position] = { key: columnSelect, type: filterSelected.value, value: value };
        setOptionsPDF({ ...optionsPDF });
        setIsEdit(false);
    }

    function deleteItem() {
        optionsPDF.filtersOperations.splice(position, 1);
        setOptionsPDF({ ...optionsPDF });
        setIsEdit(false);
    }

    function closeEdit() {
        setIsEdit(false);
        setValue(actual.value);
        setFilterSelected(item);
        setColumnSelect(actual.key);
    }

    return (
        <div className="tab">
            <OptionTitle >
                <h5>{actual.key}</h5>
                <div className="buttons">
                    {!isEdit ? <>
                        <FiTrash className="icon-color" onClick={deleteItem} size={18} />
                        <MdModeEdit className="icon-color" onClick={() => setIsEdit(true)} size={18} />
                    </>
                        :
                        <>
                            <BsXLg className="icon-color" onClick={closeEdit} size={18} />
                            <BsCheckLg className="icon-color" onClick={saveChange} size={18} />
                        </>}
                    {/* <IoIosArrowUp className={isOpen ? "center down" : "center up"} onClick={() => setIsEdit(true)} size={18} /> */}
                </div>
            </OptionTitle>
            <FilterOptions isEditing={isEdit} value={value} setValue={setValue} header={header} columnSelect={columnSelect} setColumnSelect={setColumnSelect} filterSelected={filterSelected} setFilterSelected={setFilterSelected} />

        </div>
    );
}

export default memo(FilterItemOptions);