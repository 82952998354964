import styled from "styled-components";

export const Form = styled.form`
  padding: 1.2rem 1rem;
  background-color: ${(props) => props.theme.backgrounds.white};
  height: 100%;
  overflow-y: auto;
  label {
    color: ${({ theme }) => theme.texts.darkerGreyII};
    :hover{
      cursor: pointer;
    }
  }

  .marginLeft {
    margin-left: 8px;
  }

  .doc {
    margin-top: 8px;
    grid-area: doc;
  }
  .email {
    grid-area: email;
  }

  .birthday {
    grid-area: birthday;
  }

  .relationship {
    grid-area: relationship;
  }

  .phone {
    grid-area: phone;
  }
  .street {
    grid-area: street;
  }

  .zipCode {
    grid-area: zipCode;
  }

  .nbhood {
    grid-area: nbhood;
  }

  .city {
    grid-area: city;
  }

  .state {
    grid-area: state;
  }

  .country {
    grid-area: country;
  }

  #fields {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 6px;
    grid-template-areas:
      "name lastName"
      "doc doc"
      "email email"
      "birthday birthday"
      "relationship relationship"
      "phone phone"
      "zipCode zipCode"
      "street street"
      "nbhood nbhood"
      "city state"
      "country country";
  }

  #back {
    display: flex;
    align-items: center;
    background: transparent;
    margin: 0;
    padding: 0;

    h3 {
      margin: 0px;
      color: ${(props) => props.theme.texts.purpleDark};
    }

    #icon {
      font-size: 25px;
      cursor: pointer;
      color: ${(props) => props.theme.texts.purpleDark};
    }
  }

  .row-name {
    display: grid;
    grid-template-columns: auto auto;
  }

  .mr {
    padding-right: 0.5rem;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  #profile {
    padding: 0.5rem;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding-inline: 0.5rem;
    object-fit: cover;
  }

  #row {
    display: flex;
    margin-top: 2px;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const OutlineButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background: none;
  padding: 5px 8px;
  border-radius: 10px;
  border: solid 1px ${(props) => props.theme.texts.purpleDarkTitle};
  transition: box-shadow 0.3s;
  margin-right: 5px;

  #icon {
    font-size: 20px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  .right {
    margin-right: 4px;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;

export const BtnFile = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.purple.dark};
  color: ${(props) => props.theme.purple.dark};
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 50%;
`;

export const Default = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
`;
