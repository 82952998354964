import {
  api
} from "../api";
import {
  getToken
} from "../auth";

export const postEventUser = (eventId, participation) => {
  return api({
      url: `/event-user/${eventId}`,
      method: "POST",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      data: {
        participation
      }
    })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const deleteEventUser = (userId, eventId) => {
  return api({
      url: `/event-user/${userId}/${eventId}`,
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};


export const getUsersInEvent = (eventId, groupId) => {
  return api({
      url: `/event-user/presence/${eventId}/${groupId}`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};


export const pacthEventUser = (eventId, participation) => {
  return api({
      url: `/event-user/participation/${eventId}`,
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      data: {
        participation
      }
    })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const patchEventPresence = (eventId, usersAdd, usersRemove) => {
  return api({
      url: `/event-user/presence/${eventId}/`,
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      data: {
        usersAdd,
        usersRemove
      }
    })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};