import { Dispatch, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StorageMediaSharingService } from "../../../../../services/requests/storage-media-sharing";
import { FileRepository } from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";

interface UseFilesRepository {
    files: FileRepository[];
    isLoading: boolean;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    error?: string;
    setSelectedId: Dispatch<React.SetStateAction<string>>;
}


export const UseFilesSendRepository = (): UseFilesRepository => {
    const [selectedId, setSelectedId] = useState<string>("");
    const repositoryInformations = useSelector((store: TypeConstRedux) => store.repository);
    const { folder, filters } = repositoryInformations;
    const { types } = filters;

    useEffect(() => {
        const lengthCluster = folder.length;
        if (lengthCluster == 0) {
            setSelectedId("");
        } else {
            const clusterSelected = folder[lengthCluster - 1];
            setSelectedId(clusterSelected.id);
        }
    }, [folder]);

    const filterCluster = useCallback(
        (files: FileRepository[]) => {
            if (types.length > 0) {
                return files.filter((folder) => types.some((type) => type == folder.fileType));
            }
            return files;
        }, [types]
    );

    const selectFunction = () => {

        return StorageMediaSharingService.getSend();
    }
    const {
        data: files = [],
        isLoading,
        isFetching,
        error,
        isSuccess,
        isError
    } = useQuery([Queries.REPOSITORY_FILES_SHARING_SEND, selectedId], selectFunction, {
        refetchOnWindowFocus: false,
        retry: 2,
        select: filterCluster,
        refetchInterval: false

    });
    return {
        files,
        isLoading,
        isFetching,
        setSelectedId,
        isSuccess,
        isError
    };
};