import { DefaultTheme } from "styled-components";
export const dark: DefaultTheme = {
  title: "dark",
  error: "#F45",
  heightToolbar: "55px",
  color: {
    secondary_cinza: {
      navigation_drawer: "#F1F1F1",
    },
  },
  general: {
    column_gap: "1rem",
  },
  fonts: {
    text_xs: "0.75rem",
    text_sm: "0.875rem",
    text_base: "1rem",
    text_lg: "1.125rem",
    text_xl: "1.25rem",
    text_2xl: "1.5rem",
    text_3xl: "1.875rem",
    text_4xl: "2.25rem",
    text_5xl: "3rem",
    text_6xl: "3.75rem",
    text_7xl: "4.5rem",
    text_8xl: "6rem",
    text_9xl: "8rem",
    family_toolbar: "inter-bold, sans-serif",
  },
  card: {
    primary: "#0B2C40",
    text_primary: "#fff",
    text_secondaty: "#ff8f2f",
  },
  begin: {
    color: {
      background: {
        primary: "#fff",
        secondary: "#FEF9FB",
        footer: "#7C1A40",
      },
      font: {
        primary: "#fff",
        secondary: "black",
        title: "#fff",
      },
      button: {
        primary: "white",
        text_primary: "white",
        more: "none",
        secondary: "#DF6A00",
        text_secondary: "white",
      },
      information: {
        background: "#092232",
      },
      card: {
        font: "#484848",
        button: "#071D2B",
        font_header: "#092232",
        background_header: "#fff",
        border: "rgb(255 255 255 / 25%)",
      },
    },
    background: {
      primary: "#071D2B",
      secondary: "#092232",
      footer: " #071D2B",
    },
  },
  purple: {
    ligther: "#f2ac6d",
    ligtherI: "#F5A6C5",

    light: "#e8a72e",
    lightI: "#A13660",
    lightII: "#92406d",

    dark: "#092232",
    darkI: "#70012C",

    darker: "#092232",
    darkerI: "#300013",
  },
  default: {
    ligther: "#FFFFFF",
    ligtherI: "#EDEDED",

    light: "#DDDCDC",
    lightI: "#193648",

    dark: "#AEAEAE",
    darkI: "#848383",

    darker: "#666666",
    darkerI: "#484848",

    black: "#2F2E2E",
    blackI: "#000000",
  },
  colors: {
    primary: "#333",
    secundary: "#C62E65",

    background: "#222",
    text: "#fff",
  },
  blue: {
    ligther: "#D9F8FB",
    ligtherI: "#93F2FC",

    light: "#56E2F1",
    lightI: "#26BDCD",

    dark: "#009FB0",
    darkI: "#008493",

    darker: "#00646F",
    darkerI: "#013F46",
  },
  darkBlue: {
    ligther: "#B19AE5",
    ligtherI: "#8676AA",

    light: "#705E97",
    lightI: "#54427B",

    dark: "#473C5F",
    darkI: "#393246",

    darker: "#292433",
    darkerI: "#1D1A24",
  },
  green: {
    ligther: "#E0FFB9",
    ligtherI: "#C9F394",

    light: "#ABDC6D",
    lightI: "#87BC46",

    dark: "#4E840C",
    darkI: "#3B6309",

    darker: "#324816",
    darkerI: "#313D22",

    black: "#2B2F26",
    blackI: "#151515",
  },
  chat: {
    message: {
      backgroundMyI: "#0F2C40",
      backgroundMyII: "#0B3857",
      backgroundOtherI: "#1A5174",
      backgroundOtherII: "#1069A1",
      send: "#848383",
      read: "#DF6A00",
      backgroundData: "#0B2B40",
      backgroundRound: "#0a1f2c",
      borderRound: "#0d283a",
    },
  },
  texts: {
    white: "#FFFFFF",
    titleCards: "#2F80ED",
    contentCards: "#ffffff",
    hintEventCard: "#EE9B08",
    hintDailyCardI: "#EE9B08",
    hintDailyCardII: "#EE9B08",
    primaryNavBar: "#DF6A00",
    secondaryNavBar: "#AEAEAE",
    titleInformations: "#df6a00",

    secondaryToolbar: "#DF6A00",
    lightGrey: "#FFFFFF",
    lightGreyI: "#FFFFFF",
    lightGreyII: "#FFFFFF",
    darkGrey: "#FFFFFF",
    darkGreyI: "#FFFFFF",
    darkerGrey: "#FFFFFF",
    darkerGreyI: "#FFFFFF",
    darkerGreyII: "#FFFFFF",
    black: "#FFFFFF",
    pinkII: "#FFFFFF",
    purpleDark: "#FFFFFF",
    purpleDarkTitle: "#ff8f2f",
    orange: "#ff8f2f",
  },
  backgrounds: {
    cardWheather: "#037453;",
    primary: "#092232",
    secondary: "#0B2C40",
    secondaryToolbar: "#0d283a",
    white: "#092232",
    answer: "#0b2c40",
    default: "#0b2c40",
    blueLight: "#2581C41A",
    buttonSchedule: "#DF6A00",
    pinkII: "#082231",
    purpleLightI: "#0a1f2c",
    purpleDark: "#092232",
    lightGreyII: "#0B2B40",
    lightGreyIV: "#092232",
    perfilNaveBar: "#071D2B",
    folderTalk: "#0B2C40",
    lightGrey: "#0E344D",
    lightGreyI: "#DDDCDC",
    darkI: "#848383",
    toolbarSchedule: "#092232",
    conversaSelecionada: "#092232",
    buttonModalGroup1: "#05141e",
    buttonModalGroup2: "#0c2c41",
    buttonModalGroup1Login: "#DF6A00",
    buttonModalGroup2Login: "#DF6A00",
    gradient2: "#da7e2b",
    gradient3: "#e08f45",
  },
  button: {
    backgroundEvent: "transparent",
    textEvent: "#ffffff",
    hoverEvent: "#2F80ED",
  },
  hover: {
    navBar: "#1E3341",
    purpleLightII: "#5a9fd11a",
    purpleDark: "#DF6A00",
    lightGrey: "#2581C41A",
    lightGreyIII: "#2581C41A",
    orange: "#DF6A00",
    input: "#FFFF",
    red: "#ff4c2f",
    green: "#1ca54c",
    default: "#2581C41A",
    buttonHover: "#2581C41F",
    buttonActive: "#2581C43F",
  },
  highlights: {
    default: "#DF6A00",
    navBar: "#1A3751",
  },
  bordes: {
    card: "trasnparent",

    calendar: "#848383",

    purpleLight: "#193648",
    purpleLightIIShadow: "#193648",
    lightGrey: "#193648",
    lightGreyI: "#848383",
    lightGreyII: "#193648",
    input: "#848383",
    footerChat: "#666666",
    rose: "#848383",
  },
  boxShadow: {
    card: "none",
  },
  icon: {
    perfil: "#df6a00",
    dashboard: "#ffffff",
    cardDailyMessage: "#406996",
    cardProgrammingEvent: "#406996",
  },
  linearGradient: {
    cardWheather:
      "linear-gradient(180deg, #09976D -6.75%, #037453 -6.74%, #09976D 44.2%)",
  },
  link: "#DF6A00",
};
