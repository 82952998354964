import { IoIosClose, MdOutlineLocationOn } from '../../assets/icons';
import { ContainerList } from '../../pages/home/Conversations/Group/Profile/ProfileInfo/style';
import { photoUrl } from "../../util/verification";
import { Back, HorizontalMidia, Item, ItemNopd, Office, User, Window } from "./style";


const WindowGroup = ((props) => {
    const { groupInfo, setMarkerSelected, setInformationComplete } = props

    return <Window>
        <div className="background">
            <Back>
                <IoIosClose id="icon" onClick={() => {
                    setMarkerSelected(null);
                    setInformationComplete(false);
                }} />
                <h3>Informações do grupo</h3>
            </Back>
            <div className='scroll'>
                <div id="profile">
                    <img id="img" src={photoUrl(groupInfo)} />
                    <h5 id="name">{groupInfo.name}</h5>
                    <h5 id="number-members">{groupInfo.memberNumber} {groupInfo.memberNumber == 1 ? "membro" : "membros"}</h5>
                </div>

                <ItemNopd>
                    <Office>
                        Líderes de Grupo
                    </Office>
                    {
                        groupInfo.leaders && groupInfo.leaders.length > 0 ?
                            <>
                                {groupInfo.leaders.map(({ name, photoUrl, nickname, roles }, i) => (
                                    <User key={i}>
                                        <img alt="Perfil" src={photoUrl} />
                                        <div className="div_funcoes">
                                            <p >{name ? name : "@" + nickname}</p>
                                            <p className="funcoes">
                                                {roles.map(({ name }) => { return name + " " })}</p>
                                        </div>
                                    </User>
                                ))}
                            </>
                            : <p className="description margin padding">Sem liderança</p>

                    }
                </ItemNopd>

                {/* <Item>
          Atividades
          <p className="description">
            <MdCheck className="icon" />
            Realizadas: 0
          </p>
          <p className="description">
            <MdSchedule className="icon" />
            Programadas: 0
          </p>
        </Item> */}
                {groupInfo.description ?
                    <Item>
                        Descrição
                        <p className="description">
                            {groupInfo.description}
                        </p>
                    </Item>
                    :
                    <Item>
                        Descrição do grupo
                        <p className="description">
                            Sem descrição
                        </p>
                    </Item>
                }
                {groupInfo.address ?
                    <Item>

                        Localização
                        <div className="location">
                            <MdOutlineLocationOn className="icon" />
                            <p className='description'>{groupInfo.address}</p>
                        </div>

                    </Item>
                    :
                    <Item>
                        Localização
                        <p className="description">
                            Sem localização cadastrada
                        </p>
                    </Item>
                }
                {groupInfo.lastMessagesWithMidia && groupInfo.lastMessagesWithMidia.length > 0 &&
                    <Item>
                        <div id="midia">
                            Mídia
                        </div>
                        <HorizontalMidia>
                            <div>
                                {groupInfo.lastMessagesWithMidia.map((media, i) =>
                                    <img key={i} src={media.fileURL} />
                                )}
                            </div>
                        </HorizontalMidia>
                    </Item>
                }

                <ItemNopd>
                    <Office>
                        Membros
                    </Office>
                    <ContainerList>
                        {groupInfo.participants && groupInfo.participants.map(({ name, photoUrl, nickname }, i) => (
                            <User key={i}>
                                <img alt="Perfil" src={photoUrl} />
                                <div className="name">{name ? name : "@" + nickname}</div>
                            </User>
                        ))}
                    </ContainerList>

                </ItemNopd>
            </div>

        </div>
    </Window>
});



export default WindowGroup;