import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconStorageFill = ({
  color = "#700540",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_5711_390)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.782666 23.216C1.30533 23.7387 1.93333 24 2.66667 24H21.3333C22.0658 24.0009 22.6933 23.74 23.216 23.2173C23.7387 22.6947 24 22.0667 24 21.3333V4.7C24.0009 4.38889 23.9511 4.08889 23.8507 3.8C23.7502 3.51112 23.6 3.24445 23.4 3.00001L21.7333 0.966673C21.4898 0.656451 21.1844 0.417785 20.8173 0.250674C20.4502 0.0835625 20.0667 7.05467e-06 19.6667 7.05467e-06H4.33333C3.93244 -0.000881834 3.54889 0.0822293 3.18267 0.24934C2.81644 0.416451 2.51111 0.655562 2.26667 0.966673L0.6 3.00001C0.400889 3.24445 0.251111 3.51112 0.150666 3.8C0.0502221 4.08889 0 4.38889 0 4.7V21.3333C-0.000888888 22.0658 0.26 22.6933 0.782666 23.216ZM21.6263 4.5H2.37368C2.21394 4.5 2.11866 4.32197 2.20727 4.18906L3.55467 2.16795C3.83287 1.75065 4.30122 1.5 4.80275 1.5H12H19.1972C19.6987 1.5 20.1671 1.75065 20.4453 2.16795L21.7927 4.18906C21.8813 4.32197 21.786 4.5 21.6263 4.5ZM11.7666 9C11.3889 9 11.072 9.128 10.816 9.384C10.56 9.64 10.4324 9.95645 10.4333 10.3333V14.3586C10.4333 14.4477 10.3256 14.4923 10.2626 14.4293L9.2333 13.4C8.98886 13.1556 8.67775 13.0333 8.29997 13.0333C7.92219 13.0333 7.61108 13.1556 7.36664 13.4C7.12219 13.6444 6.99997 13.9556 6.99997 14.3333C6.99997 14.7111 7.12219 15.0222 7.36664 15.2667L10.8333 18.7333C11.1 19 11.4111 19.1333 11.7666 19.1333C12.1222 19.1333 12.4333 19 12.7 18.7333L16.1666 15.2667C16.4111 15.0222 16.5333 14.7111 16.5333 14.3333C16.5333 13.9556 16.4111 13.6444 16.1666 13.4C15.9222 13.1556 15.6111 13.0333 15.2333 13.0333C14.8555 13.0333 14.5444 13.1556 14.3 13.4L13.2707 14.4293C13.2077 14.4923 13.1 14.4477 13.1 14.3586V10.3333C13.1 9.95556 12.972 9.63867 12.716 9.38267C12.46 9.12667 12.1435 8.99911 11.7666 9Z"
          fill="inherit"
          />
      </g>
      <defs>
        <clipPath id="clip0_5711_390">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconStorageFill;
