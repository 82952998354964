import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 0px 16px;
  height: auto;
  overflow: none;
  #title {
    color: ${(props) => props.theme.texts.purpleDark};
    margin-bottom: 0;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .center-button {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
  }

  #icon {
    font-size: 22px;
    color: ${(props) => props.theme.default.darkI};
  }

  #icon-purple {
    font-size: 22px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }

  .load {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .scroll {
    overflow-y: auto;
  }

  .grupo {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.texts.purpleDark};

    #img {
      height: 100px;
    }

    #name {
      padding-left: 8px;
      font-size: 18px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  h5 {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  margin-bottom: 12px;

  h5 {
    justify-self: start;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const ButtonConfirmPassWord = styled.button`
  width: 10rem;
  display: block;
  align-items: center;
  font-size: 16px;
  color: #fff;
  background-color: ${(props) => props.theme.hover.green};
  text-align: center;
  height: 1.8rem;
  margin-top: 8px;
  margin-bottom: 2rem;
  border-radius: 2px;
`;
