import { Container, Input } from './style'
import { AiOutlinePlus, IoIosClose, IoIosCheckmark } from '../../../../../assets/icons'
import { postCluster } from "../../../../../services/requests/cluster";
import { connect } from "react-redux";
import { setListFolder } from "../../../../../store/actions/folderAction";

const Add = (props) => {
    const { newFolder, abare, folders, setFocus, toListFolder } = props;

    const createFolder = (e) => {
        e.preventDefault();
        const { folder } = e.target.elements

        !(folder.value.trim() == '') &&
            postCluster(abare.id, folder.value).then((res) => {
                if (res.status == 200) {
                    const newFolder = {...res.data.result, itens:[]}
                    // setFolders((prev) => prev.concat([res.data.result]));
                    toListFolder([...folders, newFolder])
                    setFocus(false)
                }
            });
    }

    return (
        <Container>
            {newFolder &&
                <form onSubmit={createFolder}>
                    <Input id="input">
                        <input placeholder="Nome da pasta" autoComplete="off" maxLength={30} type="text" id="add" name="folder" autoFocus={true} />
                        <button className="sub" type="submit" ><IoIosCheckmark id="icon" /></button>
                        <button onClick={() => setFocus(false)} className="sub" > <IoIosClose id="icon" /> </button>
                    </Input>
                </form>
            }
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
        folders: state.folder.list
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toListFolder(list) {
            dispatch(setListFolder(list));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Add);