import { FileRepository, FolderRepository } from "../../types/project";
import { api } from "../api";
import { getToken } from "../auth";

const post = (data) => {
  return api({
    url: `/storage-cluster`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

const get = () => {
  return api({
    url: `/storage-cluster`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export type UpdateNameStorageClusterPayload = {
  storageClusterId: string;
  name: string;
  folder: FolderRepository;
};

const patchName = (data) => {
  return api({
    url: `/storage-cluster/update-name-cluster`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: {
      storageClusterId: data.storageClusterId,
      name: data.name,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

export type UpdateColorStorageClusterPayload = {
  storageClusterId: string;
  color: string;
  folder: FolderRepository;
};

const patchColor = (data) => {
  return api({
    url: `/storage-cluster/update-cluster-color`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: {
      storageClusterId: data.storageClusterId,
      color: data.color,
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

const deleteById = (id) => {
  return api({
    url: `/storage-cluster`,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: { storageClusterId: [id] },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

const getAllCluster = () => {
  return api({
    url: `/storage-cluster`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

const getAllSubCluster = (id) => {
  console.log(id);
  return api({
    url: `/storage-cluster/sub-cluster`,
    method: "POST",
    data: { StorageCluster_id: id },
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

export const StorageClusterService = {
  post,
  get,
  getAllSubCluster,
  getAllCluster,
  deleteById,
  patchName,
  patchColor,
};
