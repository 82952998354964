import React from "react";
import { SVGComponent } from "../../types/project";
import Icon, { SVGComponentBase } from "./Icon";

const IconCopy = ({ color = "#484848", size = 16, ...rest }: SVGComponentBase) => {
  return (
    <Icon viewBox="0 0 16 16" color={color} size={size} {...rest}>
      <path
        d="M4 8C4 6.11467 4 5.17133 4.586 4.586C5.17133 4 6.11467 4 8 4H10C11.8853 4 12.8287 4 13.414 4.586C14 5.17133 14 6.11467 14 8V11.3333C14 13.2187 14 14.162 13.414 14.7473C12.8287 15.3333 11.8853 15.3333 10 15.3333H8C6.11467 15.3333 5.17133 15.3333 4.586 14.7473C4 14.162 4 13.2187 4 11.3333V8Z"
        stroke="inherit"
      />
      <path
        d="M4 13.3333C3.46957 13.3333 2.96086 13.1226 2.58579 12.7475C2.21071 12.3725 2 11.8638 2 11.3333V7.33333C2 4.81933 2 3.562 2.78133 2.78133C3.562 2 4.81933 2 7.33333 2H10C10.5304 2 11.0391 2.21071 11.4142 2.58579C11.7893 2.96086 12 3.46957 12 4"
        stroke="inherit"
      />
    </Icon>
  );
};

export default IconCopy;
