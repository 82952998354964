import styled from "styled-components";

interface StyledProps {
  displayName?: boolean
}

export const VoiceMessage = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${props => props.displayName ? '16px' : '8px'} 46px 6px 8px;
  height: 57px;
  width: 15vw;

  @media (max-width: 1560px) {
    width: 180px;
  }

  @media (max-width: 900px) {
    width: 150px;
  }

  @media (max-width: 500px) {
    width: 120px;
    .duration{
      display: none;
    }
  }

  @media (max-width: 350px) {
    width: 100px;
  }
  
  .waveform-container {
    display: grid;
    overflow: hidden;
    grid-template-columns: auto 1fr;
    align-items: center;
    border: 1px solid none;
    font-size: 11px;
  }

  .duration{
    color: ${({theme}) => theme.texts.darkerGreyI};
  }

  .defaultBar {
    margin-left: 10px;
    height: 5px;
    background-color: ${(props) => props.theme.default.dark};
    border-radius: 15px;
  }

  #waveform-audio {
    cursor: pointer;
    min-width: 100%;
  }
  audio {
    min-width: 90%;
  }

`;

export const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.purple.dark};
  color: #FFF;
  padding: 0.5rem;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;