import { createContext, useState } from "react";
import { ReportEnum, ReportStylesInterface } from "../types/project";
import { ReportFunctions } from "../util/report/reportFunctions";

interface ReportContextType {
  optionReportSelected: ReportEnum | undefined;
  stylesPDF: ReportStylesInterface;
  closeOptionReportSelected: () => void;
  handleStylesPDF: (styles: ReportStylesInterface) => void;
  handleOptionReportSelected: (option: ReportEnum | undefined) => void;
}

export const ReportContext = createContext<ReportContextType>(
  {} as ReportContextType
);

interface ReportProviderProps {
  children: React.ReactNode;
}

export function ReportContextProvider({ children }: ReportProviderProps) {
  const [optionReportSelected, setOptionReportSelected] = useState<
    ReportEnum | undefined
  >(undefined);

  const [stylesPDF, setStylesPDF] = useState<ReportStylesInterface>(
    ReportFunctions.defaultStylePDF
  );

  function handleOptionReportSelected(option: ReportEnum | undefined) {
    console.log("oii");
    setOptionReportSelected(option);
  }

  function handleStylesPDF(styles: ReportStylesInterface) {
    setStylesPDF(styles);
  }

  function closeOptionReportSelected() {
    setOptionReportSelected(undefined);
  }
  
  return (
    <ReportContext.Provider
      value={{
        optionReportSelected,
        stylesPDF,
        handleStylesPDF,
        handleOptionReportSelected,
        closeOptionReportSelected,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}
