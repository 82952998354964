import { HiCheck as Checkmark } from '../../../assets/icons';
import { IPlan } from './plans.interfaces';
import { checkout } from './signature.service';
import { PlanCard as PCard } from './style';

function Plan(props:IPlan) {
  const { info } = props;

  async function handleBuy() {
    const { id } = info;
    await checkout({ id });
  }

  function formatPrice(price:number) {
    return `R$ ${(price).toFixed(2)}`.replace('.', ',');
  }

  return (
    <PCard>
      <div className="content">
        <h2 className="name">{info.name}</h2>
        { info.originalPrice
            && (
              <p className="original-price">
                {formatPrice(info.originalPrice)}
              </p>
            )}
        <h1 className="price">
          {formatPrice(info.price)}
        </h1>
        <p className="description">{info.description}</p>
        <ul className="features">
          {
            info.features.slice(0,6).map((feature) => (
              <li key={feature}>
                <Checkmark size={24} className="icon" />
                <p>{feature}</p>
              </li>
            ))
          }
        </ul>
      </div>
      <button
        type="button"
        className="btn-buy"
        onClick={handleBuy}
      >
        Comprar

      </button>
    </PCard>
  );
}

export default Plan;
