import { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { patchUpdatePrivacyUser } from "../../../../../../services/requests/privacy";
import { setAbare } from "../../../../../../store/actions/abareAction";
import { ToastModel } from "../../../../../../util/error";
import ItemContainer from "../../../Components/ItemContainer";
import Container from "../../../Components/SettingsContainer";
import { VerticalRowItems } from "../../../style";

const template = (privacy, opt) => {
    if (opt) {
        switch (opt) {
            case 'Nome':
                return privacy.name;
            case 'E-mail':
                return privacy.email;
            case 'CPF':
                return privacy.cpf;
            case 'Telefone/Celular':
                return privacy.phone;
            case 'Data de aniversário':
                return privacy.birthday;
            case 'Dados Eleitorais':
                return privacy.voter;
            case 'Ligações':
                return privacy.call;
            case 'Grupos':
                return privacy.groupAdd;
            default:
                break;
        }
    }
}

const DataPrivacy = (props) => {
    const { titleContainer, titleItem, message, text, abare, toChangeAbare } = props;
    const { goBack } = useHistory();
    const { path } = useRouteMatch();

    const value = template(abare.privacy, titleContainer);
    const [opt1, setOpt1] = useState(value == '1');
    const [opt2, setOpt2] = useState(value == '2');
    const [opt3, setOpt3] = useState(value == '3');

    const route = path.split('/')
    const privacyName = route[route.length - 1];
    const { addToast } = useToasts();

    const items = [
        { name: "radio_btn1", message: message ? "Ninguém" : "Apenas eu", marginBottom: true, value: '1', checked: opt1, onchange: (e) => { setOpt1(!opt1); setOpt2(false); setOpt3(false); privacyData(e.target.value) } },
        { name: "radio_btn2", message: "Contatos", marginBottom: true, checked: opt2, value: '2', onchange: (e) => { setOpt1(false); setOpt2(!opt2); setOpt3(false); privacyData(e.target.value) } },
        { name: "radio_btn3", message: "Todos", marginBottom: false, checked: opt3, value: '3', onchange: (e) => { setOpt1(false); setOpt2(false); setOpt3(!opt3); privacyData(e.target.value) } }
    ]

    const privacyData = (option: string) => {
        const data = {
            name: privacyName == 'name' ? option : abare.privacy.name,
            email: privacyName == 'email' ? option : abare.privacy.email,
            phone: privacyName == 'phone' ? option : abare.privacy.phone,
            birthday: privacyName == 'birthday' ? option : abare.privacy.birthday,
            voter: privacyName == 'electoral' ? option : abare.privacy.voter,
            cpf: privacyName == 'cpf' ? option : abare.privacy.cpf,
            groupAdd: privacyName == 'restrictionGroup' ? option : abare.privacy.groupAdd,
            call: privacyName == 'restrictionCall' ? option : abare.privacy.call,
        }
        patchUpdatePrivacyUser(abare.id, data)
            .then((res) => {
                if (res.status == 200) {
                    toChangeAbare({ ...abare, privacy: res.data.result })
                }
                else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            })
    }

    return (
        <Container title={titleContainer} isIcon onClick={() => goBack()}>
            <form>
                <ItemContainer title={titleItem}>
                    {items.map((it, key) => (
                        <VerticalRowItems noHover={true} isSingle marginBottom={it.marginBottom} key={key}>
                            <input type="radio" name={it.name} checked={it.checked} value={it.value} onChange={(e) => { it.onchange(e) }} />
                            <span>{it.message}</span>
                        </VerticalRowItems>
                    ))}
                    <div className="line">
                        {(() => {
                            if (opt1) {
                                if (message) {
                                    return `Ninguém poderá ${text}`
                                }
                                return `Somente você irá ${text}`
                            }
                            if (opt2) {
                                return `Somente os seus contatos terão acesso para ${text}`
                            }
                            if (opt3) {
                                return `Todos poderão ${text}`
                            }
                        })()}
                    </div>
                </ItemContainer>
            </form>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toChangeAbare(newAbare) {
            dispatch(setAbare(newAbare));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPrivacy);