import { Menu } from "@mui/material";
import { useState } from "react";

interface MenuProps {
  children: React.ReactNode[];
}

export function FloatMenu({ children }: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (open) {
      onClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="dropdown">
      <div onClick={onOpen}>{children[0]}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: "150px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1,
              borderRadius: "6px",
            },
          },
        }}
        onClick={onClose}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {children[1]}
      </Menu>
    </div>
  );
}
