import { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from './style';


interface TDrop {
    onDrop
    disabled?: boolean
    multiple?: boolean
    image?: boolean,
    maxFiles?: number,
    message?: string,
    accept?: {
        [key: string]: string[];
    }
}
const Dropzone = ({ onDrop, disabled = false, multiple = true, image = false, maxFiles = 3, message = '', accept = undefined }: TDrop) => {
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
        onDrop, maxFiles: maxFiles, disabled: disabled, multiple: multiple, accept
    });

    return (
        <Container {...getRootProps({ className: "dropzone", refKey: 'innerRef' })}>
            <input className="input-zone" accept={image ? "image/*" : "aplication/*"} multiple={multiple} {...getInputProps()} />
            <div>
                {message.length > 0 ? <p className="dropzone-content">
                    {message}
                </p> :
                    isDragActive ? (
                        <p className="dropzone-content">
                            Solte os arquivos aqui para envia-los
                        </p>
                    ) : (
                        <p className="dropzone-content">
                            Você pode arrastar e soltar arquivos aqui para adicioná-los(Max: 3).
                        </p>
                    )}
            </div>
        </Container>
    );
};

export default memo(Dropzone);