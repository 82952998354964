import { FiAlertTriangle } from "react-icons/fi";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from 'redux';
import { IoIosClose } from '../../../../../../assets/icons';
import { deleteGroupRole } from "../../../../../../services/requests/group-role";
import { setChat } from "../../../../../../store/actions/messages/messageAction";
import { TProfileGroup, TRole } from '../../../../../../types/project';
import { Body, Container, Footer, Header } from "./style";

type TProps = {
    modal: any;
    groupInfo: TProfileGroup,
    role: TRole,
    setChat: any,
}


const DeleteRole = (props: TProps) => {
    const { modal, role, groupInfo, setChat } = props
    const { addToast } = useToasts();
    const { goBack } = useHistory()

    const deleteRole = () => {
        deleteGroupRole(groupInfo.id, role.id).then((res) => {
            if (res.status = 200) {
                const message = res.data.message.pt
                addToast({
                    title: message,
                    description: `A função '${role.name}' foi removida do grupo.`

                },
                    { appearance: 'success' });

                const index = groupInfo.roles.findIndex(x => x.role.id === role.id);
                groupInfo.roles.splice(index, 1);

                setChat(groupInfo.id, groupInfo);
                goBack()
            }
        })
    }
    return (
        <Container>
            <Header>
                <h4 id="title">Excluir Função</h4>
                <IoIosClose size={24} id="close" onClick={() => modal.current.close()} />
            </Header>
            <Body>
                <FiAlertTriangle id="alert" />
                <p>Você está prestes a excluir permanentemente esta função. </p>
                <p>Se você não tem certeza de que deseja fazer isso, feche esta janela. </p>
            </Body>
            <Footer>
                <button onClick={() => deleteRole()}>
                    Excluir
                </button>
            </Footer>
        </Container>
    );
}


const mapDispatchToProps = dispatch => bindActionCreators({
    setChat,
}, dispatch)


export default connect(null, mapDispatchToProps)(DeleteRole)