import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconSearch = ({ color = "#666666", size = 24, ...rest }: SVGComponent) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="transparent"
      {...rest}
    >
      <path
        d="M22 22.5L17.1745 17.6745M17.1745 17.6745C17.9999 16.8491 18.6547 15.8692 19.1014 14.7907C19.5481 13.7122 19.778 12.5563 19.778 11.389C19.778 10.2217 19.5481 9.0658 19.1014 7.98734C18.6547 6.90887 17.9999 5.92895 17.1745 5.10353C16.3491 4.27811 15.3692 3.62335 14.2907 3.17664C13.2122 2.72992 12.0563 2.5 10.889 2.5C9.72169 2.5 8.5658 2.72992 7.48734 3.17664C6.40887 3.62335 5.42895 4.27811 4.60353 5.10353C2.93652 6.77055 2 9.0315 2 11.389C2 13.7465 2.93652 16.0075 4.60353 17.6745C6.27055 19.3415 8.5315 20.278 10.889 20.278C13.2465 20.278 15.5075 19.3415 17.1745 17.6745Z"
        stroke={color}
        fill="inherit"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSearch;
