import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;

  #alert {
    font-size: 45px;
    color: #d72424;
  }

  p {
    font-size: 14px;
    color: ${props => props.theme.texts.darkerGreyII};
    text-align: center;
    margin: 0.5rem 0rem;
  }
`;

export const SelectContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items:center;
  width:100%;
  margin:0.5rem;
  gap:1rem;

  span{
    font-size: 14px;
    color: ${props => props.theme.texts.darkerGreyII};
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  button {
    color: #d30000;
    border: 1px solid #d30000;
    padding: 0.6rem 2rem;
    border-radius: 7px;
    &:hover{
        background-color: #FDE9E9;
    }
  }
`;
