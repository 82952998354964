import {
    api
} from '../api';
import {
    getToken
} from "../auth";


export const postGroupRole = (groupId, data) => {
    return api({
            url: "/group-role/" + groupId,
            method: "POST",
            headers: {
                Authorization: "Bearer " + getToken()
            },
            data: data

        }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteGroupRole = (groupId, roleId) => {
    return api({
            url: `/group-role/${groupId}/${roleId}`,
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + getToken()
            },
        }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchGroupRole = (groupId, roleId, data) => {
    return api({
            url: `/group-role/${groupId}/${roleId}`,
            method: "PATCH",
            headers: {
                Authorization: "Bearer " + getToken()
            },
            data: data
        }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};