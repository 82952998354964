import { Fragment, useRef } from "react";
import { IoKeyOutline } from "react-icons/io5";
import ItemContainer from "../../../Components/ItemContainer";
import ChangePassword from "../../ChangePassword";
import { Button, HorizontalRow, PasswordPerfil } from "../../style";

const ModalPassWord = () => {

    const modalEditPassWord = useRef<any>();

    return (
        <Fragment>
            <ItemContainer title="Senha" isButton={false}>
                <Button onClick={() => modalEditPassWord.current?.open()}>
                    <HorizontalRow>
                        <IoKeyOutline size={22} />
                        <PasswordPerfil>
                            Alterar senha
                            <div className="line">
                                Defina uma senha de acesso para sua conta
                            </div>
                        </PasswordPerfil>
                    </HorizontalRow>
                </Button>
            </ItemContainer>
            <ChangePassword modalEditPassWord={modalEditPassWord} />
        </Fragment>
    );
}

export default ModalPassWord;