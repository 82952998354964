import styled from "styled-components";

export const Container = styled.div`
  .otpInput {
    width: 2.5rem !important;
    height: 2.5rem;
    margin-right: 15px;
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
    background-color: rgba(37, 129, 196, 0.1) !important;

    &:focus {
      outline: none;
      color: ${(props) => props.theme.purple.dark} !important;
    }
    &:focus-within {
      color: ${(props) => props.theme.purple.dark} !important;
      -webkit-text-fill-color: ${(props) => props.theme.purple.dark};
      border: 0.1px solid ${(props) => props.theme.purple.dark};
    }
  }

  .istrue{
    opacity: 60%;
  }
`;
