import { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { ButtonArrow } from "../../../../../../components/Button/style.js";
import { Checked } from "../../../../../../components/Checked";
import { NotFound, Search } from "../../../../../../components/index.js";
import { ShimmerCard } from "../../../../../../components/Shimmers";
import { deleteUsersRole } from "../../../../../../services/requests/user-group-role.js";
import { setCheckFalse } from "../../../../../../util/verification.js";
import { Container, Header, Menu } from '../Promote/style';


const Remove = (props) => {
    const { personal, participants, roleId, updateMembersGroup, modal } = props;
    const [hidden, setHidden] = useState(false);
    const [users, setUsers] = useState<any>(setCheckFalse(participants))
    const { addToast } = useToasts();

    const updateUsers = () => {
        if (hidden) {
            const itens = users.filter(element => element.checked);
            removeParticipants(itens)
        }
    }

    const removeParticipants = (itens) => {
        var itensRemove: Array<any> = []
        itens.map((user) => {
            itensRemove.push({ id: user.id, roleBegin: user.roleBegin })
            return user
        })



        deleteUsersRole(personal.Group_id, roleId, itensRemove).then((res) => {
            if (res.status == 200) {
                const message = res.data.message.pt
                addToast({
                    title: message,
                    description: `Membros removidos da função `

                },
                    { appearance: 'success' });
                let difference = participants.filter(x => !itens.some(user => user.id === x.id));
                updateMembersGroup(difference)
                modal.current.close()
            }
        })
    }

    return (
        <Container three={true}>
            <Header>
                <div>
                    <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                    <h5>Funções - Remover</h5>
                </div> 
            </Header> 
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    { users && setUsers(result) }
                }} />
                   <ButtonArrow onClick={() => updateUsers()}>
                    <AiFillCheckCircle id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>
            </Menu>
         
            <div>
                {users ?
                    users.length ?
                        <Checked users={users} setUsers={setUsers} setHidden={setHidden} />
                        :
                        <NotFound text="Não há membros para adicionar" />
                    :
                    <div>
                        <ShimmerCard height={"75px"} />
                        <ShimmerCard height={"75px"} />
                    </div>
                }
            </div>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        personal: state.chat.personal,
    }
}

export default connect(mapStateToProps)(Remove);