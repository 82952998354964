import styled from "styled-components";
import { mobile, notebook, tablet } from "../../../../../../components/NavBar/style";

export const BlocoConta = styled.div`
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
  align-items: flex-end;
  margin-bottom: 2rem;
  justify-content: flex-start;
  height: 100%;
  gap: 1.5rem;

  color: ${({ theme }) => theme.texts.darkerGreyII};
  @media only screen and (max-width: ${mobile}) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: ${notebook}) {

    margin-bottom: 0.5rem;
  }
  .column-content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .buy-storage {
    font-family: "Inter";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    text-align: left;
    padding: 0px;
    color: ${({ theme }) => theme.texts.contentCards};

  }
  hr {
    height: 50%;
    width: 0px;
    border-left: 2px solid ${({ theme }) => theme.texts.darkGrey};
  }
  .center {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .line {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .noMargin {
    margin: 3px 0px 0px 0px !important;
  }

  p {
    margin: 0px;
    color: ${({ theme }) => theme.texts.contentCards};

  }

  .p_title_information {
    font-family: "Inter";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  .p_information {
    font-family: "Inter";
    font-weight: 200;
    font-size: 16px;

    margin-bottom: 0.5rem;
  }

  .div_informacoes_conta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height:172px;
  }

  .circularBar {
    align-self: center;
    width: 9rem;
    height: 9rem;
  }

  .circular {
    top: -103% !important;
  }

  .lineBar {
    margin-top: 4px;
  }

  .div_armazenamento {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;

    .used {
      color: ${({ theme }) => theme.texts.black};
    }
  }

  .p_porcentagem {
    color: ${({ theme }) => theme.texts.black};
    text-align: center;
    font-family: "Inter";
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.9375rem; /* 117.5% */
    display: flex;
    align-items: center;
  }

  .p_armazenamento_total {
  }

  .p_texto_armazenamento {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${({ theme }) => theme.texts.darkerGrey};
  }

  @media only screen and (max-width: 430px) {
    .p_porcentagem {
      font-size: 30px;
    }
    .p_texto_armazenamento {
      font-size: 11px;
    }
  }
`;
