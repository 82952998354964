import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconChatFill = ({
  color = "#700540",
  size = 24,
  ...rest
}: SVGComponent) => {
  const groupTransform = `scale(${size / 24 || 1})`; // Escala proporcional com base no tamanho

  return (
    <Icon color={color} size={size} {...rest}>
      <g transform={groupTransform} clipPath="url(#clip0_5707_617)">
        <g clipPath="url(#clip1_5707_617)">
          <path
            d="M12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C10.0587 24.0026 8.14595 23.5322 6.42721 22.6296L1.27921 23.97C1.1072 24.0148 0.926463 24.0139 0.754919 23.9673C0.583376 23.9207 0.426979 23.8301 0.301229 23.7045C0.175479 23.5789 0.084742 23.4226 0.0380108 23.2511C-0.00872034 23.0796 -0.00982328 22.8988 0.0348114 22.7268L1.37401 17.58C0.468971 15.8594 -0.00266359 13.9441 1.13149e-05 12C1.13149e-05 5.3724 5.37241 0 12 0ZM13.5024 13.2H8.10001L7.97761 13.2084C7.76219 13.238 7.56479 13.3447 7.4219 13.5086C7.27901 13.6725 7.20028 13.8826 7.20028 14.1C7.20028 14.3174 7.27901 14.5275 7.4219 14.6914C7.56479 14.8553 7.76219 14.962 7.97761 14.9916L8.10001 15H13.5024L13.6236 14.9916C13.839 14.962 14.0364 14.8553 14.1793 14.6914C14.3222 14.5275 14.4009 14.3174 14.4009 14.1C14.4009 13.8826 14.3222 13.6725 14.1793 13.5086C14.0364 13.3447 13.839 13.238 13.6236 13.2084L13.5024 13.2ZM15.9 9H8.10001L7.97761 9.0084C7.76219 9.03803 7.56479 9.14466 7.4219 9.30856C7.27901 9.47246 7.20028 9.68255 7.20028 9.9C7.20028 10.1174 7.27901 10.3275 7.4219 10.4914C7.56479 10.6553 7.76219 10.762 7.97761 10.7916L8.10001 10.8H15.9L16.0224 10.7916C16.2378 10.762 16.4352 10.6553 16.5781 10.4914C16.721 10.3275 16.7997 10.1174 16.7997 9.9C16.7997 9.68255 16.721 9.47246 16.5781 9.30856C16.4352 9.14466 16.2378 9.03803 16.0224 9.0084L15.9 9Z"
            fill="inherit"
            />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5707_617">
          <rect width={size} height={size} fill="white" />
        </clipPath>
        <clipPath id="clip1_5707_617">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default IconChatFill;
