import { ReactNode } from 'react';
import { ContainerCard } from './style';

interface CardProps {
    display?: boolean;
    children?: ReactNode;
    marginBottom?: string;
}
const Card = ({ display, children, marginBottom = "24px" }: CardProps) => {
    const style = display ? { display: 'flex' } : {}
    return (
        <div style={{ width: "100%" }}>
            <ContainerCard $marginBottom={marginBottom} style={style}>
                {children}
            </ContainerCard>
        </div>
    );
}

export default Card;