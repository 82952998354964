import styled from "styled-components";

export const Default = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: white;
`;

export const Profile = styled.div`
  #profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    color: white;
    border: 1px solid ${(props) => props.theme.default.dark};
  }
`;

export const Card = styled.div`
  max-width: 425px;
  width: 95%;
  height: 100%;
  padding-inline: 1rem;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.backgrounds.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-inline: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.8rem;

  #title {
    color: ${({ theme }) => theme.begin.color.button.secondary};
    margin-bottom: 0;
  }

  h5 {
    margin-top: 0;
    color: ${(props) => props.theme.default.darker};
    font-weight: normal;
  }

  #link {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.blue.darkI};
    text-decoration: none;
    font-size: 13px;
    transition: 0.1s ease-in-out;
    &:hover {
      color: ${(props) => props.theme.blue.darker};
    }
  }

  #alternative {
    margin-top: 2rem;
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 13px;
    color: ${(props) => props.theme.default.darkerI};
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #row {
      display: flex;
    }
  }

  .betwin {
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
  }
`;

export const Button = styled.button`
  padding: 0.7rem;
  padding-inline: 2.5rem;
  cursor: pointer;
  color: white;
  border-radius: 30px;
  background: linear-gradient(267.35deg, #920b42 0.4%, #6f0c5f 100%);
  margin-top: 1rem;
`;

export const OutlineButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.texts.black};
  background: none;
  padding-inline: 1rem;
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.begin.color.button.secondary};
  transition: box-shadow 0.3s;
  margin-right: 5px;

  #icon {
    font-size: 20px;
    color: ${({ theme }) => theme.begin.color.button.secondary};
  }

  .right {
    margin-right: 4px;
  }

  &:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    background-color: ${(props) =>
      props.theme.title === "dark" ? "#5a9fd11a" : "#C6C6C6"};
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-content: center;
  justify-items: center;
  justify-content: space-around;
  place-items: center;
  overflow: hidden;
  min-height: 100%;
  background-color: red;
  .fig {
    max-width: 400px;
  }

  @media only screen and (max-width: 480px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    ${Card} {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      border-radius: 0;

      #title {
        margin-bottom: 8vh;
      }
      ${Button} {
        margin-top: 5vh;
        margin-bottom: 5vh;
      }
      #alternative {
        margin-top: 15vh;
      }
    }
  }
`;
