import { ContentImage, ContentText } from "pdfmake/interfaces";
import { useContext, useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { MdModeEdit } from "react-icons/md";
import {
  ContentHeader,
  ReportStylesInterface,
} from "../../../../../../types/project";
import ImageOptions from "../../FormContent/ImageOptions";
import TextOptions from "../../FormContent/TextOptions";
import { OptionTitle } from "../../style";
import { ReportContext } from "../../../../../../contexts/ReportContext";

interface ColumnsProps {
  position: number;
  value: ContentHeader;
  positionHeader: number;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderItemOption = ({
  value,
  positionHeader,
  position,
  setUpdate,
}: ColumnsProps) => {
  const { stylesPDF } = useContext(ReportContext);
  const isText = value.text ? true : false;
  const [valueTemp, setValueTemp] = useState<ContentHeader>(value);
  const [isEdit, setIsEdit] = useState(false);

  function saveChange() {
    stylesPDF.headerContent[positionHeader][position] = {
      ...value,
      ...valueTemp,
    };
    setIsEdit(false);
    setUpdate((oldUpdate) => !oldUpdate);
  }

  function deleteItem() {
    stylesPDF.headerContent[positionHeader].splice(position, 1);
    setIsEdit(false);
    setUpdate((oldUpdate) => !oldUpdate);
  }

  function closeEdit() {
    setValueTemp(value);
    setIsEdit(false);
  }

  return (
    <>
      <OptionTitle $borderTop={true}>
        <h5>{isText ? value.text : `Imagem ${position}`} </h5>
        <div className="buttons">
          {!isEdit ? (
            <>
              <FiTrash className="icon-color" onClick={deleteItem} size={18} />
              <MdModeEdit
                className="icon-color"
                onClick={() => setIsEdit(true)}
                size={18}
              />
            </>
          ) : (
            <>
              <BsXLg className="icon-color" onClick={closeEdit} size={18} />
              <BsCheckLg
                className="icon-color"
                onClick={saveChange}
                size={18}
              />
            </>
          )}
          {/* <IoIosArrowUp className={isOpen ? "center down" : "center up"} onClick={() => setIsEdit(true)} size={18} /> */}
        </div>
      </OptionTitle>
      {valueTemp.text ? (
        <TextOptions
          isEditing={isEdit}
          valueText={valueTemp as ContentText}
          setValueText={
            setValueTemp as React.Dispatch<React.SetStateAction<ContentText>>
          }
        />
      ) : (
        <ImageOptions
          isEdit={isEdit}
          valueImage={valueTemp as ContentImage}
          setValueImage={
            setValueTemp as React.Dispatch<React.SetStateAction<ContentImage>>
          }
        />
      )}
    </>
  );
};

export default HeaderItemOption;
