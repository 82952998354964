import Card from "../Card";
import { ShimmerCard, ShimmerCircle, ShimmerLine } from "../Shimmers";

const SkeletonBirthdays = () => {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <ShimmerCard height="20px" marginBottom="1rem" width="120px" />
      <div>
        <ShimmerCircle size="55px" />
        <ShimmerCircle size="55px" />
        <ShimmerCircle size="55px" />
      </div>
    </div>
  );
};

export default SkeletonBirthdays;
