import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from '../../../assets/icons';
import { LogoNoturno } from '../../../assets/images';
import BillingToggle from './billing-toggle.component';
import PlansList from './plans-list.component';
import {
  Container, Content, Header
} from './style';

function PlansPage() {
  const [billing, setBilling] = useState('mensal');
  const {goBack} = useHistory()

  return (
    <Container>
      <Header>
        <div className="content">
          <span className="back" onClick={goBack}>
            <IoIosArrowBack className="icon" />
            <p>Voltar</p>

          </span>
          <span className="title">Planos de Assinatura</span>
        </div>
        <img className="logo" src={LogoNoturno} alt="Logo AbaréNet" />
      </Header>
      <Content>
        <h1 className="title">
          <span className="orange">Conecte-se.&nbsp;</span>
          <span className="green">Construa.&nbsp;</span>
          <span className="red">Gerencie.</span>
        </h1>
        <p className="subtitle">Assine para usufruir de todos os recursos da plataforma</p>

        <BillingToggle onChange={(billingType) => {
          setBilling(billingType);
        }}
        />
        <PlansList billingType={billing} />
      </Content>
    </Container>
  );
}

export default PlansPage;
