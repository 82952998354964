import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .display {
    display: none;
    position: absolute;
    width: 300px;
    height: 280px;
    bottom: 100%;
    left: -5px;
    transition: 0s display;
    border-radius: 10px;
    padding: 0;
    margin: 2px 0 0 0;
    list-style-type: none;
    z-index: 9;
    overflow: hidden;

    .mb{
      height: 260px;
    }
  }

  &:hover{
    .display{
        display: block;
    }
  }
`;
