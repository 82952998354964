import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconDot = ({ color = "#666666", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M13.5 12C13.5 12.2967 13.412 12.5867 13.2472 12.8334C13.0824 13.08 12.8481 13.2723 12.574 13.3858C12.2999 13.4994 11.9983 13.5291 11.7074 13.4712C11.4164 13.4133 11.1491 13.2704 10.9393 13.0607C10.7296 12.8509 10.5867 12.5836 10.5288 12.2926C10.4709 12.0017 10.5006 11.7001 10.6142 11.426C10.7277 11.1519 10.92 10.9176 11.1666 10.7528C11.4133 10.588 11.7033 10.5 12 10.5C12.3978 10.5 12.7794 10.658 13.0607 10.9393C13.342 11.2206 13.5 11.6022 13.5 12Z"
        fill={color}
      />
    </Icon>
  );
};

export default IconDot;
