import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconPlans = ({ color = "#A6446A", size = 24, ...rest }: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M3.73047 4.06252H16.2305C16.3765 4.06245 16.5224 4.07171 16.6672 4.09025C16.6181 3.74563 16.4997 3.41452 16.3192 3.11689C16.1387 2.81925 15.8998 2.56126 15.6168 2.35846C15.3339 2.15565 15.0129 2.01225 14.673 1.93688C14.3332 1.86152 13.9816 1.85575 13.6395 1.91994L3.35938 3.67502H3.34766C2.70237 3.79841 2.12854 4.1635 1.74336 4.69572C2.32367 4.28296 3.01834 4.0616 3.73047 4.06252ZM16.2305 5.00002H3.73047C3.06765 5.00074 2.43219 5.26437 1.9635 5.73305C1.49482 6.20173 1.23119 6.8372 1.23047 7.50002V15C1.23119 15.6628 1.49482 16.2983 1.9635 16.767C2.43219 17.2357 3.06765 17.4993 3.73047 17.5H16.2305C16.8933 17.4993 17.5288 17.2357 17.9974 16.767C18.4661 16.2983 18.7297 15.6628 18.7305 15V7.50002C18.7297 6.8372 18.4661 6.20173 17.9974 5.73305C17.5288 5.26437 16.8933 5.00074 16.2305 5.00002ZM14.375 12.5C14.1278 12.5 13.8861 12.4267 13.6805 12.2894C13.475 12.152 13.3148 11.9568 13.2201 11.7284C13.1255 11.5 13.1008 11.2486 13.149 11.0062C13.1972 10.7637 13.3163 10.5409 13.4911 10.3661C13.6659 10.1913 13.8887 10.0723 14.1311 10.024C14.3736 9.9758 14.6249 10.0006 14.8534 10.0952C15.0818 10.1898 15.277 10.35 15.4143 10.5556C15.5517 10.7611 15.625 11.0028 15.625 11.25C15.625 11.5815 15.4933 11.8995 15.2589 12.1339C15.0245 12.3683 14.7065 12.5 14.375 12.5Z"
        fill="inherit"
      />
      <path
        d="M1.25 10.1367V6.25C1.25 5.40352 1.71875 3.98437 3.3457 3.67695C4.72656 3.41797 6.09375 3.41797 6.09375 3.41797C6.09375 3.41797 6.99219 4.04297 6.25 4.04297C5.50781 4.04297 5.52734 5 6.25 5C6.97266 5 6.25 5.91797 6.25 5.91797L3.33984 9.21875L1.25 10.1367Z"
        fill="inherit"
      />
    </Icon>
  );
};

export default IconPlans;
