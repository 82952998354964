import styled from "styled-components";
import { tablet } from "../../../../../components/NavBar/style";

interface styledProps {
  rightContent?: any;
}

export const AsideContent = styled.aside<styledProps>`
  display: flex;
  /* transition: width 0.5s ease; */

  flex-direction: column;
  display: ${({ rightContent }) => (rightContent ? "block" : "none")};
  width: ${({ rightContent }) => (rightContent ? "350px" : "0px")};
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  ${({ rightContent }) =>
    rightContent
      ? `overflow: hidden;
    overflow-y: auto;`
      : `  overflow: visible;
    `}
  overflow: hidden;

  /* min-width: 400px; */

  @media only screen and (max-width: ${tablet}) {
    min-width: 100%;
  }
`;
export const Container = styled.div`
  display: flex;
  padding: 1rem 0rem;
  /* transition: width 0.5s ease; */
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgrounds.white};
  color: ${({ theme }) => theme.texts.black};
  & > * {
    padding: 0px 1rem;
  }

  .up {
    transform: rotate(1deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  .down {
    transform: rotate(180deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }
  .title_line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    font-size: 1rem;
  }
  .semi-title {
    font-family: "inter-medium", sans-serif;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.texts.purpleDarkTitle};
    color: white;
    border-radius: 100%;
    height: 18px;
    width: 18px;
    padding: 4px;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  h3 {
    text-align: center;
    align-self: center;
    margin-top: 1rem;
    padding: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }
  hr {
    background-color: rgb(197, 197, 197);
    height: 1px;
    width: 100%;
  }
  .visual-file {
    display: flex;
    flex-direction: column;
  }
  .media {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 14px;
    }
    svg {
      width: 60px;
      height: auto;
    }
  }
  .name-file {
    margin-bottom: 1rem;
    overflow: hidden;
    word-wrap: break-word;
    white-space: break-spaces;
    font-family: "inter-medium", sans-serif;
    font-size: 1rem;
  }

  .properties-file {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    p {
      margin: 0px 0px 8px 0px;
    }
  }

  .title {
    font-size: 1.1rem;
    font-family: "inter-semi-bold", sans-serif;
    margin-bottom: 8px;
  }

  .information_block {
    display: grid;
    grid-template-columns: 100px 1fr;
  }
  .line {
    display: grid;
    grid-template-columns: 52px 1fr 68px;
    align-items: center;
    grid-column-gap: 1rem;

    margin-top: 8px;
  }
  .info-edit {
    display: flex;
    flex-direction: row;
    padding: 0.4rem 0.2rem;
    align-items: center;
    font-size: 0.9rem;
    gap: 0.4rem;
    border-radius: 4px;
    font-family: "inter-medium", sans-serif;
    cursor: pointer;
    transition: background-color 0.2s ease;
    height: fit-content;
    :hover {
      background-color: ${({ theme }) =>
        theme.color.secondary_cinza.navigation_drawer};
    }
  }
  .user_img {
    height: 52px;
    width: 52px;
    object-fit: cover;

    border-radius: 100%;
  }
  .user_informations {
    display: flex;
    flex-direction: column;
  }

  p {
    margin: 0px;
    padding: 0px;
  }

  .user_name {
    font-family: "inter-bold";
    font-size: 1rem;
  }

  .user_nickname {
    font-size: 0.9rem;
  }
`;

export const ContainerDrop = styled.ul`
  background-color: white;
  border-radius: 8px;
  padding: 0px;
  list-style-type: none;
  min-width: 100px;
  padding: 0.5rem;
  z-index: 500;
  li {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: background-color 0.2s ease;
    padding: 0.2rem 0rem;
    gap: 0.2rem;
    :hover {
      background-color: ${({ theme }) =>
        theme.color.secondary_cinza.navigation_drawer};
    }
  }
  hr {
    width: 100%;
    height: 1px;
  }
`;
