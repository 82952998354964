import {
  SET_BIRTHHDAYS_DASH,
  SET_COMMITMENT_DASH,
  SET_NOTIFICATIONS_DASH, SET_WEATHER_DASH
} from "../actions/actionTypes";

const initialState = {
  birthdays_dash: null,
  commitment_dash: null,
  weather_dash: null,
  notifications_dash: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BIRTHHDAYS_DASH:
      return {
        ...state,
        birthdays_dash: action.payload,
      };
    case SET_COMMITMENT_DASH:
      return {
        ...state,
        commitment_dash: action.payload,
      };
    case SET_WEATHER_DASH:
      return {
        ...state,
        weather_dash: action.payload,
      };
    case SET_NOTIFICATIONS_DASH:
      return {
        ...state,
        notifications_dash: action.payload,
      };

    default:
      return { ...state };
  }
}
