import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-image: none;
  border-radius: 16px;
  height: 225px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(145, 158, 171, 0.24);
  box-sizing: border-box;
  .truncate {
    max-height: 2.8em; /* Duas linhas de texto com algum espaço extra para os três pontos */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4em; /* Altura de uma linha de texto */
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.hover.lightGrey};
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .buttonDrop {
      border-radius: 50%;
      padding: 2px;
      :hover {
        background-color: ${({ theme }) => theme.hover.lightGreyIII};
      }
    }
    p {
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    width: 100%;
    button {
      padding: 0px !important;
    }
  }
  .media {
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 50%;
      height: 50%;
    }
    img {
      border-radius: 10%;
      object-fit: cover;
      height: inherit;
      width: inherit;
    }
  }
`;
