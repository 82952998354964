import { ChangeEvent, useCallback, useRef, useState } from "react";
import { TbArrowBack } from "react-icons/tb";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Webcam from "react-webcam";
import { CropImage, Emoji } from "..";
import { AiOutlineCamera, AiOutlinePlus, AiOutlineSend, FiCrop, GrEmoji, HiCheck, IoIosClose } from "../../assets/icons";
import { setMessage } from "../../store/actions/messages/messageAction";
import { TypeImage, TypeMessage } from "../../types/project";
import { ToastModel } from "../../util/error";
import { getTypeMessage, isFile, photoUrl } from "../../util/verification";
import ItemFile from "../ItemFile";
import {
    Btn, BtnRemove, CropPanel, Input, Item, MainItem, ModalContainer, Navbar, PicturePanel, PreviewPanel, ScrollVertical
} from "./style";

const mapStateToProps = (state) => {
    return {
        personal: state.chat.personal,
        packageInfo: state.abare.abare.packageInfo,
        socket: state.socket
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMessage(message) {
            dispatch(setMessage(message));
        }
    }
}

const Preview = (props) => {
    const { packageInfo, personal, files, setFiles, modal, setMessage } = props;
    const cropRef = useRef<any>(null)
    const [menu, setMenu] = useState(1);
    const fileInput = useRef<HTMLInputElement>(null);
    const [cropIndex, setCropIndex] = useState<any>(-1);
    const { addToast } = useToasts()

    const close = () => {
        modal.current.close();
    }

    const CropPreview = () => {
        const crop = async () => {
            const data = await cropRef.current.cropImage();
            let auxFiles = [...files];
            auxFiles[cropIndex] = { ...auxFiles[cropIndex], ...data };
            setFiles(auxFiles)
            setMenu(1)
        }

        return (
            <ModalContainer>
                <CropPanel>
                    <Navbar>
                        <div className="sendFor">
                            Enviar para
                            <img className="photoUrl" src={photoUrl(personal)} />
                        </div>
                        <div className="options">

                        </div>
                        <Btn className="self-end" onClick={() => close()}>
                            <IoIosClose id="close" />
                        </Btn>
                    </Navbar>
                    <div className="content-crop">
                        <CropImage className="my-crop" height="80%" ref={cropRef} url={files[cropIndex].defaultUrl} />
                    </div>
                    <div className="navFooter">
                        <button className="disabled" onClick={() => crop()}>
                            <HiCheck />
                        </button>
                        <button className="disabled" onClick={() => (setMenu(1))}>
                            <IoIosClose />
                        </button>
                    </div>
                </CropPanel>
            </ModalContainer>
        );
    }

    const MainPreview = () => {
        const [text, setText] = useState('');
        const [index, setIndex] = useState(cropIndex >= 0 ? cropIndex : files.length - 1);
        const currentFile = files[index]

        const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
            const list: File[] = Array.from(e.target.files ? e.target.files : [])
            var filesAdd: TypeImage[] = [];

            list && list.forEach((file: File) => {
                const limitation = isFile(file.type) ? packageInfo.fileSend :packageInfo.mediaSend;
                if (file.size < (limitation  * 1000000)) {
                    const url = URL.createObjectURL(file);
                    const type = getTypeMessage(file);
                    filesAdd.push({ defaultUrl: url, newUrl: url, file: file, type: type });
                } else {
                    ToastModel(addToast, "Arquivo grande!", "O arquivo excedeu o tamanho permitido para seu plano", 'error');
                }
            });

            setFiles([...files, ...filesAdd]);
        }

        const removeItem = (position) => {
            files.splice(position, 1);

            if (files.length) {
                files.length == index && setIndex(0)
                setFiles([...files]);
            } else {
                close();
            }
        }
        const verifyResizeFile = (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            const newFile = new Promise((resolve) => {
                reader.onload = (e: any) => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = (e: any) => {
                        var { height, width } = e.target;
                        const pixelsImage = width * height;
                        const pixelsPermission = 1920 * 1080;
                        const isGreaterLimitPixels = pixelsImage > pixelsPermission;
                        if (isGreaterLimitPixels) {
                            const reducer = Math.sqrt(pixelsImage / pixelsPermission);
                            width = Math.floor(width / reducer);
                            height = Math.floor(height / reducer);
                            resolve(resizeFile(file, width, height));
                        }
                        resolve(file);
                    };
                };
            });

            return newFile;
        }

        const resizeFile = (file, width, height) => {
            return new Promise((resolve) => {
                Resizer.imageFileResizer(file, width, height, "JPEG", 100, 0,
                    (uri) => {
                        resolve(uri);
                    },
                    "file"
                );
            });
        }

        const sendMessage = async () => {
            await Promise.all(files.map(async (file) => {
                const newFile = file.type == TypeMessage.IMAGE ? await verifyResizeFile(file.file) : file.file;
                setMessage({
                    description: text,
                    blobUrl: file.newUrl,
                    fileURL: file.newUrl,
                    file: newFile,
                    fileType: file.type,
                })
            }));
            close();
        }

        return (
            <ModalContainer>
                <PreviewPanel>
                    <Navbar>
                        <div className="sendFor">
                            Enviar para
                            <img className="photoUrl" src={photoUrl(personal)} />
                        </div>
                        {currentFile.type == TypeMessage.IMAGE ?
                            <div className="options">
                                <Btn onClick={() => (setMenu(2), setCropIndex(index))}>
                                    <FiCrop className="icons" />
                                </Btn>
                                {/*   <Btn>
                                    <FiEdit2 className="icons" />
                                </Btn> */}

                            </div>
                            :
                            <h5 className="name-file">{currentFile.file.name}</h5>
                        }
                        <Btn className="self-end" onTouchEnd={() => close()} onClick={() => close()}>
                            <IoIosClose onClick={(e) => (e.stopPropagation(), close())} size={24} id="close" />
                        </Btn>
                    </Navbar>
                    <div className="image-centered">
                        {currentFile.type == TypeMessage.IMAGE || currentFile.type == TypeMessage.VIDEO ?
                            <ItemFile className="img-video" controls type={currentFile.type} src={currentFile.newUrl} />
                            :
                            <MainItem>
                                <ItemFile className="img-video" type={currentFile.type} />
                            </MainItem>
                        }

                        <ScrollVertical>
                            <div className="itens">
                                <input ref={fileInput} style={{ display: "none" }}
                                    onChange={(e) => handleFileSelected(e)} multiple type="file" accept="aplication/*" />
                                {files && files.map(({ newUrl, type }, i) => (
                                    <Item key={i} >
                                        <BtnRemove onClick={() => removeItem(i)}><IoIosClose size={30} /></BtnRemove>
                                        <div className="media" onClick={() => setIndex(i)}>
                                            <ItemFile className="img-video" type={type} src={newUrl} />
                                        </div>

                                    </Item>
                                ))}
                                <button className="more" onClick={() => fileInput.current?.click()}>
                                    <AiOutlinePlus size={35} />
                                </button>
                            </div>
                        </ScrollVertical>
                    </div>
                    <div className="previewFooter">
                        <Emoji text={text} setText={setText}>
                            <GrEmoji className="option" size={28} />
                        </Emoji>
                        <Input>
                            <input placeholder="Digite uma mensagem..."
                                value={text}
                                onChange={e => setText(e.target.value)}
                                onKeyPress={e => e.key === "Enter" ? sendMessage() : null}

                            />
                        </Input>
                        <Btn type="submit" onClick={() => sendMessage()}>
                            <AiOutlineSend className="send" size={28} />
                        </Btn>
                    </div>
                </PreviewPanel >
            </ ModalContainer>
        );
    }

    const MenuPreview = () => {
        switch (menu) {
            case 1:
                return <MainPreview />
            case 2:
                return <CropPreview />
            default:
                return <MainPreview />
        }
    }

    return (
        <MenuPreview />
    );
}

const MainPreview = connect(mapStateToProps, mapDispatchToProps)(Preview);

const TakePicture = (props) => {
    const { modal, personal, test } = props;
    const webcamRef = useRef<any>(null);
    // const [image, setImage] = useState();
    const [files, setFiles] = useState<any[]>([{ defaultUrl: "", newUrl: "", file: {} }]);
    const fileInput = useRef<HTMLInputElement>(null);

    const close = () => {
        modal.current.close();
    }

    const capture = useCallback(
        () => {
            const base64 = webcamRef.current.getScreenshot();
            fetch(base64).then(res => res.blob()).then(blob => {
                const file = new File([blob], "mypicture", { type: "image/png" })
                const url = URL.createObjectURL(file);
                const type = getTypeMessage(file);
                setFiles([{ ...files[0], defaultUrl: url, newUrl: url, type: type, file: file }])
            })
        },
        [webcamRef]
    );

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };

    const Picture = () => (
        <ModalContainer>
            <PicturePanel>
                <Navbar>
                    <div className="sendFor">
                        Enviar para
                        <img className="photoUrl" src={photoUrl(personal)} />
                    </div>
                    <div className="principal">
                        Tirar foto
                    </div>
                    <Btn className="self-end back" onClick={() => close()}>
                        <IoIosClose id="close" />
                    </Btn>
                </Navbar>
                <div className="centered">
                    <div id="recordVideo">
                        <Webcam
                            audio={false}
                            mirrored={true}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            height="100%"
                            width="100%"
                        />
                    </div>
                </div>
                <div className="navFooter">
                    <button onClick={() => close()} className="active back-mobile">
                        <TbArrowBack id="close" />
                    </button>
                    <button onClick={() => capture()} className="active camera">
                        <AiOutlineCamera />
                    </button>
                    {/* <button className="disabled">
                        <AiOutlineVideoCamera />
                    </button> */}
                </div>
            </PicturePanel>
        </ModalContainer>

    );


    return (
        !files[0].defaultUrl ?
            <Picture />
            :
            <MainPreview modal={modal} files={files} setFiles={setFiles} />
    );
}

const MainPicture = connect(mapStateToProps)(TakePicture);

export { MainPicture, MainPreview };
