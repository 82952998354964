import { api } from '../api';
import { getToken } from "../auth";

export const updateReadMessageGroup = (userId, groupId) => {
    return api({
        url: `/message-group-user/${userId}/${groupId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchVisibleMessageGroup = (userId, groupId, msgId) => {
    return api({
        url: `/message-group-user/one-message/${userId}/${groupId}/${msgId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchVisibleAllMessageGroup = (userId, groupId) => {
    return api({
        url: `/message-group-user/all-messages/${userId}/${groupId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};