import { BiSelectMultiple } from 'react-icons/bi';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { bindActionCreators } from 'redux';
import { BsCameraVideoFill, IoIosArrowBack, MdMoreVert } from '../../../../../assets/icons';
import { Dropdown } from '../../../../../components';
import Img from '../../../../../components/Img';
import { getICanCall } from '../../../../../services/requests/privacy';
import { setDetails, setNavBar } from '../../../../../store/actions/abareAction';
import { setPersonal } from '../../../../../store/actions/messages/chatAction';
import { TypePersonal } from '../../../../../types/project';
import { ToastModel } from '../../../../../util/error';
import { photoUrl } from "../../../../../util/verification";
import { Button, Container } from './style';

const Header = (props) => {
    const { abareId, personal, setPersonal, setNavBar, setSelectMessages } = props;
    const { push } = useHistory();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const openProfile = () => {
        const url = personal.type == TypePersonal.GROUP ? 'group' : 'personal'
        push('/home/conversations/chat/' + url)
        dispatch(setDetails(true));
    }

    const { User_id_0, User_id_1 } = personal
    const contactId = ([User_id_0, User_id_1].filter(id => id !== abareId))[0]
    const history = useHistory();

    const callUser = () => {
        getICanCall(contactId).then((res) => {
            if (res.status == 200) {
                if (res.data) {
                    history.push({
                        pathname: '/call',
                        state: {
                            userToCall: contactId
                        }
                    })
                } else {
                    ToastModel(addToast, "Não autorizado a ligar", "Você não pode ligar para essa pessoa", 'error');
                }
            }
        })
    }

    return (
        <Container>
            <Button className="back" onClick={() => {
                setPersonal(null)
                setNavBar(true)
                push('/home/conversations');
            }}>
                <IoIosArrowBack size={22} />
            </Button>
            <button onClick={() => openProfile()} className="flex-row">
                <div className='content-profile'>
                    <Img className="profile" src={photoUrl(personal)} />
                </div>
                <h2 id="name">{personal.name ? personal.name : "@" + personal.nickname}</h2>
            </button>
            {personal.type === TypePersonal.PERSONAL &&
                <div className='buttons'>
                    {/*  <Button>
                        <IoIosCall className="icon" />
                    </Button> */}
                    {/* <Button onClick={callUser}>
                        <BsCameraVideoFill className="icon" />
                    </Button> */}
                </div>
            }

            <Dropdown defaultClass="bottom-left">
                <Button>
                    <MdMoreVert className="icon_drop" />
                </Button>
                <div>
                    <li onClick={() => { setSelectMessages(true) }}> <BiSelectMultiple className="icon" /> Selecionar mensagens</li>
                </div>
            </Dropdown>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    abareId: state.abare.abare.id,
    personal: state.chat.personal
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setPersonal, setNavBar
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header);