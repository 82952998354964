import styled from "styled-components";

export const Month = styled.div`
  p {
    font-size: 16px;
    font-family: "inter-medium", sans-serif;
    color: ${(props) => props.theme.texts.darkerGreyII};
    margin-top: 0px;

  }

  .empty{
    margin-top: 1rem;
  }
  .div-img{
    display: inline-flex;

    :hover{
      cursor: pointer;
    }
  }

  .spaceImage {
    margin-right: 5px;
  }
`;
