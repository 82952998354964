import { RefObject, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { BsPlusLg } from "../../../../../assets/icons";
import { Search } from "../../../../../components";
import { ModalRef } from "../../../../../components/Modal";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import { ShimmerCard } from "../../../../../components/Shimmers";
import { getCategorys } from "../../../../../services/requests/event-category";
import { TCategory } from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import AddCategory from "./Add";
import { Filter, ItemCategory, List, Menu, Pointer } from "./style";

const ATIVA = true
const INATIVA = false;
const TODAS = null;

enum MenuOption {
    LIST = "1",
    ADD = "2",
    VIEW = "3"
}

interface ListCategoryPros {
    modal: RefObject<ModalRef>;
    setMenu: any,
    setCategory: any,
    categorys: TCategory[] | undefined,
    setCategorys: any,
    isLoading: boolean,
}

const ListCategory = ({ modal, setMenu, setCategory, categorys, setCategorys, isLoading }: ListCategoryPros) => {

    const [optRadio, setOptRadio] = useState<boolean | null>(TODAS);
    const [checkedState, setCheckedState] = useState<boolean | null>(null);
    const changeRadio = (active: boolean | null) => {
        setCheckedState(active);
        setOptRadio(active)
    }

    const filter = (category: TCategory) => {
        if (checkedState == ATIVA) {
            return category.active;
        }
        if (checkedState == INATIVA) {
            return !category.active;
        }
        return true;
    }
    return (
        <ModalContainer padding={false} title="Categorias Personalizadas" onClick={() => modal.current?.close()} buttonBack={true}>
            <Menu>
                <Search itens={categorys || []} onSearch={(result) => {
                    { categorys && (setCategorys(result), setOptRadio(TODAS)) }
                }} />
                <div className="icon-circle" onClick={() => { setMenu(MenuOption.ADD) }}>
                    <BsPlusLg id="icon" />
                </div>
            </Menu>
            <Filter>
                <input id="ativas" type="radio" name="radio-1" checked={optRadio == ATIVA} onChange={() => { changeRadio(ATIVA) }} />
                <label htmlFor="ativas">Ativas</label>
                <input id="inativas" type="radio" name="radio-1" checked={optRadio == INATIVA} onChange={() => { changeRadio(INATIVA) }} />
                <label htmlFor="inativas">Inativas</label>
                <input id="todas" type="radio" name="radio-1" checked={optRadio == TODAS} onChange={() => { changeRadio(TODAS) }} />
                <label htmlFor="todas">Todas</label>
            </Filter>
            <List>
                {(!isLoading && categorys) ?
                    categorys.filter(filter).length ?
                        categorys.filter(filter).map((res, index) => (
                            !res.hidden ? <ItemCategory key={index} onClick={() => { setCategory(res); setMenu(MenuOption.VIEW) }}>
                                <Pointer active={res.active} />
                                <h3>
                                    {res.name}
                                </h3>
                            </ItemCategory>
                                :
                                null

                        ))

                        :
                        <h3>Sem categorias</h3>

                    :
                    <div>
                        <ShimmerCard height={"75px"} />
                        <ShimmerCard height={"75px"} />
                        <ShimmerCard height={"75px"} />
                    </div>
                }
            </List>
        </ModalContainer>
    )
}

interface ManagerCategoryPros {
    modal: RefObject<ModalRef>;
}

export const ManagerCategory = ({ modal }: ManagerCategoryPros) => {
    const [menu, setMenu] = useState<MenuOption>(MenuOption.LIST)
    const { data, isLoading, isSuccess } = useQuery<TCategory[]>(Queries.CATEGORIES_EVENT, getCategorys);
    const [category, setCategory] = useState<TCategory | undefined>(undefined);
    const [categorys, setCategorys] = useState<TCategory[]>([])
    useEffect(() => {
        if (isSuccess && data) {
            setCategorys(data);
        }
    }, [isSuccess]);

    switch (menu) {
        case MenuOption.ADD:
            return <AddCategory setMenu={setMenu} setCategorys={setCategorys} categorys={categorys} />
        case MenuOption.VIEW:
            return <AddCategory setMenu={setMenu} category={category} setCategorys={setCategorys} categorys={categorys} />
        default:
            return <ListCategory isLoading={isLoading}
                modal={modal} setMenu={setMenu}
                setCategory={setCategory} categorys={data} setCategorys={setCategorys} />
    }
}