import { useState } from "react";
import { HiCheck } from '../../assets/icons';
import { Circle, Container, NameColor } from "./style";

const colors = [
    { name: "Padrão", hex: "#00BFFF" },
    { name: "Azul", hex: "#455178" },
    { name: "Roxo", hex: "#700540" },
    { name: "Rosa", hex: "#D14F7C" },
    { name: "Roxo", hex: "#4F41C4" },
    { name: "Verde", hex: "#417926" },
    { name: "Cinza", hex: "#808080" },
    { name: "Amarelo", hex: "#F1D21C" },
    { name: "Marrom", hex: "#5C4801" },
]

const Colors = (props) => {
    const { color } = props
    const [value, setValue] = useState(color ? color : "#00BFFF");
    const { defaultValue } = props;

    return (
        <Container>
            {colors.map(({ name, hex }, i) => (
                <label key={name + i} className="label-color">
                    <Circle color={hex} onClick={() => setValue(hex)}>
                        {value === hex && <HiCheck />}
                    </Circle>
                    <NameColor className={value === hex ? "show-name" : "none"} color={hex}>
                        {name}
                    </NameColor>
                </label>
            ))}
            <input hidden name="color" id="color" type="text" value={value} onChange={() => { }} />
        </Container>
    );
}

export default Colors