import { api } from '../api';
import { getToken } from "../auth";

export const postMessageGroup = (data) => {
    return api({
        url: "/message-group",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: data
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getMessagesGroup = (groupId, page) => {
    return api({
        url: `/message-group/${groupId}?page=${page}&size=15`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteMessageGroup = (groupId, msgIdGroup) => {
    return api({
        url: `/message-group/${groupId}/${msgIdGroup}`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};