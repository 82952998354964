import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { connect, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { AiFillAudio, AiOutlineAudio, AiOutlineCamera, AiOutlineClose, AiOutlineFileText, AiOutlinePaperClip, AiOutlineSend, AiOutlineVideoCamera, BsImages, GrEmoji, HiCheck, IoIosClose, MdAudiotrack } from "../../../../../assets/icons";
import { Dropdown, Emoji, ItemFile, MainPicture, MainPreview, Modal } from "../../../../../components";
import { deleteUserBlock } from "../../../../../services/requests/privacy";
import { SET_ANSWER } from "../../../../../store/actions/actionTypes";
import { updateClient } from "../../../../../store/actions/messages/chatAction";
import { setMessage } from "../../../../../store/actions/messages/messageAction";
import { TMessage, TypeImage, TypeMessage, TypePersonal } from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import { getTypeMessage, isFile, isFilled, userIdContact } from "../../../../../util/verification";
import AudioRecorderFunc from "./AudioRecorder";
import SelectMessages from "./SelectMessages";
import { Btn, Container, ContainerAnswer, ContainerBlock, Flex, Input } from './style';

const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);
    if (!stream) {
        return null;
    }
    return <video ref={videoRef} width={400} height={400} autoPlay controls />
};

const RecordVideo = () => {
    const {
        status,
        startRecording,
        stopRecording,
        mediaBlobUrl,
        previewStream,
    } = useReactMediaRecorder({ video: true });

    return (
        <div>
            <VideoPreview stream={previewStream} />
            <p>{status}</p>
            <button onClick={startRecording}>Start Recording</button>
            <button onClick={stopRecording}>Stop Recording</button>
            <video src={mediaBlobUrl ? mediaBlobUrl : ""} width={200} height={200} controls />
        </div>
    );
};

const Footer = (props) => {
    const { personal, abareId, photoUrl, setMessage, updateClient, socket, packageInfo, selectMessages, setSelectMessages, test, setTest } = props
    const answer: TMessage = useSelector((state: TypeConstRedux) => state.chat.answer)
    const dispatch = useDispatch()
    const [text, setText] = useState("");
    const fileInput = useRef<HTMLInputElement>(null);
    const imageInput = useRef<HTMLInputElement>(null);
    const videoInput = useRef<HTMLInputElement>(null);
    const audioInput = useRef<HTMLInputElement>(null);
    const [previewAudio, setPreviewAudio] = useState(false);
    const modalRecordVideo = useRef<any>();
    const modalPreview = useRef<any>();
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const { addToast } = useToasts()

    const { GROUP } = TypePersonal;

    useEffect(() => {
        if (answer) {
            inputRef.current?.focus()
        }
    }, [answer])

    const [files, setFiles] = useState<any[]>([]);

    const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
        video: false,
        audio: true,
        blobPropertyBag: {
            type: "audio/mpeg"
        }
    });

    const cancel = () => {
        setPreviewAudio(false);
        stopRecording();
    }

    const finish = () => {
        setPreviewAudio(true);
        stopRecording();
    }

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const list: File[] = Array.from(e.target.files ? e.target.files : [])
        var filesAdd: TypeImage[] = [];

        list && list.forEach((file: File) => {
            const limitation = isFile(file.type) ? packageInfo.fileSend : packageInfo.mediaSend;
            if (file.size < (limitation * 1000000)) {
                const url = URL.createObjectURL(file);
                const type = getTypeMessage(file);
                filesAdd.push({ defaultUrl: url, newUrl: url, file: file, type: type });
            } else {
                ToastModel(addToast, "Arquivo grande!", "O arquivo excedeu o tamanho permitido para seu plano", 'error');
            }
        });

        setFiles([...filesAdd]);
        (list.length > 0) && modalPreview.current.open()
    }

    const Audio = () => {
        switch (status) {
            case 'recording':
                return (
                    <div className="record">
                        <Btn onClick={() => cancel()}>
                            <IoIosClose className="close" />
                        </Btn>
                        <div id="circle-icon">
                            <AiFillAudio id="icon" /> Gravando
                        </div>
                        <Btn onClick={() => finish()}>
                            <HiCheck className="finish" />
                        </Btn>
                    </div>
                );
            case 'stopped':
                return (
                    <Btn onClick={startRecording}>
                        <AiOutlineAudio size={17} />
                    </Btn>
                );
            default:
                return (
                    <Btn onClick={startRecording} className="option">
                        <AiOutlineAudio size={17} />
                    </Btn>
                );
        }
    };

    const sendMessage = (e: any) => {
        if (e.key === "Enter" && e.ctrlKey) {
            const input = inputRef.current
            if (input) {
                input.value = input.value + "\r\n";
                input.scrollTop = input.scrollHeight
            }
        } else {
            if (e.key === "Enter" || e.button == 0) {
                e.preventDefault();
                if (isFilled(text) || mediaBlobUrl) {
                    messagePersonal();
                }
            }
        }
    }

    const messagePersonal = async () => {
        const msg_id_root = answer && !(answer.type == TypePersonal.GROUP) ? answer.msg_id : null
        const msgGroup_id_root = answer && answer.type == TypePersonal.GROUP ? answer.msg_id : null
        let result: any = {
            description: text,
            msg_id_root, msgGroup_id_root
        }
        dispatch({ type: SET_ANSWER, payload: null })
        if (previewAudio && mediaBlobUrl) {
            const audioBlob = await fetch(mediaBlobUrl).then(r => r.blob());
            const audiofile = new File([audioBlob], `audio.mp3`, { type: audioBlob.type })
            result = {
                ...result,
                fileURL: mediaBlobUrl,
                fileType: TypeMessage.VOICE,
                file: audiofile,
                blobUrl: mediaBlobUrl
            }
            setPreviewAudio(false)
        }

        setMessage(result)
        setText("")
    }

    const Blocked = () => {
        const { addToast } = useToasts();
        const userIds = [userIdContact(abareId, personal) || personal.id]

        const unlock = () => {
            deleteUserBlock(abareId, userIds).then((res) => {
                if (res.status == 200) {
                    updateClient({ ...personal, iBlocked: false })
                    socket.emit('changeBlocked', { userIdContact: userIdContact(abareId, personal), photoUrl, Personal_id: personal.Personal_id, blockedMe: false })
                } else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
            })
        }
        return (
            <ContainerBlock onClick={() => unlock()}>
                DESBLOQUEAR
            </ContainerBlock>
        )
    }

    const verifyForGroup = () => {
        switch (personal.type) {
            case GROUP:
                const { groupType, admin } = personal;
                //caso seja um canal(groupType) somente o admin poderá mandar msg 
                return groupType ? admin : true;
            default:
                return true;
        }
    }

    function takePicture() {
        if (screen.width <= 550) {
            fileInput.current?.click()
        } else {
            modalRecordVideo.current.open()
        }
    }


    function descriptionAnswer() {
        if (answer) {
            if (answer.description && answer.description.length > 0) {
                return <>{answer.description}</>
            } else if (answer.fileType) {
                if (answer.fileType == TypeMessage.IMAGE) {
                    return <><ItemFile icons type={answer.fileType} size={15} /> Foto</>
                } else if (answer.fileType == TypeMessage.VIDEO) {
                    return <><ItemFile icons type={answer.fileType} size={15} /> Video</>
                } else if (answer.fileName) {
                    return <><ItemFile icons type={answer.fileType} size={15} /> {answer.fileName.split('-')[1]}</>
                }
            }
        }
    }

    function answerImage() {
        if (answer.fileType) {
            return answer.fileType == TypeMessage.IMAGE || answer.fileType == TypeMessage.VIDEO
        }
        return false
    }

    function getNameDisplay(answer: TMessage) {
        if (abareId === answer.sendBy) {
            return "Você"
        }
        if (answer.type == TypePersonal.GROUP) {
            return answer.nameUser
        }
        return personal.name ? personal.name : `@${personal.nickname}`
    }

    return (
        verifyForGroup() ?
            (!personal.iBlocked ?
                !previewAudio ? selectMessages ? <SelectMessages setSelectMessages={setSelectMessages} test={test} setTest={setTest} />
                    : <Flex>
                        {answer && <ContainerAnswer image={answerImage()}>
                            {
                                answerImage() && <div className="image">
                                    <img src={answer.fileURL!!} />
                                </div>
                            }
                            <div className="title">
                                <p>{getNameDisplay(answer)}</p>
                                <AiOutlineClose onClick={() => dispatch({ type: SET_ANSWER, payload: null })} id="icon" />
                            </div>
                            <p className="message">
                                {descriptionAnswer()}
                            </p>
                        </ContainerAnswer>}
                        <Container text={text}>
                            <Emoji text={text} setText={setText}>
                                <GrEmoji className="option" />
                            </Emoji>
                            <input ref={imageInput} style={{ display: "none" }}
                                onChange={(e) => handleFileSelected(e)} type="file" multiple accept="image/*" />
                            <input ref={videoInput} style={{ display: "none" }}
                                onChange={(e) => handleFileSelected(e)} type="file" multiple accept="video/*" />
                            <input ref={audioInput} style={{ display: "none" }}
                                onChange={(e) => handleFileSelected(e)} type="file" multiple accept="audio/*" />
                            <input ref={fileInput} style={{ display: "none" }}
                                onChange={(e) => handleFileSelected(e)} type="file" multiple accept="aplication/*" />
                            <Dropdown defaultClass={"top-right"}>
                                <AiOutlinePaperClip className="option" />
                                <div>
                                    <li onClick={() => imageInput.current?.click()}> <BsImages className="icon" /> Imagem</li>
                                    <li onClick={() => videoInput.current?.click()}> <AiOutlineVideoCamera className="icon" /> Vídeo</li>
                                    <li onClick={() => audioInput.current?.click()}> <MdAudiotrack className="icon" /> Áudio</li>
                                    <li onClick={() => fileInput.current?.click()}> <AiOutlineFileText className="icon" /> Documento</li>
                                </div>
                            </Dropdown>
                            <Input>
                                <textarea ref={inputRef} rows={2} id="message-input" placeholder="Mensagem..."
                                    value={text}
                                    onKeyPress={e => sendMessage(e)}
                                    onChange={event => setText(event.target.value)}
                                />
                                {text &&
                                    <button id="send" type="submit"
                                        onClick={e => sendMessage(e)}>
                                        <AiOutlineSend size={21} />
                                    </button>
                                }
                            </Input>
                            <Btn className="hidden" onClick={() => takePicture()}>
                                <AiOutlineCamera className="option" />
                            </Btn>
                            <Btn onClick={() => setPreviewAudio(true)} className="option hidden">
                                <AiOutlineAudio size={28} />
                            </Btn>
                            <Modal none ref={modalRecordVideo}>
                                <MainPicture test={true} modal={modalRecordVideo} />
                            </Modal >
                            <Modal none ref={modalPreview}>
                                {files.length &&
                                    <MainPreview personal={personal} modal={modalPreview} files={files} setFiles={setFiles} />
                                }
                            </Modal>
                        </Container >
                    </Flex>

                    :
                    <AudioRecorderFunc setRecorder={setPreviewAudio} />
                :
                <Blocked />)
            :
            null
    );
}
const mapStateToProps = (state: TypeConstRedux) => {
    const abare = state.abare.abare;
    return {
        personal: state.chat.personal,
        socket: state.socket,
        abareId: abare.id,
        photoUrl: abare.photoUrl,
        packageInfo: abare.packageInfo
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    updateClient,
    setMessage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);