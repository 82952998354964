import { connect } from 'react-redux';
import { Accordion, Load } from '../../../../components';
import { TypeConstRedux } from '../../../../types/redux';
import Add from './Add';
import ItemFolder from './ItemFolder';

const Folder = (props) => {
    const { newFolder, folders, setNewFolder } = props;
    return (
        folders?.length ?
            <div>
                {/* <Container/> */}
                {folders.map((folder, i) => (
                    <Accordion last={i === (folders.length-1)} key={i} folder={folder}>
                        <ItemFolder personals={folder.itens} />
                    </Accordion>
                ))}
                <Add newFolder={newFolder} setFocus={setNewFolder} />
            </div>
            :
            <Load message="Carregando..." width="24px" />
    );
}
const mapStateToProps = (state: TypeConstRedux) => ({
    folders: state.folder.list,
})

export default connect(mapStateToProps)(Folder);