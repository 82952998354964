import {
    Avatar, Waves,
} from './style';
import {
    FiUser,
} from '../../assets/icons';

const Calling = ({waves}) => {

    return (
      <Waves>
        <Avatar>
          <FiUser size={80} color="#FFF" />
        </Avatar>
        {
          waves && (
            <>
              <div className="circle delay1" />
              <div className="circle delay2" />
              <div className="circle delay3" />
              <div className="circle delay4" />
            </>
          )
        }
      </Waves>
    );
  };
  
export default Calling;