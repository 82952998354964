import { AiFillContacts, AiFillSchedule } from "react-icons/ai";
import { GiPartyHat } from "react-icons/gi";

import { FaLayerGroup } from "react-icons/fa";
import { MdOutlineGroup, MdOutlineGroups } from "react-icons/md";
import { BiCheckDouble } from "../../../../assets/icons";
import { ReportEnum } from "../../../../types/project";
import {
  Container,
  Content,
  Header,
  MiddleContainer,
  SubTitle,
  Title,
} from "./style";
import IconArrowDown from "../../../../assets/icons/IconArrowDown";
import { useContext, useState } from "react";
import TemplateBlock from "../OptionReport/Blocks/TemplateBlock";
import { ReportContext } from "../../../../contexts/ReportContext";

interface listReportProps {
  hidden?: boolean;
}

const options = [
  {
    title: "Contatos",
    content: [
      { name: "Geral", Icon: AiFillContacts, type: ReportEnum.CONTACTS_ALL },
      {
        name: "Aniversariantes",
        Icon: GiPartyHat,
        type: ReportEnum.CONTACTS_BIRTHDAYS,
      },
    ],
  },
  {
    title: "Grupos",
    content: [
      {
        name: "Individual",
        Icon: MdOutlineGroup,
        type: ReportEnum.GROUP_MEMBERS,
      },
      { name: "Pasta", Icon: MdOutlineGroups, type: ReportEnum.GROUP_ALL },
    ],
  },
  {
    title: "Eventos",
    content: [
      {
        name: "Particular",
        Icon: AiFillSchedule,
        type: ReportEnum.EVENT_PARTICULAR,
      },
      { name: "Grupo", Icon: FaLayerGroup, type: ReportEnum.EVENT_GROUP },
      {
        name: "Confirmações",
        Icon: BiCheckDouble,
        type: ReportEnum.EVENT_CONFIRMATIONS,
      },
    ],
  },
];

const ListReports = ({ hidden = false }: listReportProps) => {
  const { optionReportSelected, handleOptionReportSelected } =
    useContext(ReportContext);
  const [isVisibleParticular, setIsVisibleParticular] = useState(true);
  const [isVisibleShare, setIsVisibleShare] = useState(false);

  function handleVisibleParticular() {
    setIsVisibleParticular((prevState) => !prevState);
  }
  function handleVisibleShare() {
    setIsVisibleShare((prevState) => !prevState);
  }
  return (
    <MiddleContainer hidden={hidden}>
      <Header>
        <div className="top">
          <h3 className="title">Relatórios</h3>
        </div>
      </Header>
      <div className="container-list">
        {options.map(({ title, content }, index) => (
          <Container key={index}>
            <Title>{title}</Title>
            {content.map(({ Icon, name, type }) => (
              <Content
                className={optionReportSelected === type ? "active" : ""}
                key={type}
                onClick={() => {
                  handleOptionReportSelected(type);
                }}
              >
                <Icon className="icon" />
                <p>{name}</p>
              </Content>
            ))}
          </Container>
        ))}
        <Container>
          <Title>Templates</Title>
          <SubTitle onClick={handleVisibleParticular}>
            Particulares
            <IconArrowDown
              className={`icon-arrow-root ${
                !isVisibleParticular ? "up-root" : "down-root"
              }`}
            />
          </SubTitle>
          <div
            className={`resize ${
              isVisibleParticular ? "visible" : "invisible"
            }`}
          >
            <TemplateBlock />
          </div>

          <SubTitle onClick={handleVisibleShare}>
            Compartilhados comigo
            <IconArrowDown
              className={`icon-arrow-root ${
                !isVisibleShare ? "up-root" : "down-root"
              }`}
            />
          </SubTitle>
          {/* {content.map(({ Icon, name, type }, index) => (
                            <Content className={report === type ? 'active' : ''} key={index} onClick={() => { setReport(type) }}>
                                <Icon className="icon" />
                                <p>{name}</p>
                            </Content>
                        ))} */}
        </Container>
      </div>
    </MiddleContainer>
  );
};

export default ListReports;
