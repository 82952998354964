import styled from "styled-components";

export const Container = styled.header`
  height: 5rem;
  min-height: 64px;
  max-height: 125px;
  width: 100%;
  max-width: 100vw;
  background-color: ${(props) => props.theme.begin.background.primary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 4px 4px 8px 0px rgba(60, 60, 60, 0.05);
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  a {
    text-decoration: none;
  }

  .div-header {
    height: 100%;
    width: 100%;
    max-width: 100vw;
    flex-wrap: wrap;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .itens {
    display: flex;
    flex-wrap: nowrap;
  }

  .text-itens-header {
    font-family: "monteserrat" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: ${(props) => props.theme.fonts.text_base} !important;
    line-height: 29px !important;
    color: ${(props) => props.theme.begin.color.font.primary} !important;
  }
  /* 
  .text-itens-header:hover {
    color: rgba(0, 0, 0, 0.7) !important;
  } */

  .button-entrar {
    border: solid;
    border-radius: 45px;
    padding: 4px 12px;
    width: fit-content;
    min-width: 150px;
    border: 2px solid ${(props) => props.theme.begin.color.button.primary};
    border-color: ${(props) => props.theme.begin.color.button.primary};
    color: ${(props) => props.theme.begin.color.button.text_primary};

    font-family: "sansation";
    font-size: ${(props) => props.theme.fonts.text_base};
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  }

  /* .button-entrar:hover {
    background-color: rgb(250, 250, 250);
  } */

  .button-cadastrar {
    border: solid;
    border-radius: 45px;
    padding: 4px 12px;
    width: fit-content;
    min-width: 150px;
    border-color: ${(props) =>
      props.theme.begin.color.button.secondary}!important;
    color: ${(props) =>
      props.theme.begin.color.button.text_secondary} !important;
    background-color: ${(props) =>
      props.theme.begin.color.button.secondary}!important;

    font-family: "sansation";
    font-size: ${(props) => props.theme.fonts.text_base};
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
  }

  /* .button-cadastrar:hover {
    background-color: #8d0851;
    border-color: #8d0851;
  } */

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1346px) {
    #MapaHeader {
      display: none;
    }
  }

  @media (max-width: 1252px) {
    #AgendaHeader {
      display: none;
    }
  }

  @media (max-width: 1138px) {
    #CanalHeader {
      display: none;
    }
  }

  @media (max-width: 1040px) {
    #ChatHeader {
      display: none;
    }
  }

  @media (max-width: 950px) {
    #SegurançaHeader {
      display: none;
    }

    #DashboardHeader {
      display: none;
    }
  }

  @media (max-width: 666px) {
    #link_cadastrar_header {
      display: none;
    }

    .padding_menu {
      padding-left: 40px;
    }
    #link_login_header {
      display: none;
    }

  }
`;

export const ButtonMode = styled.button`
  border: solid;
  height: 100%;
  border-radius: 45px;
  margin-left: 16px;
  padding: 4px 12px;
  border: 2px solid ${(props) => props.theme.begin.color.button.primary};
  border-color: ${(props) => props.theme.begin.color.button.primary};
  color: ${(props) => props.theme.begin.color.button.text_primary};

  font-family: "sansation";
  font-size: ${(props) => props.theme.fonts.text_base};
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;

  .padding {
    padding-top: 6px;
  }
`;
