import styled from "styled-components";

export const Card = styled.div `
  max-width: 475px;
  width: 95%;
  height: 100%;
  padding-inline: 1rem;
  border-radius: 10px; 
  background-color: ${(props) =>
      props.theme.backgrounds.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-inline: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 0.8rem;

  #title {
    color: ${(props) =>
      props.theme.begin.color.button.secondary};
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
    color: ${(props) => props.theme.texts.darkGrey};
    font-weight: normal;
  }

  #link {
    color: ${(props) => props.theme.blue.darkI};
    text-decoration: none;
    cursor: pointer;
    font-size: 0.9rem;
    transition: 0.1s ease-in-out;
    &:hover {
      color: ${(props) => props.theme.blue.darker};
    }
  }

  #alternative {
    margin-top: 2rem;
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 0.9rem;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  .code {
    color: ${(props) => props.theme.default.darkerI};
    h5 {
      margin: 0;
      margin-top: 1rem;
      font-weight: normal;
    }
  }

  .disabledfalse {
    opacity: 60%;
    filter: grayscale(30%);
  }
`;

export const Button = styled.button `
  padding: 0.7rem;
  padding-inline: 2.5rem;
  font-size: 0.9rem;
  cursor: pointer;
  color: white;
  border-radius: 30px;
  background: linear-gradient(267.35deg, #920b42 0.4%, #6f0c5f 100%);
  margin-top: 1rem;
`;

export const Header = styled.div `
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 20px;
  color: white;
  background: linear-gradient(266.16deg, #70012d 0%, #6f0952 96.01%);
  height: 60px;

  #logo{
    height: 20px;
    width: auto;
  }
`;

export const Container = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-content: center;
  justify-items: center;
  justify-content: space-around;
  place-items: center;
  overflow: hidden;
  min-height: 100%;

  .fig {
    max-width: 600px;
  }

  @media only screen and (max-width: 1280px) {
    grid-template-rows: 1fr;
    padding: 0;
    ${Card} {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      border-radius: 0;
      max-width: none;

      h5 {
        margin-bottom: 4vh;
      }
      ${Button} {
        margin-top: 3vh;
        margin-bottom: 3vh;
      }
      #alternative {
        margin-top: 13vh;
      }
    }

    .fig {
      max-width: 280px;
      width: 80%;
    }
  }

  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    .fig {
      display: none;
    }
    ${Header} {
      display: flex;
    }
  }
`;

export const Authentication = styled.div `
  padding: 0.7rem;
  padding: 1.5rem;    
  padding-bottom: 2rem;

  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.begin.color.button.secondary};
    margin: 0;
  }

  legend {
    color: ${(props) => props.theme.default.darker};
    margin: 0;
    font-size: 14px;
    margin-bottom: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: ${(props) => props.theme.default.darkerI};
  }
`;

export const ButtonAuth = styled.button `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  padding-inline: 1rem;
  border-radius: 10px;
  background-color: white;
  min-width: 100%;
  border: solid 0.1px rgba(0, 0, 0, 0.2);
  font-size: 15px;
  margin-top: 0.5rem;

  #icon {
    font-size: 16px;
    color: green;
  }

  #icon-email {
    font-size: 17px;
    color: ${(props) => props.theme.purple.dark};
  }
  #facebook {
    font-size: 16px;
    color: #1278f3;
  }
  #text {
    text-align: center;
    width: 90%;
  }

  &:hover {
    background-color: ${(props) => props.theme.default.ligtherI};
  }
`;