import { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { useToasts } from "react-toast-notifications";
import { Socket } from "socket.io-client";
import { Load, NotFound, Search } from "../../../../../../../components";
import { ButtonArrow } from "../../../../../../../components/Button/style";
import { Checked } from "../../../../../../../components/Checked";
import { deleteRemoveParticipants } from "../../../../../../../services/requests/user-group";
import { TProfileGroup, TUser } from "../../../../../../../types/project";
import { Container, Header, Menu } from '../../../Role/Promote/style';

type TProps = {
    abare: TUser,
    groupInfo: TProfileGroup,
    updateGroupForAllUsers: any,
    socket: Socket;
    modal: any;
}

const ParticipantsRemove = (props: TProps) => {
    const { abare, groupInfo, updateGroupForAllUsers, socket, modal } = props;
    const [hidden, setHidden] = useState(false);
    const participants = structuredClone(groupInfo.participants);
    const [users, setUsers] = useState<TUser[]>(participants.filter(user => user.id != abare.id));
    const { addToast } = useToasts();

    const removeParticipants = () => {
        if (hidden) {
            var itensRemove: Array<any> = []
            users.map((user) => {
                user.checked && itensRemove.push(user.id)
            })

            deleteRemoveParticipants(groupInfo.id, itensRemove).then((res) => {
                if (res.status == 200) {
                    const message = res.data.message.pt
                    addToast({
                        title: message
                    },
                        { appearance: 'success' });
                    removeUsers(itensRemove)
                } else if (res.status == 409) {
                    addToast({
                        title: 'Você não tem permissão para remover o criador do grupo'
                    },
                        { appearance: 'error' });
                }
            })
        }
    }

    const removeUsers = (itens) => {
        groupInfo.roles.map((role) => {
            role.users = role.users.filter(x => !itens.some(id => id === x.id))
        });
        socket.emit('removeGroup', { ids: itens, groupInfo });
        groupInfo.participants = groupInfo.participants.filter(x => !itens.some(id => id === x.id))
        updateGroupForAllUsers(groupInfo)
        modal.current.close()
    }
    return (
        <Container three={true}>
            <Header>
                <div>
                    <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                    <h5>Participantes - Remover</h5>
                </div> 
            </Header> 
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    { users && setUsers(result) }
                }} />
                  <ButtonArrow onClick={() => removeParticipants()}>
                    <AiFillCheckCircle id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>
            </Menu>
            <div className="scroll">
                {users ?
                    users.length ?
                        <Checked users={users} setUsers={setUsers} setHidden={setHidden} />
                        :
                        <NotFound text="Não há membros para remover" />
                    :
                    <div className="load">
                        <Load width="20px" />
                    </div>
                }
            </div>
        </Container>
    );
}

export default ParticipantsRemove;