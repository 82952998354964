import styled from "styled-components";

interface StyledProps {
  open?: boolean;
}

export const StyledMenu = styled.nav<StyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  height: auto;
  
  text-align: left;
  padding: 0px 1rem;
  position: fixed;
  z-index: 3;
  background: ${(props) => props.theme.begin.background.secondary};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  transition: transform 0.3s ease-in-out;

  .buttons {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
 
  .tema {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${(props) => (props.theme.title === "dark" ? "white" : "#000")};
    p {
      font-size: 1rem;
    }

    .title {
      font-family: "inter-semi-bold", sans-serif;
      color: ${(props) => props.theme.texts.purpleDarkTitle};
      font-size: 1.3rem;
    }
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5rem;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
    }

    .button-entrar {
      border: solid;
      border-radius: 5px;
      width: 100%;
      min-width: 90px;
      border: 2px solid ${(props) => props.theme.begin.color.button.primary};
      border-color: ${(props) => props.theme.begin.color.button.primary};
      color: ${(props) => props.theme.begin.color.button.text_primary};

      font-family: "sansation";
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }

    /* .button-entrar:hover {
    background-color: rgb(250, 250, 250);
  } */

    .button-cadastrar {
      border: solid;
      border-radius: 5px;
      width: 100%;
      min-width: 90px;
      border-color: ${(props) =>
        props.theme.begin.color.button.secondary}!important;
      color: ${(props) =>
        props.theme.begin.color.button.text_secondary} !important;
      background-color: ${(props) =>
        props.theme.begin.color.button.secondary}!important;

      font-family: "sansation";
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;


export const Portal = styled.div<StyledProps>`
  display: ${({ open }) => (open ? "flex" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
`;
