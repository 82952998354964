import React from "react";
import { IoIosClose, FiAlertTriangle } from "../../../../../assets/icons";
import { connect } from "react-redux";
import { Container, Header, Body, Footer } from "./style";
import { deleteCluster } from "../../../../../services/requests/cluster";
import { setListFolder } from "../../../../../store/actions/folderAction";
import { useToasts } from "react-toast-notifications";



const Delete = (props) => {
    const { folder, folders, toListFolder, modalDelete } = props;
    const { addToast } = useToasts();
    

    const deleteFolder = () => {
        deleteCluster(folder.id).then((res) => {
            if (res.status == 200) {
                toListFolder(folders.filter(item => item.id !== folder.id))
                addToast({ title: "A pasta '" + folder.name + "' foi excluída", description: "Sua pasta foi apagada com sucesso!" },
                    { appearance: 'success' });
            }
        })
    }

    return (
        <Container>
            <Header>
                <h4 id="title">Excluir a pasta {folder.name}?</h4>
                <IoIosClose id="close" onClick={() => modalDelete.current.close()}/>
            </Header>
            <Body>
                <FiAlertTriangle id="alert" />
                <p>Você está prestes a excluir permanentemente esta pasta. </p>
                <p>Se você não tem certeza de que deseja fazer isso feche esta janela. </p>
            </Body>
            <Footer>
                <button onClick={() => deleteFolder()}>
                    Excluir
                </button>
            </Footer>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
        folders: state.folder.list
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toListFolder(list) {
            dispatch(setListFolder(list));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Delete);