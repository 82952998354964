import { Dispatch, SetStateAction } from 'react';
import { DefaultTheme } from "styled-components";
import { dark } from '../styles/themes/dark';
import { light } from '../styles/themes/light';


type Response<DefaultTheme> = [
  DefaultTheme,
  Dispatch<SetStateAction<DefaultTheme>>,
];

function usePersistedState(): DefaultTheme {  
    const storageValue = localStorage.getItem("theme");

    if (storageValue == "dark") {
      return dark;
    } else {
      return light;
    }

}

export default usePersistedState;