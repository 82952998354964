import { useQuery } from "react-query";
import { useToasts } from "react-toast-notifications";
import SkeletonCardTemplate from "../../../../../components/Skeleton/SkeletonCardTemplate";
import { ReportTemplateService } from "../../../../../services/requests/report-template";
import { Queries } from "../../../../../types/queries";
import { ToastModel } from "../../../../../util/error";
import CardTemplate from "../../components/CardTemplate";
import { ContainerElements, Element } from "../style";

interface TemplateBlockProps {}

const LoadingSkeleton = () => (
  <>
    <SkeletonCardTemplate />
    <SkeletonCardTemplate />
    <SkeletonCardTemplate />
    <SkeletonCardTemplate />
  </>
);
const TemplateBlock = ({}: TemplateBlockProps) => {
  const { addToast } = useToasts();
  const { data, isLoading, isSuccess, isError } = useQuery(
    Queries.REPORT_TEMPLATES,
    ReportTemplateService.get,
    {
      retry: false,
      refetchInterval: false,
      onError: (error) => {
        ToastModel(addToast, "Error", "Error ao buscar templates", "error");
      },
    }
  );

  return (
    <Element>
      {isLoading ? (
        <LoadingSkeleton />
      ) : isError ? (
        <p>Error ao buscar templates</p>
      ) : isSuccess && data.length < 1 ? (
        <p>Nenhum template salvo</p>
      ) : (
        <>
          {" "}
          {data?.map((template) => (
            <CardTemplate template={template} key={template.fileName} />
          ))}
        </>
      )}
    </Element>
  );
};

export default TemplateBlock;
