import { api } from '../../../services/api';
import { getToken } from '../../../services/auth';
import { IPlanInfo } from './plans.interfaces';



interface ICheckout {
  priceId: string;
}

interface ICheckoutPackage {
  id: number;
}

export async function index(): Promise<IPlanInfo[]> {
  const response = await api.get('/signature/');
  if (response.status !== 200) {
    throw new Error('Error on get plans');
  }
  return response.data;
}

export async function checkout(data: ICheckoutPackage): Promise<void> {
  const response = await api.post(`/signature/checkout/${data.id}`, null, { headers: { Authorization: "Bearer " + getToken() } });
  window.open(response.data.redirect, '_self');
}
