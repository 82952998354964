import { Dispatch, MouseEvent, SetStateAction, memo, useEffect, useRef, useState } from "react";
import ListOptions from "../../ListOptions";

const modalRoot: any = document.getElementById("modal-calendar");
export type ModalRef = {
    open: () => void;
    close: () => void;
    isOpen: boolean;
};

export type ModalProps = {
    // outros props do componente
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>
};
const ModalCalendar = (({ open, setOpen }: ModalProps) => {
    const help = useRef<HTMLDivElement>(null);
    const [styleTeste, setStyle] = useState({
        top: 0,
        height: 0,
        left: 0,
        width: 0
    })
    const setCoords = () => {

        const pai = help.current?.parentElement;
        if (pai) {
            setStyle({
                top: pai.offsetTop,
                height: pai.offsetHeight,
                left: pai.offsetLeft,
                width: open ? pai.offsetWidth : 0,
            })
        }

    }
    useEffect(() => {
        window.addEventListener("resize", setCoords);
        return () => {
            window.removeEventListener("resize", setCoords);
        }
    }, []);

    useEffect(() => {
        setCoords();
    }, [open]);

    const handleVisibility = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setOpen(false);
    }
    return <div id="modal-menu-events" onClick={handleVisibility}
        style={{
            top: styleTeste.top,
            height: styleTeste.height,
            left: styleTeste.left,
            width: styleTeste.width,
            position: "absolute",
            background: "#eee6",
            zIndex: 90,
            transition: "width 0.25s ease-out",
            overflow: "hidden",
            borderRadius: "14px",
        }} ref={help} className="teste">
        <ListOptions setOpen={setOpen} modal={true} overflow={false} hidden={false} />
    </div>
});

export default memo(ModalCalendar);