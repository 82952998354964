import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 56px min-content min-content;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid ${(props) => props.theme.bordes.lightGreyI};
  height: 100%;

  .pd-b-0 {
    padding-bottom: 0px;
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1rem 1rem;
  
  h3 {
    flex: 1;
    margin: 0px;
    font-size: ${(props) => props.theme.fonts.text_lg};
    color: ${(props) => props.theme.texts.purpleDark};
  }

  #check{
    color: ${(props) => props.theme.texts.purpleDark};
    cursor: pointer;
  }

  #icon-back {
    margin-right: 8px;
    cursor: pointer;
    color: ${(props) => props.theme.texts.purpleDark};
  }
`;

interface StyledProps {
  data?: any;
}

export const Row = styled.div<StyledProps>`
  padding: 1rem 1rem;

  #label-role {
    display: inline-block;
    margin-bottom: 6px;
    font-size: 1rem;
  }
`;

export const Edit = styled.div`
  background-color: ${(props) => props.theme.backgrounds.white};
  margin-bottom: 10px;

  #hr {
    margin: 0px;
    padding: 0px;
    border-top: 1px solid ${(props) => props.theme.texts.lightGreyI};
  }

  label {
    font-size: 14px;
    color: ${(props) => props.theme.texts.darkGreyI};
  }
`;

export const Participants = styled.div`
  display: grid;
  grid-template-columns: 50px auto 20px;
  grid-template-rows: 50px;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${(props) => props.theme.bordes.lightGreyI};

  .remove{
    cursor: pointer;
    color: #C2000C;
    font-size: 19px;
  }

  .add {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.backgrounds.purpleDark};
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;

    #icon {
      color: ${(props) => props.theme.texts.white};
      font-size: 20px;
    }
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .name {
    color: ${(props) => props.theme.texts.darkerGreyII};
    font-size: 15px;
  }

  .add-name {
    font-family: "inter-medium", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 1rem;
    cursor: pointer;
  }

  &:hover {
    background-color: ${(props) => props.theme.hover.lightGrey};
  }
`;
