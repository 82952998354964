import Card from "../Card";
import { ShimmerCard, ShimmerLine } from "../Shimmers";

const SkeletonCardTemplate = () => {
    return <Card>
        <ShimmerCard width="100%" height="220px" marginBottom="8px" />
        <ShimmerLine width="30%" margin="0px 0px 8px 0px" />
        <ShimmerLine width="100%" margin="0px 0px 4px 0px" />
        <ShimmerLine width="100%" margin="0px 0px 4px 0px" />
        <ShimmerLine width="30%" margin="0px" />

    </Card>
}

export default SkeletonCardTemplate;