import { useEffect, useRef, useState } from "react";
import { FiFilter } from "react-icons/fi";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { BsImages, FaUsers, FiCrop, FiTrash, IoIosArrowBack, IoIosArrowForward, MdModeEdit } from '../../../../../assets/icons';
import { CropImage, Input, InputSelect, Load, NotFound, Search } from "../../../../../components";
import { Button, ButtonArrow } from "../../../../../components/Button/style";
import { Checked } from "../../../../../components/Checked";
import { getContactsWithAccount } from "../../../../../services/requests/contact";
import { postGroup } from "../../../../../services/requests/group";
import { getAllUsers } from "../../../../../services/requests/user-group";
import { insertItemInGeneral } from "../../../../../store/actions/folderAction";
import { TypeImage } from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { ToastModel } from "../../../../../util/error";
import { isEqualsArray } from "../../../../../util/verification";
import { BtnFile, Container, Default, FormGroup, Header, HeaderBack, Main, Menu, OutlineButton, Scroll } from './style';

const GroupData = (props) => {
    const { contacts, setMenu, image, setImage, abare, modalAdd, text, setText, insertItemInGeneral, socket, groupType } = props;
    const [load, setLoad] = useState(false);
    const { addToast } = useToasts();
    const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || '';
    const fileInput = useRef<HTMLInputElement>(null);

    const nameTypeGroup = groupType ? 'canal' : 'grupo';
    const nameTypeGroupUpper = nameTypeGroup.charAt(0).toUpperCase() + nameTypeGroup.slice(1);


    const updateFolders = (group) => {
        const datas = {
            title: nameTypeGroupUpper + " criado successo!",
            description: "O " + nameTypeGroup + " '" + group.name + "' foi criado na pasta Geral.",
        }
        addToast(datas,
            { appearance: 'success' });

        setLoad(false)
        insertItemInGeneral(group)

        modalAdd.current.close()
    };

    const createGroup = e => {
        e.preventDefault()

        const name = e.target.elements.name.value
        const dataForm = new FormData();
        var userIds: Array<string> = [];

        contacts.map((key) => {
            key.checked && userIds.push(key.id)
        })

        image?.file && dataForm.append('file', image?.file);

        dataForm.append('name', name);
        dataForm.append('userIds', JSON.stringify(userIds));
        dataForm.append('groupType', groupType);

        if (!(name.trim() == '')) {
            setLoad(true)
            postGroup(dataForm).then((res) => {
                if (res.status == 200) {
                    const group = res.data.result
                    updateFolders(group)
                    socket.emit('addGroup', { ids: userIds, groupInfo: { ...group, admin: false } });
                } else if(res.status == 403) {
                    ToastModel(addToast, "Limite excedido!", `Seu limite para criar um ${nameTypeGroup} é ${res.data.limitUsers} pessoas. `, "error");
                }
                setLoad(false)
            })
        } else {
            addToast({
                title: `O nome do ${nameTypeGroup} está vazio!`,
                description: `Digite o nome do seu ${nameTypeGroup}  para prosseguir.`,

            },
                { appearance: 'warning' });

        }
    };

    const handleImage = e => {
        const file = e.target.files[0];

        if (file) {
            if (file.size <= MAX_FILE_SIZE) {
                const url = URL.createObjectURL(file);
                setImage({ mainUrl: url });
                setMenu(4)
            } else {
                addToast({
                    title: 'Imagem muito grande',
                    description: "Tamanho máximo de 6mb"
                },
                    { appearance: 'error' });
            }

        }
    };

    return (
        <Container>
            <HeaderBack>
                <IoIosArrowBack id="back" onClick={() => setMenu(2)} />
                <h5>Novo {nameTypeGroup}</h5>
            </HeaderBack>
            <FormGroup onSubmit={createGroup}>
                <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInput}
                    value={""}
                    onChange={handleImage}
                    accept="image/png, image/gif, image/jpeg"
                />
                <div className="photo-container">
                    {image?.newUrl ?
                        <>
                            <Default className="vertical-gradient">
                                <img id="profile" src={image.newUrl ? image.newUrl : image.defaultUrl} />
                            </Default>
                            <div id="options">
                                <OutlineButton type="button" onClick={() => setMenu(4)}>
                                    <FiCrop className='item' />
                                </OutlineButton>
                                <OutlineButton type="button" onClick={() => setImage({})}>
                                    <FiTrash className='item' />
                                </OutlineButton>
                                <OutlineButton type="button" onClick={() => fileInput.current?.click()}>
                                    <BsImages className='item' />
                                </OutlineButton>
                            </div>
                        </>
                        :
                        <Default className="vertical-gradient">
                            <FaUsers size={40} />
                            <BtnFile type="button" onClick={() => fileInput.current?.click()}><MdModeEdit size={19} /></BtnFile>
                        </Default>
                    }
                </div>
                <Input inText={text} label={"Nome do " + nameTypeGroup} name="name" maxLength={30} type="text" placeholder="Digite o nome aqui..." autoComplete="off" value={text} onChange={(e) => setText(e.target.value)} />
                <div className="center-button">
                    <Button disabled={load} type="submit">
                        {load ? <Load /> : 'Criar ' + nameTypeGroupUpper}
                    </Button>
                </div>
            </FormGroup>
        </Container>
    )
}

const Add = (props) => {
    const { abare, groupType, modalAdd } = props;
    const refCrop = useRef<any>(null);


    const Participants = (props) => {
        const { modalAdd, contacts, setContacts, setMenu, groupFilter, setGroupFilter } = props
        const [allChecked, setAllChecked] = useState(contacts?.every(item => item.checked) || false)
        const [hidden, setHidden] = useState(contacts?.some(x => x.checked))
        const [isLoad, setIsLoad] = useState(false)
        const [listaGrupos, setListaGrupo] = useState<any>(null)
        const [optionsGroup, setOptionsGroup] = useState<any>([{ value: 0, label: 'Contatos' }])
        const nameTypeGroup = groupType ? `Canal` : `Grupo`;

        useEffect(() => {
            if (groupType) {
                setIsLoad(true)

                getAllUsers().then((res) => {
                    if (res.status == 200) {
                        setListaGrupo(res.data)
                        for (const grupo in res.data) {
                            if (grupo != 'contacts') {
                                setOptionsGroup(groups => {
                                    return [...groups, { value: grupo, label: grupo }];
                                });
                            } 

                        }
                    }
                    setGroupFilter([])
                    setIsLoad(false)
                })
            }
        }, [])

        const changeLista = (e) => {
            setGroupFilter([])
            for (const grupo in listaGrupos) {
                e.map((value) => {
                    if (value.label == grupo || (grupo == "contacts" && value.label == "Contatos")) {
                        listaGrupos[grupo].forEach((participante) => {
                            setGroupFilter(participantes => {
                                if (participantes.find(element => (element.id == participante.id)) === undefined) {
                                    return [...participantes, participante]
                                }
                                return [...participantes]
                            })
                            // setListaGrupo(participante)
                        }) 
                    }
                })
            }
        }

        return (
            <Container>
                <Header>
                    <IoIosArrowBack id="back" onClick={() => modalAdd.current.close()} />
                    <h5>Novo {nameTypeGroup}</h5>
                </Header>
                <Menu>
                    <h4>Membros</h4>
                    <Search itens={contacts || []} onSearch={(result) => {
                        !isEqualsArray(contacts || [], result) && setContacts(result)
                    }} />
                    <ButtonArrow onClick={() => hidden ? setMenu(3) : null}>
                        <IoIosArrowForward id={!hidden ? "icon" : "icon-purple"} />
                    </ButtonArrow>
                </Menu>
                {groupType && <div className="filterChannel">
                    <FiFilter className="iconChannel" />
                    <InputSelect onChange={(e) => { changeLista(e) }} isMulti name="filterParticipants" id="filterParticipants" isLoading={isLoad}
                        placeholder="Grupo" style={true} options={optionsGroup} />
                </div>}

                {
                    groupType ?
                        <>
                            {groupFilter ?
                                <Scroll>
                                    {groupFilter.length ?
                                        <Checked contacts={groupFilter} setContacts={setGroupFilter} setAllChecked={setAllChecked} setHidden={setHidden} />
                                        :
                                        <NotFound text={"Nenhum contato selecionado"} description="Selecione um grupo no filtro" />
                                    }
                                </Scroll>
                                :
                                <div className="load">
                                    <Load width="25px" />
                                    <h4>Carregando...</h4>
                                </div>
                            }
                        </> :
                        <>
                            {contacts ?
                                <Scroll>
                                    {contacts.length ?
                                        <Checked contacts={contacts} setContacts={setContacts} setAllChecked={setAllChecked} setHidden={setHidden} />
                                        :
                                        <NotFound text={"Não há contatos"} description="Adicione clicando no perfil de uma conversa" />
                                    }
                                </Scroll>
                                :
                                <div className="load">
                                    <Load width="25px" />
                                    <h4>Carregando...</h4>
                                </div>
                            }
                        </>
                }

            </Container>)
    }

    const Crop = ({ setMenu, image, setImage }) => {

        const cropImage = async () => {
            const data = await refCrop.current.cropImage();
            setImage({ ...image, ...data })
            setMenu(3);
        }

        return (
            <Container>
                <HeaderBack>
                    <IoIosArrowBack id="back" onClick={() => setMenu(3)} />
                    <h5>Voltar</h5>
                </HeaderBack>
                <CropImage ref={refCrop} url={image?.mainUrl} />
                <div className="center">
                    <Button type="button" onClick={() => cropImage()} >Ajustar Imagem</Button>
                </div>
            </Container>
        );
    }

    const HandleMenu = () => {
        const [menu, setMenu] = useState(1);
        const [contacts, setContacts] = useState<any>(null);
        const [image, setImage] = useState<TypeImage>();
        const [text, setText] = useState<string>('');
        const [groupFilter, setGroupFilter] = useState<any>(null);
        useEffect(() => {
            getContactsWithAccount().then((res) => {
                if (res.status == 200) {
                    setContacts(res.data)
                }
            });
        }, [])

        switch (menu) {
            case 3:
                if (groupType) {
                    return <GroupData contacts={groupFilter} setMenu={setMenu} image={image} setImage={setImage} {...props} text={text} setText={setText} />
                }
                return <GroupData contacts={contacts} setMenu={setMenu} image={image} setImage={setImage} {...props} text={text} setText={setText} />
            case 4:
                return <Crop setMenu={setMenu} image={image} setImage={setImage} />
            default:
                return <Participants modalAdd={modalAdd} contacts={contacts} setContacts={setContacts} setMenu={setMenu} groupFilter={groupFilter} setGroupFilter={setGroupFilter} />
        }
    }

    return (
        <Main>
            <HandleMenu />
        </Main>
    );
}

const mapStateToProps = (state: TypeConstRedux) => {
    return {
        abare: state.abare.abare,
        socket: state.socket
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    insertItemInGeneral
}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Add);
