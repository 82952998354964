import styled from "styled-components";

export const Main = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  background: rgba(112, 5, 64, 0.05);
  background-color: ${props => props.theme.backgrounds.white};
  border-radius: 15px;
  margin: 10px;
  margin-bottom: -10px;
  transition: 0.2s;
  cursor: pointer;

  &:hover{
    background: rgba(0,0,0,0.11);
  }

  .post-left {
    display: inline-block;
    vertical-align: top;
    float: left;
    padding-top: 7px;
    padding-left: 7px;
  }

  .post-right {
    padding-left: 10px;
    display: inline-block;
    vertical-align: top;
  }

  .img {
    object-fit: cover;
    width: 100%;
    height: 130px;
    border-radius: 15px;
  }

  .wallpaper-data {
    display: flex;  
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .like-post > span, .visualize-post > span {
    font-size: 11px;
    cursor: pointer;
    color: ${props => props.theme.texts.darkerGreyII};
  }

  .like-post > .active {
    color: ${props => props.theme.texts.purpleDarkTitle};
  }

  .icon {
    font-size: 13px;
    cursor: pointer;
    color: white;
    margin-bottom: -2px;
  }

  .dropdown {
    display: flex;
  }

  .date {
    color: ${props => props.theme.texts.darkerGreyII};
    font-size: 9px;
    margin-right: auto;
    margin-top: 4px;
  }

  .settings-row {
    padding-top: 5px;
  }

  #settings {
    color: #70012C;
    padding-left: 35px;
    padding-top: 5px;
  }

  .title {
    color: ${props => props.theme.texts.darkerGreyII};
    font-size: 14px;
    margin: 5px 0px 0px 0px;
  }

  .desc-div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 70px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .desc {
    color: ${props => props.theme.texts.darkerGreyII};
    margin-top: 5px;
    font-size: 11px;
    line-height: 13px;
    margin-right: 5px;
    text-align: justify;
    box-sizing: border-box;
  }

  .userInfo{
    text-align: end;
    margin-left: auto;
  }

  .userDesc {
    color: ${props => props.theme.texts.darkerGreyII};
    font-family: "inter", sans-serif;
    font-size: 8px;
    text-align: right;
    padding-right: 7px;
    margin-top: 5px;
  }

  .nickname {
    color: ${props => props.theme.texts.darkerGreyII};
    font-family: "inter", sans-serif;
    font-size: 10px;
    font-weight: bold;
    text-align: right;
    padding-right: 7px;
  }

  .perfil-img {
    height: 30px;
    width: 30px;
    margin-right: 5px;
    border-radius: 50%;
  }

  .user-img {
    text-align: end;
    margin-top: auto;
    padding-bottom: 10px;
  }

  .row {
    display: flex;
    justify-items: end;
    margin-top: 8px;
  }
`;