import styled from "styled-components";
import { Conversation } from "../../pages/home/Conversations/Folder/ItemFolder/style";

export const ListConversation = styled(Conversation)`
  padding: 0.5rem;
  padding-inline: 1rem;
  background-color: ${props => props.theme.backgrounds.white};
  
  .relative{
    position: relative;
    padding: 0.5rem;
  }
`;

export const Default = styled.div`
  position: absolute;
  top: 30px;
  right:0px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border:2px solid white; 

  #col-message {
    padding: 1.2rem 0.7rem;
    width: 100%;

    #name {
      margin: 0;
      padding: 0;
      color: ${(props) => props.theme.texts.darkerGreyI};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
    }

    #message {
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      color: ${(props) => props.theme.texts.darkGreyI};

      .fileIcon {
        display: grid;
        grid-template-columns: 20px auto;
        align-items: center;

        .fileName{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;