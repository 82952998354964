import { Fragment, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { MdOutlinePersonOff } from "../../../../../../assets/icons";
import ModalDisabled from "../../../../../../components/Modal/ModalDisabled";
import { logout } from "../../../../../../services/auth";
import { putDisabledAccount } from "../../../../../../services/requests/user";
import { clearReducers } from "../../../../../../store/actions/cleanAction";
import ItemContainer from "../../../Components/ItemContainer";
import { Button, HorizontalRow, PasswordPerfil } from "../../style";

const DisabledOption = (props) => {
    const { clearReducers } = props;
    const modalDisabled = useRef<any>();
    const { push } = useHistory()

    const disabled = () => {
        putDisabledAccount().then((res) => {
            if (res.status == 200) {
                clearReducers()
                logout()
                push("/")
            }
        })
    }

    return (
        <Fragment>
            <ItemContainer title="Acesso">
                <Button onClick={() => modalDisabled.current?.open()}>
                    <HorizontalRow>
                        <MdOutlinePersonOff size={22} />
                        <PasswordPerfil>
                            Desativar conta
                            <div className="line">
                                Desative seu acesso a plataforma
                            </div>
                        </PasswordPerfil>
                    </HorizontalRow>
                </Button>
            </ItemContainer>
            <ModalDisabled modalRef={modalDisabled} title={"Desativa conta"} action={disabled} />
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearReducers
}, dispatch)

export default connect(null, mapDispatchToProps)(DisabledOption);