import { dark } from "../../styles/themes/dark";
import { light } from "../../styles/themes/light";
import { SET_THEME } from "./actionTypes";

export const setTheme = (theme) => {
  return {
    type: SET_THEME,
    payload: theme,
  };
};

export const toChangeTheme = () => {
  return (dispatch, getState) => {
    const { abare } = getState()
    const {title} = abare.theme
    
    if (title === "light") {
      localStorage.setItem("theme", "dark")
      dispatch(setTheme(dark))
    } else {
      localStorage.setItem("theme", "light")
      dispatch(setTheme(light))
    }
  };
};
