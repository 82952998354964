import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { useState } from "react";
import { ContainerInput } from "./style";

import { MdOutlineLocationOn } from "react-icons/md";
import { MainInput } from "../../pages/home/Conversations/Group/Edit/style";

interface InputSearchPlaceProps {
    schedule?: boolean;
    groupType: string;
    address: string;
    setAddress: (any) => void;
    setLocation?: (any) => void;
    label?: boolean;
}
const libraries = ["places"];

const InputSearchPlace = ({ schedule = false, groupType, address, setAddress, setLocation, label }: InputSearchPlaceProps) => {

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyD-QsVLrPfZ6tN0U4KAcNSMgOpbw-2bP-I",
        libraries: ["places"],
        id: 'google-map-script',
    })
    const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
    const onLoad = (ref: google.maps.places.SearchBox) => {
        setSearchBox(ref)
    }

    const onPlacesChanged = () => {
        if (searchBox) {
            const places = searchBox.getPlaces();
            if (places) {
                const place = places[0];
                const location = {
                    lat: place?.geometry?.location?.lat() || 0,
                    lng: place?.geometry?.location?.lng() || 0,
                }
                setAddress && setAddress(place.formatted_address)
                setLocation && setLocation(location)
            }
        }
    }
    // if (!isLoaded) return <MainLoad>
    //     <Load width="30px" />
    //     <h4 id="loading">Carregando...</h4>
    // </MainLoad>

    return <MainInput>
        {label && <label htmlFor="address">Endereço<MdOutlineLocationOn className="red" /></label>}
        <ContainerInput agenda={schedule}>
            {isLoaded ? <StandaloneSearchBox onLoad={onLoad}
                onPlacesChanged={onPlacesChanged}>
                <input
                    className="addressField"
                    placeholder={`Digite o endereço do ${groupType}`}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                />
            </StandaloneSearchBox>
                :
                <input
                    className="addressField"
                    placeholder={`Digite o endereço do ${groupType}`}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                />

            }
        </ContainerInput>
    </MainInput>
}

export default InputSearchPlace;