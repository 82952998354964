import styled from "styled-components";

export const ContainerNoticesEmpty = styled.div`
  grid-column: span 1;
  grid-row: span 2;
  width: 100%;
  height: 100%;
  min-height: 250px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  overflow: hidden;
  background: linear-gradient(
      0deg,
      rgba(68, 68, 68, 0.2) 0%,
      rgba(68, 68, 68, 0.2) 100%
    ),
    #ff762d;
  border-radius: 14px;
  padding: 1.5rem;

  .back-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  .content {
    z-index: 1;
  }
  header {
    color: #fff;
    font-family: "inter-semi-bold", sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    line-height: normal;
  }

  main {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    color: #fff;
    font-family: "inter-medium", sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button {
    z-index: 1;
    text-align: center;
    border-radius: 16px;
    border: 3px solid #da6c32;
    background-color: #da6c32;
    padding: 0.25rem;
    color: #fff;
    font-family: "inter-semi-bold", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    transition: background-color 0.1s ease-out;
    transition: color 0.1s ease-out;
    margin-bottom: 0.5rem;
    :hover {
      color: #da6c32;
      background-color: #fff;
      border: 3px solid #fff;
    }
  }
`;
