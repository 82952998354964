import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid lightgray;
  color: ${(props) => props.theme.default.darkerI};
  padding: 8px;
`;

export const PreviewAudio = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.chat.message.backgroundMyI};
  font-size: 11px;
  height: 46px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  max-width: 268px;

  .rec{
    background-color: ${({theme}) => theme.purple.darker};
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes pulse{
    0%{
      box-shadow: 0px 0px 2px 0px rgba(173,0,0,.3);
    }
    65%{
      box-shadow: 0px 0px 2px 3px rgba(173,0,0,.3);
    }
    90%{
      box-shadow: 0px 0px 5px 5px rgba(173,0,0,0);
    }
  }

  #timer {
    display: flex;
    justify-content: end;
    width: 38px;
    font-size: 0.8rem;
    color: ${({theme}) => theme.texts.darkerGreyI};
  }

  .record-audio{
    flex:1;
    max-width: 180px !important;

    div canvas{
      width: 100% !important;
    }
  }

  #waveform-audio {
    cursor: pointer;
    min-width: 180px;
  }
`;
