import { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { ThemeContext } from "styled-components";
import { BiLockAlt, HiOutlineMail } from '../../../assets/icons';
import { Figlogin, FigloginDark, Logo } from '../../../assets/images';
import { Forgot, InputLogin, InputPasswordLogin, Load, Modal } from "../../../components";
import { login } from '../../../services/auth';
import { getUserByToken, postActiveAccount, postLoginEmail } from "../../../services/requests/user";
import { errorDefault, ToastModel } from "../../../util/error";
import Others from "../Others";
import { Button, Card, Container, Header } from '../style';
import BlockPlataform from "../Block";

const Email = () => {
    const { addToast } = useToasts();
    const { title } = useContext(ThemeContext);

    const history = useHistory();
    const modalForgot = useRef<any>();
    const modalAuth = useRef<any>();
    const [loading, setLoading] = useState(false);
    const url = new URL(window.location.toString());
    const img = (title == "light" ? Figlogin : FigloginDark)
    const modalBlock = useRef<any>()
    const [token, setToken] = useState<string>();
    useEffect(() => {
        const token = url.searchParams.get("token");
        if (token) {
            postActiveAccount(token).then((res) => {
                if (res.status == 200) {
                    addToast({
                        title: 'Email confirmado com sucesso!',
                        description: "Você já pode entrar na plataforma."
                    },
                        { appearance: 'success' });
                }
            });


        }
    }, [])
    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const { email, password } = event.target.elements;
        postLoginEmail(email.value, password.value).then((res) => {
            const status = res.status
            if (status) {
                if (status == 200) {
                    const token = res.data.result.access_token
                    login(token)
                    getUser()
                } else if (status == 412) {
                    //usuário bloqueado
                    ToastModel(addToast, "Você está bloqueado", null, "error")
                    setToken(res.data.token.access_token)
                    modalBlock?.current?.open()
                } else {
                    errorLogin(status)
                }
            } else {
                ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
            }
            setLoading(false);
        });
    }

    const errorLogin = (status) => {
        switch (status) {
            case 404:
                ToastModel(addToast, 'Usuário não cadastrado', "Informe outro endereço ou realize um cadastro", "error")
                break
            case 401:
                ToastModel(addToast, 'Usuário ou senha incorreta', "Verifique seus dados e tente novamente", "error")
                break;
            case 403:
                ToastModel(addToast, 'Email não confirmado', "Verifique sua caixa de email", "error")
                break;
            default:
                errorDefault(status, addToast)
        }
    }

    const getUser = () => {
        getUserByToken().then((res) => {
            if (res.data.name && res.data.nickname) {
                history.push('/home')
            } else {
                history.push('/signin/data')
            }
        });
    }

    return (
        <Container className="vertical-gradient">
            <img className="fig" alt="Figura de acesso" src={img} />
            <Card>
                <Header className="vertical-gradient">
                    <img id="logo" src={Logo} alt="Logo Abaré.Net" />
                </Header>
                <h1 id="title">Conecte-se</h1>
                <form onSubmit={onSubmit}>
                    <InputLogin Icon={HiOutlineMail} name="email" label="E-mail"
                        type="email" required placeholder="Digite seu e-mail aqui" />
                    <InputPasswordLogin login={true} Icon={BiLockAlt} name="password" label="Senha"
                        type="password" required placeholder="Digite sua senha aqui" />
                    <a id="link" onClick={() => modalForgot.current?.open()}>Recuperar senha</a>
                    <div className="text-center">
                        <Button disabled={loading} type="submit" className="horizontal-gradient-login">
                            {loading ?
                                <Load />
                                :
                                "Entrar"
                            }
                        </Button>
                        <p>Não tem conta? <Link id="link" to={'/signin/email'}>Cadastre-se</Link></p>

                        {/* <p id="alternative">
                            Entre com  <a id="link" onClick={() => modalAuth.current?.open()}>
                                autenticações alternativas
                            </a>
                        </p> */}
                    </div>
                </form>
            </Card>
            <Others modalAuth={modalAuth} email />
            <Modal ref={modalBlock}>
                <BlockPlataform modalBlock={modalBlock} token={token}/>
            </Modal>
            <Forgot modalForgot={modalForgot} />
        </Container>
    )
}

export default Email;