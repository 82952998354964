import styled from "styled-components";
import {
  colapsedSchedule,
  samllNotebook,
  tablet,
} from "../../../components/NavBar/style";
import { ContainerDashBoard } from "../Dashboard/style";
import { MiddleContainer } from "./ListConversation/style";

interface StyledProps {
  colRight?: boolean;
  personal?: boolean;
  rightContent?: any;
}

export const ContentChat = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: ${({ theme }) => theme.general.column_gap};
  grid-template-rows: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  background-color: ${({ theme }) => theme.backgrounds.default};

  @media only screen and (max-width: ${colapsedSchedule}) {
    grid-gap: 0rem;
    #icon-alert {
      height: 100px;
    }
  }

  @media only screen and (max-width: ${samllNotebook}) {
    grid-template-columns: ${(props) => props.personal && "min-content auto"};
    grid-template-columns: ${(props) =>
      props.personal && props.rightContent && "1fr"};
    ${MiddleContainer} {
      display: ${(props) => props.personal && props.rightContent && "none"};
    }
  }

  @media only screen and (max-width: ${tablet}) {
    grid-template-columns: 1fr;
    ${ContainerDashBoard} {
      display: none;
    }
    #no_content_message {
      display: none;
    }
    ${MiddleContainer} {
      display: ${(props) => props.personal && props.rightContent && "none"};
    }
  }
`;
