import styled from "styled-components";
import {
  colapsedCalendar,
  colapsedSchedule,
} from "../../../../components/NavBar/style";

interface StyledProps {
  newEvent?: boolean;
  $shadow?: boolean;
}
export const ContainerTitle = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem 0.6rem 1rem;
  position: sticky;
  z-index: 2;
  align-items: center;
  top: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  background-color: ${({ theme }) => theme.backgrounds.secondaryToolbar};

  p {
    margin: 0px;
  }
  .title {
    align-items: center;
    display: flex;

    margin: 0px;
    color: ${({ theme }) => theme.texts.secondaryToolbar};
    font-family: "inter-bold", sans-serif;
    font-size: 1.5rem;
    #icon-menu {
      cursor: pointer;
      display: none;
      margin-right: 4px;
      @media only screen and (max-width: ${colapsedCalendar}) {
        display: block;
      }
    }
  }
`;

export const ContainerCalendar = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  padding: 0 1rem 1rem 1rem;
  overflow: auto;
  height: 100%;
  .myCustomHeight {
    width: 100% !important;
    .transition {
      transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1),
        -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .noTransition {
      transition: none;
    }
    #icon_read {
      color: ${({ theme }) => theme.highlights.default};
    }
    .title-event {
      padding: 0px;
      margin: 0px;
    }

    .year {
      overflow: auto;
      background-color: inherit;
    }
    .rbc-calendar {
      color: ${({ theme }) => theme.texts.darkerGreyII};
    }
    .rbc-show-more {
      background-color: initial !important;
    }
    .rbc-now {
      .rbc-button-link {
        padding: 2px;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background-color: ${({ theme }) => theme.backgrounds.buttonSchedule};
        color: ${({ theme }) => theme.texts.white};
      }
    }
    .rbc-time-view {
      > * {
        border-color: ${({ theme }) => theme.bordes.calendar} !important;
      }
    }
    .rbc-time-header {
      > * {
        border-color: ${({ theme }) => theme.bordes.calendar} !important;
      }
    }
    .rbc-time-header-content {
      > * {
        border-color: ${({ theme }) => theme.bordes.calendar} !important;
      }
    }
    .rbc-row {
      > * {
        border-color: ${({ theme }) => theme.bordes.calendar} !important;
      }
    }
    .rbc-time-header-cell {
      > * {
        border-color: ${({ theme }) => theme.bordes.calendar} !important;
      }
    }

    .rbc-time-slot {
      border-color: ${({ theme }) => theme.bordes.calendar} !important;
    }

    .rbc-timeslot-group {
      border-color: ${({ theme }) => theme.bordes.calendar} !important;
    }

    .rbc-month-row {
      border-color: ${({ theme }) => theme.bordes.calendar} !important;
    }

    .rbc-month-view {
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background-color: ${({ theme }) => theme.backgrounds.white} !important;
    }
    .rbc-time-content > * + * > * {
      border-color: ${({ theme }) => theme.bordes.calendar} !important;
    }

    .rbc-time-column {
      border-color: ${({ theme }) => theme.bordes.calendar} !important;
    }

    .rbc-day-bg {
      border-color: ${({ theme }) => theme.bordes.calendar} !important;
    }

    .rbc-selected {
      //   background-color: red !important; /* Substitua "red" pela cor desejada */
    }
    .rbc-selected-cell {
      background-color: ${({ theme }) => theme.hover.buttonHover} !important;
    }

    .rbc-off-range-bg {
      background-color: initial;
    }
    .rbc-off-range {
      .rbc-button-link {
        color: ${({ theme }) => theme.backgrounds.darkI} !important;
      }
    }

    .rbc-today {
      background-color: initial;
      button {
        padding: 2px;
        border-radius: 25%;
        color: ${({ theme }) => theme.texts.white};
      }
    }
    .rbc-time-header-cell {
      .rbc-today {
        background-color: ${({ theme }) => theme.backgrounds.buttonSchedule};
      }
    }

    .rbc-time-view {
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background-color: ${({ theme }) => theme.backgrounds.white} !important;
    }
    .rbc-toolbar {
      button {
        color: ${({ theme }) => theme.texts.darkerGreyII};
        :active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          background-color: red;
        }
        :focus {
          background-color: aliceblue;
        }
        .rbc-active {
          background-image: none;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
          border-color: black;
        }
      }
    }
    .rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      border-color: ${({ theme }) => theme.backgrounds.darkI};
      background-color: ${({ theme }) => theme.backgrounds.darkI};
    }
    .rbc-toolbar-label {
      color: ${({ theme }) => theme.texts.darkerGreyII};
    }
  }
`;
export const Container = styled.div<StyledProps>`
  display: flex;

  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.backgrounds.white};

  overflow-x: hidden;
  border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 14px;
  box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;

  #modal-menu-events {
    display: none;
  }

  @media only screen and (max-width: ${colapsedSchedule}) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  @media only screen and (max-width: ${colapsedCalendar}) {
    #modal-menu-events {
      display: block;
    }
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }
  .root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 97;
  }
  ${(props) =>
    props.newEvent
      ? ` 
      grid-template-columns: 300px 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
        "calendar newEvent"
        "calendar mySchedule"
        "calendar mySchedule";
        `
      : `
      grid-template-columns: 300px 1fr;
      grid-template-rows: 1fr 2fr;
      grid-template-areas:
        "calendar mySchedule"
        "calendar mySchedule";
        `}

  @media only screen and (max-width: 1075px) {
    grid-template-columns: 100%;
    .grid-right {
      display: none !important;
    }
    ${(props) =>
      props.newEvent
        ? ` 
        grid-template-rows: auto 1fr 1fr auto;
        grid-template-areas:
          "calendar"
          "newEvent"
          "mySchedule"
        `
        : `
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
          "calendar"
          "mySchedule"
        `}
  }
`;

export const ButtonEvent = styled.button`
  display: flex;
  border-radius: 16px;
  align-items: center;
  padding: 2px 14px;
  color: ${(props) => props.theme.texts.black};
  background-color: ${(props) => props.theme.backgrounds.white};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};
  :hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  :active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  #icon {
    color: ${(props) => props.theme.texts.black};
  }
`;
export const ContainerMySchedule = styled.div`
  background: ${(props) => props.theme.backgrounds.lightGreyIV};
  padding: 0px 32px;
  grid-area: mySchedule;
  display: block;
  min-height: 100%;
  height: fit-content;
  border-left: 2px solid ${(props) => props.theme.bordes.lightGrey};

  @media only screen and (max-width: 1226px) {
    margin-left: 0px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .topo {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: ${(props) => props.theme.backgrounds.lightGreyIV};
    margin-left: -2px;
    margin-right: -2px;
  }

  .margin_top {
    margin-top: 32px;
  }
  .icon {
    font-size: 25px;
    align-self: center;
    color: ${(props) => props.theme.begin.color.button.secondary};
    margin-right: 8px;
  }

  .filter {
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
  }
`;

export const ContainerInvitations = styled.div`
  background: ${(props) => props.theme.backgrounds.lightGreyIV};
  padding: 32px 64px 16px 32px;
  grid-area: invitations;
  display: block;
  margin: 0px 0px 0px 32px;
  border-left: 2px solid ${(props) => props.theme.bordes.lightGrey};

  @media only screen and (max-width: 1226px) {
    margin-left: 0px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

export const ContainerNewEvent = styled.div`
  background: ${(props) => props.theme.backgrounds.lightGreyIV};
  padding: 32px 32px 0px 32px;
  grid-area: newEvent;
  display: block;
  z-index: 2;
  border-left: 2px solid ${(props) => props.theme.bordes.lightGrey};

  @media only screen and (max-width: 1226px) {
    margin-left: 0px;
    padding-right: 2rem;
  }
`;

export const Title = styled.nav`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;

  .margin-left {
    margin-left: 1.5rem;
  }

  p {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 32px;
    padding: 0px 0px 0px 12px;
    margin: 16px 0px 0px 0px;
  }
  .pessoal {
    color: #23b46e;
  }
  .grupo {
    color: #2581c4;
  }
  button {
    font-family: "inter", sans-serif;
    color: #929292;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
  }

  div {
    padding-top: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    :hover {
      background-color: ${(props) =>
        props.theme.title == "light" ? "#cfcfcf" : "#0d2a3c"};
    }
    hr {
      display: none;
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: none;
      align-self: center;
      margin: 0px;
      background-color: ${(props) => props.theme.backgrounds.buttonSchedule};
      height: 4px;
    }
  }
  .active {
    color: ${(props) =>
      props.theme.title == "light" ? "#000" : "#2581c4"} !important;
    font-family: "inter-medium", sans-serif;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    display: block;
  }
`;
