import { applyMiddleware, combineReducers, createStore } from "redux";
import { DESTROY_SESSION } from "./actions/actionTypes";
import abare from './reducers/abareReducer';
import chatReducer from "./reducers/chatReducer";
import dashboardReducer from './reducers/dashboardReducer';
import folderReducer from "./reducers/folderReducer";
import scheduleReducer from './reducers/scheduleReducer';
import socketReducer from './reducers/socketReducer';
import storageCluster from './reducers/storageClusterReducer';

import configReducer from './reducers/configReducer';

import promise from 'redux-promise';
import thunk from 'redux-thunk';
import contactsReducer from "./reducers/contactsReducer";

const reducers = combineReducers({
    abare: abare,
    schedule: scheduleReducer,
    socket: socketReducer,
    folder: folderReducer,
    chat: chatReducer,
    contacts: contactsReducer,
    dashboard: dashboardReducer,
    repository: storageCluster,
    config: configReducer
})

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === DESTROY_SESSION)
        state = undefined;

    return reducers(state, action);
}

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const storeConfig = () => {
    return applyMiddleware(thunk, promise)(createStore)(rootReducer, devTools);
}

export default storeConfig;