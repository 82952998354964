import styled from "styled-components";
import { mobile, notebook } from "../../components/NavBar/style";
interface StyledProps {
  gridRow?: string;
  column2?: boolean;
  column1?: boolean;
  schedule?: boolean;
  $overflow?: boolean;
  border?: boolean;
  heightFull?: boolean;
}

interface SwitchProps {
  $dashboard?: boolean;
}
export const ContainerSwitch = styled.div<SwitchProps>`
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.backgrounds.default};
  height: 100%;

  padding: 8px 1.5rem 1.5rem 1.5rem;

  transition: padding 0.25s ease-in-out 0s;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  @media (max-width: ${notebook}) {
    padding: 0.5rem;
  }
  @media (min-width: 1200px) {
    max-width: 100%;
  }
  @media (min-width: 1440px) {
    max-width: 1440px;
  }

  @media (max-width: ${mobile}) {
    padding: ${({ $dashboard }) => ($dashboard ? "0px" : "0.5rem")};
  }
`;
export const ContainerTitle = styled.div<StyledProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(4rem + 12px + 12px);
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 5;
  background-color: ${(props) => props.theme.backgrounds.default};

  width: 100%;
  box-sizing: border-box;

  .toolbar-search {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: auto 1fr;
    flex: 1;
    align-items: center;
    box-sizing: border-box;
  }
  .buttonSearch {
    display: none !important;
  }
  .div-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 8px 1.5rem 1.5rem 1.5rem;

    transition: padding 0.25s ease-in-out 0s;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    .title-message {
      color: ${({ theme }) => theme.icon.dashboard};
      font-family: "inter-bold", sans-serif;
      font-size: 1.5rem;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
    }
    @media (max-width: ${notebook}) {
      padding: 0.5rem;
    }
    @media (min-width: 1200px) {
      max-width: 100%;
    }
    @media (min-width: 1440px) {
      max-width: 1440px;
    }
    .divRight {
      width: 100%;
      margin-right: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    @media only screen and (max-width: ${mobile}) {
      justify-content: space-between;
      .title-message {
        display: none;
      }
      #inputToolbar {
        display: none;
      }
      .buttonSearch {
        display: block !important;
      }
      .divRight {
        justify-content: space-between;
      }
    }
    @media (min-width: 1440px) {
      box-sizing: border-box;
      padding: 0px 1.5rem;
      max-width: 1440px;
    }
    #img-logo {
      display: none;
      @media only screen and (max-width: ${mobile}) {
        height: 50px;
        display: block;
      }
    }
  }

  @media only screen and (max-width: ${mobile}) {
    padding-top: 1rem;
  }

  ${(props) => props.column1 && ` margin-top: 0;`};
  @media only screen and (max-width: 900px) {
    margin: 0px;
  }

  .container_notification {
    position: relative;
    padding: 6px;
  }

  .number_notifications {
    background-color: ${({ theme }) => theme.backgrounds.buttonSchedule};
    font-family: "inter-semi-bold", sans-serif;
    font-size: 0.75rem;
    padding: 2px 6px;
    position: absolute;
    top: -4px;
    right: -0px;
    margin: 0px;
    color: white;
    border-radius: 100%;
  }

  .button {
    display: flex;
    cursor: pointer;
    #profile_dash {
      display: flex;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      align-self: center;
      object-fit: cover;
      ${(props) => props.column1 && ` display: flex;`};
      :hover {
        background-color: black;
      }
    }
    p {
      margin-top: 0px !important;
      padding-left: 1rem;
    }
  }

  .name {
    margin-bottom: 0px;
    font-size: 2rem;
    ${(props) => props.column1 && ` display: none;`};
    font-family: "inter-bold", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  .email {
    margin: 0px;
    padding: 0px;
  }
  .nameLogo {
    margin-bottom: 0px;
    font-size: 32px;
    display: none;
    ${(props) => props.column1 && ` display: flex;`};
    font-family: "inter-bold", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
    @media only screen and (max-width: 900px) {
      display: flex;
    }
  }

  .label {
    margin-top: 8px;
    font-size: 16px;
    font-family: "inter-semi-bold", sans-serif;
    color: ${(props) => props.theme.default.darkerI};
  }

  #icon_notification {
    align-self: center;
    transition: transform 0.2s ease; /* Adiciona uma transição suave */
    cursor: pointer;
    fill: ${({ theme }) => theme.icon.dashboard};
  }
  .iconDrop {
    font-size: 25px;
    fill: ${({ theme }) => theme.icon.perfil} !important;
    margin-right: 8px;
  }

  .iconCopy {
    stroke: ${({ theme }) => theme.texts.contentCards} !important;
  }
  #btn-option {
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    color: ${(props) => props.theme.purple.dark};
    #iconDrop {
      font-size: 25px;
      color: ${(props) => props.theme.begin.color.button.secondary};
    }
  }
  .button-notification {
    padding: 0.5rem;
    display: flex;
    border-radius: 100%;
    cursor: pointer;
    :hover {
      background-color: ${({ theme }) =>
        theme.backgrounds.buttonModalGroup2Login + "1a"}!important;
      #icon_notification {
        transform: scale(1.1);
      }
      .fill-icon {
        fill: ${({ theme }) =>
          theme.backgrounds.buttonModalGroup2Login}!important;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  height: 100%;

  .border {
    border: solid 1px ${({ theme }) => theme.bordes.lightGrey};
    border-radius: 14px;
    box-shadow: rgba(58, 53, 65, 0.1) 0px 2px 10px 0px;
  }
  #routes-content {
    position: static;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.backgrounds.default};

    height: 100%;
  }

  @media only screen and (max-width: 540px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
`;

export const ProfileDrop = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;

  color: ${(props) => props.theme.texts.darkerGreyII};
  overflow-y: ${({ $overflow }) =>
    $overflow ? `visible` : `auto !important;`};
  overflow-x: ${({ $overflow }) => ($overflow ? `initial` : `hidden`)};
  max-height: 75vh !important;

  .center {
    text-align: left;
    color: ${(props) => props.theme.texts.purpleDark};

    padding-left: 1rem;
  }
  .title_noti {
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey} !important ;
    padding-bottom: 8px;
    margin-bottom: 0px;
  }

  #profile {
    margin-top: 12px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    align-self: center;
    object-fit: cover;
  }

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};
    margin-top: 0.31rem;
    #name {
      margin: 0;
      font-size: 1rem;
      font-family: "inter-bold", sans-serif;
      text-transform: uppercase;
      color: ${({ theme }) => theme.texts.titleInformations};
    }

    #nickname {
      display: flex;
      margin: 0;
      margin-bottom: 1rem;
      font-size: 0.875rem;
      align-items: baseline;
      font-weight: 400;

      gap: 0.5rem;
      & > div {
        cursor: pointer;
      }
    }
  }

  li {
    border-radius: 0px !important;
    border-bottom: none !important  ;
  }
`;
// interface AnimationProps{
//   active: boolean;
// }
export const ContainerAnimation = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  position: relative;
  align-items: center;
  > * {
    transition: opacity 0.5s ease-in-out;
  }
  .active {
    opacity: 1;
    z-index: 2;
  }

  .disabled {
    opacity: 0;
    z-index: 0;
  }

  .icon-search {
    cursor: pointer;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (max-width: ${mobile}) {
    display: none;
  }

  .float {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }
`;
