import { useContext, useRef, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { ThemeContext } from "styled-components";
import { AiFillCopy } from "../../../../assets/icons";
import ModalShare from "../../../../components/Modal/ModalShare";
import { toChangeTheme } from "../../../../store/actions/themeAction";
import { TypeConstRedux } from "../../../../types/redux";
import ItemContainer from "../Components/ItemContainer";
import Container from "../Components/SettingsContainer";
import { HorizontalRow, VerticalRowItems } from "../style";

const GeneralSettings = (props) => {
    const { toChangeTheme, abare } = props;
    const { title } = useContext(ThemeContext);
    const dark = (title == "dark" ? true : false);
    const light = (title == "light" ? true : false);
    const { addToast } = useToasts();
    const shareRef = useRef<any>();
    const [shareUrl, setShareUrl] = useState('');
    const copyLink = () => {
        try {
            const url = process.env.REACT_APP_URL_WEB + "/signin/email/?invite=" + abare.invite
            setShareUrl(url);
            if (navigator.share) {
                navigator.share({ title: `Venha conhecer a plataforma de mensagens AbareNet\n`, url: url }).catch((err) => { shareRef && shareRef.current!!.open() })
            } else {
                shareRef && shareRef.current!!.open()
            }
        } catch (error) {
            addToast({
                title: "Error ao gerar link",
            },
                { appearance: 'error' });
            console.log("erro ao copiar", error)
        }
    }
    return (
        <Container title="Configurações gerais">
            {/* <Wallpaper /> */}
            {/* <DownloadAutomatic /> */}
            <ItemContainer title="Tema" isButton={false}>
                <VerticalRowItems noHover isSingle marginBottom>
                    <input type="radio" name="radio-1" checked={light} onChange={toChangeTheme} />
                    <span>Açai</span>
                </VerticalRowItems>
                <VerticalRowItems isSingle lastLine marginBottom>
                    <input type="radio" name="radio-1" checked={dark} onChange={toChangeTheme} />
                    <span>Guaraná</span>
                </VerticalRowItems>
            </ItemContainer>
            <ItemContainer title="Convite via link" isButton={false}>
                <HorizontalRow isHover onClick={() => copyLink()}>
                    <AiFillCopy id="icon"></AiFillCopy>
                    <span>Copiar link</span>
                </HorizontalRow>
                {/* <HorizontalRow isHover>
                    <BsFillShareFill id="icon" size={18}></BsFillShareFill>
                    <span>Compartilhar link</span>
                </HorizontalRow> */}
                {/*  <HorizontalRow isHover>
                    <BiReset id="icon" size={20}></BiReset>
                    <span>Redefinir link</span>
                </HorizontalRow> */}
                <div className="line">
                    Use este link para convidar alguém para entrar no Abaré.Net!
                </div>
            </ItemContainer>
            <ModalShare modalRef={shareRef} title={`Venha conhecer a plataforma de mensagens AbareNet\n`} url={shareUrl} />

        </Container>
    );


    // const View = () => {
    //     switch (option) {
    //         case 0: return <Settings />
    //         case 1: return <Wallpapers />
    //         default: return <h1>Notificações</h1>
    //     }
    // }

    // return (
    //     <Main>
    //         <View />
    //         <Dashboard />
    //     </Main>
    // );
}

const mapStateToProps = (state: TypeConstRedux) => {
    return {
        abare: state.abare.abare,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    toChangeTheme
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);

