import {
  SET_LIST_FOLDER,
  SET_LIST_ATIVA
} from "../actions/actionTypes";

const initialState = {
  list: [],
  ativa: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LIST_FOLDER:
      return {
        ...state,
        list: action.payload,
      };

    case SET_LIST_ATIVA:
      return {
        ...state,
        ativa: action.payload,
      };

    default:
      return {
        ...state
      };
  }
}