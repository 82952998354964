import { useHistory } from "react-router-dom";
import { getUserByToken } from "../../services/requests/user";
import {
  SET_ABARE,
  SET_CONFIG,
  SET_DETAILS,
  SET_NAVBAR,
  SET_NAVBAR_STATE,
} from "./actionTypes";

export const setAbare = (abare) => {
  return {
    type: SET_ABARE,
    payload: abare,
  };
};

export const setConfig = (config) => {
  return {
    type: SET_CONFIG,
    payload: config,
  };
};

export const setNavBarState = (state) => {
  return {
    type: SET_NAVBAR_STATE,
    payload: state,
  };
};

export const setNavBar = (navBar) => {
  return {
    type: SET_NAVBAR,
    payload: navBar,
  };
};

export const setDetails = (details) => {
  return {
    type: SET_DETAILS,
    payload: details,
  };
};

export const getAbare = () => {
  return (dispatch, getState) => {
    getUserByToken()
      .then((user) => {
        dispatch(setAbare(user.data));
      })
      .catch((err) => {
        useHistory().replace("/not-found");
      });
  };
};
