import { useEffect, useRef, useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { IoIosArrowUp } from "react-icons/io";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Modal } from "../../../../../components";
import { ShimmerCard } from "../../../../../components/Shimmers";
import { getCategorys } from "../../../../../services/requests/event-category";
import { addVisibleCategory, removeVisibleCategory, setCreatedCategory } from "../../../../../store/actions/scheduleAction";
import { TCategory } from "../../../../../types/project";
import { Queries } from "../../../../../types/queries";
import { TypeConstRedux } from "../../../../../types/redux";
import { getRandomColor } from "../../../../../util/verification";
import { ManagerCategory } from "../ManagerCategory";
import { Item } from "../style";

type Category = {
    label: string;
    color: string;
    checked: boolean;
    value: number;
}

interface CustomProps {
    category: Category;
    handleChecked: (cat: Category) => void;
}
const CustomCheck = ({ category, handleChecked }: CustomProps) => {
    return <div onClick={() => handleChecked(category)} className="lineChecked" >
        <Checkbox onChange={(e) => (e.stopPropagation(), handleChecked(category))} checked={category.checked} color={category.color} />
        <p>{category.label}</p>
    </div>
}

const CardPersonalizedCategory = () => {
    const dispatch = useDispatch();
    const { packageInfo } = useSelector((state: TypeConstRedux) => state.abare.abare)
    const modalCategory = useRef<any>();
    const { data, isLoading, isSuccess } = useQuery<TCategory[]>(Queries.CATEGORIES_EVENT, getCategorys)
    const [values, setValues] = useState<Category[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [alturaFormulario, setAlturaFormulario] = useState<"auto" | number>(200);

    const calcularAltura = () => {
        const formulario = document.getElementById("form-category-created-event");

        const alturaFilhos = Array.from(formulario!!.children).reduce(
            (alturaTotal, filho) => alturaTotal + filho.clientHeight,
            0
        );
        setAlturaFormulario(alturaFilhos);
    };
    useEffect(() => {
        console.log(data)
        if (isSuccess && data) {
            values.splice(0, values.length);
            data.forEach(({ id, name, active }) => {
                values.push({ value: id, label: name, checked: true, color: getRandomColor(id) })
            });
            dispatch(setCreatedCategory(data.map((category) => category.id)));

            setValues(values);
        }
    }, [isLoading, data])

    const handleChange = (category: Category) => {
        const index = values.indexOf(category);
        const value = values[index];
        const valueChecked = value.checked ?? false;
        values[index] = { ...value, checked: !valueChecked };
        if (!valueChecked) {
            dispatch(addVisibleCategory(value.value));
        } else {
            dispatch(removeVisibleCategory(value.value));
        }
        setValues([...values]);
    }

    function ordenar(a: Category, b: Category) {
        return a.label.localeCompare(b.label);
    }

    const handleOpen = () => {
        if (isOpen) {
            setAlturaFormulario(0);
        }
        else {
            calcularAltura();
        }
        setIsOpen(!isOpen);

    }
    return <Item>
        <header>
            <p className="title">Personalizadas</p>
            <div className="buttons">
                <AiOutlineSetting onClick={() => modalCategory.current?.open()} className="icon" />
                <IoIosArrowUp onClick={handleOpen} className={isOpen ? "center down icon-arrow" : "center up icon-arrow"} size={18} />
            </div>
        </header>
        <form id="form-category-created-event" style={{ maxHeight: `${alturaFormulario}px` }}>
            <div>{isLoading ?
                <>
                    <ShimmerCard height={"20px"} />
                    <ShimmerCard height={"20px"} />
                </> :
                <>{values.sort(ordenar).map((value) => <CustomCheck key={value.value} category={value} handleChecked={handleChange} />)}</>}
            </div>
        </form>
        {packageInfo.eventCategory && <Modal ref={modalCategory}>
            <ManagerCategory modal={modalCategory} />
        </Modal>}
    </Item>

}

export default CardPersonalizedCategory;