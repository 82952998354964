import { TypeMessage } from "../../types/project";
import {
  ADD_STORAGECLUSTER_FOLDER,
  CLEAR_FILTERS_REPOSITORY,
  NAVIGATION_STORAGECLUSTER_FOLDER,
  REMOVE_TYPE_FILTER_REPOSITORY,
  SET_REPOSITORY_FILE_SELECTED,
  SET_REPOSITORY_FOLDER_SELECTED,
  SET_STORAGECLUSTER_FOLDER,
  SET_TYPES_FILTER_REPOSITORY,
} from "./actionTypes";

export const addStorageClusterFolder = (folder) => {
  return {
    type: ADD_STORAGECLUSTER_FOLDER,
    payload: folder,
  };
};

export const setStorageClusterFolder = (folder) => {
  return {
    type: SET_STORAGECLUSTER_FOLDER,
    payload: folder,
  };
};
export const setRepositoryFileSelected = (file) => {
  return {
    type: SET_REPOSITORY_FILE_SELECTED,
    payload: file,
  };
};

export const setRepositoryFolderSelected = (folder) => {
  return {
    type: SET_REPOSITORY_FOLDER_SELECTED,
    payload: folder,
  };
};

export const navigationStorageClusterFolder = (folder) => {
  return {
    type: NAVIGATION_STORAGECLUSTER_FOLDER,
    payload: folder,
  };
};

export const clearAllFiltersRepository = () => {
  return {
    type: CLEAR_FILTERS_REPOSITORY,
  };
};
export const setTypesFilterRepository = (types: TypeMessage[]) => {
  return {
    type: SET_TYPES_FILTER_REPOSITORY,
    payload: types,
  };
};

export const removeTypeFilterRepository = (type: TypeMessage) => {
  return {
    type: REMOVE_TYPE_FILTER_REPOSITORY,
    payload: type,
  };
};
