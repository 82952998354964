import styled from "styled-components";
import { Conversation } from "../../../../../../pages/home/Conversations/Folder/ItemFolder/style";

export const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 50px 250px;
  overflow: hidden;

  .scroll{
    overflow-y: auto;
  }

  #icon {
    font-size: 28px;
    color: ${(props) => props.theme.texts.darkGreyI};
  }

  #icon-purple {
    font-size: 28px;
    color: ${(props) => props.theme.texts.purpleDarkTitle};
  }
`;
export const MainLoad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  justify-items: center;
  align-items: center;

  h5 {
    justify-self: start;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 18px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr 50px;
  align-items: center;

  h4 {
    padding: 1rem;
    margin: 0px;
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 15px;
  }

  #back {
    color: ${(props) => props.theme.texts.purpleDark};
    font-size: 20px;
    cursor: pointer;
  }

`;

 
export const ListConversation = styled(Conversation)`
  padding: 0.5rem;
  padding-inline: 1rem;
  pointer-events: none; 
  .relative{
    position: relative;
    padding: 0.5rem;
  }

  
`;

export const Default = styled.div`
  position: absolute;
  top: 30px;
  right:0px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border:2px solid white; 
    

`;