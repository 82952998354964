import { useEffect, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { ButtonArrow } from "../../../../../../components/Button/style.js";
import { Checked } from "../../../../../../components/Checked";
import { NotFound, Search } from "../../../../../../components/index.js";
import { ShimmerCard } from "../../../../../../components/Shimmers";
import { postAddUsersRole } from "../../../../../../services/requests/user-group-role.js";
import { getAllUsersInGroup, getParticipantesForUpdateRole } from "../../../../../../services/requests/user-group.js";
import { TProfileGroup } from "../../../../../../types/project.js";
import { Container, Header, Menu } from './style';

type TProps = {
    abare: any,
    groupInfo: TProfileGroup,
    modal: any,
    participants: any
    setParticipants: any,
    roleId: number | null,
    updateMembersGroup: any | null,
}


const PromoteAdd = (props: TProps) => {
    const { abare, modal, participants, setParticipants, roleId, updateMembersGroup, groupInfo } = props;
    const [hidden, setHidden] = useState(false);
    const [users, setUsers] = useState<any>(null)
    const { addToast } = useToasts();
    const groupId = groupInfo.id;

    useEffect(() => {
        roleId ? usersForAddRole() : usersForCreateRole()
    }, [])

    const usersForCreateRole = () => {
        getAllUsersInGroup(groupId).then((res) => {
            if (res.status == 200) {
                modifyResponse(res.data)
            }
        })
    }

    const modifyResponse = (members) => {
        if (participants.length) {
            var itens: Array<any> = [];
            members.map(user => {
                const included = !participants.some(participant => participant.id == user.id)
                included && itens.push(user)
            })
            setUsers(itens)
        } else {
            setUsers(members)
        }
    }
    const usersForAddRole = () => {
        getParticipantesForUpdateRole(abare.id, groupId, roleId).then((res) => {
            if (res.status == 200) {
                setUsers(res.data)
            }
        })
    }

    const updateUsers = () => {
        if (hidden) {
            const itens = users.filter(element => element.checked)
            if (roleId) {
                promoteUsersRole(itens)
            } else {
                setParticipants([...participants, ...itens])
                modal.current.close()
            }
        }
    }

    const promoteUsersRole = (itens) => {
        var itensAdd: Array<any> = []
        itens.map((user) => {
            itensAdd.push(user.id)
            user.checked = false;
            return user;
        })

        postAddUsersRole(groupId, roleId, itensAdd).then((res) => {
            if (res.status == 200) {
                const message = res.data.message.pt
                addToast({
                    title: message,
                    description: `Membros promovidos para a função `

                },
                    { appearance: 'success' });
                updateParticipants(res.data.result, itens)
            }
        })
    }

    const updateParticipants = (data, itens) => {
        itens.map((user) => {
            const userGroupRole = data.find(x => x.UserGroup_User_id == user.id);

            user.roleBegin = userGroupRole.roleBegin;
            return user;
        })

        const members = [...participants, ...itens]
        updateMembersGroup(members)
        modal.current.close()

    }
    return (
        <Container three={true}>
            <Header>
                <div>
                    <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                    <h5>Funções - Promover</h5>
                </div>
            </Header> 
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    { users && setUsers(result) }
                }} />
                <ButtonArrow onClick={() => updateUsers()}>
                    <AiFillCheckCircle id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>
            </Menu>
            <div className="scroll">
                {users ?
                    users.length ?
                        <Checked users={users} setUsers={setUsers} setHidden={setHidden} />
                        :
                        <NotFound description="Não há membros para promover" />
                    :
                    <div>
                        <ShimmerCard height={"75px"} />
                        <ShimmerCard height={"75px"} />
                    </div>
                }
            </div>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        abare: state.abare.abare,
        personal: state.chat.personal
    }
}



export default connect(mapStateToProps)(PromoteAdd);