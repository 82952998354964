import styled from "styled-components";
import { mobile } from "../../../../components/NavBar/style";

export const Container = styled.form`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgrounds.white};
  padding: 0px 1.5rem 1.5rem 1.5rem;
  max-height: 960px;
  overflow: auto;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  align-self: center;
  margin-bottom: 60px;

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
  }

  textarea {
    height: 150px !important;
  }
  button {
    margin-top: 8px;
    box-sizing: content-box;
    min-width: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 12px 24px;
    align-self: flex-end;
    background: ${(props) => props.theme.texts.purpleDarkTitle};
    color: white;
    font-family: "Poppins semi-bold";
    font-size: 16px;

    border-radius: 8px;
  }

  .p_send {
    color: ${({ theme }) => theme.texts.darkerGreyI};
  }
`;

interface TitleProps {
  fontSize?: string;
  padding?: boolean;
}
export const ContainerTitle = styled.div<TitleProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) =>
    padding ? "1.5rem 1.5rem 4px 1.5rem" : "1.5rem 0px 4px 0px"};

  margin-bottom: 1rem;
  position: sticky;
  top: 0px;
  z-index: 5;
  background-color: ${(props) => props.theme.backgrounds.white};
  border-bottom: 1px solid ${(props) => props.theme.begin.color.card.border};

  @media only screen and (max-width: ${mobile}) {
    padding-top: 1rem;
  }

  @media only screen and (max-width: 900px) {
    margin: 0px;
  }

  #icon-back {
    margin-right: 8px;
    cursor: pointer;
  }
  .button {
    display: flex;
    #profile_dash {
      display: none;
      width: 52px;
      height: 52px;
      border-radius: 100%;
      align-self: center;
      object-fit: cover;
    }
    p {
      margin-top: 0px !important;
      padding-left: 1rem;
    }
    :hover {
      cursor: pointer;
    }
    @media only screen and (max-width: 900px) {
      #profile_dash {
        display: flex;
      }
    }
  }

  .name {
    margin-bottom: 0px;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "32px")};
    font-family: "inter-bold", sans-serif;
    color: ${(props) => props.theme.texts.purpleDark};
  }
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  padding: 0px 4px 0px 0px;
  color: #fff;
  background-color: gray;
  height: 75px;
  width: 100%;
  font-size: 45px;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  word-wrap: break-word !important;
  max-width: 100%;
  div {
    display: flex;
    align-items: center;
  }
  svg {
    min-width: 50px;
  }
  p {
    margin-left: 6px;
    flex-shrink: 1;
    font-size: 1rem;
    overflow-wrap: break-word;
    word-break: break-word !important;
  }
  .media {
    min-width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 75px;
  }

  img,
  video {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
  }
`;
