import { api } from '../api';
import { getToken } from "../auth";


export const postAddUsersRole = (groupId, roleId, userIds) => {
    return api({
        url: `/user-group-role/${groupId}/${roleId}`,
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { userIds }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchRemoveUsersRole = (groupId, roleId, users) => {
    return api({
        url: `/user-group-role/${groupId}/${roleId}`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { users }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchUpdateLeaders = (userId, groupId, usersAdd, usersRemove) => {
    return api({
        url: `/user-group-role/leaders/${userId}/${groupId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { usersAdd, usersRemove }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteUsersRole = (groupId, roleId, users) => {
    return api({
        url: `/user-group-role/${groupId}/${roleId}`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { users }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};
