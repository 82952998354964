import { Dispatch, SetStateAction, useContext, useRef } from "react";
import { AiFillSave } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { ModalRef } from "../../../../components/Modal";
import {
  ReportOptionsInterface,
} from "../../../../types/project";
import { HeaderTable } from "../../../../util/report/reportTypes";
import ModalNewTemplate from "../components/ModalNewTemplate";
import ContentBlock from "./Blocks/ContentBlock";
import DownloadBlock from "./Blocks/DownloadBlock";
import TemplateBlock from "./Blocks/TemplateBlock";
import VisualBlock from "./Blocks/VisualBlock";
import { ContainerList, Header } from "./style";
import { ReportContext } from "../../../../contexts/ReportContext";

interface OptionsReportProps {
  border?: string;
  open?: boolean;
  data: Object[] | undefined;
  header: HeaderTable;
  optionsPDF: ReportOptionsInterface;
  setOptionsPDF: Dispatch<SetStateAction<ReportOptionsInterface>>;
  setHeader: Dispatch<SetStateAction<HeaderTable | undefined>>;
  setUpdate: Dispatch<SetStateAction<boolean>>;
  download: () => void; //Função para baixar pdf
  isLoading: boolean; //Saber se a pagina estar carregando as informações
  isWaiting: boolean; //Saber se a pagina estar esperando as informações do usuário
  urlPdf: string | undefined;
}

const OptionReport = ({
  isLoading,
  isWaiting,
  border,
  data,
  header,
  setHeader,
  setUpdate,
  download,
  optionsPDF,
  setOptionsPDF,
  open = true,
  urlPdf,
}: OptionsReportProps) => {
  const modalRef = useRef<ModalRef>(null);
  const { closeOptionReportSelected } = useContext(ReportContext);
  const onSave = () => modalRef.current?.open();

  return (
    <ContainerList $open={open} border={border}>
      <Header>
        <IoIosArrowBack
          onClick={closeOptionReportSelected}
          id="icon"
          className="iconBack"
        />
        <h3>Configurações do relatório</h3>
        <div onClick={onSave} className="buttons">
          <AiFillSave title="Salvar template" className="icon-color" />
        </div>
      </Header>
      <ContentBlock
        header={header}
        optionsPDF={optionsPDF}
        setOptionsPDF={setOptionsPDF}
      />
      <VisualBlock
        header={header}
        setHeader={setHeader}
        setUpdate={setUpdate}
      />
      <DownloadBlock
        isLoading={isLoading}
        isWaiting={isWaiting}
        data={data}
        header={header}
        downloadPdf={download}
      />
      <TemplateBlock />
      <ModalNewTemplate
        urlPdf={urlPdf}
        modal={modalRef}
      />
    </ContainerList>
  );
};

export default OptionReport;
