import {
  EnumOrdering,
  ReportOrdering,
  TypeDataTable,
} from "../../types/project";
import { HeaderTable } from "./reportTypes";

const sortData = (
  data: any[],
  optionSort: ReportOrdering,
  header: HeaderTable
): any[] => {
  if (optionSort.column !== undefined && header.itens[optionSort.column]) {
    return sortValue(
      data,
      header.itens[optionSort.column].type,
      optionSort.type,
      optionSort.column
    );
  }
  return data;
};

const sortValue = (
  data: any[],
  typeData: TypeDataTable,
  typeSort: EnumOrdering,
  key: string
): any[] => {
  const valueSort = typeSort == EnumOrdering.CRESCENT ? 1 : -1;
  function sortBoolean(a, b) {
    if (a[key] && !b[key]) {
      return valueSort;
    } else if (!a[key] && b[key]) {
      return valueSort * -1;
    }
    return 0;
  }
  function sortString(a, b) {
    return a[key].localeCompare(b[key]) * valueSort;
  }

  function sortNumber(a, b) {
    if (a[key] > b[key]) {
      return valueSort;
    } else if (a[key] < b[key]) {
      return valueSort * -1;
    }
    return 0;
  }

  function sortData(a, b) {
    const dataA = new Date(a[key]).getTime();
    const dataB = new Date(b[key]).getTime();
    if (dataA > dataB) {
      return valueSort;
    } else if (dataA < dataB) {
      return valueSort * -1;
    }
    return 0;
  }

  function sort(a, b) {
    if (a[key] == null && b[key] == null) {
      return 0;
    }
    if (a[key] == null) {
      return valueSort;
    }
    if (b[key] == null) {
      return valueSort * -1;
    }
    switch (typeData) {
      case TypeDataTable.BOOLEAN:
        return sortBoolean(a, b);
      case TypeDataTable.STRING:
        return sortString(a, b);
      case TypeDataTable.NUMBER:
        return sortNumber(a, b);
      case TypeDataTable.DATA:
      case TypeDataTable.DATA_BIRTHDAY:
        return sortData(a, b);
      default:
        return sortNumber(a, b);
    }
  }
  return data.sort(sort);
};

export const ReportSort = {
  sortData,
};
