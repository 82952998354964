import { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ThemeContext } from "styled-components";
import { Figlogin, FigloginDark, Logo } from '../../../assets/images';
import { CodeSMS, InputPhone, Load } from "../../../components";
import { login } from "../../../services/auth";
import { getUserByToken, postLoginNumber, postLoginVerifyNumber } from "../../../services/requests/user";
import { errorDefault, ToastModel } from "../../../util/error";
import Others from "../Others";
import { Button, Card, Container, Header } from '../style';

const Phone = () => {
    const { addToast } = useToasts();
    const [code, setCode] = useState<String>("");
    const [phone, setPhone] = useState<String>();
    const [send, setSend] = useState(false);
    const [loading, setLoadinng] = useState(false);
    const [submitLoad, setSubmitLoad] = useState(false);
    const history = useHistory();
    const [timer, setTimer] = useState(0);
    const modalAuth = useRef<any>();

    const { title } = useContext(ThemeContext);
    const img = (title == "light" ? Figlogin : FigloginDark)

    useEffect(() => {
        if (send && timer > 0) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer, send])

    const submit = (event) => {
        event.preventDefault();
        if (phone) {
            setLoadinng(true);
            postLoginNumber(phone).then((res) => {
                const status = res.status

                if (status) {
                    if (status == 200) {
                        addToast({
                            title: 'SMS enviado com sucesso!',
                            description: "Verifique o código que foi enviado para seu celular"
                        },
                            { appearance: 'success' });
                        setTimer(30);
                        setSend(true);
                    } else {
                        const message = res.data.message.pt

                        switch (status) {
                            case 404:
                                ToastModel(addToast, message, "Informe outro telefone ou realize um cadastro", "error")
                                break;
                            case 400:
                                ToastModel(addToast, 'Erro ao enviar o SMS!', "Informe um número de celular válido", "error")
                                break;
                            case 409:
                                ToastModel(addToast, message, "Confirme seu número na página de cadastro.", "error")
                                break;
                            default:
                                errorDefault(status, addToast)
                        }
                    }
                } else {
                    ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
                }
                setLoadinng(false);
            });

        }
    }

    const loginVerifyPhone = () => {
        if (code.length == 6) {
            setSubmitLoad(true)
            postLoginVerifyNumber(phone, code).then((res) => {
                if (res.status == 200) {
                    const token = res.result.access_token
                    login(token)
                    getUser()
                } else {
                    addToast({
                        title: 'Código incorreto!',
                        description: "Favor enviar o código correto"
                    },
                        { appearance: 'error' });
                    setSubmitLoad(false);
                }
            });
        }
    }

    const getUser = () => {
        getUserByToken().then((res) => {
            if (res.data.nickname) {
                history.push('/home')
            } else {
                history.push('/signin/data')
            }
        });
    }

    return (
        <Container className="vertical-gradient">
            <img className="fig" src={img} />
            <Card>
                <Header>
                    <img id="logo" src={Logo} alt="Logo Abaré.Net" />
                </Header>
                <h2 id="title">Conecte-se</h2>
                <form onSubmit={submit}>
                    <InputPhone onChange={setPhone} value={phone} disabled={send} timer={timer}
                        loading={loading} name="phone" label="Número de Telefone"
                        type="text" placeholder="Digite seu número aqui" />
                    {send && <a id="link" onClick={() => setSend(false)}>Alterar número</a>}
                </form>

                <div className="text-center"> 
                    <div className={"code disabled" + send}>
                        <h5>Insira o código que foi enviado para seu celular</h5>
                        <CodeSMS
                            disabled={!send}
                            autoFocus
                            isNumberInput
                            length={6}
                            className="otpContainer"
                            inputClassName="otpInput"
                            onChangeOTP={(otp) => setCode(otp)}
                        />
    
                        {!submitLoad ?
                            <Button  className="horizontal-gradient-login" disabled={!send} onClick={() => loginVerifyPhone()}>
                                Entrar
                            </Button>
                            :
                            <Button disabled={true} onClick ={() => loginVerifyPhone()}>
                                <Load />
                            </Button>
                        }
                    </div>
                    <p id="alternative">
                        <a id="link" onClick={() => modalAuth.current?.open()}> Autenticações alternativas </a>
                        ou
                        <Link id="link" to={'/signin/phone'}> Cadastre-se</Link>
                    </p>
                </div>
                <Others modalAuth={modalAuth} />
            </Card >
        </Container>
    );
}
export default Phone;