import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { useRef } from "react";

import { AiFillEye } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { HiDownload } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Dropdown, ItemFile, Modal } from "../../../../../../components";
import { ModalRef } from "../../../../../../components/Modal";
import { StorageMediaService } from "../../../../../../services/requests/storage-media";
import { setRepositoryFileSelected } from "../../../../../../store/actions/storageClusterAction";
import { DownloadType, FileRepository } from "../../../../../../types/project";
import { Queries } from "../../../../../../types/queries";
import { ToastModel } from "../../../../../../util/error";
import { transformUrlImage } from "../../../../../../util/verification";
import ModalExit from "../../modal/modalConfirmation";
import ModalShare from "../../modal/modalShare";
import ModalUpdateMedia from "../../modal/modalUpdateMedia";
import { Container } from "./style";

interface FileInterface {
  file: FileRepository;
}

const FileCard = ({ file }: FileInterface) => {
  const confirmationRef = useRef<any>();
  const fileRef = useRef<any>();
  const shareRef = useRef<ModalRef>(null);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (id: string) => StorageMediaService.deleteMedia([id]),
    {
      onSuccess: ({ status }: AxiosResponse) => {
        if (status == 200) {
          queryClient.setQueryData(
            [
              Queries.REPOSITORY_FILES,
              file.StorageCluster_id ? file.StorageCluster_id : "",
            ],
            (prevData) => {
              if (Array.isArray(prevData)) {
                return prevData.filter(
                  (item) => item.fileName != file.fileName
                );
              }
              return [];
            }
          );
          confirmationRef.current?.close();
        } else {
          ToastModel(
            addToast,
            "Erro de conexão!",
            "Tente novamente mais tarde.",
            "error"
          );
        }
      },
      onError: () => {
        ToastModel(
          addToast,
          "Erro de conexão!",
          "Tente novamente mais tarde.",
          "error"
        );
      },
    }
  );

  const handleDelete = () => {
    if (!isLoading) {
      mutate(file.fileName);
    }
  };

  const handleFileSelected = () => {
    dispatch(setRepositoryFileSelected(file));
  };

  const onShare = () => shareRef.current?.open();
  const openEdit = () => fileRef.current?.open();
  const openDelete = () => confirmationRef.current?.open();

  return (
    <Container>
      <header>
        <span title={file.name} className="truncate">
          {" "}
          {file.name}
        </span>
        <div className="dropdown">
          <Dropdown defaultClass="bottom-left">
            <FiMoreVertical id="menu" className="buttonDrop" />
            <div className="widthLista">
              <li onClick={openEdit}>
                {" "}
                <MdModeEdit className="icon" size={18} /> Editar
              </li>

              <form>
                <li onClick={openDelete}>
                  {" "}
                  <FaTrash className="icon" size={14} /> Excluir
                </li>
              </form>

              <form>
                <li onClick={onShare}>
                  {" "}
                  <BsFillShareFill className="icon" size={14} /> Compartilhar
                </li>
              </form>

              <form>
                <li
                  onClick={() => {
                    console.log("oiii");
                    fetch(transformUrlImage(file.fileURL!))
                      .then((res) => {
                        console.log("Fettttt");
                        if (res.ok) {
                          return res.blob();
                        } else {
                          //Error
                        }
                      })
                      .then((blob) => {
                        const { fileName, fileType } = file;
                        const getNameFile = () => {
                          const name = fileName!.split(/-(.*)/s)[1];
                          return name ? name : fileName;
                        };

                        if (blob) {
                          saveAs(
                            blob,
                            getNameFile() + "." + DownloadType[fileType!]
                          );
                        }
                      })
                      .catch(() => {
                        //Download
                      });
                  }}
                >
                  {" "}
                  <HiDownload className="icon" size={14} /> Fazer Download
                </li>
              </form>

              <li onClick={handleFileSelected}>
                {" "}
                <AiFillEye className="icon" size={14} /> Ver detalhes
              </li>
            </div>
          </Dropdown>
        </div>
      </header>

      <div className="media">
        <ItemFile type={file.fileType} src={transformUrlImage(file.fileURL)} />
      </div>

      <Modal mobile={true} ref={shareRef}>
        <ModalShare type="file" modal={shareRef} file={file} />
      </Modal>
      <Modal mobile={true} ref={fileRef}>
        <ModalUpdateMedia file={file} modal={fileRef} />
      </Modal>
      <ModalExit
        title="Deletar arquivo"
        action={handleDelete}
        modalRef={confirmationRef}
      />
    </Container>
  );
};

export default FileCard;
