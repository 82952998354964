import { Line } from "rc-progress";
import { memo, useContext, useState } from "react";
import { AiFillHdd, AiOutlineSchedule } from "react-icons/ai";
import { BiConversation } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdSdStorage } from "react-icons/md";
import { RiUserReceivedLine, RiUserShared2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { setTypeRepository } from "../../../../../store/actions/scheduleAction";
import { setStorageClusterFolder } from "../../../../../store/actions/storageClusterAction";
import { EnumTypeRepository, TypeConstRedux } from "../../../../../types/redux";
import { formatBytes } from "../../../../../util/verification";
import SidebarItem from "./SidebarItem";
import { SideBAr, SidebarItemContainer } from "./styles";

const SubClusterItem = ({ cluster, idSelected, pathCluster, layer }) => {
  // const queryCluster = useQuery([Queries.REPOSITORY_FOLDERS, cluster], () => getAllSubCluster(cluster.id));
  // const [visible, setVisible] = useState(false);
  // const dispatch = useDispatch();
  // const thisPathCluster = (path: any[]) => {
  //     path.push({ name: cluster.name, id: cluster.id })
  //     return path;
  // }

  // const handleCluster = (e: React.MouseEvent<HTMLDivElement>) => {
  //     e.stopPropagation();
  //     dispatch(setStorageClusterFolder([...thisPathCluster(pathCluster())]));
  // }

  // const handleVisible = (e: React.MouseEvent<HTMLDivElement>) => {
  //     e.stopPropagation();
  //     setVisible(!visible);
  // }
  // return (
  //     <>
  //         <SidebarItem open={visible} onArrow={handleVisible} layer={layer} onAction={handleCluster} selected={idSelected == cluster.id} arrow icon={(<AiFillFolder />)} label={cluster.name} />
  //         {visible && queryCluster.isSuccess && (
  //             queryCluster.data.map((folder) => <SubClusterItem layer={layer + 1} pathCluster={() => {
  //                 return thisPathCluster(pathCluster());
  //             }} idSelected={idSelected} cluster={folder} key={folder.id} />)
  //         )}
  //     </>)
  return <></>;
};
function index() {
  // const queryCluster = useQuery([Queries.REPOSITORY_FOLDERS, { id: "", name: "Meu Repositório" }], () => getAllSubCluster(""));
  const { folder } = useSelector((state: TypeConstRedux) => state.repository);
  const { packageInfo } = useSelector((state: any) => state.abare.abare);
  const typeSelect = useSelector(
    (store: TypeConstRedux) => store.repository.typeSelect
  );
  const porcentagemArmazenamento =
    (packageInfo.hdUsed * 100) / packageInfo.hdLimit;
  const idSelected = folder.length == 0 ? "" : folder[folder.length - 1].id;
  const { texts } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const typeSelected = (type: EnumTypeRepository) => type == typeSelect;
  const handleTypeSelected = (type: EnumTypeRepository) =>
    dispatch(setTypeRepository(type));

  const handleVisible = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setVisible(!visible);
  };
  const handleCluster = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleTypeSelected(EnumTypeRepository.MY_SPACE);
    dispatch(setStorageClusterFolder([]));
  };
  return (
    <SideBAr>
      <SidebarItemContainer>
        <SidebarItem
          open={visible}
          onArrow={handleVisible}
          onAction={handleCluster}
          selected={typeSelected(EnumTypeRepository.MY_SPACE)}
          arrow
          icon={<AiFillHdd />}
          label={"Meu espaço"}
        />
        <SidebarItem
          active={false}
          selected={typeSelected(EnumTypeRepository.SHARE_WITH_ME)}
          open={visible}
          onArrow={handleVisible}
          onAction={() => handleTypeSelected(EnumTypeRepository.SHARE_WITH_ME)}
          icon={<RiUserReceivedLine />}
          label={"Compartilhados comigo"}
        />
        <SidebarItem
          active={false}
          selected={typeSelected(EnumTypeRepository.SHARE_WITH_FRIENDS)}
          onAction={() =>
            handleTypeSelected(EnumTypeRepository.SHARE_WITH_FRIENDS)
          }
          icon={<RiUserShared2Line />}
          label={"Compartilhados"}
        />
        <hr className="divider-item" />

        <SidebarItem
          active={false}
          selected={typeSelected(EnumTypeRepository.CONVERSATION)}
          open={visible}
          onAction={() => handleTypeSelected(EnumTypeRepository.CONVERSATION)}
          onArrow={handleVisible}
          icon={<BiConversation />}
          label={"Conversas"}
        />
        <hr className="divider-item" />

        <SidebarItem
          active={false}
          selected={typeSelected(EnumTypeRepository.SCHEDULE)}
          onAction={() => handleTypeSelected(EnumTypeRepository.SCHEDULE)}
          open={visible}
          onArrow={handleVisible}
          icon={<AiOutlineSchedule />}
          label={"Agendas"}
        />
        <hr className="divider-item" />

        <SidebarItem
          active={false}
          selected={typeSelected(EnumTypeRepository.REPORT)}
          onAction={() => handleTypeSelected(EnumTypeRepository.REPORT)}
          open={visible}
          onArrow={handleVisible}
          icon={<HiOutlineDocumentReport />}
          label={"Relatórios"}
        />

        <hr />

        <div className="storage-repository">
          <SidebarItem
            onAction={(e) => {}}
            icon={<MdSdStorage />}
            label={"Armazenamento"}
          />
          <Line
            className="lineBar"
            percent={
              porcentagemArmazenamento > 0 ? porcentagemArmazenamento : 0
            }
            strokeWidth={2}
            trailWidth={2}
            strokeColor={texts.purpleDarkTitle}
          />
          <p className="p-informations-storage">{`${formatBytes(
            packageInfo.hdUsed * 1000000
          )} de ${formatBytes(packageInfo.hdLimit * 1000000)} usados`}</p>
        </div>
      </SidebarItemContainer>
    </SideBAr>
  );
}

export default memo(index);
