import styled, { css } from "styled-components";

export const ContainerChannel = styled.div`
  display: grid;
  grid-template-columns: 252px 1fr 400px;
  grid-template-rows: 100vh;
  max-height: 100vh;
  overflow-y: hidden;

  @media only screen and (max-width: 1500px) {
    grid-template-columns: 100px 1fr;
  }
`;

export const Container = styled.div`
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 124px;

  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    /* Chrone */
    display: none;
  }

  @media (max-width: 450px) {
    padding: 0 20px;
  }
`;

export const Back = styled.div`
  height: 56px;
  width: 100%;

  .icon {
    height: 24px;
    width: 22.82px;
    padding: 6px 5.71px;
    margin: 10px 0;
  }
`;

export const Header = styled.div`
  border-radius: 10px;
  background-color: #fff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
`;

export const Banner = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: min(33vw, 199px);
  position: relative;

  > img {
    flex-shrink: 0;
    object-fit: cover;

    width: 100%;
    height: min(33vw, 256px);
    position: relative;
    border-radius: 10px 10px 0 0;
  }
`;

export const Avatar = styled.div`
  > img {
    width: max(100px, min(160px, 14vw));
    height: max(100px, min(160px, 14vw));

    border: 5px solid #ffffff;
    background: var(--gray);
    border-radius: 50%;
    object-fit: cover;

    position: absolute;
    bottom: max(-140px, -10vw);
    left: 81px;

    @media (max-width: 450px) {
      left: 20px;
    }
  }
`;
export const Followage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 285px !important;
  top: 2vw;

  > span {
    margin-right: 62px;
    color: #484848;

    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;

    > strong {
      color: #7c1a40;
    }
  }
`;

export const Button = styled.button`
  background-color: transparent;
  color: #7c1a40;
  padding: 10px;
  border: 2px solid #7c1a40;
  border-radius: 25px;
  cursor: pointer;
  outline: 0;
  margin-right: 72px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const ProfileData = styled.div`
  padding: 140px 90px 0;

  display: flex;
  flex-direction: column;

  position: relative;

  > h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #2f2e2e;
    margin-bottom: 0;
  }

  .tags {
    list-style: none;
    display: flex;
    align-items: center;

    padding: 0;
    margin: 5px 0;
    color: #fff;

    > li {
      font-style: normal;
      font-weight: normal;

      padding: 1px 8px;
      background-color: #bc718e;
      border-radius: 4px;

      & + li {
        margin-left: 15px;
      }
    }
  }

  .userNickname {
    display: flex;
    align-items: center;

    > img {
      height: 21px;
      width: 21px;
      border-radius: 50%;
      margin-right: 6px;
    }

    > h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #666666;
      margin: 8px 0 16px 0;
    }
  }

  > p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #2f2e2e;
    margin: 0 0 16px;
  }

  .accountData {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 0 24px;

    > li {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      color: #666666;

      & + li {
        margin-left: 72px;
      }

      .iconData {
        color: #7c1a40;
        height: 15px;
        width: 15px;
        margin-right: 3.5px;
      }

      > a {
        text-decoration: none;
        color: #666666;
      }
    }
  }
`;

const iconCSS = css`
  width: 20px;
  height: 20px;

  color: var(--gray);
`;
