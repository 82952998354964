import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { IoIosClose } from '../../../../../../assets/icons';
import { ItemFile, Load, NotFound } from '../../../../../../components';
import FloatingButton from '../../../../../../components/Button/FloatingButton/FloatingButton';
import ModalContainer from "../../../../../../components/Modal/ModalContainer";
import { ShimmerCard } from '../../../../../../components/Shimmers';
import { deleteFileEvent, getFilesEvent, postFileEvent } from '../../../../../../services/requests/event';
import { TEvent } from '../../../../../../types/project';
import { errorDefault } from '../../../../../../util/error';
import { Item } from '../../../../Dashboard/Protocol/style';

type TProps = {
    modal: any,
    event: TEvent,
}

const FilesEvent = ({ modal, event }: TProps) => {
    const { addToast } = useToasts();
    const [filesEvent, setFilesEvent] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const fileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setIsLoading(true);
        getFilesEvent(event.id).then((res) => {
            if (!errorDefault(res.status, addToast)) {
                setFilesEvent(res.data);
            }
        }).finally(() => { setIsLoading(false) });
    }, [])

    function handleFileSelected(e: ChangeEvent<HTMLInputElement>) {
        setIsSending(true);
        const list: File[] = Array.from(e.target.files ? e.target.files : []);
        addFiles(list)
    }

    async function addFiles(files) {
        await Promise.all(files.map(async (file) => {
            const dataForm = new FormData();
            dataForm.append('file', file);
            dataForm.append('eventId', event.id.toString());
            dataForm.append('name', file.name);

            postFileEvent(dataForm).then((res) => {
                if (!errorDefault(res.status, addToast)) {
                    setFilesEvent((files) => ([...files, res.data]));
                } 
            }).catch((err) => {
                console.log(err)
            }).finally(() => setIsSending(false))
        }));
    }

    function deleteFile(file) {
        const index = filesEvent.indexOf(file);
        const fileDeleting = { ...file, isDeleting: true };
        filesEvent.splice(index, 1, fileDeleting);
        setFilesEvent([...filesEvent]);

        deleteFileEvent(file.id).then((res) => {
            if (!errorDefault(res.status, addToast)) {
                filesEvent.splice(index, 1);
                setFilesEvent([...filesEvent]);
                addToast({ title: 'Arquivo Excluído com sucesso', }, { appearance: 'success' });
            } else {
                throw Error("Error ao tentar excluir arquivo");
            }
        }).catch(() => {
            filesEvent.splice(index, 1, file);
            setFilesEvent([...filesEvent]);
        }).finally(() => setIsSending(false))
    };

    const ListPeopleWithPermissionEdit = () => (
        <>
            {
                filesEvent.length > 0 ?
                    <div>
                        {filesEvent.map((file) =>
                            file.isDeleting ? <ShimmerCard height="75px" margin='8px 0px 0px 0px' /> :
                                <Item key={file.id} >
                                    <div className="media">
                                        <ItemFile className="img-video" type={file.fileType} src={file.fileURL} />
                                    </div>
                                    <p>{file.name}</p>
                                    <IoIosClose onClick={(e) => (e.stopPropagation(), deleteFile(file))} />
                                </Item>)}
                    </div>
                    :
                    <NotFound description="Nenhum Arquivo" />
            }
        </>
    );
    return (
        <ModalContainer title="Arquivos" onClick={() => modal.current.close()} buttonBack={true}>
            <p className='only_line'>Você pode adicionar <p className='hint'>&nbsp;+&nbsp;</p> ou remover <p className='hint'>&nbsp;x&nbsp;</p> arquivos</p>
            <input ref={fileInput} style={{ display: "none" }}
                onChange={(e) => handleFileSelected(e)} type="file" multiple accept="aplication/*" />
            {isLoading ? <div className='load'>
                <ShimmerCard height="75px" margin='8px 0px 0px 0px' />
                <ShimmerCard height="75px" margin='8px 0px 0px 0px' />
            </div>
                : isSending ? <Load message="Enviando..." dark={true} />
                    : <ListPeopleWithPermissionEdit />}
            <FloatingButton size='large' onclick={() => fileInput.current?.click()} mobile={false} icon="add" />
        </ModalContainer>
    )
}

export default FilesEvent;