import Container from "../Components/SettingsContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toChangeTheme } from "../../../../store/actions/themeAction";
import ItemContainer from "../Components/ItemContainer";
import { Header, ListConversation } from "./style";
import { Dropdown, Modal, Search } from "../../../../components";
import { useEffect, useRef, useState } from "react";
import { TypeConstRedux } from "../../../../types/redux";
import { photoUrl } from "../../../../util/verification";
import {
  BsPersonPlus,
  BsPersonDash,
  MdMoreVert,
  BsFillCloudUploadFill,
  BsFillCloudDownloadFill,
} from "../../../../assets/icons";
import { useHistory } from "react-router-dom";
import DeleteContacts from "./DeleteContacts";
import { deleteContactsRedux } from "../../../../store/actions/contactsAction";
import UploadContacts from "./Upload";
import Img from "../../../../components/Img";
import AddContact from "../../../../components/AddContact";

const Contacts = (props) => {
  const { contacts, deleteContactsRedux } = props;
  const [contactsResult, setContactsResult] = useState<any>(contacts);
  const { push } = useHistory();
  const modalDelete = useRef<any>();
  const modalUpload = useRef<any>();
  const [isNewContact, setIsNewContact] = useState(false);

  function closeNewContact() {
    setIsNewContact(false);
  }
  useEffect(() => {
    setContactsResult(contacts);
  }, [contacts]);

  const downloadContacts = () => {
    const json = JSON.stringify(contacts);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "contatos.json";
    link.click();
    URL.revokeObjectURL(url);
  };
  const MenuContacts = () => (
    <Dropdown defaultClass="bottom-left">
      <MdMoreVert id="menu" />
      <div>
        <li
          onClick={() => {
            modalUpload.current.open();
          }}
        >
          {" "}
          <BsFillCloudUploadFill className="icon" /> Upload de contatos
        </li>
        <li
          onClick={() => {
            downloadContacts();
          }}
        >
          {" "}
          <BsFillCloudDownloadFill className="icon" /> Download de contatos
        </li>
      </div>
    </Dropdown>
  );

  return isNewContact ? (
    <AddContact onClose={closeNewContact} />
  ) : (
    <Container title="Meus contatos" menu={MenuContacts}>
      <ItemContainer isButton={false}>
        <Header>
          <Search
            itens={contacts || []}
            onSearch={(result) => {
              setContactsResult(result);
            }}
          />
          <div
            className="icon-circle"
            onClick={() => setIsNewContact(true)}
          >
            <BsPersonPlus id="icon" />
          </div>
          <div
            className="icon-circle"
            onClick={() => modalDelete.current.open()}
          >
            <BsPersonDash id="icon" />
          </div>
        </Header>

        {contactsResult.map((contact, key) =>
          !contact.hidden ? (
            <ListConversation
              key={key}
              onClick={
                () =>{}
                  // push(
                  //   "/settings/contacts/user/" + contact.contactId
                  // ) /* setContact(contact) */
              }
            >
              <Img className="profile" src={photoUrl(contact)} />
              <div id="col-message">
                <h4 id="name" className="sz-16">
                  {contact.name}
                </h4>
              </div>
            </ListConversation>
          ) : null
        )}
      </ItemContainer>
      <Modal ref={modalDelete}>
        <DeleteContacts
          contacts={contacts}
          modal={modalDelete}
          deleteContactsRedux={deleteContactsRedux}
        />
      </Modal>
      <Modal ref={modalUpload}>
        <UploadContacts modalRef={modalUpload} />
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state: TypeConstRedux) => {
  return {
    abare: state.abare.abare,
    contacts: state.contacts.list,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toChangeTheme,
      deleteContactsRedux,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
