import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconNotifications = ({
  color = "#666666",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon viewBox="0 0 24 24" color={color} size={size} {...rest}>
      <path
        d="M16.026 21.4737C16.0262 22.111 15.7864 22.7249 15.3545 23.1923C14.9227 23.6596 14.3308 23.9459 13.6975 23.9937L13.5087 24H10.9915C10.3564 24.0002 9.7447 23.7595 9.27903 23.3261C8.81336 22.8927 8.52811 22.2987 8.48048 21.6632L8.47419 21.4737H16.026ZM12.2501 1.20514e-09C14.5345 -3.78253e-05 16.7296 0.890392 18.3724 2.48346C20.0152 4.07653 20.9772 6.24769 21.0555 8.53895L21.0606 8.8421V13.5966L23.3538 18.1996C23.4539 18.4004 23.504 18.6226 23.4998 18.8471C23.4956 19.0716 23.4372 19.2918 23.3297 19.4887C23.2222 19.6857 23.0687 19.8535 22.8824 19.9779C22.6961 20.1023 22.4826 20.1795 22.26 20.2029L22.1153 20.2105H2.38491C2.16108 20.2106 1.94057 20.1562 1.74228 20.052C1.54398 19.9478 1.37382 19.7969 1.24637 19.6122C1.11892 19.4276 1.03798 19.2147 1.01048 18.9917C0.982991 18.7688 1.00977 18.5425 1.08851 18.3322L1.14641 18.1996L3.43965 13.5966V8.8421C3.43965 6.49703 4.36789 4.24801 6.02017 2.58979C7.67245 0.931576 9.91342 1.20514e-09 12.2501 1.20514e-09Z"
      />
    </Icon>
  );
};

export default IconNotifications;
