import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { NotFound } from "../../../../components";
import AddContact from "../../../../components/AddContact";
import DashBoard from "../../Dashboard";
import Profile from "../Chat/Profile";
import { AsideContent, ChatContainer } from "../Chat/style";
import { ProfileGroup } from "../Group";
import Image from "../../../../assets/images/padronagem.svg";
import { EmptyMessage } from "../../../../components/EmptyMessage";
const routes = [
  { path: "/dashboard", component: DashBoard },
  { path: "/personal", component: Profile },
  { path: "/group", component: ProfileGroup },
  { path: "/addContact", component: AddContact },
];

const NoMessage = (props) => {
  const { location } = props;
  const { pathname } = useLocation();
  const rightContent = pathname.split("/")[4] !== "dashboard";
  const { path } = useRouteMatch();

  return (
    <ChatContainer id="no_content_message" rightContent={rightContent}>
      <div id="noContent">
        <EmptyMessage type="message" />
      </div>
      <AsideContent rightContent={rightContent}>
        <Switch location={location}>
          {routes.map(({ component: Component, ...route }, key) => (
            <Route {...route} path={path + route.path} key={key}>
              <Component gridRow column1={true} />
            </Route>
          ))}
          <Redirect to={path + "/dashboard"} />
        </Switch>
      </AsideContent>
    </ChatContainer>
  );
};

export default NoMessage;
