import { AxiosError, AxiosResponse } from "axios";
import { RefObject, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { InputControlled, TextArea } from "../../../../components/Input";
import Modal, { ModalRef } from "../../../../components/Modal";
import ModalContainer from "../../../../components/Modal/ModalContainer";
import { ContainerModal } from "./style";

import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { useToasts } from "react-toast-notifications";
import { ReportTemplateService } from "../../../../services/requests/report-template";
import { ReportEnum, ReportStylesInterface } from "../../../../types/project";
import { Queries } from "../../../../types/queries";
import { ToastModel } from "../../../../util/error";
import { EnumTypeRepository } from "../../../../types/redux";
import { ReportContext } from "../../../../contexts/ReportContext";

interface ColumnsProps {
  modal: RefObject<ModalRef>;
  urlPdf: string | undefined;
}

// Função para converter base64 em Blob
function base64ToBlob(base64Data, contentType = "application/octet-stream") {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays: any[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

const ModalNewTemplate = ({ modal, urlPdf }: ColumnsProps) => {
  const { optionReportSelected, stylesPDF } = useContext(ReportContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [isRequired, setIsRequired] = useState(false);
  const handleDescription = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value);
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const mutation = useMutation(
    (dataForm: FormData) => ReportTemplateService.post(dataForm),
    {
      onSuccess: ({ data, status, ...rest }: AxiosResponse) => {
        console.log(data, status, { ...rest });
        if (status == 201) {
          queryClient.setQueryData(Queries.REPORT_TEMPLATES, (prevData) => {
            if (Array.isArray(prevData)) {
              return [...prevData, data];
            }
            return [data];
          });
          setIsRequired(false);
          setName("");
          setDescription("");
          modal.current?.close();
          ToastModel(
            addToast,
            "Template criado!",
            "Template criado com sucesso",
            "success"
          );
        } else {
          ToastModel(addToast, "Erro de conexão!", "Error", "error");
        }
      },
      onError: (err: AxiosError) => {
        ToastModel(addToast, "Erro de conexão!", err.message, "error");
      },
    }
  );

  const handleGeneratePreview = async () => {
    if (urlPdf) {
      const pdfData = atob(urlPdf.split(",")[1]);
      // pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
      const pageNumber = 1; // Página a ser convertida em imagem
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.0 });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      // Converter o canvas em uma imagem de dados
      const imageDataURL = canvas.toDataURL("image/jpeg"); // ou 'image/png'

      // Redimensionar a imagem
      const newWidth = viewport.width / 2; // Nova largura desejada
      const newHeight = viewport.height / 2;

      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.width = newWidth;
      resizedCanvas.height = newHeight / 2;

      const resizedContext: any = resizedCanvas.getContext("2d");
      resizedContext.drawImage(canvas, 0, 0, newWidth, newHeight);

      const resizedImageDataURL = resizedCanvas.toDataURL("image/jpeg"); // ou 'image/png'

      setPreviewImage(resizedImageDataURL);
    }
  };

  async function saveChange() {
    if (name.length >= 1 && optionReportSelected) {
      if (!mutation.isLoading) {
        const pdfBase64 = urlPdf?.split(",")[1];
        await handleGeneratePreview(); // Chama a função para gerar a imagem de visualização
        const jsonData = stylesPDF;
        const jsonFile = new File([JSON.stringify(jsonData)], "data.json", {
          type: "application/json",
        });
        const formData = new FormData();
        formData.append("_json", jsonFile);
        formData.append("type", optionReportSelected.toString());
        // Converter base64 para Blob
        const blob = base64ToBlob(pdfBase64, "application/pdf");
        const pdfFile = new File([blob], "documento.pdf", {
          type: "application/pdf",
        });

        // if (previewImage) {
        //   console.log(previewImage);
        //   console.log("oiiii");
        //   const imageData = previewImage.split(",")[1];
        //   const imageBlob = new Blob([atob(imageData)], { type: "image/jpeg" }); // ou 'image/png'
        //   const imageFile = new File([imageBlob], "preview.png", {
        //     type: "image/png",
        //   }); // ou 'image/png'
        //   console.log(imageFile);
        //   formData.append("image", imageFile); // Adiciona a imagem de visualização
        // } else {
        //   return;
        // }
        formData.append("name", name);
        formData.append("description", description);
        formData.append("pdf", pdfFile);
        mutation.mutate(formData);
      }
    } else {
      setIsRequired(true);
    }
  }

  const onBack = () => modal.current?.close();

  return (
    <Modal mobile={true} ref={modal}>
      <ModalContainer
        loadRequisition={mutation.isLoading}
        padding={false}
        onButtonRight={saveChange}
        buttonConfirmation={true}
        title="Salvar template"
        onClick={onBack}
        buttonBack={true}
      >
        <ContainerModal>
          <InputControlled
            isRequired={isRequired}
            value={name}
            onChange={handleName}
            label={"Nome"}
          />
          <TextArea
            r
            rows={4}
            value={description}
            onChange={handleDescription}
            label={"Descrição"}
          />
        </ContainerModal>
      </ModalContainer>
    </Modal>
  );
};

export default ModalNewTemplate;
