import styled from "styled-components";
import { ContainerSearch } from "../../../../../components/Input/style";

export const Container = styled.div`
  padding-top: 0.4rem;
  padding-bottom: 1rem;

  #sub {
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: 0.7s ease;
    
    &:hover {
      #plus {
        color: ${(props) => props.theme.purple.darker};
      }
    }
  }
`;

export const Input = styled(ContainerSearch)`
  display: grid;
  grid-template-columns: auto 30px 30px;
  padding: 5px 1rem;
  border: 0px;
  color: ${(props) => props.theme.texts.purpleDark};
  -webkit-text-fill-color: ${(props) => props.theme.purple.purpleDark};
  border-radius: 0;
  border-bottom: 1px solid ${(props) => props.theme.bordes.purpleLightIIShadow};
  background: ${(props) => props.theme.backgrounds.purpleLightI};

  .sub {
    transition: 0.7s ease;
    &:hover {
      #icon {
        color: ${(props) => props.theme.texts.purpleDarkTitle};
      }
    }
  }

  &:focus-within {
    border: 0px;
    #icon {
      color: ${(props) => props.theme.default.darkI};
    }
  }

  #icon {
    font-size: 26px;
    color: ${(props) => props.theme.default.darkI};
  }
`;
