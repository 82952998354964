import { AxiosError, AxiosResponse } from "axios";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { FiTrash } from "../../../../../../assets/icons";
import { Input, Modal, Toggle } from "../../../../../../components";
import ModalContainer from "../../../../../../components/Modal/ModalContainer";
import { getHasCategoryInEvent, patchUpdateCategorys, postCreateCategorys } from "../../../../../../services/requests/event-category";
import { addVisibleCategory } from "../../../../../../store/actions/scheduleAction";
import { TCategory } from "../../../../../../types/project";
import { Queries } from "../../../../../../types/queries";
import { ToastModel } from "../../../../../../util/error";
import { isFilled } from "../../../../../../util/verification";
import DeleteCategory from "../Delete";
import { FlexToogle } from "./style";

type SetCategorysType = React.Dispatch<React.SetStateAction<TCategory[]>>

const ToogleEdit = (props: { active: boolean }) => {
    const [checked, setChecked] = useState<boolean>(props.active)
    return (
        <FlexToogle>
            <h3>Ativo:</h3>
            <Toggle name="toogle" value={checked} onChange={() => { setChecked(!checked) }} />
        </FlexToogle>
    )
}
const AddCategory = (props: { setMenu, category?: TCategory, setCategorys: SetCategorysType, categorys: TCategory[] | null }) => {
    const { setMenu, category, setCategorys, categorys } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [permited, setPermited] = useState<boolean>(category ? true : false)
    const { addToast } = useToasts();
    const modalRef = useRef<any>();
    const modalRefAnyEvent = useRef<any>();
    const dispatch = useDispatch();
    const formRef = useRef<HTMLFormElement>(null);

    const checkInputValue = (e) => {
        const inputValue = e.target.value;
        if (isFilled(inputValue)) {
            setPermited(true)
        } else {
            setPermited(false)
        }
    };
    const goBack = () => {
        setMenu("1")
    }
    const queryClient = useQueryClient();

    const mutateCreate = useMutation((name: string) => postCreateCategorys({ name }), {
        onSuccess: ({ data, status }: AxiosResponse<TCategory>) => {
            if (status == 201) {
                queryClient.setQueryData(Queries.CATEGORIES_EVENT, (prevData) => {
                    if (Array.isArray(prevData)) {
                        return [...prevData, data];
                    }
                    return [data];
                });
                dispatch(addVisibleCategory(data.id));
                ToastModel(addToast, "Categoria criado com sucesso", "", "success");
                goBack();
            } else if (status == 409) {
                ToastModel(addToast, "Categoria de evento já existe", "Por favor digite outro nome!", "error")
            } else {
                ToastModel(addToast, "Erro ao criar categoria", "Por favor tente novamente mais tarde", "error")
            }
        },
        onError: (error: AxiosError) => {
            ToastModel(addToast, "Erro ao criar categoria", "Por favor tente novamente mais tarde", "error")
        }
    });

    const mutateEdit = useMutation((data: { id: number, name: string, active: boolean }) => patchUpdateCategorys(data), {
        onSuccess: ({ data, status }: AxiosResponse<TCategory>) => {

            if (status == 200) {
                queryClient.setQueryData(Queries.CATEGORIES_EVENT, (prevData) => {
                    if (Array.isArray(prevData)) {
                        return prevData.map((item) => {
                            if (item.id === data.id) {
                                return data;
                            }
                            return item;
                        });
                    }
                    return [data];
                });
                ToastModel(addToast, "Categoria editada", "", "success")
                goBack();
            } else if (status == 409) {
                ToastModel(addToast, "Categoria de evento já existe", "Por favor digite outro nome!", "error")
            } else if (status == 400) {
                ToastModel(addToast, "Categoria não atualizada", "Altere alguma informação da categoria", "warning")
            } else {
                ToastModel(addToast, "Erro ao editar categoria", "Por favor tente novamente mais tarde", "error")
            }
        },
        onError: (error: AxiosError) => {
            ToastModel(addToast, "Erro ao criar categoria", "Por favor tente novamente mais tarde", "error")
        }
    });

    const editCategory = (id: number, name: string, active: boolean) => {
        patchUpdateCategorys({ id, name, active }).then((res) => {
            if (res.status == 200) {
                setCategorys(value => {
                    if (value) {
                        const indexCategory = value.findIndex((cat) => cat.id == id);
                        value[indexCategory] = res.data
                        return [...value]
                    } else {
                        return value
                    }
                }
                )
                ToastModel(addToast, "Categoria editada", "", "success")
                goBack()
            } else if (res.status == 409) {
                ToastModel(addToast, "Categoria de evento já existe", "Por favor digite outro nome!", "error")
            } else if (res.status == 400) {
                ToastModel(addToast, "Categoria não atualizada", "Altere alguma informação da categoria", "warning")
            } else {
                ToastModel(addToast, "Erro ao criar categoria", "Por favor tente novamente mais tarde", "error")
            }
        })
    }
    const action = () => {
        const name = formRef.current?.name_category.value;

        if (permited && name) {

            if (category) {
                const active = formRef.current?.toogle.checked;
                console.log(name)
                console.log(active)
                mutateEdit.mutate({ id: category.id, name, active });
            } else {
                mutateCreate.mutate(name);
            }

        }
        return false;
    }

    const actionDelete = (category: TCategory) => {
        getHasCategoryInEvent(category.id).then((res) => {
            if (res.status == 200) {
                if (res.data.existCategoryAnyEvent) {
                    modalRefAnyEvent.current.open()
                } else {
                    modalRef.current.open()
                }
            }
        })
    }
    return (
        <ModalContainer ButtonExtra={category ? <FiTrash size={24} className="icon-trash" onClick={() => actionDelete(category)} /> : undefined} loadRequisition={mutateCreate.isLoading || mutateEdit.isLoading} title={category ? "Categoria" : "Cadastrar Categoria"} onClick={() => goBack()} buttonBack={true} buttonConfirmation={true} onButtonRight={() => action()}>
            <form ref={formRef} onSubmit={action}>
                <div className="pd-8" >
                    <Input placeholder="Digite aqui " label="Nome"
                        name="name_category"
                        maxLength={50} defaultValue={category?.name}
                        ref={inputRef}
                        onKeyUp={checkInputValue}
                    />

                    {category && <ToogleEdit active={category.active} />}
                </div>
            </form>

            {
                (category && categorys) &&
                <>
                    <Modal ref={modalRefAnyEvent}>
                        <DeleteCategory hasAnyEvent={true}
                            category={category} setCategorys={setCategorys} goBack={goBack}
                            categorys={categorys}
                        />
                    </Modal>
                    <Modal ref={modalRef}>
                        <DeleteCategory hasAnyEvent={false}
                            category={category} setCategorys={setCategorys} goBack={goBack}
                            categorys={categorys}
                        />
                    </Modal>
                </>
            }

        </ModalContainer>
    )
}

export default AddCategory;