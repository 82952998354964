import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 1rem 1rem 0 1rem;
  gap: 1rem;

  box-sizing: border-box;
  align-items: center;
  width: 100%;
  .title {
    margin-left: 1rem;
  }

  .is_visible {
    display: block;
  }

  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }

  .item_selected {
    display: flex;
    background-color: #ccc;
    margin-right: 8px;
    height: 24px;
    border-radius: 14px;
    padding: 0px 8px;
    align-items: center;
  }
  .name_item {
    margin: 0 8px 0 0;
    white-space: nowrap;
  }

  button {
    border-left: solid 1px #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: 14px;
    width: 14px;
  }
`;


export const Exit = styled.div`
  text-align: center;

  #icon {
    color: ${(props) => props.theme.texts.darkerGreyI};
  }

  #message {
    margin: 0px 0px 16px 0px;
    color: ${(props) => props.theme.texts.darkerGreyI};
    font-weight: normal;
  }

  .action {
    padding: 18px 0px 16px 0px;

    button {
      padding: 10px 16px;
      border-radius: 6px;
      border: solid 1px;
      transition: all 200ms ease-in 0s;
    }
  }
`;
