import React from "react";
import { SVGComponent } from "../../types/project";
import Icon from "./Icon";

const IconHomeFill = ({
  color = "#700540",
  size = 24,
  ...rest
}: SVGComponent) => {
  return (
    <Icon
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.6655 0.504192C11.008 0.180408 11.4614 0 11.9327 0C12.404 0 12.8575 0.180408 13.1999 0.504192L22.1349 8.94852C22.4085 9.2074 22.6263 9.51942 22.7751 9.86546C22.9238 10.2115 23.0004 10.5843 23 10.9609V21.235C22.9995 21.9685 22.7078 22.6718 22.189 23.1903C21.6701 23.7088 20.9667 24 20.2332 24H17.4664C16.7326 24 16.0288 23.7085 15.5099 23.1896C14.9911 22.6707 14.6995 21.967 14.6995 21.2332V16.6218C14.6995 16.3772 14.6024 16.1426 14.4294 15.9697C14.2565 15.7967 14.0219 15.6996 13.7773 15.6996H10.0882C9.84358 15.6996 9.609 15.7967 9.43604 15.9697C9.26308 16.1426 9.16591 16.3772 9.16591 16.6218V21.2332C9.16591 21.967 8.87441 22.6707 8.35553 23.1896C7.83665 23.7085 7.1329 24 6.3991 24H3.63228C2.89848 24 2.19473 23.7085 1.67585 23.1896C1.15697 22.6707 0.865463 21.967 0.865463 21.2332V10.9591C0.865578 10.5826 0.942526 10.2101 1.09159 9.86439C1.24066 9.51868 1.45871 9.20703 1.7324 8.94852L10.6655 0.500503V0.504192Z"
        fill="inherit"
      />
    </Icon>
  );
};

export default IconHomeFill;
