import { useRef, useState } from "react";
import { VscBroadcast } from "react-icons/vsc";
import { connect } from "react-redux";
import { FaFolderPlus, MdGroupAdd, MdMoreVert } from '../../../../assets/icons';
import { Dropdown, Modal } from "../../../../components";
import { Folder } from "../Folder/pages";
import { AddGroup } from "../Group";
import { Header, MiddleContainer } from './style';

const ListConversation = () => {
    const [newFolder, setNewFolder] = useState<Boolean>(false);
    const modalAddGroup = useRef<any>();
    const modalAddChannel = useRef<any>();


    return (
        <MiddleContainer>
            <Header>
                <div className="top">
                    <h3 className="title">Conversas</h3>
                    <Dropdown defaultClass="bottom-left">
                        <MdMoreVert className="icon-header" id="menu" />
                        <div>
                            <li onClick={() => setNewFolder(true)}> <FaFolderPlus className="icon" /> Nova pasta</li>
                            <li onClick={() => modalAddGroup.current.open()}> <MdGroupAdd className="icon" /> Novo grupo</li>
                            <li onClick={() => modalAddChannel.current.open()}> <VscBroadcast className="icon" />Novo canal</li>
                        </div>
                    </Dropdown>
                </div>
            </Header>
            <div className="container">
                <Folder newFolder={newFolder} setNewFolder={setNewFolder} />
            </div>
            <Modal mobile={true} ref={modalAddGroup}>
                <AddGroup modalAdd={modalAddGroup} groupType={false} />
            </Modal>
            <Modal mobile={true} ref={modalAddChannel}>
                <AddGroup modalAdd={modalAddChannel} groupType={true} />
            </Modal>
        </MiddleContainer>
    );
}

function mapStateToProps(state) {
    return {
        abare: state.abare.abare,
        personal: state.chat.personal,
    }
}

export default connect(mapStateToProps)(ListConversation);