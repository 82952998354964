import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemFile } from "../../../../../components";
import { setTypesFilterRepository } from "../../../../../store/actions/storageClusterAction";
import { TypeMessage } from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { getTypeNameMedia } from "../../../../../util/verification";
import { ContainerType } from "./style";

const CardType = ({ type, selectTypes, handleSelect }) => {
    const typeValue = TypeMessage[type];
    const selectType = selectTypes.some((typeOne) => typeOne == typeValue);
    const classDefault = `card-type card card-hover ${selectType ? "selected" : ""}`;

    return <div onClick={(e) => { e.stopPropagation(), handleSelect(typeValue) }} className={classDefault}>
        <ItemFile className='icon' icons={true} type={typeValue} />
        <p>{getTypeNameMedia(typeValue)}</p>

    </div>
}
const FilterTypes = () => {
    const typesRedux = useSelector((store: TypeConstRedux) => store.repository.filters.types);
    const dispatch = useDispatch();
    const types: string[] = [];
    for (const chave in TypeMessage) {
        if (chave != 'UNDEFINED')
            types.push(chave);
    }
    const [selectTypes, setSelectTypes] = useState<TypeMessage[]>(typesRedux);

    const handleSelectedTypes = (type: TypeMessage) => {
        if (!selectTypes.some((typeOne) => typeOne == type)) {
            selectTypes.push(type);
        } else {
            selectTypes.filter((typeOne) => typeOne != type);
        }
        setSelectTypes([...selectTypes]);
    };

    const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(setTypesFilterRepository(selectTypes));
    }

    const onClear = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        setSelectTypes([]);
    }
    return <ContainerType>
        <div className="container-types">
            {types.map((type) => <CardType handleSelect={handleSelectedTypes} selectTypes={selectTypes} key={type} type={type} />)}
        </div>
        <div className="container-buttons">
            <button className="button-clear" onClick={onClear}> Limpar</button>
            <button className="button-confirm" onClick={onSave}>Aplicar</button>
        </div>
    </ContainerType>

};

export default FilterTypes;