import { AxiosResponse } from "axios";
import { api } from "../api";
import { getToken } from "../auth";

export interface ReportTemplatePayload {
  name: string;
  _public?: boolean;
  description: string;
  _json: File;
  pdf?: File;
}

export interface ReportTemplateData {
  name: string;
  fileName: string;
  fileURL: string;
  fileNameUrlModel: string;
  fileUrlModel: string;
  createdAt: Date;
  description: string;
  public: boolean;
  User_id: string;
  type: string;
}

export interface ReportTemplateUpdate {
  fileNameId: string;
  name: string;
}

const post = (data: FormData): Promise<AxiosResponse> => {
  return api({
    url: `/report-template`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

const get = (): Promise<ReportTemplateData[]> => {
  return api({
    url: `/report-template`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error.response;
      } else if (error.request) {
        throw error.request;
      } else {
        throw error.message;
      }
    });
};

const deleteToId = (id: string): Promise<AxiosResponse> => {
  return api({
    url: `/report-template`,
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: {
      reportTemplateId:[
        id
      ]
    }
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

const updateName = (data: ReportTemplateUpdate): Promise<AxiosResponse> => {
  return api({
    url: `/report-template/`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        return error.message;
      }
    });
};

export const ReportTemplateService = {
  post,
  get,
  deleteToId,
  updateName,
};
