import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { AiFillCheckCircle, IoIosArrowBack } from '../../../../../../assets/icons';
import { Load, NotFound, Search } from '../../../../../../components';
import { ButtonArrow } from '../../../../../../components/Button/style';
import { Checked } from '../../../../../../components/Checked';
import { getParticipantsWithRole } from '../../../../../../services/requests/user-group';
import { patchUpdateLeaders } from '../../../../../../services/requests/user-group-role';
import { TProfileGroup, TUser } from '../../../../../../types/project';
import { isEqualsArray } from '../../../../../../util/verification';
import { Container, Header, MainLoad, Menu } from './style';

type TProps = {
    abare: TUser,
    modal: any,
    groupInfo: TProfileGroup,
    updateGroupForAllUsers: any,
}

const Leaders = (props: TProps) => {
    const { abare, groupInfo, modal, updateGroupForAllUsers } = props;
    const [hidden, setHidden] = useState(false);
    const { addToast } = useToasts();
    const [users, setUsers] = useState<any[]>([]);
    const [staticUsers, setStaticUsers] = useState([]);
    const [load, setLoad] = useState(true);

    const checked = (usersArray) => {
        usersArray.forEach(user => {
            user.checked = user.userGroupRole.MapGroupLeader ? true : false;
            user.hidden = false;
        });

        setStaticUsers(usersArray);
        setUsers(usersArray);
    }
    const updateLeader = () => {
        if (hidden) {
            let usersAdd: Array<any> = [];
            let usersRemove: Array<any> = [];

            users.forEach(user => {
                if (user.userGroupRole.MapGroupLeader) {
                    !user.checked && usersRemove.push({ id: user.id, roleId: user.groupRole.id, roleBegin: user.userGroupRole.roleBegin })
                } else {
                    user.checked && usersAdd.push({ id: user.id, roleId: user.groupRole.id, roleBegin: user.userGroupRole.roleBegin })
                }
            });

            patchUpdateLeaders(abare.id, groupInfo.id, usersAdd, usersRemove).then((res) => {
                if (res.status == 200) {
                    // updateGroupInfo(usersAdd, usersRemove)
                    addToast({
                        title: "Liderança atualizada!"
                    }, 
                        { appearance: 'success' });
                    modal.current.close()
                }
            })
        }
    }

    // const updateGroupInfo = (usersAdd, usersRemove) => {
    //     participants.forEach(user => {
    //         if (usersRemove.includes(user.id)) {
    //             user.admin = false;
    //         } else if (usersAdd.includes(user.id)) {
    //             user.admin = true;
    //         }
    //         user.checked = false;
    //     });
    //     updateGroupForAllUsers(groupInfo)
    // }

    useEffect(() => {
        getParticipantsWithRole(abare.id, groupInfo.id).then((res) => {
            if (res.status == 200) {
                checked(res.data)
            }
            setLoad(false);
        })
    }, [])

    useEffect(() => {
        isEqualsArray(staticUsers, users) ? setHidden(false) : setHidden(true)
    }, [users])

    return (
        <Container>
            <Header>
                <IoIosArrowBack id="back" onClick={() => modal.current.close()} />
                <h5>Liderança - Associar</h5>
            </Header>
            <Menu>
                <h4>Membros</h4>
                <Search itens={users || []} onSearch={(result) => {
                    setUsers(result)
                }} />
                <ButtonArrow onClick={() => updateLeader()} >
                    <AiFillCheckCircle id={!hidden ? "icon" : "icon-purple"} />
                </ButtonArrow>
            </Menu>
            <div className='scroll'>
                {
                    load ?
                        <MainLoad>
                            <Load width="30px" />
                        </MainLoad>
                        :
                        users.length ?
                            <Checked leaders={users} setUsers={setUsers} />
                            :
                            <NotFound description="Não há membros associados a funções." />
                }
            </div>
        </Container>
    );
}

export default Leaders;