import { useEffect, useState } from "react";
import { ContentSchedule } from "../Schedule/style";
import { ContainerSwitch } from "../style";
import ListOptions from "./ListOptions";
import DriveRepository from "./components/ListStorage";


const Repository = () => {
    const [widthWindow, setWidthWindow] = useState<number>(window.innerWidth);
    const handleWidth = () => setWidthWindow(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", handleWidth);
        return () => {
            window.removeEventListener("resize", handleWidth);
        }
    });

    return (
        <ContainerSwitch>
            <ContentSchedule>
                {widthWindow >= 925 && <ListOptions />}
                <DriveRepository />
            </ContentSchedule >
        </ContainerSwitch>

    );
};

export default Repository;