import styled from "styled-components";

interface ContainerCardProps {
  $marginBottom?: string;
  $padding?: string;
}

export const ContainerCard = styled.div<ContainerCardProps>`
  display: grid;
  min-width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backgrounds.white};
  position: relative;
  border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom : "24px"};
  padding: ${({ $padding }) => ($padding ? $padding : "8px 16px 16px 16px")};

  .dropdown {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .button-dropdown {
    background-color: gray;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    color: ${(props) => props.theme.begin.color.button.secondary} !important;
  }
  .title {
    position: absolute;
    left: 8px;
    top: 5px;
    font-size: 16px;
    font-family: "inter-semi-bold", sans-serif;
    color: ${(props) => props.theme.default.darkerI};
  }

  .widthLista {
    width: 140px;
  }

  .confirmBtn {
    display: flex;
    justify-content: end;
    margin-top: 16px;
  }

  .card-name {
    font-family: "inter-semi-bold", sans-serif;
    font-size: 1.1rem;
    margin-bottom: 8px;
  }

  .card-image {
    border: 1px solid ${(props) => props.theme.backgrounds.lightGrey};
    border-radius: 14px;
    display: flex;
    height: 220px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 8px;
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }
`;

export const BtnCardDash = styled.button`
  font-family: "inter", sans-serif;
  font-size: 14px;
  color: ${(props) => props.theme.texts.purpleDarkTitle};
`;

export const ACardDash = styled.a`
  font-family: "inter", sans-serif;
  font-size: 14px;
  color: ${(props) => props.theme.texts.purpleDark};
`;
