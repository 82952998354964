import { AiFillFolder } from "react-icons/ai";
import {
    AiFillAudio,
    AiFillFileExcel, AiFillFilePdf, AiFillFileWord,
    BsCameraVideoFill,
    BsFileEarmarkZipFill, BsFillFileEarmarkTextFill, FaFilePowerpoint,
    IoImageSharp,
    IoMdMusicalNote,
    MdInsertDriveFile
} from "../../assets/icons";
import { TypeMessage } from "../../types/project";
import { transformUrlImage } from "../../util/verification";
import { BsFillFileEarmarkFill, BsFillFileEarmarkMusicFill, BsFillFileEarmarkPdfFill, BsFillFileExcelFill, BsFillFileImageFill, BsFillFileMusicFill, BsFillFilePdfFill, BsFillFilePlayFill, BsFillFilePptFill, BsFillFileTextFill, BsFillFileWordFill, BsFillFileXFill, BsFillFileZipFill, BsFillFolderFill } from "react-icons/bs";

const ItemFile = (props) => {
    const { type, icons, url, ...rest } = props
    const { IMAGE, VIDEO, AUDIO, PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, ZIP, VOICE, FOLDER } = TypeMessage;

    const GetMedia = () => {
        if (rest.src) {
            rest.src = transformUrlImage(rest.src);
        }
        switch (type) {
            case IMAGE:
                return (
                    icons ?
                        <BsFillFileImageFill {...rest} />
                        :
                        <img {...rest} />
                )
            case VIDEO:
                return (
                    icons ?
                        <BsFillFilePlayFill {...rest} />
                        :
                        <video {...rest} />
                )
            case AUDIO:
                return <BsFillFileMusicFill {...rest} />
            case PDF:
                return <BsFillFilePdfFill {...rest} />
            case DOC:
                return <BsFillFileWordFill />
            case DOCX:
                return <BsFillFileTextFill {...rest} />
            case PPT:
                return <BsFillFilePptFill {...rest} />
            case PPTX:
                return <BsFillFilePptFill {...rest} />
            case XLS:
                return <BsFillFileExcelFill {...rest} />
            case XLSX:
                return <BsFillFileExcelFill {...rest} />
            case ZIP:
                return <BsFillFileZipFill {...rest} />
            case VOICE:
                return <BsFillFileMusicFill {...rest} />
            case FOLDER:
                return <BsFillFolderFill {...rest} />
            default:
                return <BsFillFileEarmarkFill {...rest} />
        }
    }

    return (
        <GetMedia />
    );
}

export default ItemFile