import styled from "styled-components";

export const DropList = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.backgrounds.folderTalk};
  list-style-type: none;
  z-index: 9;

  &:hover {
    display: block;
  }
 
`;

export const Loading = styled.div`
  #load {
    display: flex;
    flex-direction: row;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;
  }
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;
  min-width: 50%;

  #title {
    margin: 0;
    padding-inline: 0.7rem;
    padding-top: 1rem;
    font-size: 15px;
    color: ${({ theme }) => theme.highlights.default};
  }

  &:focus-within {
    ${DropList} {
      display: block;
    }
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 0.7rem 1.1rem;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  color: ${({ theme }) => theme.texts.darkerGreyI};
  font-size: 16px;

  .img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  #col {
    overflow: hidden;
    padding-inline: 0.2rem;
    #name {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.texts.darkerGreyI};
      margin: 0;
      padding: 0;
    }
    #nickname {
      font-size: 12px;
      font-weight: normal;
      color: ${(props) => props.theme.default.darkI};
      margin: 0;
      padding: 0;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.backgrounds.lightGrey};
  }
`;
