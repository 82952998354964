import { useEffect, useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, ItemFile, Modal } from "../../../../../components";
import Img from "../../../../../components/Img";
import { ModalRef } from "../../../../../components/Modal";
import { ShimmerCard, ShimmerCircle } from "../../../../../components/Shimmers";
import { setRepositoryFileSelected } from "../../../../../store/actions/storageClusterAction";
import {
  FileRepository,
  FolderRepository,
  TypeMessage,
  TypeStatusSharing,
} from "../../../../../types/project";
import { TypeConstRedux } from "../../../../../types/redux";
import { getTypeNameMedia } from "../../../../../util/verification";
import { Back } from "../../../Conversations/Chat/Profile/style";
import { UseDetailsItem } from "../../hooks/useDetailsItem";
import ModalShare from "../modal/modalShare";
import { AsideContent, Container, ContainerDrop } from "./style";
import IconPlus from "../../../../../assets/icons/IconPlus";
import IconArrowDown from "../../../../../assets/icons/IconArrowDown";
import { Divider, Menu, MenuItem } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FiTrash } from "react-icons/fi";
import { BsEye } from "react-icons/bs";
import { UseRemoveSharing } from "../../hooks/useRemoveSharing";

const UserSharing = ({ user, item, id, folder }) => {
  const level = user.level == TypeStatusSharing.EDITOR ? "Editor" : "Leitor";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { mutate, isLoading } = UseRemoveSharing(item, id, [user.id], folder);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (!isLoading) {
      mutate();
      handleClose();
    }
  };
  console.log(item);

  return (
    <div className="dropdown">
      <div className="line">
        <Img className="user_img" src={user.url} />
        <div className="user_informations">
          <p className="user_name">{user.name}</p>
          <p className="user_nickname">{user.name}</p>
        </div>
        <span className="info-edit" onClick={handleClick}>
          {level} <IconArrowDown className={!open ? "up" : "down"} size={20} />
        </span>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: "150px",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1,
              borderRadius: "6px",
            },
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{
            mx: 0.5,
            borderRadius: "6px",
            mb: 0.5,
            pr: 1,
          }}
          selected={user.level == TypeStatusSharing.READER}
          onClick={handleClose}
        >
          <ListItemIcon>
            <BsEye />
          </ListItemIcon>
          <ListItemText>Leitor</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{
            mx: 0.5,
            borderRadius: "6px",
            pr: 1,
          }}
          selected={user.level == TypeStatusSharing.EDITOR}
          onClick={handleClose}
        >
          <ListItemIcon>
            <MdModeEdit />
          </ListItemIcon>
          <ListItemText>Editor</ListItemText>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={handleDelete}
          sx={{
            mx: 0.5,
            borderRadius: "6px",
            color: "red",
            pr: 1,
          }}
        >
          <ListItemIcon
            sx={{
              color: "red",
            }}
          >
            <FiTrash />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

const UserSharingShimming = () => {
  return (
    <div className="line">
      <ShimmerCircle size="52px" />
      <div className="user_informations">
        <p className="user_name">
          <ShimmerCard height="20px" width="125px" />
        </p>
        <p className="user_nickname">
          <ShimmerCard height="20px" width="120px" />
        </p>
      </div>
    </div>
  );
};

const DetailsFile = () => {
  const { fileSelected, folderSelected, itemSelected } = useSelector(
    (state: TypeConstRedux) => state.repository
  );
  const id =
    itemSelected == "file" ? fileSelected?.fileName : folderSelected?.id;
  const shareRef = useRef<ModalRef>(null);
  const { items, isLoading, isSuccess } = UseDetailsItem(id, itemSelected);

  if (itemSelected === undefined) {
    return <></>;
  }

  const type =
    itemSelected == "file" ? fileSelected!!.fileType : TypeMessage.FOLDER;
  const item =
    itemSelected == "file"
      ? (fileSelected as FileRepository)
      : (folderSelected as FolderRepository);

  const dispatch = useDispatch();
  const onShare = () => shareRef.current?.open();

  return (
    <AsideContent rightContent={itemSelected !== undefined}>
      {itemSelected && (
        <div className="informations-chat">
          <Back>
            <h3>Informações</h3>
            <IoIosClose
              id="icon"
              onClick={() => dispatch(setRepositoryFileSelected(undefined))}
            />
          </Back>
          <Container>
            <div className="visual-file">
              <div className="media">
                <ItemFile
                  style={{
                    color:
                      folderSelected?.color && itemSelected == "folder"
                        ? folderSelected?.color
                        : "inherit",
                  }}
                  type={type}
                  src={itemSelected == "file" ? fileSelected?.fileURL : ""}
                />
              </div>
              <p className="name-file">{item.name}</p>
            </div>
            <hr />
            <div className="properties-file">
              <div className="title_line">
                <p className="title">Propriedades</p>
              </div>
              <div className="information_block">
                <p className="semi-title">Tipo</p>
                <p>{getTypeNameMedia(type)}</p>
              </div>
              <div className="information_block">
                <p className="semi-title">Criado em</p>
                <p>{new Date(item.createdAt).toLocaleString()}</p>
              </div>
              <div className="information_block">
                <p className="semi-title">Modificado</p>
                <p>{new Date(item.editedAt).toLocaleString()}</p>
              </div>
            </div>
            <hr />
            <div className="properties-file">
              <div className="title_line">
                <p className="title">Compartilhado com </p>
                <IconPlus className="icon" onClick={onShare} />
              </div>
              {isLoading ? (
                <>
                  <UserSharingShimming /> <UserSharingShimming />
                </>
              ) : isSuccess ? (
                items.length ? (
                  items.map((user) => (
                    <UserSharing
                      id={id}
                      user={user}
                      key={user.id}
                      item={itemSelected}
                      folder={folderSelected}
                    />
                  ))
                ) : (
                  <h3>Sem compartilhamento feitos</h3>
                )
              ) : (
                <h3>Erro ao buscar dados</h3>
              )}
            </div>
          </Container>
        </div>
      )}
      <Modal mobile={true} ref={shareRef}>
        <ModalShare
          type={itemSelected}
          modal={shareRef}
          file={fileSelected}
          folder={folderSelected}
        />
      </Modal>
    </AsideContent>
  );
};

export default DetailsFile;
