import { ContentImage, ContentText } from "pdfmake/interfaces";
import { useContext, useState } from "react";
import { FiGrid } from "react-icons/fi";
import { TbGridDots } from "react-icons/tb";
import { InputSelect } from "../../../../../components";
import ModalContainer from "../../../../../components/Modal/ModalContainer";
import {
  EnumPositionHorizontal,
  ReportStylesInterface,
} from "../../../../../types/project";
import { HeaderTable } from "../../../../../util/report/reportTypes";
import ImageOptions from "../FormContent/ImageOptions";
import TextOptions from "../FormContent/TextOptions";
import { ItemModal } from "../style";
import { ReportContext } from "../../../../../contexts/ReportContext";

interface ColumnsProps {
  modal: any;
}

const AddHeaderModal = ({ modal }: ColumnsProps) => {
  const { stylesPDF, handleStylesPDF } = useContext(ReportContext);
  const typeValues = [
    { value: "text", label: "Texto" },
    { value: "image", label: "Imagem" },
  ];
  const positionValues = [
    { value: EnumPositionHorizontal.CENTER, label: "Centro" },
    { value: EnumPositionHorizontal.LEFT, label: "Esquerda" },
    { value: EnumPositionHorizontal.RIGHT, label: "Direita" },
  ];
  const fontValues = [{ value: "roboto", label: "Roboto" }];

  const [typeSelect, setTypeSelect] = useState(typeValues[0].value);
  const [positionSelect, setPositionSelect] = useState(positionValues[0].value);

  const [valueText, setValueText] = useState<ContentText>({
    text: "",
    alignment: "center",
    fontSize: 12,
    color: "#000",
    bold: false,
    italics: false,
    margin: [4, 4, 4, 4],
  });
  const [valueImage, setValueImage] = useState<ContentImage>({
    image: "",
    alignment: "center",
    fit: [50, 50],
    fontSize: 12,
    color: "#000",
    bold: false,
    italics: false,
    margin: [4, 4, 4, 4],
  });

  const alignmentValues = [
    { value: "left", label: "Esquerda" },
    { value: "right", label: "Direita" },
    { value: "center", label: "Centralizada" },
    { value: "justify", label: "Justificada" },
  ];
  function saveChange() {
    // stylesPDF.headerContent = [[{ text: "oiii" }], [{ text: "oiii" }], [{ text: "oiii" }]];
    if (typeSelect === "text") {
      console.log(valueText);
      stylesPDF.headerContent[positionSelect] = [
        ...stylesPDF.headerContent[positionSelect],
        { ...valueText, position: positionSelect },
      ];
    } else {
      console.log(valueImage);
      stylesPDF.headerContent[positionSelect] = [
        ...stylesPDF.headerContent[positionSelect],
        { ...valueImage, position: positionSelect },
      ];
    }
    handleStylesPDF({ ...stylesPDF });
    modal.current.close();
  }
  return (
    <ModalContainer
      padding={false}
      onButtonRight={saveChange}
      buttonConfirmation={true}
      title="Adicionar item no cabeçalho"
      onClick={() => modal.current.close()}
      buttonBack={true}
    >
      <ItemModal $noPadding={true} $borderBottom={true}>
        <div
          className="flex padding border"
          style={{ minWidth: "230px", flex: 1 }}
        >
          <div className="icon margin-right">
            <TbGridDots title="Tipo do item" size="20px" />
          </div>
          <InputSelect
            $flex={true}
            value={typeValues.find((option) => option.value === typeSelect)}
            onChange={(e) => {
              setTypeSelect(e.value);
            }}
            placeholder="Selecione a coluna que deseja modificar"
            options={typeValues}
          />
          <div className="icon margin-right margin_left">
            <FiGrid title="Alinhamento" size="20px" />
          </div>
          <InputSelect
            $flex={true}
            value={positionValues.find(
              (option) => option.value === positionSelect
            )}
            onChange={(e) => {
              setPositionSelect(e.value);
            }}
            placeholder="Selecione a posição do elemento"
            options={positionValues}
          />
        </div>
        {typeSelect == "text" ? (
          <TextOptions valueText={valueText} setValueText={setValueText} />
        ) : (
          <ImageOptions valueImage={valueImage} setValueImage={setValueImage} />
        )}
      </ItemModal>
    </ModalContainer>
  );
};

export default AddHeaderModal;
