import { } from 'react';
import { FiDownload, FiUpload, IoIosClose, MdOutlineFileDownloadOff } from "../../../assets/icons";
import { Logo } from '../../../assets/images';
import { TypeMessage, TypeStatusRequest } from '../../../types/project';
import { transformUrlImage } from '../../../util/verification';
import { Load } from '../../index';
import { LoadUpload } from '../style';
import { Image } from './style';

const ImgVideo = ({ message, setMessage, fetchMessage }) => {
    const { DOWNLOADING, ERRODOWN, ERROUP, UPLOADING, FILETOOLARGE, LIMITMIDIA } = TypeStatusRequest

    const FileView = (props) => {
        const { controls, ...rest } = props
        if (rest.src) {
            rest.src = transformUrlImage(rest.src);
        }
        return (
            message.fileType == TypeMessage.IMAGE ?
                <img {...rest} />
                :
                <video {...rest} controls={controls} />
        )

    }

    const LayoutRequest = ({ children, ...rest }) => (
        <div className="uploading">
            <LoadUpload {...rest}>
                <div className="start-icon">
                    {children}
                </div>
            </LoadUpload>
        </div>
    );

    const LayoutLoad = ({ children }) => (
        <>
            {children}
            <div className="uploading">
                <LoadUpload /* onClick={() => message.source.cancel({ status: CANCELED })} */>
                    <Load width="25px" size="2px" />
                    <div className="cancel-icon">
                        <IoIosClose className="icon" />
                    </div>
                </LoadUpload>
            </div>
        </>
    );

    const FilePreview = () => (
        <img className="blur" src={transformUrlImage(message.fileURLPreview)} onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src = Logo;
        }} />
    )

    const FileStatus = () => {
        switch (message.statusRequest) {
            case DOWNLOADING:
                return (
                    <LayoutLoad>
                        <FilePreview />
                    </LayoutLoad>
                );
            case ERRODOWN:
                return (
                    <>
                        <FilePreview />
                        <LayoutRequest onClick={() => fetchMessage(message)}>
                            <MdOutlineFileDownloadOff />
                        </LayoutRequest>
                    </>
                );
            case UPLOADING:
                return (
                    <LayoutLoad>
                        <FileView className="blur" src={message.fileURL} />
                    </LayoutLoad>
                );
            case ERROUP:
            case LIMITMIDIA:
            case FILETOOLARGE:
                return (
                    <>
                        <FileView className="blur" src={message.fileURL} />
                        <LayoutRequest onClick={() => setMessage(message)}>
                            <FiUpload />
                        </LayoutRequest>
                    </>
                );
            default:
                // SUCCESS
                return (
                    !message.blobUrl ?
                        <>
                            <FilePreview />
                            <LayoutRequest onClick={() => fetchMessage(message)}>
                                <FiDownload />
                            </LayoutRequest>
                        </>

                        :
                        <FileView src={message.blobUrl} controls />
                );
        }
    }

    return (
        <Image>
            <FileStatus />
        </Image>
    )
}

export default ImgVideo;