import styled from "styled-components";
import { mobile, notebook } from "../../../../../components/NavBar/style";

export const ContainerType = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 1rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .button-clear {
    :hover {
      background-color: ${({ theme }) => theme.backgrounds.lightGrey};
    }
  }

  .button-confirm {
    color: ${({ theme }) => theme.texts.white};
    margin-left: 8px;
    background-color: ${({ theme }) =>
      theme.backgrounds.buttonModalGroup1Login};
  }
  p {
    margin: 0px;
    display: flex;
    font-size: 1rem;
  }

  .selected {
    background-color: ${({ theme }) => theme.hover.lightGrey};
  }
  .container-types {
    display: grid;
    height: auto;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(5, minmax(140px, 140px));
    padding: 8px;
    grid-gap: 8px;
    margin-bottom: 0px;

    @media only screen and (max-width: ${notebook}) {
      grid-template-columns: repeat(3, minmax(140px, 140px));
    }
    @media only screen and (max-width: ${mobile}) {
      grid-template-columns: repeat(2, minmax(140px, 140px));
    }
  }

  .container-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8px;
    margin-bottom: 8px;
  }
  .card-type {
    display: grid;
    height: 50px;
    align-items: center;
    justify-content: center;
    grid-template-columns: 35px 100px;

    padding: 8px;
  }
`;

export const ContainerToolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.texts.black};

  p {
    margin: 0;
  }
  .container-type {
    padding: 4px;
    align-items: center;
  }
  .item-type {
    margin: 0px 4px;
    display: grid;
    color: ${({ theme }) => theme.texts.white};

    grid-template-columns: auto 20px;
    align-items: center;
    background-color: ${({ theme }) => theme.backgrounds.buttonSchedule};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.backgrounds.lightGrey};
  }

  .item-text {
    padding: 4px;
    border-right: 1px solid ${({ theme }) => theme.texts.white};
  }

  .item-close {
    padding: 4px;
  }

  .button-clear {
    padding: 4px;
    height: 100%;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    color: ${({ theme }) => theme.hover.red};
    margin-left: 4px;
    svg {
      padding-left: 4px;
    }
    :hover {
      background-color: ${({ theme }) => theme.hover.lightGrey};
    }
  }

  svg {
    cursor: pointer;
  }
`;
