import { EnumPositionHorizontal } from "../types/project";

const positionValues = [
  { value: EnumPositionHorizontal.CENTER, label: "Coluna central" },
  { value: EnumPositionHorizontal.LEFT, label: "Coluna esquerda" },
  { value: EnumPositionHorizontal.RIGHT, label: "Coluna direita" },
];
const fontValues = [
  { value: "Arial", label: "Arial" },
  { value: "Georgia", label: "Georgia" },
  { value: "Helvetica", label: "Helvetica" },
  { value: "Roboto", label: "Roboto" },
  { value: "TimesNewRoman", label: "TimesNewRoman" },
];

const alignmentValues = [
  { value: "left", label: "Esquerda" },
  { value: "right", label: "Direita" },
  { value: "center", label: "Centralizada" },
  { value: "justify", label: "Justificada" },
];
export const Utils = {
  positionValues,
  fontValues,
  alignmentValues,
};

export const defaultCategories = [
  { value: 0, label: "Outros" },
  { value: 1, label: "Aniversário" },
  { value: 2, label: "Reunião" },
  { value: 3, label: "Atividade" },
  { value: 4, label: "Compromisso" },
];

export const isDefaultCategory = (categoryValue) =>
  categoryValue >= 0 && categoryValue <= 4;
