import { api } from '../api';
import { getToken } from "../auth";


export const postAddUsersGroup = (groupId, userIds) => {
    return api({
        url: "/user-group",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { groupId, userIds }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};


export const getAllUsersInGroup = (groupId) => {
    return api({
        url: `/user-group/${groupId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getAllUsers = () => {
    return api({
        url: `/user-group/groups/all-participants`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getUserForPrivacyGroup = (userIdInfo, groupId) => {
    return api({
        url: `/user-group/privacy/${userIdInfo}/${groupId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getParticipantesForUpdateRole = (userId, groupId, roleId) => {
    return api({
        url: `/user-group/role/not-participate/${userId}/${groupId}/${roleId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const getParticipantsWithRole = (userId, groupId) => {
    return api({
        url: `/user-group/role/participants/all/${userId}/${groupId}`,
        method: "GET",
        headers: {
            Authorization: "Bearer " + getToken()
        }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteRemoveParticipants = (groupId, userIds) => {
    return api({
        url: `/user-group/`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { groupId, userIds }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const deleteExitGroup = (groupId) => {
    return api({
        url: `/user-group/exit-group/${groupId}`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchConfigUserGroup = (userId, groupId, data) => {
    return api({
        url: `/user-group/${userId}/${groupId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const patchUpdateAdmin = (groupId, usersAdd, usersRemove) => {
    return api({
        url: `/user-group/update/admin/${groupId}`,
        method: "PATCH",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { usersAdd, usersRemove }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};


export const deleteGroupForUser = (groupId) => {
    return api({
        url: `/user-group/group`,
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { groupId }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};

export const postAddUserInGroup = (groupId) => {
    return api({
        url: "/user-group/add-user/invite",
        method: "POST",
        headers: {
            Authorization: "Bearer " + getToken()
        },
        data: { groupId }
    }).then(response => response)
        .catch(error => {
            if (error.response) {
                return error.response
            } else if (error.request) {
                return error.request
            } else {
                console.log('Error: ', error.message);
                return error.message
            }
        });
};
