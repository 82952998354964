import {
  SET_BIRTHHDAYS_DASH,
  SET_COMMITMENT_DASH,
  SET_NOTIFICATIONS_DASH, SET_WEATHER_DASH
} from "./actionTypes";

export const setBirthdaysDash = (birthdays) => {
  return {
    type: SET_BIRTHHDAYS_DASH,
    payload: birthdays,
  };
};

export const setCommitmentDash = (commitment) => {
  return {
    type: SET_COMMITMENT_DASH,
    payload: commitment,
  };
};

export const setWeatherDash = (weather) => {
  return {
    type: SET_WEATHER_DASH,
    payload: weather,
  };
};

export const setNotificationsDash = (notifications) => {
  return {
    type: SET_NOTIFICATIONS_DASH,
    payload: notifications,
  };
};
