import { api } from "../api";
import { getToken, logout } from "../auth";

export const postRegister = (email, password, invite) => {
  return api({
    url: "/users/register/",
    method: "POST",
    data: { email, password, invite },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const putNameAndNick = (id, name, nickname) => {
  return api({
    url: "/users/register/user_data/",
    method: "PUT",
    data: { id, name, nickname },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const patchUserData = (data) => {
  return api({
    url: `/users`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postLoginEmail = (email, password) => {
  return api({
    url: "/users/login/",
    method: "POST",
    data: { email, password },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postLoginNumber = (phoneNumber) => {
  return api({
    url: "/users/login-phone-number",
    method: "POST",
    data: { phoneNumber },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postLoginVerifyNumber = (phoneNumber, code) => {
  return api({
    url: "/users/login-verification-code",
    method: "POST",
    data: { phoneNumber, code },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.error("API error: unable to verify code");
      console.error(error.response.data);
      return error.response.data;
    });
};

export const postPhoto = (id, data) => {
  return api({
    url: `/photo/${id}`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const patchPhoto = (data) => {
  return api({
    url: `/photo/`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postRegisterWithPhone = (phoneNumber, invite) => {
  return api({
    url: "/users/register-phone-verification",
    method: "POST",
    data: { phoneNumber, invite },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postRegisterVerifyCode = (phoneNumber, code) => {
  return api({
    url: "/users/register-verification-code",
    method: "POST",
    data: { phoneNumber, code },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postResetPassword = (email) => {
  return api({
    url: "/users/forgot-password",
    method: "POST",
    data: { email },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postActiveAccount = (token) => {
  return api({
    url: "/users/register/active-account/" + token,
    method: "POST",
  })
    .then((response) => response)
    .catch((error) => {
      console.error("API error: unable to send password recovery email");
      console.error(error.response.data);
      return error.response.data;
    });
};

export const getUserByToken = () => {
  const token = getToken();
  return api({
    url: "/users/" + token,
    headers: {
      Authorization: "Bearer " + token,
    },
    method: "GET",
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        const { status } = error.response;
        //User blocked
        if (status == 412) {
          logout();
          window.location.href = "/login";
        }

        return error.response;
      } else if (error.request) {
        // window.location.href = '/'
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postLoginGoogle = (user) => {
  return api({
    url: "/users/auth/google/callback",
    method: "POST",
    data: user,
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postLoginFacebook = (user) => {
  return api({
    url: "/users/auth/facebook/callback",
    method: "POST",
    data: user,
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const putForgotPassword = (token, password) => {
  return api({
    url: "/users/update-forgot-password",
    method: "PUT",
    data: { token, password },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const getUsersByName = (nickname) => {
  return api({
    url: `/users/nickname/${nickname}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postMessage = (data) => {
  return api({
    url: "/users/message",
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: data,
  })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postChangeEmail = (email) => {
  return api({
    url: "/users/change-email",
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: { email },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const patchConfirmChangeEmail = (token) => {
  return api({
    url: `/users/change-email/${token}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const postRegisterEmail = (userId, email, password) => {
  return api({
    url: `/users/register-info-access`,
    method: "POST",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: { userId, email, password },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        console.log(error.response);
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const patchConfirmRegisterEmail = (token) => {
  return api({
    url: `/users/register-info-access/${token}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const patchUpdatePassword = (userId, password, newPassword) => {
  return api({
    url: `/users/update-password`,
    method: "PUT",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: { userId, password, newPassword },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const putPhoneNumber = (userId, phoneNumber) => {
  return api({
    url: `/users/update-phoneNumber/${userId}`,
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
    data: { phoneNumber },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};

export const putDisabledAccount = () => {
  return api({
    url: `/users/disabled-account`,
    method: "PUT",
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  })
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return error.request;
      } else {
        console.log("Error", error.message);
        return error.message;
      }
    });
};
