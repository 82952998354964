import moment from 'moment';
import { useEffect, useState } from 'react';
import { ViewStatic } from 'react-big-calendar';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { add } from 'react-big-calendar/lib/utils/dates';
import { TEvent } from '../../../../../types/project';
import { areDatesEqualMonth } from '../../../../../util/date';
import Event from '../../Content/Event';


function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment()
  } else {
    currentDate = moment(currentDate)
  }

  const first = currentDate.clone().startOf('month')
  const last = currentDate.clone().endOf('month')
  const weeksCount = Math.ceil((first.day() + last.date()) / 7)
  const calendar: any = Object.assign([], { currentDate, first, last })

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week: any[] = []
    calendar.push(week)
    calendar.year = currentDate.year()
    calendar.month = currentDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 1 - first.day())
      date.calendar = calendar
      week.push(date)
    }
  }

  return calendar
}

function CalendarDate(props) {
  const { dateToRender, dateOfMonth } = props
  const today =
    dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? 'today'
      : ''

  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    )
  }

  return (
    <button
      className={`date in-month ${today}`}
      onClick={() => props.onClick(dateToRender)}>
      {dateToRender.date()}
    </button>
  )
}


const Calendar = ({ date }) => {
  const [calendar, setCalendar] = useState(undefined);

  useEffect(() => {
    setCalendar(createCalendar(date));
  }, [date]);

  if (!calendar) {
    return null;
  }

  return (
    <div className="month">
      <div className="month-name">
        {/* {this.state.calendar.currentDate.format('MMMM').toUpperCase()} */}
      </div>
      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
        <span key={index} className="day">
          {day}
        </span>
      ))}
      {/* {this.state.calendar.map((week, index) => (
        <div key={index}>
          {week.map(date => (
            <CalendarDate
              key={date.date()}
              dateToRender={date}
              dateOfMonth={this.state.calendar.currentDate}
              onClick={date =>
                alert(`Will go to daily-view of ${date.format('YYYY-MM-DD')}`)
              }
            />
          ))}
        </div>
      ))} */}
    </div>
  )
  // ...
};

type daysEvent = {
  day: Date;
  events: TEvent[];
}
const Year: (React.ComponentType<any> & ViewStatic) = ({ date, events }) => {
  const myEvents: TEvent[] = events;
  const myEventsMonth = myEvents.filter(event => areDatesEqualMonth(new Date(event.dateStart), date));

  return <div className="year">{myEventsMonth.map((event) => <Event event={event} />)}</div>;
};

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, 'month');

    case navigate.NEXT:
      return add(date, 1, 'month');

    default:
      return date;
  }
};

Year.title = (date, { localizer }) => localizer.format(date, 'MMMM YYYY');

export default Year;
