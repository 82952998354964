import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { bindActionCreators } from 'redux';
import { BsImages, FaUser, FiAtSign, FiCrop, FiTrash, HiOutlineUser } from '../../../assets/icons';
import { CropImage, InputLogin, Load, Modal } from '../../../components';
import Img from '../../../components/Img';
import { postGeneral } from '../../../services/requests/cluster';
import { getUserByToken, postPhoto, putNameAndNick } from '../../../services/requests/user';
import { setAbare } from "../../../store/actions/abareAction";
import { TypeImage } from '../../../types/project';
import { ToastModel, errorDefault } from '../../../util/error';
import { transformUrlImage } from '../../../util/verification';
import { Button, Card, Container, Default, OutlineButton, Profile } from './style';
const UserData = (props) => {
    const { setAbare } = props;
    const { addToast } = useToasts();
    const [step, setStep] = useState(1);
    const [image, setImage] = useState<TypeImage>();
    const fileInput = useRef<HTMLInputElement>(null);
    const [abare, setAbareInfo] = useState<any>(null);
    const [load, setLoad] = useState(false);
    const history = useHistory();
    const modalImage = useRef<any>();
    const refCrop = useRef<any>(null);
    const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE || '';

    let dataForm;

    useEffect(() => {
        getUserByToken().then((res) => {
            setAbareInfo(res.data);
        });
    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        const { name, user } = event.target.elements;
        setLoad(true)
        putNameAndNick(abare?.id, name.value, user.value).then((res) => {
            const status = res.status
            if (status) {
                if (status == 200) {
                    postGeneral(abare.id);
                    abare.name = name.value;
                    abare.nickname = user.value;
                    setAbare({ ...abare })
                    setAbareInfo(abare)
                    setStep(2)
                } else {
                    errorRegistrer(status)
                }
            } else {
                ToastModel(addToast, 'Erro de conexão!', "Tente novamente mais tarde", "error")
            }
            setLoad(false)
        });
    }


    const errorRegistrer = (status) => {
        switch (status) {
            case 409:
                addToast({
                    title: 'Apelido já está cadastrado',
                    description: "Por favor informe outro apelido"
                },
                    { appearance: 'error' });
                break
            default:
                errorDefault(status, addToast)
        }
    }

    const upload = () => {
        const file = image?.file;

        if (file) {
            setLoad(true)
            dataForm = new FormData();
            dataForm.append('file', file);

            postPhoto(abare.id, dataForm).then((res) => {
                const status = res.status;
                if (status == 200) {
                    abare.photoUrl = res.data.url;
                    setAbare({ ...abare })
                    history.push('/home');
                } else {
                    if (!errorDefault(status, addToast)) {
                        switch (res.statusCode) {
                            case 413:
                                if (res.message == "File too large") {
                                    addToast({
                                        title: 'Imagem muito grande',
                                        description: "Tamanho máximo de 6mb"
                                    },
                                        { appearance: 'error' });
                                    break;
                                }
                                break
                            default:
                                addToast({
                                    title: 'Não foi possível prosseguir com o cadastro',
                                    description: "Tente novamente mais tarde."
                                },
                                    { appearance: 'error' });
                                break;
                        }
                    }
                    setLoad(false)
                }
            });
        } else {
            history.push('/home');
        }
    }

    const Datas = () => {
        return (
            <Card>
                <h2 id="title">Finalizando seu cadastro</h2>
                <h5>Insira seus dados para continuar</h5>

                <form onSubmit={onSubmit}>
                    <InputLogin Icon={HiOutlineUser} name="name" label="Nome"
                        type="text" required placeholder="Digite seu nome aqui" />
                    <InputLogin Icon={FiAtSign} name="user" label="Apelido"
                        type="text" required placeholder="Insira um apelido" />
                    <div className="text-center">
                        <Button disabled={load} type="submit" className="horizontal-gradient-login">
                            {load ? <Load /> : "Próximo"}
                        </Button>
                    </div>
                </form>
            </Card>
        );
    }


    const Photo = () => {

        const openModal = (obj?) => {
            obj && setImage({ ...image, ...obj });
            modalImage.current.open();
        }

        const handleImage = e => {
            const file = e.target.files[0];

            if (file) {
                if (file.size <= MAX_FILE_SIZE) {
                    const url = URL.createObjectURL(file);
                    openModal({ ...image, mainUrl: url })
                } else {
                    addToast({
                        title: 'Imagem muito grande',
                        description: "Tamanho máximo de 6mb"
                    },
                        { appearance: 'error' });
                }
            }
        };


        const crop = async () => {
            const data = await refCrop.current.cropImage();
            setImage({ ...image, ...data })
            modalImage.current.close()
        }

        return (
            <Card>
                <h2 id="title">Foto para seu perfil</h2>
                <h5>Escolha uma imagem (opcional)</h5>

                <input
                    type="file"
                    style={{ display: "none" }}
                    ref={fileInput}
                    value={""}
                    onChange={handleImage}
                    accept="image/png, image/gif, image/jpeg"
                />

                <div className="center">
                    {(image?.newUrl || image?.defaultUrl) ?
                        <>
                            <Profile>
                                <Img id="profile" src={image?.newUrl ? image.newUrl : transformUrlImage(image.defaultUrl)} alt="Foto de perfil" />
                            </Profile>
                            <div id="row">
                                <OutlineButton onClick={() => openModal({ mainUrl: image.defaultUrl })}>
                                    <FiCrop id='icon' />
                                </OutlineButton>
                                <OutlineButton onClick={() => setImage({})}>
                                    <FiTrash id='icon' />
                                </OutlineButton>
                                <OutlineButton onClick={() => fileInput.current?.click()}>
                                    <BsImages id='icon' />
                                </OutlineButton>
                            </div>
                        </>
                        :
                        <>
                            <Default className="vertical-gradient" onClick={() => fileInput.current?.click()}>
                                <FaUser size={40} />
                            </Default>
                            <OutlineButton onClick={() => fileInput.current?.click()}>
                                <BsImages id='icon' className="right" /> Selecione uma imagem
                            </OutlineButton>
                        </>
                    }
                    <Button className="horizontal-gradient-login" disabled={load} onClick={() => upload()}>
                        {image?.mainUrl ?
                            load ? <Load /> : "Próximo"
                            :
                            "Pular"
                        }
                    </Button>
                </div>
                <Modal title="Recortar Foto" subTitle="Arraste para ajustar a imagem" ref={modalImage}>
                    <CropImage height="280px" ref={refCrop} url={image?.mainUrl} />
                    <div className="text-center">
                        <Button className="horizontal-gradient-login" type="button" onClick={() => crop()} >Ajustar Imagem</Button>
                    </div>
                </Modal>
            </Card>
        );
    }

    return (
        <Container className="vertical-gradient">
            {step === 1 ?
                Datas() :
                Photo()
            }
        </Container>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setAbare
}, dispatch)

export default connect(null, mapDispatchToProps)(UserData);