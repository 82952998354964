import styled from "styled-components";

export const Main = styled.div`
    background-color: red;
`;

export const Container = styled.div`
  display: grid ;
  margin-bottom: 25px;
  grid-column-gap: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(112, 5, 64, 0.05);

  .select{
    box-shadow: 3px 3px #92406D;
  }

  .no-select{
    border: none;
  }

  .center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .img-container {
    height: auto;
    background-color: ${props => props.theme.backgrounds.white};
  }
`;


export const Img = styled.img`
  object-fit: cover;
  width: 45%;
  display: inline-block;
  vertical-align: top;
  float: left;
  height: 130px;
  margin-left: 10px;
  margin-bottom: 7px;
  transition: 0.3s;
  border: none;
  cursor: pointer;

  &:hover{
    opacity: 0.3;
  }
`