import { FolderRepository } from "../../types/project";
import { EnumTypeRepository, TStorageCluster } from "../../types/redux";
import {
  ADD_STORAGECLUSTER_FOLDER,
  CLEAR_FILTERS_REPOSITORY,
  NAVIGATION_STORAGECLUSTER_FOLDER,
  REMOVE_TYPE_FILTER_REPOSITORY,
  SET_REPOSITORY_FILE_SELECTED,
  SET_REPOSITORY_FOLDER_SELECTED,
  SET_SELECT_REPOSITORY,
  SET_STORAGECLUSTER_FOLDER,
  SET_TYPES_FILTER_REPOSITORY,
} from "../actions/actionTypes";

const initialState: TStorageCluster = {
  folder: [], //Caminho das pastas atual
  fileSelected: undefined,
  folderSelected: undefined,
  itemSelected: undefined,
  filters: {
    types: [],
  },
  typeSelect: EnumTypeRepository.MY_SPACE,
};

function navigationPath(
  folderActual: FolderRepository[],
  folderSelect: FolderRepository
) {
  if (folderSelect.id == "") {
    return [];
  } else {
    const indexActual = folderActual.indexOf(
      folderActual.find((folder) => folder.id == folderSelect.id)!
    );
    return folderActual.filter((folder, index) => index <= indexActual);
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STORAGECLUSTER_FOLDER:
      return {
        ...state,
        folder: [...action.payload],
        fileSelected: undefined,
      };
    case ADD_STORAGECLUSTER_FOLDER:
      return {
        ...state,
                fileSelected: undefined,
        folderSelected: undefined,
        itemSelected: undefined,
        folder: [...state.folder, action.payload],
      };
    case SET_REPOSITORY_FILE_SELECTED:
      return {
        ...state,
        fileSelected: action.payload,
        folderSelected:
          action.payload == undefined ? undefined : state.folderSelected,
        itemSelected: action.payload == undefined ? undefined : "file",
      };
    case SET_REPOSITORY_FOLDER_SELECTED:
      return {
        ...state,
        folderSelected: action.payload,
        fileSelected:
          action.payload == undefined ? undefined : state.fileSelected,
        itemSelected: action.payload == undefined ? undefined : "folder",
      };
    case NAVIGATION_STORAGECLUSTER_FOLDER:
      return {
        ...state,
        fileSelected: undefined,
        folderSelected: undefined,
        itemSelected: undefined,
        folder: navigationPath(state.folder, action.payload),
      };

    case CLEAR_FILTERS_REPOSITORY:
      return {
        ...state,
        filters: {
          types: [],
        },
      };
    case SET_TYPES_FILTER_REPOSITORY:
      state.filters.types = action.payload;
      return {
        ...state,
      };
    case REMOVE_TYPE_FILTER_REPOSITORY:
      return {
        ...state,
        filters: {
          types: state.filters.types.filter((type) => type != action.payload),
        },
      };
    case SET_SELECT_REPOSITORY:
      return {
        ...state,
        typeSelect: action.payload,
      };
    default:
      return { ...state };
  }
}
