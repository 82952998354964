import { ADD_CONTACT, SET_CONTACTS } from "../actions/actionTypes"

const initialState = {
    list: []
}
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CONTACTS:
            return {
                list: action.payload
            }
        case ADD_CONTACT:
            return {
                list: [...state.list, action.payload]
            }
        default:
            return { ...state }
    }
}