import styled from "styled-components";

interface StyledProps {
  actived?: string | boolean;
}

interface StyledPropsMenu {
  actived?: string | boolean;
  open: boolean;
}

export const mobile = "540px";
export const mobileHeight = "500px";
export const tablet = "768px";
export const samllNotebook = "1200px";
export const notebook = "1366px";
export const mediumNotebook = "1580px";

export const largeNotebook = "1710px";
export const colapsedCalendar = "925px";

export const colapsedSchedule = "1050px";

export const ProfileMenu = styled.div<StyledProps>`
  display: flex;
  padding: 12px;
  padding-bottom: 1rem;
  height: 64px;
  border-bottom: 1px solid ${(props) => props.theme.bordes.lightGrey};

  position: relative;

  #change-state {
    height: 30px;
    position: absolute;
    right: -15px;
    z-index: 10;
    top: 50%;
    width: 30px;
    transform: translateY(-50%);
    color: ${(props) => props.theme.texts.purpleDark};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra de elevação */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${mediumNotebook}) {
    #change-state {
      display: none;
    }
  }

  .down {
    transform: rotate(90deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  .up {
    transform: rotate(270deg);
    transition: transform 0.4s linear;
    cursor: pointer;
  }

  #profile {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .not-full {
    padding: 0px 8px;
    box-sizing: border-box;
  }

  .text {
    align-self: center;
    margin-left: 12px;

    #name {
      margin: 0;
      font-size: 20px;
    }

    #nickname {
      margin: 0;
      font-size: 14px;
      font-weight: lighter;
    }
  }
`;

export const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.75rem;

  @media only screen and (max-width: ${mobile}) {
    gap: 0.5rem;
  }
  @media only screen and (max-width: ${mobile}) {
    border-radius: 20px;
  }

  .container-icons {
    position: relative;
    transition: opacity 0.3s ease; /* Configuração da transição */
    height: 1.5rem;
    width: 1.5rem;

    svg {
      transition: opacity 0.3s ease; /* Configuração da transição */
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .nav-link-minimized {
    grid-template-columns: 1fr 0px !important;
    justify-items: center;
    padding: 0.5rem 1rem !important;
    gap: 0px !important;
  }

  .icon-default {
    opacity: 1;
  }

  .active-indicator {
    opacity: 0;
    fill: ${({ theme }) => theme.texts.primaryNavBar} !important;
  }

  .hidden {
    overflow: hidden;
    width: 0px;
  }

  .item-menu {
    display: flex;
    flex-direction: column;

    .arrow {
      cursor: pointer;
      transition: transform 0.4s linear;
    }

    @media only screen and (max-width: ${mobile}) {
      .arrow {
        display: none;
      }
    }

    .up {
      transform: rotate(180deg);
    }

    .down {
      transform: rotate(0deg);
    }

    .movel {
      transition: height 0.4s;
      overflow: hidden;
    }

    .open-visible {
      height: 200px;
    }

    .close-visible {
      height: 0px;
    }

    .nav-link {
      cursor: pointer;
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 1rem;
      color: ${(props) => props.theme.texts.purpleDark};
      font-size: 1rem;
      border-radius: 4px;
      align-items: center;
      text-decoration: none !important;
      transition: background-color 0.3s ease-in;
      padding: 8px 0px 8px 16px;

      @media only screen and (max-width: ${mobile}) {
        border-radius: 20px;
      }

      &:focus,
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
      }

      .text {
        color: ${({ theme }) => theme.texts.secondaryNavBar};
        font-family: "inter-medium", sans-serif;
        margin: 0px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.hover.navBar};
      }

      &:active {
        background-color: ${(props) => props.theme.hover.buttonActive};
      }
    }

    .grid-arrow {
      grid-template-columns: 24px 1fr 24px;
    }

    @media only screen and (max-width: ${mobile}) {
      .grid-arrow {
        grid-template-columns: 24px;
      }
    }

    .actived {
      background-color: ${({ theme }) => theme.highlights.navBar} !important;
      color: ${(props) => props.theme.texts.primaryNavBar};

      .text {
        color: ${({theme}) => theme.texts.primaryNavBar};
        font-family: "inter-semi-bold", sans-serif;
      }

      .active-indicator {
        opacity: 1;
        fill: ${({ theme }) => theme.texts.primaryNavBar} !important;
      }

      .icon-default {
        opacity: 0;
      }
    }
  }
`;

export const Container = styled.nav<StyledPropsMenu>`
  background-color: ${(props) => props.theme.backgrounds.white};
  width: ${({ open }) => (open ? `232px` : `80px`)};
  color: ${(props) => props.theme.default.ligther};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-right: 1px solid ${({ theme }) => theme.bordes.lightGrey};
  transition: all 0.5s ease;
  @media only screen and (max-width: ${mediumNotebook}) {
    width: 100px;
    ${ProfileMenu} {
      justify-content: center;
    }

    .text {
      display: none;
    }

    .nav-link {
      justify-content: center;
    }
  }

  @media only screen and (max-width: ${mobile}) {
    width: 100%;
    border: none;
    ${MenuList} {
      flex-direction: row;
      justify-content: space-evenly;
      box-sizing: border-box;
      height: 60px;
    }

    ${ProfileMenu} {
      display: none;
    }

    .item-menu {
      padding: 5px 0px;
    }
  }
`;
